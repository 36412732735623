
import { getGameTitleByType } from '@/utils/gameConstants';
import { computed, defineComponent, ref } from 'vue';
import { shareGameResult } from '@/utils/shareResult';
import { useStore } from '@/store';
import { calculateGameResult } from '@/utils/helper';

export default defineComponent({
  name: 'HistoryModalScoreboardComponent',
  components: {},
  props: {
    game: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const user = computed(() => store.getters.getUser);
    const title = getGameTitleByType(props.game.gameName);
    const customGameName = ref(props.game.customGameName);
    const customGameCss = ref(props.game.customGameCss);
    const dateString = ref(
      props.game.dateCreated.format('DD MMMM, YYYY - hh:mm A')
    );
    const hostName = ref(undefined as string | undefined);
    const isHostNotPlaying = ref(props.game.isHostPlaying === false);
    const totalScores = ref([] as any[]);

    function calculateResult() {
      if (props.game.host !== user.value.uid) {
        const host = props.game.players.find(
          player => player.playerId === props.game.host
        );
        if (host) {
          hostName.value = host.name;
        }
      }
      const result = calculateGameResult(props.game);
      totalScores.value = result['scores'];
    }

    calculateResult();

    async function shareResult() {
      const data = {
        gameName: props.game.gameName,
        date:
          dateString.value +
          (hostName.value ? ` - Hosted by ${hostName.value}` : ''),
        scores: totalScores.value
      };
      const url = await shareGameResult(data);
      window.open(url, '_blank');
    }

    return {
      title,
      customGameName,
      customGameCss,
      dateString,
      hostName,
      isHostNotPlaying,
      totalScores,
      shareResult
    };
  }
});
