
import { computed, defineComponent, ref } from 'vue';
import { useStore } from '@/store';
import vClickOutside from 'click-outside-vue3';
import { TeamRole } from '@/models/TeamRole';

export default defineComponent({
  name: 'TeamMemberTile',
  directives: {
    clickOutside: vClickOutside.directive
  },
  emits: ['team-role', 'leave-team'],
  props: {
    member: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const store = useStore();
    const user = computed(() => store.getters.getUser);
    const showMenu = ref(false);
    const menuType =
      props.member.role === TeamRole.Owner
        ? props.member.uid === user.value.uid
          ? 0
          : 1
        : props.member.uid === user.value.uid
        ? 2
        : 3;

    const openMenu = function() {
      showMenu.value = true;
    };

    const closeMenu = function(leave: boolean | undefined) {
      showMenu.value = false;
      if (leave === true) {
        context.emit('leave-team');
      }
    };

    const openTeamRole = function() {
      context.emit('team-role');
    };
    return {
      showMenu,
      openMenu,
      closeMenu,
      openTeamRole,
      menuType
    };
  }
});
