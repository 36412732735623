
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SignUpSuccessModal',
  emits: ['close'],
  props: {
    teamName: {
      type: String
    }
  },
  setup(_, context) {
    const close = function() {
      context.emit('close');
    };

    return {
      close
    };
  }
});
