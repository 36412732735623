
import { getGameTitleByType } from '@/utils/gameConstants';
import { computed, defineComponent, ref } from 'vue';
import { useStore } from '@/store';

export default defineComponent({
  name: 'HistoryModalOneToHundredComponent',
  components: {},
  props: {
    game: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const user = computed(() => store.getters.getUser);
    const title = getGameTitleByType(props.game.gameName);
    const customGameName = ref(props.game.customGameName);
    const customGameCss = ref(props.game.customGameCss);
    const dateString = ref(
      props.game.dateCreated.format('DD MMMM, YYYY - hh:mm A')
    );
    const hostName = ref(undefined as string | undefined);
    const isHostNotPlaying = ref(props.game.isHostPlaying === false);
    const score = ref(props.game.scoredPoints ?? 0);
    const players = ref([] as any[]);
    const showPlayers = ref([] as any[]);
    const rounds = ref([] as string[]);

    function calculateResult() {
      if (props.game.host !== user.value.uid) {
        const host = props.game.players.find(
          player => player.playerId === props.game.host
        );
        if (host) {
          hostName.value = host.name;
        }
      }

      const actualPlayers = Object.assign(
        [],
        isHostNotPlaying.value
          ? props.game.players.filter(
              player => player.playerId !== props.game.host
            )
          : props.game.players
      );
      props.game.gameDetails.map(detail => {
        rounds.value.push(detail.chosenTopic.topic);
      });
      actualPlayers.map(player => {
        players.value.push({
          name: player.name,
          email: player.email
        });
      });

      if (players.value.length > 7) {
        showPlayers.value = players.value.slice(0, 7);
      } else {
        showPlayers.value = players.value;
      }
    }

    calculateResult();

    function showMore() {
      showPlayers.value = players.value;
    }

    return {
      title,
      customGameName,
      customGameCss,
      dateString,
      hostName,
      isHostNotPlaying,
      score,
      players,
      showPlayers,
      rounds,
      showMore
    };
  }
});
