
import { defineComponent, onMounted, ref } from 'vue';
import { amplitudeEvent } from '@/config/amplitudeConfig';
// import NumberOfPlayersRummy from '@/components/Modal/AboutComponent/NumberOfPlayersComponents/NumberOfPlayersRummy.vue';
import HowToPlayRummy from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayRummy.vue';
import CollapseTransition from '@/components/CollapseTransition.vue';

export default defineComponent({
  name: 'AboutRummy',
  components: {
    // NumberOfPlayersRummy,
    HowToPlayRummy,
    CollapseTransition
  },
  setup() {
    const showTabs = ref([true, false, false]);

    onMounted(() => {
      amplitudeEvent('previewGame', {
        game: 'rummy_game',
        version: 'regular',
        eventTime: new Date(Date.now()).toISOString()
      });
    });

    return {
      showTabs
    };
  }
});
