
import { getGameTitleByType } from '@/utils/gameConstants';
import { computed, defineComponent, ref } from 'vue';
import { useStore } from '@/store';

export default defineComponent({
  name: 'HistoryModalTwoWordsComponent',
  components: {},
  props: {
    game: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const user = computed(() => store.getters.getUser);
    const title = getGameTitleByType(props.game.gameName);
    const customGameName = ref(props.game.customGameName);
    const customGameCss = ref(props.game.customGameCss);
    const dateString = ref(
      props.game.dateCreated.format('DD MMMM, YYYY - hh:mm A')
    );
    const hostName = ref(undefined as string | undefined);
    const isHostNotPlaying = ref(props.game.isHostPlaying === false);
    const status = ref(props.game.won === 'civilian');
    const word = ref(props.game.words.word);
    const clue = ref(props.game.words.clue);
    const civilians = ref([] as any[]);
    const showCivilians = ref([] as any[]);
    const mimics = ref([] as any[]);
    const showMimics = ref([] as any[]);
    const blindMimic = ref(undefined as any);

    function calculateResult() {
      if (props.game.host !== user.value.uid) {
        hostName.value =
          props.game.players[props.game.host]?.name ?? props.game.hostName;
      }

      const actualPlayers = Object.assign({}, props.game.players);
      if (isHostNotPlaying.value) {
        delete actualPlayers[props.game.host];
      }
      const playerIds = Object.keys(actualPlayers ?? {});
      playerIds.map(playerId => {
        if (actualPlayers[playerId]) {
          const player = actualPlayers[playerId];
          if (player.role === 'civilian') {
            civilians.value.push({
              name: player.name,
              email: player.email
            });
          } else if (player.role === 'mimic') {
            mimics.value.push({
              name: player.name,
              email: player.email
            });
          } else {
            blindMimic.value = {
              name: player.name,
              email: player.email
            };
          }
        }
      });
      if (civilians.value.length > 7) {
        showCivilians.value = civilians.value.slice(0, 7);
      } else {
        showCivilians.value = civilians.value;
      }
      if (mimics.value.length > 7) {
        showMimics.value = mimics.value.slice(0, 7);
      } else {
        showMimics.value = mimics.value;
      }
    }

    calculateResult();

    function showCivilianMore() {
      showCivilians.value = civilians.value;
    }

    function showMimicMore() {
      showMimics.value = mimics.value;
    }

    return {
      title,
      customGameName,
      customGameCss,
      dateString,
      hostName,
      isHostNotPlaying,
      status,
      word,
      clue,
      civilians,
      showCivilians,
      mimics,
      showMimics,
      blindMimic,
      showCivilianMore,
      showMimicMore
    };
  }
});
