
import { defineComponent, onMounted, ref } from 'vue';
import { getGameTitleByType, customGameTypes } from '@/utils/gameConstants';
import CreateCustomizedGameModal from '@/components/Modal/CreateCustomizedGameModal.vue';
import router from '@/router';
import { amplitudeEvent } from '@/config/amplitudeConfig';

export default defineComponent({
  name: 'CustomizedGameListModal',
  components: {
    CreateCustomizedGameModal
  },
  emits: ['close', 'start'],
  setup(_, context) {
    const show = ref(true);
    const showCreateModal = ref(false);
    const createType = ref('');
    const filteredCustomGameTypes = customGameTypes;

    const close = function() {
      show.value = false;
      setTimeout(() => {
        context.emit('close');
      }, 50);
    };

    const showCreate = function(type: string) {
      createType.value = type;
      showCreateModal.value = true;
    };

    const onCreateClosed = function() {
      showCreateModal.value = false;
    };

    const onGameCreate = function(name: string, description: string) {
      showCreateModal.value = false;
      let routeData = router.resolve({
        name: 'CreateCustomGame',
        query: {
          type: createType.value,
          title: name.trim(),
          description: description.trim()
        }
      });
      window.open(routeData.href, '_blank');
    };

    onMounted(() => {
      amplitudeEvent('customizeGame', {
        eventTime: new Date(Date.now()).toISOString()
      });
    });

    return {
      show,
      filteredCustomGameTypes,
      showCreateModal,
      createType,
      close,
      showCreate,
      onCreateClosed,
      onGameCreate,
      getGameTitleByType
    };
  }
});
