
import HistoryTile from '@/components/HistoryLeaderboard/HistoryTile.vue';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'HistoryMonthList',
  components: {
    HistoryTile
  },
  props: {
    date: {
      type: String,
      required: true
    },
    games: {
      type: Array,
      required: true
    }
  },
  emits: ['open'],
  setup(_, context) {
    function onOpenModal(game: any) {
      context.emit('open', game);
    }

    return {
      onOpenModal
    };
  }
});
