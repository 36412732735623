
import { defineComponent, onMounted } from 'vue';
import { amplitudeEvent } from '@/config/amplitudeConfig';
// import NumberOfPlayersFunnyOrNot from '@/components/Modal/AboutComponent/NumberOfPlayersComponents/NumberOfPlayersFunnyOrNot.vue';
import HowToPlayFunnyOrNot from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayFunnyOrNot.vue';

export default defineComponent({
  name: 'AboutFunnyOrNot',
  components: {
    // NumberOfPlayersFunnyOrNot,
    HowToPlayFunnyOrNot
  },
  setup() {
    onMounted(() => {
      amplitudeEvent('previewGame', {
        game: 'funny_or_not',
        version: 'regular',
        eventTime: new Date(Date.now()).toISOString()
      });
    });
  }
});
