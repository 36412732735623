
import { groupWinnerGameTypes } from '@/utils/gameConstants';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LeaderboardDetailRankingTile',
  props: {
    gameName: {
      type: String,
      required: true
    },
    ranking: {
      type: Number,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    showChange: {
      type: Boolean,
      required: true
    }
  },
  emits: ['scroll'],
  setup(props) {
    const trophy =
      props.ranking === 1 ? 'gold' : props.ranking === 2 ? 'silver' : 'bronze';
    const changeText =
      props.data.change === undefined
        ? undefined
        : props.data.change === 0
        ? 'Same rank since last game'
        : props.data.change > 0
        ? `Moved up by ${props.data.change} ${
            props.data.change === 1 ? 'rank' : 'ranks'
          } since last game`
        : `Moved down by ${-props.data.change} ${
            props.data.change === -1 ? 'rank' : 'ranks'
          } since last game`;
    return {
      trophy,
      changeText,
      groupWinnerGameTypes
    };
  }
});
