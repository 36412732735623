
import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'VideoModal',
  emits: ['close'],
  props: {
    youtubeVideoId: {
      type: String,
      required: true
    }
  },
  setup(props, context) {
    const plyr = ref();

    const close = function() {
      context.emit('close');
    };

    const videoSrc = `https://www.youtube.com/embed/${props.youtubeVideoId}?iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1`;

    onMounted(() => {
      plyr.value.player.on('ready', () => {
        plyr.value.player.play();
      });
    });

    return {
      plyr,
      close,
      videoSrc
    };
  }
});
