export enum SubscriptionLevel {
  FREE = 'free', // newly added from Pricing v3.5 change
  FREE_TRIAL = 'free_trial', // newly added from Pricing v4 change
  BASIC = 'basic',
  BASIC_EDUCATOR = 'basic_educator',
  PREMIUM = 'premium', // not available since Pricing v3 change
  PREMIUM_EDUCATOR = 'premium_educator', // not available since Pricing v3 change
  PROFESSIONAL = 'professional',
  PROFESSIONAL_EDUCATOR = 'professional_educator',
  ENTERPRISE = 'enterprise',
  LITE = 'lite',
  PRO = 'pro',
  BRONZE = 'bronze',
  GOLD = 'gold',
  ONE_OFF_EVENT = 'one_off_event'
}
