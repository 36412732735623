
import { useStore } from '@/store';
import { computed, defineComponent } from 'vue';
import { SubscriptionLevel } from '@/models/SubscriptionLevel';

export default defineComponent({
  name: 'OrganizationSubscriptionSuccessfulModal',
  emits: ['close'],
  setup(_, context) {
    const store = useStore();
    const subscriptionDetails = computed(
      () => store.getters.getSubscriptionDetails
    );

    const close = function() {
      context.emit('close');
    };

    return {
      SubscriptionLevel,
      subscriptionDetails,
      close
    };
  }
});
