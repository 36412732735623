
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'ImageUploadingModal',
  components: {},
  props: {
    uploadingImageCount: {
      type: Number,
      required: true
    },
    uploadedImageCount: {
      type: Number,
      required: true
    }
  },
  emits: ['close'],
  setup(props, context) {
    const show = ref(true);
    const progress = ref(
      (props.uploadedImageCount * 100.0) / props.uploadingImageCount
    );

    const close = function() {
      show.value = false;
      setTimeout(() => {
        context.emit('close');
      }, 50);
    };

    watch(props, newValue => {
      progress.value =
        (newValue.uploadedImageCount * 100.0) / newValue.uploadingImageCount;
    });

    return {
      show,
      close,
      progress
    };
  }
});
