
import { defineComponent, onBeforeMount, ref } from 'vue';

import {
  handlePlaygroundSignIn,
  handleZoomOnSignedIn,
  handleZoomOnSignedOut,
  isDev,
  isPlayground,
  isProd,
  redirectToLogin,
  redirectToPlay
} from '@/utils/helper';
import firebase from 'firebase';
import { store } from '@/store';
import { UPDATE_USER } from '@/store/mutation-types';
import { initAmplitude } from '@/config/amplitudeConfig';
import BrightfulHeader from '@/components/General/BrightfulHeader.vue';
import BrightfulFooter from '@/components/General/BrightfulFooter.vue';
import AccountSettingsModal from '@/components/Modal/AccountSettingsModal.vue';
import MobileNavModal from '@/components/Modal/MobileNavModal.vue';
export default defineComponent({
  name: 'FeedbackMain',
  components: {
    BrightfulHeader,
    BrightfulFooter,
    AccountSettingsModal,
    MobileNavModal
  },
  emits: ['show-cancel-subscription', 'show-renew-subscription'],
  setup() {
    const playgroundDev: 'osho' | 'dag' | 'yugene' = 'yugene';
    const showAccountSettingsModal = ref(false);
    const showMobileNavModal = ref(false);
    const isLoading = ref(true);
    const showInitialLoading = ref(true);

    onBeforeMount(async () => {
      if (isPlayground) {
        handlePlaygroundSignIn(playgroundDev);
      }
    });

    async function handleSignedInUser(
      user: firebase.User,
      needRefreshToken: boolean
    ) {
      if (store.state.user === undefined) {
        store.commit(UPDATE_USER, {
          newUser: user
        });
      }
      needRefreshToken;
      await handleZoomOnSignedIn(store.state.zoomClientStatus);
      showInitialLoading.value = false;
      getAccessToken();
      // log after confirming user is logged in
      initAmplitude();
    }

    async function handleSignedOutUser() {
      if (store.state.zoomClientStatus?.inZoomClient) {
        await handleZoomOnSignedOut(store.state.zoomClientStatus);
      } else {
        if (isPlayground) {
          showInitialLoading.value = false;
          getAccessToken();
        } else if (isDev || isProd) {
          if (!showInitialLoading.value) {
            redirectToPlay('logout');
          } else {
            redirectToLogin('source', 'feedback');
          }
        }
      }
    }

    firebase.auth().onAuthStateChanged(_user => {
      if (_user === null || _user.isAnonymous) {
        handleSignedOutUser();
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const selectedAction = urlParams.get('selected');
        handleSignedInUser(_user, selectedAction == 'subscribed');
      }
    });

    const getAccessToken = async function() {
      isLoading.value = true;
      isLoading.value = false;
    };

    return {
      isLoading,
      showInitialLoading,
      showAccountSettingsModal,
      showMobileNavModal
    };
  }
});
