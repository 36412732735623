
import { computed, defineComponent, onBeforeMount, ref, watch } from 'vue';
import { Top5Question } from '@/models/Top5Question';

export default defineComponent({
  name: 'GeneralTop5InputItem',
  props: {
    id: {
      type: String,
      required: true
    },
    number: {
      type: Number,
      required: true
    },
    value: Top5Question || Object,
    checked: Boolean
  },
  emits: ['valuechanged', 'allfilled'],
  setup(props, context) {
    const checkedValue = ref(false);
    const question = ref('');
    const answers = ref([] as string[]);
    const type = ref('');

    const maxTop5InputLength = 140;

    const onValue = function(isCheckbox: Boolean) {
      context.emit('valuechanged', {
        checked: checkedValue.value,
        question: question.value,
        answers: answers.value,
        type: type.value,
        index: props.number
      });
      if (!isCheckbox) {
        if (
          question.value != '' &&
          answers.value.length >= 2 &&
          answers.value.findIndex((answer: string) => answer.trim() === '') ===
            -1
        ) {
          context.emit('allfilled');
        }
      }
    };
    const factId = computed(() => {
      return `fact_${props.number}`;
    });
    const surveyId = computed(() => {
      return `survey${props.number}`;
    });

    onBeforeMount(() => {
      checkedValue.value = props.checked ?? false;
      question.value = props.value?.question ?? '';
      answers.value = props.value?.answers ?? [];
      type.value = props.value?.type ?? 'fact';
    });

    watch(props, newValue => {
      checkedValue.value = newValue.checked ?? false;
      question.value = newValue.value?.question ?? '';
      answers.value = newValue.value?.answers ?? [];
      type.value = newValue.value?.type ?? 'fact';
    });

    return {
      checkedValue,
      question,
      answers,
      maxTop5InputLength,
      type,
      factId,
      surveyId,
      onValue
    };
  }
});
