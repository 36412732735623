
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'EnterpriseCardModal',
  emits: ['close'],
  setup(_, context) {
    const activeAccountTab = ref(25);
    const price = computed(() => {
      switch (activeAccountTab.value) {
        case 25:
          return '$10,500';
        case 50:
          return '$18,000';
        case 100:
          return '$34,800';
        case 200:
          return '$67,200';
        case 400:
          return '$129,600';
        default:
          return 'Error';
      }
    });

    const close = function() {
      context.emit('close');
    };

    return {
      activeAccountTab,
      price,
      close
    };
  }
});
