
import { defineComponent, onMounted } from 'vue';
import { getGameTitleByType } from '@/utils/gameConstants';
import { amplitudeEvent } from '@/config/amplitudeConfig';

export default defineComponent({
  name: 'GameComingSoonModal',
  emits: ['close'],
  props: {
    type: {
      type: String,
      required: true
    }
  },
  setup(props, context) {
    const close = function() {
      context.emit('close');
    };

    onMounted(() => {
      amplitudeEvent('openGameComingSoonModal', {
        game: props.type,
        eventTime: new Date(Date.now()).toISOString()
      });
    });

    return {
      close,
      getGameTitleByType
    };
  }
});
