
import { getGameTitleByType } from '@/utils/gameConstants';
import { shareFunnyOrNotQuestions, shareGameResult } from '@/utils/shareResult';
import { computed, defineComponent, ref } from 'vue';
import { useStore } from '@/store';
import { roundToOneDecimal } from '@/utils/helper';

export default defineComponent({
  name: 'HistoryModalFunnyOrNotComponent',
  components: {},
  props: {
    game: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const user = computed(() => store.getters.getUser);
    const title = getGameTitleByType(props.game.gameName);
    const customGameName = ref(props.game.customGameName);
    const customGameCss = ref(props.game.customGameCss);
    const dateString = ref(
      props.game.dateCreated.format('DD MMMM, YYYY - hh:mm A')
    );
    const hostName = ref(undefined as string | undefined);
    const isHostNotPlaying = ref(props.game.isHostPlaying === false);
    const rounds = ref([] as any[]);
    const totalScores = ref([] as any[]);

    function calculateResult() {
      if (props.game.host !== user.value.uid) {
        const host = props.game.players.find(
          player => player.playerId === props.game.host
        );
        if (host) {
          hostName.value = host.name;
        }
      }

      const actualPlayers = Object.assign(
        [],
        isHostNotPlaying.value
          ? props.game.players.filter(
              player => player.playerId !== props.game.host
            )
          : props.game.players
      );
      for (let i = 0; i < props.game.roundDetails.length; i++) {
        const detail = props.game.roundDetails[i];
        const round = {
          question: detail.question.question,
          battlers: [
            {
              name: '',
              votes: 0,
              answer: ''
            },
            {
              name: '',
              votes: 0,
              answer: ''
            }
          ]
        };
        actualPlayers.map(player => {
          if (player.roundDetails.length > i) {
            if (detail.battlers[0] === player.playerId) {
              round.battlers[0].name = player.name;
              round.battlers[0].answer = player.roundDetails[i].answer;
            } else if (detail.battlers[1] === player.playerId) {
              round.battlers[1].name = player.name;
              round.battlers[1].answer = player.roundDetails[i].answer;
            } else if (detail.battlers[0] === player.roundDetails[i].voteTo) {
              round.battlers[0].votes += 1;
            } else if (detail.battlers[1] === player.roundDetails[i].voteTo) {
              round.battlers[1].votes += 1;
            }
          }
        });
        rounds.value.push(round);
      }
      actualPlayers.map(player => {
        let score = 0;
        if (player.roundDetails) {
          player.roundDetails.map(detail => {
            score += detail.score ?? 0;
          });
        }
        totalScores.value.push({
          name: player.name,
          email: player.email,
          score: roundToOneDecimal(score)
        });
      });
      totalScores.value.sort((a, b) => b.score - a.score);
    }

    calculateResult();

    async function shareQuestions() {
      const data = {
        rounds: rounds.value,
        date:
          dateString.value +
          (hostName.value ? ` - Hosted by ${hostName.value}` : '')
      };
      const url = await shareFunnyOrNotQuestions(data);
      window.open(url, '_blank');
    }

    async function shareResult() {
      const data = {
        gameName: props.game.gameName,
        date:
          dateString.value +
          (hostName.value ? ` - Hosted by ${hostName.value}` : ''),
        scores: totalScores.value
      };
      const url = await shareGameResult(data);
      window.open(url, '_blank');
    }

    return {
      title,
      customGameName,
      customGameCss,
      hostName,
      isHostNotPlaying,
      dateString,
      rounds,
      totalScores,
      shareQuestions,
      shareResult
    };
  }
});
