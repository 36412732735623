
import { defineComponent, onMounted } from 'vue';
import { amplitudeEvent } from '@/config/amplitudeConfig';
// import NumberOfPlayersTrivia from '@/components/Modal/AboutComponent/NumberOfPlayersComponents/NumberOfPlayersTrivia.vue';
import HowToPlayTrivia from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayTrivia.vue';
import SampleQuestionTrivia from '@/components/Modal/AboutComponent/SampleQuestionComponents/SampleQuestionTrivia.vue';

export default defineComponent({
  name: 'AboutTrivia',
  components: {
    // NumberOfPlayersTrivia,
    HowToPlayTrivia,
    SampleQuestionTrivia
  },
  setup() {
    onMounted(() => {
      amplitudeEvent('previewGame', {
        game: 'trivia',
        version: 'regular',
        eventTime: new Date(Date.now()).toISOString()
      });
    });
  }
});
