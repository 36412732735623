
import { defineComponent } from 'vue';
import { redirectToLive } from '@/utils/helper';

export default defineComponent({
  name: 'WhiteboardDeletedModal',
  props: {
    deletedFromWhiteboard: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {
      redirectToLive
    };
  }
});
