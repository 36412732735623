
import { computed, defineComponent, ref } from 'vue';
// import BrightfulTestimonialPart2 from '@/components/Home/Special/BrightfulTestimonialPart2.vue';
// import ChristmasBanner from '@/components/Home/Special/ChristmasBanner.vue';
// import HalloweenBanner from '@/components/Home/Special/HalloweenBanner.vue';
// import ThanksgivingBanner from '@/components/Home/Special/ThanksgivingBanner.vue';
// import MeditationBanner from '@/components/Home/MeditationBanner.vue';
// import GameUpsellBanner from '@/components/Home/GameUpsellBanner.vue';
// import RecentlyPlayed from '@/components/Home/RecentlyPlayed.vue';
// import ChristmasSelectedView from '@/components/Home/Special/ChristmasSelectedView.vue';
// import HalloweenSelectedView from '@/components/Home/Special/HalloweenSelectedView.vue';
// import ThanksgivingSelectedView from '@/components/Home/Special/ThanksgivingSelectedView.vue';
import ActivityDetailsView from '@/components/Home/ActivityDetailsView.vue';
import EventToolsDetailsView from '@/components/Home/EventToolsDetailsView.vue';
import BrightfulActivities from '@/components/Home/BrightfulActivities.vue';
import BrightfulActivitiesTools from '@/components/Home/BrightfulActivitiesTools.vue';
import useFirebaseCollection from '@/hooks/firebase/useFirebaseCollection';
import router from '@/router';
import {
  customGameTypes,
  detailGameTypes,
  detailEventToolsTypes
} from '@/utils/gameConstants';
import { useStore } from '@/store';
import { NavigationTabs } from '@/models/navigationTabs';

export default defineComponent({
  name: 'HomeMain',
  emits: [
    // GamesOfTheDay + RegularGames
    'about',
    'view-details',
    'start',
    // CustomizedGames
    'create',
    // GamesOfTheDay + CustomizedGames
    'customized-game-start',
    // GamesOfTheDay
    'customized-game-more',
    // SelectGamesByCategory
    'video',
    'launch-event-tools-modal'
  ],
  props: {
    selectedType: {
      type: String
    }
  },
  components: {
    // BrightfulTestimonialPart2,
    // ChristmasBanner,
    // HalloweenBanner,
    // ThanksgivingBanner,
    // MeditationBanner,
    // GameUpsellBanner,
    // RecentlyPlayed,
    // ChristmasSelectedView,
    // HalloweenSelectedView,
    // ThanksgivingSelectedView,
    ActivityDetailsView,
    EventToolsDetailsView,
    BrightfulActivities,
    BrightfulActivitiesTools
  },
  setup(props, context) {
    const store = useStore();
    const userDoc = computed(() => store.getters.getUserDoc);
    const survey1Answer = computed(() => store.getters.getSurvey1Answer);
    const userTotalGameplays = computed(() => store.getters.getTotalGameplays);
    const userGameplayHistory = computed(
      () => store.getters.getUserGameplayHistoryCounts
    );
    // const userTestimonial = computed(() => store.getters.getUserTestimonial);
    const hasHistory = ref(userTotalGameplays.value !== 0);
    const has3OrMoreHistory = ref(userTotalGameplays.value >= 3);
    const hasGMHistory = computed(() => {
      return userGameplayHistory.value['guided_meditation'];
    });

    const showAllActivities = computed(() => {
      return router.currentRoute.value.query.show === 'all';
    });

    const filtered = computed(() => {
      if (!props.selectedType || !customGameTypes.includes(props.selectedType))
        return [];
      const rawGames = editorsPickGames.collectionData.value ?? [];
      return rawGames.filter(
        game => (game as any).gameName === props.selectedType
      );
    });

    const editorsPickGames =
      survey1Answer.value !== undefined
        ? useFirebaseCollection('minigame_custom_game_public', {
            onMounted: true,
            fieldPath: 'creatorSurvey1Answer',
            opStr: 'array-contains-any',
            value: survey1Answer.value,
            fieldPath2: 'isEditorsPick',
            opStr2: '==',
            value2: true,
            orderBy: 'dateCreated',
            isDesc: true,
            limit: 50
          })
        : useFirebaseCollection('minigame_custom_game_public', {
            onMounted: true,
            fieldPath: 'isEditorsPick',
            opStr: '==',
            value: true,
            orderBy: 'dateCreated',
            isDesc: true,
            limit: 50
          });

    function showAbout(type: string) {
      context.emit('about', type);
    }

    function startGame(event) {
      context.emit('start', event);
    }

    function viewDetails(event) {
      context.emit('view-details', event);
    }

    function showCreateCustomizedGame() {
      context.emit('create');
    }

    function showCustomizedGameExample(
      type: string,
      customGameName: string,
      customGameId: string
    ) {
      context.emit('customized-game-more', [
        type,
        customGameName,
        customGameId
      ]);
    }

    function startCustomizedGame(event) {
      context.emit('customized-game-start', event);
    }

    function showVideo(type: string) {
      context.emit('video', type);
    }

    function goToSelectedType(type: string) {
      router.push({
        query: { tab: NavigationTabs.HOME, type: type }
      });
    }

    function onEventToolsLaunchModal(event) {
      context.emit('launch-event-tools-modal', event);
    }

    return {
      userDoc,
      viewDetails,
      showAbout,
      startGame,
      showCreateCustomizedGame,
      startCustomizedGame,
      showCustomizedGameExample,
      showAllActivities,
      filtered,
      hasHistory,
      has3OrMoreHistory,
      hasGMHistory,
      survey1Answer,
      // showBrightfulTestimonialPart2,
      showVideo,
      goToSelectedType,
      detailGameTypes,
      detailEventToolsTypes,
      onEventToolsLaunchModal
    };
  }
});
