
import { computed, defineComponent, ref } from 'vue';
import {
  eventToolsHistoryDateFormat,
  eventToolsParticipantsWithoutHost
} from '@/utils/helper';
// import { shareLivePollResult } from '@/utils/shareResult';
import { store } from '@/store';
import {
  WheelActivityHistory,
  WheelResult
} from '@/models/event_tools/spin_the_wheel/spin_the_wheel_model';
import firebase from 'firebase';
import BounceLoading from '@/components/BounceLoading.vue';
export default defineComponent({
  name: 'WheelHistoryModal',
  props: {
    result: Object as () => WheelResult,
    game: Object as () => WheelActivityHistory,
    getResult: {
      type: Boolean,
      required: false
    }
  },

  emits: ['back'],
  setup(props, context) {
    function onBack() {
      context.emit('back');
    }
    const wheelResult = ref(props.result);
    const wheelEntries = computed(() => {
      return [
        ...new Set([
          ...wheelResult.value!.entries,
          ...(wheelResult.value!.selectedEntryForRound ?? [])
        ])
      ];
    });
    const userDoc = computed(() => store.getters.getUserDoc);
    async function fetchResult() {
      console.log(userDoc.value['defaultLobbyId']);
      console.log(props.game!.wheel?.id);
      console.log(props.game!.activityId);
      try {
        const snapshot = await firebase
          .firestore()
          .doc(
            `minigame_lobbies/${
              userDoc.value['defaultLobbyId']
            }/spin_the_wheel/${props.game!.wheel?.id}/results/${
              props.game!.activityId
            }`
          )
          .get();
        console.log(wheelResult.value);
        console.log(snapshot.data());
        if (snapshot.exists) {
          wheelResult.value = snapshot.data() as WheelResult;
        }
      } catch (err) {
        console.log(err);
      }
    }
    if (props.getResult) {
      console.log('fetching result');
      fetchResult();
    }

    const isShown = ref(false);
    const participants = computed(() => {
      if (wheelResult.value?.participants) {
        const actualParticipants = eventToolsParticipantsWithoutHost(
          wheelResult.value.participants
        );
        if (actualParticipants.length > 7 && !isShown.value) {
          return actualParticipants.slice(0, 7);
        }
        return actualParticipants;
      }
      return [];
    });
    const isEntriesShown = ref(false);
    const entries = computed(() => {
      if (wheelEntries.value) {
        if (wheelEntries.value.length > 7 && !isEntriesShown.value) {
          return wheelEntries.value.slice(0, 7);
        }
        return wheelEntries.value;
      }
      return [];
    });

    function onShowMore() {
      isShown.value = true;
    }
    function onEntriesShowMore() {
      isEntriesShown.value = true;
    }

    return {
      wheelResult,

      isShown,
      participants,
      isEntriesShown,
      entries,
      wheelEntries,
      eventToolsParticipantsWithoutHost,
      onShowMore,
      onEntriesShowMore,
      onBack,
      eventToolsHistoryDateFormat
    };
  },
  components: { BounceLoading }
});
