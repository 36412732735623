import { toRefs, reactive, onMounted } from 'vue';
import firebase from '@/firebaseApp';

export default function(
  collectionName: string,
  queryOptions: {
    onMounted?: boolean | undefined;
    fieldPath?: string | firebase.firestore.FieldPath | undefined;
    fieldPath2?: string | firebase.firestore.FieldPath | undefined;
    fieldPath3?: string | firebase.firestore.FieldPath | undefined;
    opStr?: firebase.firestore.WhereFilterOp | undefined;
    opStr2?: firebase.firestore.WhereFilterOp | undefined;
    opStr3?: firebase.firestore.WhereFilterOp | undefined;
    value?: any;
    value2?: any;
    value3?: any;
    orderBy?: string | undefined;
    isDesc?: boolean;
    limit?: number | undefined;
  },
  onSnapShot?: boolean | undefined
) {
  const state: {
    error: firebase.auth.Error | null;
    collectionData: any[] | null;
    loading: boolean;
  } = reactive({
    // error if one happens
    error: null,
    // the results of the query
    collectionData: [],
    // if the query is loading or ot
    loading: false
  });

  // get the database
  const db = firebase.firestore();

  /**
   * there is the option to load the query when the component
   * is mounted, you need to set the option in the `queryOptions`
   * params that you pass in
   *
   */
  if (queryOptions && queryOptions.onMounted)
    onMounted(() => {
      queryOptions && queryOptions.onMounted && getCollection(queryOptions);
    });

  const getCollection = async (queryOptions: {
    fieldPath?: string | firebase.firestore.FieldPath | undefined;
    fieldPath2?: string | firebase.firestore.FieldPath | undefined;
    fieldPath3?: string | firebase.firestore.FieldPath | undefined;
    opStr?: firebase.firestore.WhereFilterOp | undefined;
    opStr2?: firebase.firestore.WhereFilterOp | undefined;
    opStr3?: firebase.firestore.WhereFilterOp | undefined;
    value?: any;
    value2?: any;
    value3?: any;
    orderBy?: string | undefined;
    isDesc?: boolean;
    limit?: number | undefined;
  }) => {
    state.loading = true;
    state.error = null;

    let resultArray: {}[] = [];
    let theQuery =
      queryOptions.fieldPath && queryOptions.opStr && queryOptions.value
        ? db
            .collection(collectionName)
            .where(
              queryOptions.fieldPath,
              queryOptions.opStr,
              queryOptions.value
            )
        : db.collection(collectionName);
    theQuery =
      queryOptions.fieldPath2 && queryOptions.opStr2 && queryOptions.value2
        ? theQuery.where(
            queryOptions.fieldPath2,
            queryOptions.opStr2,
            queryOptions.value2
          )
        : theQuery;
    theQuery =
      queryOptions.fieldPath3 && queryOptions.opStr3 && queryOptions.value3
        ? theQuery.where(
            queryOptions.fieldPath3,
            queryOptions.opStr3,
            queryOptions.value3
          )
        : theQuery;
    theQuery = queryOptions.limit
      ? theQuery.limit(queryOptions.limit)
      : theQuery;
    theQuery = queryOptions.orderBy
      ? theQuery.orderBy(
          queryOptions.orderBy,
          queryOptions.isDesc ? 'desc' : 'asc'
        )
      : theQuery;

    try {
      if (!onSnapShot) {
        const querySnapshot = await theQuery.get();
        querySnapshot.forEach(doc => {
          resultArray.push({ id: doc.id, ...doc.data() });
        });
        state.collectionData = resultArray;
        state.error = null;
        return { data: resultArray };
      } else {
        theQuery.onSnapshot(snapshot => {
          resultArray = [];
          snapshot.docs.forEach(doc => {
            resultArray.push({ id: doc.id, ...doc.data() });
          });
          state.collectionData = resultArray;
          state.error = null;
        });
      }
    } catch (err) {
      console.log('Error getCollection: ', err);
      state.error = err as any;
      return { err };
    } finally {
      state.loading = false;
    }
  };

  return {
    ...toRefs(state),
    getCollection: getCollection
  };
}
