import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HistoryScoreGameTile = _resolveComponent("HistoryScoreGameTile")
  const _component_HistoryNoResultGameTile = _resolveComponent("HistoryNoResultGameTile")
  const _component_HistoryWerewolfTile = _resolveComponent("HistoryWerewolfTile")
  const _component_HistoryOneToHundredTile = _resolveComponent("HistoryOneToHundredTile")
  const _component_HistoryTwoWordsTile = _resolveComponent("HistoryTwoWordsTile")
  const _component_HistorySpotMyLieTile = _resolveComponent("HistorySpotMyLieTile")
  const _component_HistoryCardGameTile = _resolveComponent("HistoryCardGameTile")
  const _component_HistoryTop5Tile = _resolveComponent("HistoryTop5Tile")
  const _component_HistoryLivePollTile = _resolveComponent("HistoryLivePollTile")
  const _component_HistoryLiveQnaTile = _resolveComponent("HistoryLiveQnaTile")
  const _component_HistorySpinTheWheelTile = _resolveComponent("HistorySpinTheWheelTile")

  return (_ctx.scoreGameTypes.includes(_ctx.gameName))
    ? (_openBlock(), _createBlock(_component_HistoryScoreGameTile, {
        key: 0,
        game: _ctx.game,
        onOpen: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onOpenModal($event)))
      }, null, 8, ["game"]))
    : (_ctx.noResultGameTypes.includes(_ctx.gameName))
      ? (_openBlock(), _createBlock(_component_HistoryNoResultGameTile, {
          key: 1,
          game: _ctx.game,
          onOpen: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onOpenModal($event)))
        }, null, 8, ["game"]))
      : (_ctx.gameName === 'werewolf_game')
        ? (_openBlock(), _createBlock(_component_HistoryWerewolfTile, {
            key: 2,
            game: _ctx.game,
            onOpen: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onOpenModal($event)))
          }, null, 8, ["game"]))
        : (_ctx.gameName === 'one_to_hundred')
          ? (_openBlock(), _createBlock(_component_HistoryOneToHundredTile, {
              key: 3,
              game: _ctx.game,
              onOpen: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onOpenModal($event)))
            }, null, 8, ["game"]))
          : (_ctx.gameName === 'two_words_game')
            ? (_openBlock(), _createBlock(_component_HistoryTwoWordsTile, {
                key: 4,
                game: _ctx.game,
                onOpen: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onOpenModal($event)))
              }, null, 8, ["game"]))
            : (_ctx.gameName === 'spot_my_lie')
              ? (_openBlock(), _createBlock(_component_HistorySpotMyLieTile, {
                  key: 5,
                  game: _ctx.game,
                  onOpen: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onOpenModal($event)))
                }, null, 8, ["game"]))
              : (_ctx.cardGameTypes.includes(_ctx.gameName))
                ? (_openBlock(), _createBlock(_component_HistoryCardGameTile, {
                    key: 6,
                    game: _ctx.game,
                    onOpen: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onOpenModal($event)))
                  }, null, 8, ["game"]))
                : (_ctx.gameName === 'top5')
                  ? (_openBlock(), _createBlock(_component_HistoryTop5Tile, {
                      key: 7,
                      game: _ctx.game,
                      onOpen: _cache[8] || (_cache[8] = ($event: any) => (_ctx.onOpenModal($event)))
                    }, null, 8, ["game"]))
                  : (_ctx.isLivePoll(_ctx.gameName))
                    ? (_openBlock(), _createBlock(_component_HistoryLivePollTile, {
                        key: 8,
                        game: _ctx.game
                      }, null, 8, ["game"]))
                    : (_ctx.gameName == 'qna')
                      ? (_openBlock(), _createBlock(_component_HistoryLiveQnaTile, {
                          key: 9,
                          game: _ctx.game
                        }, null, 8, ["game"]))
                      : (_ctx.gameName == 'spin_the_wheel')
                        ? (_openBlock(), _createBlock(_component_HistorySpinTheWheelTile, {
                            key: 10,
                            game: _ctx.game
                          }, null, 8, ["game"]))
                        : _createCommentVNode("", true)
}