
import router from '@/router';
import { useStore } from '@/store';
import { computed, defineComponent } from 'vue';
import { questionBasedTypes } from '@/utils/gameConstants';
import { isFreePlan, isFreeTrialPlan } from '@/utils/pricing_rules';
import { firebaseModifiedSource } from '@/utils/helper';

export default defineComponent({
  name: 'GameVersionTile',
  emits: [],
  props: {
    game: {
      type: Object,
      required: true
    },
    checked: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const user = computed(() => store.getters.getUser);
    const subscriptionDetails = computed(
      () => store.getters.getSubscriptionDetails
    );
    const isMine = props.game.created_by === user.value.uid;
    const notCustomGameAccess = computed(
      () =>
        !isMine &&
        (isFreeTrialPlan(subscriptionDetails.value) ||
          isFreePlan(subscriptionDetails.value))
    );
    const suffix = questionBasedTypes.includes(props.game.gameName)
      ? 'questions'
      : 'words';
    function goToPricing() {
      router.push({ name: 'Pricing', query: { source: 'version' } });
    }
    return {
      suffix,
      isMine,
      notCustomGameAccess,
      firebaseModifiedSource,
      goToPricing
    };
  }
});
