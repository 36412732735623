
import useFirebaseCollection from '@/hooks/firebase/useFirebaseCollection';
import { defineComponent } from 'vue';
import BounceLoading from '@/components/BounceLoading.vue';
import PollHost from '@/components/Modal/WhiteboardComponent/PollComponents/PollHost.vue';
import PollParticipant from '@/components/Modal/WhiteboardComponent/PollComponents/PollParticipant.vue';
export default defineComponent({
  name: 'PollModal',
  components: {
    BounceLoading,
    PollHost,
    PollParticipant
  },
  props: {
    whiteboardId: {
      type: String,
      required: true
    },
    isHost: {
      type: Boolean,
      required: true
    },
    numParticipants: {
      type: Number,
      required: true
    }
  },
  emits: ['close', 'hasVoted', 'hasNotVoted'],
  setup(props, context) {
    const pollsListener = useFirebaseCollection(
      `whiteboard_data/${props.whiteboardId}/polls`,
      {
        onMounted: true
      },
      true
    );

    const close = function() {
      context.emit('close');
    };

    const hasVoted = function() {
      context.emit('hasVoted');
    };

    const hasNotVoted = function() {
      context.emit('hasNotVoted');
    };

    return { ...pollsListener, close, hasVoted, hasNotVoted };
  }
});
