
import { LivePollResult } from '@/models/event_tools/live_poll/live_poll_model';

import { computed, defineComponent, ref } from 'vue';
import {
  eventToolsHistoryDateFormat,
  eventToolsParticipantsWithoutHost
} from '@/utils/helper';
import MultipleChoiceHistoryDetail from './components/MultipleChoiceHistoryDetail.vue';
import RatingHistoryDetail from './components/RatingHistoryDetail.vue';
import ShortAnswerHistoryDetail from './components/ShortAnswerHistoryDetail.vue';
import WordCloudHistoryDetail from './components/WordCloudHistoryDetail.vue';
import { LivePollType } from '@/utils/live_poll_enums';
import { shareLivePollResult } from '@/utils/shareResult';
import { store } from '@/store';
import firebase from 'firebase';
import { LivePollActivityHistory } from '@/models/event_tools/live_poll/live_poll_model';
export default defineComponent({
  name: 'LivePollHistoryModal',
  props: {
    result: Object as () => LivePollResult,
    game: Object as () => LivePollActivityHistory,
    getResult: {
      type: Boolean,
      required: false
    }
  },
  emits: ['back'],
  setup(props, context) {
    const lobbyDoc = computed(() => store.getters.getLobbyDoc);
    const wordCloudImage = ref(null);
    const pollResult = ref(props.result);
    function onBack() {
      context.emit('back');
    }
    const userDoc = computed(() => store.getters.getUserDoc);
    const isShown = ref(false);
    const participants = computed(() => {
      if (pollResult.value?.participants) {
        const actualParticipants = eventToolsParticipantsWithoutHost(
          pollResult.value?.participants
        );
        if (actualParticipants.length > 7 && !isShown.value) {
          return actualParticipants.slice(0, 7);
        }
        return actualParticipants;
      }
      return [];
    });

    function onShowMore() {
      isShown.value = true;
    }
    async function fetchResult() {
      try {
        const snapshot = await firebase
          .firestore()
          .doc(
            `minigame_lobbies/${userDoc.value['defaultLobbyId']}/live_poll/${
              props.game!.poll?.id
            }/results/${props.game!.activityId}`
          )
          .get();
        if (snapshot.exists) {
          pollResult.value = snapshot.data() as LivePollResult;
        }
      } catch (err) {
        console.log(err);
      }
    }
    if (props.getResult) {
      console.log('fetching result');
      fetchResult();
    }
    async function onShare() {
      const url = await shareLivePollResult(
        lobbyDoc.value,
        pollResult.value!,
        wordCloudImage.value
      );
      console.log(url);
      window.open(url, '_blank');
    }
    return {
      wordCloudImage,
      LivePollType,
      pollResult,
      isShown,
      participants,
      eventToolsParticipantsWithoutHost,
      onShowMore,
      onShare,
      onBack,
      eventToolsHistoryDateFormat
    };
  },
  components: {
    MultipleChoiceHistoryDetail,
    RatingHistoryDetail,
    ShortAnswerHistoryDetail,
    WordCloudHistoryDetail
  }
});
