
import { defineComponent, PropType } from 'vue';

import { QnaQuestion } from '@/models/event_tools/qna/qna';
export default defineComponent({
  name: 'QnaQuestionsList',
  // components: { BounceLoading },
  props: {
    questions: { type: Array as PropType<Array<QnaQuestion>>, required: true }
  },
  setup(props, context) {
    return {};
  }
});
