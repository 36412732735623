
import usePostAPI from '@/hooks/usePostAPI';
import { store } from '@/store';
import {
  configureGameRoomButton,
  getEndpoint,
  redirectToGameRoom
} from '@/utils/helper';
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'ActiveRoomModal',

  props: { showIcon: { type: Boolean, required: true } },
  setup() {
    const activeRoomCollapsed = ref(false);
    const activeGameRoomId = computed(() => store.getters.activeGameRoomId);
    const activeGameName = computed(() => store.getters.activeGameName);
    const activeGameType = computed(() => {
      const lobby = store.getters.getLobbyDoc ?? {};
      return lobby['gameName'] ?? 'draw_it';
    });
    const numOfPlayers = computed(() => {
      const lobby = store.getters.getLobbyDoc ?? {};
      const playerIds: Array<string> = lobby['playerIds'] ?? [];
      return playerIds.length;
    });
    const userDoc = computed(() => store.getters.getUserDoc);
    const defaultLobbyId = computed(() => {
      return userDoc.value['defaultLobbyId'];
    });
    const isUserUsingCustomGameRoomId = computed(
      () => store.getters.isUserUsingCustomGameRoomId
    );
    const isLoading = ref(false);

    function onGameRoomClose() {}
    function onGameRoomLink() {
      return redirectToGameRoom(
        activeGameRoomId.value ?? defaultLobbyId.value,
        isUserUsingCustomGameRoomId.value
      );
    }
    async function onEndActivity() {
      isLoading.value = true;
      await usePostAPI(getEndpoint('endActivity'), {});
      await configureGameRoomButton(defaultLobbyId.value);
      isLoading.value = false;
    }

    return {
      activeRoomCollapsed,
      activeGameName,
      activeGameType,
      numOfPlayers,
      isLoading,
      onEndActivity,
      onGameRoomLink,
      onGameRoomClose
    };
  }
});
