
import { computed, defineComponent, ref } from 'vue';
import CryptoJS from 'crypto-js';
import { getEndpoint } from '@/utils/helper';
import { amplitudeEvent } from '@/config/amplitudeConfig';
import usePostAPI from '@/hooks/usePostAPI';

export default defineComponent({
  name: 'LoginAsHostModal',
  emits: ['close'],
  props: {
    whiteboardId: {
      type: String,
      required: true
    },
    whiteboardPassword: {
      type: String,
      required: true
    }
  },
  setup(props, context) {
    const password = ref('');
    const passwordEye = ref(false);
    const isLoading = ref(false);
    const errorMessage = ref('');

    const showPasswordEye = computed(() => {
      return password.value.trim().length > 0;
    });

    const confirmButtonUpdate = computed(() => {
      return password.value.trim().length >= 4;
    });

    async function confirmLoginAsHost() {
      isLoading.value = true;
      const hashedPassword = CryptoJS.SHA3(password.value).toString();
      if (props.whiteboardPassword !== hashedPassword) {
        isLoading.value = false;
        errorMessage.value = 'Wrong password. Please try again.';
        amplitudeEvent('incorrectPassword', {
          whiteboardId: props.whiteboardId
        });
        return;
      }
      try {
        const encryptedPassword = CryptoJS.AES.encrypt(
          password.value,
          props.whiteboardId
        ).toString();
        const response = await usePostAPI(
          getEndpoint('loginAsHost', '/api/whiteboard/'),
          {
            whiteboardId: props.whiteboardId,
            password: encryptedPassword
          }
        );
        const result = response.data;
        const whiteboardId = result['whiteboardId'];
        if (response.status === 200 && whiteboardId === props.whiteboardId) {
          amplitudeEvent('switchHost', {
            whiteboardId: whiteboardId
          });
          context.emit('close');
        }
      } catch (err) {
        console.log(err);
        isLoading.value = false;
        errorMessage.value =
          'An unexpected error has occured. Please try again later or contact support@brightful.me for more info.';
      }
    }

    return {
      password,
      passwordEye,
      showPasswordEye,
      isLoading,
      errorMessage,
      confirmButtonUpdate,
      confirmLoginAsHost
    };
  }
});
