import { createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "modal-content" }
const _hoisted_2 = { class: "modal-header" }
const _hoisted_3 = { class: "modal-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HistoryModalDrawItComponent = _resolveComponent("HistoryModalDrawItComponent")
  const _component_HistoryModalWouldYouRatherComponent = _resolveComponent("HistoryModalWouldYouRatherComponent")
  const _component_HistoryModalPollComponent = _resolveComponent("HistoryModalPollComponent")
  const _component_HistoryModalScoreboardComponent = _resolveComponent("HistoryModalScoreboardComponent")
  const _component_HistoryModalFunnyOrNotComponent = _resolveComponent("HistoryModalFunnyOrNotComponent")
  const _component_HistoryModalWerewolfComponent = _resolveComponent("HistoryModalWerewolfComponent")
  const _component_HistoryModalQOfTheDayComponent = _resolveComponent("HistoryModalQOfTheDayComponent")
  const _component_HistoryModalDrawTellComponent = _resolveComponent("HistoryModalDrawTellComponent")
  const _component_HistoryModalOneToHundredComponent = _resolveComponent("HistoryModalOneToHundredComponent")
  const _component_HistoryModalTwoWordsComponent = _resolveComponent("HistoryModalTwoWordsComponent")
  const _component_HistoryModalGuidedMeditationComponent = _resolveComponent("HistoryModalGuidedMeditationComponent")
  const _component_HistoryModalCardGameComponent = _resolveComponent("HistoryModalCardGameComponent")
  const _component_HistoryModalSpotMyLieComponent = _resolveComponent("HistoryModalSpotMyLieComponent")
  const _component_HistoryModalTop5Component = _resolveComponent("HistoryModalTop5Component")

  return (_openBlock(), _createBlock("div", {
    class: "modal-right",
    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.close()))
  }, [
    _createVNode(_Transition, {
      appear: "",
      name: "slide"
    }, {
      default: _withCtx(() => [
        (_ctx.show)
          ? (_openBlock(), _createBlock("div", {
              key: 0,
              class: "modal-dialog",
              onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
            }, [
              _createVNode("div", _hoisted_1, [
                _createVNode("div", _hoisted_2, [
                  _createVNode("button", {
                    type: "button",
                    class: "btn-close-left",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.close()))
                  }, [
                    _createVNode("img", {
                      src: require('@/assets/img/close.png'),
                      alt: "close modal"
                    }, null, 8, ["src"])
                  ])
                ]),
                _createVNode("div", _hoisted_3, [
                  (_ctx.game.gameName == 'draw_it')
                    ? (_openBlock(), _createBlock(_component_HistoryModalDrawItComponent, {
                        key: 0,
                        game: _ctx.game
                      }, null, 8, ["game"]))
                    : _createCommentVNode("", true),
                  (_ctx.game.gameName == 'would_you_rather')
                    ? (_openBlock(), _createBlock(_component_HistoryModalWouldYouRatherComponent, {
                        key: 1,
                        game: _ctx.game
                      }, null, 8, ["game"]))
                    : _createCommentVNode("", true),
                  (_ctx.game.gameName == 'poll')
                    ? (_openBlock(), _createBlock(_component_HistoryModalPollComponent, {
                        key: 2,
                        game: _ctx.game
                      }, null, 8, ["game"]))
                    : _createCommentVNode("", true),
                  (_ctx.scoreboardGameTypes.includes(_ctx.game.gameName))
                    ? (_openBlock(), _createBlock(_component_HistoryModalScoreboardComponent, {
                        key: 3,
                        game: _ctx.game
                      }, null, 8, ["game"]))
                    : _createCommentVNode("", true),
                  (_ctx.game.gameName == 'funny_or_not')
                    ? (_openBlock(), _createBlock(_component_HistoryModalFunnyOrNotComponent, {
                        key: 4,
                        game: _ctx.game
                      }, null, 8, ["game"]))
                    : _createCommentVNode("", true),
                  (_ctx.game.gameName == 'werewolf_game')
                    ? (_openBlock(), _createBlock(_component_HistoryModalWerewolfComponent, {
                        key: 5,
                        game: _ctx.game
                      }, null, 8, ["game"]))
                    : _createCommentVNode("", true),
                  (_ctx.game.gameName == 'question_of_the_day')
                    ? (_openBlock(), _createBlock(_component_HistoryModalQOfTheDayComponent, {
                        key: 6,
                        game: _ctx.game
                      }, null, 8, ["game"]))
                    : _createCommentVNode("", true),
                  (_ctx.game.gameName == 'draw_tell')
                    ? (_openBlock(), _createBlock(_component_HistoryModalDrawTellComponent, {
                        key: 7,
                        game: _ctx.game
                      }, null, 8, ["game"]))
                    : _createCommentVNode("", true),
                  (_ctx.game.gameName == 'one_to_hundred')
                    ? (_openBlock(), _createBlock(_component_HistoryModalOneToHundredComponent, {
                        key: 8,
                        game: _ctx.game
                      }, null, 8, ["game"]))
                    : _createCommentVNode("", true),
                  (_ctx.game.gameName == 'two_words_game')
                    ? (_openBlock(), _createBlock(_component_HistoryModalTwoWordsComponent, {
                        key: 9,
                        game: _ctx.game
                      }, null, 8, ["game"]))
                    : _createCommentVNode("", true),
                  (_ctx.game.gameName == 'guided_meditation')
                    ? (_openBlock(), _createBlock(_component_HistoryModalGuidedMeditationComponent, {
                        key: 10,
                        game: _ctx.game
                      }, null, 8, ["game"]))
                    : _createCommentVNode("", true),
                  (_ctx.cardGameTypes.includes(_ctx.game.gameName))
                    ? (_openBlock(), _createBlock(_component_HistoryModalCardGameComponent, {
                        key: 11,
                        game: _ctx.game
                      }, null, 8, ["game"]))
                    : _createCommentVNode("", true),
                  (_ctx.game.gameName == 'spot_my_lie')
                    ? (_openBlock(), _createBlock(_component_HistoryModalSpotMyLieComponent, {
                        key: 12,
                        game: _ctx.game
                      }, null, 8, ["game"]))
                    : _createCommentVNode("", true),
                  (_ctx.game.gameName == 'top5')
                    ? (_openBlock(), _createBlock(_component_HistoryModalTop5Component, {
                        key: 13,
                        game: _ctx.game
                      }, null, 8, ["game"]))
                    : _createCommentVNode("", true)
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}