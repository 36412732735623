import { getGameIconsByType, getGameTitleByType } from './gameConstants';
import axios from 'axios';
import { isProd, eventToolsHistoryDateFormat } from './helper';
import {
  LivePollResult,
  MultipleChoiceLivePoll,
  RatingLivePoll,
  ShortAnswerLivePoll,
  WordCloudLivePoll
} from '@/models/event_tools/live_poll/live_poll_model';
import { LivePollType } from './live_poll_enums';
import {
  multipleChoicePollCss,
  ratingPollCss,
  shortAnswerPollCss,
  wordCloudPollCss
} from './live_poll/share_result_css';

export const htmlCssToImageApiId = isProd
  ? 'e58298d2-96ea-4b37-b5fe-4225004aaeea'
  : 'b0a16b3a-873d-461d-a0d7-c4c7e68dbbc1';
export const htmlCssToImageApiKey = isProd
  ? '9c24a955-3e8b-4ec8-ab79-9bffc1826044'
  : 'f17474d9-f3d4-4715-9fd8-5fc383b6e316';

export async function generateImageFromHtmlCss(html, css) {
  try {
    const headers = {
      auth: {
        username: htmlCssToImageApiId,
        password: htmlCssToImageApiKey
      },
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const htmlCssToImageResponse = await axios.post(
      'https://hcti.io/v1/image',
      JSON.stringify({ html: html, css: css }),
      headers
    );
    return htmlCssToImageResponse.data.url;
  } catch (e) {
    console.log((e as any).toString());
  }
  return '';
}

const scoreCss = `
  .social-image {
    background: linear-gradient(180deg, #2E3062 0%, #4A4C93 100%);
    width: 600px;
    padding: 24px;
    margin: auto;
    font-family: 'gilroy__boldbold';
  }

  .color-blue {
    color: #1C9DEE;
  }

  .wrapper {
    border: 2px solid #000000;
    color: #fff;
    padding: 24px;
    text-align: center;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Fbg-dot.png?alt=media&token=a061b0e8-d7f9-44cc-a05a-4fe6c3000a72");
    background-repeat: no-repeat;
    background-position: bottom;
  }

  .footer {
    text-align: left;
  }

  .footer>img {
    height: 40px;
    display: inline-block;
    /* float: left; */
  }

  .footer>p {
    display: inline-block;
    margin-top: 24px;
    /* height: 40px; */
    float: right;
  }

  .color-orange {
    color: #FC7028;
  }

  .social-image h1 {
    text-align: center;
  }

  .social-image h1 small{
    font-family: 'gilroy_regularregular';
    display: block;
    font-size: 16px;
    opacity: 0.5
  }

  table {
    width: 100%;
    /* border: 1px solid #ccc; */
    border-spacing: 0px;
    font-family: 'gilroy_regularregular';
    margin-bottom: 24px;
  }

  table tr:nth-child(even) {
    background: rgba(255, 255, 255, 0.1);
  }
  table tr:nth-child(odd) {
    background: rgba(255, 255, 255, 0.05);
  }

  table tr td {
    border-width: 0 1px;
    padding-top: 8px;
    padding-bottom: 10px;
  }

  table tr td:first-child {
    width: 40px;
    background: rgba(255, 255, 255, 0.1)
  }

  table tr td:last-child {
    text-align: left;
    padding-left: 12px;
  }

  table tr td:last-child strong {
    margin-left: auto;
    font-family: 'gilroy__boldbold';
    color: #32C270;
    float: right;
    margin-right: 12px;
  }

  .bt-1 {
      border-top: 1px solid #ccc;
  }
  .bl-1 {
    border-left: 1px solid #ccc;
  }
  .br-1 {
    border-right: 1px solid #ccc;
  }
`;

export async function shareGameResult(result) {
  const gameName = result.gameName;
  const gameTitle = getGameTitleByType(gameName);
  const gameIcon = getGameIconsByType(gameName);
  const date = result.date;

  let tableString = '';
  for (let i = 0; i < result.scores.length; i++) {
    tableString += `
      <tr>
        <td>${
          i == 0
            ? '<img style="margin-bottom: -5px;" src="https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/common%2Fgold.svg?alt=media&token=e217c86b-b371-40c3-bc38-d9aaff29eafe" alt="">'
            : i + 1
        }        
        </td>
        <td>
          ${result.scores[i].name}
          <strong>${result.scores[i].score}</strong>
        </td>
      </tr>
    `;
  }

  const html = `
    <div class="social-image" style=" width: 600px; overflow:hidden; ">
      <div class="wrapper">
          <h1>
            <img src="${gameIcon}" style="margin: auto; height: 120px; display: block;">
            ${gameTitle}
            <small>${date}</small>
          </h1>

          <table cellpas>
            ${tableString}
          </table>

          <div class="footer">
              <img src="https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Flogo_new.png?alt=media&token=f591fc72-e9a1-4c9c-b93f-5dff5224d449" alt="">
              <p>
                  Play at: <a class="color-orange">www.brightful.me</a>
              </p>
          </div>
      </div>
    </div>
    <link rel="stylesheet"
      href="https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Ffonts%2Fstylesheet.css?alt=media&token=6444781f-6f0d-4ded-bb41-e237e9ecad20">
  `;

  return generateImageFromHtmlCss(html, scoreCss);
}

const drawItCss = `
	.social-image {
		background: linear-gradient(180deg, #2E3062 0%, #4A4C93 100%);
		width: 600px;
		padding: 24px;
		margin: auto;
		font-family: 'gilroy__boldbold';
	}

	.color-blue {
		color: #1C9DEE;
	}

	.wrapper {
		border: 2px solid #000000;
		color: #fff;
		padding: 24px;
		text-align: center;
		background-image: url("https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Fbg-dot.png?alt=media&token=a061b0e8-d7f9-44cc-a05a-4fe6c3000a72");
		background-repeat: no-repeat;
		background-position: bottom;
	}

  span {
    text-align: center;
    margin-bottom: 24px;
  }

  span small{
    font-family: 'gilroy_regularregular';
    display: block;
    font-size: 16px;
    opacity: 0.5
  }

	.footer {
		text-align: left;
	}

	.footer>img {
		height: 40px;
		display: inline-block;
		/* float: left; */
	}

	.footer>p {
		display: inline-block;
		margin-top: 24px;
		/* height: 40px; */
		float: right;
	}

	.color-orange {
		color: #FC7028;
	}

	table {
		margin-bottom: 36px;
		color: #fff;
	}

	table tr td {
		text-align: center;
		padding-bottom: 24px;
	}

	table tr td img{
		border-radius: 10px;
		background-color: white;
	}

	table tr td .img {
		border-radius: 10px;
		width: 164px;
		height: 164px;
		background-color: #fff;
		position: relative;
		z-index: 1;
	}

	table tr td .img .grade {
    width:30px;
    position: absolute;
    top: -3px;
    left: -3px;
    background: transparent;
	}

	table tr td h4{
		margin: 0;
		padding-bottom: 6px;
	}

	table tr td .content {
		background-color: #2E3062;
		padding-top: 20px;
		padding-bottom: 8px;
		margin-top: -8px;
		color: #FC7028;
	}

  table tr td .content small {
    display: block;
    color: #B4B4B4;
    font-size: 12px;
    font-weight: 100;
	  font-family: 'gilroy_regularregular';
  }
`;

export async function shareDrawItImages(result) {
  const numberOfRounds = result.drawings.length;
  const date = result.date;
  let tableString: string = '';
  const colCount: number = Math.ceil(numberOfRounds / 3);

  for (let rowIndex = 0; rowIndex < colCount; rowIndex++) {
    let tableCol: string = '';
    for (
      let colIndex = 0;
      colIndex < Math.min(3, numberOfRounds - rowIndex * 3);
      colIndex++
    ) {
      const drawing = result.drawings[rowIndex * 3 + colIndex];
      tableCol += `
        <td>
          <h4>${drawing.name}</h4>
          <div class="img">
            <img src="${
              drawing.url
                ? drawing.url
                : 'https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/common%2Fwhite.png?alt=media&token=1e3f7937-b2c8-4008-a4e5-fa8af6611a02'
            }" alt="" width=164 height=164 style="background-color: white;">
          </div>
          <div class="content">
            ${drawing.word}
            <small>${drawing.votes} ${
        drawing.votes === 1 ? 'vote' : 'votes'
      }</small>
          </div>
		    </td>
			`;
    }
    tableString += `
      <tr>
        ${tableCol}
      </tr>
    `;
  }

  const html = `
    <div class="social-image" style=" width: 600px; overflow:hidden; ">
      <div class="wrapper">
        <span>
          <img src="https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Fdraw-it-header-new.png?alt=media&token=4b0546bf-90d1-4fcb-a245-8c93b2820b8e" alt="" width=194>
          <small>${date}</small>
        </span>
        <table style="margin: auto;">
          ${tableString}
        </table>
        <div class="footer">
          <img src="https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Flogo_new.png?alt=media&token=f591fc72-e9a1-4c9c-b93f-5dff5224d449" alt="">
          <p>
            Play at: <a class="color-orange">www.brightful.me</a>
          </p>
        </div>
      </div>
    </div>
    <link rel="stylesheet"
      href="https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Ffonts%2Fstylesheet.css?alt=media&token=6444781f-6f0d-4ded-bb41-e237e9ecad20">
  `;

  return generateImageFromHtmlCss(html, drawItCss);
}

export async function shareDrawTellImages(result) {
  const numberOfRounds = result.drawings.length;
  const date = result.date;
  let tableString: string = '';
  const colCount: number = Math.ceil(numberOfRounds / 3);

  for (let rowIndex = 0; rowIndex < colCount; rowIndex++) {
    let tableCol: string = '';
    for (
      let colIndex = 0;
      colIndex < Math.min(3, numberOfRounds - rowIndex * 3);
      colIndex++
    ) {
      const drawing = result.drawings[rowIndex * 3 + colIndex];
      tableCol += `
        <td>
          <h4>${drawing.name}</h4>
          <img src="${drawing.url}" alt="" width=164 height=164 style="background-color: white;">
        </td>
      `;
    }
    tableString += `
      <tr>
        ${tableCol}
      </tr>
    `;
  }

  const html = `
    <div class="social-image" style=" width: 600px; overflow:hidden; ">
      <div class="wrapper">
        <span>
          <img src="https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Fdraw-tell-header-new.png?alt=media&token=e30d0032-96d7-4277-8efd-37968567241c" alt="" width=194>
          <small>${date}</small>
        </span>
        <h3>Topic: <strong class="color-blue">${result.topic}</strong></h3>
        <table style="margin: auto;">
          ${tableString}
        </table>
        <div class="footer">
          <img src="https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Flogo_new.png?alt=media&token=f591fc72-e9a1-4c9c-b93f-5dff5224d449" alt="">
          <p>
              Play at: <a class="color-orange">www.brightful.me</a>
          </p>
        </div>
      </div>
    </div>
    <link rel="stylesheet"
        href="https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Ffonts%2Fstylesheet.css?alt=media&token=6444781f-6f0d-4ded-bb41-e237e9ecad20">
  `;

  return generateImageFromHtmlCss(html, drawItCss);
}

const funnyOrNotCss = `
  .social-image {
    background: linear-gradient(180deg, #2e3062 0%, #4a4c93 100%);
    width: 600px;
    padding: 24px;
    margin: auto;
    font-family: 'gilroy__boldbold';
  }
  
  .logo {
    margin: auto;
    display: block;
  }

  span {
    text-align: center;
  }

  span small{
    font-family: 'gilroy_regularregular';
    display: block;
    font-size: 16px;
    opacity: 0.5
  }
  
  .color-blue {
    color: #1c9dee;
  }
  
  .wrapper {
    border: 2px solid #000000;
    color: #fff;
    padding: 24px;
    text-align: left;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Fbg-dot.png?alt=media&token=a061b0e8-d7f9-44cc-a05a-4fe6c3000a72");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
  }
  
  .footer {
    text-align: left;
  }
  
  .footer > img {
    height: 40px;
    display: inline-block;
    /* float: left; */
  }
  
  .footer > p {
    display: inline-block;
    margin-top: 24px;
    /* height: 40px; */
    float: right;
  }
  
  .color-orange {
    color: #fc7028;
  }
  
  table {
    background-color: #21234E;
    margin-bottom: 24px;
    color: #fff;
    border-spacing: 0;
    padding: 12px;
    line-height: 130%
  }

  table tbody { 
    min-width: 100%;
    display: table;
  }

  table tr td .img .grade {
    width: 30px;
    position: absolute;
    top: -3px;
    left: -3px;
    background: transparent;
  }
  
  table tr td h4 {
    margin: 0;
    padding-bottom: 6px;
  }
  
  table tr td .content {
    background-color: #2e3062;
    padding-top: 20px;
    padding-bottom: 8px;
    margin-top: -8px;
    color: #fc7028;
  }
  
  table tr td .content small {
    display: block;
    color: #b4b4b4;
    font-size: 12px;
    font-weight: 100;
    font-family: 'gilroy_regularregular';
  }
  
  .the-answer {
    width: 100%;
    text-align: left;
  }
  
  .the-answer .head .name {
    margin-right: auto;
    width: auto;
    display: inline-block;
    padding: 3px 8px;
    font-size: 12px;
    margin-bottom: 8px;
  }
  
  .the-answer .head .vote {
    float: right;
    display: inline-block;
    font-size: 12px;
    font-family: 'gilroy_regularregular';
  }
  
  .the-answer .content {
    color: #fff;
    font-family: 'gilroy_regularregular';
    padding: 12px;
  }
  
  .the-answer.color-1 .content {
    background: rgba(32, 165, 89, 0.2);
  }
  
  .the-answer.color-1 .head .name {
    background: #20a559;
  }
  
  .the-answer.color-2 .content {
    background: rgba(45, 144, 161, 0.2);
  }
  
  .the-answer.color-2 .head .name {
    background: #2d90a1;
  }  
`;

export async function shareFunnyOrNotQuestions(result) {
  const date = result.date;
  let tableRow: string = '';

  for (let index = 0; index < result.rounds.length; index++) {
    const round = result.rounds[index];
    tableRow = tableRow.concat(`
      <table style="display: inline-block; margin-right: 10px; vertical-align: top" width="48%">
        <tr>
          <td align="center" colspan="2" style="text-align: center; vertical-align: middle; padding-bottom: 12px;">
            ${round.question}
          </td>
        </tr>
        <tr>
          <td colspan="2" style="vertical-align: top; padding-bottom: 12px;">
            <div class="the-answer color-1">
              <div class="head">
                <div class="name">
                  ${round.battlers[0].name}
                </div>
                <div class="vote">
                  ${round.battlers[0].votes} ${
      round.battlers[0].votes === 1 ? 'vote' : 'votes'
    }
                </div>
              </div>
              <div class="content bg-1">
                ${round.battlers[0].answer}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <div class="the-answer color-2">
              <div class="head">
                <div class="name">
                  ${round.battlers[1].name}
                </div>
                <div class="vote">
                  ${round.battlers[1].votes} ${
      round.battlers[1].votes === 1 ? 'vote' : 'votes'
    }
                </div>
              </div>
              <div class="content bg-1">
                ${round.battlers[1].answer}
              </div>
            </div>
          </td>
        </tr>
      </table>
    `);
  }

  const html = `
    <div class="social-image" style=" width: 800px; overflow:hidden; ">
      <div class="wrapper">
        <span>
          <img class="logo" src="https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Ffunny-or-not-header.png?alt=media&token=4f6d83d2-c108-400d-99f1-843e9fe827a3" alt="" width=280>
          <small>${date}</small>
        </span>
        <div style="height: 24px;"></div>
        ${tableRow}
        <div class="footer">
          <img src="https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Flogo_new.png?alt=media&token=f591fc72-e9a1-4c9c-b93f-5dff5224d449" alt="">
          <p>
            Play at: <a class="color-orange">www.brightful.me</a>
          </p>
        </div>
      </div>
    </div>
    <link rel="stylesheet"
        href="https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Ffonts%2Fstylesheet.css?alt=media&token=6444781f-6f0d-4ded-bb41-e237e9ecad20">
  `;

  return generateImageFromHtmlCss(html, funnyOrNotCss);
}

const top5Css = `
  .social-image {
    background: linear-gradient(180deg, #2e3062 0%, #4a4c93 100%);
    width: 900px;
    padding: 24px;
    margin: auto;
    font-family: 'gilroy__boldbold';
  }

  .bold {
    font-family: 'gilroy__boldbold';
  }

  .text-center {
    text-align: center;
  }

  .color-blue {
    color: #1c9dee;
  }

  .wrapper {
    border: 2px solid #000000;
    color: #fff;
    padding: 24px;
    text-align: center;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Fbg-dot.png?alt=media&token=a061b0e8-d7f9-44cc-a05a-4fe6c3000a72");
    background-repeat: no-repeat;
    background-position: bottom;
  }

  .footer {
    text-align: left;
  }

  .footer > img {
    height: 40px;
    display: inline-block;

    /* float: left; */
  }

  .footer > p {
    display: inline-block;
    margin-top: 24px;

    /* height: 40px; */
    float: right;
  }

  .color-orange {
    color: #fc7028;
  }

  .social-image h1 {
    text-align: center;
  }

  .social-image h1 small {
    font-family: 'gilroy_regularregular';
    display: block;
    font-size: 16px;
    opacity: 0.5;
  }



  table {
    max-width: calc(50% - 24px);
    /* border: 1px solid #ccc; */
    border-spacing: 0px;
    font-family: 'gilroy_regularregular';
    margin-bottom: 24px;
    background: #2e3062;
    border-radius: 6px;
    border: 1px solid #1c2043;
    min-width: 40%;
    padding: 24px;
    display: block;
    box-sizing: border-box;
  }

  table tr {
    max-width: 100%;
    min-width: 10%;
  }

  table tr td {
    max-width: 100%;
    min-width: 10%;
  }

  table h3 {
    line-height: 100%
  }
  .item {
    background: #3c3e70;
    width: 100%;
    height: 100%;
    border-radius: 1px;
    padding: 12px;
    text-align: left;
    box-sizing: border-box;
    margin-top: 2px;
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    position: relative;
  }

  .item strong {
    text-align: left;
    font-family: 'gilroy__boldbold';

  }

  .item:not(.pink):not(.blue) strong {
    max-width: 90%;
  }
  .item strong:first-child {
    max-width: 65%;
  }
  .item strong.right {
    max-width: 40%;
    position: absolute;
    right: 12px;
    display: inline-flex;
    align-items: center;
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .item.blue {
    background: rgba(28, 157, 238, 0.12);
  }

  .item.blue strong.right {
    color: #1C9DEE;
  }

  .item.pink strong.right {
    color: #DC7DA1;
  }
  .item.blue strong.right::before {
    content: url("https://firebasestorage.googleapis.com/v0/b/assets.brightful.me/o/common%2Fcheck-circle-blue.svg?alt=media&token=40106c96-8cdb-4092-a522-6aeda44a759c");
    margin-right: 2px;
  }

  .item.pink strong.right::before {
    content: url("https://firebasestorage.googleapis.com/v0/b/assets.brightful.me/o/common%2Fcheck-circle-pink.svg?alt=media&token=f29c3361-13d9-400c-a797-1fb6f36f2023");
    margin-right: 2px;
  }

  .item.pink {
    background: rgba(220, 125, 161, 0.12);
  }

  .bt-1 {
    border-top: 1px solid #ccc;
  }

  .bl-1 {
    border-left: 1px solid #ccc;
  }

  .br-1 {
    border-right: 1px solid #ccc;
  }
`;

export async function shareTop5Questions(result) {
  const date = result.date;
  let table: string = '',
    table2Row: string = '';

  function getTop5Table(roundDetail: any, players: any) {
    const answeredQuestions: any[] = Array.isArray(
      roundDetail['answeredQuestions']
    )
      ? roundDetail['answeredQuestions']
      : roundDetail['answeredQuestions']
      ? Object.keys(roundDetail['answeredQuestions']).map(answer => {
          return {
            answer: answer,
            playerId: roundDetail['answeredQuestions'][answer],
            team: players[roundDetail['answeredQuestions'][answer]]['team']
          };
        })
      : [];
    const question: any = roundDetail['questionData'];
    const answers: string[] = [
      question['answer1'],
      question['answer2'],
      question['answer3'],
      question['answer4'],
      question['answer5']
    ];

    let tableRow: string = '';
    for (const answer of answers) {
      let tableCol: string = '';

      const answeredQuestion = answeredQuestions.find(
        obj => obj.answer === answer
      );
      if (answeredQuestion) {
        const team =
          answeredQuestion.team == 'blue' ? 'item pink' : 'item blue';
        const player = players[answeredQuestion.playerId];
        tableCol = tableCol.concat(`<td>
                    <div class='${team}'>
                        <strong>${answer}</strong>
                        <strong class="right">${player.name}</strong>
                    </div>
                </td>`);
      } else {
        tableCol = tableCol.concat(`<td>
                    <div class='item'>
                        <strong>${answer}</strong>
                        <strong class="right"></strong>
                    </div>
                </td>`);
      }

      tableRow = tableRow.concat(`<tr>
                ${tableCol}
            </tr>`);
    }
    return `
    <table cellpas style="margin-right: 12px"  class="center">
      <tr>
        <td colspan="2">
          <h3 class="bold text-center">${question.question}</h3>
        </td>
      </tr>
        ${tableRow}
    </table>`;
  }

  const roundDetails = result.roundDetails;
  const players: any = {};
  result.players.map(player => {
    players[player.id] = player;
  });
  for (let index = 0; index < roundDetails.length; index++) {
    table2Row = table2Row.concat(
      `${getTop5Table(roundDetails[index], players)}\n`
    );
    if (roundDetails.length == index + 1 || index % 2 == 1) {
      table = table.concat(`
        <div style="display: flex">
          ${table2Row}
        </div>
      `);
      table2Row = '';
    }
  }

  const html = `
    <div class="social-image" style=" width: 800px; overflow:hidden; ">
      <div class="wrapper">
        <h1>
          <img class="logo" src="https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Ftop5-header.png?alt=media&token=d09c56d0-173e-4aee-94c0-7319e2842f40" style="margin: auto; height: 120px; display: block;">
          <small>${date}</small>
        </h1>
        ${table}
        <div class="footer">
          <img src="https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Flogo_new.png?alt=media&token=f591fc72-e9a1-4c9c-b93f-5dff5224d449" alt="">
          <p>
            Play at: <a class="color-orange">www.brightful.me</a>
          </p>
        </div>
      </div>
    </div>
    <link rel="stylesheet"
        href="https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Ffonts%2Fstylesheet.css?alt=media&token=6444781f-6f0d-4ded-bb41-e237e9ecad20">
  `;

  return generateImageFromHtmlCss(html, top5Css);
}

const leaderboardDetailListCss = `
  .social-image {
    background: linear-gradient(180deg, #2e3062 0%, #4a4c93 100%);
    width: 600px;
    padding: 24px;
    margin: auto;
    font-family: 'gilroy__boldbold';
  }

  .logo {
    margin: auto;
    display: block;
    margin-bottom: 24px;
  }

  .social-image h1 {
    text-align: center;
  }

  .social-image h1 small{
    font-family: 'gilroy_regularregular';
    display: block;
    font-size: 20px;
    opacity: 0.5
  }

  .color-blue {
    color: #1c9dee;
  }
  .color-green {
    color: #32C270;
  }
  .color-yellow {
    color: #D6AA11;
  }
  .color-grey {
    color: #858585;
  }

  .wrapper {
    border: 2px solid #000000;
    color: #fff;
    padding: 24px;
    text-align: left;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Fbg-dot.png?alt=media&token=a061b0e8-d7f9-44cc-a05a-4fe6c3000a72");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
  }

  .footer {
    text-align: left;
  }

  .footer > img {
    height: 40px;
    display: inline-block;
    /* float: left; */
  }

  .footer > p {
    display: inline-block;
    margin-top: 24px;
    /* height: 40px; */
    float: right;
  }

  .color-orange {
    color: #fc7028;
  }

  table {
    margin-bottom: 24px;
    color: #fff;
    border-spacing: 0;
    padding: 12px;
    line-height: 130%
  }

  table thead tr th {

  }

  table thead tr th> .head {
    background: #F1F5F8;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px 12px;
    box-sizing: border-box;
    border-radius: 3px;
    margin-bottom: 3px;
  }

  table thead tr th> .head >  div:first-child {
    width: 40%;
    color: #1C2043;
  }

  table thead tr th> .head >  div:not(:first-child) {
    width: 20%;
    text-align: center;
  }

  table tr td div.item {
    width: 100%;
    background: #fff;
    border-radius: 3px;
    margin-bottom: 3px;
    display: flex;
    padding: 8px 12px;
    align-items: center;
    box-sizing: border-box;
  }

  table tbody tr td> .item >  div:first-child {
    width: 40%;
    color: #1C2043;
    display: flex;
    align-items: center;
  }

  table tbody tr td> .item >  div:not(:first-child) {
    width: 20%;
    text-align: center;
  }

  table tbody tr td> .item .trophy {
    height: 36px;
  }

  table tbody tr td> .item .arrow {
    height: 24px;
  }

  table tbody tr td> .item h4 {
    margin-left: 6px;
    color: #2E3062;
  }

  table tbody tr td> .item h3 {
    width: 48px;
    font-size: 36px;
    text-align: center;
    opacity: 0.5;
    line-height: 0;
  }

  /*
  table tr td div.item .text h2 {
    color: #2E3062;
    line-height: 0;
    font-size: 20px;
  }

  table tr td div.item .text h2  small {
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 3px;
    margin-bottom: 24px;
    opacity: 0.5;
  }

  table tr td div.item .text p {
    color: #646464;
    font-family: 'gilroy_regularregular';
    line-height: 0;
    font-size: 14px;
    margin-top: 24px;
  }

  table tr td div.item .text p span {
    font-size: 24px;
    margin-left: 6px;
    margin-right: 6px;
    vertical-align: middle;
  }

  table tr td div.item .text p strong {
      font-family: 'gilroy__boldbold';
  }

  table tr td div.item .right-part {
      margin-left: auto;
      display: flex;
  } */
`;

export async function shareLeaderboardDetailList(result) {
  const gameName = result.gameName;
  const gameTitle = getGameTitleByType(gameName);
  const gameIcon = getGameIconsByType(gameName);
  const date = result.date;
  const sortType = result.sortType;

  let tableString = '';
  for (let i = 0; i < result.rankings.length; i++) {
    const ranking = result.rankings[i];
    const rankString =
      i === 0
        ? '<img class="trophy" src="https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/common%2Fgold.svg?alt=media&token=e217c86b-b371-40c3-bc38-d9aaff29eafe" alt="">'
        : i === 1
        ? '<img class="trophy" src="https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/common%2Fsilver.svg?alt=media&token=c6e8c70c-5b93-47f1-9c2c-381c83e83e0d" alt="">'
        : i === 2
        ? '<img class="trophy" src="https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/common%2Fbonze.svg?alt=media&token=32a2d37d-a3a0-479b-b4cb-83b0444573f1" alt="">'
        : `<h3>${i + 1}</h3>`;
    tableString += `
      <tr>
        <td>
          <div class="item">
            <div>
              ${rankString}              
              <h4>${ranking.name}</h4>
            </div>
            <div class="color-grey">${ranking.games}</div>
            <div class="color-yellow ">${ranking.wins}</div>
            ${
              sortType
                ? ''
                : '<div class="color-green ">' + ranking.score + '</div>'
            }
          </div>
        </td>
      </tr>  
    `;
  }

  const html = `
    <div class="social-image" style=" width: 800px; overflow:hidden; ">
      <div class="wrapper">      
        <h1>
          <img src="${gameIcon}" style="margin: auto; height: 120px; display: block;">
          ${gameTitle}
          <small>${date}</small>
        </h1>
        <table width="100%" rowspacing="10">
          <thead>            
            <tr>
              <th class="">
                <div class="head">
                  <div>RANKS BY ${sortType ? 'WINS' : 'POINTS'}</div>
                  <div class="color-grey">Games</div>
                  <div class="color-yellow ">Wins</div>
                  ${sortType ? '' : '<div class="color-green ">Point</div>'}
                </div>                    
              </th>            
            </tr>        
          </thead>
          <tbody>
            ${tableString}
          </tbody>
        </table>
        <div class="footer">
          <img src="https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Flogo_new.png?alt=media&token=f591fc72-e9a1-4c9c-b93f-5dff5224d449" alt="">
          <p>
              Play at: <a class="color-orange">www.brightful.me</a>
          </p>
        </div>
      </div>
    </div>
    <link rel="stylesheet"
      href="https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Ffonts%2Fstylesheet.css?alt=media&token=6444781f-6f0d-4ded-bb41-e237e9ecad20">
  `;

  return generateImageFromHtmlCss(html, leaderboardDetailListCss);
}
export async function shareLivePollResult(
  lobby: any,
  result: LivePollResult,
  wordCloudImage?: string | null
) {
  let html, css;
  const participants = result.participants ?? [];
  const respondedText =
    participants.length > 0
      ? `${result?.respondedParticipantIDs?.length ?? 0}/${participants.length}`
      : `${result?.respondedParticipantIDs?.length ?? 0}`;
  if (result.poll.type == LivePollType.RATING) {
    const poll = result.poll as RatingLivePoll;
    let columns = '';
    for (let i = 1; i <= poll.maxVal; i++) {
      const percent =
        result!.respondedParticipantIDs.length == 0
          ? 0
          : (result?.response.response[i] /
              result!.respondedParticipantIDs.length) *
            100;
      columns += `<div class="item" data-height="150px">
        <span>${percent}%</span>
        <div class="value-column" style="height: ${percent}%"></div>
      </div>`;
    }

    html = `
    <!DOCTYPE html>
    <html lang="en" dir="ltr">
      <head>
        <meta charset="utf-8">
        <title></title>
        <link rel="stylesheet" href="style.css">
      </head>
      <body>
    
    
        <div class="social-image" style=" width: 600px; overflow:hidden; ">
              <div class="wrapper">
    
                <h1>
                  <p style="font-size: 14px; color: #BADC58">Rating</p>
                    ${poll.question}
                  <small>${eventToolsHistoryDateFormat(
                    result.dateCreated
                  )} - Hosted by ${lobby.hostName}</small>
                </h1>
                <p style="font-size: 14px; color: #ccc; text-align: center">Responded: ${respondedText}</p>
    
                <div class="preview-rating border py-4 px-4">
                  <div class="chart" data-item="0" data-value="10">
                    ${columns}
                  </div>
                  <div class="label">
                    <img src="https://app.brightful.me/img/arrow-line-rating.655d73a0.svg" alt="">
                    <div class="d-flex">
                      <div>${poll.labelLow}</div>
                      <div class="ml-auto">${poll.labelHigh}</div>
                    </div>
                  </div>
    
                  <div class="rating-score mt-4">
                    <span>Score</span>
                    <h2>${result?.response.average}</h2>
                  </div>
    
                </div>
    
                <div class="footer">
                    <img  class="mr-3" src="https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Flogo_new.png?alt=media&token=f591fc72-e9a1-4c9c-b93f-5dff5224d449" alt="">
                    <p>
                        Play at: <a class="color-orange">www.brightful.me</a>
                    </p>
                </div>
              </div>
          </div>
          <link rel="stylesheet"
              href="https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Ffonts%2Fstylesheet.css?alt=media&token=6444781f-6f0d-4ded-bb41-e237e9ecad20">
    
      </body>
    </html>
  
  `;
    css = ratingPollCss;
  } else if (result.poll.type == LivePollType.WORD_CLOUD) {
    const poll = result.poll as WordCloudLivePoll;

    html = `
    <!DOCTYPE html>
<html lang="en" dir="ltr">
  <head>
    <meta charset="utf-8">
    <title></title>
    <link rel="stylesheet" href="style.css">
  </head>
  <body>


    <div class="social-image" style=" width: 600px; overflow:hidden; ">
          <div class="wrapper">

            <h1>
              <p style="font-size: 14px; color: #BADC58">WORD CLOUD</p>
              ${poll.question}
              <small>${eventToolsHistoryDateFormat(
                result.dateCreated
              )} - Hosted by ${lobby.hostName}</small>
            </h1>
            <p style="font-size: 14px; color: #ccc; text-align: center">Responded: ${respondedText}</p>



            <div class="preview-word-cloud"><img style="max-width: 100%" src="${wordCloudImage}" alt=""></div>

              <div class="footer">
                  <img  class="mr-3" src="https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Flogo_new.png?alt=media&token=f591fc72-e9a1-4c9c-b93f-5dff5224d449" alt="">
                  <p>
                      Play at: <a class="color-orange">www.brightful.me</a>
                  </p>
              </div>
          </div>
      </div>
       <link rel="stylesheet"
          href="https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Ffonts%2Fstylesheet.css?alt=media&token=6444781f-6f0d-4ded-bb41-e237e9ecad20">

  </body>
</html>
`;
    css = wordCloudPollCss;
  } else if (result.poll.type == LivePollType.MULTIPLE_CHOICE) {
    const poll = result.poll as MultipleChoiceLivePoll;
    let results = '';
    let index = 0;
    for (const option of poll.options) {
      const percent =
        result!.respondedParticipantIDs.length > 0
          ? (result?.response[option.text] /
              result!.respondedParticipantIDs.length) *
            100
          : 0;
      results += `
        <div class="preview-bar-chart-item mb-4 mt-2 color-${(index % 10) + 1}">
          <strong class="text-sm w-100 d-flex mb-1">
            ${option.text}
            <span class="ml-auto d-inline-block">${
              result?.response[option.text]
            } <span class="regular">(${percent}%)</span></span>
          </strong>
          <div class="progress h-3 rounded-0">
            <div class="progress-bar " role="progressbar" style="width: ${percent}%" aria-valuenow="${percent}" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
        `;
      index++;
    }
    console.log(results);
    html = `<!DOCTYPE html>
    <html lang="en" dir="ltr">
      <head>
        <meta charset="utf-8">
        <title></title>
        <link rel="stylesheet" href="style.css">
      </head>
      <body>
  
        <div class="social-image" style=" width: 600px; overflow:hidden; ">
          <div class="wrapper">

            <h1>
            <p style="font-size: 14px; color: #BADC58">MULTIPLE CHOICE</p>
              ${poll.question}
              <small>${eventToolsHistoryDateFormat(
                result.dateCreated
              )} - Hosted by ${lobby.hostName}</small>
            </h1>
            <p style="font-size: 14px; color: #ccc; text-align: center">Responded: ${respondedText}</p>

            <div class="preview-bar-chart h-100 border py-4 px-4">
              ${results}
            </div>
            <div class="footer">
                <img  class="mr-3" src="https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Flogo_new.png?alt=media&token=f591fc72-e9a1-4c9c-b93f-5dff5224d449" alt="">
                <p>
                    Play at: <a class="color-orange">www.brightful.me</a>
                </p>
            </div>
          </div>
        </div>
        <link rel="stylesheet"
          href="https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Ffonts%2Fstylesheet.css?alt=media&token=6444781f-6f0d-4ded-bb41-e237e9ecad20">
    
      </body>
    </html>
    `;
    css = multipleChoicePollCss;
  } else if (result.poll.type == LivePollType.SHORT_ANSWER) {
    const poll = result?.poll as ShortAnswerLivePoll;
    let options = '';
    for (const key in result.response) {
      const response = result.response[key];
      options += `
      <div class="preview-short-answer-item">
        <strong> <img class="mr-3" src="https://firebasestorage.googleapis.com/v0/b/image-hosting-aa7d4.appspot.com/o/person.svg?alt=media&token=ec6c8c11-c2f9-460c-9053-9c059ffaa260" /> 
          ${response.name}
        </strong>
        <div class="text">${response.answer}</div>
        <div class="right-box text-right">
          <div class="d-flex align-items-center justify-content-end">
            <img class="mr-3" src="https://firebasestorage.googleapis.com/v0/b/image-hosting-aa7d4.appspot.com/o/thumb.svg?alt=media&token=cd82753c-df8d-4d5e-b7d5-e899750a41a3" />
            <strong class="ml-1">12</strong>
          </div>
          <!-- <span class="blur-badge green side-sm sm-mt-1 ">Answered</span> -->
        </div>
      </div>
      `;
    }
    html = `
    <!DOCTYPE html>
<html lang="en" dir="ltr">
  <head>
    <meta charset="utf-8">
    <title></title>
    <link rel="stylesheet" href="style.css">
  </head>
  <body>


    <div class="social-image" style=" width: 600px; overflow:hidden; ">
          <div class="wrapper">

            <h1>
              <p style="font-size: 14px; color: #BADC58">SHORT ANSWER</p>
                ${poll.question}
                <small>${eventToolsHistoryDateFormat(
                  result.dateCreated
                )} - Hosted by ${lobby.hostName}</small>
            </h1>
            <p style="font-size: 14px; color: #ccc; text-align: center">Responded: ${respondedText}</p>

              <div class="preview-short-answer  px-0 pt-0">
              ${options}
              </div>


              <div class="footer">
                  <img  class="mr-3" src="https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Flogo_new.png?alt=media&token=f591fc72-e9a1-4c9c-b93f-5dff5224d449" alt="">
                  <p>
                      Play at: <a class="color-orange">www.brightful.me</a>
                  </p>
              </div>
          </div>
      </div>
       <link rel="stylesheet"
          href="https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Ffonts%2Fstylesheet.css?alt=media&token=6444781f-6f0d-4ded-bb41-e237e9ecad20">

  </body>
</html>
`;

    css = shortAnswerPollCss;
  }

  return generateImageFromHtmlCss(html, css);
}
