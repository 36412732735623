
import { computed, defineComponent, ref } from 'vue';
import firebaseApp from '@/firebaseApp';
import CryptoJS from 'crypto-js';

export default defineComponent({
  name: 'SetNewPasswordModal',
  emits: ['close'],
  props: {
    whiteboardId: {
      type: String,
      required: true
    }
  },
  setup(props, context) {
    const password = ref('');
    const passwordEye = ref(false);
    const isLoading = ref(false);
    const errorMessage = ref('');

    const showPasswordEye = computed(() => {
      return password.value.trim().length > 0;
    });

    const confirmButtonUpdate = computed(() => {
      return password.value.trim().length >= 4;
    });

    async function confirmSetNewPassword() {
      isLoading.value = true;
      const hashedPassword = CryptoJS.SHA3(password.value).toString();
      try {
        await firebaseApp
          .firestore()
          .collection('whiteboard_data')
          .doc(props.whiteboardId)
          .update({
            password: hashedPassword,
            dateUpdated: firebaseApp.firestore.FieldValue.serverTimestamp()
          });
        isLoading.value = false;
        context.emit('close');
      } catch (err) {
        console.log(err);
        isLoading.value = false;
        errorMessage.value =
          'An unexpected error has occured. Please try again later or contact support@brightful.me for more info.';
      }
    }

    return {
      password,
      passwordEye,
      showPasswordEye,
      isLoading,
      errorMessage,
      confirmButtonUpdate,
      confirmSetNewPassword
    };
  }
});
