
import {
  liteParticipantsUnitRate,
  nLiteParticipantsIncluded,
  nProParticipantsIncluded,
  proParticipantsUnitRate
} from '@/utils/pricingConstant';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ParticipantsInfoModal',
  emits: ['close'],
  setup(_, context) {
    const close = function() {
      context.emit('close');
    };

    return {
      close,
      liteParticipantsUnitRate,
      nLiteParticipantsIncluded,
      proParticipantsUnitRate,
      nProParticipantsIncluded
    };
  }
});
