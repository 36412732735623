
import usePostAPI from '@/hooks/usePostAPI';
// import router from '@/router';
import { store } from '@/store';
import {
  getEndpoint,
  // handleSlackStatus,
  subscriptionText,
  getPeriodEndDate
} from '@/utils/helper';
import { computed, defineComponent, ref } from 'vue';
import BounceLoading from '@/components/BounceLoading.vue';
import useFirebaseLoginOut from '@/hooks/firebase/useFirebaseLoginOut';
// import { NavigationTabs } from '@/models/navigationTabs';
export default defineComponent({
  name: 'CancelSubscriptionModal',
  emits: ['close'],
  components: {
    BounceLoading
  },
  setup(_, context) {
    const isLoading = ref(true);
    const isSuccess = ref(false);
    // const slackActive = ref(false);
    const error = ref('');
    // const user = computed(() => store.getters.getUser);
    const subscriptionDetails = computed(
      () => store.getters.getSubscriptionDetails
    );
    // const userDoc = computed(() => store.getters.getUserDoc);
    const subscriptionLevelText = computed(() => {
      return subscriptionText(subscriptionDetails.value);
    });
    const subscriptionPeriodEnd = ref('');
    const close = function() {
      context.emit('close');
    };
    const refresh = function() {
      window.location.reload();
      context.emit('close');
    };
    const initialize = async function() {
      let endDate = getPeriodEndDate(
        subscriptionDetails.value.stripeCurrentPeriodEnd
      );
      subscriptionPeriodEnd.value = endDate ? endDate : '';
      isLoading.value = false;
    };
    initialize();
    // const goToSlack = function() {
    //   router.push({ name: 'Dashboard', query: { tab: NavigationTabs.SLACK } });
    //   context.emit('close');
    // };
    const cancelSubscription = async function() {
      try {
        isLoading.value = true;
        const response = await usePostAPI(
          getEndpoint('cancelSubscription', '/api/stripe/'),
          {}
        );
        useFirebaseLoginOut().getSubscriptionDetails(true);
        if (response.status == 200) {
          isSuccess.value = true;
          error.value = '';
        } else {
          console.log(response.data);
          error.value = 'There is problem executing the request right now';
          isSuccess.value = false;
        }
        // const slackUid = userDoc.value['slackUid'];
        // let res = await handleSlackStatus(store, user.value.uid, slackUid);
        // slackActive.value = res.isAuthenticated;
        isLoading.value = false;
      } catch (err) {
        isLoading.value = false;
        error.value = 'There is problem executing the request right now';
        console.log(err);
      }
    };

    return {
      subscriptionPeriodEnd,
      subscriptionLevelText,
      isLoading,
      error,
      isSuccess,
      // slackActive,
      // goToSlack,
      cancelSubscription,
      close,
      refresh
    };
  }
});
