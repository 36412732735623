import { createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "modal-content" }
const _hoisted_2 = { class: "modal-header" }
const _hoisted_3 = { class: "modal-body" }
const _hoisted_4 = { class: "game-settings" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SampleQuestionWouldYouRather = _resolveComponent("SampleQuestionWouldYouRather")
  const _component_SampleQuestionQOfTheDay = _resolveComponent("SampleQuestionQOfTheDay")
  const _component_SampleQuestionTrivia = _resolveComponent("SampleQuestionTrivia")
  const _component_SampleQuestionSpotMyLie = _resolveComponent("SampleQuestionSpotMyLie")
  const _component_SampleQuestionDrawTell = _resolveComponent("SampleQuestionDrawTell")
  const _component_SampleQuestionPoll = _resolveComponent("SampleQuestionPoll")

  return (_openBlock(), _createBlock("div", {
    class: "modal-right",
    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.close()))
  }, [
    _createVNode(_Transition, {
      appear: "",
      name: "slide"
    }, {
      default: _withCtx(() => [
        (_ctx.show)
          ? (_openBlock(), _createBlock("div", {
              key: 0,
              class: "modal-dialog",
              onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
            }, [
              _createVNode("div", _hoisted_1, [
                _createVNode("div", _hoisted_2, [
                  _createVNode("button", {
                    type: "button",
                    class: "btn-close-left",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.close()))
                  }, [
                    _createVNode("img", {
                      src: require('@/assets/img/close.png'),
                      alt: "close modal"
                    }, null, 8, ["src"])
                  ])
                ]),
                _createVNode("div", _hoisted_3, [
                  _createVNode("div", _hoisted_4, [
                    (_ctx.type == 'would_you_rather')
                      ? (_openBlock(), _createBlock(_component_SampleQuestionWouldYouRather, { key: 0 }))
                      : _createCommentVNode("", true),
                    (_ctx.type == 'question_of_the_day')
                      ? (_openBlock(), _createBlock(_component_SampleQuestionQOfTheDay, { key: 1 }))
                      : _createCommentVNode("", true),
                    (_ctx.type == 'trivia')
                      ? (_openBlock(), _createBlock(_component_SampleQuestionTrivia, { key: 2 }))
                      : _createCommentVNode("", true),
                    (_ctx.type == 'spot_my_lie')
                      ? (_openBlock(), _createBlock(_component_SampleQuestionSpotMyLie, { key: 3 }))
                      : _createCommentVNode("", true),
                    (_ctx.type == 'draw_tell')
                      ? (_openBlock(), _createBlock(_component_SampleQuestionDrawTell, { key: 4 }))
                      : _createCommentVNode("", true),
                    (_ctx.type == 'poll')
                      ? (_openBlock(), _createBlock(_component_SampleQuestionPoll, { key: 5 }))
                      : _createCommentVNode("", true)
                  ])
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}