import { toRefs, reactive, onMounted } from 'vue';
import firebase from '@/firebaseApp';

export default function(whiteboardId: string) {
  const state: {
    isOnline: boolean;
  } = reactive({
    isOnline: true
  });

  const realtimeDb = firebase.database();
  const whiteboardUserStatusRef = firebase
    .database()
    .ref(`/whiteboard_user_status/${whiteboardId}`);
  const whiteboardParticipantRef = firebase
    .firestore()
    .collection(`whiteboard_data/${whiteboardId}/participants`);

  onMounted(() => {
    listenConnectionStatus();
  });

  const listenConnectionStatus = () => {
    const currentUser = firebase.auth().currentUser;
    if (currentUser === null) {
      return;
    }
    const uid = currentUser.uid;
    realtimeDb.ref('.info/connected').on('value', snapshot => {
      if (snapshot.val() === false) {
        state.isOnline = false;
        whiteboardParticipantRef.doc(uid).update({
          dateLeft: firebase.firestore.FieldValue.serverTimestamp(),
          isOnline: false
        });
        return;
      }
      whiteboardUserStatusRef
        .child(currentUser.uid)
        .onDisconnect()
        .set({
          isOnline: false,
          lastUpdated: firebase.database.ServerValue.TIMESTAMP
        })
        .then(() => {
          state.isOnline = true;
          whiteboardUserStatusRef.child(uid).set({
            isOnline: true,
            lastUpdated: firebase.database.ServerValue.TIMESTAMP
          });
          whiteboardParticipantRef.doc(uid).update({
            dateLeft: null,
            isOnline: true
          });
        });
    });
  };

  return {
    ...toRefs(state)
  };
}
