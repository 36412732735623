
import router from '@/router';
import { useStore } from '@/store';
import moment from 'moment';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'FreeTrialEndingBar',
  setup() {
    const store = useStore();
    const userDoc = computed(() => store.getters.getUserDoc);
    const freeTrialEndDate =
      userDoc.value['freeTrialEndDate'] !== undefined
        ? userDoc.value['freeTrialEndDate'].toDate()
        : undefined;

    const remainingFreeTrialDays =
      moment(freeTrialEndDate).diff(moment(), 'days') + 1;

    const remainingFreeTrialDaysText = remainingFreeTrialDays
      .toString()
      .concat(' days');

    function goToPricing() {
      router.push({ name: 'Pricing', query: { source: 'free-trial-banner' } });
    }

    return {
      remainingFreeTrialDays,
      remainingFreeTrialDaysText,
      goToPricing
    };
  }
});
