
import { computed, defineComponent, ref } from 'vue';
import CategoryFilterMobile from '@/components/CategoryFilterMobile.vue';
import LeaderboardList from '@/components/HistoryLeaderboard/LeaderboardList.vue';
import LeaderboardDetail from '@/components/HistoryLeaderboard/LeaderboardDetail.vue';
import {
  getGameTitleByType,
  firestoreGameTypes,
  leaderboardGameTypes
} from '@/utils/gameConstants';
import { useStore } from '@/store';
import moment from 'moment';
import firebase from '@/firebaseApp';
import useFirebaseCollection from '@/hooks/firebase/useFirebaseCollection';
import { canAccessFullLeaderboard, isProPlan } from '@/utils/pricing_rules';
import { UPDATE_PROMOTION_FLAG, UPDATE_USER_DOC } from '@/store/mutation-types';
import useFirebaseDocument from '@/hooks/firebase/useFirebaseDocument';
export default defineComponent({
  name: 'LeaderboardMain',
  components: {
    CategoryFilterMobile,
    LeaderboardList,
    LeaderboardDetail
  },
  props: {
    selectedType: {
      type: String
    }
  },
  emits: ['open_upgrade_modal', 'scroll'],
  setup(_, context) {
    const store = useStore();
    const subscriptionDetails = computed(
      () => store.getters.getSubscriptionDetails
    );
    const user = computed(() => store.getters.getUser);
    const userDoc = computed(() => store.getters.getUserDoc);
    const canAccessFullLeaderboardValue = computed(() => {
      return canAccessFullLeaderboard(subscriptionDetails.value, userDoc.value);
    });

    const leaderboardGameNames = leaderboardGameTypes.map((type: string) =>
      getGameTitleByType(type)
    );
    const leaderboardGameNameIndex = ref(-1);

    const gamePeriods = ['Select a month', 'Select a year'];
    const leaderboardGamePeriod = ref(undefined as any);

    const isLoading = ref(false);
    const collectionData = ref([] as any[]);
    const leaderboardData = ref({});
    const leaderboardEnabledMonths = ref([] as string[]);
    const leaderboardEnabledYears = ref([] as number[]);

    const includeOtherHostLeaderboard = ref(false);

    const teamInfo = computed(() => store.getters.getTeamInfo);
    const hasOtherHost =
      isProPlan(subscriptionDetails.value) &&
      teamInfo.value &&
      teamInfo.value.members.length > 1;

    async function getRoomGameData(gameRoomId: string) {
      const userGameplayHistoryQuery = useFirebaseCollection(
        `minigame_gameplay_history/${gameRoomId}/game_plays`,
        {
          onMounted: false
        }
      );
      await userGameplayHistoryQuery.getCollection({
        fieldPath: 'isFinished',
        opStr: '==',
        value: true,
        orderBy: 'dateCreated',
        isDesc: true
      });
      userGameplayHistoryQuery.collectionData.value!.map(data => {
        if (
          data['dateCreated'] &&
          ((firestoreGameTypes.includes(data.gameName) &&
            data['dateCreated'] instanceof firebase.firestore.Timestamp) ||
            (!firestoreGameTypes.includes(data.gameName) &&
              !(data['dateCreated'] instanceof firebase.firestore.Timestamp)))
        ) {
          const date =
            data['dateCreated'] instanceof firebase.firestore.Timestamp
              ? moment(data['dateCreated'].toDate())
              : moment(data['dateCreated']);
          const newData = Object.assign({}, data);
          newData['dateCreated'] = date;
          collectionData.value.push(newData);
        }
      });
    }

    async function getGameHistory() {
      isLoading.value = true;

      collectionData.value = [];

      if (includeOtherHostLeaderboard.value) {
        for (let i = 0; i < teamInfo.value.members.length; i++) {
          const userDocument = useFirebaseDocument('minigame_user', {
            onMounted: false
          });
          await userDocument.getDocument(10, teamInfo.value.members[i].uid);
          await getRoomGameData(
            userDocument.documentData.value['defaultGameRoomId']
          );
        }
      } else {
        await getRoomGameData(userDoc.value['defaultGameRoomId']);
      }

      collectionData.value.sort(
        (a, b) => b.dateCreated.unix() - a.dateCreated.unix()
      );

      getLeaderboardFiltered();

      isLoading.value = false;
    }

    function getLeaderboardFiltered() {
      leaderboardEnabledMonths.value = [];
      leaderboardEnabledYears.value = [];
      leaderboardData.value = {};

      const filteredGameNames =
        leaderboardGameNameIndex.value === -1
          ? leaderboardGameTypes
          : [leaderboardGameTypes[leaderboardGameNameIndex.value]];
      const gameFilteredCollectionData = collectionData.value.filter(
        data =>
          filteredGameNames.includes(data.gameName) &&
          (includeOtherHostLeaderboard.value ||
            (!includeOtherHostLeaderboard.value &&
              data.host === user.value.uid))
      );
      gameFilteredCollectionData.map(data => {
        const date = data.dateCreated;
        const month = moment(date).format('MMMM YYYY');
        if (!leaderboardEnabledMonths.value.includes(month)) {
          leaderboardEnabledMonths.value.push(month);
        }
        if (!leaderboardEnabledYears.value.includes(date.year())) {
          leaderboardEnabledYears.value.push(date.year());
        }
      });

      const dateFilteredCollectionData = leaderboardGamePeriod.value
        ? gameFilteredCollectionData.filter(data => {
            const monthDate = data.dateCreated.format('MMMM YYYY');
            return monthDate.includes(leaderboardGamePeriod.value);
          })
        : gameFilteredCollectionData;

      dateFilteredCollectionData.map(data => {
        const gameName = data.gameName;
        if (leaderboardData.value[gameName]) {
          leaderboardData.value[gameName].push(data);
        } else {
          leaderboardData.value[gameName] = [data];
        }
      });
    }

    async function updateVisitedLeaderboard() {
      const seenPromotions = userDoc.value['seenPromotions'] ?? {};

      if (!seenPromotions['leaderboard']) {
        store.commit(UPDATE_PROMOTION_FLAG, {
          newPromotionFlag: undefined
        });
        await firebase
          .firestore()
          .collection('minigame_user')
          .doc(userDoc.value.id)
          .update({
            'seenPromotions.leaderboard': true
          });
        const userDocumentProps = useFirebaseDocument('minigame_user', {
          onMounted: false,
          documentId: undefined
        });
        await userDocumentProps.getDocument(10, userDoc.value.id);
        store.commit(UPDATE_USER_DOC, {
          newUserDoc: userDocumentProps.documentData.value
        });
      }
    }

    getGameHistory();
    updateVisitedLeaderboard();

    function onSelectLeaderboardGameName(index: number) {
      leaderboardGameNameIndex.value = index;
      leaderboardGamePeriod.value = undefined;
      getLeaderboardFiltered();
    }

    function onSelectLeaderboardGamePeriod(value: any) {
      leaderboardGamePeriod.value = value;
      getLeaderboardFiltered();
    }

    function onIncludeOtherHostLeaderboard(value) {
      includeOtherHostLeaderboard.value = value;
      getGameHistory();
    }

    function onOpenUpgradeModal() {
      context.emit('open_upgrade_modal');
    }

    function onShareLeaderboardList() {}

    function saveScrollPosition(pos: number) {
      context.emit('scroll', pos);
    }

    return {
      canAccessFullLeaderboardValue,
      gamePeriods,
      leaderboardGameNames,
      leaderboardGameNameIndex,
      leaderboardGamePeriod,
      onSelectLeaderboardGameName,
      onSelectLeaderboardGamePeriod,
      hasOtherHost,
      includeOtherHostLeaderboard,
      onIncludeOtherHostLeaderboard,
      onOpenUpgradeModal,
      onShareLeaderboardList,
      saveScrollPosition,
      isLoading,
      collectionData,
      leaderboardData,
      leaderboardEnabledMonths,
      leaderboardEnabledYears
    };
  }
});
