
import moment from 'moment';
import { defineComponent, ref } from 'vue';
import { getGameTitleByType, getGameCssByType } from '@/utils/gameConstants';

export default defineComponent({
  name: 'RecentlyPlayedTile',
  emits: ['start', 'customized-game-start'],
  props: {
    game: {
      type: Object,
      required: true
    },
    skeleton: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const isLoading = ref(false);

    function startGame() {
      if (props.game.customGameId) {
        context.emit('customized-game-start', {
          gameName: props.game.gameName,
          customGameId: props.game.customGameId,
          createdBy: props.game.created_by
        });
      } else {
        context.emit('start', { gameName: props.game.gameName });
      }
      isLoading.value = true;
      setTimeout(function() {
        isLoading.value = false;
      }, 2000);
    }

    return {
      getGameTitleByType,
      getGameCssByType,
      startGame,
      moment,
      isLoading
    };
  }
});
