
import { LivePoll } from '@/models/event_tools/live_poll/live_poll_model';
import {
  dateDifference,
  gameNameFromPollType,
  getEndpoint,
  hasActiveGame,
  livePollConfigs,
  onGameStart,
  pollDateCreatedText
} from '@/utils/helper';
import { LivePollType } from '@/utils/live_poll_enums';
import { computed, defineComponent, PropType, Ref, ref } from 'vue';
import firebase from 'firebase';
import { store } from '@/store';
import { ActivityType } from '@/utils/gamenames_enums';
import GeneralWarningModal from '@/components//Modal/GeneralWarningModal.vue';
import usePostAPI from '@/hooks/usePostAPI';
import { LaunchOptions } from '@/models/GameStartOptions';
import LivePollHistoryListModal from './Modal/History/LivePollHistoryListModal.vue';

export default defineComponent({
  name: 'CreatePollHavingHistory',
  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'show-modal': (type: LivePollType) => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    preview: (type: LivePoll) => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'launch-modal': (options: LaunchOptions) => true
  },
  props: {
    polls: { type: Array as PropType<Array<LivePoll>>, required: true }
  },
  components: { GeneralWarningModal, LivePollHistoryListModal },
  setup(_, context) {
    const modalTypeShown: Ref<
      ['deleteWarning', LivePoll] | ['historyModal', LivePoll] | null
    > = ref(null);
    const launchButtonLoading: Ref<string | null> = ref(null);
    const pollConfigs = livePollConfigs;
    const subscriptionDetails = computed(
      () => store.getters.getSubscriptionDetails
    );
    const userDoc = computed(() => store.getters.getUserDoc);

    function onPollType(type: LivePollType) {
      context.emit('show-modal', type);
    }
    function onPreview(poll: LivePoll) {
      context.emit('preview', poll);
    }

    async function onLaunch(poll: LivePoll) {
      launchButtonLoading.value = poll.id!;
      const gameName = gameNameFromPollType(poll.type);
      // check if lobby is open
      const launchOptions: LaunchOptions = {
        activityType: ActivityType.LIVE_POLL,
        chosenGame: gameName,
        createGameSource: 'dashboard',
        poll: poll
      };
      if (!hasActiveGame()) {
        // no active game in session
        await onGameStart({
          ...launchOptions,
          subscriptionLevel: subscriptionDetails.value.subscriptionLevel,
          zoomClientConfig: store.state.zoomClientStatus,
          startFresh: false
        });
      } else {
        context.emit('launch-modal', launchOptions);
      }
      launchButtonLoading.value = null;
    }
    function onDelete(poll: LivePoll) {
      console.log(poll);
      modalTypeShown.value = ['deleteWarning', poll];
    }
    function onDeleteCancel() {
      modalTypeShown.value = null;
    }
    async function onDeleteConfirm(poll: LivePoll, event: any) {
      try {
        if (event?.onRequest) {
          event.onRequest();
        }
        const lobbyId = userDoc.value['defaultLobbyId'];
        await usePostAPI(getEndpoint('deletePoll'), {
          lobbyId: lobbyId,
          pollId: poll.id
        });
        if (event?.onSuccess) {
          event?.onSuccess();
        }
      } catch (err) {
        console.log(err);
        if (event?.onError) {
          event?.onError();
        }
      }
      modalTypeShown.value = null;
    }
    async function onLaunchedCount(poll: LivePoll) {
      modalTypeShown.value = ['historyModal', poll];
    }

    return {
      pollConfigs,
      modalTypeShown,
      launchButtonLoading,
      pollDateCreatedText,
      onPollType,
      onPreview,
      onLaunch,
      onDelete,
      onLaunchedCount,
      onDeleteCancel,
      onDeleteConfirm
    };
  }
});
