
import { defineComponent, ref } from 'vue';
import NumberOfPlayersWouldYouRather from '@/components/Modal/AboutComponent/NumberOfPlayersComponents/NumberOfPlayersWouldYouRather.vue';
import NumberOfPlayersDrawIt from '@/components/Modal/AboutComponent/NumberOfPlayersComponents/NumberOfPlayersDrawIt.vue';
import NumberOfPlayersDrawTell from '@/components/Modal/AboutComponent/NumberOfPlayersComponents/NumberOfPlayersDrawTell.vue';
import NumberOfPlayersSpotMyLie from '@/components/Modal/AboutComponent/NumberOfPlayersComponents/NumberOfPlayersSpotMyLie.vue';
import NumberOfPlayersDescribeIt from '@/components/Modal/AboutComponent/NumberOfPlayersComponents/NumberOfPlayersDescribeIt.vue';
import NumberOfPlayersQOfTheDay from '@/components/Modal/AboutComponent/NumberOfPlayersComponents/NumberOfPlayersQOfTheDay.vue';
import NumberOfPlayersTrivia from '@/components/Modal/AboutComponent/NumberOfPlayersComponents/NumberOfPlayersTrivia.vue';
import NumberOfPlayersHearts from '@/components/Modal/AboutComponent/NumberOfPlayersComponents/NumberOfPlayersHearts.vue';
import NumberOfPlayersGinRummy from '@/components/Modal/AboutComponent/NumberOfPlayersComponents/NumberOfPlayersGinRummy.vue';
import NumberOfPlayersRummy from '@/components/Modal/AboutComponent/NumberOfPlayersComponents/NumberOfPlayersRummy.vue';
import NumberOfPlayersCharades from '@/components/Modal/AboutComponent/NumberOfPlayersComponents/NumberOfPlayersCharades.vue';
import HowToPlayWouldYouRather from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayWouldYouRather.vue';
import HowToPlayDrawIt from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayDrawIt.vue';
import HowToPlayDrawTell from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayDrawTell.vue';
import HowToPlaySpotMyLie from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlaySpotMyLie.vue';
import HowToPlayDescribeIt from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayDescribeIt.vue';
import HowToPlayQOfTheDay from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayQOfTheDay.vue';
import HowToPlayTrivia from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayTrivia.vue';
import HowToPlayHearts from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayHearts.vue';
import HowToPlayGinRummy from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayGinRummy.vue';
import HowToPlayRummy from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayRummy.vue';
import HowToPlayCharades from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayCharades.vue';

export default defineComponent({
  name: 'CustomizedGameHowToPlayModal',
  components: {
    NumberOfPlayersWouldYouRather,
    NumberOfPlayersDrawIt,
    NumberOfPlayersDrawTell,
    NumberOfPlayersSpotMyLie,
    NumberOfPlayersDescribeIt,
    NumberOfPlayersQOfTheDay,
    NumberOfPlayersTrivia,
    NumberOfPlayersHearts,
    NumberOfPlayersGinRummy,
    NumberOfPlayersRummy,
    NumberOfPlayersCharades,
    HowToPlayWouldYouRather,
    HowToPlayDrawIt,
    HowToPlayDrawTell,
    HowToPlaySpotMyLie,
    HowToPlayDescribeIt,
    HowToPlayQOfTheDay,
    HowToPlayTrivia,
    HowToPlayHearts,
    HowToPlayGinRummy,
    HowToPlayRummy,
    HowToPlayCharades
  },
  props: {
    type: {
      type: String,
      required: true
    }
  },
  setup(props, context) {
    const show = ref(true);

    const close = function() {
      show.value = false;
      setTimeout(() => {
        context.emit('close');
      }, 50);
    };

    return {
      show,
      close
    };
  }
});
