// store.ts
import { SubscriptionDetails } from '@/models/SubscriptionDetails';
import ZoomClientConfig from '@/utils/helper';
import { InjectionKey } from 'vue';
import { createStore, useStore as baseUseStore, Store } from 'vuex';
import {
  UPDATE_ACTIVEGAMENAME,
  UPDATE_ACTIVEGAMEROOMID,
  UPDATE_EDITORS_PICK_GAMES,
  UPDATE_ISUSERUSINGCUSTOMGAMEROOMID,
  UPDATE_LOBBY_DOC,
  UPDATE_MEDITATION_TRACKS,
  UPDATE_MOST_POPULAR_GAMES,
  UPDATE_SURVEY_1_ANSWER,
  UPDATE_USER,
  UPDATE_USER_CUSTOMIZED_GAMES,
  UPDATE_USER_DOC,
  UPDATE_USER_SUBSCRIPTION_DETAILS,
  UPDATE_USER_TESTIMONIAL,
  UPDATE_SLACK_STATUS,
  UPDATE_SLACK_CHANNELS,
  UPDATE_TEAM_INFO,
  UPDATE_PROMOTION_FLAG,
  UPDATE_SLACK_MODAL_STATUS,
  UPDATE_ZOOM_STATUS,
  UPDATE_CURRENT_GAME_DATA
} from './mutation-types';

// define your typings for the store state
export interface State {
  user: Object | undefined;
  userDoc: Object | undefined;
  lobbyDoc: Object | undefined;
  survey1Answer: string[] | undefined;
  isUserUsingCustomGameRoomId: boolean;
  activeGameRoomId: string | undefined;
  activeGameName: string | undefined;
  subscriptionDetails: SubscriptionDetails;
  userGameplayHistoryCounts: Object | undefined;
  userTestimonial: Object | undefined;
  editorsPickGames: Object[] | undefined;
  mostPopularGames: Object[] | undefined;
  userCustomizedGames: Object[] | undefined;
  meditationTracks: Object[] | undefined;
  slackStatus: Object | undefined;
  slackChannels: Object[] | undefined;
  reservedChannels: String[] | undefined;
  teamInfo: Object | undefined;
  promotionFlag: string | undefined;
  slackSuccessModalSeen: Boolean | undefined;
  zoomClientStatus: ZoomClientConfig | undefined;
  currentGameData: Object | undefined;
}

// define injection key
export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
  state: {
    user: undefined,
    userDoc: undefined,
    lobbyDoc: undefined,
    survey1Answer: undefined,
    isUserUsingCustomGameRoomId: false,
    activeGameRoomId: undefined,
    activeGameName: undefined,
    subscriptionDetails: {
      subscriptionLevel: undefined,
      subscriptionInterval: undefined,
      teamId: undefined,
      teamRole: undefined
    },
    userGameplayHistoryCounts: undefined,
    userTestimonial: undefined,
    editorsPickGames: undefined,
    mostPopularGames: undefined,
    userCustomizedGames: undefined,
    meditationTracks: undefined,
    slackStatus: undefined,
    slackChannels: undefined,
    reservedChannels: [],
    teamInfo: undefined,
    promotionFlag: undefined,
    slackSuccessModalSeen: undefined,
    zoomClientStatus: undefined,
    currentGameData: undefined
  },
  getters: {
    getUser: state => {
      return state.user!;
    },
    getUserDoc: state => {
      return state.userDoc!;
    },
    isUserCreatedBeforeOct: state => {
      return state.userDoc!['dateCreated'].toDate() <= new Date(2021, 10, 5);
    },
    isUserCreatedBeforeEventsToolLaunch: state => {
      return state.userDoc!['dateCreated'].toDate() <= new Date(2023, 2, 15);
    },
    getUserGameplayHistoryCounts: state => {
      return state.userDoc ? state.userDoc['gameplays'] ?? {} : {};
    },
    getTotalGameplays: state => {
      const gameplays = state.userDoc ? state.userDoc['gameplays'] ?? {} : {};
      let count = 0;
      for (const gameName in gameplays) {
        count += gameplays[gameName];
      }
      return count;
    },
    getLobbyDoc: state => {
      return state.lobbyDoc!;
    },
    getSurvey1Answer: state => {
      return state.survey1Answer!;
    },
    isUserUsingCustomGameRoomId: state => {
      return state.isUserUsingCustomGameRoomId;
    },
    activeGameRoomId: state => {
      return state.activeGameRoomId;
    },
    activeGameName: state => {
      return state.activeGameName;
    },
    getSubscriptionDetails: state => {
      return state.subscriptionDetails!;
    },

    getUserTestimonial: state => {
      return state.userTestimonial!;
    },
    getEditorsPickGames: state => {
      return state.editorsPickGames!;
    },
    getMostPopularGames: state => {
      return state.mostPopularGames!;
    },
    getUserCustomizedGames: state => {
      return state.userCustomizedGames!;
    },
    getMeditationTracks: state => {
      return state.meditationTracks!;
    },
    getSlackStatus: state => {
      return state.slackStatus!;
    },
    getSlackChannels: state => {
      return state.slackChannels!;
    },
    getTeamInfo: state => {
      return state.teamInfo;
    },
    getPromotionFlag: state => {
      return state.promotionFlag;
    },
    getSlackModalStatus: state => {
      return state.slackSuccessModalSeen;
    },
    getZoomClientStatus: state => {
      return state.zoomClientStatus;
    },
    getCurrentGameData: state => {
      return state.currentGameData;
    }
  },
  mutations: {
    [UPDATE_USER](state, payload: { newUser: Object }) {
      state.user = payload.newUser;
    },
    [UPDATE_USER_DOC](state, payload: { newUserDoc: Object }) {
      state.userDoc = payload.newUserDoc;
    },
    [UPDATE_LOBBY_DOC](state, payload: { newLobbyDoc: Object }) {
      state.lobbyDoc = payload.newLobbyDoc;
    },
    [UPDATE_SURVEY_1_ANSWER](state, payload: { newSurvey1Answer: string[] }) {
      state.survey1Answer = payload.newSurvey1Answer;
    },
    [UPDATE_ISUSERUSINGCUSTOMGAMEROOMID](
      state,
      payload: { isUserUsingCustomGameRoomId: boolean }
    ) {
      state.isUserUsingCustomGameRoomId = payload.isUserUsingCustomGameRoomId;
    },
    [UPDATE_ACTIVEGAMEROOMID](state, payload: { activeGameRoomId: string }) {
      state.activeGameRoomId = payload.activeGameRoomId;
    },
    [UPDATE_ACTIVEGAMENAME](state, payload: { activeGameName: string }) {
      state.activeGameName = payload.activeGameName;
    },
    [UPDATE_USER_SUBSCRIPTION_DETAILS](
      state,
      payload: { newSubscriptionDetails: SubscriptionDetails }
    ) {
      state.subscriptionDetails = payload.newSubscriptionDetails;
    },

    [UPDATE_USER_TESTIMONIAL](state, payload: { newUserTestimonial: Object }) {
      state.userTestimonial = payload.newUserTestimonial;
    },
    [UPDATE_EDITORS_PICK_GAMES](
      state,
      payload: { newEditorsPickGames: Object[] }
    ) {
      state.editorsPickGames = payload.newEditorsPickGames;
    },
    [UPDATE_MOST_POPULAR_GAMES](
      state,
      payload: { newMostPopularGames: Object[] }
    ) {
      state.mostPopularGames = payload.newMostPopularGames;
    },
    [UPDATE_USER_CUSTOMIZED_GAMES](
      state,
      payload: { newUserCustomizedGames: Object[] }
    ) {
      state.userCustomizedGames = payload.newUserCustomizedGames;
    },
    [UPDATE_MEDITATION_TRACKS](
      state,
      payload: { newMeditationTracks: Object[] }
    ) {
      state.meditationTracks = payload.newMeditationTracks;
    },
    [UPDATE_SLACK_STATUS](state, payload: { newSlackStatus: Object[] }) {
      state.slackStatus = payload.newSlackStatus;
    },
    [UPDATE_SLACK_CHANNELS](
      state,
      payload: { newSlackChannels: Object[]; reservedChannels: String[] }
    ) {
      state.slackChannels = payload.newSlackChannels;
      state.reservedChannels = payload.reservedChannels;
    },
    [UPDATE_TEAM_INFO](state, payload: { newTeamInfo: Object }) {
      state.teamInfo = payload.newTeamInfo;
    },
    [UPDATE_PROMOTION_FLAG](
      state,
      payload: { newPromotionFlag: string | undefined }
    ) {
      state.promotionFlag = payload.newPromotionFlag;
    },
    [UPDATE_SLACK_MODAL_STATUS](
      state,
      payload: { slackSuccessModalSeen: boolean }
    ) {
      state.slackSuccessModalSeen = payload.slackSuccessModalSeen;
    },
    [UPDATE_ZOOM_STATUS](
      state,
      payload: { zoomClientStatus: ZoomClientConfig }
    ) {
      state.zoomClientStatus = payload.zoomClientStatus;
    },
    [UPDATE_CURRENT_GAME_DATA](state, payload: { newCurrentGameData: Object }) {
      state.currentGameData = payload.newCurrentGameData;
    }
  },
  actions: {},
  plugins: []
});

export function useStore() {
  return baseUseStore(key);
}
