
import { store } from '@/store';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'PricingFAQ',
  props: {
    version: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    function couponHref() {
      const origin = window.location.origin;
      return `${origin}/pricing?getCouponCode=t`;
    }
    const inZoomClient = computed(() => {
      return store.state.zoomClientStatus?.inZoomClient ?? false;
    });
    const v5QuestionsAndAnswers = [
      {
        id: 0,
        question: 'What currency are the prices in?',
        answer: 'All prices are in US dollars (USD).',
        inZoomPricing: true
      },
      {
        id: 0,
        question: 'What currency are the prices in?',
        answer: 'All prices are in US dollars (USD).',
        inZoomPricing: true
      },
      {
        id: 1,
        question: 'What are the payment options?',
        answer:
          'We use Stripe to process our payments, which accepts all credit cards, Google Pay and Apple Pay.',
        inZoomPricing: true
      },
      {
        id: 2,
        question: 'Can I cancel my paid subscription at any time?',
        answer:
          'Yes, you can. You will be downgraded to the Free plan at your next renewal date. Your subscription will remain active at its current level through the end of the billing period that you’ve already paid for.',
        inZoomPricing: true
      },
      {
        id: 3,
        question:
          'Can I let other members of my team host their own activities on Brightful?',
        answer:
          'Yes! If you sign up for a PRO plan, you can allow team members to host their own games and access other team building functions on Brightful. They own a room ID and are able to customize them. You can add or remove members anytime in the dashboard.',
        inZoomPricing: false
      },

      {
        id: 4,
        question:
          'How will Brightful keep track of how many participants are in my subscription?',
        answer:
          'Brightful keeps track of participants by their email address. Participants that are counted towards your subscription include those who join your game room, as well as those who are added to your Brightful Slack App channel. For example: if you play a Brightful game with Adam and Luca, then connect to the Brightful Slack App where Adam and Darren are in your channel, you will have a total of 4 participants registered to your plan. If you sign up for a PRO plan with a site-wide license, Brightful will count the participants across all game rooms and connected Slack channels used by all team members.',
        inZoomPricing: false
      },

      {
        id: 5,
        question: 'Do you offer any discount for schools and educators?',
        answer: `We offer 25% off for schools and educators when you commit to an annual subscription. Sign up with your K-12 educator email and then come back here to click this: <a class="color-blue" href="${couponHref()}" target="_blank" >Get coupon code</a>. Use this code when you input your payment info for an annual subscription - the Educator discount will automatically be applied to your subscription. If you believe you qualify for an Educator discount but the coupon code does not appear, send an email to support@brightful.me.`,
        inZoomPricing: false
      },
      {
        id: 6,
        question:
          'I want to sign up for Brightful but I need to convince my manager or teammates. Can you help me?',
        answer:
          'Definitely! Please send us an email at <a href="mailto:support@brightful.me">support@brightful.me</a> about what kind of help you need, and we’ll get you the right information.',
        inZoomPricing: true
      }
    ];
    const v6QuestionsAndAnswers = [
      {
        id: 0,
        question: 'What currency are the prices in?',
        answer: 'All prices are in US dollars (USD).',
        inZoomPricing: true
      },
      {
        id: 1,
        question: 'What are the payment options?',
        answer:
          'We use Stripe to process our payments, which accepts all credit cards, Google Pay and Apple Pay.',
        inZoomPricing: true
      },
      {
        id: 2,
        question: 'Can I cancel my paid subscription at any time?',
        answer:
          'Yes, you can. You will be downgraded to the Free plan at your next renewal date. Your subscription will remain active at its current level through the end of the billing period that you’ve already paid for.',
        inZoomPricing: true
      },
      {
        id: 3,
        question:
          'Can I let other members of my team host their own activities on Brightful?',
        answer:
          'Yes. Please email <a href="mailto:sales@brightful.me">sales@brightful.me</a> and we can provide more information regarding pricing and features to you.',
        inZoomPricing: false
      },
      {
        id: 4,
        question:
          'I want to sign up for Brightful but I need to convince my manager or teammates. Can you help me?',
        answer:
          'Definitely! Please send us an email at <a href="mailto:support@brightful.me">support@brightful.me</a> about what kind of help you need, and we’ll get you the right information.',
        inZoomPricing: true
      }
    ];
    const isV6 = props.version === 6;
    const filteredQuestions = isV6
      ? inZoomClient.value
        ? v6QuestionsAndAnswers.filter(ques => ques.inZoomPricing)
        : v6QuestionsAndAnswers
      : inZoomClient.value
      ? v5QuestionsAndAnswers.filter(ques => ques.inZoomPricing)
      : v5QuestionsAndAnswers;
    return {
      filteredQuestions
    };
  }
});
