
import { computed, defineComponent } from 'vue';
import BounceLoading from '@/components/BounceLoading.vue';
import QANameList from '@/components/Modal/WhiteboardComponent/QA/QANameList.vue';
import QAParticipantScreen from '@/components/Modal/WhiteboardComponent/QA/QAParticipantScreen.vue';

import useFirebaseAuth from '@/hooks/firebase/useFirebaseAuth';
export default defineComponent({
  name: 'QAModal',
  components: {
    QANameList,
    BounceLoading,
    QAParticipantScreen
  },
  emits: ['close'],
  props: {
    whiteboardData: {
      type: Object,
      required: true
    },
    participantsData: {
      type: Array,
      required: true
    },
    whiteboardId: {
      type: String,
      required: true
    },
    isModalOpen: {
      type: Boolean,
      required: true
    }
  },
  setup(props, context) {
    const isLoading = computed(() => {
      if (
        !props.whiteboardData ||
        props.whiteboardData === {} ||
        !props.participantsData ||
        props.participantsData.length == 0
      )
        return true;
      return false;
    });
    const { currentUser } = useFirebaseAuth();
    const isHost = computed(() => {
      return props.whiteboardData?.currentHost == currentUser?.value?.uid;
    });
    const close = function() {
      context.emit('close');
    };

    return { close, isLoading, isHost };
  }
});
