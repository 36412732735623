
import { defineComponent, onMounted } from 'vue';

export default defineComponent({
  name: 'RedirectAuthandGameRoom',
  setup() {
    onMounted(() => {
      const url = window.location.href;
      const action = url.substring(url.lastIndexOf('/') + 1);
      if (action.includes('signup') || action.includes('login'))
        window.location.replace(
          `${window.location.origin}/room/auth/${action.replace(
            'signup',
            'login'
          )}`
        );
      else window.location.replace(`${window.location.origin}/room/${action}`);
    });
  }
});
