
import { getGameCssByType, getGameTitleByType } from '@/utils/gameConstants';
import { computed, defineComponent, ref } from 'vue';
import useFirebaseCollection from '@/hooks/firebase/useFirebaseCollection';
import { useStore } from '@/store';
import { getGameWinners } from '@/utils/helper';
import useFirebaseDocument from '@/hooks/firebase/useFirebaseDocument';
export default defineComponent({
  name: 'HistoryScoreGameTile',
  props: {
    game: {
      type: Object,
      required: true
    }
  },
  emits: ['open'],
  setup(props, context) {
    const store = useStore();
    const user = computed(() => store.getters.getUser);
    const gameRoomId = ref(props.game.gameRoomId);
    const gamePlayId = ref(props.game.gamePlayId);
    const gameName = ref(props.game.gameName);
    const title = ref(getGameTitleByType(gameName.value));
    const customGameName = ref(undefined as string | undefined);
    const customGameCss = ref(undefined as string | undefined);
    const dateString = ref(props.game.dateCreated.format('MMM DD'));
    const playersCount = ref(0);
    const winners = ref([] as string[]);
    const hostName = ref(undefined as string | undefined);
    const game = ref(Object.assign({}, props.game));
    const isFinished = computed(() => props.game.isFinished);

    const playersQuery = props.game.isCurrent
      ? useFirebaseCollection(`minigame_data/${gameRoomId.value}/players`, {
          onMounted: false
        })
      : useFirebaseCollection(
          `minigame_gameplay_history/${gameRoomId.value}/game_plays/${gamePlayId.value}/players`,
          {
            onMounted: false
          }
        );

    async function getGamePlayers() {
      await playersQuery.getCollection({});
      if (user.value && props.game.host !== user.value.uid) {
        const host = playersQuery.collectionData.value!.find(
          player => player.playerId === props.game.host
        );
        if (host) {
          hostName.value = host.name;
        }
      }

      const actualPlayers = Object.assign(
        [],
        props.game.isHostPlaying === false
          ? playersQuery.collectionData.value!.filter(
              player => player.playerId !== props.game.host
            )
          : playersQuery.collectionData.value!
      );
      playersCount.value = actualPlayers.length;
      game.value['players'] = playersQuery.collectionData.value;

      if (isFinished.value) {
        const result = getGameWinners(game.value);
        winners.value = result
          .filter(player => player.win)
          .map(player => player.name);
      }
    }

    async function getCustomGameInfo() {
      if (game.value.isCustomGame && game.value.customGameId) {
        const customGameDocumentProps = useFirebaseDocument(
          'minigame_custom_game_public',
          {
            onMounted: false
          }
        );
        await customGameDocumentProps.getDocument(10, game.value.customGameId);
        if (customGameDocumentProps.documentData.value) {
          customGameName.value =
            customGameDocumentProps.documentData.value.customGameName;
          customGameCss.value = getGameCssByType(
            customGameDocumentProps.documentData.value.gameName
          );
          game.value['customGameName'] = customGameName.value;
          game.value['customGameCss'] = customGameCss.value;
        }
      }
    }

    getGamePlayers();
    getCustomGameInfo();

    function onOpenModal() {
      if (isFinished.value) {
        context.emit('open', game.value);
      }
    }

    return {
      isFinished,
      gameName,
      title,
      customGameName,
      customGameCss,
      dateString,
      playersCount,
      winners,
      hostName,
      onOpenModal
    };
  }
});
