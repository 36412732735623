
import { defineComponent } from 'vue';
import LivePoll from '@/components/EventTools/LivePoll/LivePoll.vue';
import { EventToolsType } from '@/utils/event_tools_enums';
import SpinTheWheel from '../EventTools/SpinTheWheel/SpinTheWheel.vue';

export default defineComponent({
  name: 'EventToolsDetailsView',
  emits: ['launch-modal'],
  props: {
    type: {
      type: String,
      required: true
    }
  },
  components: {
    LivePoll,
    SpinTheWheel
  },
  setup(props, context) {
    function onLaunchModal(event) {
      context.emit('launch-modal', event);
    }

    return {
      onLaunchModal,
      EventToolsType
    };
  }
});
