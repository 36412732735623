
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LeaderboardListSkeletonLoading',
  components: {},
  props: {},
  setup() {
    return {};
  }
});
