
const fabric = require('fabric').fabric;
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import firebaseApp from '@/firebaseApp';
import useFirebaseDocument from '@/hooks/firebase/useFirebaseDocument';
import useFirebaseCollection from '@/hooks/firebase/useFirebaseCollection';
import useDisconnectListener from '@/hooks/firebase/useDisconnectListener';
import useFirebaseAuth from '@/hooks/firebase/useFirebaseAuth';
import { amplitudeEvent } from '@/config/amplitudeConfig';
import {
  Tools,
  PenColors,
  PenTools,
  ActionTools
} from '@/components/Whiteboard/ToolTypes';
import WhoIsHostingMessage from '@/components/Whiteboard/WhoIsHostingMessage.vue';
import QAModal from '@/components/Modal/WhiteboardComponent/QAModal.vue';
import PollModal from '@/components/Modal/WhiteboardComponent/PollComponents/PollModal.vue';
import ShareButtonModal from '@/components/Modal/WhiteboardComponent/ShareButtonModal.vue';
import SetNewPasswordModal from '@/components/Modal/WhiteboardComponent/SetNewPasswordModal.vue';
import ConfirmDeleteWhiteboardModal from '@/components/Modal/WhiteboardComponent/ConfirmDeleteWhiteboardModal.vue';
import LoginAsHostModal from '@/components/Modal/WhiteboardComponent/LoginAsHostModal.vue';
import WhiteboardDeletedModal from '@/components/Modal/WhiteboardComponent/WhiteboardDeletedModal.vue';
import BounceLoading from '@/components/BounceLoading.vue';
import Popover from '@/components/Popover.vue';
import { getTriggeredRole } from '@/utils/helper';

export default defineComponent({
  name: 'WhiteboardPainting',
  components: {
    WhoIsHostingMessage,
    QAModal,
    PollModal,
    ShareButtonModal,
    SetNewPasswordModal,
    ConfirmDeleteWhiteboardModal,
    LoginAsHostModal,
    WhiteboardDeletedModal,
    BounceLoading,
    Popover
  },
  props: {
    whiteboardId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const showInitialLoading = ref(true);
    const showMenu = ref(false);
    const showQAModal = ref(false);
    const showPollModal = ref(false);
    const showShareButtonModal = ref(false);
    const showSetNewPasswordModal = ref(false);
    const showConfirmDeleteWhiteboardModal = ref(false);
    const showLoginAsHostModal = ref(false);
    const showWhiteboardDeletedModal = ref(false);
    const unreadCounts = ref({});

    watch([showQAModal, showPollModal], ([shownQA, shownPoll]) => {
      if (shownQA || shownPoll) {
        document.body.className += ' has-right-panel modal-open';
      } else {
        document.body.className = document.body.className.replaceAll(
          ' has-right-panel modal-open',
          ''
        );
      }
    });
    const whiteboardPassword = ref('');
    const hasActivePoll = ref(false);
    const hasNotVoted = ref(false);

    const isMobile = ref(
      computed(() => {
        return navigator.userAgent.match(/(iPad|iPhone|iPod|android)/i);
      })
    );

    function hideMenu() {
      showMenu.value = false;
    }

    function toggleMenu() {
      showMenu.value = !showMenu.value;
    }

    function showQA() {
      showPollModal.value = false;
      if (!showQAModal.value) {
        amplitudeEvent('openQA', {
          whiteboardId: props.whiteboardId,
          trigger: getTriggeredRole(isHost.value)
        });
      }
      showQAModal.value = true;
    }

    function onQAModalClosed() {
      showQAModal.value = false;
      currentMessage.value = participantMessagesCount.value;
    }

    function showPoll() {
      showQAModal.value = false;
      showPollModal.value = true;
    }

    function onPollModalClosed() {
      showPollModal.value = false;
    }

    function showShareButton() {
      showShareButtonModal.value = true;
    }

    function onShareButtonModalClosed() {
      showShareButtonModal.value = false;
    }

    function showSetNewPassword() {
      showMenu.value = false;
      showSetNewPasswordModal.value = true;
    }

    function onSetNewPasswordModalClosed() {
      showSetNewPasswordModal.value = false;
    }

    function showConfirmDeleteWhiteboard() {
      showMenu.value = false;
      showConfirmDeleteWhiteboardModal.value = true;
    }

    function onConfirmDeleteWhiteboardModalClosed() {
      showConfirmDeleteWhiteboardModal.value = false;
    }

    function showLoginAsHost() {
      showMenu.value = false;
      showLoginAsHostModal.value = true;
    }

    function onLoginAsHostModalClosed() {
      showLoginAsHostModal.value = false;
    }

    function participantHasVoted() {
      hasNotVoted.value = false;
    }

    function participantHasNotVoted() {
      // show poll modal when particiapnt not voted yet
      if (hasActivePoll.value) showPoll();
      hasNotVoted.value = true;
    }

    // below are variables related to drawing capabilities
    const isFirstTimeDrawn = ref(true);
    const toolSelected = ref('');
    const currentPageNumber = ref(1);
    const hostFocusedPage = ref(1);
    const flashingCount = ref(5);
    const totalPages = ref(0);
    const isHost = ref(false);
    const isSelected = ref(false);
    const hostName = ref('');
    const isHostDisconnected = ref(false);
    const showWhoIsHostingMessage = ref(false);
    const numOfOnlineParticipants = ref(0);
    const isClubhouseLink = ref(false);
    const clubhouseLink = ref('');
    const maxPages = 4;
    let hostUid = '';
    let canvas = null as null | any;
    let strokeWidth = 3;
    let timerInterval: number | undefined = undefined;
    let originWidth: number | undefined = undefined;
    let originHeight: number | undefined = undefined;

    const firebasePageDocument = useFirebaseDocument(
      `whiteboard_data/${props.whiteboardId}/pages`,
      {
        onMounted: false
      }
    );

    // whiteboardData
    const firebaseWhiteboardDocument = useFirebaseDocument(`whiteboard_data`, {
      documentId: props.whiteboardId,
      onMounted: true,
      onSnapshot: true
    });

    // all participants
    const participantsListener = useFirebaseCollection(
      `whiteboard_data/${props.whiteboardId}/participants`,
      {
        onMounted: true
      },
      true
    );
    useDisconnectListener(props.whiteboardId);
    const { currentUser } = useFirebaseAuth();

    // current participant
    const firebaseParticipantDocument = useFirebaseDocument(
      `whiteboard_data/${props.whiteboardId}/participants`,
      {
        documentId: currentUser?.value?.uid,
        onMounted: true,
        onSnapshot: true
      }
    );

    // QA chat
    // let setUnreadCount = function(unreadCounts: any) {
    //   return firebaseParticipantDocument.updateDocument({
    //     unreadCounts: unreadCounts
    //   });
    // };
    // let setParticipantMessageCount = function(uid: string, count: any) {
    //   return firebaseParticipantDocument.updateDocument({
    //     participantMessagesCount: count
    //   });
    // };

    // // channels
    // const hostChannelsCollection = useFirebaseCollection(
    //   `whiteboard_channel/`,
    //   {
    //     onMounted: true,
    //     fieldPath: 'whiteboardId',
    //     opStr: '==',
    //     value: props.whiteboardId,
    //     orderBy: 'lastMessageDate',
    //     isDesc: true
    //   },
    //   true
    // );

    // channel

    // // messages
    // const messagesCollection = function(channelId: string) {
    //   return useFirebaseCollection(
    //     `whiteboard_channel/${channelId}/messages`,
    //     {
    //       onMounted: true,
    //       orderBy: 'dateCreated',
    //       isDesc: true
    //     },
    //     true
    //   );
    // };

    // // members
    // const membersCollection = function(channelId: string) {
    //   return useFirebaseCollection(
    //     `whiteboard_channel/${channelId}/members`,
    //     {
    //       onMounted: true
    //     },
    //     true
    //   );
    // };
    const currentSnapshotIndex = ref([] as number[]);
    const canRedo = ref([] as boolean[]);
    const snapshotHistory = [] as [number, string][][];

    const paintObjects = [] as any[];
    const paintData = [] as any[];

    // BOARD STATE
    const pushSnapshot = (json: any, index: number) => {
      currentSnapshotIndex.value[index] += 1;
      if (canRedo.value[index]) {
        snapshotHistory[index] = snapshotHistory[index].slice(
          0,
          currentSnapshotIndex.value[index]
        );
        canRedo.value[index] = false;
      }
      const boardState = JSON.stringify(json);
      snapshotHistory[index].push([Date.now(), boardState]);
    };

    const canvasToJson = () => {
      const json = canvas.toJSON();
      json.objects.map((object: any) => {
        object['hostWidth'] = canvas.width;
        object['hostHeight'] = canvas.height;
      });
      return json;
    };

    const sendCanvas = async (json: any) => {
      const batch = firebaseApp.firestore().batch();
      const objects = json.objects;
      try {
        const snapshot = await firebaseApp
          .firestore()
          .collection('whiteboard_data')
          .doc(props.whiteboardId)
          .collection('pages')
          .doc(currentPageNumber.value.toString())
          .collection('paintings')
          .get();
        snapshot.docs.forEach(doc => {
          batch.delete(doc.ref);
        });
        (objects as Array<any>).forEach((value, index) => {
          const temp = {} as any;
          Object.keys(value).forEach(
            key => (temp[key] = JSON.stringify(value[key]))
          );
          batch.set(
            firebaseApp
              .firestore()
              .collection('whiteboard_data')
              .doc(props.whiteboardId)
              .collection('pages')
              .doc(currentPageNumber.value.toString())
              .collection('paintings')
              .doc(index.toString()),
            temp
          );
        });
        await batch.commit();
      } catch (err) {
        console.error(err);
      }
    };

    // TOOL SELECTION

    const toggleObjectsSelectable = (selectable: boolean) => {
      canvas.discardActiveObject();
      canvas.forEachObject((obj: any) => {
        obj.selectable = selectable;
        obj.evented = selectable;
      });
      canvas.renderAll();
    };

    const loadCanvasFromJson = (json: any) => {
      if (!canvas) {
        return;
      }
      json.objects.map((object: any) => {
        if (object['hostWidth'] && object['hostHeight']) {
          const scale = Math.min(
            canvas.width / object['hostWidth'],
            canvas.height / object['hostHeight']
          );
          object['left'] = (object['left'] ?? 0) * scale;
          object['top'] = (object['top'] ?? 0) * scale;
          object['scaleX'] = (object['scaleX'] ?? 1) * scale;
          object['scaleY'] = (object['scaleY'] ?? 1) * scale;
        }
      });
      canvas.loadFromJSON(json, canvas.renderAll.bind(canvas));
      if (!isHost.value) {
        toggleObjectsSelectable(false);
      }
    };

    const clickedTool = (tool: Tools) => {
      if (!ActionTools.includes(tool)) {
        canvas.selection = isHost.value && tool === Tools.Select;

        toggleObjectsSelectable(isHost.value && tool === Tools.Select);

        canvas.isDrawingMode = isHost.value && PenTools.includes(tool);

        toolSelected.value = tool;
      }

      switch (tool) {
        case Tools.Pen_Black:
        case Tools.Pen_Blue:
        case Tools.Pen_Red:
        case Tools.Pen_Yellow:
          canvas.freeDrawingBrush.color = PenColors[PenTools.indexOf(tool)];
          canvas.freeDrawingBrush.width = strokeWidth;
          return;
        case Tools.Clear: {
          canvas.remove(...canvas.getObjects());
          const json = canvasToJson();
          pushSnapshot(json, currentPageNumber.value - 1);
          sendCanvas(json);
          amplitudeEvent('clearPage', {
            whiteboardId: props.whiteboardId,
            pageNumber: currentPageNumber.value
          });
          return;
        }
        case Tools.Undo:
          if (currentSnapshotIndex.value[currentPageNumber.value - 1] !== 0) {
            currentSnapshotIndex.value[currentPageNumber.value - 1] -= 1;
            loadCanvasFromJson(
              JSON.parse(
                snapshotHistory[currentPageNumber.value - 1][
                  currentSnapshotIndex.value[currentPageNumber.value - 1]
                ][1]
              )
            );
            sendCanvas(canvasToJson());
            canRedo.value[currentPageNumber.value - 1] = true;
          }
          return;
        case Tools.Redo:
          if (
            currentSnapshotIndex.value[currentPageNumber.value - 1] !==
              snapshotHistory[currentPageNumber.value - 1].length - 1 &&
            canRedo.value[currentPageNumber.value - 1]
          ) {
            currentSnapshotIndex.value[currentPageNumber.value - 1] += 1;
            canRedo.value[currentPageNumber.value - 1] =
              currentSnapshotIndex.value[currentPageNumber.value - 1] !==
              snapshotHistory[currentPageNumber.value - 1].length - 1;
            loadCanvasFromJson(
              JSON.parse(
                snapshotHistory[currentPageNumber.value - 1][
                  currentSnapshotIndex.value[currentPageNumber.value - 1]
                ][1]
              )
            );
            sendCanvas(canvasToJson());
          }
          return;
        case Tools.Delete:
          if (canvas.getActiveObjects()) {
            canvas.remove(...canvas.getActiveObjects());
            canvas.discardActiveObject();
            const json = canvasToJson();
            pushSnapshot(json, currentPageNumber.value - 1);
            sendCanvas(json);
          }
          return;
        default:
          return;
      }
    };

    // ADDING SHAPES

    const addShape = (shape: any) => {
      shape.evented = false;
      shape.selectable = false;
      canvas.add(shape);
    };

    const createNewShape = (options: any) => {
      switch (toolSelected.value) {
        case Tools.Text:
          return new fabric.IText('Write some text', options);
        default:
          return {};
      }
    };

    const addText = (mouse: { x: number; y: number }) => {
      const options = {
        left: mouse.x,
        top: mouse.y,
        fill: PenColors[0],
        fontFamily: 'Arial',
        fontSize: 26
      };
      const shape = createNewShape(options);
      addShape(shape);
      canvas.setActiveObject(shape);
      canvas.renderAll();
      clickedTool(Tools.Select);
      const json = canvasToJson();
      pushSnapshot(json, currentPageNumber.value - 1);
      sendCanvas(json);
      amplitudeEvent('addText', {
        whiteboardId: props.whiteboardId,
        pageNumber: currentPageNumber.value
      });
    };

    // LISTENING TO CANVAS EVENTS

    const onMouseUp = (event: any) => {
      const mouse = canvas.getPointer(event.e);
      switch (toolSelected.value) {
        case Tools.Pen_Black:
        case Tools.Pen_Blue:
        case Tools.Pen_Red:
        case Tools.Pen_Yellow: {
          canvas.renderAll();
          const json = canvasToJson();
          pushSnapshot(json, currentPageNumber.value - 1);
          sendCanvas(json);
          if (isFirstTimeDrawn.value) {
            isFirstTimeDrawn.value = false;
            amplitudeEvent('draw', {
              whiteboardId: props.whiteboardId
            });
          }
          return;
        }
        case Tools.Text:
          addText(mouse);
          return;
        default:
          return;
      }
    };

    const listenToMouseUp = () => {
      if (isHost.value) {
        canvas.on('mouse:up', onMouseUp);
      } else {
        canvas.off('mouse:up', onMouseUp);
      }
    };

    const onObjectModified = () => {
      const json = canvasToJson();
      pushSnapshot(json, currentPageNumber.value - 1);
      sendCanvas(json);
    };

    const listenToObjectModified = () => {
      if (isHost.value) {
        canvas.on('object:modified', onObjectModified);
      } else {
        canvas.off('object:modified', onObjectModified);
      }
    };

    const onSelectionUpdated = () => {
      isSelected.value = canvas.getActiveObjects().length > 0;
    };

    const listenToSelectionUpdated = () => {
      if (isHost.value) {
        canvas.on('selection:cleared', onSelectionUpdated);
        canvas.on('selection:updated', onSelectionUpdated);
        canvas.on('selection:created', onSelectionUpdated);
      } else {
        canvas.off('selection:cleared', onSelectionUpdated);
        canvas.off('selection:updated', onSelectionUpdated);
        canvas.off('selection:created', onSelectionUpdated);
      }
    };

    const listenToCanvasEvents = () => {
      listenToMouseUp();
      listenToObjectModified();
      listenToSelectionUpdated();
    };

    //

    const addListener = (i: number, mounted: boolean) => {
      const firebaseCollection = useFirebaseCollection(
        `whiteboard_data/${props.whiteboardId}/pages/${i + 1}/paintings`,
        {
          onMounted: mounted
        },
        true
      );
      currentSnapshotIndex.value.push(-1);
      canRedo.value.push(false);
      snapshotHistory.push([]);

      paintObjects.push(firebaseCollection);

      paintData.push(
        computed(() => {
          const result = [] as any;
          paintObjects[i].collectionData.value.forEach(
            (object: { [x: string]: string }) => {
              const temp = {} as any;
              Object.keys(object).forEach(key => {
                temp[key] = JSON.parse(object[key]);
              });
              result.push(temp);
            }
          );
          return {
            version: '4.3.1',
            objects: result
          };
        })
      );

      watch(paintData[i], newValue => {
        if (i == currentPageNumber.value - 1) {
          loadCanvasFromJson(JSON.parse(JSON.stringify(newValue)));
          if (!isHost.value || snapshotHistory[i].length == 0) {
            pushSnapshot(newValue, i);
          }
        } else {
          pushSnapshot(newValue, i);
        }
      });

      if (!mounted) {
        firebaseCollection.getCollection({});
      }
    };

    const addPage = async () => {
      if (totalPages.value < maxPages) {
        await firebasePageDocument.createDocument(
          {
            page: totalPages.value + 1,
            dateCreated: firebaseApp.firestore.FieldValue.serverTimestamp()
          },
          (totalPages.value + 1).toString()
        );
        await firebaseWhiteboardDocument.updateDocument({
          id: props.whiteboardId,
          pageCount: totalPages.value + 1
        });
        selectPage(totalPages.value);
        amplitudeEvent('addPage', {
          whiteboardId: props.whiteboardId,
          pageNumber: totalPages.value + 1
        });
      }
    };

    const selectPage = (pageN: number) => {
      currentPageNumber.value = pageN;

      if (
        snapshotHistory[currentPageNumber.value - 1][
          currentSnapshotIndex.value[currentPageNumber.value - 1]
        ]
      ) {
        loadCanvasFromJson(
          JSON.parse(
            snapshotHistory[currentPageNumber.value - 1][
              currentSnapshotIndex.value[currentPageNumber.value - 1]
            ][1]
          )
        );
        if (!isHost.value) {
          toggleObjectsSelectable(false);
        }
      } else {
        canvas.remove(...canvas.getObjects());
      }
      amplitudeEvent('goToPage', {
        whiteboardId: props.whiteboardId,
        pageNumber: currentPageNumber.value,
        trigger: getTriggeredRole(isHost.value)
      });
    };

    const updateHostPage = async () => {
      if (isHost.value) {
        await firebaseWhiteboardDocument.updateDocument({
          id: props.whiteboardId,
          hostFocusedPage: currentPageNumber.value
        });
      }
    };

    const onResize = function() {
      if (!originWidth || !originHeight) {
        originWidth = window.innerWidth;
        originHeight = window.innerHeight;
      }
      if (
        /Android/i.test(navigator.userAgent) &&
        window.innerWidth + window.innerHeight + 50 < originWidth + originHeight
      ) {
        console.log('android keyboard');
      } else {
        originWidth = window.innerWidth;
        originHeight = window.innerHeight;
        const canvasContainer = document.getElementById('canvas');
        canvas.setWidth(canvasContainer?.clientWidth);
        canvas.setHeight(canvasContainer?.clientHeight);
        selectPage(currentPageNumber.value);
      }
    };

    const initCanvas = function() {
      canvas = new fabric.Canvas('canvas', {
        selection: false,
        uniScaleTransform: true
      });
      clickedTool(Tools.Pen_Black);

      window.addEventListener('resize', onResize);
      onResize();
      canvas.selectionFullyContained = false;

      fabric.Object.prototype.set({
        transparentCorners: false,
        borderColor: '#63CBAB',
        cornerColor: '#87E5CA'
      });

      resetCanvas();
    };

    const resetCanvas = () => {
      canvas.isDrawingMode = isHost.value;
      listenToCanvasEvents();
      if (!isHost.value) {
        toggleObjectsSelectable(false);
      } else {
        updateHostPage();
      }
    };
    watch(firebaseParticipantDocument.documentData, newValue => {
      if (newValue && newValue.unreadCounts) {
        unreadCounts.value = newValue.unreadCounts;
      }
      if (newValue && newValue.participantMessagesCount) {
        participantMessagesCount.value = newValue.participantMessagesCount;
        if (currentMessage.value == -1) {
          currentMessage.value = participantMessagesCount.value;
        }
      }
    });

    watch(firebaseWhiteboardDocument.documentData, newWhiteboardDoc => {
      if (newWhiteboardDoc && currentUser.value !== null) {
        // show WhiteboardDeletedModal when whiteboardId is undefined
        whiteboardData.value = newWhiteboardDoc;
        if (newWhiteboardDoc.whiteboardId === undefined) {
          showWhiteboardDeletedModal.value = true;
          return;
        }
        whiteboardPassword.value = newWhiteboardDoc['password'];
        hasActivePoll.value = newWhiteboardDoc['hasActivePoll'];
        if (hostFocusedPage.value != newWhiteboardDoc['hostFocusedPage']) {
          hostFocusedPage.value = newWhiteboardDoc['hostFocusedPage'];
          flashingCount.value = 0;
          if (timerInterval) {
            clearInterval(timerInterval);
          }
          timerInterval = setInterval(() => {
            flashingCount.value++;
            if (flashingCount.value >= 5 && timerInterval) {
              clearInterval(timerInterval);
            }
          }, 500);
        }
        const isNewHost =
          newWhiteboardDoc['currentHost'] === currentUser?.value?.uid;
        if (newWhiteboardDoc['pageCount']) {
          if (hostUid !== newWhiteboardDoc['currentHost']) {
            hostUid = newWhiteboardDoc['currentHost'];
            hostName.value = newWhiteboardDoc['currentHostName'];
            if (!isNewHost) {
              showWhoIsHostingMessage.value = true;
              setTimeout(() => {
                showWhoIsHostingMessage.value = false;
              }, 3000);
            }
          }
          isClubhouseLink.value =
            newWhiteboardDoc['clubhouseLink'] !== undefined &&
            newWhiteboardDoc['clubhouseLink'] !== null
              ? newWhiteboardDoc['clubhouseLink'].includes('joinclubhouse')
              : false;
          clubhouseLink.value = newWhiteboardDoc['clubhouseLink'];
          if (totalPages.value < newWhiteboardDoc['pageCount']) {
            for (
              let i = totalPages.value;
              i < newWhiteboardDoc['pageCount'];
              i++
            ) {
              addListener(i, false);
            }
            totalPages.value = newWhiteboardDoc['pageCount'];
          }
          if (!canvas) {
            setTimeout(() => {
              isHost.value = isNewHost;
              initCanvas();
            }, 100);
          } else if (isHost.value != isNewHost) {
            isHost.value = isNewHost;
            resetCanvas();
          }
        }
        showInitialLoading.value = false;
      }
    });

    watch(participantsListener.collectionData, participants => {
      if (participants) {
        participantsData.value = participants;
        numOfOnlineParticipants.value = participants.filter(
          participantDoc => participantDoc['isOnline']
        ).length;
        const hostParticipantDoc = participants.find(participantDoc => {
          return participantDoc.id === hostUid;
        });
        if (hostParticipantDoc === undefined) return;
        // show the message "No one is hosting" when host has disconnected
        if (hostParticipantDoc['isOnline']) {
          isHostDisconnected.value = false;
        } else {
          isHostDisconnected.value = true;
          showWhoIsHostingMessage.value = true;
          setTimeout(() => {
            showWhoIsHostingMessage.value = false;
          }, 3000);
        }
      }
    });

    watch(currentPageNumber, () => {
      if (isHost.value) {
        updateHostPage();
      }
    });

    const hasUnreadMessages = computed(() => {
      for (const value of Object.values(unreadCounts.value)) {
        if (value != 0) {
          return true;
        }
      }
      if (
        readMessageCount.value != -1 &&
        participantMessagesCount.value != readMessageCount.value
      )
        return true;
      return false;
    });

    const participantMessagesCount = ref(0);
    const currentMessage = ref(-1);
    const readMessageCount = computed(() => {
      if (showQAModal.value) {
        return participantMessagesCount.value;
      } else {
        return currentMessage.value;
      }
    });

    onMounted(() => {
      if (/Android/i.test(navigator.userAgent)) {
        const viewHeight = window.innerHeight;
        const viewWidth = window.innerWidth;
        const viewport = document.querySelector('meta[name=viewport]');

        viewport?.setAttribute(
          'content',
          'height=' + viewHeight + ', width=' + viewWidth + ', initial-scale=1'
        );
      }
    });

    const whiteboardData = ref({});
    const participantsData = ref([] as any[]);
    return {
      showInitialLoading,
      showMenu,
      toggleMenu,
      hideMenu,
      //
      showQAModal,
      showQA,
      onQAModalClosed,
      //
      showPollModal,
      showPoll,
      onPollModalClosed,
      //
      showShareButtonModal,
      showShareButton,
      onShareButtonModalClosed,
      //
      showSetNewPasswordModal,
      showSetNewPassword,
      onSetNewPasswordModalClosed,
      //
      showConfirmDeleteWhiteboardModal,
      showConfirmDeleteWhiteboard,
      onConfirmDeleteWhiteboardModalClosed,
      //
      showLoginAsHostModal,
      showLoginAsHost,
      onLoginAsHostModalClosed,
      //
      showWhiteboardDeletedModal,
      //
      whiteboardPassword,
      //
      hasNotVoted,
      participantHasVoted,
      participantHasNotVoted,
      //
      toolSelected,
      strokeWidth,
      Tools,
      currentSnapshotIndex,
      canRedo,
      currentPageNumber,
      hostFocusedPage,
      flashingCount,
      totalPages,
      maxPages,
      isHost,
      isSelected,
      hostName,
      isHostDisconnected,
      showWhoIsHostingMessage,
      numOfOnlineParticipants,
      hasActivePoll,
      isClubhouseLink,
      clubhouseLink,
      clickedTool,
      selectPage,
      addPage,
      unreadCounts,
      hasUnreadMessages,
      participantMessagesCount,
      readMessageCount,
      whiteboardData,
      participantsData,
      isMobile
    };
  }
});
