export class WouldYouRatherQuestion {
  public question: string = '';
  public choice1: string = '';
  public choice2: string = '';
  public index: number = -1;
  public key: number = -1;

  public constructor(fields?: {
    question?: string;
    choice1?: string;
    choice2?: string;
    index?: number;
    key?: number;
  }) {
    if (fields) Object.assign(this, fields);
  }
}
