
import { computed, defineComponent, ref } from 'vue';
import firebaseApp from '@/firebaseApp';
import useFirebaseAuth from '@/hooks/firebase/useFirebaseAuth';
import { amplitudeEvent } from '@/config/amplitudeConfig';
export default defineComponent({
  name: 'PollParticipantVote',
  props: {
    whiteboardId: {
      type: String,
      required: true
    },
    pollDoc: {
      type: Object,
      required: true
    },
    pollOptions: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const { currentUser } = useFirebaseAuth();
    const buttonLoading = ref(false);
    const errorMessage = ref('');
    const selectedOptionOne = ref('');
    const selectedOptionMultiple = ref([]);

    const validOption = computed(() => {
      if (props.pollDoc['type'] === 'multiple-choice-one') {
        return selectedOptionOne.value !== '';
      }
      return selectedOptionMultiple.value.length !== 0;
    });

    const castVote = async function() {
      buttonLoading.value = true;
      if (currentUser.value === null) return;
      const batch = firebaseApp.firestore().batch();
      const pollRef = firebaseApp
        .firestore()
        .collection(`whiteboard_data/${props.whiteboardId}/polls`)
        .doc(props.pollDoc['id']);
      try {
        if (props.pollDoc['type'] === 'multiple-choice-one') {
          batch.update(
            pollRef.collection('answers').doc(selectedOptionOne.value),
            {
              lastUpdated: firebaseApp.firestore.FieldValue.serverTimestamp(),
              voters: firebaseApp.firestore.FieldValue.arrayUnion(
                currentUser.value.uid
              )
            }
          );
        } else {
          for (let optionId of selectedOptionMultiple.value) {
            batch.update(pollRef.collection('answers').doc(optionId), {
              lastUpdated: firebaseApp.firestore.FieldValue.serverTimestamp(),
              voters: firebaseApp.firestore.FieldValue.arrayUnion(
                currentUser.value.uid
              )
            });
          }
        }
        batch.update(pollRef, {
          voteCounts: firebaseApp.firestore.FieldValue.increment(1)
        });
        await batch.commit();
        amplitudeEvent('pollAnswer', {
          whiteboardId: props.whiteboardId
        });
      } catch (err) {
        console.log(err);
        errorMessage.value =
          'An unexpected error has occured. Please try again later or contact support@brightful.me for more info.';
      } finally {
        buttonLoading.value = false;
      }
    };

    return {
      buttonLoading,
      errorMessage,
      selectedOptionOne,
      selectedOptionMultiple,
      validOption,
      castVote
    };
  }
});
