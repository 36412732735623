
import { Ref, computed, defineComponent, ref } from 'vue';
import {
  eventToolsHistoryDateFormat,
  eventToolsParticipantsWithoutHost,
  qnaDateFormat
} from '@/utils/helper';

// import { shareLivePollResult } from '@/utils/shareResult';
import { store } from '@/store';
import {
  Qna,
  QnaActivityHistory,
  QnaQuestion
} from '@/models/event_tools/qna/qna';
import firebase from 'firebase';
import QnaQuestionsList from './QnaQuestionsList.vue';
import BounceLoading from '@/components/BounceLoading.vue';
// import BounceLoading from '@/components/BounceLoading.vue';
export default defineComponent({
  name: 'QnaDetailModal',
  props: {
    qna: Object as () => Qna,
    game: Object as () => QnaActivityHistory,
    getResult: {
      type: Boolean,
      required: false
    }
  },
  emits: ['back'],
  setup(props, context) {
    const userDoc = computed(() => store.getters.getUserDoc);
    const questions: Ref<Array<QnaQuestion>> = ref([]);
    const qnaData = ref(props.qna);
    async function fetchQna() {
      try {
        const snapshot = await firebase
          .firestore()
          .doc(
            `minigame_lobbies/${userDoc.value['defaultLobbyId']}/qna/${
              props.game!.sessionId
            }`
          )
          .get();
        if (snapshot.exists) {
          qnaData.value = snapshot.data() as Qna;
        }
      } catch (err) {
        console.log(err);
      }
    }
    if (props.getResult) {
      console.log('fetching qna');
      fetchQna();
    }
    async function fetcQuestions() {
      try {
        const sessionId = props.qna
          ? props.qna.sessionId
          : props.game?.sessionId;
        const snapshots = await firebase
          .firestore()
          .collection(
            `minigame_lobbies/${userDoc.value['defaultLobbyId']}/qna/${sessionId}/questions`
          )
          .orderBy('dateCreated', 'desc')
          .get();

        if (!snapshots.empty && snapshots.docs.length != 0) {
          questions.value = snapshots.docs.map(
            doc => doc.data() as QnaQuestion
          );
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetcQuestions();
    function onBack() {
      context.emit('back');
    }
    const isShown = ref(false);
    const participants = computed(() => {
      if (qnaData.value?.participants) {
        const actualParticipants = eventToolsParticipantsWithoutHost(
          qnaData.value?.participants
        );
        if (actualParticipants.length > 7 && !isShown.value) {
          return actualParticipants.slice(0, 7);
        }
        return actualParticipants;
      }
      return [];
    });

    function onShowMore() {
      isShown.value = true;
    }
    return {
      questions,
      qnaData,
      isShown,
      participants,
      eventToolsParticipantsWithoutHost,
      qnaDateFormat,
      onShowMore,
      onBack,
      eventToolsHistoryDateFormat
    };
  },
  components: {
    QnaQuestionsList,
    BounceLoading
  }
});
