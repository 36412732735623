
import { useStore } from '@/store';
import { computed, defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'TypeformSurvey',
  props: {
    autoOpen: {
      type: Boolean,
      required: true,
      default: true
    },
    email: {
      type: String,
      required: true
    }
  },
  emits: ['interactPanel'],
  setup(props, context) {
    const store = useStore();
    const userDoc = computed(() => store.getters.getUserDoc);
    const subscriptionDetails = computed(
      () => store.getters.getSubscriptionDetails
    );
    const amplitudeUserId =
      userDoc.value['amplitudeUserId'] !== undefined
        ? userDoc.value['amplitudeUserId']
        : userDoc.value['id'];
    const dateCreated = userDoc.value['dateCreated'].toDate();
    const signupDate = `${dateCreated.getUTCFullYear()}-${pad(
      dateCreated.getUTCMonth() + 1
    )}-${pad(dateCreated.getUTCDate())}`;
    const survey1Answer =
      userDoc.value['survey1Answer'] !== undefined
        ? userDoc.value['survey1Answer'].join(',')
        : 'null';
    const typeformLink = `https://form.typeform.com/to/xovFUHFh?typeform-medium=embed-snippet#email=${props.email}&amp_id=${amplitudeUserId}&plan=${subscriptionDetails.value.subscriptionLevel}&signup_date=${signupDate}&play_purpose=${survey1Answer}`;

    const isAutoOpen = ref(props.autoOpen);

    const interactPanel = function() {
      context.emit('interactPanel');
    };

    function pad(number: number) {
      if (number < 10) return '0' + number;
      return number;
    }

    onMounted(() => {
      (function() {
        var js,
          q,
          d = document,
          gi = d.getElementById,
          ce = d.createElement,
          gt = d.getElementsByTagName,
          id = 'typef_orm_share',
          b = 'https://embed.typeform.com/';
        if (!gi.call(d, id)) {
          js = ce.call(d, 'script') as any;
          js.id = id;
          js.src = b + 'embed.js';
          q = gt.call(d, 'script')[0];
          if (q.parentNode !== null) q.parentNode.insertBefore(js, q);
        }
      })();
    });

    return {
      typeformLink,
      isAutoOpen,
      interactPanel
    };
  }
});
