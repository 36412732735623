
import {
  LivePollResult,
  RatingLivePoll
} from '@/models/event_tools/live_poll/live_poll_model';

import { defineComponent, ref } from 'vue';
export default defineComponent({
  name: 'RatingHistoryDetail',

  props: {
    result: Object as () => LivePollResult
  },
  emits: ['close'],
  setup(props) {
    const poll = ref(props.result?.poll as RatingLivePoll);
    return { poll };
  }
});
