
import { defineComponent, ref } from 'vue';
import router from '@/router';

export default defineComponent({
  name: 'HistoryUpgradePlanModal',
  components: {},
  props: {},
  emits: ['close'],
  setup(_, context) {
    const show = ref(true);

    const close = function() {
      show.value = false;
      setTimeout(() => {
        context.emit('close');
      }, 50);
    };

    function goToPricing() {
      router.push({ name: 'Pricing', query: { source: 'history' } });
    }

    return {
      show,
      close,
      goToPricing
    };
  }
});
