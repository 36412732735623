
import router from '@/router';
import {
  getEndpoint,
  getPeriodEndDate,
  openUrlOnExternalBrowserFromZoom,
  subscriptionText
} from '@/utils/helper';
import { isExpiredFreeTrialPlan } from '@/utils/pricing_rules';
import { computed, defineComponent, ref } from 'vue';
import useFirebaseLoginOut from '@/hooks/firebase/useFirebaseLoginOut';
import { amplitudeEvent } from '@/config/amplitudeConfig';
import usePostAPI from '@/hooks/usePostAPI';
import { useStore } from '@/store';
import { getAccountFeatures } from '@/utils/pricingConstant';
import moment from 'moment';
import { TeamRole } from '@/models/TeamRole';
import {
  availableForUpgrade,
  isFreePlan,
  isFreeTrialPlan,
  isOneOffPlan,
  stripeMeteredActive
} from '@/utils/pricing_rules';
import { NavigationTabs } from '@/models/navigationTabs';
export default defineComponent({
  name: 'BrightfulHeader',
  props: {
    onlyUI: {
      type: Boolean,
      default: false
    },
    overrideTopHeader: {
      type: Boolean,
      default: false
    },
    showAccountsMenu: {
      type: Boolean,
      default: true
    },
    hideBackToDashboard: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'account',
    'mobile-nav',
    'show-cancel-subscription',
    'show-renew-subscription'
  ],
  setup(props, context) {
    const store = useStore();
    const user = computed(() => store.getters.getUser);
    const userDoc = computed(() => store.getters.getUserDoc);
    const useTopHeader =
      router.currentRoute.value.path.includes('pricing') ||
      router.currentRoute.value.path.includes('team') ||
      router.currentRoute.value.path.includes('feedback') ||
      props.overrideTopHeader;
    const subscriptionDetails = computed(
      () => store.getters.getSubscriptionDetails
    );
    const loginState = useFirebaseLoginOut();
    let userEmail;
    let isFingerprintJSFlagged = false;
    let isCancelled;
    let periodEndDate;
    let oneOffEventEndDate;
    let teamRole;
    let isTeamAccount;
    let freeTrialEndDate;
    let remainingFreeTrialDays;
    let remainingFreeTrialDaysText;
    let freeTrialEnded;
    let showPlanDetailsPopOver;
    let hasNotification;
    let subscriptionEnded;
    let isStripeMeteredActive;
    let subscriptionLevelText;
    let isUserInFreeTrial;
    let accountFeatures;
    let isOneOff;
    let showChangePlan;
    let showCancelSubscription;
    if (user.value) {
      userEmail = user.value.email;
      isFingerprintJSFlagged = userDoc.value['isFingerprintJSFlagged'] ?? false;
      isCancelled = computed(
        () => subscriptionDetails.value.stripeCancelAtPeriodEnd ?? false
      );
      periodEndDate = computed(() => {
        return getPeriodEndDate(
          subscriptionDetails.value.stripeCurrentPeriodEnd
        );
      });
      oneOffEventEndDate = computed(() => {
        if (userDoc.value.oneOffEventEndDate) {
          return moment(userDoc.value.oneOffEventEndDate.toDate()).format(
            'MMM DD, YYYY'
          );
        } else {
          return null;
        }
      });
      teamRole = computed(() => subscriptionDetails.value.teamRole);
      isTeamAccount = computed(
        () =>
          subscriptionDetails.value.teamId != null &&
          subscriptionDetails.value.teamId != undefined
      );
      freeTrialEndDate =
        userDoc.value['freeTrialEndDate'] !== undefined
          ? userDoc.value['freeTrialEndDate'].toDate()
          : undefined;

      remainingFreeTrialDays =
        moment(freeTrialEndDate).diff(moment(), 'days') + 1;

      remainingFreeTrialDaysText = computed(() => {
        if (remainingFreeTrialDays == 1) return 'Ends today';
        else if (remainingFreeTrialDays == 2) return 'Ends Tomorrow';
        return `${remainingFreeTrialDays} days left`;
      });
      freeTrialEnded = isExpiredFreeTrialPlan(
        subscriptionDetails.value,
        userDoc.value
      );
      showPlanDetailsPopOver = ref(false);

      hasNotification = ref(false);
      subscriptionEnded = computed(
        () => subscriptionDetails.value.subscriptionEnded ?? false
      );
      isStripeMeteredActive = computed(() =>
        stripeMeteredActive(subscriptionDetails.value)
      );
      subscriptionLevelText = computed(() => {
        return subscriptionText(subscriptionDetails.value);
      });

      isUserInFreeTrial =
        !freeTrialEnded && isFreeTrialPlan(subscriptionDetails.value);

      accountFeatures = ref(getAccountFeatures(subscriptionDetails.value));

      isOneOff = isOneOffPlan(subscriptionDetails.value);
      showChangePlan = computed(() => {
        return (
          !isCancelled.value && availableForUpgrade(subscriptionDetails.value)
        );
      });
      showCancelSubscription = computed(() => {
        return !isCancelled.value && !isOneOff;
      });
    }

    function showMobileNav() {
      context.emit('mobile-nav');
    }

    function showAccount() {
      context.emit('account');
    }

    function goToPricing() {
      router.push({ name: 'Pricing', query: { source: 'header' } });
    }

    function goToDashboard() {
      router.push({
        name: 'Dashboard',
        query: { tab: NavigationTabs.HOME, source: 'header' }
      });
    }

    async function goToBilling() {
      try {
        const fromZoomClient = store.state.zoomClientStatus?.inZoomClient;
        const zoomUid = store.state.zoomClientStatus?.uid ?? null;
        const response = await usePostAPI(
          getEndpoint('createPortalLink', '/api/stripe/'),
          {
            fromZoomClient: fromZoomClient,
            zoomUid: zoomUid,
            returnUrl:
              window.location.href.split('?')[0] +
              '?selected=subscribed&source=fromBilling'
          }
        );
        const result = response.data;
        amplitudeEvent('forwardToStripe', {
          action: 'billing',
          eventTime: new Date(Date.now()).toISOString()
        });
        const sessionUrl = result['data']['sessionUrl'];
        if (fromZoomClient) {
          // open in an external browser
          openUrlOnExternalBrowserFromZoom(sessionUrl);
        } else {
          window.location.href = sessionUrl;
        }
      } catch (err) {
        console.log(err);
      }
    }

    return {
      //
      userEmail,
      useTopHeader,
      isFingerprintJSFlagged,
      subscriptionDetails,
      isTeamAccount,
      teamRole,
      TeamRole,
      //
      freeTrialEnded,
      showPlanDetailsPopOver,

      hasNotification,
      subscriptionLevelText,
      isUserInFreeTrial,
      accountFeatures,
      isOneOff,
      isCancelled,
      periodEndDate,
      oneOffEventEndDate,
      showChangePlan,
      showCancelSubscription,
      showMobileNav,
      showAccount,
      goToPricing,
      goToDashboard,
      goToBilling,
      logout: loginState.logout,
      isFreePlan,
      isFreeTrialPlan,
      remainingFreeTrialDaysText,
      subscriptionEnded,
      isStripeMeteredActive
    };
  }
});
