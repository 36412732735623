
import usePostAPI from '@/hooks/usePostAPI';
import { getEndpoint } from '@/utils/helper';
import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'OrganizationSubscriptionLoadingModal',
  emits: ['close'],
  setup(_, context) {
    const buttonLoading = ref(true);
    const bodyText = ref('Upgrading your subscription');

    const close = function() {
      context.emit('close');
    };

    const subscribeOrgPlan = async function() {
      try {
        await usePostAPI(getEndpoint('subscribeOrgPlan', '/api/stripe/'), {});
        window.location.href =
          window.location.href.split('?')[0] +
          '?selected=org_subscription_success';
      } catch (err) {
        buttonLoading.value = false;
        if ((err as any).response !== undefined) {
          const errorMsg = (err as any).response.data.error;
          if (errorMsg === 'ineligible') {
            bodyText.value =
              'The email provided is not included in any bulk license';
          } else if (errorMsg === 'no_remaining_license') {
            bodyText.value =
              'The account limit for the license on this domain has already been reached. Please contact your administrator.';
          } else if (errorMsg === 'already_subscribed') {
            bodyText.value = 'You already have an active subscription.';
          } else {
            bodyText.value =
              'An unexpected error has occured. Please try again later or contact support@brightful.me for more info.';
          }
        }
      }
    };

    onMounted(() => {
      subscribeOrgPlan();
    });

    return {
      buttonLoading,
      bodyText,
      close,
      subscribeOrgPlan
    };
  }
});
