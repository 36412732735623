
import QAChannelItem from '@/components/Modal/WhiteboardComponent/QA/QAChannelItem.vue';
import QAMessageList from '@/components/Modal/WhiteboardComponent/QA/QAMessageList.vue';
import useFirebaseCollection from '@/hooks/firebase/useFirebaseCollection';
import { defineComponent, ref, watch } from 'vue';
export default defineComponent({
  name: 'QANameList',
  props: {
    whiteboardData: {
      type: Object,
      required: true
    },
    isModalOpen: {
      type: Boolean,
      required: true
    },

    participantsData: {
      type: Array,
      required: true
    }
  },
  components: {
    QAChannelItem,
    QAMessageList
  },
  setup(props) {
    const hostChannelsCollection = useFirebaseCollection(
      `whiteboard_channel/`,
      {
        onMounted: true,
        fieldPath: 'whiteboardId',
        opStr: '==',
        value: props?.whiteboardData?.id,
        orderBy: 'lastMessageDate',
        isDesc: true
      },
      true
    );
    watch(hostChannelsCollection.collectionData, channels => {
      if (channels) {
        currentChannels.value = channels;
      }
      loading.value = !currentChannels.value;
    });
    watch(props, newValue => {
      if (!newValue.isModalOpen && !isRoot.value) {
        isRoot.value = true;
        channelOpened.value = {};
      }
    });

    const onChannelClicked = function(channel: any) {
      let channelId: string = channel.id;
      channelOpened.value = currentChannels.value.find((chan: any) => {
        return chan.id == channelId;
      });
      isRoot.value = false;
    };
    const onBack = function() {
      isRoot.value = true;
      channelOpened.value = {};
    };
    const loading = ref(true);
    const currentChannels = ref([] as any[]);
    const isRoot = ref(true);
    const channelOpened = ref({});

    return {
      currentChannels,
      loading,
      isRoot,
      channelOpened,
      onChannelClicked,
      onBack
    };
  }
});
