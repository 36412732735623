
import { defineComponent, onMounted } from 'vue';
import { amplitudeEvent } from '@/config/amplitudeConfig';
// import NumberOfPlayersSpotMyLie from '@/components/Modal/AboutComponent/NumberOfPlayersComponents/NumberOfPlayersSpotMyLie.vue';
import HowToPlaySpotMyLie from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlaySpotMyLie.vue';
import SampleQuestionSpotMyLie from '@/components/Modal/AboutComponent/SampleQuestionComponents/SampleQuestionSpotMyLie.vue';

export default defineComponent({
  name: 'AboutSpotMyLie',
  components: {
    // NumberOfPlayersSpotMyLie,
    HowToPlaySpotMyLie,
    SampleQuestionSpotMyLie
  },
  setup() {
    onMounted(() => {
      amplitudeEvent('previewGame', {
        game: 'spot_my_lie',
        version: 'regular',
        eventTime: new Date(Date.now()).toISOString()
      });
    });
  }
});
