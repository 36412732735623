
import { defineComponent } from 'vue';
import GameInfo from '@/components/GameInfo.vue';
import {
  friendlyCompetitionGameTypes,
  boostConversationGameTypes,
  socialDeductionGameTypes,
  mindfulGameTypes,
  cardGameTypes
} from '@/utils/gameConstants';

export default defineComponent({
  name: 'BrightfulActivities',
  emits: ['about', 'view-details'],
  components: {
    GameInfo
  },
  props: {
    selectedType: {
      type: String
    }
  },
  setup(_, context) {
    function viewDetails(event) {
      context.emit('view-details', event);
    }

    function showAbout(type: string) {
      context.emit('about', type);
    }

    return {
      friendlyCompetitionGameTypes,
      boostConversationGameTypes,
      socialDeductionGameTypes,
      mindfulGameTypes,
      cardGameTypes,
      viewDetails,
      showAbout
    };
  }
});
