
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import {
  liteMonthly,
  liteAnnually,
  proMonthly,
  proAnnually,
  basicMonthly,
  basicAnnually,
  professionalMonthly,
  professionalAnnually,
  basicEducatorMonthly,
  basicEducatorAnnually,
  professionalEducatorMonthly,
  professionalEducatorAnnually,
  liteMonthlyPrice,
  liteAnnuallyPrice,
  proMonthlyPrice,
  proAnnuallyPrice,
  enterpriseMonthlyPrice,
  enterpriseAnnuallyPrice,
  nLiteParticipantsIncluded,
  nProParticipantsIncluded,
  liteParticipantsUnitRate,
  proParticipantsUnitRate
} from '@/utils/pricingConstant';
import useGetAPI from '@/hooks/useGetAPI';
import { useStore } from '@/store';
import { SubscriptionLevel } from '@/models/SubscriptionLevel';
import { SubscriptionInterval } from '@/models/SubscriptionInterval';
import ParticipantsInfoModal from '@/components/Pricing/Modal/ParticipantsInfoModal.vue';
import OkModal from '@/components/Modal/OkModal.vue';
import CouponCodeModal from '@/components/Pricing/Modal/CouponCodeModal.vue';
import CompletedFormModal from '@/components/Pricing/Modal/CompletedFormModal.vue';

import TestimonialsAndCompanies from '@/components/Pricing/TestimonialsAndCompanies.vue';
import usePostAPI from '@/hooks/usePostAPI';
import { oneOffEventV5 } from '@/utils/pricingConstant';
import {
  getEndpoint,
  isDev,
  isInvalidEducationEmail,
  isPlayground
} from '@/utils/helper';

export default defineComponent({
  name: 'AllPlansV5',
  components: {
    CompletedFormModal,
    ParticipantsInfoModal,
    CouponCodeModal,
    OkModal,
    TestimonialsAndCompanies
  },
  emits: ['proceed-checkout'],
  setup(_, context) {
    const store = useStore();
    const subscriptionDetails = computed(
      () => store.getters.getSubscriptionDetails
    );

    const loadingButton = ref('');
    const isEducatorEmail = ref(false);
    const mobileSelectedTab = ref(0);
    const isCouponCodeLoading = ref(false);
    const couponCodeError = ref('');
    const showParticipantsModal = ref(false);
    const showCouponCodeModal = ref(false);
    const basicEducatorMonthlyPrice = 25;
    const basicEducatorAnnuallyPrice = 5;
    const professionalEducatorMonthlyPrice = 129;
    const professionalEducatorAnnuallyPrice = 33;
    const isDesktop = ref(true);
    const showCollapsed = ref([false, false, false]);
    const user = computed(() => store.getters.getUser);
    const USDtoAUDRate = 1.37;

    // false = Annualy, true = Monthly
    const monthlyTabSelected = ref(false);
    const litePlanPrice = ref(liteAnnuallyPrice);
    const proPlanPrice = ref(proAnnuallyPrice);
    const enterprisePlanPrice = ref(enterpriseAnnuallyPrice);
    const basicEducatorPlanPrice = ref(basicEducatorAnnuallyPrice);
    const professionalEducatorPlanPrice = ref(
      professionalEducatorAnnuallyPrice
    );
    const litePlanPriceAUD = ref((liteAnnuallyPrice * USDtoAUDRate).toFixed(2));
    const proPlanPriceAUD = ref((proAnnuallyPrice * USDtoAUDRate).toFixed(2));
    const enterprisePlanPriceAUD = ref(
      (enterpriseAnnuallyPrice * USDtoAUDRate).toFixed(2)
    );
    const basicEducatorPlanPriceAUD = ref(
      (basicEducatorAnnuallyPrice * USDtoAUDRate).toFixed(2)
    );
    const professionalEducatorPlanPriceAUD = ref(
      (professionalEducatorAnnuallyPrice * USDtoAUDRate).toFixed(2)
    );

    const showBasicPlanMonthlyButton = computed(() => {
      if (
        subscriptionDetails.value.subscriptionLevel ===
          SubscriptionLevel.FREE ||
        subscriptionDetails.value.subscriptionInterval ===
          SubscriptionInterval.FREE_TRIAL
      ) {
        return true;
      }
      return false;
    });
    const showBasicPlanAnnuallyButton = computed(() => {
      if (
        subscriptionDetails.value.subscriptionLevel ===
          SubscriptionLevel.FREE ||
        subscriptionDetails.value.subscriptionInterval ===
          SubscriptionInterval.FREE_TRIAL ||
        (subscriptionDetails.value.subscriptionLevel ===
          SubscriptionLevel.BASIC &&
          subscriptionDetails.value.subscriptionInterval ===
            SubscriptionInterval.MONTH) ||
        (subscriptionDetails.value.subscriptionLevel ===
          SubscriptionLevel.BASIC_EDUCATOR &&
          subscriptionDetails.value.subscriptionInterval ===
            SubscriptionInterval.MONTH)
      ) {
        return true;
      }
      return false;
    });
    const showProfessionalPlanMonthlyButton = computed(() => {
      if (
        subscriptionDetails.value.subscriptionLevel ===
          SubscriptionLevel.FREE ||
        subscriptionDetails.value.subscriptionInterval ===
          SubscriptionInterval.FREE_TRIAL ||
        subscriptionDetails.value.subscriptionLevel ===
          SubscriptionLevel.BASIC ||
        subscriptionDetails.value.subscriptionLevel ===
          SubscriptionLevel.BASIC_EDUCATOR ||
        subscriptionDetails.value.subscriptionLevel ===
          SubscriptionLevel.PREMIUM ||
        subscriptionDetails.value.subscriptionLevel ===
          SubscriptionLevel.PREMIUM_EDUCATOR
      ) {
        return true;
      }
      return false;
    });
    const showProfessionalPlanAnnuallyButton = computed(() => {
      if (
        subscriptionDetails.value.subscriptionLevel ===
          SubscriptionLevel.FREE ||
        subscriptionDetails.value.subscriptionInterval ===
          SubscriptionInterval.FREE_TRIAL ||
        subscriptionDetails.value.subscriptionLevel ===
          SubscriptionLevel.BASIC ||
        subscriptionDetails.value.subscriptionLevel ===
          SubscriptionLevel.BASIC_EDUCATOR ||
        subscriptionDetails.value.subscriptionLevel ===
          SubscriptionLevel.PREMIUM ||
        subscriptionDetails.value.subscriptionLevel ===
          SubscriptionLevel.PREMIUM_EDUCATOR ||
        (subscriptionDetails.value.subscriptionLevel ===
          SubscriptionLevel.PROFESSIONAL &&
          subscriptionDetails.value.subscriptionInterval ===
            SubscriptionInterval.MONTH) ||
        (subscriptionDetails.value.subscriptionLevel ===
          SubscriptionLevel.PROFESSIONAL_EDUCATOR &&
          subscriptionDetails.value.subscriptionInterval ===
            SubscriptionInterval.MONTH)
      ) {
        return true;
      }
      return false;
    });

    const showLiteButton = computed(() => {
      const interval = subscriptionDetails.value.subscriptionInterval;
      const level = subscriptionDetails.value.subscriptionLevel;
      // hide for pro
      if (level == SubscriptionLevel.PRO) return false;
      else if (level == SubscriptionLevel.LITE) {
        if (interval == SubscriptionInterval.YEAR) {
          return false;
        } else if (interval == SubscriptionInterval.MONTH) {
          return !monthlyTabSelected.value;
        }
      }
      return true;
    });
    const showProButton = computed(() => {
      const interval = subscriptionDetails.value.subscriptionInterval;
      const level = subscriptionDetails.value.subscriptionLevel;

      if (level == SubscriptionLevel.LITE) return true;
      else if (level == SubscriptionLevel.PRO) {
        if (interval == SubscriptionInterval.YEAR) {
          return false;
        } else if (interval == SubscriptionInterval.MONTH) {
          return !monthlyTabSelected.value;
        }
      }

      return true;
    });
    const priceSuffix = computed(() => {
      if (monthlyTabSelected.value) return 'mo';
      return 'yr';
    });

    const showEducationSection = ref(false);
    const userInAustralia = ref(false);
    const showCompletedFormModal = ref(false);

    watch(monthlyTabSelected, newSelectedTab => {
      if (newSelectedTab) {
        litePlanPrice.value = liteMonthlyPrice;
        proPlanPrice.value = proMonthlyPrice;
        enterprisePlanPrice.value = enterpriseMonthlyPrice;

        litePlanPriceAUD.value = (liteMonthlyPrice * USDtoAUDRate).toFixed(2);
        proPlanPriceAUD.value = (proMonthlyPrice * USDtoAUDRate).toFixed(2);
        enterprisePlanPriceAUD.value = (
          enterpriseMonthlyPrice * USDtoAUDRate
        ).toFixed(2);
        basicEducatorPlanPrice.value = basicEducatorMonthlyPrice;
        basicEducatorPlanPriceAUD.value = (
          basicEducatorMonthlyPrice * USDtoAUDRate
        ).toFixed(2);
        professionalEducatorPlanPrice.value = professionalEducatorMonthlyPrice;
        professionalEducatorPlanPriceAUD.value = (
          professionalEducatorMonthlyPrice * USDtoAUDRate
        ).toFixed(2);
      } else {
        litePlanPrice.value = liteAnnuallyPrice;
        litePlanPriceAUD.value = (liteAnnuallyPrice * USDtoAUDRate).toFixed(2);
        basicEducatorPlanPrice.value = basicEducatorAnnuallyPrice;
        basicEducatorPlanPriceAUD.value = (
          basicEducatorAnnuallyPrice * USDtoAUDRate
        ).toFixed(2);

        proPlanPrice.value = proAnnuallyPrice;
        proPlanPriceAUD.value = (proAnnuallyPrice * USDtoAUDRate).toFixed(2);
        enterprisePlanPrice.value = enterpriseAnnuallyPrice;
        enterprisePlanPriceAUD.value = (
          enterpriseAnnuallyPrice * USDtoAUDRate
        ).toFixed(2);
        professionalEducatorPlanPrice.value = professionalEducatorAnnuallyPrice;
        professionalEducatorPlanPriceAUD.value = (
          professionalEducatorAnnuallyPrice * USDtoAUDRate
        ).toFixed(2);
      }
    });

    function proceedCheckout(priceId: string, plan: 'lite' | 'pro') {
      context.emit('proceed-checkout', {
        priceId: priceId,
        onLoading: () => {
          loadingButton.value = plan;
        },
        onSuccess: () => {
          loadingButton.value = '';
        },
        onError: () => {
          loadingButton.value = '';
        }
      });
    }

    async function isUserInAustralia() {
      const response = await useGetAPI('http://ip-api.com/json');
      if (response) {
        const countryCode = response['data']['countryCode'];
        userInAustralia.value = countryCode === 'AU';
      }
    }
    async function onParticipantsModalToggle() {
      showParticipantsModal.value = !showParticipantsModal.value;
    }
    const onResize = function() {
      isDesktop.value = window.innerWidth >= 1200;
    };
    function onCollapse(index: number) {
      if (isDesktop.value) return;
      showCollapsed.value[index] = !showCollapsed.value[index];
    }
    async function getCouponCode() {
      // buttonLoading.value = true;
      isCouponCodeLoading.value = true;
      const email = user.value['email'];

      try {
        await usePostAPI(getEndpoint('generateCoupon', '/api/stripe/'), {
          email: email
        });
        showCouponCodeModal.value = true;
        isCouponCodeLoading.value = false;
        console.log('Coupon code sent');
      } catch (err) {
        isCouponCodeLoading.value = false;
        return;
      }
    }
    async function validK12Email(email) {
      if (!email) return false;
      if (isDev || isPlayground) {
        console.log('.....1');
        return true;
      }
      return !(await isInvalidEducationEmail(email));
    }
    async function checkCouponCodeRequest() {
      const urlParams = new URLSearchParams(window.location.search);
      const isCouponCodeRequest = urlParams.get('getCouponCode') == 't';
      isEducatorEmail.value = await validK12Email(user.value['email']);
      if (isCouponCodeRequest) {
        console.log(`AllPlans: ${isEducatorEmail.value}`);
        if (isEducatorEmail.value) {
          await getCouponCode();
        } else {
          // show error dialog
          couponCodeError.value = 'A valid K-12 educator email is required.';
        }
      }
    }
    function planPriceStringify(num) {
      if (num % 1 != 0) return num.toFixed(2);
      return num;
    }
    onMounted(() => {
      isUserInAustralia();
      window.addEventListener('resize', onResize);
      onResize();
      checkCouponCodeRequest();
    });

    return {
      loadingButton,
      basicMonthly,
      basicAnnually,
      professionalMonthly,
      professionalAnnually,
      basicEducatorMonthly,
      basicEducatorAnnually,
      professionalEducatorMonthly,
      professionalEducatorAnnually,
      monthlyTabSelected,
      showBasicPlanMonthlyButton,
      showBasicPlanAnnuallyButton,
      showProfessionalPlanMonthlyButton,
      showProfessionalPlanAnnuallyButton,
      showEducationSection,
      userInAustralia,
      showCompletedFormModal,
      litePlanPrice,
      proPlanPrice,
      enterprisePlanPrice,
      basicEducatorPlanPrice,
      professionalEducatorPlanPrice,
      litePlanPriceAUD,
      proPlanPriceAUD,
      enterprisePlanPriceAUD,
      basicEducatorPlanPriceAUD,
      professionalEducatorPlanPriceAUD,
      showParticipantsModal,
      nLiteParticipantsIncluded,
      nProParticipantsIncluded,
      liteParticipantsUnitRate,
      proParticipantsUnitRate,
      liteMonthly,
      liteAnnually,
      proMonthly,
      proAnnually,
      showCouponCodeModal,
      showCollapsed,
      isDesktop,
      isCouponCodeLoading,
      couponCodeError,
      mobileSelectedTab,
      isEducatorEmail,
      showLiteButton,
      showProButton,
      oneOffEventV5,
      priceSuffix,
      proceedCheckout,
      onParticipantsModalToggle,
      onCollapse,
      getCouponCode,
      planPriceStringify
    };
  }
});
