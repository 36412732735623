export class TriviaQuestion {
  public question: string = '';
  public correct: string = '';
  public incorrect1: string = '';
  public incorrect2: string = '';
  public incorrect3: string = '';
  public index: number = -1;
  public key: number = -1;
  public imageData: any;
  public image: string = '';

  public constructor(fields?: {
    question?: string;
    correct?: string;
    incorrect1?: string;
    incorrect2?: string;
    incorrect3?: string;
    index?: number;
    key?: number;
    imageData?: any;
    image?: any;
  }) {
    if (fields) Object.assign(this, fields);
  }
}
