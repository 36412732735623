import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "top-message-bar top-message-bar-float" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = {
  key: 0,
  class: "col-lg-12 align-center"
}
const _hoisted_4 = {
  key: 1,
  class: "col-lg-12 align-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      (_ctx.isHostDisconnected)
        ? (_openBlock(), _createBlock("div", _hoisted_3, " No one is hosting at the moment "))
        : (_openBlock(), _createBlock("div", _hoisted_4, _toDisplayString(_ctx.hostName) + " is now hosting ", 1))
    ])
  ]))
}