import { toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "info" }
const _hoisted_2 = {
  key: 0,
  class: "br-icon icon-clock size-14"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "bounce1"
}
const _hoisted_5 = {
  key: 2,
  class: "bounce2"
}
const _hoisted_6 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: ["recently-played-item-v2", { 'skeleton-loading': _ctx.skeleton }]
  }, [
    _createVNode("div", _hoisted_1, [
      _createVNode("h4", {
        class: _ctx.getGameCssByType(_ctx.game.gameName)
      }, [
        _createTextVNode(_toDisplayString(_ctx.getGameTitleByType(_ctx.game.gameName)) + " ", 1),
        _createVNode("span", null, _toDisplayString(_ctx.skeleton ? '' : _ctx.game.customGameName), 1)
      ], 2),
      _createVNode("p", null, [
        (!_ctx.skeleton)
          ? (_openBlock(), _createBlock("i", _hoisted_2))
          : _createCommentVNode("", true),
        _createVNode("span", null, _toDisplayString(_ctx.game.dateCreated ? _ctx.moment(_ctx.game.dateCreated.toDate()).fromNow() : ''), 1)
      ])
    ]),
    _createVNode("button", {
      class: ["btn btn-md btn-orange more-padding add-36", { 'loading spinner': _ctx.isLoading }],
      disabled: _ctx.isLoading,
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.startGame && _ctx.startGame(...args)))
    }, [
      (!_ctx.isLoading)
        ? (_openBlock(), _createBlock("div", _hoisted_3, " Play "))
        : _createCommentVNode("", true),
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock("div", _hoisted_4))
        : _createCommentVNode("", true),
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock("div", _hoisted_5))
        : _createCommentVNode("", true),
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock("div", _hoisted_6))
        : _createCommentVNode("", true)
    ], 10, ["disabled"])
  ], 2))
}