
import { textFromChoices } from '@/utils/gameConstants';
import { arrayRemove } from '@/utils/helper';
import { computed, defineComponent, onMounted, ref } from 'vue';
import BounceLoading from '@/components/BounceLoading.vue';

export default defineComponent({
  name: 'CustomGameSuggestions',
  emits: ['picked', 'refresh', 'report'],
  components: {
    BounceLoading
  },
  props: {
    gameName: {
      type: String,
      required: true
    },
    content: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      requried: true
    },
    suggestionLimitReached: {
      type: Boolean,
      requried: false
    },
    insufficientEntries: {
      type: Boolean,
      requried: false
    },
    isSuggestionUnsafe: {
      type: Boolean,
      requried: false
    }
  },
  setup(props, ctx) {
    onMounted(() => {
      hideAiPannel();
      hideReportModal();
    });

    const listContent = computed(() => props.content);
    const visibleLength = 6;
    const fewListContent = computed(
      () => props.content.slice(0, visibleLength) as any[]
    );
    const aiPanelClasses = ref(['ai-panel']);
    const smartSuggestionClasses = ref(['btn', 'btn-sm', 'close-ai-panel']);
    const hideAiPannel = function() {
      aiPanelClasses.value.push('hide');
      document.body.className = document.body.className.replaceAll(
        ' show-with-ai-panel',
        ''
      );
      if (!props.insufficientEntries) {
        tryMeShown.value = true;
      }
    };
    const showTryMe = function() {
      smartSuggestionClasses.value.push('has-tryme-speech');
    };
    const hideTryMe = function() {
      smartSuggestionClasses.value = arrayRemove(
        aiPanelClasses.value,
        'has-tryme-speech'
      );
    };
    const hideReportModal = function() {
      reportingItem.value = '';
      document
        .getElementById('reportContent')
        ?.setAttribute('style', 'display:none');
    };
    const showReportModal = function(item: any) {
      reportingItem.value = item;
      document.getElementById('reportContent')?.setAttribute('style', '');
    };
    const showAiPannel = function() {
      aiPanelClasses.value = arrayRemove(aiPanelClasses.value, 'hide');
      document.body.className += ' show-with-ai-panel';
      if (!props.insufficientEntries) {
        tryMeShown.value = true;
      }
    };

    const reportingItem = ref();
    const showFootItems = computed(
      () =>
        !props.insufficientEntries &&
        !props.suggestionLimitReached &&
        !props.isSuggestionUnsafe
    );
    const tryMeShown = ref(false);
    const showTryMeButton = computed(() => {
      return (
        !tryMeShown.value &&
        aiPanelClasses.value.includes('hide') &&
        !props.insufficientEntries
      );
    });
    const onItemPick = function(item: any) {
      ctx.emit('picked', item);
    };
    const onRefresh = function() {
      ctx.emit('refresh');
    };
    const onReport = function() {
      ctx.emit(
        'report',
        reportingItem.value,
        textFromItem(reportingItem.value)
      );
      hideReportModal();
    };
    const textFromItem = function(val: any) {
      return textFromChoices(props.gameName, val);
    };
    return {
      hideAiPannel,
      showAiPannel,
      aiPanelClasses,
      listContent,
      fewListContent,
      onItemPick,
      onRefresh,
      onReport,
      textFromItem,
      hideReportModal,
      showReportModal,
      reportingItem,
      showFootItems,
      showTryMe,
      hideTryMe,
      smartSuggestionClasses,
      showTryMeButton
    };
  }
});
