
import { defineComponent, onMounted, onUpdated } from 'vue';
import ProgressBar from 'progressbar.js';
export default defineComponent({
  name: 'PollResultBox',
  props: {
    pollDoc: {
      type: Object,
      required: true
    },
    pollOptions: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const bars: any[] = [];
    onMounted(() => {
      for (let i = 0; i < props.pollOptions.length; i++) {
        const pollOption = props.pollOptions[i] as any;
        const pollOptionVoteCounts = pollOption['voters'].length;
        const pollAllVoteCounts = props.pollDoc['voteCounts'];
        const bar = new ProgressBar.Line(`#poll-result-option-${i + 1}`, {
          strokeWidth: 4,
          easing: 'easeInOut',
          duration: 1400,
          color: '#2AC969',
          trailColor: '#E7E7E7',
          trailWidth: 4,
          svgStyle: { width: '100%', height: '100%' }
        });
        bar.animate(
          pollOptionVoteCounts !== 0
            ? pollOptionVoteCounts / pollAllVoteCounts
            : 0
        );
        bars.push(bar);
      }
    });

    onUpdated(() => {
      for (let i = 0; i < props.pollOptions.length; i++) {
        const pollOption = props.pollOptions[i] as any;
        const pollOptionVoteCounts = pollOption['voters'].length;
        const pollAllVoteCounts = props.pollDoc['voteCounts'];
        bars[i].animate(
          pollOptionVoteCounts !== 0
            ? pollOptionVoteCounts / pollAllVoteCounts
            : 0
        );
      }
    });
  }
});
