import { toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, Teleport as _Teleport } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "team-manage"
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "inside-1-line " }
const _hoisted_6 = { class: "heading color-primary" }
const _hoisted_7 = { class: "row mt-4" }
const _hoisted_8 = { class: "col-lg-12 col-xl-7" }
const _hoisted_9 = { class: "heading inside-1-line" }
const _hoisted_10 = { class: "team-list list-group list-group-flush" }
const _hoisted_11 = { class: "col-lg-12 col-xl-5 hide-mobile hide-tablet hide-small-desktop" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TeamMemberTile = _resolveComponent("TeamMemberTile")
  const _component_TeamMemberInviteBox = _resolveComponent("TeamMemberInviteBox")
  const _component_EditTeamNameModal = _resolveComponent("EditTeamNameModal")
  const _component_AssignTeamRoleModal = _resolveComponent("AssignTeamRoleModal")
  const _component_ConfirmationModal = _resolveComponent("ConfirmationModal")
  const _component_OkModal = _resolveComponent("OkModal")
  const _component_InviteTeamModal = _resolveComponent("InviteTeamModal")

  return (_openBlock(), _createBlock(_Fragment, null, [
    (_ctx.user)
      ? (_openBlock(), _createBlock("section", _hoisted_1, [
          _createVNode("div", _hoisted_2, [
            _createVNode("div", _hoisted_3, [
              _createVNode("div", _hoisted_4, [
                _createVNode("div", _hoisted_5, [
                  _createVNode("h4", _hoisted_6, _toDisplayString(_ctx.teamInfo.name), 1),
                  _createVNode("button", {
                    class: "btn btn-sm btn-transparent no-padding mb-1 ml-2",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openEditTeamNameModal()))
                  }, " Edit ")
                ])
              ])
            ]),
            _createVNode("div", _hoisted_7, [
              _createVNode("div", _hoisted_8, [
                _createVNode("h6", _hoisted_9, [
                  _createTextVNode(_toDisplayString(_ctx.teamInfo.members.length) + " " + _toDisplayString(_ctx.teamInfo.members.length == 1 ? 'member' : 'members') + " ", 1),
                  _createVNode("button", {
                    class: "btn btn-xs btn-blue-stroke ml-3 hide-desktop",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openInviteTeamModal()))
                  }, " Invite ")
                ]),
                _createVNode("ul", _hoisted_10, [
                  (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.teamInfo.members, (member, index) => {
                    return (_openBlock(), _createBlock(_component_TeamMemberTile, {
                      key: member.uid,
                      member: member,
                      onTeamRole: ($event: any) => (_ctx.openAssignTeamRoleModal(index)),
                      onLeaveTeam: ($event: any) => (_ctx.removeUserFromTeam(index))
                    }, null, 8, ["member", "onTeamRole", "onLeaveTeam"]))
                  }), 128))
                ])
              ]),
              _createVNode("div", _hoisted_11, [
                _createVNode(_component_TeamMemberInviteBox, {
                  teamInfo: _ctx.teamInfo,
                  onEnableLink: _cache[3] || (_cache[3] = ($event: any) => (_ctx.enableLink($event))),
                  onResetLink: _cache[4] || (_cache[4] = ($event: any) => (_ctx.openConfirmResetLinkModal()))
                }, null, 8, ["teamInfo"])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (_ctx.showEditTeamNameModal)
            ? (_openBlock(), _createBlock(_component_EditTeamNameModal, {
                key: 0,
                name: _ctx.teamInfo.name,
                onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.closeEditTeamNameModal($event)))
              }, null, 8, ["name"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (_ctx.showAssignTeamRoleModal)
            ? (_openBlock(), _createBlock(_component_AssignTeamRoleModal, {
                key: 0,
                role: _ctx.teamInfo.members[_ctx.selectedMemberIndex].role,
                onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.closeAssignTeamRoleModal($event)))
              }, null, 8, ["role"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (_ctx.showConfirmResetLinkModal)
            ? (_openBlock(), _createBlock(_component_ConfirmationModal, {
                key: 0,
                title: "Reset invite links?",
                description: "People will not be albe to use the old invite links to join your team.",
                okText: "Reset links",
                onClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.closeConfirmResetLinkModal($event)))
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (_ctx.showConfirmDowngradeModal)
            ? (_openBlock(), _createBlock(_component_ConfirmationModal, {
                key: 0,
                description: "You will no longer have privileges as a Admin. Are you sure you want to change your role to Host?",
                okText: "confirm",
                onClose: _cache[8] || (_cache[8] = ($event: any) => (_ctx.closeConfirmDowngradeModal($event)))
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (_ctx.showConfirmLeaveTeamModal)
            ? (_openBlock(), _createBlock(_component_ConfirmationModal, {
                key: 0,
                description: "You will not be able to use any features exclusive to paid accounts on Brightful. Are you sure you want to leave the team?",
                okText: "confirm",
                onClose: _cache[9] || (_cache[9] = ($event: any) => (_ctx.closeConfirmLeaveTeamModal($event)))
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (_ctx.showOwnerUnableToLeaveTeamModal)
            ? (_openBlock(), _createBlock(_component_OkModal, {
                key: 0,
                description: "You are unable to leave this team as you are the only owner in it.",
                onClose: _cache[10] || (_cache[10] = ($event: any) => (_ctx.closeOwnerUnableToLeaveTeamModal()))
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (_ctx.showConfirmRemoveFromTeamModal)
            ? (_openBlock(), _createBlock(_component_ConfirmationModal, {
                key: 0,
                description: 
          'Are you sure you want to remove ' +
            _ctx.teamInfo.members[_ctx.selectedMemberIndex].name +
            ' from the team?'
        ,
                okText: "confirm",
                onClose: _cache[11] || (_cache[11] = ($event: any) => (_ctx.closeConfirmRemoveFromTeamModal($event)))
              }, null, 8, ["description"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (_ctx.showInviteTeamModal)
            ? (_openBlock(), _createBlock(_component_InviteTeamModal, {
                key: 0,
                teamInfo: _ctx.teamInfo,
                onEnableLink: _cache[12] || (_cache[12] = ($event: any) => (_ctx.enableLink($event))),
                onResetLink: _cache[13] || (_cache[13] = ($event: any) => {
          _ctx.closeInviteTeamModal();
          _ctx.openConfirmResetLinkModal();
        }),
                onClose: _cache[14] || (_cache[14] = ($event: any) => (_ctx.closeInviteTeamModal()))
              }, null, 8, ["teamInfo"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]))
  ], 64))
}