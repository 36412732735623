import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PollHostLaunchPoll = _resolveComponent("PollHostLaunchPoll")
  const _component_PollHostResult = _resolveComponent("PollHostResult")

  return (_ctx.pollDoc === undefined && !_ctx.hasActivePoll)
    ? (_openBlock(), _createBlock(_component_PollHostLaunchPoll, {
        key: 0,
        whiteboardId: _ctx.whiteboardId,
        numParticipants: _ctx.numParticipants
      }, null, 8, ["whiteboardId", "numParticipants"]))
    : (_ctx.pollDoc !== undefined && _ctx.hasActivePoll)
      ? (_openBlock(), _createBlock("div", _hoisted_1, [
          _createVNode(_component_PollHostResult, {
            whiteboardId: _ctx.whiteboardId,
            pollDoc: _ctx.pollDoc
          }, null, 8, ["whiteboardId", "pollDoc"])
        ]))
      : _createCommentVNode("", true)
}