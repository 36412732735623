
import { defineComponent, onMounted } from 'vue';
import { amplitudeEvent } from '@/config/amplitudeConfig';
// import NumberOfPlayersPoll from '@/components/Modal/AboutComponent/NumberOfPlayersComponents/NumberOfPlayersPoll.vue';
import HowToPlayPoll from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayPoll.vue';
import SampleQuestionPoll from '@/components/Modal/AboutComponent/SampleQuestionComponents/SampleQuestionPoll.vue';

export default defineComponent({
  name: 'AboutPoll',
  components: {
    // NumberOfPlayersPoll,
    HowToPlayPoll,
    SampleQuestionPoll
  },
  setup() {
    onMounted(() => {
      amplitudeEvent('previewGame', {
        game: 'poll',
        version: 'regular',
        eventTime: new Date(Date.now()).toISOString()
      });
    });
  }
});
