import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "q-and-a-result" }
const _hoisted_2 = { class: "answer" }
const _hoisted_3 = { class: "result-detail" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("h5", null, _toDisplayString(`${_ctx.pollDoc['question']} ${
          _ctx.pollDoc['type'] === 'multiple-choice-multiple'
            ? '(Multiple-choice)'
            : ''
        }`), 1),
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.pollOptions.length, (index) => {
      return (_openBlock(), _createBlock("div", {
        class: "option",
        key: `option-${index}`
      }, [
        _createVNode("p", _hoisted_2, _toDisplayString(_ctx.pollOptions[index - 1]['answer']), 1),
        _createVNode("div", {
          id: `poll-result-option-${index}`
        }, null, 8, ["id"]),
        _createVNode("div", _hoisted_3, _toDisplayString(_ctx.pollOptions[index - 1]['voters'].length) + " (" + _toDisplayString(_ctx.pollOptions[index - 1]['voters'].length !== 0
            ? `${(
                (_ctx.pollOptions[index - 1]['voters'].length /
                  _ctx.pollDoc['voteCounts']) *
                100
              ).toFixed(0)}%`
            : '0%') + ") ", 1)
      ]))
    }), 128)),
    _createVNode("p", null, _toDisplayString(`${_ctx.pollDoc['voteCounts']} ${
          _ctx.pollDoc['voteCounts'] === 1
            ? 'attendee responsed'
            : 'attendees responsed'
        }`), 1)
  ]))
}