
import useFirebaseCollection from '@/hooks/firebase/useFirebaseCollection';
import useFirebaseDocument from '@/hooks/firebase/useFirebaseDocument';
import { defineComponent, ref, watch } from 'vue';
import BounceLoading from '@/components/BounceLoading.vue';

export default defineComponent({
  name: 'CustomizedGameExampleModal',
  emits: ['close'],
  components: {
    BounceLoading
  },
  props: {
    type: {
      type: String,
      required: true
    },
    customGameDocId: {
      type: String,
      required: true
    }
  },
  setup(props, context) {
    const isWordBased =
      props.type == 'draw_it' ||
      props.type == 'describe_it' ||
      props.type == 'charades';

    const customGameQuestionBank = useFirebaseCollection(
      `minigame_custom_game_public/${props.customGameDocId}/question_bank`,
      {
        onMounted: true,
        orderBy: 'index',
        limit: isWordBased ? 36 : 20
      }
    );
    const customGameProps = useFirebaseDocument('minigame_custom_game_public', {
      onMounted: true,
      documentId: props.customGameDocId
    });

    const show = ref(true);
    const isDisabled = ref(false);
    const isLoading = ref(false);
    const settings = ref({});
    const description = ref(undefined);
    watch(customGameProps.documentData, data => {
      if (data) {
        description.value = data.description;
      } else {
        description.value = undefined;
      }
    });

    const close = function() {
      show.value = false;
      setTimeout(() => {
        context.emit('close');
      }, 50);
    };

    return {
      isWordBased,
      description,
      ...customGameQuestionBank,
      show,
      isDisabled,
      isLoading,
      settings,
      close
    };
  }
});
