
import { defineComponent, onBeforeMount, ref, watch } from 'vue';
import { YesOrNoTopic } from '@/models/YesOrNoTopic';

export default defineComponent({
  name: 'GeneralYesOrNoInputItem',
  props: {
    id: {
      type: String,
      required: true
    },
    number: {
      type: Number,
      required: true
    },
    value: YesOrNoTopic || Object,
    checked: Boolean
  },
  emits: ['valuechanged', 'allfilled'],
  setup(props, context) {
    const checkedValue = ref(false);
    const word = ref('');
    const hint = ref('');

    const maxYesOrNoWordLength = 50;
    const maxYesOrNoHintLength = 140;

    const onValue = function(isCheckbox: Boolean) {
      context.emit('valuechanged', {
        checked: checkedValue.value,
        word: word.value,
        hint: hint.value,
        index: props.number
      });
      if (!isCheckbox) {
        if (word.value != '' && hint.value != '') {
          context.emit('allfilled');
        }
      }
    };

    onBeforeMount(() => {
      checkedValue.value = props.checked ?? false;
      word.value = props.value?.word ?? '';
      hint.value = props.value?.hint ?? '';
    });

    watch(props, newValue => {
      checkedValue.value = newValue.checked ?? false;
      word.value = props.value?.word ?? '';
      hint.value = props.value?.hint ?? '';
    });

    return {
      checkedValue,
      word,
      hint,
      maxYesOrNoWordLength,
      maxYesOrNoHintLength,
      onValue
    };
  }
});
