import { toRefs, reactive, computed } from 'vue';
import firebase from '@/firebaseApp';
import { getEndpoint, redirectToPlay } from '@/utils/helper';
import { SubscriptionLevel } from '@/models/SubscriptionLevel';
import { SubscriptionInterval } from '@/models/SubscriptionInterval';
import usePostAPI from '../usePostAPI';
import { TeamClaims } from '@/models/SubscriptionDetails';

export default function() {
  const state: {
    error: firebase.auth.Error | null;
    username: string;
    password: string;
    user: firebase.User | null;
    subscriptionLevel: SubscriptionLevel;
    subscriptionInterval: SubscriptionInterval | null;
    loading: boolean;
    teamId: string | null;
    teamRole: string | null;
  } = reactive({
    error: null,
    username: '',
    password: '',
    user: null,
    subscriptionLevel: SubscriptionLevel.FREE,
    subscriptionInterval: null,
    loading: false,
    teamId: null,
    teamRole: null
  });

  const isValid = computed(() => {
    const { username, password } = state;
    return (
      username !== null &&
      username.length !== 0 &&
      password !== null &&
      password.length !== 0
    );
  });

  const login = async () => {
    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(state.username, state.password);
    } catch (err) {
      state.error = err as any;
    }
  };

  const logout = () => {
    try {
      firebase.auth().signOut();
      redirectToPlay('logout');
    } catch (err) {
      state.error = err as any;
    }
  };

  const getSubscriptionDetails = async (forceRefresh?: boolean | undefined) => {
    state.loading = true;
    state.error = null;
    try {
      const idTokenResult = await firebase
        .auth()
        .currentUser?.getIdTokenResult(forceRefresh);

      const customClaims = idTokenResult?.claims ?? null;
      const subscriptionEnded = customClaims?.subscriptionEnded ?? false;

      if (
        customClaims === null ||
        customClaims === undefined ||
        customClaims.stripeRole === null ||
        customClaims.stripeRole === undefined
      ) {
        state.subscriptionLevel = SubscriptionLevel.FREE;
        state.subscriptionInterval = null;
      } else {
        state.subscriptionLevel = customClaims.stripeRole;
        // state.subscriptionLevel = SubscriptionLevel.ONE_OFF_EVENT;
        state.subscriptionInterval = customClaims.stripeInterval;
      }

      state.teamId = customClaims?.teamId;
      state.teamRole = customClaims?.teamRole;
      const currentSubscriptionDetails: any = {
        subscriptionLevel: state.subscriptionLevel,
        subscriptionInterval: state.subscriptionInterval,
        teamId: state.teamId,
        teamRole: state.teamRole,
        subscriptionEnded: subscriptionEnded,
        isEducationDiscountApplied: customClaims?.isEducationDiscountApplied,
        stripeMeteredActive: customClaims?.stripeMeteredActive,
        stripeCancelAtPeriodEnd: customClaims?.stripeCancelAtPeriodEnd,
        stripeCancelAt: customClaims?.stripeCancelAt,
        stripeCanceledAt: customClaims?.stripeCanceledAt,
        stripeCurrentPeriodStart: customClaims?.stripeCurrentPeriodStart,
        stripeCurrentPeriodEnd: customClaims?.stripeCurrentPeriodEnd
      };
      // get team related claims
      try {
        if (customClaims?.teamId) {
          const response = await usePostAPI(getEndpoint('teamSpecificClaims'), {
            teamId: customClaims?.teamId
          });
          if (response.status == 200) {
            // teamClaims:
            // stripeRole
            // stripeInterval
            // isEducationDiscountApplied
            // stripeMeteredActive
            const teamClaims = response.data as TeamClaims;
            currentSubscriptionDetails.teamClaims = teamClaims;
          }
        }
      } catch (err) {
        console.log(`err in getting team claims ${err}`);
      }
      return currentSubscriptionDetails;
    } catch (err) {
      console.error('Error getting custom claims: ', err);
      state.error = err as any;
      state.subscriptionLevel = SubscriptionLevel.FREE;
      return state.subscriptionLevel;
    } finally {
      state.loading = false;
    }
  };

  return {
    ...toRefs(state),
    isValid,
    login,
    logout,
    getSubscriptionDetails
  };
}
