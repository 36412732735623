
import router from '@/router';
import { computed, defineComponent, onMounted, PropType, ref } from 'vue';
import Flicking from '@egjs/vue3-flicking';
import VueEasyLightbox from 'vue-easy-lightbox';
import {
  getGameTitleByType,
  getGameDescriptionByType,
  getGameRecommendedPlayersByType,
  getGameHeroByType,
  getGameVideoByType,
  customGameTypes,
  gameVideo,
  getGameScreenshotsByType,
  cardGameTypes
} from '@/utils/gameConstants';
import StandardCustomizedGameTile from '@/components/Home/StandardCustomizedGameTile.vue';
import SampleQuestionsModal from '@/components/Modal/SampleQuestionsModal.vue';
import HowToPlayDrawIt from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayDrawIt.vue';
import HowToPlayYesOrNo from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayYesOrNo.vue';
import HowToPlayFunnyOrNot from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayFunnyOrNot.vue';
import HowToPlayDescribeIt from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayDescribeIt.vue';
import HowToPlayDrawTell from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayDrawTell.vue';
import HowToPlayGinRummy from '@/components/Home/HowToPlayComponents/HowToPlayGinRummy.vue';
import HowToPlayHearts from '@/components/Home/HowToPlayComponents/HowToPlayHearts.vue';
import HowToPlayQOfTheDay from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayQOfTheDay.vue';
import HowToPlayRummy from '@/components/Home/HowToPlayComponents/HowToPlayRummy.vue';
import HowToPlaySpotMyLie from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlaySpotMyLie.vue';
import HowToPlayTrivia from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayTrivia.vue';
import HowToPlayTwoWords from '@/components/Home/HowToPlayComponents/HowToPlayTwoWords.vue';
import HowToPlayWerewolf from '@/components/Home/HowToPlayComponents/HowToPlayWerewolf.vue';
import HowToPlayWordsOfPower from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayWordsOfPower.vue';
import HowToPlayWouldYouRather from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayWouldYouRather.vue';
import HowToPlayOneToHundred from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayOneToHundred.vue';
import HowToPlayCharades from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayCharades.vue';
import HowToPlayPoll from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayPoll.vue';
import HowToPlayTop5 from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayTop5.vue';
import DeskopOrTwoDevicesWarningBar from '@/components/Modal/AboutComponent/DeskopOrTwoDevicesWarningBar.vue';
import MeditationSelectedView from '@/components/Home/MeditationSelectedView.vue';
import { useStore } from '@/store';
import { amplitudeEvent } from '@/config/amplitudeConfig';
import { firebaseModifiedSource } from '@/utils/helper';
import { NavigationTabs } from '@/models/navigationTabs';
export default defineComponent({
  name: 'ActivityDetailsView',
  emits: [
    'customized-game-start',
    'start',
    'customized-game-more',
    'video',
    'about'
  ],
  props: {
    type: {
      type: String,
      required: true
    },
    games: {
      type: Array as PropType<Array<any>>,
      default: undefined
    }
  },
  components: {
    StandardCustomizedGameTile,
    SampleQuestionsModal,
    HowToPlayDrawIt,
    HowToPlayYesOrNo,
    HowToPlayFunnyOrNot,
    HowToPlayDescribeIt,
    HowToPlayDrawTell,
    HowToPlayGinRummy,
    HowToPlayHearts,
    HowToPlayQOfTheDay,
    HowToPlayRummy,
    HowToPlaySpotMyLie,
    HowToPlayTrivia,
    HowToPlayTwoWords,
    HowToPlayWerewolf,
    HowToPlayWordsOfPower,
    HowToPlayWouldYouRather,
    HowToPlayOneToHundred,
    HowToPlayCharades,
    HowToPlayPoll,
    HowToPlayTop5,
    DeskopOrTwoDevicesWarningBar,
    MeditationSelectedView,
    Flicking,
    VueEasyLightbox
  },
  setup(props, context) {
    const store = useStore();
    const userDoc = computed(() => store.getters.getUserDoc);
    const has3UserHistory =
      userDoc.value['hostHasStartedGameWith3Players'] &&
      userDoc.value['hostHasStartedGameWith3Players'][props.type] == true;
    const showSampleQuestionsModal = ref(false);
    const isClicked = ref(false);
    const gameVideoId = getGameVideoByType(props.type);
    const videoSrc = gameVideoId
      ? `https://www.youtube.com/embed/${gameVideoId}?iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1`
      : undefined;
    const info = getGameScreenshotsByType(props.type);
    const imageSrc = ref([] as any[]);
    if (info) {
      for (let i = 0; i < (info[1] as number); i++) {
        imageSrc.value.push(
          require(`@/assets/img/games-screen/v2/${info[0]}-${i + 1}.png`)
        );
      }
    }
    const imageIndex = ref(-1);
    const demoExpanded = ref(false);
    const flicking = ref(null);

    const showSampleQuestions = function() {
      showSampleQuestionsModal.value = true;
    };

    const onSampleQuestionsClosed = function() {
      showSampleQuestionsModal.value = false;
    };

    function startGame(event) {
      context.emit('start', {
        gameName: event.gameName,
        track: event.track,
        source: 'GameList'
      });
      isClicked.value = true;
      setTimeout(function() {
        isClicked.value = false;
      }, 3000);
    }

    function showCustomizedGameMore(
      customGameName: string,
      customGameId: string
    ) {
      context.emit('customized-game-more', [
        props.type,
        customGameName,
        customGameId,
        'GameList'
      ]);
    }

    function startCustomizedGame(customGameName: string, customGameId: string) {
      context.emit('customized-game-start', {
        gameName: props.type,
        customGameId: customGameId,
        customGameName: customGameName,
        source: 'GameList'
      });
    }

    function showVideo(type: string) {
      context.emit('video', type);
    }

    function showAbout(type: string) {
      context.emit('about', type);
    }

    function scrollToCustomized() {
      document.getElementById('browse-customized-version')?.scrollIntoView();
    }

    function expandDemo() {
      demoExpanded.value = !demoExpanded.value;
    }

    function showImage(index: number) {
      imageIndex.value = index;
    }

    function hideImage() {
      imageIndex.value = -1;
    }

    async function onPrevCarousel() {
      try {
        await (flicking.value as any).prev();
      } catch (e) {
        e;
      }
    }

    async function onNextCarousel() {
      try {
        await (flicking.value as any).next();
      } catch (e) {
        e;
      }
    }
    async function onYourCustomizedGames() {
      router.push({
        query: { tab: NavigationTabs.CUSTOMIZED }
      });
    }

    onMounted(() => {
      amplitudeEvent('visitedDetailsPage', {
        game: props.type,
        eventTime: new Date(Date.now()).toISOString()
      });
      setTimeout(() => {
        (flicking.value as any).init();
      }, 500);
    });

    return {
      //
      getGameTitleByType,
      getGameDescriptionByType,
      getGameRecommendedPlayersByType,
      getGameHeroByType,
      customGameTypes,
      cardGameTypes,
      gameVideo,
      //
      has3UserHistory,
      startGame,
      showCustomizedGameMore,
      startCustomizedGame,
      showVideo,
      showAbout,
      scrollToCustomized,
      showSampleQuestionsModal,
      showSampleQuestions,
      onSampleQuestionsClosed,
      isClicked,
      videoSrc,
      imageSrc,
      imageIndex,
      showImage,
      hideImage,
      demoExpanded,
      expandDemo,
      flicking,
      onPrevCarousel,
      onNextCarousel,
      onYourCustomizedGames,
      firebaseModifiedSource
    };
  }
});
