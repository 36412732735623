
import { defineComponent, onBeforeMount, ref, watch } from 'vue';
import { Keyword } from '@/models/Keyword';

export default defineComponent({
  name: 'GeneralKeywordInputItem',
  props: {
    id: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    number: {
      type: Number,
      required: true
    },
    value: Keyword || Object,
    checked: Boolean
  },
  emits: ['valuechanged', 'allfilled'],
  setup(props, context) {
    const checkedValue = ref(false);
    const word = ref('');

    const maxDrawItDescribeItInputLength = 50;

    const onValue = function(isCheckbox: Boolean) {
      context.emit('valuechanged', {
        checked: checkedValue.value,
        word: word.value,
        index: props.number
      });
      if (!isCheckbox) {
        if (word.value != '') {
          context.emit('allfilled');
        }
      }
    };

    onBeforeMount(() => {
      checkedValue.value = props.checked ?? false;
      word.value = props.value?.word ?? '';
    });

    watch(props, newValue => {
      checkedValue.value = newValue.checked ?? false;
      word.value = newValue.value?.word ?? '';
    });

    return {
      checkedValue,
      word,
      maxDrawItDescribeItInputLength,
      onValue
    };
  }
});
