
import { WordCloudLivePoll } from '@/models/event_tools/live_poll/live_poll_model';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'WordCloudPreviewModal',

  props: {
    livePoll: {
      type: Object as () => WordCloudLivePoll,
      default: null,
      required: true
    }
  },
  emits: ['close'],
  setup(_, context) {
    function onClose() {
      context.emit('close');
    }

    return {
      onClose
    };
  }
});
