
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BlockFreePlanModal',
  emits: ['close', 'submit-form'],
  props: {
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    },
    okText: {
      type: String,
      default: 'OK'
    }
  },
  setup(_, context) {
    const close = function() {
      context.emit('close');
    };

    return {
      close
    };
  }
});
