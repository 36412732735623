
import { defineComponent, ref, watch } from 'vue';
import BounceLoading from '@/components/BounceLoading.vue';
import PollParticipantVote from '@/components/Modal/WhiteboardComponent/PollComponents/PollParticipantVote.vue';
import PollResultBox from '@/components/Modal/WhiteboardComponent/PollComponents/PollResultBox.vue';
import useFirebaseCollection from '@/hooks/firebase/useFirebaseCollection';
import useFirebaseAuth from '@/hooks/firebase/useFirebaseAuth';
export default defineComponent({
  name: 'PollParticipant',
  components: {
    BounceLoading,
    PollParticipantVote,
    PollResultBox
  },
  props: {
    whiteboardId: {
      type: String,
      required: true
    },
    pollDoc: {
      type: Object,
      required: true
    }
  },
  emits: ['hasVoted', 'hasNotVoted'],
  setup(props, context) {
    const { currentUser } = useFirebaseAuth();
    const pollOptionsListener = useFirebaseCollection(
      `whiteboard_data/${props.whiteboardId}/polls/${props.pollDoc['id']}/answers`,
      {
        onMounted: true
      },
      true
    );
    const isLoading = ref(true);
    const hasVoted = ref(false);

    watch(pollOptionsListener.collectionData, pollOptions => {
      if (pollOptions && currentUser.value !== null) {
        for (let option of pollOptions) {
          const voters: string[] = option['voters'];
          if (voters.includes(currentUser.value.uid)) {
            hasVoted.value = true;
            isLoading.value = false;
            context.emit('hasVoted');
            return;
          }
        }
        context.emit('hasNotVoted');
        isLoading.value = false;
      }
    });

    return { ...pollOptionsListener, isLoading, hasVoted };
  }
});
