import axios from 'axios';

export default async function(apiUrl: string) {
  try {
    const response = await axios.get(apiUrl);
    return response;
  } catch (err) {
    console.error(`error occured doing GET: ${err}`);
  }
}
