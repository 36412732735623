
import { getGameTitleByType } from '@/utils/gameConstants';
import { computed, defineComponent, ref } from 'vue';
import { useStore } from '@/store';
import { shareTop5Questions } from '@/utils/shareResult';

export default defineComponent({
  name: 'HistoryModalTop5Component',
  components: {},
  props: {
    game: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const user = computed(() => store.getters.getUser);
    const title = getGameTitleByType(props.game.gameName);
    const customGameName = ref(props.game.customGameName);
    const customGameCss = ref(props.game.customGameCss);
    const dateString = ref(
      props.game.dateCreated.format('DD MMMM, YYYY - hh:mm A')
    );
    const hostName = ref(undefined as string | undefined);
    const isHostNotPlaying = ref(props.game.isHostPlaying === false);
    const status = ref(0);
    const best = ref({} as any);
    const moderator = ref({} as any);
    const teams = ref([] as any[]);
    const rounds = ref([] as string[]);

    function calculateResult() {
      if (props.game.host !== user.value.uid) {
        const host = props.game.players.find(
          player => player.playerId === props.game.host
        );
        if (host) {
          hostName.value = host.name;
        }
      }

      const actualPlayers = Object.assign(
        [],
        isHostNotPlaying.value
          ? props.game.players.filter(
              player => player.playerId !== props.game.host
            )
          : props.game.players
      );
      let max = 0;
      Object.keys(props.game.totalAnswered).map(playerId => {
        if (max < props.game.totalAnswered[playerId]) {
          max = props.game.totalAnswered[playerId];
        }
      });
      const bPlayer = actualPlayers.find(
        player => props.game.totalAnswered[player.playerId] === max
      );
      best.value = {
        name: bPlayer.name,
        score: max
      };

      const modPlayer = actualPlayers.find(
        player => player.playerId === props.game.moderatorId
      );
      moderator.value = {
        name: modPlayer.name,
        email: modPlayer.email
      };
      teams.value = props.game.gameDetails.map(detail => {
        return {
          score: detail.teamPoint,
          players: Object.values(detail.teamPlayers).map(player => {
            return {
              name: (player as any).name,
              email: (player as any).email
            };
          }),
          showPlayers: []
        };
      });
      rounds.value = props.game.roundDetails.map(
        detail => detail.questionData.question
      );

      if (teams.value[0].players.length > 7) {
        teams.value[0].showPlayers = teams.value[0].players.slice(0, 7);
      } else {
        teams.value[0].showPlayers = teams.value[0].players;
      }
      if (teams.value[1].players.length > 7) {
        teams.value[1].showPlayers = teams.value[1].players.slice(0, 7);
      } else {
        teams.value[1].showPlayers = teams.value[1].players;
      }
    }

    calculateResult();

    async function shareQuestions() {
      const data = {
        roundDetails: props.game.roundDetails,
        players: props.game.players,
        date:
          dateString.value +
          (hostName.value ? ` - Hosted by ${hostName.value}` : '')
      };
      const url = await shareTop5Questions(data);
      window.open(url, '_blank');
    }

    function showBlueMore() {
      teams.value[0].showPlayers = teams.value[0].players;
    }

    function showPurpleMore() {
      teams.value[0].showPlayers = teams.value[0].players;
    }

    return {
      title,
      customGameName,
      customGameCss,
      dateString,
      hostName,
      isHostNotPlaying,
      status,
      best,
      moderator,
      teams,
      rounds,
      shareQuestions,
      showBlueMore,
      showPurpleMore
    };
  }
});
