
import QAMessageItem from '@/components/Modal/WhiteboardComponent/QA/QAMessageItem.vue';
import useFirebaseCollection from '@/hooks/firebase/useFirebaseCollection';
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import ChatModel from '../../../../utils/whiteboardChatModel';
import useFirebaseAuth from '@/hooks/firebase/useFirebaseAuth';
import useFirebaseDocument from '@/hooks/firebase/useFirebaseDocument';
import { amplitudeEvent } from '@/config/amplitudeConfig';
import { getTriggeredRole } from '@/utils/helper';
export default defineComponent({
  name: 'QAMessageList',
  components: {
    QAMessageItem
  },
  props: {
    isHost: {
      type: Boolean,
      required: true
    },
    channel: {
      type: Object,
      required: true
    },
    participantsData: {
      type: Array,
      required: true
    },
    whiteboardData: {
      type: Object,
      required: true
    },
    isModalOpen: {
      type: Boolean,
      required: true
    }
  },
  emits: ['backClicked'],

  setup(props, context) {
    const { currentUser } = useFirebaseAuth();
    const currentMessages = ref();
    const onBack = function() {
      context.emit('backClicked');
    };
    const myUnreadCount = computed(() => {
      if (isLoading.value) return 0;
      let unreadCount: number = 0;
      let lastIndex = currentMember.value?.lastConsumedMessageIndex;
      if (!lastIndex) lastIndex = currentMessages.value.length;
      for (let i = 0; i < currentMessages.value.length - lastIndex; i++) {
        if (currentMessages.value[i].from != currentUser?.value?.uid) {
          unreadCount++;
        }
      }
      let participant: any = props.participantsData.find((participant: any) => {
        return participant.id == currentUser?.value?.uid;
      });

      if (
        !participant.unreadCounts ||
        participant.unreadCounts[props.channel.id] != unreadCount
      ) {
        if (!participant.unreadCounts) {
          participant.unreadCounts = {};
        }
        participant.unreadCounts[props.channel.id] = unreadCount;
        ChatModel.setUnreadCounts(
          currentUser?.value?.uid!,
          props.channel.whiteboardId,
          participant.unreadCounts
        );
      }
      return unreadCount;
    });
    const messagesCollection = (channelId: string) =>
      useFirebaseCollection(
        `whiteboard_channel/${channelId}/messages`,
        {
          onMounted: true,
          orderBy: 'dateCreated',
          isDesc: true
        },
        true
      );
    const memberDoc = (channelId: string) =>
      useFirebaseDocument(`whiteboard_channel/${channelId}/members`, {
        documentId: currentUser?.value?.uid,
        onMounted: true,
        onSnapshot: true
      });
    const isLoading = computed(() => {
      return !currentMessages.value;
    });
    const participantsMap = ref();
    onMounted(() => {
      for (let i = 0; i < props.participantsData.length; i++) {
        let participants: Array<any> = props.participantsData;
        let uid: string = participants[i].uid;
        if (!participantsMap.value) {
          participantsMap.value = {};
        }
        participantsMap.value[uid] = participants[i];
      }
      setTimeout(() => {
        scrollToBottom();
      }, 200);
    });
    watch(memberDoc(props.channel.id).documentData, member => {
      if (member) {
        currentMember.value = member;
        if (props.isHost) {
          myUnreadCount.value;
        }
      }
    });
    watch(messagesCollection(props.channel.id).collectionData, messages => {
      if (messages) {
        if (
          currentMessages.value &&
          messages.length != 0 &&
          messages.length > currentMessages.value.length
        ) {
          setTimeout(() => {
            scrollToBottom();
          }, 200);
        }
        currentMessages.value = messages;
        let channelLastConsumedMessageIndex = messages.length ?? 0;
        let memberLastConsumedMemberIndex =
          currentMember.value.lastConsumedMessageIndex ?? 0;
        if (
          !props.isHost &&
          participantsMap.value[currentUser?.value?.uid!]
            .participantMessagesCount != messages.length
        ) {
          ChatModel.setParticipantMessageCount(
            currentUser?.value?.uid!,
            props.whiteboardData.id,
            messages.length
          );
        }
        if (channelLastConsumedMessageIndex > memberLastConsumedMemberIndex) {
          if (
            channelLastConsumedMessageIndex - memberLastConsumedMemberIndex ==
            1
          ) {
            if (currentMessages.value[0].from != currentUser.value?.uid) {
              // log receive message;
              amplitudeEvent('receiveQAMessage', {
                whiteboardId: props.channel.whiteboardId,
                trigger: getTriggeredRole(props.isHost),
                messages: 1
              });
            }
          } else {
            amplitudeEvent('receiveQAMessage', {
              whiteboardId: props.channel.whiteboardId,
              trigger: getTriggeredRole(props.isHost),
              messages:
                channelLastConsumedMessageIndex - memberLastConsumedMemberIndex
            });
          }
          ChatModel.setLastConsumedMessageIndex(
            currentUser?.value?.uid!,
            props.channel.id,
            messages.length
          );
        }

        currentMessages.value = currentMessages.value.reverse();
        for (let i = 0; i < currentMessages.value.length; i++) {
          currentMessages.value[i].fromMember =
            participantsMap.value[currentMessages.value[i].from];
        }
      }
    });
    const textFieldBody = ref('');
    const onSend = function() {
      if (textFieldBody.value.trim() == '') return;
      ChatModel.sendMessage(
        currentUser?.value?.uid!,
        props.channel.id,
        textFieldBody.value,
        'text',
        { type: 'text' }
      );
      textFieldBody.value = '';
      amplitudeEvent('sendQAMessage', {
        whiteboardId: props.channel.whiteboardId,
        trigger: getTriggeredRole(props.isHost)
      });
    };
    const scrollToBottom = function() {
      if (
        currentMessages.value !== undefined &&
        currentMessages.value.length !== undefined &&
        currentMessages.value.length != 0
      ) {
        var container = document.getElementById(
          currentMessages.value[currentMessages.value.length - 1].id
        )!;
        if (container) {
          container?.scrollIntoView();
        }
      }
    };
    // const inViewPort = function(el: any) {
    //   var rect = el.getBoundingClientRect();
    //   return (
    //     rect.top >= 0 &&
    //     rect.left >= 0 &&
    //     rect.bottom <=
    //       (window.innerHeight ||
    //         document.documentElement
    //           .clientHeight) /* or $(window).height() */ &&
    //     rect.right <=
    //       (window.innerWidth ||
    //         document.documentElement.clientWidth) /* or $(window).width() */
    //   );
    // };
    const currentMember = ref();
    const newLine = function() {
      textFieldBody.value = `${textFieldBody.value}\n`;
    };
    return {
      onBack,
      currentMessages,
      isLoading,
      onSend,
      textFieldBody,
      currentMember,
      newLine
    };
  }
});
