
import { amplitudeEvent } from '@/config/amplitudeConfig';
import { WordCloudLivePoll } from '@/models/event_tools/live_poll/live_poll_model';
import { store } from '@/store';
import { LivePollType } from '@/utils/live_poll_enums';
import firebase from 'firebase';
import { computed, defineComponent, Ref, ref } from 'vue';
export default defineComponent({
  name: 'CreateWordCloudModal',

  props: {},
  emits: ['close'],
  setup(_, context) {
    const userDoc = computed(() => store.getters.getUserDoc);

    const isButtonLoading: Ref<boolean> = ref(false);
    const pollData: Ref<WordCloudLivePoll> = ref({
      dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
      launchedCount: 0,
      question: '',
      type: LivePollType.WORD_CLOUD,
      isPofanityAllowed: true,
      wordLimit: 1
    });
    function onClose() {
      context.emit('close');
    }
    function onWordLimitUpdate(type: 'increment' | 'decrement') {
      if (type === 'increment') {
        // if (pollData.value.wordLimit >= 10) return;
        pollData.value.wordLimit = pollData.value.wordLimit + 1;
      } else {
        if (pollData.value.wordLimit <= 1) return;
        pollData.value.wordLimit = pollData.value.wordLimit - 1;
      }
    }
    function onToggle(value: boolean) {
      pollData.value.isPofanityAllowed = value;
    }
    const validForm = computed(() => {
      return (
        pollData.value.question?.trim() !== '' && pollData.value.wordLimit >= 1
        // && pollData.value.wordLimit <= 10
      );
    });

    async function onSave() {
      if (!validForm.value) {
        console.log('Form incomplete');
        console.log(pollData.value);
        return;
      }

      isButtonLoading.value = true;
      await firebase
        .firestore()
        .collection(
          `minigame_lobbies/${userDoc.value['defaultLobbyId']}/live_poll`
        )
        .add(pollData.value);
      console.log('new poll added');
      amplitudeEvent('savePoll', {
        eventTime: new Date(Date.now()).toISOString(),
        pollType: pollData.value.type
      });
      isButtonLoading.value = false;
      onClose();
    }
    return {
      onClose,
      onSave,
      onWordLimitUpdate,
      onToggle,
      pollData,
      validForm,
      isButtonLoading
    };
  }
});
