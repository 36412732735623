
import { computed, defineComponent, ref } from 'vue';
import router from '@/router';
import BounceLoading from '@/components/BounceLoading.vue';
import CreateCustomGameComponent from '@/components/CustomGame/CreateCustomGameComponent.vue';
import {
  isPlayground,
  isDev,
  isProd,
  completeLobbyFromLobbyId,
  redirectToPlay,
  redirectToLogin,
  handleZoomOnSignedOut,
  handleZoomOnSignedIn
} from '@/utils/helper';
import firebase from '@/firebaseApp';
import useFirebaseLoginOut from '@/hooks/firebase/useFirebaseLoginOut';
import { useStore } from '@/store';
import {
  UPDATE_ACTIVEGAMEROOMID,
  UPDATE_ISUSERUSINGCUSTOMGAMEROOMID,
  UPDATE_LOBBY_DOC,
  UPDATE_USER,
  UPDATE_USER_DOC,
  UPDATE_USER_SUBSCRIPTION_DETAILS
} from '@/store/mutation-types';
import { amplitudeEvent, initAmplitude } from '@/config/amplitudeConfig';
import useFirebaseDocument from '@/hooks/firebase/useFirebaseDocument';

export default defineComponent({
  name: 'CreateCustomGame',
  components: {
    BounceLoading,
    CreateCustomGameComponent
  },
  setup() {
    if (
      !router.currentRoute.value.query.type ||
      !router.currentRoute.value.query.title
    ) {
      router.back();
    }
    const type = router.currentRoute.value.query.type as string;
    const title = router.currentRoute.value.query.title as string;

    const store = useStore();
    const userDoc = computed(() => store.getters.getUserDoc);
    const subscriptionDetails = computed(
      () => store.getters.getSubscriptionDetails
    );
    const userDocumentProps = useFirebaseDocument('minigame_user', {
      onMounted: false,
      documentId: undefined
    });
    const showInitialLoading = ref(true);

    async function handleSignedInUser(user: firebase.User) {
      if (store.state.user === undefined) {
        store.commit(UPDATE_USER, {
          newUser: user
        });
      }
      if (userDoc.value === undefined) {
        await userDocumentProps.getDocument(10, user.uid);
        if (userDocumentProps.documentData.value === null) {
          console.log('Waiting 3s for initialization');
          await new Promise(resolve => setTimeout(resolve, 3000));
          await userDocumentProps.getDocument(10, user.uid);
        }
        store.commit(UPDATE_USER_DOC, {
          newUserDoc: userDocumentProps.documentData.value
        });
      }
      if (subscriptionDetails.value.subscriptionLevel === undefined) {
        store.commit(UPDATE_USER_SUBSCRIPTION_DETAILS, {
          newSubscriptionDetails: await useFirebaseLoginOut().getSubscriptionDetails()
        });
      }
      if (store.state.lobbyDoc === undefined) {
        let lobby: any = await completeLobbyFromLobbyId(
          userDoc.value['defaultLobbyId']
        );
        store.commit(UPDATE_LOBBY_DOC, {
          newLobbyDoc: lobby
        });
        if (lobby !== null) {
          if (lobby['customGameRoomId']) {
            store.commit(UPDATE_ISUSERUSINGCUSTOMGAMEROOMID, {
              isUserUsingCustomGameRoomId: true
            });
            store.commit(UPDATE_ACTIVEGAMEROOMID, {
              activeGameRoomId: lobby['customGameRoomId']
            });
          } else {
            store.commit(UPDATE_ACTIVEGAMEROOMID, {
              activeGameRoomId: userDoc.value['defaultGameRoomId']
            });
          }
        }
      }
      await handleZoomOnSignedIn(store.state.zoomClientStatus);
      showInitialLoading.value = false;
      // log after confirming user is logged in
      initAmplitude();
      amplitudeEvent('visitQuestionEditor', {
        gameType: type,
        name: title,
        purpose: 'create',
        eventTime: new Date(Date.now()).toISOString()
      });
    }

    async function handleSignedOutUser() {
      if (store.state.zoomClientStatus?.inZoomClient) {
        await handleZoomOnSignedOut(store.state.zoomClientStatus);
      } else {
        if (isPlayground) {
          showInitialLoading.value = false;
        } else if (isDev || isProd) {
          if (!showInitialLoading.value) {
            redirectToPlay('logout');
          } else {
            redirectToLogin('source', 'create-custom-game');
          }
        }
      }
    }

    firebase.auth().onAuthStateChanged(_user => {
      if (_user === null || _user.isAnonymous) {
        handleSignedOutUser();
      } else {
        handleSignedInUser(_user);
      }
    });

    return { showInitialLoading };
  }
});
