
import { defineComponent, onMounted } from 'vue';
import { amplitudeEvent } from '@/config/amplitudeConfig';
// import NumberOfPlayersDescribeIt from '@/components/Modal/AboutComponent/NumberOfPlayersComponents/NumberOfPlayersDescribeIt.vue';
import HowToPlayDescribeIt from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayDescribeIt.vue';

export default defineComponent({
  name: 'AboutDescribeIt',
  components: {
    // NumberOfPlayersDescribeIt,
    HowToPlayDescribeIt
  },
  setup() {
    onMounted(() => {
      amplitudeEvent('previewGame', {
        game: 'describe_it',
        version: 'regular',
        eventTime: new Date(Date.now()).toISOString()
      });
    });
  }
});
