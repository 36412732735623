
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import {
  bronzeMonthly,
  bronzeAnnually,
  goldMonthly,
  goldAnnually,
  bronzeMonthlyPrice,
  bronzeAnnuallyPrice,
  goldMonthlyPrice,
  goldAnnuallyPrice,
  eventPriceV6,
  oneOffEventV6
} from '@/utils/pricingConstant';
import useGetAPI from '@/hooks/useGetAPI';
import { useStore } from '@/store';
import { SubscriptionLevel } from '@/models/SubscriptionLevel';
import { SubscriptionInterval } from '@/models/SubscriptionInterval';

import { isActiveSubscription, isOneOffPlan } from '@/utils/pricing_rules';
import router from '@/router';

export default defineComponent({
  name: 'AllPlansV6',

  emits: ['proceed-checkout'],
  setup(_, context) {
    const store = useStore();
    const subscriptionDetails = computed(
      () => store.getters.getSubscriptionDetails
    );
    const survey1Answer = computed(() => store.getters.getSurvey1Answer);
    const hasActiveSubscription = computed(() => {
      return isActiveSubscription(subscriptionDetails.value);
    });
    const inZoomClient = computed(() => {
      return store.state.zoomClientStatus?.inZoomClient ?? false;
    });
    const showOneOffPlan = computed(() => {
      return (
        isOneOffPlan(subscriptionDetails.value) ||
        (!inZoomClient.value && !hasActiveSubscription.value)
      );
    });
    const eventTabByDefault = computed(() => {
      const isEventSurveyAnswer = survey1Answer.value[0] == 'Business - Events';
      return showOneOffPlan.value && isEventSurveyAnswer;
    });
    const planSelectedTab = ref(eventTabByDefault.value ? 2 : 0);

    const isDesktop = ref(true);
    const loadingButton = ref('');
    const showCollapsed = ref([false, false, false]);
    const USDtoAUDRate = 1.37;

    const bronzePlanPrice = ref(bronzeAnnuallyPrice / 12);
    const goldPlanPrice = ref(goldAnnuallyPrice / 12);
    const bronzePlanPriceAUD = ref(
      (bronzeAnnuallyPrice * USDtoAUDRate).toFixed(2)
    );
    const goldPlanPriceAUD = ref((goldAnnuallyPrice * USDtoAUDRate).toFixed(2));

    const showBronzeButton = computed(() => {
      const interval = subscriptionDetails.value.subscriptionInterval;
      const level = subscriptionDetails.value.subscriptionLevel;
      if (level == SubscriptionLevel.ONE_OFF_EVENT) return true;
      else if (level == SubscriptionLevel.GOLD) return false;
      else if (level == SubscriptionLevel.BRONZE) {
        if (interval == SubscriptionInterval.YEAR) {
          return false;
        } else if (interval == SubscriptionInterval.MONTH) {
          return planSelectedTab.value == 0;
        }
      }
      return true;
    });
    const showGoldButton = computed(() => {
      const interval = subscriptionDetails.value.subscriptionInterval;
      const level = subscriptionDetails.value.subscriptionLevel;

      if (level == SubscriptionLevel.ONE_OFF_EVENT) return true;
      else if (level == SubscriptionLevel.BRONZE) return true;
      else if (level == SubscriptionLevel.GOLD) {
        if (interval == SubscriptionInterval.YEAR) {
          return false;
        } else if (interval == SubscriptionInterval.MONTH) {
          return planSelectedTab.value == 0;
        }
      }

      return true;
    });
    const showEventButton = computed(() => {
      const level = subscriptionDetails.value.subscriptionLevel;

      if (level == SubscriptionLevel.ONE_OFF_EVENT) return false;
      return true;
    });

    const userInAustralia = ref(false);

    function onContactUs() {
      let routeData = router.resolve({
        name: 'Feedback',
        query: {
          source: 'pricing'
        }
      });
      window.open(routeData.href, '_blank');
    }

    watch(planSelectedTab, newSelectedTab => {
      if (newSelectedTab == 1) {
        bronzePlanPrice.value = bronzeMonthlyPrice;
        goldPlanPrice.value = goldMonthlyPrice;
        bronzePlanPriceAUD.value = (
          bronzePlanPrice.value * USDtoAUDRate
        ).toFixed(2);
        goldPlanPriceAUD.value = (goldPlanPrice.value * USDtoAUDRate).toFixed(
          2
        );
      } else {
        bronzePlanPrice.value = bronzeAnnuallyPrice / 12;
        bronzePlanPriceAUD.value = (
          bronzePlanPrice.value * USDtoAUDRate
        ).toFixed(2);
        goldPlanPrice.value = goldAnnuallyPrice / 12;
        goldPlanPriceAUD.value = (goldPlanPrice.value * USDtoAUDRate).toFixed(
          2
        );
      }
    });

    function proceedCheckout(
      priceId: string,
      plan: 'bronze' | 'gold' | 'event'
    ) {
      context.emit('proceed-checkout', {
        priceId: priceId,
        onLoading: () => {
          loadingButton.value = plan;
        },
        onSuccess: () => {
          loadingButton.value = '';
        },
        onError: () => {
          loadingButton.value = '';
        }
      });
    }

    async function isUserInAustralia() {
      const response = await useGetAPI('http://ip-api.com/json');
      if (response) {
        const countryCode = response['data']['countryCode'];
        userInAustralia.value = countryCode === 'AU';
      }
    }
    const onResize = function() {
      isDesktop.value = window.innerWidth >= 1200;
    };

    function planPriceStringify(num) {
      if (num % 1 != 0) return num.toFixed(2);
      return num;
    }
    onMounted(() => {
      isUserInAustralia();
      window.addEventListener('resize', onResize);
      onResize();
    });

    return {
      showEventButton,
      loadingButton,
      userInAustralia,
      bronzePlanPrice,
      goldPlanPrice,
      bronzePlanPriceAUD,
      goldPlanPriceAUD,
      showOneOffPlan,
      bronzeMonthly,
      bronzeAnnually,
      goldMonthly,
      bronzeMonthlyPrice,
      goldMonthlyPrice,
      bronzeAnnuallyPrice,
      goldAnnuallyPrice,
      eventPriceV6,
      goldAnnually,
      showCollapsed,
      isDesktop,
      planSelectedTab,
      showBronzeButton,
      showGoldButton,
      oneOffEventV6,
      inZoomClient,
      proceedCheckout,
      planPriceStringify,
      onContactUs
    };
  }
});
