
import { LivePollType } from '@/utils/live_poll_enums';
import { computed, defineComponent, Ref, ref, watch } from 'vue';
import CreateMultipleChoiceModal from './Modal/CreatePoll/CreateMultipleChoiceModal.vue';
import CreateRatingModal from './Modal/CreatePoll/CreateRatingModal.vue';
import CreateWordCloudModal from './Modal/CreatePoll/CreateWordCloudModal.vue';
import CreateShortAnswerModal from './Modal/CreatePoll/CreateShortAnswerModal.vue';
import CreatePollHavingHistory from './CreatePollHavingHistory.vue';
import CreatePollWithoutHistory from './CreatePollWithoutHistory.vue';
import { store } from '@/store';
import useFirebaseCollection from '@/hooks/firebase/useFirebaseCollection';
import {
  LivePoll,
  MultipleChoiceLivePoll,
  RatingLivePoll,
  WordCloudLivePoll
} from '@/models/event_tools/live_poll/live_poll_model';
import BounceLoading from '@/components/BounceLoading.vue';
import MultipleChoicePreviewModal from './Modal/Previews/MultipleChoicePreviewModal.vue';
import RatingPreviewModal from './Modal/Previews/RatingPreviewModal.vue';
import WordCloudPreviewModal from './Modal/Previews/WordCloudPreviewModal.vue';
import ShortAnswerPreviewModal from './Modal/Previews/ShortAnswerPreviewModal.vue';
import { LaunchOptions } from '@/models/GameStartOptions';
import { canAccessEventTools } from '@/utils/pricing_rules';

export default defineComponent({
  name: 'LivePoll',
  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'launch-modal': (options: LaunchOptions) => true
  },
  props: {},
  components: {
    CreateMultipleChoiceModal,
    CreateRatingModal,
    CreateWordCloudModal,
    CreateShortAnswerModal,
    CreatePollHavingHistory,
    CreatePollWithoutHistory,
    BounceLoading,
    MultipleChoicePreviewModal,
    RatingPreviewModal,
    WordCloudPreviewModal,
    ShortAnswerPreviewModal
  },
  setup() {
    const userDoc = computed(() => store.getters.getUserDoc);
    const subscriptionDetails = computed(
      () => store.getters.getSubscriptionDetails
    );
    const canAccessPoll = computed(() =>
      canAccessEventTools(subscriptionDetails.value)
    );
    const pollQuery = useFirebaseCollection(
      `minigame_lobbies/${userDoc.value['defaultLobbyId']}/live_poll`,
      {
        onMounted: true,
        orderBy: 'dateCreated',
        isDesc: true
      },
      true
    );

    const isLoading: Ref<boolean> = ref(true);
    const polls: Ref<Array<LivePoll> | null> = ref(null);
    const modalTypeShown: Ref<
      [LivePollType, 'create'] | [LivePoll, 'preview'] | null
    > = ref(null);

    watch(pollQuery.collectionData, newValue => {
      if (newValue) {
        polls.value = newValue;
      } else {
        polls.value = [];
      }
      isLoading.value = false;
    });

    const hasPollHistory = computed(
      () => polls.value && polls.value.length != 0
    );

    function onShowModal(type: LivePollType) {
      modalTypeShown.value = [type, 'create'];
    }
    function onPreview(livePoll: LivePoll) {
      modalTypeShown.value = [livePoll, 'preview'];
    }
    function onModalClose() {
      modalTypeShown.value = null;
    }
    return {
      isLoading,
      hasPollHistory,
      modalTypeShown,
      LivePollType,
      polls,
      canAccessPoll,
      onShowModal,
      onModalClose,
      onPreview
    };
  }
});
