
import { computed, defineComponent, onBeforeMount, ref } from 'vue';
import BounceLoading from '@/components/BounceLoading.vue';
import Pricing from '@/pages/Pricing.vue';
import firebase from '@/firebaseApp';
import {
  handlePlaygroundSignIn,
  handleZoomOnSignedIn,
  handleZoomOnSignedOut,
  isDev,
  isPlayground,
  isProd,
  redirectToLogin,
  redirectToPlay
} from '@/utils/helper';
import useFirebaseDocument from '@/hooks/firebase/useFirebaseDocument';
import { initAmplitude } from '@/config/amplitudeConfig';
import { useStore } from '@/store';
import {
  UPDATE_SURVEY_1_ANSWER,
  UPDATE_USER,
  UPDATE_USER_DOC,
  UPDATE_USER_SUBSCRIPTION_DETAILS
} from '@/store/mutation-types';
import useFirebaseLoginOut from '@/hooks/firebase/useFirebaseLoginOut';
import { hideFreshdesk } from '@/config/freshdeskConfig';

export default defineComponent({
  name: 'PricingEntry',
  emits: ['show-cancel-subscription', 'show-renew-subscription'],
  components: {
    BounceLoading,
    Pricing
  },
  setup() {
    const playgroundDev: 'osho' | 'dag' | 'yugene' = 'yugene';
    const store = useStore();
    const userDoc = computed(() => store.getters.getUserDoc);
    const subscriptionDetails = computed(
      () => store.getters.getSubscriptionDetails
    );
    const userDocumentProps = useFirebaseDocument('minigame_user', {
      onMounted: false,
      documentId: undefined
    });
    const showInitialLoading = ref(true);

    onBeforeMount(async () => {
      if (isPlayground) {
        handlePlaygroundSignIn(playgroundDev);
      }
    });

    async function handleSignedInUser(
      user: firebase.User,
      needRefreshToken: boolean
    ) {
      hideFreshdesk();
      if (store.state.user === undefined) {
        store.commit(UPDATE_USER, {
          newUser: user
        });
      }
      if (store.state.userDoc === undefined) {
        await userDocumentProps.getDocument(10, user.uid);
        if (userDocumentProps.documentData.value === null) {
          console.log('Waiting 3s for initialization');
          await new Promise(resolve => setTimeout(resolve, 3000));
          await userDocumentProps.getDocument(10, user.uid);
        }
        store.commit(UPDATE_USER_DOC, {
          newUserDoc: userDocumentProps.documentData.value
        });
        if (userDoc.value['survey1Answer']) {
          store.commit(UPDATE_SURVEY_1_ANSWER, {
            newSurvey1Answer: userDoc.value['survey1Answer']
          });
        }
      }
      if (subscriptionDetails.value.subscriptionLevel === undefined) {
        store.commit(UPDATE_USER_SUBSCRIPTION_DETAILS, {
          newSubscriptionDetails: await useFirebaseLoginOut().getSubscriptionDetails()
        });
      }

      if (needRefreshToken) {
        await user.getIdToken(true);
        store.commit(UPDATE_USER, {
          newUser: user
        });
        store.commit(UPDATE_USER_SUBSCRIPTION_DETAILS, {
          newSubscriptionDetails: await useFirebaseLoginOut().getSubscriptionDetails()
        });
      }
      await handleZoomOnSignedIn(store.state.zoomClientStatus);
      showInitialLoading.value = false;

      // log after confirming user is logged in
      initAmplitude();
    }

    async function handleSignedOutUser() {
      if (store.state.zoomClientStatus?.inZoomClient) {
        await handleZoomOnSignedOut(store.state.zoomClientStatus);
      } else {
        if (isPlayground) {
          showInitialLoading.value = false;
        } else if (isDev || isProd) {
          if (!showInitialLoading.value) {
            redirectToPlay('logout');
          } else {
            redirectToLogin('source', 'pricing');
          }
        }
      }
    }

    firebase.auth().onAuthStateChanged(_user => {
      if (_user === null || _user.isAnonymous) {
        handleSignedOutUser();
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const selectedAction = urlParams.get('selected');
        handleSignedInUser(_user, selectedAction == 'subscribed');
      }
    });

    return {
      showInitialLoading
    };
  }
});
