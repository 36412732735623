export class YesOrNoTopic {
  public word: string = '';
  public hint: string = '';
  public index: number = -1;
  public key: number = -1;

  public constructor(fields?: {
    word?: string;
    hint?: string;
    index?: number;
    key?: number;
  }) {
    if (fields) Object.assign(this, fields);
  }
}
