
import { computed, defineComponent, ref } from 'vue';
import { freeGameTypes } from '@/utils/gameConstants';
import { newGameTypes } from '@/utils/gameConstants';
import {
  comingSoonGameTypes,
  detailGameTypes,
  getGameDescriptionByType,
  getGameRecommendedPlayersByType
} from '@/utils/gameConstants';
import GameComingSoonModal from '@/components/Modal/GameComingSoonModal.vue';
import { useStore } from '@/store';
import router from '@/router';
import { isExpiredFreeTrialPlan, isFreePlan } from '@/utils/pricing_rules';

export default defineComponent({
  name: 'GameInfo',
  components: { GameComingSoonModal },
  props: {
    type: {
      type: String,
      required: true
    }
  },
  emits: ['view-details', 'about'],
  setup(props, context) {
    const isLoading = ref(false);
    const store = useStore();
    const userDoc = computed(() => store.getters.getUserDoc);
    const subscriptionDetails = computed(
      () => store.getters.getSubscriptionDetails
    );
    const version = computed(() => store.getters.getDisplayedVersion);
    const showGameComingSoonModal = ref(false);
    const gamesAvailableToFreePlan = computed(
      () =>
        (isFreePlan(subscriptionDetails.value) ||
          isExpiredFreeTrialPlan(subscriptionDetails.value, userDoc.value)) &&
        freeGameTypes.includes(props.type)
    );
    const gamesWithNewLabel = ref(newGameTypes.includes(props.type));
    const gamesGrayedFreePlan = computed(
      () =>
        (isFreePlan(subscriptionDetails.value) ||
          isExpiredFreeTrialPlan(subscriptionDetails.value, userDoc.value)) &&
        !freeGameTypes.includes(props.type)
    );

    function viewDetails() {
      if (comingSoonGameTypes.includes(props.type)) {
        showGameComingSoonModal.value = true;
        return;
      }
      if (isExpiredFreeTrialPlan(subscriptionDetails.value, userDoc.value)) {
        const subscriptionEnded =
          subscriptionDetails.value.subscriptionEnded ?? false;
        goToPricing(
          subscriptionEnded ? 'subscription-ended' : 'free-trial-ended'
        );
        return;
      } else if (
        !freeGameTypes.includes(props.type) &&
        isFreePlan(subscriptionDetails.value)
      ) {
        goToPricing('subscription-ended');
        return;
      }

      context.emit('view-details', {
        type: props.type,
        source: 'GameList',
        onLoading: () => {
          isLoading.value = true;
        },
        onSuccess: () => {
          isLoading.value = false;
        },
        onError: () => {
          isLoading.value = false;
        }
      });
    }

    function showAbout() {
      context.emit('about', props.type);
    }

    function goToPricing(source: string) {
      router.push({
        name: 'Pricing',
        query: { source: source }
      });
    }

    return {
      detailGameTypes,
      gamesAvailableToFreePlan,
      gamesWithNewLabel,
      gamesGrayedFreePlan,
      version,
      showGameComingSoonModal,
      isLoading,
      getGameDescriptionByType,
      getGameRecommendedPlayersByType,
      viewDetails,
      showAbout,
      goToPricing
    };
  }
});
