
import usePostAPI from '@/hooks/usePostAPI';
import { firebaseModifiedSource, getEndpoint } from '@/utils/helper';
import { computed, defineComponent, onMounted, ref } from 'vue';
import { Carousel, Slide } from 'vue3-carousel';
import CategoryFilterMobile from '@/components/CategoryFilterMobile.vue';
import SkeletonLoading from '@/components/SkeletonLoading.vue';
import DeskopOrTwoDevicesWarningBar from '@/components/Modal/AboutComponent/DeskopOrTwoDevicesWarningBar.vue';
import { useStore } from '@/store';
import { UPDATE_MEDITATION_TRACKS } from '@/store/mutation-types';

export default defineComponent({
  name: 'MeditationSelectedView',
  emits: ['start'],
  components: {
    Carousel,
    Slide,
    CategoryFilterMobile,
    SkeletonLoading,
    DeskopOrTwoDevicesWarningBar
  },
  setup(_, context) {
    onMounted(() => {
      getMeditationContent();
    });
    const store = useStore();
    const meditationTracks = computed(() => store.getters.getMeditationTracks);
    // const meditationTracks = ref([] as any[]);
    const selectedTag = ref('all');
    const selectedDurationIndex = ref(-1);
    const selectedSortIndex = ref(0);
    const tagToTabNames = {
      all: 'All',
      reducing_stress: 'Relaxed',
      happiness: 'Happy',
      energy_booster: 'Energized',
      improving_productivity: 'Productive',
      mindfulness_practice: 'Mindful'
    };
    const tagToNames = {
      all: 'All',
      reducing_stress: 'Reducing Stress',
      happiness: 'Happiness',
      energy_booster: 'Energy booster',
      improving_productivity: 'Improving productivity',
      mindfulness_practice: 'Mindfulness Practice'
    };

    const durationRanges = [4, 7, 10];
    const imagesUrl = ref([] as string[]);
    const getMeditationContent = async function() {
      try {
        if (meditationTracks.value === undefined) {
          let tracks: any[] = [];
          const response = await usePostAPI(
            getEndpoint('meditationTracks'),
            {}
          );
          if (response.status == 200) {
            imagesUrl.value = response.data.imagesUrl;
            tracks = response.data.tracks;
            tracks = sortByPremiumPlan(tracks);
            for (let index in tracks) {
              let track = tracks[index];
              let urls = imagesUrl.value.filter(val =>
                val.includes(`${track.trackId}.jpeg`)
              );
              if (urls.length != 0) {
                tracks[index].imageURL = urls[0];
              }
            }
            store.commit(UPDATE_MEDITATION_TRACKS, {
              newMeditationTracks: tracks
            });
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    const filteredTracks = computed(() => {
      let tracks: any[] = [];
      if (meditationTracks.value.length == 0) return [];
      // apply tag filter
      tracks = filterByTag(meditationTracks.value, selectedTag.value);

      // apply duration filter
      tracks = filterByDuration(tracks, selectedDurationIndex.value + 1);

      return tracks;
    });

    const filterByTag = function(tracks: any[], tag: string) {
      return tracks.filter(value => {
        if (tag == '' || tag == 'all') return 1;
        else return value.tags.includes(tag);
      });
    };
    const filterByDuration = function(tracks: any[], durationIndex: number) {
      return tracks.filter(value => {
        let duration = Math.round(value.duration / 60);
        if (durationIndex == 0) {
          return 1;
        } else if (durationIndex == 1) {
          return duration >= durationRanges[0] && duration <= durationRanges[1];
        } else if (durationIndex == 2) {
          return duration > durationRanges[1] && duration <= durationRanges[2];
        }
      });
    };

    const sortByPremiumPlan = function(tracks: any[]) {
      return tracks.sort((a, b) => {
        return a.access_level == 'Free' && b.access_level != 'Free'
          ? -1
          : a.access_level != 'Free' && b.access_level == 'Free'
          ? 1
          : 0;
      });
    };
    const onTag = function(tag: string) {
      selectedTag.value = tag;
    };
    const onDuration = function(index: number) {
      selectedDurationIndex.value = index;
    };
    const onSort = function(index: number) {
      selectedSortIndex.value = index;
    };
    const onTrackTap = function(track: any) {
      context.emit('start', { gameName: 'guided_meditation', track: track });
    };
    const durationFilterItems = [
      `${durationRanges[0]} - ${durationRanges[1]}  mins`,
      `${durationRanges[1] + 1} - ${durationRanges[2]} mins`
    ];
    const sortItems = [
      'Recommended',
      `Popularity`,
      `Duration: Low to High`,
      `Duration: High to Low`
    ];

    const settings = {
      itemsToShow: 2,
      snapAlign: 'center'
    };
    const breakpoints = {
      570: {
        itemsToShow: 3.5,
        snapAlign: 'center'
      },
      768: {
        itemsToShow: 5,
        snapAlign: 'center'
      },
      1024: {
        itemsToShow: 6,
        snapAlign: 'center'
      }
    };
    return {
      filteredTracks,
      tagToNames,
      tagToTabNames,
      selectedTag,
      selectedDurationIndex,
      selectedSortIndex,
      onTag,
      onDuration,
      onSort,
      onTrackTap,
      durationFilterItems,
      sortItems,
      meditationTracks,
      settings,
      breakpoints,
      firebaseModifiedSource
    };
  }
});
