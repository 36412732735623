
import useFirebaseCollection from '@/hooks/firebase/useFirebaseCollection';
import moment from 'moment';
import { useStore } from '@/store';
import { computed, defineComponent, Ref, ref } from 'vue';
import vClickOutside from 'click-outside-vue3';
import firebase from '@/firebaseApp';
import router from '@/router';
import { isProd, isGameRoomOpen } from '@/utils/helper';
import {
  isOneOffPlan,
  isExpiredFreeTrialPlan,
  hasTeamManagementAccess,
  canAccessUsageReport
} from '@/utils/pricing_rules';
import useFirebaseDocument from '@/hooks/firebase/useFirebaseDocument';
import { UPDATE_PROMOTION_FLAG, UPDATE_USER_DOC } from '@/store/mutation-types';
import { amplitudeEvent } from '@/config/amplitudeConfig';
import { NavigationTabs } from '@/models/navigationTabs';
import ActiveRoomModal from '@/components/Modal/ActiveRoomModal.vue';
export default defineComponent({
  name: 'LeftNavBar',
  directives: {
    clickOutside: vClickOutside.directive
  },
  emits: ['on-tab'],
  props: {
    activeTab: {
      type: String,
      required: true
    },
    selectedType: {
      type: String
    }
  },
  components: { ActiveRoomModal },
  setup(props, context) {
    const store = useStore();
    const lobbyDoc = computed(() => store.getters.getLobbyDoc);
    const userDoc = computed(() => store.getters.getUserDoc);
    const isActiveRoomClosed = computed(
      () => !isGameRoomOpen(lobbyDoc.value ?? {})
    );

    const isLoading = ref(false);
    const brightfulBitesQuery = useFirebaseCollection('minigame_bites', {
      onMounted: true,
      fieldPath: 'createdAt',
      opStr: '<=',
      value: moment().format('YYYY-MM-DD'),
      orderBy: 'createdAt',
      isDesc: true,
      limit: 1
    });
    const subscriptionDetails = computed(
      () => store.getters.getSubscriptionDetails
    );
    const freeTrialEndedCompletely = computed(() => {
      return isExpiredFreeTrialPlan(subscriptionDetails.value, userDoc.value);
    });
    // const userTotalGameplays = computed(() => store.getters.getTotalGameplays);
    const clickedTab: Ref<String> = ref(props.activeTab);

    const canAccessAdmin = computed(() =>
      hasTeamManagementAccess(subscriptionDetails.value)
    );

    const canAccessUsageReportValue = computed(() =>
      canAccessUsageReport(subscriptionDetails.value)
    );

    const hasUnreadBites = computed(() => {
      if (
        freeTrialEndedCompletely.value ||
        isOneOffPlan(subscriptionDetails.value)
      ) {
        return false;
      }
      const bites = brightfulBitesQuery.collectionData.value ?? [];
      if (bites.length == 0) {
        return false;
      } else {
        return userDoc.value['readBiteDoc'] != bites[0].id;
      }
    });

    const promotionFlag = computed(() => store.getters.getPromotionFlag);

    // const showBitesPromotion = computed(() => promotionFlag.value === 'bites');
    // const showSlackPromotion = computed(() => promotionFlag.value === 'slack');
    // const showLeaderboardPromotion = computed(
    //   () => promotionFlag.value === 'leaderboard'
    // );
    // const showTeamPromotion = computed(() => promotionFlag.value === 'team');

    const showBitesPromotion = computed(() => false);
    // const showSlackPromotion = computed(() => false);
    const showLeaderboardPromotion = computed(() => false);
    const showTeamPromotion = computed(() => false);

    const showEventToolsPromotion = computed(
      () => promotionFlag.value === 'event_tools'
    );

    // const showSlackTab = computed(() => {
    //   const inZoomClient = store.state.zoomClientStatus?.inZoomClient;
    //   if (inZoomClient) return false;
    //   return true;
    // });

    const showAll = computed(() => {
      return router.currentRoute.value.query.show === 'all';
    });

    function onTab(tab: NavigationTabs) {
      context.emit('on-tab', tab);
      clickedTab.value = tab;
    }
    function handlePromotion(flag: NavigationTabs) {
      amplitudeEvent('clickPromotionPopup', {
        eventTime: new Date(Date.now()).toISOString(),
        content: flag
      });
      onTab(flag);
      hidePromotion(flag);
    }
    async function hidePromotion(flag: NavigationTabs) {
      store.commit(UPDATE_PROMOTION_FLAG, {
        newPromotionFlag: undefined
      });
      const promotionProperty = `seenPromotions.${flag}`;
      await firebase
        .firestore()
        .collection('minigame_user')
        .doc(userDoc.value.id)
        .update({
          [promotionProperty]: true
        });
      const userDocumentProps = useFirebaseDocument('minigame_user', {
        onMounted: false,
        documentId: undefined
      });
      await userDocumentProps.getDocument(10, userDoc.value.id);
      store.commit(UPDATE_USER_DOC, {
        newUserDoc: userDocumentProps.documentData.value
      });
    }

    // async function hideSlackPromotion() {
    //   store.commit(UPDATE_PROMOTION_FLAG, {
    //     newPromotionFlag: undefined
    //   });
    //   await firebase
    //     .firestore()
    //     .collection('minigame_user')
    //     .doc(userDoc.value.id)
    //     .update({
    //       'seenPromotions.slack': userTotalGameplays.value >= 10 ? 10 : 1
    //     });
    //   const userDocumentProps = useFirebaseDocument('minigame_user', {
    //     onMounted: false,
    //     documentId: undefined
    //   });
    //   await userDocumentProps.getDocument(10, userDoc.value.id);
    //   store.commit(UPDATE_USER_DOC, {
    //     newUserDoc: userDocumentProps.documentData.value
    //   });
    // }

    // function handleSlackPromotion() {
    //   amplitudeEvent('clickPromotionPopup', {
    //     eventTime: new Date(Date.now()).toISOString(),
    //     content: 'slack'
    //   });
    //   onTab(NavigationTabs.SLACK);
    //   hideSlackPromotion();
    // }

    async function hideTeamPromotion() {
      const seenPromotions = userDoc.value['seenPromotions'] ?? {};
      const seenTeam = seenPromotions['team'] ?? 0;
      const guideStep = seenTeam === 0 ? 1 : 10;

      store.commit(UPDATE_PROMOTION_FLAG, {
        newPromotionFlag: undefined
      });
      await firebase
        .firestore()
        .collection('minigame_user')
        .doc(userDoc.value.id)
        .update({
          'seenPromotions.team': guideStep
        });
      const userDocumentProps = useFirebaseDocument('minigame_user', {
        onMounted: false,
        documentId: undefined
      });
      await userDocumentProps.getDocument(10, userDoc.value.id);
      store.commit(UPDATE_USER_DOC, {
        newUserDoc: userDocumentProps.documentData.value
      });
    }

    function handleTeamPromotion() {
      amplitudeEvent('clickPromotionPopup', {
        eventTime: new Date(Date.now()).toISOString(),
        content: 'team'
      });
      onTab(NavigationTabs.HOME);
      hideTeamPromotion();
    }

    function onContactUs() {
      let routeData = router.resolve({
        name: 'Feedback',
        query: {
          source: 'dashboard'
        }
      });
      window.open(routeData.href, '_blank');
    }

    return {
      isProd,
      isActiveRoomClosed,
      isLoading,
      clickedTab,
      NavigationTabs,
      onTab,
      canAccessAdmin,
      canAccessUsageReportValue,
      hasUnreadBites,
      showBitesPromotion,
      handlePromotion,
      hidePromotion,
      showEventToolsPromotion,

      // showSlackPromotion,
      // showSlackTab,
      // hideSlackPromotion,
      // handleSlackPromotion,
      showLeaderboardPromotion,

      showTeamPromotion,
      hideTeamPromotion,
      handleTeamPromotion,
      onContactUs,
      showAll
    };
  }
});
