
import { computed, defineComponent, onBeforeMount } from 'vue';
import EditCustomizedGameTile from '@/components/EditCustomizedGameTile.vue';
import router from '@/router';
import useFirebaseDocument from '@/hooks/firebase/useFirebaseDocument';
import { useStore } from '@/store';
import { isFreeOrLitePlan } from '@/utils/pricing_rules';

export default defineComponent({
  name: 'YourCustomizedGames',
  emits: [
    'customized-game-start',
    'create-customized-game',
    'show-mobile-screen-unsupported',
    'customized-game-edit-block'
  ],
  props: ['error'],
  components: {
    EditCustomizedGameTile
  },
  setup(_, context) {
    const store = useStore();
    const userDoc = computed(() => store.getters.getUserDoc);
    const subscriptionDetails = computed(
      () => store.getters.getSubscriptionDetails
    );
    const canCreateCustomGame = computed(
      () => !isFreeOrLitePlan(subscriptionDetails.value, userDoc.value)
    );
    const userCustomizedGames = computed(() => {
      if (!store.getters.getUserCustomizedGames) return [];
      const games = [...store.getters.getUserCustomizedGames];
      return games.sort((a, b) => b.dateUpdated - a.dateUpdated);
    });

    function startCustomizedGame(event) {
      context.emit('customized-game-start', {
        gameName: event.gameName,
        customGameName: event.customGameName,
        customGameId: event.customGameId,
        source: 'GameList'
      });
    }

    const onGameCreate = function() {
      if (window.innerWidth <= 575) {
        context.emit('show-mobile-screen-unsupported');
        return;
      }
      context.emit('create-customized-game');
    };

    const onGameEdit = function(customGameId: string) {
      if (window.innerWidth <= 575) {
        context.emit('show-mobile-screen-unsupported');
        return;
      }
      if (!canCreateCustomGame.value) {
        context.emit('customized-game-edit-block');
      } else {
        let routeData = router.resolve({
          name: 'EditCustomGame',
          query: {
            customGameId: customGameId
          }
        });
        window.open(routeData.href, '_blank');
      }
    };

    const onGameDelete = async function(customGameDocId: string) {
      try {
        await useFirebaseDocument('minigame_custom_game_public').deleteDocument(
          customGameDocId
        );
        // await usePostAPI(getEndpoint('deleteCustomGamePage'), {
        //   customGameId: customGameDocId
        // });
      } catch (err) {
        console.log(err);
      }
    };

    onBeforeMount(() => {
      window.scrollTo(0, 0);
    });

    return {
      userCustomizedGames,
      startCustomizedGame,
      onGameCreate,
      onGameEdit,
      onGameDelete
    };
  }
});
