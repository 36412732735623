import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "games-filters mt-4"
}
const _hoisted_2 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CategoryFilterMobile = _resolveComponent("CategoryFilterMobile")
  const _component_LeaderboardList = _resolveComponent("LeaderboardList")
  const _component_LeaderboardDetail = _resolveComponent("LeaderboardDetail")

  return (_openBlock(), _createBlock(_Fragment, null, [
    (
      _ctx.canAccessFullLeaderboardValue &&
        !_ctx.selectedType &&
        _ctx.collectionData.length > 0
    )
      ? (_openBlock(), _createBlock("section", _hoisted_1, [
          _createVNode("div", _hoisted_2, [
            _createVNode(_component_CategoryFilterMobile, {
              icons: ['icon-category', 'icon-calendar'],
              titles: ['Game', 'Period'],
              textForNoFilter: ['All', 'All time'],
              multiFilters: [_ctx.leaderboardGameNames, _ctx.gamePeriods],
              selectedIndices: [_ctx.leaderboardGameNameIndex, _ctx.leaderboardGamePeriod],
              includeAlls: [true, true],
              hasDates: [false, true],
              dateTypes: [[], ['month', 'year']],
              enabledMonths: _ctx.leaderboardEnabledMonths,
              enabledYears: _ctx.leaderboardEnabledYears,
              switchText: _ctx.hasOtherHost ? 'Include other hosts’ games' : undefined,
              switchValue: _ctx.includeOtherHostLeaderboard,
              onSelect: _cache[1] || (_cache[1] = ($event: any) => (
          $event[1] == 0
            ? _ctx.onSelectLeaderboardGameName($event[0])
            : _ctx.onSelectLeaderboardGamePeriod($event[0])
        )),
              onSwitch: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onIncludeOtherHostLeaderboard($event)))
            }, null, 8, ["multiFilters", "selectedIndices", "enabledMonths", "enabledYears", "switchText", "switchValue"])
          ])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.selectedType)
      ? (_openBlock(), _createBlock(_component_LeaderboardList, {
          key: 1,
          data: _ctx.leaderboardData,
          isLoading: _ctx.isLoading,
          noPadding: 
      _ctx.canAccessFullLeaderboardValue &&
        !_ctx.selectedType &&
        _ctx.collectionData.length > 0
    ,
          onScroll: _cache[3] || (_cache[3] = ($event: any) => (_ctx.saveScrollPosition($event)))
        }, null, 8, ["data", "isLoading", "noPadding"]))
      : _createCommentVNode("", true),
    (_ctx.selectedType)
      ? (_openBlock(), _createBlock(_component_LeaderboardDetail, {
          key: 2,
          gameName: _ctx.selectedType,
          includeOtherHost: _ctx.includeOtherHostLeaderboard,
          onUpgrade: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onOpenUpgradeModal()))
        }, null, 8, ["gameName", "includeOtherHost"]))
      : _createCommentVNode("", true)
  ], 64))
}