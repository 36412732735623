export const ratingPollCss = `.social-image {
  background: linear-gradient(180deg, #2e3062 0%, #4a4c93 100%);
  width: 600px;
  padding: 24px;
  margin: auto;
  font-family: 'gilroy__boldbold';
}

.logo {
  margin: auto;
  display: block;
  margin-bottom: 24px;
}

.social-image h1 {
  text-align: center;
}

.social-image h1 small{
  font-family: 'gilroy_regularregular';
  display: block;
  font-size: 16px;
  opacity: 0.5
}

.color-blue {
  color: #1c9dee;
}

.wrapper {
  border: 2px solid #000000;
  color: #fff;
  padding: 24px;
  text-align: left;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Fbg-dot.png?alt=media&token=a061b0e8-d7f9-44cc-a05a-4fe6c3000a72");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}

.footer {
  text-align: left;
}

.footer > img {
  height: 40px;
  display: inline-block;

  /* float: left; */
}

.footer > p {
  display: inline-block;
  margin-top: 24px;

  /* height: 40px; */
  float: right;
}

.color-orange {
  color: #fc7028;
}




.blur-badge.green {
  background: rgba(50, 194, 112, 0.12);
  color: #32c270;
  font-size:12px;
  padding: 4px 8px;
}

.mr-3 {
  margin-right: 6px;
}

.mr-6 {
  margin-right: 6px;
}
.d-flex  {
  display: flex;
}
.align-items-center {
  align-item: center;
}
.justify-content-end {
  justify-content: end;
}

.sm-mt-1 {
      margin-top: 6px;
    display: block;
}



.preview-rating {
  background-color: transparent;
  padding: 12px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.preview-rating > div {
  display: block;
  width: 100%;
}
.preview-rating > div.chart {
  height: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 6px;
  padding-top: 24px;
}
.preview-rating > div.chart .item {
  width: 20%;
  background-color: #e0e0e0;
  position: relative;
}
.preview-rating > div.chart .item > span {
  top: -24px;
  left: 0;
  position: absolute;
  text-align: center;
  color: #fff;
  font-size: 12px;
  width: 100%;
}
.preview-rating > div.chart .item .value-column {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #FF7979;
}
.preview-rating > div.chart .item:last-child .value-column {
  background: #32c270;
}
.preview-rating > div.chart[data-value="5"] .item:nth-of-type(2) .value-column {
  background: #CE8D70;
}
.preview-rating > div.chart[data-value="5"] .item:nth-of-type(3) .value-column {
  background: #A39D68;
}
.preview-rating > div.chart[data-value="5"] .item:nth-of-type(4) .value-column {
  background: #63B65C;
}
.preview-rating > div.chart[data-value="6"] .item:nth-of-type(2) .value-column {
  background: #CE8D70;
}
.preview-rating > div.chart[data-value="6"] .item:nth-of-type(3) .value-column {
  background: #B9956C;
}
.preview-rating > div.chart[data-value="6"] .item:nth-of-type(4) .value-column {
  background: #8DA664;
}
.preview-rating > div.chart[data-value="6"] .item:nth-of-type(5) .value-column {
  background: #63B65C;
}
.preview-rating > div.chart[data-value="8"] .item:nth-of-type(2) .value-column,
.preview-rating > div.chart[data-value="7"] .item:nth-of-type(2) .value-column {
  background: #CE8D70;
}
.preview-rating > div.chart[data-value="8"] .item:nth-of-type(3) .value-column,
.preview-rating > div.chart[data-value="7"] .item:nth-of-type(3) .value-column {
  background: #B9956C;
}
.preview-rating > div.chart[data-value="8"] .item:nth-of-type(4) .value-column,
.preview-rating > div.chart[data-value="7"] .item:nth-of-type(4) .value-column {
  background: #8DA664;
}
.preview-rating > div.chart[data-value="8"] .item:nth-of-type(5) .value-column,
.preview-rating > div.chart[data-value="7"] .item:nth-of-type(5) .value-column {
  background: #78AE60;
}
.preview-rating > div.chart[data-value="8"] .item:nth-of-type(6) .value-column,
.preview-rating > div.chart[data-value="7"] .item:nth-of-type(6) .value-column {
  background: #63B65C;
}
.preview-rating > div.chart[data-value="8"] .item:nth-of-type(7) .value-column {
  background: #63B65C;
}
.preview-rating > div.chart[data-value="10"] .item:nth-of-type(2) .value-column,
.preview-rating > div.chart[data-value="9"] .item:nth-of-type(2) .value-column {
  background: #E28574;
}
.preview-rating > div.chart[data-value="10"] .item:nth-of-type(3) .value-column,
.preview-rating > div.chart[data-value="9"] .item:nth-of-type(3) .value-column {
  background: #CE8D70;
}
.preview-rating > div.chart[data-value="10"] .item:nth-of-type(4) .value-column,
.preview-rating > div.chart[data-value="9"] .item:nth-of-type(4) .value-column {
  background: #B9956C;
}
.preview-rating > div.chart[data-value="10"] .item:nth-of-type(5) .value-column,
.preview-rating > div.chart[data-value="9"] .item:nth-of-type(5) .value-column {
  background: #A39D68;
}
.preview-rating > div.chart[data-value="10"] .item:nth-of-type(6) .value-column,
.preview-rating > div.chart[data-value="9"] .item:nth-of-type(6) .value-column {
  background: #8DA664;
}
.preview-rating > div.chart[data-value="10"] .item:nth-of-type(7) .value-column,
.preview-rating > div.chart[data-value="9"] .item:nth-of-type(7) .value-column {
  background: #78AE60;
}
.preview-rating > div.chart[data-value="10"] .item:nth-of-type(8) .value-column,
.preview-rating > div.chart[data-value="9"] .item:nth-of-type(8) .value-column {
  background: #63B65C;
}
.preview-rating > div.chart[data-value="10"] .item:nth-of-type(9) .value-column {
  background: #4CBF57;
}
.preview-rating > div.label {
  margin-bottom: 24px;
}
.preview-rating > div.label > img {
  width: 100%;
  margin-top: 12px;
}

.preview-rating > div.label > div > div:first-child {
  color: #FF7979;
}
.preview-rating > div.label > div > div:last-child {
  color: #32c270;
}
.preview-rating.light {
  background-color: #fff;
}
.preview-rating.light .chart .item span {
  color: #909090;
}
.preview-rating .rating-score {
  background: rgba(186, 186, 186, 0.12);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 12px 24px;
  text-align: center;
  border-radius: 6px;
}
.preview-rating .rating-score h2 {
  color: #fff;
  margin-top: 12px;
  font-family: "gilroy__boldbold";
  font-size: 30px;
}
.preview-rating .rating-score span {
  background-color: #2e3062;
  color: #fff;
  padding: 4px 12px;
  text-transform: uppercase;
  margin-top: -22px;
  font-size: 12px;
  border-radius: 12px;
  display: block;
}
.ml-auto {
  margin-left: auto;
}
.d-flex {
  display: flex;
}
`;
export const wordCloudPollCss = `.social-image {
    background: linear-gradient(180deg, #2e3062 0%, #4a4c93 100%);
    width: 600px;
    padding: 24px;
    margin: auto;
    font-family: 'gilroy__boldbold';
  }
  
  .logo {
    margin: auto;
    display: block;
    margin-bottom: 24px;
  }
  
  .social-image h1 {
    text-align: center;
  }
  
  .social-image h1 small{
    font-family: 'gilroy_regularregular';
    display: block;
    font-size: 16px;
    opacity: 0.5
  }
  
  .color-blue {
    color: #1c9dee;
  }
  
  .wrapper {
    border: 2px solid #000000;
    color: #fff;
    padding: 24px;
    text-align: left;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Fbg-dot.png?alt=media&token=a061b0e8-d7f9-44cc-a05a-4fe6c3000a72");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
  }
  
  .footer {
    text-align: left;
  }
  
  .footer > img {
    height: 40px;
    display: inline-block;
  
    /* float: left; */
  }
  
  .footer > p {
    display: inline-block;
    margin-top: 24px;
  
    /* height: 40px; */
    float: right;
  }
  
  .color-orange {
    color: #fc7028;
  }
  .preview-word-cloud {
      background-color: #3f4180;
      padding: 12px;
      height: 100%;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      margin-bottom: 24px;
        border-radius: 6px;
  }
  
  .preview-word-cloud img {
      width: 100%;
      border-radius: 6px;
      padding: 24px 12px;
      background-color: #fff;
          box-sizing: border-box;
  }
  
  
  .blur-badge.green {
    background: rgba(50, 194, 112, 0.12);
    color: #32c270;
    font-size:12px;
    padding: 4px 8px;
  }
  
  .mr-3 {
    margin-right: 6px;
  }
  
  .mr-6 {
    margin-right: 6px;
  }
  .d-flex  {
    display: flex;
  }
  .align-items-center {
    align-item: center;
  }
  .justify-content-end {
    justify-content: end;
  }
  
  .sm-mt-1 {
        margin-top: 6px;
      display: block;
  }
  `;

export const multipleChoicePollCss = `
  .social-image {
    background: linear-gradient(180deg, #2e3062 0%, #4a4c93 100%);
    width: 600px;
    padding: 24px;
    margin: auto;
    font-family: 'gilroy__boldbold';
  }
  
  .logo {
    margin: auto;
    display: block;
    margin-bottom: 24px;
  }
  
  .social-image h1 {
    text-align: center;
  }
  
  .social-image h1 small{
    font-family: 'gilroy_regularregular';
    display: block;
    font-size: 16px;
    opacity: 0.5
  }
  
  .color-blue {
    color: #1c9dee;
  }
  
  .wrapper {
    border: 2px solid #000000;
    color: #fff;
    padding: 24px;
    text-align: left;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Fbg-dot.png?alt=media&token=a061b0e8-d7f9-44cc-a05a-4fe6c3000a72");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
  }
  
  .footer {
    text-align: left;
  }
  
  .footer > img {
    height: 40px;
    display: inline-block;
  
    /* float: left; */
  }
  
  .footer > p {
    display: inline-block;
    margin-top: 24px;
  
    /* height: 40px; */
    float: right;
  }
  
  .color-orange {
    color: #fc7028;
  }
  
  
  
  
  .blur-badge.green {
    background: rgba(50, 194, 112, 0.12);
    color: #32c270;
    font-size:12px;
    padding: 4px 8px;
  }
  
  .mr-3 {
    margin-right: 6px;
  }
  
  .mr-6 {
    margin-right: 6px;
  }
  .d-flex  {
    display: flex;
  }
  .align-items-center {
    align-item: center;
  }
  .justify-content-end {
    justify-content: end;
  }
  
  
  
  @colors: @green, @yellow, @light-blue, #BADC58, @pink, #4CA1AF, @additional-purple , #FFA38D, @orange, #78E08F;
.loop-colors (@index) when (@index > 0) {
  .loop-colors(@index - 1);
  @color: extract(@colors, @index);
  .preview-bar-chart-item.color-@{index} strong{
    color: @color;
  }
  .preview-bar-chart-item.color-@{index} .progress .progress-bar{
    background-color: @color;
  }
}
.loop-colors(10);
  .preview-bar-chart {
    background-color: #2e3062;
    color: #fff;
    padding: 12px;
    margin-bottom: 24px;

    .preview-bar-chart-item {}
  }
  
  
  
    .blur-badge.green {
      background: rgba(50, 194, 112, 0.12);
      color: #32c270;
      font-size:12px;
      padding: 4px 8px;
    }
  
    .mr-3 {
      margin-right: 6px;
    }
  
    .mr-6 {
      margin-right: 6px;
    }
    .d-flex  {
      display: flex;
    }
    .align-items-center {
      align-item: center;
    }
    .justify-content-end {
      justify-content: end;
    }
  
    .sm-mt-1 {
          margin-top: 6px;
        display: block;
    }
  
  .ml-auto {
    margin-left: auto;
  }
  .d-flex {
    display: flex;
  }
  
  
  .h-3 {
      height: 0.75rem;
  }
  
  .mb-1, .my-1 {
      margin-bottom: 0.25rem!important;
  }
  
  
  .mt-2, .my-2 {
      margin-top: 0.5rem!important;
  }
  .mb-4, .my-4 {
      margin-bottom: 1.5rem!important;
  }
  .rounded-0 {
      border-radius: 0!important;
  }
  
  .progress {
      display: -ms-flexbox;
      display: flex;
      height: 1rem;
      overflow: hidden;
      line-height: 0;
      font-size: .75rem;
      background-color: #e9ecef;
      border-radius: 0.25rem;
  }
  
  .progress-bar {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-pack: center;
      justify-content: center;
      overflow: hidden;
      color: #fff;
      text-align: center;
      white-space: nowrap;
      background-color: #007bff;
      transition: width .6s ease;
  }
  
  .bg-blue {
    background-color: #5b86e5;
  }
  .bg-purple {
    background-color: #2e3062;
  }
  .bg-pink {
    background-color: #FD79A8;
  }
  .bg-dark-purple {
    background-color: #21234e;
  }
  .bg-light-blue {
    background: #F1F5F8;
  }
  .bg-light-grey {
    background: #f5f5f5;
  }
  .bg-purple {
    background: #2e3062;
  }
  .bg-white {
    background: #fff;
  }
  .bg-grey {
    background-color: #F9F9F9;
  }
  .bg-green {
    background-color: #32c270;
  }
  .bg-yellow {
    background-color: #f9ca24;
  }
  .bg-dark-4 {
    background-color: #909090;
  }
  .bg-dark-5 {
    background-color: #bcbcbc;
  }
  .bg-dark-6 {
    background-color: #e0e0e0;
  }
  .bg-additional-purple {
    background-color: #C67FE2;
  }
  .bg-gradient-blue {
    background: linear-gradient(135.34deg, #3CCCF8 3.34%, #1C9DEE 97.23%);
  }
  .bg-gradient-pink {
    background: linear-gradient(135.34deg, #DCAAF0 3.34%, #C67FE2 97.23%);
  }
  
  .color-primary {
    color: #2e3062;
  }
  .color-dark-1 {
    color: #212121;
  }
  .color-dark-3 {
    color: #646464;
  }
  .color-dark-4 {
    color: #909090;
  }
  .color-dark-5 {
    color: #bcbcbc;
  }
  .color-red {
    color: #d50000;
  }
  .color-orange {
    color: #fc7028 !important;
  }
  .color-yellow {
    color: #f9ca24;
  }
  .color-green {
    color: #32c270;
  }
  .color-light-green {
    color: #BADC58;
  }
  .color-grass-green {
    color: #4CA1AF;
  }
  .color-grey {
    color: #909090;
  }
  .color-blue {
    color: #5b86e5;
  }
  .color-red {
    color: #d50000;
  }
  .color-light-blue {
    color: #1C9DEE;
  }
  .color-pink {
    color: #FD79A8;
  }
  .color-white {
    color: #fff;
  }
  .color-additional-purple {
    color: #C67FE2;
  }
  
  `;

export const shortAnswerPollCss = `
  .social-image {
    background: linear-gradient(180deg, #2e3062 0%, #4a4c93 100%);
    width: 600px;
    padding: 24px;
    margin: auto;
    font-family: 'gilroy__boldbold';
  }
  
  .logo {
    margin: auto;
    display: block;
    margin-bottom: 24px;
  }
  
  .social-image h1 {
    text-align: center;
  }
  
  .social-image h1 small{
    font-family: 'gilroy_regularregular';
    display: block;
    font-size: 16px;
    opacity: 0.5
  }
  
  .color-blue {
    color: #1c9dee;
  }
  
  .wrapper {
    border: 2px solid #000000;
    color: #fff;
    padding: 24px;
    text-align: left;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Fbg-dot.png?alt=media&token=a061b0e8-d7f9-44cc-a05a-4fe6c3000a72");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
  }
  
  .footer {
    text-align: left;
  }
  
  .footer > img {
    height: 40px;
    display: inline-block;
  
    /* float: left; */
  }
  
  .footer > p {
    display: inline-block;
    margin-top: 24px;
  
    /* height: 40px; */
    float: right;
  }
  
  .color-orange {
    color: #fc7028;
  }
  
  
  
  
  .blur-badge.green {
    background: rgba(50, 194, 112, 0.12);
    color: #32c270;
    font-size:12px;
    padding: 4px 8px;
  }
  
  .mr-3 {
    margin-right: 6px;
  }
  
  .mr-6 {
    margin-right: 6px;
  }
  .d-flex  {
    display: flex;
  }
  .align-items-center {
    align-item: center;
  }
  .justify-content-end {
    justify-content: end;
  }
  
  .sm-mt-1 {
        margin-top: 6px;
      display: block;
  }
  
    .preview-short-answer {
      background-color: transparent;
      padding: 12px;
      margin-bottom: 24px;
    }
    .preview-short-answer .preview-short-answer-item {
      background-color: #2e3062;
      color: #fff;
      padding: 12px;
      position: relative;
    }
    .preview-short-answer .preview-short-answer-item strong {
      font-size: 12px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      opacity: 0.48;
    }
    .preview-short-answer .preview-short-answer-item strong span {
      font-size: 18px;
      margin-right: 3px;
    }
    .preview-short-answer .preview-short-answer-item + .preview-short-answer-item {
      margin-top: 6px;
    }
    .preview-short-answer .preview-short-answer-item .right-box {
      position: absolute;
      top: 0;
      right: 0;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      height: 100%;
      padding: 6px;
    }
    @media screen and (max-width: 575px) {
      .preview-short-answer .preview-short-answer-item .right-box {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
      }
    }
    @media screen and (max-width: 575px) {
      .preview-short-answer .preview-short-answer-item .right-box .justify-content-end {
        -webkit-box-pack: start !important;
            -ms-flex-pack: start !important;
                justify-content: start !important;
        margin-right: 12px;
      }
    }
    .preview-short-answer.light {
      background: #fff;
    }
    .preview-short-answer.light .preview-short-answer-item {
      background: #2E3062;
    }
    .preview-short-answer.light .preview-short-answer-item strong {
      color: #646464;
    }
    .preview-short-answer.light .preview-short-answer-item .text {
      color: #757575;
    }
    .preview-short-answer.white {
      /* background: #fff; */
    }
    .preview-short-answer.white .preview-short-answer-item {
      background-color: #fff;
      border: 1px solid #f1f1f1;
    }
    .preview-short-answer.white .preview-short-answer-item strong {
      color: #646464;
    }
    .preview-short-answer.white .preview-short-answer-item .text {
      color: #2e3062;
    }
  
    .blur-badge.green {
      background: rgba(50, 194, 112, 0.12);
      color: #32c270;
      font-size:12px;
      padding: 4px 8px;
    }
  
    .mr-3 {
      margin-right: 6px;
    }
  
    .mr-6 {
      margin-right: 6px;
    }
    .d-flex  {
      display: flex;
    }
    .align-items-center {
      align-item: center;
    }
    .justify-content-end {
      justify-content: end;
    }
  
    .sm-mt-1 {
          margin-top: 6px;
        display: block;
    }
  
  .ml-auto {
    margin-left: auto;
  }
  .d-flex {
    display: flex;
  }
  `;
