
import { defineComponent } from 'vue';
import PollHostLaunchPoll from '@/components/Modal/WhiteboardComponent/PollComponents/PollHostLaunchPoll.vue';
import PollHostResult from '@/components/Modal/WhiteboardComponent/PollComponents/PollHostResult.vue';
export default defineComponent({
  name: 'PollHost',
  components: {
    PollHostLaunchPoll,
    PollHostResult
  },
  props: {
    whiteboardId: {
      type: String,
      required: true
    },
    hasActivePoll: {
      type: Boolean,
      required: true
    },
    pollDoc: {
      type: Object
    },
    numParticipants: {
      type: Number,
      required: true
    }
  }
});
