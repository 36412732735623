
import { amplitudeEvent } from '@/config/amplitudeConfig';
import { RatingLivePoll } from '@/models/event_tools/live_poll/live_poll_model';
import { store } from '@/store';
import { LivePollType } from '@/utils/live_poll_enums';
import firebase from 'firebase';
import { computed, defineComponent, Ref, ref } from 'vue';
export default defineComponent({
  name: 'CreateRatingModal',

  props: {},
  emits: ['close'],
  setup(_, context) {
    const userDoc = computed(() => store.getters.getUserDoc);
    const minValAllowed = 2;
    const maxValAllowed = 10;
    const isButtonLoading: Ref<boolean> = ref(false);
    const pollData: Ref<RatingLivePoll> = ref({
      dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
      launchedCount: 0,
      labelHigh: '',
      labelLow: '',
      question: '',
      type: LivePollType.RATING,
      maxVal: 5,
      minVal: minValAllowed
    });
    function onClose() {
      context.emit('close');
    }

    function onMaxScoreUpdate(type: 'increment' | 'decrement') {
      if (type === 'increment') {
        if (pollData.value.maxVal >= maxValAllowed) return;
        pollData.value.maxVal = pollData.value.maxVal + 1;
      } else {
        if (pollData.value.maxVal <= minValAllowed) return;
        pollData.value.maxVal = pollData.value.maxVal - 1;
      }
    }
    const validForm = computed(() => {
      return (
        pollData.value.question?.trim() !== '' &&
        pollData.value.maxVal >= minValAllowed &&
        pollData.value.maxVal <= maxValAllowed
      );
    });

    async function onSave() {
      if (!validForm.value) {
        console.log('Form incomplete');
        console.log(pollData.value);
        return;
      }

      isButtonLoading.value = true;
      await firebase
        .firestore()
        .collection(
          `minigame_lobbies/${userDoc.value['defaultLobbyId']}/live_poll`
        )
        .add(pollData.value);
      console.log('new poll added');
      amplitudeEvent('savePoll', {
        eventTime: new Date(Date.now()).toISOString(),
        pollType: pollData.value.type
      });
      isButtonLoading.value = false;
      onClose();
    }
    return {
      onClose,
      onSave,
      onMaxScoreUpdate,

      pollData,
      isButtonLoading,
      minValAllowed,
      maxValAllowed,
      validForm
    };
  }
});
