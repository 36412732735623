
import { defineComponent } from 'vue';
import RecentlyPlayedTile from '@/components/Home/RecentlyPlayedTile.vue';

export default defineComponent({
  name: 'SkeletonLoading',
  components: {
    RecentlyPlayedTile
  },
  props: {
    type: String
  }
});
