
import { computed, defineComponent, onMounted, ref } from 'vue';
import useFirebaseAuth from '@/hooks/firebase/useFirebaseAuth';
import { amplitudeEvent } from '@/config/amplitudeConfig';

export default defineComponent({
  name: 'BeginnerIntroductionModal',
  emits: ['close', 'start'],
  setup(_, context) {
    const { currentUser } = useFirebaseAuth();

    const show = ref(true);
    const isDisabled = ref(false);
    const name = ref('');
    const isLoading = ref(false);

    const isValidName = computed(() => {
      return (
        name.value
          .replace(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/g, ' ')
          .trim() !== ''
      );
    });

    async function onUpdateName() {
      try {
        const correctedName = name.value
          .replace(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/g, ' ')
          .trim();
        isLoading.value = true;
        await currentUser.value?.updateProfile({
          displayName: correctedName
        });
        isLoading.value = false;
        // context.emit('start');
      } catch (error) {
        console.log(error);
      }
    }

    onMounted(() => {
      name.value = currentUser.value?.displayName ?? '';
      amplitudeEvent('openIntroModal', {
        eventTime: new Date(Date.now()).toISOString()
      });
    });

    const close = function(type: string) {
      show.value = false;
      setTimeout(() => {
        context.emit('close', type);
      }, 50);
    };

    return {
      show,
      isDisabled,
      close,
      name,
      isValidName,
      onUpdateName,
      isLoading
    };
  }
});
