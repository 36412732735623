import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, Teleport as _Teleport, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BounceLoading = _resolveComponent("BounceLoading")
  const _component_CreateWheelHavingHistory = _resolveComponent("CreateWheelHavingHistory")
  const _component_CreateWheelWithoutHistory = _resolveComponent("CreateWheelWithoutHistory")
  const _component_CreateWheelModal = _resolveComponent("CreateWheelModal")
  const _component_WheelPreviewModal = _resolveComponent("WheelPreviewModal")

  return (_openBlock(), _createBlock(_Fragment, null, [
    (!_ctx.canAccessWheel)
      ? (_openBlock(), _createBlock("div", _hoisted_1))
      : (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_BounceLoading, { key: 1 }))
        : (_ctx.hasWheelHistory)
          ? (_openBlock(), _createBlock(_component_CreateWheelHavingHistory, {
              key: 2,
              wheels: _ctx.wheels!,
              onShowModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onShowModal())),
              onPreview: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onPreview($event))),
              onLaunchModal: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('launch-modal', $event)))
            }, null, 8, ["wheels"]))
          : (_openBlock(), _createBlock(_component_CreateWheelWithoutHistory, {
              key: 3,
              onShowModal: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onShowModal()))
            })),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      (_ctx.modalTypeShown)
        ? (_openBlock(), _createBlock("div", _hoisted_2, [
            (_ctx.modalTypeShown[0] == 'create')
              ? (_openBlock(), _createBlock("div", _hoisted_3, [
                  _createVNode(_component_CreateWheelModal, {
                    onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onModalClose()))
                  })
                ]))
              : (_ctx.modalTypeShown[0] == 'preview')
                ? (_openBlock(), _createBlock("div", _hoisted_4, [
                    _createVNode(_component_WheelPreviewModal, {
                      onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onModalClose())),
                      wheel: _ctx.modalTypeShown[1]
                    }, null, 8, ["wheel"])
                  ]))
                : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]))
  ], 64))
}