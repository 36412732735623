export class Top5Question {
  public question: string = '';
  public answers: string[] = [];
  public index: number = -1;
  public key: number = -1;
  public type: string = 'fact';

  public constructor(fields?: {
    question?: string;
    type?: string;
    answers?: string[];
    index?: number;
    key?: number;
  }) {
    if (fields) Object.assign(this, fields);
  }
}
