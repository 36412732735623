
import { defineComponent, onMounted, ref } from 'vue';
import { amplitudeEvent } from '@/config/amplitudeConfig';
// import NumberOfPlayersTop5 from '@/components/Modal/AboutComponent/NumberOfPlayersComponents/NumberOfPlayersTop5.vue';
import HowToPlayTop5 from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayTop5.vue';

export default defineComponent({
  name: 'AboutTop5',
  components: {
    // NumberOfPlayersTop5
    HowToPlayTop5
  },
  setup() {
    const showTabs = ref([true, false]);

    onMounted(() => {
      amplitudeEvent('previewGame', {
        game: 'top5',
        version: 'regular',
        eventTime: new Date(Date.now()).toISOString()
      });
    });

    return {
      showTabs
    };
  }
});
