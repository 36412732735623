
import { computed, defineComponent, ref, watch } from 'vue';
import BounceLoading from '@/components/BounceLoading.vue';
import BrightfulHeader from '@/components/General/BrightfulHeader.vue';
// import FreeTrialEndingCouponBar from '@/components/Pricing/FreeTrialEndingCouponBar.vue';
// import ChristmasCouponBar from '@/components/Home/Special/ChristmasCouponBar.vue';
import AllPlansV5 from '@/components/Pricing/AllPlansV5.vue';
import AllPlansV6 from '@/components/Pricing/AllPlansV6.vue';
import PricingFAQ from '@/components/Pricing/PricingFAQ.vue';
import AccountSettingsModal from '@/components/Modal/AccountSettingsModal.vue';
import MobileNavModal from '@/components/Modal/MobileNavModal.vue';
import VerifyEmailModal from '@/components/Pricing/Modal/VerifyEmailModal.vue';
import ChangeEmailModal from '@/components/Pricing/Modal/ChangeEmailModal.vue';
import EducationEmailRequiredModal from '@/components/Pricing/Modal/EducationEmailRequiredModal.vue';
import EnterpriseCardModal from '@/components/Pricing/Modal/EnterpriseCardModal.vue';
import PaymentSuccessfulModal from '@/components/Pricing/Modal/PaymentSuccessfulModal.vue';
// import CouponCode from '@/components/Pricing/CouponCode.vue';
import BlockFreePlanModal from '@/components/Modal/BlockFreePlanModal.vue';
import {
  getEndpoint,
  hypenDigitGameRoomId,
  isGameRoomOpen,
  handleZoomOnSignedIn,
  openUrlOnExternalBrowserFromZoom,
  configureGameRoomButton
} from '@/utils/helper';
import { isFreeTrialPlan, isActiveSubscription } from '@/utils/pricing_rules';
import { amplitudeEvent } from '@/config/amplitudeConfig';
import firebase from '@/firebaseApp';
import router from '@/router';
import { SubscriptionLevel } from '@/models/SubscriptionLevel';
import { SubscriptionInterval } from '@/models/SubscriptionInterval';
import { loadStripe } from '@stripe/stripe-js/pure';
import {
  isNotValidEducationEmail,
  basicEducatorMonthly,
  basicEducatorAnnually,
  professionalEducatorMonthly,
  professionalEducatorAnnually,
  stripePublishableKey,
  isPricingBeforeV6
} from '@/utils/pricingConstant';
import usePostAPI from '@/hooks/usePostAPI';
import { useStore } from '@/store';
import { UPDATE_ACTIVEGAMEROOMID } from '@/store/mutation-types';
import { NavigationTabs } from '@/models/navigationTabs';
export default defineComponent({
  name: 'PricingMain',
  emits: ['show-cancel-subscription', 'show-renew-subscription'],
  components: {
    BounceLoading,
    BrightfulHeader,
    // FreeTrialEndingCouponBar,
    // ChristmasCouponBar,
    AllPlansV5,
    AllPlansV6,
    PricingFAQ,
    AccountSettingsModal,
    MobileNavModal,
    VerifyEmailModal,
    ChangeEmailModal,
    EducationEmailRequiredModal,
    EnterpriseCardModal,

    PaymentSuccessfulModal,
    BlockFreePlanModal
    // CouponCode
  },
  setup() {
    const store = useStore();
    const user = computed(() => store.getters.getUser);
    const userDoc = computed(() => store.getters.getUserDoc);
    const lobbyDoc = computed(() => store.getters.getLobbyDoc);
    const survey1Answer = computed(() => store.getters.getSurvey1Answer);
    const topTitle = computed(() => {
      // if (survey1Answer.value) {
      //   if (survey1Answer.value[0] == 'Business - Meeting')
      //     return 'Transform your meetings to fun and meaningful experiences.';
      //   if (survey1Answer.value[0] == 'Business - Events')
      //     return 'Turn your events into interactive and memorable experiences.';
      //   if (survey1Answer.value[0] == 'Education - Students')
      //     return 'Turn your online classes into fun and memorable experiences.';
      //   if (survey1Answer.value[0] == 'Personal - with family & friends')
      //     return 'Turn your video calls into fun and meaningful memories.';
      // }
      return 'Strengthen your connections while remote';
    });
    const isUserUsingCustomGameRoomId = computed(
      () => store.getters.isUserUsingCustomGameRoomId
    );

    const activeGameRoomId = computed(() => store.getters.activeGameRoomId);
    const activeGameName = computed(() => store.getters.activeGameName);
    const isActiveRoomClosed = computed(
      () => !isGameRoomOpen(lobbyDoc.value ?? {})
    );
    const subscriptionDetails = computed(
      () => store.getters.getSubscriptionDetails
    );
    //
    const freeTrialEnded = userDoc.value['freeTrialEnded'] ?? false;
    const showInitialLoading = ref(true);
    const showAccountSettingsModal = ref(false);
    const showMobileNavModal = ref(false);
    const showVerifyEmailModal = ref(false);
    const selectedPlanPriceId = ref('');
    const showChangeEmailModal = ref(false);
    const showEducationEmailRequiredModal = ref(false);
    const isEducation = ref(false);

    const showEnterpriseCardModal = ref(false);
    const showPaymentSuccessfulModal = ref(false);

    const urlParams = new URLSearchParams(window.location.search);
    const isFreeTrialEnded = urlParams.get('source') === 'free-trial-ended';
    const isSubscriptionEnded =
      urlParams.get('source') === 'subscription-ended';
    const isFromGame = computed(
      () => urlParams.get('source') === 'upgrade-from-game'
    );
    const isFromGameEnd = computed(() => urlParams.get('source') === 'gameEnd');
    const isFromGameNew = ref(false);
    const isFromGameEndNew = ref(false);
    const showUpgradeModal = ref(false);
    //
    const defaultLobbyId = ref('');
    const digitOnlyGameRoomId = ref('');
    // current subscribed plan
    const currentPlan = ref('');
    const currentPlanInterval = ref('');
    const inZoomClient = computed(() => {
      return store.state.zoomClientStatus?.inZoomClient ?? false;
    });
    const hasActiveSubscription = computed(() => {
      return isActiveSubscription(subscriptionDetails.value);
    });
    const showV5Pricing = computed(() => {
      return (
        hasActiveSubscription.value &&
        isPricingBeforeV6(subscriptionDetails.value)
      );
    });
    const shownDialog = computed(() => {
      return (
        showAccountSettingsModal.value ||
        showMobileNavModal.value ||
        showVerifyEmailModal.value ||
        showChangeEmailModal.value ||
        showEducationEmailRequiredModal.value
      );
    });

    watch(shownDialog, newValue => updateBodyCssForDialog(newValue));

    function updateBodyCssForDialog(shown: boolean) {
      if (shown) {
        document.body.className += ' modal-open';
        const needHelpBox = document.getElementById('freshworks-container');
        if (needHelpBox !== null) needHelpBox.hidden = true;
      } else {
        document.body.className = document.body.className.replaceAll(
          ' modal-open',
          ''
        );
        const needHelpBox = document.getElementById('freshworks-container');
        if (needHelpBox !== null) needHelpBox.hidden = false;
      }
    }

    updateBodyCssForDialog(false);

    async function handleSignedInUser() {
      currentPlan.value = subscriptionDetails.value.subscriptionLevel;
      currentPlanInterval.value =
        subscriptionDetails.value.subscriptionInterval;
      defaultLobbyId.value = userDoc.value['defaultLobbyId'];
      digitOnlyGameRoomId.value = hypenDigitGameRoomId(
        defaultLobbyId.value.substring(defaultLobbyId.value.indexOf('_') + 1)
      );
      store.commit(UPDATE_ACTIVEGAMEROOMID, {
        activeGameRoomId: userDoc.value['defaultGameRoomId']
      });
      await configureGameRoomButton(defaultLobbyId.value);
      await handleZoomOnSignedIn(store.state.zoomClientStatus);
      showInitialLoading.value = false;
      showUpgradeModal.value = isFreeTrialEnded || isSubscriptionEnded;
      window.scrollTo(0, 0);
      const urlParams = new URLSearchParams(window.location.search);
      const planToOpen = urlParams.get('plan');
      if (planToOpen) {
        if (planToOpen == 'enterprise') {
          showEnterpriseCardModal.value = true;
        }
      }
      const isNewlySubscribed = urlParams.get('source') === 'newSubscribed';
      isFromGameNew.value = urlParams.get('isFromGame') === 'true';
      isFromGameEndNew.value = urlParams.get('isFromGameEnd') === 'true';

      if (isNewlySubscribed) {
        showPaymentSuccessfulModal.value = true;
      }
      amplitudeEvent('visitedPricing', {
        eventTime: new Date(Date.now()).toISOString(),
        path: window.location.href.split('?')[0]
      });
    }
    handleSignedInUser();

    function showAccountSettings() {
      showAccountSettingsModal.value = true;
    }

    function onAccountSettingsClosed() {
      showAccountSettingsModal.value = false;
    }

    function showMobileNav() {
      showMobileNavModal.value = true;
    }

    function onMobileNavClosed(result: string) {
      showMobileNavModal.value = false;
      switch (result) {
        case 'home':
          onTab(NavigationTabs.HOME);
          break;
        case 'customized':
          onTab(NavigationTabs.CUSTOMIZED);
          break;
        case 'setting':
          showAccountSettings();
          break;
        default:
          break;
      }
    }

    function isGoogleAccount() {
      if (
        user.value &&
        user.value['providerData'] &&
        user.value['providerData'][0] &&
        user.value['providerData'][0]?.providerId.includes('google.com')
      ) {
        return true;
      }
      return false;
    }

    const proceedCheckout = async function(event) {
      const priceId = event?.priceId;
      try {
        if (event?.onLoading) {
          event.onLoading();
        }
        selectedPlanPriceId.value = priceId;
        if (isGoogleAccount()) {
          await stripeCheckout(true);
        } else {
          await stripeCheckout(false);
        }
        if (event?.onSuccess) {
          event.onSuccess();
        }
      } catch (err) {
        console.log(err);
        if (event?.onError) {
          event.onError();
        }
      }
    };

    const onVerifyEmailClosed = function() {
      showVerifyEmailModal.value = false;
    };

    const showChangeEmail = function() {
      showChangeEmailModal.value = true;
    };

    const showEducationEmailRequired = function() {
      showEducationEmailRequiredModal.value = true;
    };

    async function stripeCheckout(isGoogleAccount: boolean = false) {
      const priceId = selectedPlanPriceId.value;
      const user = firebase.auth().currentUser;
      if (!user || !user.email || priceId === undefined) return;
      if (
        priceId === basicEducatorMonthly ||
        priceId === basicEducatorAnnually ||
        priceId === professionalEducatorMonthly ||
        priceId === professionalEducatorAnnually
      ) {
        const whitelistDoc = (
          await firebase
            .firestore()
            .collection('minigame_edu_email_whitelist')
            .doc('whitelist')
            .get()
        ).data()!;
        const whiteListRegex = whitelistDoc['regex'];
        const whiteListDomains = whitelistDoc['domain'];
        if (
          isNotValidEducationEmail(user.email, whiteListRegex, whiteListDomains)
        ) {
          onVerifyEmailClosed();
          showEducationEmailRequired();
          return;
        }
      }
      amplitudeEvent('visitBillingInfoEmailConfirmed', {
        accountType: isGoogleAccount ? 'google' : 'email',
        eventTime: new Date(Date.now()).toISOString()
      });
      const isNewSubscribe =
        currentPlan.value === SubscriptionLevel.FREE ||
        isFreeTrialPlan(subscriptionDetails.value);
      const isOneOffPlan =
        subscriptionDetails.value.subscriptionLevel ==
        SubscriptionLevel.ONE_OFF_EVENT;
      const useCheckoutSession = isNewSubscribe || isOneOffPlan;
      try {
        const fromZoomClient = inZoomClient.value;
        const zoomUid = store.state.zoomClientStatus?.uid ?? null;
        const response = await usePostAPI(
          getEndpoint(
            useCheckoutSession ? 'createCheckoutSession' : 'createPortalLink',
            '/api/stripe/'
          ),
          useCheckoutSession
            ? {
                fromZoomClient: fromZoomClient,
                zoomUid: zoomUid,
                priceId: priceId,
                successUrl:
                  window.location.origin +
                  '/pricing' +
                  `?selected=subscribed&source=newSubscribed&isFromGame=${isFromGame.value}&isFromGameEnd=${isFromGameEnd.value}`,
                cancelUrl:
                  window.location.origin +
                  '/pricing' +
                  '?source=newSubscribeCancelled'
              }
            : {
                fromZoomClient: fromZoomClient,
                zoomUid: zoomUid,
                priceId: priceId,
                returnUrl:
                  window.location.origin +
                  '/pricing' +
                  '?selected=subscribed&source=fromUpgrade'
              }
        );
        const result = response.data;
        if (useCheckoutSession) {
          amplitudeEvent('forwardToStripe', {
            action: 'new',
            eventTime: new Date(Date.now()).toISOString()
          });
          const isNew = result['data']['isNewSubscribe'];
          if (isNew) {
            const sessionId = result['data']['sessionId'];
            const sessionUrl = result['data']['sessionUrl'];
            if (fromZoomClient) {
              // open in an external browser
              openUrlOnExternalBrowserFromZoom(sessionUrl);
            } else {
              const stripe = await loadStripe(stripePublishableKey);
              await stripe?.redirectToCheckout({ sessionId: sessionId });
            }
          } else {
            const sessionUrl = result['data']['sessionUrl'];
            if (fromZoomClient) {
              // open in an external browser
              openUrlOnExternalBrowserFromZoom(sessionUrl);
            } else {
              window.location.href = sessionUrl;
            }
          }
          return;
        }
        amplitudeEvent('forwardToStripe', {
          action: 'upgrade',
          eventTime: new Date(Date.now()).toISOString()
        });
        const sessionUrl = result['data']['sessionUrl'];
        onVerifyEmailClosed();
        if (fromZoomClient) {
          // open in an external browser
          openUrlOnExternalBrowserFromZoom(sessionUrl);
        } else {
          window.location.href = sessionUrl;
        }
        return;
      } catch (err) {
        console.log(err);
      }
    }

    function onTab(tab: string) {
      router.push({ name: 'Dashboard', query: { tab: tab } });
    }

    function onShowBlockFreePlanCustomIdModal() {
      showAccountSettingsModal.value = false;

      showUpgradeModal.value = true;
    }

    function onBackToTop() {
      window.scrollTo(0, 0);
    }

    return {
      SubscriptionInterval,
      //
      user,
      topTitle,
      isUserUsingCustomGameRoomId,
      activeGameName,
      activeGameRoomId,
      isActiveRoomClosed,
      showInitialLoading,
      subscriptionDetails,
      freeTrialEnded,
      showV5Pricing,
      //
      showAccountSettings,
      showAccountSettingsModal,

      onAccountSettingsClosed,
      onShowBlockFreePlanCustomIdModal,
      //
      showMobileNavModal,
      showMobileNav,
      onMobileNavClosed,
      //
      showVerifyEmailModal,
      proceedCheckout,
      onVerifyEmailClosed,
      onBackToTop,
      //
      showChangeEmailModal,
      showChangeEmail,
      //
      showEducationEmailRequiredModal,
      showEducationEmailRequired,
      showPaymentSuccessfulModal,
      showUpgradeModal,

      //
      isEducation,
      //
      showEnterpriseCardModal,
      //
      defaultLobbyId,
      isFromGame,
      isFromGameNew,
      isFromGameEnd,
      isFromGameEndNew,
      digitOnlyGameRoomId,
      survey1Answer,
      currentPlan,
      currentPlanInterval,
      stripeCheckout,
      //
      isFreeTrialPlan
    };
  }
});
