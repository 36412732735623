import axios from 'axios';
import useFirebaseAuth from './firebase/useFirebaseAuth';

export default async function(apiUrl: string, data: any) {
  const { currentUser } = useFirebaseAuth();
  const user = currentUser.value;
  if (user === null) {
    throw 'user not logged in';
  }
  const jsonData = JSON.parse(JSON.stringify(data));
  const firebaseToken = await user.getIdToken();
  const response = await axios.post(apiUrl, jsonData, {
    headers: {
      Authorization: `Bearer ${firebaseToken}`
    }
  });
  return response;
}
