
import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'CustomizedGameUpdateTitleDescriptionModal',
  components: {},
  props: {
    title: {
      type: String,
      required: true
    },
    description: String
  },
  setup(props, context) {
    const show = ref(true);
    const updatedTitle = ref('');
    const updatedDescription = ref('');

    const maxCustomGameNameLength = 30;
    const maxDescriptionLength = 280;

    const close = function(title: string, description: string) {
      show.value = false;
      setTimeout(() => {
        context.emit('close', [title, description]);
      }, 50);
    };

    onMounted(() => {
      updatedTitle.value = props.title;
      updatedDescription.value = props.description ?? '';
    });

    return {
      show,
      updatedTitle,
      updatedDescription,
      maxCustomGameNameLength,
      maxDescriptionLength,
      close
    };
  }
});
