
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BounceLoading',
  props: {
    customClass: {
      type: String,
      default: ''
    }
  }
});
