import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "preview-bar-chart h-100 bg-white border py-4 px-4" }
const _hoisted_2 = { class: "heading text-center color-primary" }
const _hoisted_3 = { class: "color-grey text-center" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "text-sm w-100 d-flex mb-1" }
const _hoisted_6 = { class: "ml-auto d-inline-block" }
const _hoisted_7 = {
  key: 0,
  class: "regular"
}
const _hoisted_8 = {
  key: 1,
  class: "regular"
}
const _hoisted_9 = {
  key: 0,
  class: "progress h-3 rounded-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("h4", _hoisted_2, _toDisplayString(_ctx.result?.poll.question), 1),
      _createVNode("p", _hoisted_3, " Responded: " + _toDisplayString(_ctx.result?.respondedParticipantIDs?.length), 1),
      (_ctx.result?.response)
        ? (_openBlock(), _createBlock("div", _hoisted_4, [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList((_ctx.result?.poll as _ctx.MultipleChoiceLivePoll).options, (option, index) => {
              return (_openBlock(), _createBlock("div", {
                key: option.text,
                class: ["preview-bar-chart-item mb-4 mt-2 ", { [`color-${(index % 10) + 1}`]: true }]
              }, [
                _createVNode("strong", _hoisted_5, [
                  _createTextVNode(_toDisplayString(option.text) + " ", 1),
                  _createVNode("span", _hoisted_6, [
                    _createTextVNode(_toDisplayString(_ctx.result?.response[option.text]) + " ", 1),
                    (_ctx.result!.respondedParticipantIDs.length > 0)
                      ? (_openBlock(), _createBlock("span", _hoisted_7, " (" + _toDisplayString((_ctx.result?.response[option.text]/
              _ctx.result!.respondedParticipantIDs.length)*100) + " %) ", 1))
                      : (_openBlock(), _createBlock("span", _hoisted_8, " (0%) "))
                  ])
                ]),
                (_ctx.result?.response)
                  ? (_openBlock(), _createBlock("div", _hoisted_9, [
                      _createVNode("div", {
                        class: "progress-bar ",
                        role: "progressbar",
                        style: `width: ${(_ctx.result?.response[option.text]/
              _ctx.result!.respondedParticipantIDs.length)*100}%`,
                        "aria-valuenow": (_ctx.result?.response[option.text]/
              _ctx.result!.respondedParticipantIDs.length)*100,
                        "aria-valuemin": "0",
                        "aria-valuemax": "100"
                      }, null, 12, ["aria-valuenow"])
                    ]))
                  : _createCommentVNode("", true)
              ], 2))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}