import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, Teleport as _Teleport, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BounceLoading = _resolveComponent("BounceLoading")
  const _component_CreatePollHavingHistory = _resolveComponent("CreatePollHavingHistory")
  const _component_CreatePollWithoutHistory = _resolveComponent("CreatePollWithoutHistory")
  const _component_CreateMultipleChoiceModal = _resolveComponent("CreateMultipleChoiceModal")
  const _component_CreateRatingModal = _resolveComponent("CreateRatingModal")
  const _component_CreateWordCloudModal = _resolveComponent("CreateWordCloudModal")
  const _component_CreateShortAnswerModal = _resolveComponent("CreateShortAnswerModal")
  const _component_MultipleChoicePreviewModal = _resolveComponent("MultipleChoicePreviewModal")
  const _component_RatingPreviewModal = _resolveComponent("RatingPreviewModal")
  const _component_WordCloudPreviewModal = _resolveComponent("WordCloudPreviewModal")
  const _component_ShortAnswerPreviewModal = _resolveComponent("ShortAnswerPreviewModal")

  return (_openBlock(), _createBlock(_Fragment, null, [
    (!_ctx.canAccessPoll)
      ? (_openBlock(), _createBlock("div", _hoisted_1))
      : (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_BounceLoading, { key: 1 }))
        : (_ctx.hasPollHistory)
          ? (_openBlock(), _createBlock(_component_CreatePollHavingHistory, {
              key: 2,
              polls: _ctx.polls!,
              onShowModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onShowModal($event))),
              onPreview: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onPreview($event))),
              onLaunchModal: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('launch-modal', $event)))
            }, null, 8, ["polls"]))
          : (_openBlock(), _createBlock(_component_CreatePollWithoutHistory, {
              key: 3,
              onShowModal: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onShowModal($event)))
            })),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      (_ctx.modalTypeShown)
        ? (_openBlock(), _createBlock("div", _hoisted_2, [
            (_ctx.modalTypeShown[1] == 'create')
              ? (_openBlock(), _createBlock("div", _hoisted_3, [
                  (_ctx.modalTypeShown[0] == _ctx.LivePollType.MULTIPLE_CHOICE)
                    ? (_openBlock(), _createBlock(_component_CreateMultipleChoiceModal, {
                        key: 0,
                        onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onModalClose()))
                      }))
                    : (_ctx.modalTypeShown[0] == _ctx.LivePollType.RATING)
                      ? (_openBlock(), _createBlock(_component_CreateRatingModal, {
                          key: 1,
                          onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onModalClose()))
                        }))
                      : (_ctx.modalTypeShown[0] == _ctx.LivePollType.WORD_CLOUD)
                        ? (_openBlock(), _createBlock(_component_CreateWordCloudModal, {
                            key: 2,
                            onClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onModalClose()))
                          }))
                        : (_ctx.modalTypeShown[0] == _ctx.LivePollType.SHORT_ANSWER)
                          ? (_openBlock(), _createBlock(_component_CreateShortAnswerModal, {
                              key: 3,
                              onClose: _cache[8] || (_cache[8] = ($event: any) => (_ctx.onModalClose()))
                            }))
                          : _createCommentVNode("", true)
                ]))
              : (_ctx.modalTypeShown[1] == 'preview')
                ? (_openBlock(), _createBlock("div", _hoisted_4, [
                    (_ctx.modalTypeShown[0].type == _ctx.LivePollType.MULTIPLE_CHOICE)
                      ? (_openBlock(), _createBlock(_component_MultipleChoicePreviewModal, {
                          key: 0,
                          onClose: _cache[9] || (_cache[9] = ($event: any) => (_ctx.onModalClose())),
                          livePoll: _ctx.modalTypeShown[0] as _ctx.MultipleChoiceLivePoll
                        }, null, 8, ["livePoll"]))
                      : (_ctx.modalTypeShown[0].type == _ctx.LivePollType.RATING)
                        ? (_openBlock(), _createBlock(_component_RatingPreviewModal, {
                            key: 1,
                            onClose: _cache[10] || (_cache[10] = ($event: any) => (_ctx.onModalClose())),
                            livePoll: _ctx.modalTypeShown[0] as _ctx.RatingLivePoll
                          }, null, 8, ["livePoll"]))
                        : (_ctx.modalTypeShown[0].type == _ctx.LivePollType.WORD_CLOUD)
                          ? (_openBlock(), _createBlock(_component_WordCloudPreviewModal, {
                              key: 2,
                              onClose: _cache[11] || (_cache[11] = ($event: any) => (_ctx.onModalClose())),
                              livePoll: _ctx.modalTypeShown[0] as _ctx.WordCloudLivePoll
                            }, null, 8, ["livePoll"]))
                          : (_ctx.modalTypeShown[0].type == _ctx.LivePollType.SHORT_ANSWER)
                            ? (_openBlock(), _createBlock(_component_ShortAnswerPreviewModal, {
                                key: 3,
                                onClose: _cache[12] || (_cache[12] = ($event: any) => (_ctx.onModalClose())),
                                livePoll: _ctx.modalTypeShown[0]
                              }, null, 8, ["livePoll"]))
                            : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]))
  ], 64))
}