
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'GeneralWarningModal',
  props: {
    title: {
      type: String,
      default: ''
    },

    warning: {
      type: String,
      default: ''
    }
  },
  emits: ['onConfirm', 'onCancel'],
  components: {},
  setup(_, context) {
    const isLoading = ref(false);
    function onConfirm() {
      context.emit('onConfirm', {
        onRequest: () => {
          isLoading.value = true;
        },
        onSuccess: () => {
          isLoading.value = false;
        },
        onError: () => {
          isLoading.value = false;
        }
      });
    }
    function onCancel() {
      context.emit('onCancel');
    }
    return { isLoading, onConfirm, onCancel };
  }
});
