
import zoomSdk from '@zoom/appssdk';
import { RoutePath } from '@/models/RoutePath';
import { defineComponent, ref } from 'vue';
import DashboardEntry from '../pages/DashboardEntry.vue';
import PricingEntry from '../pages/PricingEntry.vue';
import FeedbackEntry from '../pages/FeedbackEntry.vue';
import Whiteboard from '../pages/Whiteboard.vue';
import CreateCustomGame from '../pages/CreateCustomGame.vue';
import EditCustomGame from '../pages/EditCustomGame.vue';
import CancelSubscriptionModal from '@/components/Pricing/Modal/CancelSubscriptionModal.vue';
import RenewSubscriptionModal from '@/components/Pricing/Modal/RenewSubscriptionModal.vue';
import BounceLoading from '@/components/BounceLoading.vue';
import { store } from '@/store';
import { UPDATE_ZOOM_STATUS } from '@/store/mutation-types';
import ZoomClientConfig from '@/utils/helper';
import router from '@/router';
import firebase from '@/firebaseApp';
export default defineComponent({
  name: 'RootPage',
  props: {
    routePath: {
      type: String,
      required: true
    }
  },
  components: {
    DashboardEntry,
    PricingEntry,
    Whiteboard,
    CreateCustomGame,
    EditCustomGame,
    FeedbackEntry,
    CancelSubscriptionModal,
    RenewSubscriptionModal,
    BounceLoading
  },
  setup() {
    console.log('root');
    const showCancelSubscriptionModal = ref(false);
    const showRenewSubscriptionModal = ref(false);
    const showInitialLoading = ref(true);
    const zoomUid = ref();
    const zoomMid = ref();
    const inZoomClient = ref(false);
    const zoomClientConfig = ref();

    async function configureZoom() {
      try {
        await zoomSdk.config({
          popoutSize: { width: 480, height: 360 },
          capabilities: [
            'shareApp',
            'getUserContext',
            'authorize',
            'onAuthorized',
            'sendAppInvitationToAllParticipants',
            'sendAppInvitation',
            'onConnect',
            'connect',
            'openUrl',
            'onParticipantChange',
            'postMessage',
            'getMeetingJoinUrl',
            'getMeetingParticipants'
          ]
        });

        inZoomClient.value = true;
        let routerZoomUid: string | null = router.currentRoute.value.query
          .zoomuid as string;
        let routerZoomMid: string | null = router.currentRoute.value.query
          .mid as string;
        if (routerZoomUid === '') routerZoomUid = null;
        if (routerZoomMid === '') routerZoomMid = null;
        if (routerZoomUid) {
          zoomUid.value = routerZoomUid;
          localStorage.setItem('zoomUid', zoomUid.value);
        } else {
          zoomUid.value = localStorage.getItem('zoomUid');
        }
        if (routerZoomMid) {
          zoomMid.value = routerZoomMid;
          localStorage.setItem('zoomMid', zoomMid.value);
        } else {
          zoomMid.value = localStorage.getItem('zoomMid');
        }
        let role: string;
        let inMeeting: Boolean;
        let hostName: string | null = null;
        let meetingJoinUrl: string | null;
        try {
          const userContext = await zoomSdk.getUserContext();

          try {
            const meetingUrlResponse = await zoomSdk.getMeetingJoinUrl();
            meetingJoinUrl = meetingUrlResponse.joinUrl;
          } catch (err) {
            console.log('getMeetingJoinUrl not supported');
            meetingJoinUrl = null;
          }
          console.log('in meeting: connecting...');
          await zoomSdk.connect();
          console.log('in meeting: connected');
          role = userContext.role;
          inMeeting = true;
          // const meetingParticipants = await zoomSdk.getMeetingParticipants();
          // const hostIndex = meetingParticipants.participants.findIndex(
          //   participant => participant.role == 'host'
          // );
          // if (hostIndex != -1) {
          //   const host = meetingParticipants.participants[hostIndex];
          //   hostName = host.screenName;
          // }
        } catch (err) {
          console.log(err);
          role = 'host';
          inMeeting = false;
          meetingJoinUrl = null;
        }
        const isZoomHost = role === 'host';
        zoomClientConfig.value = new ZoomClientConfig(
          true,
          isZoomHost,
          zoomUid.value,
          inMeeting ? zoomMid.value : null,
          meetingJoinUrl,
          hostName
        );
        store.commit(UPDATE_ZOOM_STATUS, {
          zoomClientStatus: zoomClientConfig.value
        });

        if (!isZoomHost && zoomMid.value) {
          await firebase.auth().signInAnonymously();
        }
      } catch (err) {
        inZoomClient.value = false;
        zoomClientConfig.value = new ZoomClientConfig(
          false,
          false,
          undefined,
          undefined,
          undefined,
          undefined
        );

        store.commit(UPDATE_ZOOM_STATUS, {
          zoomClientStatus: zoomClientConfig.value
        });
      }
      showInitialLoading.value = false;
    }
    configureZoom();
    return {
      RoutePath,
      showCancelSubscriptionModal,
      showRenewSubscriptionModal,
      showInitialLoading
    };
  }
});
