import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WhiteboardEntryHost = _resolveComponent("WhiteboardEntryHost")
  const _component_WhiteboardEntryParticipant = _resolveComponent("WhiteboardEntryParticipant")

  return (
      ((_ctx.whiteboardData.currentHost === undefined &&
        _ctx.whiteboardData.currentHostName === undefined) ||
        _ctx.whiteboardData.currentHost === _ctx.currentUser.uid) &&
        _ctx.whiteboardData.id
    )
    ? (_openBlock(), _createBlock(_component_WhiteboardEntryHost, {
        key: 0,
        whiteboardId: _ctx.whiteboardData.id,
        clubhouseTitle: _ctx.whiteboardData.clubhouseTitle,
        clubhouseEventDate: _ctx.whiteboardData.clubhouseEventDate,
        isAlreadyHost: _ctx.whiteboardData.currentHost === _ctx.currentUser.uid
      }, null, 8, ["whiteboardId", "clubhouseTitle", "clubhouseEventDate", "isAlreadyHost"]))
    : (_ctx.whiteboardData.id)
      ? (_openBlock(), _createBlock(_component_WhiteboardEntryParticipant, {
          key: 1,
          whiteboardId: _ctx.whiteboardData.id,
          clubhouseTitle: _ctx.whiteboardData.clubhouseTitle,
          clubhouseEventDate: _ctx.whiteboardData.clubhouseEventDate,
          hasJoined: _ctx.whiteboardData.participants.indexOf(_ctx.currentUser.uid) !== -1,
          totalParticipantsNumber: _ctx.whiteboardData.participants.length
        }, null, 8, ["whiteboardId", "clubhouseTitle", "clubhouseEventDate", "hasJoined", "totalParticipantsNumber"]))
      : _createCommentVNode("", true)
}