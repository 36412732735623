
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AssignTeamRoleModal',
  emits: ['close'],
  props: {
    role: {
      type: String,
      required: true
    }
  },
  setup(_, context) {
    const close = function(value: String | undefined) {
      context.emit('close', value);
    };

    return {
      close
    };
  }
});
