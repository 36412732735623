import { createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "modal-content pin-header" }
const _hoisted_2 = { class: "modal-header" }
const _hoisted_3 = { class: "modal-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BounceLoading = _resolveComponent("BounceLoading")
  const _component_QANameList = _resolveComponent("QANameList")
  const _component_QAParticipantScreen = _resolveComponent("QAParticipantScreen")

  return (_openBlock(), _createBlock("div", {
    class: "modal-right no-overlay-layer for-long-modal",
    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.close()))
  }, [
    _createVNode(_Transition, {
      appear: "",
      name: "slide"
    }, {
      default: _withCtx(() => [
        _createVNode("div", {
          class: "modal-dialog",
          onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
        }, [
          _createVNode("div", _hoisted_1, [
            _createVNode("div", _hoisted_2, [
              _createVNode("button", {
                type: "button",
                class: "btn-close-left",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.close()))
              }, [
                _createVNode("img", {
                  src: require('@/assets/img/close.png'),
                  alt: "close modal"
                }, null, 8, ["src"])
              ])
            ]),
            _createVNode("div", _hoisted_3, [
              (_ctx.isLoading)
                ? (_openBlock(), _createBlock(_component_BounceLoading, { key: 0 }))
                : (_ctx.isHost)
                  ? (_openBlock(), _createBlock(_component_QANameList, {
                      key: 1,
                      participantsData: _ctx.participantsData,
                      whiteboardData: _ctx.whiteboardData,
                      isModalOpen: _ctx.isModalOpen
                    }, null, 8, ["participantsData", "whiteboardData", "isModalOpen"]))
                  : (_openBlock(), _createBlock(_component_QAParticipantScreen, {
                      key: 2,
                      participantsData: _ctx.participantsData,
                      whiteboardData: _ctx.whiteboardData,
                      isModalOpen: _ctx.isModalOpen
                    }, null, 8, ["participantsData", "whiteboardData", "isModalOpen"]))
            ])
          ])
        ])
      ]),
      _: 1
    })
  ]))
}