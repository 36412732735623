export enum Tools {
  Pen_Black = 'pen_black',
  Pen_Blue = 'pen_blue',
  Pen_Red = 'pen_red',
  Pen_Yellow = 'pen_yellow',
  Text = 'text',
  Select = 'select',
  Undo = 'undo',
  Redo = 'redo',
  Clear = 'clear',
  Delete = 'delete'
}

export const PenTools = [
  Tools.Pen_Black,
  Tools.Pen_Blue,
  Tools.Pen_Red,
  Tools.Pen_Yellow
];

export const ActionTools = [Tools.Undo, Tools.Redo, Tools.Clear, Tools.Delete];

export const PenColors = ['#000000', '#168FFF', '#F51365', '#F9E42D'];
