
import { computed, defineComponent, ref, watch } from 'vue';
import QAMessageList from '@/components/Modal/WhiteboardComponent/QA/QAMessageList.vue';
import useFirebaseAuth from '@/hooks/firebase/useFirebaseAuth';
import useFirebaseDocument from '@/hooks/firebase/useFirebaseDocument';

export default defineComponent({
  name: 'QAParticipantScreen',
  components: {
    QAMessageList
  },

  props: {
    whiteboardData: {
      type: Object,
      required: true
    },
    participantsData: {
      type: Array,
      required: true
    },
    isModalOpen: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const { currentUser } = useFirebaseAuth();
    const isLoading = computed(() => {
      return (
        !props.whiteboardData ||
        props.whiteboardData === {} ||
        !props.participantsData ||
        props.participantsData.length == 0 ||
        !currentChannel.value
      );
    });
    const channelDoc = function(channelId: string) {
      return useFirebaseDocument(`whiteboard_channel`, {
        documentId: channelId,
        onMounted: true,
        onSnapshot: true
      });
    };
    watch(
      channelDoc(`CH${props.whiteboardData?.id}${currentUser?.value?.uid}`)
        .documentData,
      value => {
        if (value) {
          currentChannel.value = value;
        }
      }
    );

    const isHost = computed(() => {
      return props.whiteboardData?.currentHost == currentUser?.value?.uid;
    });
    const currentChannel = ref();
    return { isLoading, isHost, currentChannel };
  }
});
