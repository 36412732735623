
import { computed, defineComponent, ref, watch } from 'vue';
import { useStore } from '@/store';
import firebaseApp from '@/firebaseApp';
import { uuid } from 'vue-uuid';
import EditTeamNameModal from '@/components/Team/Modal/EditTeamNameModal.vue';
import AssignTeamRoleModal from '@/components/Team/Modal/AssignTeamRoleModal.vue';
import InviteTeamModal from '@/components/Team/Modal/InviteTeamModal.vue';
import ConfirmationModal from '@/components/Modal/ConfirmationModal.vue';
import OkModal from '@/components/Modal/OkModal.vue';
import TeamMemberTile from '@/components/Team/TeamMemberTile.vue';
import TeamMemberInviteBox from '@/components/Team/TeamMemberInviteBox.vue';
import { isGameRoomOpen, getEndpoint } from '@/utils/helper';
import { eligibleForTeam } from '@/utils/pricing_rules';
import router from '@/router';
import { TeamRole } from '@/models/TeamRole';
import usePostAPI from '@/hooks/usePostAPI';
import { amplitudeEvent } from '@/config/amplitudeConfig';
import useFirebaseCollection from '@/hooks/firebase/useFirebaseCollection';
import { TeamInfo } from '@/models/TeamInfo';
import { UPDATE_TEAM_INFO } from '@/store/mutation-types';
import { NavigationTabs } from '@/models/navigationTabs';
export default defineComponent({
  name: 'TeamMain',
  emits: ['show-cancel-subscription', 'show-renew-subscription'],
  components: {
    EditTeamNameModal,
    AssignTeamRoleModal,
    ConfirmationModal,
    InviteTeamModal,
    OkModal,
    TeamMemberTile,
    TeamMemberInviteBox
  },
  setup() {
    const store = useStore();
    const user = computed(() => store.getters.getUser);
    const lobbyDoc = computed(() => store.getters.getLobbyDoc);
    const isUserUsingCustomGameRoomId = computed(
      () => store.getters.isUserUsingCustomGameRoomId
    );
    const activeGameRoomId = computed(() => store.getters.activeGameRoomId);
    const activeGameName = computed(() => store.getters.activeGameName);
    const isActiveRoomClosed = computed(
      () => !isGameRoomOpen(lobbyDoc.value ?? {})
    );
    const subscriptionDetails = computed(
      () => store.getters.getSubscriptionDetails
    );
    //
    const showEditTeamNameModal = ref(false);
    const showAssignTeamRoleModal = ref(false);
    const showConfirmResetLinkModal = ref(false);
    const showConfirmDowngradeModal = ref(false);
    const showConfirmLeaveTeamModal = ref(false);
    const showOwnerUnableToLeaveTeamModal = ref(false);
    const showConfirmRemoveFromTeamModal = ref(false);
    const showInviteTeamModal = ref(false);
    const selectedMemberIndex = ref(-1);
    //
    const teamInfo = computed(() => store.getters.getTeamInfo);

    const shownDialog = computed(() => {
      return (
        showEditTeamNameModal.value ||
        showAssignTeamRoleModal.value ||
        showConfirmResetLinkModal.value ||
        showConfirmDowngradeModal.value ||
        showConfirmLeaveTeamModal.value ||
        showOwnerUnableToLeaveTeamModal.value ||
        showConfirmRemoveFromTeamModal.value ||
        showInviteTeamModal.value
      );
    });

    watch(shownDialog, newValue => updateBodyCssForDialog(newValue));

    function updateBodyCssForDialog(shown: boolean) {
      if (shown) {
        document.body.className += ' modal-open';
        const needHelpBox = document.getElementById('freshworks-container');
        if (needHelpBox !== null) needHelpBox.hidden = true;
      } else {
        document.body.className = document.body.className.replaceAll(
          ' modal-open',
          ''
        );
        const needHelpBox = document.getElementById('freshworks-container');
        if (needHelpBox !== null) needHelpBox.hidden = false;
      }
    }

    updateBodyCssForDialog(false);

    async function configureTeamInfo() {
      amplitudeEvent('visitTeamPage', {
        eventTime: new Date(Date.now()).toISOString()
      });
      if (eligibleForTeam(subscriptionDetails.value)) {
        const teamQuery = useFirebaseCollection('minigame_teams', {});
        await teamQuery.getCollection({
          fieldPath: 'owner',
          opStr: '==',
          value: user.value.uid,
          limit: 1
        });
        const teams = teamQuery.collectionData.value ?? [];
        if (teams.length == 0) {
          const newTeamInfo = new TeamInfo({
            id: firebaseApp
              .firestore()
              .collection('minigame_teams')
              .doc().id,
            name: (user.value.displayName ?? '') + "'s Team",
            owner: user.value.uid,
            members: [
              {
                uid: user.value.uid,
                name: user.value.displayName ?? '',
                email: user.value.email,
                role: TeamRole.Owner
              }
            ],
            link: {
              url: uuid.v4(),
              enabled: true
            }
          });
          await firebaseApp
            .firestore()
            .collection('minigame_teams')
            .doc(newTeamInfo.id)
            .set(Object.assign({}, newTeamInfo));
          store.commit(UPDATE_TEAM_INFO, {
            newTeamInfo: newTeamInfo
          });
        } else {
          const teamInfo = new TeamInfo(teams[0]);
          store.commit(UPDATE_TEAM_INFO, {
            newTeamInfo: teamInfo
          });
        }
      }
    }

    configureTeamInfo();

    async function saveTeamInfo() {
      if (teamInfo.value) {
        await firebaseApp
          .firestore()
          .collection('minigame_teams')
          .doc(teamInfo.value.id)
          .set(Object.assign({}, teamInfo.value));
      }
    }

    function openAssignTeamRoleModal(index: number) {
      selectedMemberIndex.value = index;
      showAssignTeamRoleModal.value = true;
    }

    async function closeAssignTeamRoleModal(role: string | undefined) {
      showAssignTeamRoleModal.value = false;
      if (role) {
        const isMe =
          teamInfo.value!.members[selectedMemberIndex.value].uid ===
            user.value.uid && role === TeamRole.Host;
        if (isMe) {
          openConfirmDowngradeModal();
        } else {
          teamInfo.value!.members[selectedMemberIndex.value].role = role;
          await saveTeamInfo();
        }
      }
    }

    function openConfirmDowngradeModal() {
      showConfirmDowngradeModal.value = true;
    }

    async function closeConfirmDowngradeModal(value: boolean) {
      showConfirmDowngradeModal.value = false;
      if (value) {
        teamInfo.value!.members[selectedMemberIndex.value].role = TeamRole.Host;
        await saveTeamInfo();
        onTab(NavigationTabs.HOME);
      }
    }

    async function enableLink(value: boolean) {
      teamInfo.value!.link.enabled = value;
      await saveTeamInfo();
    }

    function openConfirmResetLinkModal() {
      showConfirmResetLinkModal.value = true;
    }

    async function closeConfirmResetLinkModal(value: boolean) {
      showConfirmResetLinkModal.value = false;
      if (value) {
        teamInfo.value!.link.url = uuid.v4();
        await saveTeamInfo();
      }
    }

    function openEditTeamNameModal() {
      showEditTeamNameModal.value = true;
    }

    async function closeEditTeamNameModal(value: string | undefined) {
      showEditTeamNameModal.value = false;
      if (value) {
        teamInfo.value!.name = value;
        await saveTeamInfo();
      }
    }

    async function removeUserFromTeam(index: number) {
      selectedMemberIndex.value = index;
      const isMe =
        teamInfo.value!.members[selectedMemberIndex.value].uid ===
        user.value.uid;

      if (isMe) {
        if (teamInfo.value?.owner === user.value.uid) {
          openOwnerUnableToLeaveTeamModal();
        } else {
          openConfirmLeaveTeamModal();
        }
      } else {
        openConfirmRemoveFromTeamModal();
      }
    }

    function openConfirmLeaveTeamModal() {
      showConfirmLeaveTeamModal.value = true;
    }

    async function closeConfirmLeaveTeamModal(value: boolean) {
      showConfirmLeaveTeamModal.value = false;
      if (value) {
        await usePostAPI(getEndpoint('leaveTeam'), {});
        onTab('home');
      }
    }

    function openOwnerUnableToLeaveTeamModal() {
      showOwnerUnableToLeaveTeamModal.value = true;
    }

    async function closeOwnerUnableToLeaveTeamModal() {
      showOwnerUnableToLeaveTeamModal.value = false;
    }

    function openConfirmRemoveFromTeamModal() {
      showConfirmRemoveFromTeamModal.value = true;
    }

    async function closeConfirmRemoveFromTeamModal(value: boolean) {
      showConfirmRemoveFromTeamModal.value = false;
      if (value) {
        teamInfo.value!.members.splice(selectedMemberIndex.value, 1);
        await saveTeamInfo();
      }
    }

    function openInviteTeamModal() {
      showInviteTeamModal.value = true;
    }

    function closeInviteTeamModal() {
      showInviteTeamModal.value = false;
    }

    function onTab(tab: string) {
      router.push({ name: 'Dashboard', query: { tab: tab } });
    }

    return {
      //
      user,
      isUserUsingCustomGameRoomId,
      activeGameName,
      activeGameRoomId,
      isActiveRoomClosed,
      subscriptionDetails,
      //
      showEditTeamNameModal,
      openEditTeamNameModal,
      closeEditTeamNameModal,
      //
      showAssignTeamRoleModal,
      openAssignTeamRoleModal,
      closeAssignTeamRoleModal,
      selectedMemberIndex,
      //
      enableLink,
      showConfirmResetLinkModal,
      openConfirmResetLinkModal,
      closeConfirmResetLinkModal,
      //
      showConfirmDowngradeModal,
      openConfirmDowngradeModal,
      closeConfirmDowngradeModal,
      //
      removeUserFromTeam,
      showConfirmLeaveTeamModal,
      openConfirmLeaveTeamModal,
      closeConfirmLeaveTeamModal,
      //
      showOwnerUnableToLeaveTeamModal,
      openOwnerUnableToLeaveTeamModal,
      closeOwnerUnableToLeaveTeamModal,
      //
      showConfirmRemoveFromTeamModal,
      openConfirmRemoveFromTeamModal,
      closeConfirmRemoveFromTeamModal,
      //
      showInviteTeamModal,
      openInviteTeamModal,
      closeInviteTeamModal,
      //
      teamInfo
    };
  }
});
