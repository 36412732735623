import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BounceLoading = _resolveComponent("BounceLoading")
  const _component_Pricing = _resolveComponent("Pricing")

  return (_ctx.showInitialLoading)
    ? (_openBlock(), _createBlock(_component_BounceLoading, { key: 0 }))
    : (_openBlock(), _createBlock(_component_Pricing, {
        key: 1,
        onShowCancelSubscription: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('show-cancel-subscription'))),
        onShowRenewSubscription: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('show-renew-subscription')))
      }))
}