
import { getGameTitleByType } from '@/utils/gameConstants';
import { computed, defineComponent, ref } from 'vue';
import { useStore } from '@/store';
import { roundToOneDecimal } from '@/utils/helper';

export default defineComponent({
  name: 'HistoryModalCardGameComponent',
  components: {},
  props: {
    game: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const user = computed(() => store.getters.getUser);
    const title = getGameTitleByType(props.game.gameName);
    const customGameName = ref(props.game.customGameName);
    const customGameCss = ref(props.game.customGameCss);
    const dateString = ref(
      props.game.dateCreated.format('DD MMMM, YYYY - hh:mm A')
    );
    const hostName = ref(undefined as string | undefined);
    const isHostNotPlaying = ref(props.game.isHostPlaying === false);
    const winnerString = ref('');
    const players = ref([] as any[]);
    const roundScores = ref([] as any[]);
    const totalScores = ref([] as number[]);

    function calculateResult() {
      if (props.game.host !== user.value.uid) {
        hostName.value =
          props.game.players[props.game.host]?.name ?? props.game.hostName;
      }

      const actualPlayers = Object.assign({}, props.game.players);
      if (isHostNotPlaying.value) {
        delete actualPlayers[props.game.host];
      }
      const winners = [] as string[];
      const result = [] as any[];
      let max = 0;
      Object.keys(actualPlayers ?? {}).map(playerId => {
        const player = actualPlayers[playerId];
        result.push({
          name: player.name,
          email: player.email,
          score: 0
        });
        props.game.roundDetails.map(roundDetail => {
          if (roundDetail.players && roundDetail.players[playerId]) {
            result[result.length - 1].score +=
              roundDetail.players[playerId].score ?? 0;
          }
        });
        if (result[result.length - 1].score > max) {
          max = result[result.length - 1].score;
        }
      });
      props.game.roundDetails.map(roundDetail => {
        const playerIds = Object.keys(actualPlayers ?? {});
        const scores = [] as number[];
        for (let i = 0; i < playerIds.length; i++) {
          if (roundDetail.players && roundDetail.players[playerIds[i]]) {
            scores.push(roundDetail.players[playerIds[i]].score ?? 0);
          } else {
            scores.push(0);
          }
        }
        roundScores.value.push(scores);
      });
      result.map(player => {
        players.value.push({
          name: player.name,
          email: player.email
        });
        totalScores.value.push(roundToOneDecimal(player.score));
        if (player.score === max) {
          winners.push(player.name);
        }
      });
      if (winners.length === 1) {
        winnerString.value = `${winners[0]} is the final winner!`;
      } else if (winners.length === 2) {
        winnerString.value = `${winners[0]} and ${winners[1]} are the final winners!`;
      } else {
        winnerString.value =
          winners.slice(0, winners.length - 1).join(', ') +
          ' and ' +
          winners[winners.length - 1] +
          ' are the final winners!';
      }
    }

    calculateResult();

    return {
      title,
      customGameName,
      customGameCss,
      dateString,
      hostName,
      isHostNotPlaying,
      winnerString,
      players,
      roundScores,
      totalScores
    };
  }
});
