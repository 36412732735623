
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HowToPlayTop5',
  emits: ['more'],
  props: {
    outClass: {
      type: String,
      default: 'settings'
    },
    showNumber: {
      type: Boolean,
      default: true
    }
  },
  setup(_, context) {
    function onMore() {
      context.emit('more');
    }
    return {
      onMore
    };
  }
});
