import { toRefs, reactive, onMounted } from 'vue';
import firebase from '@/firebaseApp';

export default function(
  collectionName: string,
  queryOptions?: {
    onMounted?: boolean | undefined;
    documentId?: string | undefined;
    onSnapshot?: boolean | undefined;
  }
) {
  const state: {
    error: firebase.auth.Error | null;
    documentData: any | null;
    loading: boolean;
  } = reactive({
    // error if one happens
    error: null,
    // the results of the query
    documentData: {},
    // if the query is loading or ot
    loading: false
  });

  // get the database
  const db = firebase.firestore();

  /**
   * there is the option to load the query when the component
   * is mounted, you need to set the option in the `queryOptions`
   * params that you pass in
   *
   */
  if (queryOptions && queryOptions.onMounted)
    onMounted(() => {
      queryOptions &&
        queryOptions.onMounted &&
        getDocument(10, queryOptions.documentId!, queryOptions.onSnapshot);
    });

  const deleteDocument = async (_documentId: string) => {
    state.loading = true;
    state.error = null;
    try {
      await db
        .collection(collectionName)
        .doc(_documentId)
        .delete();
      state.error = null;
      state.documentData = null;
      return { id: _documentId };
    } catch (err) {
      console.error('Error removing document: ', err);
      state.error = err as any;
      state.documentData = null;
      return { err };
    } finally {
      state.loading = false;
    }
  };

  const createDocument = async (_documentData: {}, _documentId?: string) => {
    state.loading = true;
    state.error = null;
    try {
      if (_documentId) {
        await db
          .collection(collectionName)
          .doc(_documentId)
          .set({
            ..._documentData,
            dateCreated: firebase.firestore.FieldValue.serverTimestamp()
          });
        state.error = null;
        state.documentData!.id = _documentId;
        const _doc = await db
          .collection(collectionName)
          .doc(_documentId)
          .get();
        return { id: _doc.id, ..._doc.data() };
      } else {
        const docRef = await db.collection(collectionName).add({
          ..._documentData,
          dateCreated: firebase.firestore.FieldValue.serverTimestamp()
        });
        state.error = null;
        state.documentData!.id = docRef.id;
        const _doc = await docRef.get();
        return { id: _doc.id, ..._doc.data() };
      }
    } catch (err) {
      // The document probably doesn't exist.
      console.error('Error createDocument: ', err);
      state.error = err as any;
      state.documentData = null;
      return { err };
    } finally {
      state.loading = false;
    }
  };

  const updateDocument = async (_documentData: any) => {
    state.loading = true;
    state.error = null;

    const data = { ..._documentData };
    delete data[_documentData.id];

    const docRef = db.collection(collectionName).doc(_documentData.id);

    try {
      await docRef.update({
        ...data
      });
      state.error = null;
      state.documentData = null;
      const _doc = await docRef.get();
      return { id: _doc.id, ..._doc.data() };
    } catch (err) {
      // The document probably doesn't exist.
      console.error('Error updating document: ', err);
      state.error = err as any;
      state.documentData = null;
      return { err };
    } finally {
      state.loading = false;
    }
  };

  const getDocument = async (
    retries: number,
    documentId: string,
    onSnapshot?: boolean
  ) => {
    state.loading = true;
    state.error = null;

    try {
      if (onSnapshot) {
        await db
          .collection(collectionName)
          .doc(documentId)
          .onSnapshot(snapshot => {
            state.documentData = { id: snapshot.id, ...snapshot.data() };
            state.error = null;
          });
      } else {
        const doc = await db
          .collection(collectionName)
          .doc(documentId)
          .get();
        if (doc.exists) {
          state.documentData = { id: doc.id, ...doc.data() };
          state.error = null;
        } else {
          // doc.data() will be undefined in this case
          state.documentData = null;
          state.error = null;
        }
      }
    } catch (err) {
      console.error(`Error getDocument (retries left: ${retries}) : `, err);
      if (retries <= 0) {
        state.error = err as any;
        return;
      } else {
        return getDocument(retries - 1, documentId, onSnapshot);
      }
    } finally {
      state.loading = false;
    }
  };

  return {
    ...toRefs(state),
    getDocument: getDocument,
    createDocument,
    updateDocument,
    deleteDocument
  };
}
