import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LivePoll = _resolveComponent("LivePoll")
  const _component_SpinTheWheel = _resolveComponent("SpinTheWheel")

  return (_ctx.type === _ctx.EventToolsType.LIVE_POLL)
    ? (_openBlock(), _createBlock(_component_LivePoll, {
        key: 0,
        onLaunchModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onLaunchModal($event)))
      }))
    : (_ctx.type === _ctx.EventToolsType.SPIN_THE_WHEEL)
      ? (_openBlock(), _createBlock(_component_SpinTheWheel, {
          key: 1,
          onLaunchModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onLaunchModal($event)))
        }))
      : _createCommentVNode("", true)
}