export class RecommendedGameInfo {
  public index: number = 0;
  public condition: string = '>';
  public count: number = 1;
  public gameName?: string;
  public isCustomGame: boolean = false;
  public customGameName?: string;
  public customGameId?: string;
  public customGameIcon?: string;
  public title: string = '';
  public description: string = '';

  public constructor(fields?: {
    index: number;
    condition: string;
    count: number;
    gameName?: string;
    isCustomGame: boolean;
    customGameName?: string;
    customGameId?: string;
    customGameIcon?: string;
    title: string;
    description: string;
  }) {
    if (fields) Object.assign(this, fields);
  }
}
