
import { amplitudeEvent } from '@/config/amplitudeConfig';
import { ShortAnswerLivePoll } from '@/models/event_tools/live_poll/live_poll_model';
import { store } from '@/store';
import { LivePollType } from '@/utils/live_poll_enums';
import firebase from 'firebase';
import { computed, defineComponent, Ref, ref } from 'vue';
export default defineComponent({
  name: 'CreateShortAnswerModal',

  props: {},
  emits: ['close'],
  setup(_, context) {
    const userDoc = computed(() => store.getters.getUserDoc);

    const isButtonLoading: Ref<boolean> = ref(false);
    const pollData: Ref<ShortAnswerLivePoll> = ref({
      dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
      launchedCount: 0,
      question: '',
      type: LivePollType.SHORT_ANSWER
    });
    function onClose() {
      context.emit('close');
    }

    const validForm = computed(() => {
      return pollData.value.question?.trim() !== '';
    });

    async function onSave() {
      if (!validForm.value) {
        console.log('Form incomplete');
        console.log(pollData.value);
        return;
      }

      isButtonLoading.value = true;
      await firebase
        .firestore()
        .collection(
          `minigame_lobbies/${userDoc.value['defaultLobbyId']}/live_poll`
        )
        .add(pollData.value);
      console.log('new poll added');
      amplitudeEvent('savePoll', {
        eventTime: new Date(Date.now()).toISOString(),
        pollType: pollData.value.type
      });
      isButtonLoading.value = false;
      onClose();
    }
    return {
      onClose,
      onSave,
      validForm,
      pollData,
      isButtonLoading
    };
  }
});
