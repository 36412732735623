import { SubscriptionLevel } from '@/models/SubscriptionLevel';

export function identifyFreshdeskEmail(email: string) {
  try {
    if (window.FreshworksWidget)
      window.FreshworksWidget('identify', 'ticketForm', {
        email: email
      });
  } catch (err) {
    console.error(err);
  }
}

export function prefillFreshdeskEmail(subscriptionLevel: string) {
  try {
    let priority = 1;
    switch (subscriptionLevel) {
      case SubscriptionLevel.PROFESSIONAL:
      case SubscriptionLevel.PROFESSIONAL_EDUCATOR:
        priority = 3;
        break;
      case SubscriptionLevel.PREMIUM:
      case SubscriptionLevel.PREMIUM_EDUCATOR:
        priority = 2;
        break;
      case SubscriptionLevel.FREE:
        priority = 1;
        break;
    }
    if (window.FreshworksWidget)
      window.FreshworksWidget('prefill', 'ticketForm', {
        priority: priority
      });
  } catch (err) {
    console.error(err);
  }
}

export function hideFreshdesk() {
  try {
    if (window.FreshworksWidget) window.FreshworksWidget('hide');
  } catch (err) {
    console.error(err);
  }
}
