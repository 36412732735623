
import {
  firestoreGameTypes,
  getGameTitleByType,
  groupWinnerGameTypes
} from '@/utils/gameConstants';
import { defineComponent, ref } from 'vue';
import router from '@/router';
import useFirebaseCollection from '@/hooks/firebase/useFirebaseCollection';
import { getGameWinners, roundToOneDecimal } from '@/utils/helper';

import { NavigationTabs } from '@/models/navigationTabs';
export default defineComponent({
  name: 'LeaderboardTile',
  props: {
    gameName: {
      type: String,
      required: true
    },
    games: {
      type: Array,
      required: true
    }
  },
  emits: ['scroll'],
  setup(props, context) {
    const isLoading = ref(false);
    const title = getGameTitleByType(props.gameName);
    const rank = [] as any[];
    const rankings = ref([] as any[]);

    async function getGamePlayers(game: any) {
      if (firestoreGameTypes.includes(props.gameName)) {
        const playersQuery = useFirebaseCollection(
          `minigame_gameplay_history/${game.gameRoomId}/game_plays/${game.gamePlayId}/players`,
          {
            onMounted: false
          }
        );
        await playersQuery.getCollection({});
        game['players'] = playersQuery.collectionData.value!;
      }

      const result = getGameWinners(game);
      result.map(winner => {
        let index = rank.findIndex(ranking =>
          winner.email
            ? ranking.email === winner.email
            : ranking.name === winner.name
        );
        if (index === -1) {
          rank.push({
            name: winner.name,
            email: winner.email,
            wins: 0,
            score: 0
          });
          index = rank.length - 1;
        }
        rank[index].wins += winner.win;
        if (winner.score) {
          rank[index].score += winner.score;
        }
      });
      rank.map(player => {
        player.score = roundToOneDecimal(player.score);
      });
    }

    async function calculateResult() {
      isLoading.value = true;

      for (let i = 0; i < props.games.length; i++) {
        await getGamePlayers(props.games[i]);
      }
      rank.sort((a, b) => {
        if (groupWinnerGameTypes.includes(props.gameName)) {
          return b.wins - a.wins;
        } else {
          return b.score - a.score;
        }
      });
      rankings.value = rank.slice(0, 3);

      isLoading.value = false;
    }

    calculateResult();

    function viewDetails() {
      router.push({
        query: { tab: NavigationTabs.LEADERBOARD, type: props.gameName }
      });
      context.emit('scroll', window.scrollY);
      window.scrollTo(0, 0);
    }

    return {
      groupWinnerGameTypes,
      isLoading,
      title,
      rankings,
      viewDetails
    };
  }
});
