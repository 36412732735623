
import { defineComponent, ref } from 'vue';
import SampleQuestionWouldYouRather from '@/components/Modal/AboutComponent/SampleQuestionComponents/SampleQuestionWouldYouRather.vue';
import SampleQuestionQOfTheDay from '@/components/Modal/AboutComponent/SampleQuestionComponents/SampleQuestionQOfTheDay.vue';
import SampleQuestionTrivia from '@/components/Modal/AboutComponent/SampleQuestionComponents/SampleQuestionTrivia.vue';
import SampleQuestionSpotMyLie from '@/components/Modal/AboutComponent/SampleQuestionComponents/SampleQuestionSpotMyLie.vue';
import SampleQuestionDrawTell from '@/components/Modal/AboutComponent/SampleQuestionComponents/SampleQuestionDrawTell.vue';
import SampleQuestionPoll from '@/components/Modal/AboutComponent/SampleQuestionComponents/SampleQuestionPoll.vue';

export default defineComponent({
  name: 'SampleQuestionsModal',
  components: {
    SampleQuestionWouldYouRather,
    SampleQuestionQOfTheDay,
    SampleQuestionTrivia,
    SampleQuestionSpotMyLie,
    SampleQuestionDrawTell,
    SampleQuestionPoll
  },
  props: {
    type: {
      type: String,
      required: true
    }
  },
  emits: ['close'],
  setup(_, context) {
    const show = ref(true);

    const close = function() {
      show.value = false;
      setTimeout(() => {
        context.emit('close');
      }, 50);
    };

    return {
      show,
      close
    };
  }
});
