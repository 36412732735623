import { toRefs, reactive } from 'vue';
import firebase from '@/firebaseApp';

export default function() {
  const state: {
    user: firebase.User | null | undefined;
    loading: boolean;
    error: firebase.auth.Error | null;
    currentUser: firebase.User | null;
  } = reactive({
    user: undefined,
    loading: true,
    error: null,
    currentUser: firebase.auth().currentUser
  });

  firebase.auth().onAuthStateChanged(_user => {
    if (_user) {
      state.user = _user;
    } else {
      state.user = null;
    }
    state.loading = false;
  });

  const signInAnonymously = async () => {
    try {
      state.loading = true;
      const user = await firebase.auth().signInAnonymously();
      state.user = user.user;
      state.error = null;
      return true;
    } catch (err) {
      console.log('Error signInAnonymously: ', err);
      state.error = err as any;
      return { err };
    } finally {
      state.loading = false;
    }
  };

  return {
    ...toRefs(state),
    signInAnonymously
  };
}
