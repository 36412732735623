
import { defineComponent } from 'vue';
import BounceLoading from '@/components/BounceLoading.vue';
import PollResultBox from '@/components/Modal/WhiteboardComponent/PollComponents/PollResultBox.vue';
import useFirebaseCollection from '@/hooks/firebase/useFirebaseCollection';
export default defineComponent({
  name: 'PollHostResult',
  components: {
    BounceLoading,
    PollResultBox
  },
  props: {
    whiteboardId: {
      type: String,
      required: true
    },
    pollDoc: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const pollOptionsListener = useFirebaseCollection(
      `whiteboard_data/${props.whiteboardId}/polls/${
        props.pollDoc!['id']
      }/answers`,
      {
        onMounted: true
      },
      true
    );

    return {
      ...pollOptionsListener
    };
  }
});
