import 'reflect-metadata';
import { createApp } from 'vue';
import './registerServiceWorker';
import * as Sentry from '@sentry/browser';
import HomePage from './HomePage.vue';
import router from './router';
import { store, key } from './store';
import VuePlyr from 'vue-plyr';
import 'vue-plyr/dist/vue-plyr.css';
import animated from 'animate.css';
import Maska from 'maska';

Sentry.init({
  dsn:
    'https://5be1835f106f4123944d206e30c845e2@o402694.ingest.sentry.io/5264186',
  release: "8108393903",
  environment: "PROD",
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    'Extension context invalidated.',
    // ignore freshdesk being blocked by ad blocker
    'window.FreshworksWidget'
  ],
  beforeSend(event) {
    if (
      event.message?.includes('Extension context invalidated.') ||
      event.message == 'Extension context invalidated.'
    ) {
      return null;
    }
    return event;
  }
});

createApp(HomePage)
  .use(store, key)
  .use(router)
  .use(VuePlyr)
  .use(animated)
  .use(Maska)
  .mount('#app');
