export class TeamInfo {
  public id: string = '';
  public name: string = '';
  public owner: string = '';
  public members: {
    uid: string;
    name: string;
    email: string;
    role: string;
  }[] = [];
  public link: {
    url?: string;
    enabled?: boolean;
  } = {};

  public constructor(fields?: {
    id?: string;
    name?: string;
    owner?: string;
    members?: {
      uid: string;
      name: string;
      email: string;
      role: string;
    }[];
    link?: {};
  }) {
    if (fields) Object.assign(this, fields);
  }
}
