export class SingleQuestion {
  public question: string = '';
  public topic: string = '';
  public index: number = -1;
  public key: number = -1;

  public constructor(fields?: {
    question?: string;
    topic?: string;
    index?: number;
    key?: number;
  }) {
    if (fields) Object.assign(this, fields);
  }
}
