
import {
  LivePoll,
  LivePollResult
} from '@/models/event_tools/live_poll/live_poll_model';
import BounceLoading from '@/components/BounceLoading.vue';
import { store } from '@/store';
import firebase from 'firebase';
import { computed, defineComponent, Ref, ref } from 'vue';
import {
  eventToolsHistoryDateFormat,
  eventToolsParticipantsWithoutHost
} from '@/utils/helper';
import LivePollHistoryModal from './LivePollHistoryModal.vue';
export default defineComponent({
  name: 'LivePollHistoryListModal',
  components: { BounceLoading, LivePollHistoryModal },
  props: {
    poll: Object as () => LivePoll
  },
  emits: ['close'],
  setup(props, context) {
    const state: Ref<'loading' | 'list' | ['result', LivePollResult]> = ref(
      'loading'
    );
    const userDoc = computed(() => store.getters.getUserDoc);
    const pollResults: Ref<Array<LivePollResult> | null> = ref(null);
    async function fetchResults() {
      try {
        const snapshots = await firebase
          .firestore()
          .collection(
            `minigame_lobbies/${userDoc.value['defaultLobbyId']}/live_poll/${props.poll?.id}/results`
          )
          .orderBy('dateCreated', 'desc')
          .get();
        if (!snapshots.empty && snapshots.docs.length != 0) {
          let results: Array<LivePollResult> = [];
          snapshots.docs.forEach(doc => {
            let result = doc.data() as LivePollResult;
            result.id = doc.id;
            results.push(result);
          });
          pollResults.value = results;
        }
      } catch (err) {
        console.log(err);
      }
      state.value = 'list';
    }
    fetchResults();
    function onClose() {
      context.emit('close');
    }
    function onView(result: LivePollResult) {
      // result.participants = [
      //   { id: '1', email: '111', name: '111' },
      //   { id: '2', email: '111', name: '111' },
      //   { id: '3', email: '111', name: '111' },
      //   { id: '4', email: '111', name: '111' },
      //   { id: '5', email: '111', name: '111' },
      //   { id: '6', email: '111', name: '111' },
      //   { id: '7', email: '111', name: '111' },
      //   { id: '8', email: '111', name: '111' },
      //   { id: '9', email: '111', name: '111' },
      //   { id: '10', email: '111', name: '111' }
      // ];
      state.value = ['result', result];
    }
    function onBack() {
      state.value = 'list';
    }

    return {
      pollResults,
      state,
      eventToolsParticipantsWithoutHost,
      onClose,
      onView,
      onBack,
      eventToolsHistoryDateFormat
    };
  }
});
