
import { computed, defineComponent, ref } from 'vue';
import { amplitudeEvent } from '@/config/amplitudeConfig';
import usePostAPI from '@/hooks/usePostAPI';
import { getEndpoint } from '@/utils/helper';
import WhiteboardPainting from '@/components/Whiteboard/WhiteboardPainting.vue';

export default defineComponent({
  name: 'WhiteboardEntryParticipant',
  props: {
    whiteboardId: {
      type: String,
      required: true
    },
    clubhouseTitle: {
      type: String,
      default: null
    },
    clubhouseEventDate: {
      type: String,
      default: null
    },
    hasJoined: {
      type: Boolean,
      default: false
    },
    totalParticipantsNumber: {
      type: Number,
      required: true
    }
  },
  components: {
    WhiteboardPainting
  },
  setup(props) {
    const completedWhiteboardJoin = ref(props.hasJoined);
    const participantName = ref('');
    const isLoading = ref(false);
    const errorMessage = ref('');

    const confirmButtonUpdate = computed(() => {
      return participantName.value.trim();
    });

    async function joinWhiteboard() {
      isLoading.value = true;
      try {
        const response = await usePostAPI(
          getEndpoint('join', '/api/whiteboard/'),
          {
            whiteboardId: props.whiteboardId,
            participantName: participantName.value
          }
        );
        const result = response.data;
        const whiteboardId = result['whiteboardId'];
        if (response.status === 200 && whiteboardId === props.whiteboardId) {
          amplitudeEvent('spaceJoined', {
            whiteboardId: whiteboardId,
            groupSize: props.totalParticipantsNumber
          });
          completedWhiteboardJoin.value = true;
        }
      } catch (err) {
        console.log(err);
        errorMessage.value =
          'An unexpected error has occured. Please try again later or contact support@brightful.me for more info.';
      }
    }

    return {
      completedWhiteboardJoin,
      participantName,
      isLoading,
      errorMessage,
      confirmButtonUpdate,
      joinWhiteboard
    };
  }
});
