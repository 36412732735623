
import { getGameTitleByType } from '@/utils/gameConstants';
import { computed, defineComponent, ref } from 'vue';
import { useStore } from '@/store';

export default defineComponent({
  name: 'HistoryModalWouldYouRatherComponent',
  components: {},
  props: {
    game: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const user = computed(() => store.getters.getUser);
    const title = getGameTitleByType(props.game.gameName);
    const customGameName = ref(props.game.customGameName);
    const customGameCss = ref(props.game.customGameCss);
    const dateString = ref(
      props.game.dateCreated.format('DD MMMM, YYYY - hh:mm A')
    );
    const hostName = ref(undefined as string | undefined);
    const isHostNotPlaying = ref(props.game.isHostPlaying === false);
    const players = ref([] as any[]);
    const showPlayers = ref([] as any[]);
    const maxSwitch = ref(0);
    const mostSwitches = ref([] as string[]);
    const maxWolf = ref(0);
    const loneWolves = ref([] as string[]);
    const questions = ref([] as any[]);

    function calculateResult() {
      if (props.game.host !== user.value.uid) {
        const host = props.game.players.find(
          player => player.playerId === props.game.host
        );
        if (host) {
          hostName.value = host.name;
        }
      }

      const actualPlayers = Object.assign(
        [],
        isHostNotPlaying.value
          ? props.game.players.filter(
              player => player.playerId !== props.game.host
            )
          : props.game.players
      );
      const jumps = [] as any[];
      const roundDetails = [] as any[];
      const wolves = {};
      props.game.gameDetails.map(detail => {
        roundDetails.push({
          choice1: [] as string[],
          choice2: [] as string[]
        });
        questions.value.push({
          questionText: detail.questionText,
          choice1Text: detail.choice1Text,
          choice2Text: detail.choice2Text
        });
      });
      actualPlayers.map(player => {
        const localJump = player.roundDetails.filter(detail => detail.hasJumped)
          .length;
        players.value.push({
          name: player.name,
          email: player.email
        });
        jumps.push({
          name: player.name,
          jumps: localJump
        });
        if (maxSwitch.value < localJump) {
          maxSwitch.value = localJump;
        }
        if (player.roundDetails) {
          player.roundDetails.map((detail, index) => {
            if (detail.selectedChoice === 0) {
              roundDetails[index].choice1.push(player.name);
            } else if (detail.selectedChoice === 1) {
              roundDetails[index].choice2.push(player.name);
            }
          });
        }
      });
      roundDetails.map((detail, index) => {
        const wolf =
          detail.choice1.length === 1
            ? detail.choice1[0]
            : detail.choice2.length === 1
            ? detail.choice2[0]
            : null;
        if (wolf) {
          if (wolves[wolf]) {
            wolves[wolf]++;
          } else {
            wolves[wolf] = 1;
          }
        }
        questions.value[index].choice1Players = detail.choice1.length;
        questions.value[index].choice2Players = detail.choice2.length;
      });
      if (props.game.gameDetails.length >= 4 && actualPlayers.length >= 4) {
        if (maxSwitch.value > 0) {
          mostSwitches.value = jumps
            .filter(jump => jump.jumps === maxSwitch.value)
            .map(jump => jump.name);
        }
        Object.keys(wolves).map(name => {
          if (maxWolf.value < wolves[name]) {
            maxWolf.value = wolves[name];
          }
        });
        if (maxWolf.value > 0) {
          loneWolves.value = Object.keys(wolves).filter(
            name => wolves[name] === maxWolf.value
          );
        }
      }
      if (players.value.length > 7) {
        showPlayers.value = players.value.slice(0, 7);
      } else {
        showPlayers.value = players.value;
      }
    }

    calculateResult();

    function showMore() {
      showPlayers.value = players.value;
    }

    return {
      title,
      customGameName,
      customGameCss,
      dateString,
      hostName,
      isHostNotPlaying,
      players,
      showPlayers,
      maxSwitch,
      mostSwitches,
      maxWolf,
      loneWolves,
      questions,
      showMore
    };
  }
});
