
import { computed, defineComponent, ref } from 'vue';
import router from '@/router';
import {
  getEndpoint,
  getPeriodEndDate,
  isProd,
  subscriptionText,
  openUrlOnExternalBrowserFromZoom
} from '@/utils/helper';
import {
  hasTeamManagementAccess,
  isExpiredFreeTrialPlan,
  availableForUpgrade,
  isFreePlan,
  isFreeTrialPlan,
  isOneOffPlan,
  stripeMeteredActive,
  canAccessUsageReport
} from '@/utils/pricing_rules';
import usePostAPI from '@/hooks/usePostAPI';
import { useStore } from '@/store';
import { getAccountFeatures } from '@/utils/pricingConstant';
import useFirebaseCollection from '@/hooks/firebase/useFirebaseCollection';
import moment from 'moment';
import { TeamRole } from '@/models/TeamRole';
import { NavigationTabs } from '@/models/navigationTabs';
import ActiveRoomModal from '@/components/Modal/ActiveRoomModal.vue';
export default defineComponent({
  name: 'MobileNavModal',
  emits: ['close', 'show-cancel-subscription', 'show-renew-subscription'],
  props: {
    activeTab: {
      type: String,
      required: true
    },
    digitOnlyGameRoomId: {
      type: String,
      required: true
    },
    showActiveGameRoom: {
      type: Boolean,
      required: true
    }
  },
  components: { ActiveRoomModal },
  setup(props, context) {
    const store = useStore();
    const userDoc = computed(() => store.getters.getUserDoc);
    const isUserUsingCustomGameRoomId = computed(
      () => store.getters.isUserUsingCustomGameRoomId
    );
    const isCancelled = computed(() => {
      return subscriptionDetails.value.stripeCancelAtPeriodEnd ?? false;
    });
    const periodEndDate = computed(() => {
      return getPeriodEndDate(subscriptionDetails.value.stripeCurrentPeriodEnd);
    });
    const oneOffEventEndDate = computed(() => {
      if (userDoc.value.oneOffEventEndDate) {
        return moment(userDoc.value.oneOffEventEndDate.toDate()).format(
          'MMM DD, YYYY'
        );
      } else {
        return null;
      }
    });

    const isPricingRoute = router.currentRoute.value.path.includes('pricing');
    const subscriptionDetails = computed(
      () => store.getters.getSubscriptionDetails
    );
    const subscriptionEnded = computed(
      () => subscriptionDetails.value.subscriptionEnded ?? false
    );
    const isStripeMeteredActive = computed(() =>
      stripeMeteredActive(subscriptionDetails.value)
    );
    const isFingerprintJSFlagged =
      userDoc.value['isFingerprintJSFlagged'] ?? false;
    const teamRole = computed(() => subscriptionDetails.value.teamRole);
    const isTeamAccount = computed(
      () =>
        subscriptionDetails.value.teamId != null &&
        subscriptionDetails.value.teamId != undefined
    );
    const freeTrialEnded = isExpiredFreeTrialPlan(
      subscriptionDetails.value,
      userDoc.value
    );
    const freeTrialEndDate =
      userDoc.value['freeTrialEndDate'] !== undefined
        ? userDoc.value['freeTrialEndDate'].toDate()
        : undefined;
    const remainingFreeTrialDays =
      moment(freeTrialEndDate).diff(moment(), 'days') + 1;
    const remainingFreeTrialDaysText = computed(() => {
      if (remainingFreeTrialDays == 1) return 'Ends today';
      else if (remainingFreeTrialDays == 2) return 'Ends Tomorrow';
      return `${remainingFreeTrialDays} days left`;
    });
    const freeTrialEndedCompletely = computed(() => {
      return freeTrialEnded;
    });
    const showPlanDetailsPopOver = ref(false);
    const show = ref(true);
    const subscriptionLevelText = computed(() => {
      return subscriptionText(subscriptionDetails.value);
    });
    const isUserInFreeTrial =
      !freeTrialEnded && isFreeTrialPlan(subscriptionDetails.value);
    const accountFeatures = ref(getAccountFeatures(subscriptionDetails.value));
    const canAccessAdmin = computed(() =>
      hasTeamManagementAccess(subscriptionDetails.value)
    );
    const canAccessUsageReportValue = computed(() =>
      canAccessUsageReport(subscriptionDetails.value)
    );
    const clickedTab = ref(props.activeTab);
    const brightfulBitesQuery = useFirebaseCollection('minigame_bites', {
      onMounted: true,
      orderBy: 'createdAt',
      isDesc: true,
      limit: 1
    });
    const isOneOff = isOneOffPlan(subscriptionDetails.value);
    const hasUnreadBites = computed(() => {
      if (
        freeTrialEndedCompletely.value ||
        isOneOffPlan(subscriptionDetails.value)
      ) {
        return false;
      }
      const bites = brightfulBitesQuery.collectionData.value ?? [];
      if (bites.length == 0) {
        return false;
      } else {
        return userDoc.value['readBiteDoc'] != bites[0].id;
      }
    });
    const showChangePlan = computed(() => {
      return (
        !isCancelled.value && availableForUpgrade(subscriptionDetails.value)
      );
    });
    const showCancelSubscription = computed(() => {
      return !isCancelled.value && !isOneOff;
    });
    const showSlackTab = computed(() => {
      const inZoomClient = store.state.zoomClientStatus?.inZoomClient;
      if (inZoomClient) return false;
      return true;
    });
    const showAll = computed(() => {
      return router.currentRoute.value.query.show === 'all';
    });
    function close(type?: string) {
      show.value = false;
      setTimeout(() => {
        context.emit('close', type);
      }, 50);
    }
    function goToPricing() {
      context.emit('close');
      router.push({ name: 'Pricing', query: { source: 'header' } });
    }
    async function goToBilling() {
      try {
        const fromZoomClient = store.state.zoomClientStatus?.inZoomClient;
        const zoomUid = store.state.zoomClientStatus?.uid ?? null;
        const response = await usePostAPI(
          getEndpoint('createPortalLink', '/api/stripe/'),
          {
            fromZoomClient: fromZoomClient,
            zoomUid: zoomUid,
            returnUrl:
              window.location.href.split('?')[0] +
              '?selected=subscribed&source=fromBilling'
          }
        );
        const result = response.data;
        const sessionUrl = result['data']['sessionUrl'];
        if (fromZoomClient) {
          // open in an external browser
          openUrlOnExternalBrowserFromZoom(sessionUrl);
        } else {
          window.location.href = sessionUrl;
        }
      } catch (err) {
        console.log(err);
      }
    }

    function onContactUs() {
      let routeData = router.resolve({
        name: 'Feedback',
        query: {
          source: 'dashboard'
        }
      });
      window.open(routeData.href, '_blank');
    }

    return {
      isProd,
      //
      isUserUsingCustomGameRoomId,
      isPricingRoute,
      subscriptionDetails,
      isCancelled,
      periodEndDate,
      oneOffEventEndDate,
      //
      freeTrialEnded,
      remainingFreeTrialDaysText,
      isTeamAccount,
      teamRole,
      TeamRole,
      showPlanDetailsPopOver,
      subscriptionLevelText,
      subscriptionEnded,
      show,
      isUserInFreeTrial,
      accountFeatures,
      isOneOff,
      showChangePlan,
      showCancelSubscription,
      showSlackTab,
      showAll,
      NavigationTabs,
      close,
      goToPricing,
      goToBilling,
      hasUnreadBites,
      isFreePlan,
      isFreeTrialPlan,
      isStripeMeteredActive,
      onContactUs,
      isFingerprintJSFlagged,
      canAccessAdmin,
      canAccessUsageReportValue,
      clickedTab
    };
  }
});
