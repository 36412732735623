import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "modal modal-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BounceLoading = _resolveComponent("BounceLoading")
  const _component_WhiteboardDeletedModal = _resolveComponent("WhiteboardDeletedModal")
  const _component_WhiteboardEntry = _resolveComponent("WhiteboardEntry")

  return (_openBlock(), _createBlock(_Fragment, null, [
    (_ctx.loading || _ctx.signInLoading)
      ? (_openBlock(), _createBlock(_component_BounceLoading, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.error)
      ? (_openBlock(), _createBlock("div", _hoisted_1, [
          _createVNode("div", _hoisted_2, [
            _createVNode("div", {
              class: "modal-dialog modal-dialog-centered",
              onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
            }, [
              _createVNode(_component_WhiteboardDeletedModal)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.loading && !_ctx.signInLoading)
      ? (_openBlock(), _createBlock(_component_WhiteboardEntry, {
          key: 2,
          whiteboardData: _ctx.documentData
        }, null, 8, ["whiteboardData"]))
      : _createCommentVNode("", true)
  ], 64))
}