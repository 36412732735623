
import router from '@/router';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FreeTrialEndedBanner',
  props: {
    subscriptionEnded: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    function goToPricing() {
      router.push({ name: 'Pricing', query: { source: 'free-trial-banner' } });
    }

    return {
      goToPricing
    };
  }
});
