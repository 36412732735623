export enum NavigationTabs {
  HOME = 'home',
  CUSTOMIZED = 'customized',
  HISTORY = 'history',
  LEADERBOARD = 'leaderboard',
  REPORT = 'report',
  BITES = 'bites',
  SLACK = 'slack',
  TEAM = 'team',
  RECORDS = 'records',
  EVENT_TOOLS = 'event_tools'
}
