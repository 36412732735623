
import router from '@/router';
import { closeTab } from '@/utils/helper';
import { defineComponent, ref } from 'vue';
import { NavigationTabs } from '@/models/navigationTabs';
export default defineComponent({
  name: 'PaymentSuccessfulModal',
  props: {
    isFromGame: {
      type: Boolean,
      required: true
    },
    isFromGameEnd: {
      type: Boolean,
      required: true
    },
    lobbyId: {
      type: String,
      required: true
    }
  },
  emits: ['close'],
  setup(props, context) {
    const isLoading = ref(false);

    const close = function() {
      context.emit('close');
    };
    async function goToDashboard() {
      if (props.isFromGame) {
        closeTab(props.lobbyId);
      } else {
        if (props.isFromGameEnd) {
          isLoading.value = true;
          await closeTab(props.lobbyId, true);
          isLoading.value = false;
        }
        router.push({
          name: 'Dashboard',
          query: { tab: NavigationTabs.HOME, source: 'pricing' }
        });
      }
    }
    return {
      isLoading,
      goToDashboard,
      close
    };
  }
});
