
import { defineComponent, onMounted } from 'vue';
import { amplitudeEvent } from '@/config/amplitudeConfig';
// import NumberOfPlayersOneToHundred from '@/components/Modal/AboutComponent/NumberOfPlayersComponents/NumberOfPlayersOneToHundred.vue';
import HowToPlayOneToHundred from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayOneToHundred.vue';

export default defineComponent({
  name: 'AboutOneToHundred',
  components: {
    // NumberOfPlayersOneToHundred,
    HowToPlayOneToHundred
  },
  setup() {
    onMounted(() => {
      amplitudeEvent('previewGame', {
        game: 'one_to_hundred',
        version: 'regular',
        eventTime: new Date(Date.now()).toISOString()
      });
    });
  }
});
