
interface StartGameEvent {
  gameName: string;
  source?: string;
  track?: any;
  onSuccess?: Function;
  onError?: Function;
  onLoading?: Function;
}
interface CustomizedGameStartEvent {
  gameName: string;
  customGameName: string;
  customGameId: string;
  isCommunityCreated: boolean;
  source?: string;
  onSuccess?: any;
  onError?: any;
}
import { defineComponent, ref, computed, watch, onMounted, Ref } from 'vue';
import BounceLoading from '@/components/BounceLoading.vue';
import BrightfulHeader from '@/components/General/BrightfulHeader.vue';
import AboutModal from '@/components/Modal/AboutModal.vue';
import GameVersionModal from '@/components/Modal/GameVersionModal.vue';
import AccountSettingsModal from '@/components/Modal/AccountSettingsModal.vue';
import MobileNavModal from '@/components/Modal/MobileNavModal.vue';
import CustomizedGameListModal from '@/components/Modal/CustomizedGameListModal.vue';
import MobileScreenUnsupportedModal from '@/components/Modal/MobileScreenUnsupportedModal.vue';
import CustomizedGameSettingsModal from '@/components/Modal/CustomizedGameSettingsModal.vue';
import CustomizedGameExampleModal from '@/components/Modal/CustomizedGameExampleModal.vue';
import OrganizationSubscriptionLoadingModal from '@/components/Modal/OrganizationSubscriptionLoadingModal.vue';
import OrganizationSubscriptionSuccessfulModal from '@/components/Modal/OrganizationSubscriptionSuccessfulModal.vue';
import BeginnerIntroductionModal from '@/components/Modal/BeginnerIntroductionModal.vue';
import OkModal from '@/components/Modal/OkModal.vue';
import SuccessModal from '@/components/Modal/SuccessModal.vue';
import VideoModal from '@/components/Modal/VideoModal.vue';
import ConfirmationModal from '@/components/Modal/ConfirmationModal.vue';
import OnboardingVideoModal from '@/components/Modal/OnboardingVideoModal.vue';
import HistoryModal from '@/components/HistoryLeaderboard/HistoryModal.vue';
import HistoryUpgradePlanModal from '@/components/HistoryLeaderboard/HistoryUpgradePlanModal.vue';
import SignUpSuccessModal from '@/components/Modal/SignUpSuccessModal.vue';
import TypeformSurvey from '@/components/TypeformSurvey.vue';
// import OnboardingAssistant from '@/components/OnboardingAssistant.vue';
import Home from '@/views/Home.vue';
// import CommunityMadeGames from '@/views/CommunityMadeGames.vue';
import YourCustomizedGames from '@/views/YourCustomizedGames.vue';
// import IcebreakerMaterials from '@/views/IcebreakerMaterials.vue';
// import SlackApp from '@/views/SlackApp.vue';
import History from '@/views/History.vue';
import UsageReport from '@/views/UsageReport.vue';
import Team from '@/views/Team.vue';
import Leaderboard from '@/views/Leaderboard.vue';
import LeftNavBar from '@/components/LeftNavBar.vue';
import FreeTrialEndedBanner from '@/components/Home/FreeTrialEndedBanner.vue';
import FreeTrialEndingBar from '@/components/Home/FreeTrialEndingBar.vue';
import LaunchModal from '@/components/Modal/LaunchModal.vue';
import {
  getCookie,
  hypenDigitGameRoomId,
  onGameStart,
  onCustomGameStart,
  isGameRoomOpen,
  getEndpoint,
  hasActiveGame
} from '@/utils/helper';
import {
  isLitePlan,
  // eligibleForTeam,
  isFreeOrLitePlan,
  isExpiredFreeTrialPlan,
  isLevel2Plan,
  hasTeamManagementAccess,
  isFreePlan,
  isFreeTrialPlan,
  stripeMeteredActive,
  isActiveSubscription,
  isLevel1Plan,
  canAccessUsageReport
} from '@/utils/pricing_rules';
import {
  gameTypes,
  freeGameTypes,
  getGameVideoByType,
  // leaderboardPromotionGameTypes,
  detailGameTypes,
  versionGameTypes,
  leaderboardGameTypes,
  detailEventToolsTypes
} from '@/utils/gameConstants';
import { amplitudeEvent, identifyAmplitude } from '@/config/amplitudeConfig';
import firebase from '@/firebaseApp';
import useFirebaseCollection from '@/hooks/firebase/useFirebaseCollection';
import router from '@/router';
import VueScrollTo from 'vue-scrollto';
import { SubscriptionInterval } from '@/models/SubscriptionInterval';
import { heapAddUserProperties, identifyHeap } from '@/config/heapConfig';
import { useStore } from '@/store';
import firebaseApp from '@/firebaseApp';
import {
  UPDATE_ACTIVEGAMEROOMID,
  UPDATE_CURRENT_GAME_DATA,
  // UPDATE_PROMOTION_FLAG,
  UPDATE_SLACK_MODAL_STATUS,
  // UPDATE_SLACK_STATUS,
  UPDATE_USER_CUSTOMIZED_GAMES,
  UPDATE_USER_SUBSCRIPTION_DETAILS
} from '@/store/mutation-types';
import { TeamInfo } from '@/models/TeamInfo';
import usePostAPI from '@/hooks/usePostAPI';
import useFirebaseLoginOut from '@/hooks/firebase/useFirebaseLoginOut';
import moment from 'moment';
import { TeamRole } from '@/models/TeamRole';
import useDocumentListener from '@/hooks/firebase/useDocumentListener';
import useRTDBListener from '@/hooks/firebase/useRTDBListener';
import { ActivityType, GameName } from '@/utils/gamenames_enums';
import { NavigationTabs } from '@/models/navigationTabs';
import { LaunchOptions } from '@/models/GameStartOptions';
export default defineComponent({
  name: 'DashboardMain',
  props: {
    showSignupSuccess: {
      type: Boolean,
      required: true
    },
    teamName: {
      type: String
    }
  },
  emits: ['show-cancel-subscription', 'show-renew-subscription'],
  components: {
    BounceLoading,
    BrightfulHeader,
    AboutModal,
    GameVersionModal,
    AccountSettingsModal,
    MobileNavModal,
    CustomizedGameListModal,
    MobileScreenUnsupportedModal,
    CustomizedGameSettingsModal,
    CustomizedGameExampleModal,
    OrganizationSubscriptionLoadingModal,
    OrganizationSubscriptionSuccessfulModal,
    VideoModal,
    BeginnerIntroductionModal,
    OkModal,
    SuccessModal,
    ConfirmationModal,
    OnboardingVideoModal,
    HistoryModal,
    HistoryUpgradePlanModal,
    SignUpSuccessModal,
    TypeformSurvey,
    // OnboardingAssistant,
    Home,
    // CommunityMadeGames,
    YourCustomizedGames,
    // IcebreakerMaterials,
    // SlackApp,
    History,
    Leaderboard,
    UsageReport,
    Team,
    LeftNavBar,
    FreeTrialEndedBanner,
    FreeTrialEndingBar,
    LaunchModal
  },
  setup(props) {
    const store = useStore();
    const modalTypeShown: Ref<['launch', LaunchOptions] | null> = ref(null);
    const user = computed(() => store.getters.getUser);
    const userDoc = computed(() => store.getters.getUserDoc);
    const lobbyDoc = computed(() => store.getters.getLobbyDoc);
    const survey1Answer = computed(() => store.getters.getSurvey1Answer);
    const isUserUsingCustomGameRoomId = computed(
      () => store.getters.isUserUsingCustomGameRoomId
    );
    const activeGameRoomId = computed(() => store.getters.activeGameRoomId);
    const activeGameName = computed(() => store.getters.activeGameName);
    const isActiveRoomClosed = computed(
      () => !isGameRoomOpen(lobbyDoc.value ?? {})
    );
    const subscriptionDetails = computed(
      () => store.getters.getSubscriptionDetails
    );
    // const teamInfo = computed(() => store.getters.getTeamInfo);
    const userTotalGameplays = computed(() => store.getters.getTotalGameplays);

    // const isSlackAuthenticated = computed(() =>
    //   userDoc.value['slackUid'] ? true : false
    // );

    const userCustomizedGamesQuery = useFirebaseCollection(
      'minigame_custom_game_public',
      {
        onMounted: true,
        fieldPath: 'created_by',
        opStr: '==',
        value: user.value.uid,
        orderBy: 'dateCreated',
        isDesc: false
      },
      true
    );

    watch(userCustomizedGamesQuery.collectionData, newValue => {
      store.commit(UPDATE_USER_CUSTOMIZED_GAMES, {
        newUserCustomizedGames: newValue
      });
    });

    const teamRole = computed(() => subscriptionDetails.value.teamRole);

    const isTeamAccount = computed(
      () =>
        subscriptionDetails.value.teamId != null &&
        subscriptionDetails.value.teamId != undefined
    );

    const freeTrialEnded = isExpiredFreeTrialPlan(
      subscriptionDetails.value,
      userDoc.value
    );

    const freeTrialEndDate =
      userDoc.value['freeTrialEndDate'] !== undefined
        ? userDoc.value['freeTrialEndDate'].toDate()
        : undefined;

    const remainingFreeTrialDays =
      moment(freeTrialEndDate).diff(moment(), 'days') + 1;
    const subscriptionEnded = computed(
      () => subscriptionDetails.value.subscriptionEnded ?? false
    );

    const freeTrialEndedCompletely = freeTrialEnded;
    //
    const showInitialLoading = ref(true);
    //
    const showCustomIdBlockModal = ref(false);

    const showRestrictedGameHostBlockModal = ref(false);
    const showCustomizedGameStartBlockModal = ref(false);
    const showAboutModal = ref(false);
    const showGameVersionModal = ref(false);
    const showMeteredWarningDialog = ref(false);
    const aboutType = ref('');
    const settingsType = ref('');
    const gameStartSource = ref(undefined as string | undefined);
    const selectedMeditationTrackId = ref(undefined);
    const showAccountSettingsModal = ref(false);
    const showMobileNavModal = ref(false);
    const showCustomizedModal = ref(false);
    const showDuplicateSuccessModal = ref(false);
    const duplicateCustomGameId = ref(undefined as string | undefined);

    const showMobileScreenUnsupportedModal = ref(false);
    const showSignupSuccessModal = ref(false);
    //
    const showTypeformSurvey1 = ref(false);
    const openTypeformSurvey1 = ref(false);
    //
    const showCustomizedGameSettingsModal = ref(false);
    const customizedGameSettingsExampleType = ref('');
    const customizedGameSettingsCustomGameDocName = ref('');
    const customizedGameSettingsCustomGameDocId = ref('');
    const customizedGameType = ref('');
    //
    const showCustomizedGameExampleModal = ref(false);
    const customizedGameSettingsExampleCustomGameDocId = ref('');
    //
    const showVideoModal = ref(false);
    const showBeginnerIntroductionModal = ref(false);
    const youtubeVideoId = ref('');
    //
    const defaultLobbyId = ref('');
    const digitOnlyGameRoomId = ref('');
    //
    const showHistoryModal = ref(false);
    const historyGame = ref('');
    //
    const showHistoryUpgradePlanModal = ref(false);
    //
    const showOrganizationSubscriptionLoadingModal = ref(false);
    const showOrganizationSubscriptionSuccessfulModal = ref(false);
    //
    const scrollPosition = ref(0);
    //

    const teamErrorDescription = ref(undefined as string | undefined);
    const teamSuccessDescription = ref(undefined as string | undefined);
    const showTeamModal = ref(false);

    const showConfirmLeaveTeamModal = ref(false);

    const onnboardingVideoIndex = ref(-1);

    const nBeginGames = ref();
    const showFreeTrialEndedBar = computed(() => {
      return (
        (freeTrialEndedCompletely || subscriptionEnded.value) &&
        (teamRole.value == TeamRole.Owner || !isTeamAccount.value)
      );
    });
    const showFreeTrialEndingBar = computed(() => {
      return (
        !selectedType.value &&
        isFreeTrialPlan(subscriptionDetails.value) &&
        remainingFreeTrialDays <= 5
      );
    });
    const shownDialog = computed(() => {
      return (
        showAboutModal.value ||
        showGameVersionModal.value ||
        showAccountSettingsModal.value ||
        showMobileNavModal.value ||
        showCustomizedModal.value ||
        showMobileScreenUnsupportedModal.value ||
        showCustomizedGameSettingsModal.value ||
        showCustomizedGameExampleModal.value ||
        showVideoModal.value ||
        showBeginnerIntroductionModal.value ||
        showOrganizationSubscriptionLoadingModal.value ||
        showOrganizationSubscriptionSuccessfulModal.value ||
        showTeamModal.value ||
        showConfirmLeaveTeamModal.value ||
        onnboardingVideoIndex.value !== -1 ||
        showHistoryModal.value ||
        showHistoryUpgradePlanModal.value ||
        showSignupSuccessModal.value ||
        showDuplicateSuccessModal.value
      );
    });

    watch(shownDialog, newValue => updateBodyCssForDialog(newValue));

    const tab = computed(() => {
      switch (router.currentRoute.value.query.tab) {
        case NavigationTabs.HOME:
        case NavigationTabs.CUSTOMIZED:
        case NavigationTabs.HISTORY:
        case NavigationTabs.LEADERBOARD:
          return router.currentRoute.value.query.tab;
        case NavigationTabs.REPORT:
          if (canAccessUsageReport(subscriptionDetails.value)) {
            return NavigationTabs.REPORT;
          } else {
            return NavigationTabs.HOME;
          }
        case NavigationTabs.TEAM:
          if (hasTeamManagementAccess(subscriptionDetails.value)) {
            return NavigationTabs.TEAM;
          } else {
            return NavigationTabs.HOME;
          }
        default:
          return NavigationTabs.HOME;
      }
    });

    const titleAndDescription = computed(() => {
      switch (router.currentRoute.value.query.tab) {
        case NavigationTabs.HOME:
          return {
            title: 'Activities & Tools',
            description: 'Engage your team or audience.'
          };
        case NavigationTabs.CUSTOMIZED:
          return {
            title: 'Game Content Creator',
            description: 'Create games with your own content.'
          };
        case NavigationTabs.HISTORY:
          return {
            title: 'Past Activities',
            description: 'Check out what activities you have hosted.'
          };
        case NavigationTabs.LEADERBOARD:
          return {
            title: 'Game Leaderboard',
            description: 'View who are doing best in a game.'
          };
        case NavigationTabs.REPORT:
          if (canAccessUsageReport(subscriptionDetails.value)) {
            return {
              title: 'Usage Report',
              description: 'View a summary of your account.'
            };
          } else {
            return {
              title: 'Activities & Tools',
              description: 'Engage your team or audience.'
            };
          }
        // case NavigationTabs.BITES:
        //   return {
        //     title: 'Icebreaker Materials',
        //     description: 'Share interesting topics or stories with your team.'
        //   };
        // case NavigationTabs.SLACK:
        //   return {
        //     title: 'Slack App',
        //     description: 'Engage your team within Slack.'
        //   };
        case NavigationTabs.TEAM:
          if (hasTeamManagementAccess(subscriptionDetails.value)) {
            return {
              title: 'Admins / Hosts',
              description: 'Invite others to use Brightful.'
            };
          } else {
            return {
              title: 'Activities & Tools',
              description: 'Engage your team or audience.'
            };
          }
        default:
          return {
            title: 'Activities & Tools',
            description: 'Engage your team or audience.'
          };
      }
    });

    const selectedType = computed(() => {
      const type = router.currentRoute.value.query.type;
      if (type && typeof type === 'string') {
        if (tab.value == NavigationTabs.HOME) {
          if (
            detailGameTypes.includes(type) ||
            detailEventToolsTypes.includes(type)
          ) {
            return router.currentRoute.value.query.type as string;
          } else {
            return null;
          }
        } else if (tab.value == NavigationTabs.LEADERBOARD) {
          if (leaderboardGameTypes.includes(type)) {
            return router.currentRoute.value.query.type as string;
          } else {
            return null;
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    });

    const hasNavBack = computed(() => {
      return (
        selectedType.value || router.currentRoute.value.query.show === 'all'
      );
    });

    function updateBodyCssForDialog(shown: boolean) {
      if (shown) {
        document.body.className += ' modal-open';
        const needHelpBox = document.getElementById('freshworks-container');
        if (needHelpBox !== null) needHelpBox.hidden = true;
      } else {
        document.body.className = document.body.className.replaceAll(
          ' modal-open',
          ''
        );
        const needHelpBox = document.getElementById('freshworks-container');
        if (needHelpBox !== null) needHelpBox.hidden = false;
      }
    }

    updateBodyCssForDialog(false);

    function onBack() {
      router.push({ query: { tab: tab.value } });
      goToScroll();
    }

    function storeOldVisitorId() {
      const visitorCache = getCookie('visitorCache');
      if (visitorCache === '') {
        // no visitor id found
        return;
      } else {
        firebase
          .firestore()
          .collection('minigame_user')
          .doc(user.value.uid)
          .update({
            amplitudeUserId: JSON.parse(visitorCache)['visitorId']
          });
      }
    }

    async function handleSignedInUser() {
      console.log('Dashboard handleSignedInUser: running');

      nBeginGames.value = userTotalGameplays.value;
      defaultLobbyId.value = userDoc.value['defaultLobbyId'];
      digitOnlyGameRoomId.value = hypenDigitGameRoomId(
        defaultLobbyId.value.substring(defaultLobbyId.value.indexOf('_') + 1)
      );
      store.commit(UPDATE_ACTIVEGAMEROOMID, {
        activeGameRoomId: userDoc.value['defaultGameRoomId']
      });

      const userCollectionGameDataQuery = useDocumentListener(
        'minigame_data',
        userDoc.value['defaultGameRoomId']
      );
      const userRTDBGameDataQuery = useRTDBListener(
        `minigame_data/${userDoc.value['defaultGameRoomId']}`
      );
      watch(
        [
          userCollectionGameDataQuery.documentData,
          userRTDBGameDataQuery.documentData
        ],
        newValue => {
          const actualV = newValue[0] ? newValue[0] : newValue[1];
          let v = actualV ? Object.assign({}, actualV) : null;
          if (v) {
            if (v['isFinished'] || v['isClosed']) {
              v = null;
            } else {
              v['isCurrent'] = true;
            }
          }
          store.commit(UPDATE_CURRENT_GAME_DATA, {
            newCurrentGameData: v
          });
        }
      );

      showSignupSuccessModal.value = props.showSignupSuccess;

      const urlParams = new URLSearchParams(window.location.search);
      const selectedAction = urlParams.get('selected');
      const customGameId = urlParams.get('customGame');
      if (selectedAction) {
        if (selectedAction == 'customized_games') {
          VueScrollTo.scrollTo('#customized_games', 200);
        } else if (selectedAction == 'organization_subscription') {
          showOrganizationSubscriptionLoadingModal.value = true;
        } else if (selectedAction == 'org_subscription_success') {
          showOrganizationSubscriptionSuccessfulModal.value = true;
        } else {
          startGame({ gameName: selectedAction });
        }
      }
      if (customGameId) await showCustomizedGameSettings(customGameId);
      showInitialLoading.value = false;
      storeOldVisitorId();
      amplitudeEvent('visitedDashboard', {
        eventTime: new Date(Date.now()).toISOString()
      });
      identifyHeap(user.value.uid);
      if (survey1Answer.value !== undefined && survey1Answer.value !== '') {
        const allAnswers = survey1Answer.value.join(', ');
        identifyAmplitude('setOnce', 'survey1Answer', allAnswers);
        heapAddUserProperties('survey1Answer', allAnswers);
      }
      if (subscriptionDetails.value.teamRole) {
        identifyAmplitude(
          'set',
          'teamRole',
          subscriptionDetails.value.teamRole
        );
      }

      const teamUrl = urlParams.get('team');
      if (teamUrl) {
        handleTeam(teamUrl);
      }
      if (!stripeMeteredActive(subscriptionDetails.value)) {
        showMeteredWarningDialog.value = true;
      }

      handlePromotion();
    }

    async function handleTeam(teamUrl: string) {
      const teamQuery = useFirebaseCollection('minigame_teams', {});
      await teamQuery.getCollection({
        fieldPath: 'link.url',
        opStr: '==',
        value: teamUrl,
        fieldPath2: 'link.enabled',
        opStr2: '==',
        value2: true,
        limit: 1
      });
      const teams = teamQuery.collectionData.value ?? [];
      if (teams.length > 0) {
        const teamInfo = new TeamInfo(teams[0]);
        if (!teamInfo.members.find(member => member.uid === user.value.uid)) {
          amplitudeEvent('startJoinTeam', {
            eventTime: new Date(Date.now()).toISOString()
          });
          if (subscriptionDetails.value.teamId) {
            teamErrorDescription.value = `You are not able to join ${teamInfo.name} because you are currently registered in another team. Leave the current team and then try to join again. For further assistance please contact us at support@brightful.me.`;
            showTeamModal.value = true;
          } else if (isActiveSubscription(subscriptionDetails.value)) {
            teamErrorDescription.value = `You are not able to join ${teamInfo.name} because you currently have an active paid plan. For further assistance please contact us at support@brightful.me.`;
            showTeamModal.value = true;
          } else {
            const response = await usePostAPI(getEndpoint('joinTeam'), {
              teamId: teamInfo.id
            });
            if (response.status === 200) {
              teamSuccessDescription.value = `You have joined ${teamInfo.name} successfully.`;
              showTeamModal.value = true;
              const result = await useFirebaseLoginOut().getSubscriptionDetails(
                true
              );
              store.commit(UPDATE_USER_SUBSCRIPTION_DETAILS, {
                newSubscriptionDetails: result
              });
              amplitudeEvent('completeJoinTeam', {
                eventTime: new Date(Date.now()).toISOString()
              });
            }
          }
        }
      }
    }

    async function handlePromotion() {
      const seenSlackSuccessModal =
        userDoc.value['seenSlackSuccessModal'] ?? false;
      store.commit(UPDATE_SLACK_MODAL_STATUS, {
        slackSuccessModalSeen: seenSlackSuccessModal ?? false
      });
      // const seenTeam = seenPromotions['team'] ?? 0;
      // const seenSlack = seenPromotions['slack'] ?? 0;

      // const seenPromotions = userDoc.value['seenPromotions'] ?? {};
      // const seenEventTools = seenPromotions['event_tools'];
      // if (
      //   !seenEventTools &&
      //   store.getters.isUserCreatedBeforeEventsToolLaunch
      // ) {
      //   store.commit(UPDATE_PROMOTION_FLAG, {
      //     newPromotionFlag: 'event_tools'
      //   });
      //   amplitudeEvent('promotionPopupShows', {
      //     eventTime: new Date(Date.now()).toISOString(),
      //     content: 'team'
      //   });
      //   return;
      // }

      // if (
      //   seenSlack === 0 &&
      //   !isSlackAuthenticated.value &&
      //   userTotalGameplays.value >= 4 &&
      //   userTotalGameplays.value < 10
      // ) {
      //   store.commit(UPDATE_PROMOTION_FLAG, {
      //     newPromotionFlag: 'slack'
      //   });
      //   amplitudeEvent('promotionPopupShows', {
      //     eventTime: new Date(Date.now()).toISOString(),
      //     content: 'slack'
      //   });
      //   return;
      // }

      // if (!seenPromotions['leaderboard']) {
      //   const gameplayHistoryQuery = useFirebaseCollection(
      //     `minigame_user/${user.value.uid}/user_gameplay_history`,
      //     {
      //       onMounted: false
      //     }
      //   );
      //   await gameplayHistoryQuery.getCollection({
      //     fieldPath: 'gameName',
      //     opStr: 'in',
      //     value: leaderboardPromotionGameTypes,
      //     limit: 4
      //   });
      //   if (gameplayHistoryQuery.collectionData.value?.length === 4) {
      //     store.commit(UPDATE_PROMOTION_FLAG, {
      //       newPromotionFlag: 'leaderboard'
      //     });
      //     amplitudeEvent('promotionPopupShows', {
      //       eventTime: new Date(Date.now()).toISOString(),
      //       content: 'leaderboard'
      //     });
      //     return;
      //   }
      // }

      // if (
      //   !seenPromotions['bites'] &&
      //   userTotalGameplays.value >= 4 &&
      //   userTotalGameplays.value < 10
      // ) {
      //   store.commit(UPDATE_PROMOTION_FLAG, {
      //     newPromotionFlag: 'bites'
      //   });
      //   amplitudeEvent('promotionPopupShows', {
      //     eventTime: new Date(Date.now()).toISOString(),
      //     content: 'bites'
      //   });
      //   return;
      // }

      // if (eligibleForTeam(subscriptionDetails.value)) {
      //   if (
      //     seenTeam !== 10 &&
      //     teamInfo.value &&
      //     teamInfo.value.members.length === 1
      //   ) {
      //     store.commit(UPDATE_PROMOTION_FLAG, {
      //       newPromotionFlag: 'team'
      //     });
      //     amplitudeEvent('promotionPopupShows', {
      //       eventTime: new Date(Date.now()).toISOString(),
      //       content: 'team'
      //     });
      //     return;
      //   }
      // }

      // if (
      //   seenSlack !== 10 &&
      //   !isSlackAuthenticated.value &&
      //   userTotalGameplays.value >= 10
      // ) {
      //   store.commit(UPDATE_PROMOTION_FLAG, {
      //     newPromotionFlag: 'slack'
      //   });
      //   amplitudeEvent('promotionPopupShows', {
      //     eventTime: new Date(Date.now()).toISOString(),
      //     content: 'slack'
      //   });
      //   return;
      // }
    }

    handleSignedInUser();

    async function startGame(event: StartGameEvent) {
      try {
        if (event.onLoading) event.onLoading();
        settingsType.value = event.gameName;
        customizedGameType.value = '';
        gameStartSource.value = event.source;
        selectedMeditationTrackId.value = event.track
          ? event.track.trackId
          : undefined;

        if (gameTypes.includes(event.gameName)) {
          if (
            isFreePlan(subscriptionDetails.value) &&
            !freeGameTypes.includes(event.gameName)
          ) {
            goToPricing('subscription-ended');
          } else if (user.value.displayName === null) {
            showBeginnerIntroductionModal.value = true;
          } else {
            const launchOptions: LaunchOptions = {
              activityType: ActivityType.GAME,
              chosenGame: settingsType.value,
              createGameSource: gameStartSource.value ?? null
            };
            if (!hasActiveGame()) {
              await onGameStart({
                ...launchOptions,
                subscriptionLevel: subscriptionDetails.value.subscriptionLevel,
                zoomClientConfig: store.state.zoomClientStatus,
                startFresh: false,
                trackId: selectedMeditationTrackId.value
              });
            } else {
              modalTypeShown.value = [
                'launch',
                {
                  ...launchOptions,
                  trackId: selectedMeditationTrackId.value
                }
              ];
            }
          }
        }
        if (event.onSuccess) {
          event.onSuccess();
        }
      } catch (err) {
        console.log(err);
        if (event.onError) {
          event.onError();
        }
      }
    }

    function viewDetails(event) {
      const type: string = event.type;
      const source: string = event.source;
      const onSuccess = event.onSuccess;
      const onError = event.onError;
      const onLoading = event.onLoading;
      if (detailGameTypes.includes(type)) {
        saveScrollPosition(window.scrollY);
        window.scrollTo(0, 0);
        router.push({
          query: { tab: NavigationTabs.HOME, type: type }
        });
      } else if (versionGameTypes.includes(type)) {
        showGameVersion(type, source);
      } else {
        startGame({
          gameName: type,
          source: source,
          onSuccess: onSuccess,
          onError: onError,
          onLoading: onLoading
        });
      }
    }

    function showAbout(type: string) {
      aboutType.value = type;
      showAboutModal.value = true;
    }

    function onAboutClosed() {
      showAboutModal.value = false;
    }

    function showGameVersion(type: string, source?: string) {
      aboutType.value = type;
      gameStartSource.value = source;
      showGameVersionModal.value = true;
    }

    async function onGameVersionClosed(event) {
      const type: string | undefined = event.gameName;
      const customGameName: string | undefined = event.customGameName;
      const customGameId: string | undefined = event.customGameId;
      const isCommunityCreated: boolean = event.isCommunityCreated ?? false;
      const onSuccess: any = event.onSuccess;
      const onError: any = event.onError;
      try {
        if (type) {
          if (!customGameId || !customGameName) {
            await startGame({ gameName: type, source: gameStartSource.value });
          } else {
            await startCustomizedGame({
              gameName: type,
              customGameName: customGameName,
              customGameId: customGameId,
              isCommunityCreated: isCommunityCreated,
              source: gameStartSource.value
            });
          }
        }
        if (onSuccess) onSuccess();
        showGameVersionModal.value = false;
      } catch (err) {
        console.log(err);
        if (onError) onError();
      }
    }

    function openHistoryModal(game: any) {
      historyGame.value = game;
      showHistoryModal.value = true;
    }

    function onHistoryModalClosed() {
      showHistoryModal.value = false;
    }

    function openHistoryUpgradePlanModal() {
      showHistoryUpgradePlanModal.value = true;
    }

    function onHistoryUpgradePlanModalClosed() {
      showHistoryUpgradePlanModal.value = false;
    }

    function showAccountSettings() {
      showAccountSettingsModal.value = true;
    }

    function onAccountSettingsClosed() {
      showAccountSettingsModal.value = false;
    }

    function onLeaveTeam() {
      showAccountSettingsModal.value = false;
      openConfirmLeaveTeamModal();
    }

    function openConfirmLeaveTeamModal() {
      showConfirmLeaveTeamModal.value = true;
    }

    async function closeConfirmLeaveTeamModal(value: boolean) {
      showConfirmLeaveTeamModal.value = false;
      if (value) {
        await usePostAPI(getEndpoint('leaveTeam'), {});
        await router.push({ name: 'Dashboard' });
        window.location.reload();
      }
    }

    function openOnboardingVideoModal(index: number) {
      onnboardingVideoIndex.value = index;
    }

    async function closeOnboardingVideoModal() {
      onnboardingVideoIndex.value = -1;
    }

    function onShowBlockFreePlanCustomIdModal() {
      showAccountSettingsModal.value = false;
      goToPricing('subscription-ended');
    }

    function showMobileNav() {
      showMobileNavModal.value = true;
    }

    function onMobileNavClosed(result: string) {
      showMobileNavModal.value = false;
      switch (result) {
        case NavigationTabs.HOME:
        case NavigationTabs.CUSTOMIZED:
        case NavigationTabs.EVENT_TOOLS:
        case NavigationTabs.HISTORY:
        case NavigationTabs.LEADERBOARD:
        case NavigationTabs.REPORT:
        case NavigationTabs.TEAM:
          onTab(result);
          break;
        case 'setting':
          showAccountSettings();
          break;
        default:
          break;
      }
    }

    function onLeftNavBarTab(tab: string) {
      switch (tab) {
        case NavigationTabs.HOME:
        case NavigationTabs.CUSTOMIZED:
        case NavigationTabs.EVENT_TOOLS:
        case NavigationTabs.HISTORY:
        case NavigationTabs.LEADERBOARD:
        case NavigationTabs.REPORT:
        case NavigationTabs.TEAM:
          onTab(tab);
          break;
        default:
          break;
      }
    }

    function showCustomized() {
      showCustomizedModal.value = true;
    }

    function onCreateCustomizedGame() {
      if (
        isExpiredFreeTrialPlan(subscriptionDetails.value, userDoc.value) ||
        isLevel1Plan(subscriptionDetails.value)
      ) {
        const subscriptionEnded =
          subscriptionDetails.value.subscriptionEnded ?? false;
        goToPricing(
          subscriptionEnded ? 'subscription-ended' : 'upgrade-from-customized'
        );
        return;
      } else {
        showCustomized();
      }
    }

    function onCustomizedClosed() {
      showCustomizedModal.value = false;
    }

    async function onDuplicateCustomizedGame(customGameId: string) {
      if (
        isExpiredFreeTrialPlan(subscriptionDetails.value, userDoc.value) ||
        isFreeOrLitePlan(subscriptionDetails.value, userDoc.value)
      ) {
        const subscriptionEnded =
          subscriptionDetails.value.subscriptionEnded ?? false;
        goToPricing(
          subscriptionEnded ? 'subscription-ended' : 'free-trial-ended'
        );
      } else {
        const response = await usePostAPI(
          getEndpoint('duplicateCustomGamePage'),
          {
            customGameId: customGameId
          }
        );
        if (response.status == 200) {
          const newCustomGameDocId = response.data.customGameId;
          openDuplicateSuccessModal(newCustomGameDocId);
        }
      }
    }

    function openDuplicateSuccessModal(customGameId: string) {
      showDuplicateSuccessModal.value = true;
      duplicateCustomGameId.value = customGameId;
    }

    async function closeDuplicateSuccessModal(value?: boolean) {
      showDuplicateSuccessModal.value = false;
      if (value) {
        if (window.innerWidth <= 575) {
          showMobileScreenUnsupportedModal.value = true;
        } else {
          let routeData = router.resolve({
            name: 'EditCustomGame',
            query: {
              customGameId: duplicateCustomGameId.value
            }
          });
          window.open(routeData.href, '_blank');
        }
      }
    }

    async function showCustomizedGameSettings(
      /// this customGameId is the actual custom game id inside the document
      customGameId: string
    ) {
      const customGameDocQuery = await firebaseApp
        .firestore()
        .collection('minigame_custom_game_public')
        .where('customGameId', '==', customGameId)
        .limit(1)
        .get();
      if (
        customGameDocQuery.docs &&
        customGameDocQuery.docs.length > 0 &&
        customGameDocQuery.docs[0].data().created_by !== user.value.uid &&
        (isFreeTrialPlan(subscriptionDetails.value) ||
          isFreePlan(subscriptionDetails.value))
      ) {
        goToPricing('free-trial-ended');
      }
      customizedGameSettingsCustomGameDocId.value = customGameId;
      showCustomizedGameSettingsModal.value = true;
    }

    async function startCustomizedGame(event: CustomizedGameStartEvent) {
      try {
        customizedGameSettingsCustomGameDocName.value = event.customGameName;
        customizedGameSettingsCustomGameDocId.value = event.customGameId;
        customizedGameType.value = event.gameName;
        gameStartSource.value = event.source;
        if (
          (isFreeTrialPlan(subscriptionDetails.value) &&
            event.isCommunityCreated) ||
          isFreePlan(subscriptionDetails.value)
        ) {
          goToPricing('free-trial-ended');
        } else if (user.value.displayName === null) {
          showBeginnerIntroductionModal.value = true;
        } else {
          const launchOptions: LaunchOptions = {
            activityType: ActivityType.GAME,
            chosenGame: event.gameName,
            createGameSource: gameStartSource.value ?? null,
            customGameId: customizedGameSettingsCustomGameDocId.value,
            customGameName: customizedGameSettingsCustomGameDocName.value
          };
          if (!hasActiveGame()) {
            await onCustomGameStart({
              ...launchOptions,
              subscriptionLevel: subscriptionDetails.value.subscriptionLevel,
              zoomClientConfig: store.state.zoomClientStatus,
              startFresh: false,
              trackId: selectedMeditationTrackId.value,
              customGameId: customizedGameSettingsCustomGameDocId.value,
              customGameName: customizedGameSettingsCustomGameDocName.value
            });
          } else {
            modalTypeShown.value = [
              'launch',
              {
                ...launchOptions,
                trackId: selectedMeditationTrackId.value
              }
            ];
          }
        }
        if (event.onSuccess) {
          event.onSuccess();
        }
      } catch (err) {
        console.log(err);
        if (event.onError) {
          event.onError();
        }
      }
    }

    function onCustomizedGameSettingsClosed() {
      showCustomizedGameSettingsModal.value = false;
    }

    function showCustomizedGameExample(
      type: string,
      /// this customGameId is the actual custom game id inside the document
      customGameId: string,
      source?: string
    ) {
      customizedGameSettingsExampleType.value = type;
      customizedGameSettingsExampleCustomGameDocId.value = customGameId;
      showCustomizedGameExampleModal.value = true;
      gameStartSource.value = source;
    }

    function onCustomizedGameExampleClosed() {
      showCustomizedGameExampleModal.value = false;
    }

    function hideOrganizationSubscriptionLoadingModal() {
      showOrganizationSubscriptionLoadingModal.value = false;
    }

    function hideOrganizationSubscriptionSuccessfulModal() {
      showOrganizationSubscriptionSuccessfulModal.value = false;
    }

    function showVideo(type: string) {
      youtubeVideoId.value = getGameVideoByType(type) ?? '';
      showVideoModal.value = true;
      amplitudeEvent('clickedDemoVideo', {
        location: `Dashboard ${type}`,
        eventTime: new Date(Date.now()).toISOString()
      });
    }

    function hideVideo() {
      showVideoModal.value = false;
    }

    function hideSignupSuccessModal() {
      showSignupSuccessModal.value = false;
    }

    function goToPricing(source: string) {
      router.push({
        name: 'Pricing',
        query: { source: source }
      });
    }

    async function onBeginnerIntroductionStart() {
      showBeginnerIntroductionModal.value = false;
      if (customizedGameType.value !== '') {
        const launchOptions: LaunchOptions = {
          activityType: ActivityType.GAME,
          chosenGame: GameName.DRAW_IT,
          createGameSource: gameStartSource.value ?? null,
          customGameId: customizedGameSettingsCustomGameDocId.value,
          customGameName: customizedGameSettingsCustomGameDocName.value
        };
        if (!hasActiveGame()) {
          await onCustomGameStart({
            ...launchOptions,
            subscriptionLevel: subscriptionDetails.value.subscriptionLevel,
            zoomClientConfig: store.state.zoomClientStatus,
            startFresh: false,
            trackId: selectedMeditationTrackId.value,
            customGameId: customizedGameSettingsCustomGameDocId.value,
            customGameName: customizedGameSettingsCustomGameDocName.value
          });
        } else {
          modalTypeShown.value = [
            'launch',
            {
              ...launchOptions,
              trackId: selectedMeditationTrackId.value
            }
          ];
        }
      } else {
        const launchOptions: LaunchOptions = {
          activityType: ActivityType.GAME,
          chosenGame: GameName.DRAW_IT,
          createGameSource: gameStartSource.value ?? null
        };
        if (!hasActiveGame()) {
          await onGameStart({
            ...launchOptions,
            subscriptionLevel: subscriptionDetails.value.subscriptionLevel,
            zoomClientConfig: store.state.zoomClientStatus,
            startFresh: false,
            trackId: selectedMeditationTrackId.value
          });
        } else {
          modalTypeShown.value = [
            'launch',
            {
              ...launchOptions,
              trackId: selectedMeditationTrackId.value
            }
          ];
        }
      }
    }

    function onLaunchModal(event) {
      modalTypeShown.value = ['launch', event];
    }

    function interactTypeformPanel() {
      firebase
        .firestore()
        .collection('minigame_user')
        .doc(user.value.uid)
        .update({
          closedTypeformSurvey1: true
        });
    }

    function onTab(tab: string) {
      router.push({ query: { tab: tab } });
      if (tab === NavigationTabs.HOME || tab === NavigationTabs.LEADERBOARD)
        goToScroll();
    }

    function saveScrollPosition(pos: number) {
      scrollPosition.value = pos;
    }

    function goToScroll() {
      setTimeout(() => {
        window.scrollTo(0, scrollPosition.value);
      }, 50);
    }

    onMounted(() => {
      document
        .getElementById('app')
        ?.setAttribute(
          'style',
          'min-height:100vh; display:flex; flex-direction:column;'
        );
      window.onpopstate = goToScroll;
    });

    return {
      ...userCustomizedGamesQuery,
      modalTypeShown,
      SubscriptionInterval,
      //
      userDoc,
      user,
      isUserUsingCustomGameRoomId,
      activeGameName,
      isActiveRoomClosed,
      activeGameRoomId,
      subscriptionDetails,
      isTeamAccount,
      teamRole,
      TeamRole,
      freeTrialEnded,
      showInitialLoading,
      freeTrialEndedCompletely,
      subscriptionEnded,
      NavigationTabs,
      showFreeTrialEndedBar,
      showFreeTrialEndingBar,
      //
      startGame,
      viewDetails,
      settingsType,
      onLaunchModal,
      //
      showAboutModal,
      showAbout,
      onAboutClosed,
      showGameVersionModal,
      showGameVersion,
      onGameVersionClosed,
      aboutType,
      showCustomIdBlockModal,

      showRestrictedGameHostBlockModal,
      showCustomizedGameStartBlockModal,
      showMeteredWarningDialog,
      //
      showAccountSettings,
      showAccountSettingsModal,
      onAccountSettingsClosed,
      onShowBlockFreePlanCustomIdModal,
      //
      showMobileNavModal,
      showMobileNav,
      onMobileNavClosed,
      //
      onLeftNavBarTab,
      //
      showTypeformSurvey1,
      openTypeformSurvey1,
      interactTypeformPanel,
      //
      showCustomizedModal,
      showCustomized,
      onCustomizedClosed,
      //,
      onDuplicateCustomizedGame,
      showDuplicateSuccessModal,
      openDuplicateSuccessModal,
      closeDuplicateSuccessModal,
      //
      showMobileScreenUnsupportedModal,
      //
      showCustomizedGameSettings,
      showCustomizedGameSettingsModal,
      onCustomizedGameSettingsClosed,
      customizedGameSettingsCustomGameDocName,
      customizedGameSettingsCustomGameDocId,
      //
      startCustomizedGame,
      //
      showCustomizedGameExample,
      showCustomizedGameExampleModal,
      onCustomizedGameExampleClosed,
      customizedGameSettingsExampleType,
      customizedGameSettingsExampleCustomGameDocId,
      //
      showOrganizationSubscriptionLoadingModal,
      hideOrganizationSubscriptionLoadingModal,
      //
      showOrganizationSubscriptionSuccessfulModal,
      hideOrganizationSubscriptionSuccessfulModal,
      //
      showVideoModal,
      showVideo,
      hideVideo,
      youtubeVideoId,
      //
      onTab,
      digitOnlyGameRoomId,
      tab,
      titleAndDescription,
      selectedType,
      hasNavBack,
      onBack,
      //
      goToPricing,

      //
      showBeginnerIntroductionModal,
      onBeginnerIntroductionStart,
      //
      saveScrollPosition,
      goToScroll,
      //
      isFreePlan,
      isFreeTrialPlan,
      remainingFreeTrialDays,
      isLitePlan,
      isLevel2Plan,
      //
      teamErrorDescription,
      teamSuccessDescription,
      showTeamModal,
      //
      onLeaveTeam,
      showConfirmLeaveTeamModal,
      openConfirmLeaveTeamModal,
      closeConfirmLeaveTeamModal,
      //
      onnboardingVideoIndex,
      openOnboardingVideoModal,
      closeOnboardingVideoModal,
      onCreateCustomizedGame,
      nBeginGames,

      //
      historyGame,
      showHistoryModal,
      openHistoryModal,
      onHistoryModalClosed,
      //
      showHistoryUpgradePlanModal,
      //
      openHistoryUpgradePlanModal,
      onHistoryUpgradePlanModalClosed,
      //
      showSignupSuccessModal,
      hideSignupSuccessModal
    };
  }
});
