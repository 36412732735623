
import useFirebaseCollection from '@/hooks/firebase/useFirebaseCollection';
import { computed, defineComponent, ref } from 'vue';
import { useStore } from '@/store';
import moment from 'moment';
import Datepicker from 'vuejs3-datepicker';
import { amplitudeEvent } from '@/config/amplitudeConfig';
import { TeamRole } from '@/models/TeamRole';

export default defineComponent({
  name: 'UsageReport',
  components: {
    Datepicker
  },
  props: {},
  emits: [],
  setup() {
    const store = useStore();
    const user = computed(() => store.getters.getUser);

    const gameData = ref({});
    const gameEnabledYears = ref([] as number[]);

    const slackCollectionData = ref([] as any[]);
    const selectedYear = ref(new Date());

    const isLoading = ref(true);
    const noData = ref(false);

    const slackHistoryQuery = useFirebaseCollection(`stripe_metered_usage`, {
      onMounted: false
    });
    const subscriptionDetails = computed(
      () => store.getters.getSubscriptionDetails
    );
    const teamRole = computed(() => subscriptionDetails.value.teamRole);
    const teamInfo = computed(() => store.getters.getTeamInfo);
    async function getGameHistory() {
      amplitudeEvent('visitUsageReport', {
        eventTime: new Date(Date.now()).toISOString()
      });

      gameEnabledYears.value = [];
      let owner: string;
      if (!teamRole.value || teamRole.value == TeamRole.Owner) {
        owner = user.value.uid;
      } else {
        owner = teamInfo.value?.owner;
      }
      await slackHistoryQuery.getCollection({
        fieldPath: 'owner',
        opStr: '==',
        value: owner
      });

      if (slackHistoryQuery.collectionData.value?.length === 0) {
        slackCollectionData.value = [];
      } else {
        const slackHistoryPeriodQuery = useFirebaseCollection(
          `stripe_metered_usage/${
            slackHistoryQuery.collectionData.value![0].id
          }/periods`,
          {
            onMounted: false
          }
        );
        await slackHistoryPeriodQuery.getCollection({
          orderBy: 'currentPeriodEnd',
          isDesc: true
        });
        slackCollectionData.value = Object.assign(
          [],
          slackHistoryPeriodQuery.collectionData.value
        );
        for (let i = 0; i < slackCollectionData.value.length; i++) {
          const slackHistoryPeriodParticipantQuery = useFirebaseCollection(
            `stripe_metered_usage/${
              slackHistoryQuery.collectionData.value![0].id
            }/periods/${slackCollectionData.value[i].id}/participants`,
            {
              onMounted: false
            }
          );
          await slackHistoryPeriodParticipantQuery.getCollection({});
          slackCollectionData.value[i].participants =
            slackHistoryPeriodParticipantQuery.collectionData.value;
        }
      }

      getHistoryFiltered();

      isLoading.value = false;
      noData.value = slackCollectionData.value.length === 0;
    }

    async function getHistoryFiltered() {
      gameData.value = {};

      const yearDate = moment(
        new Date(moment(selectedYear.value).year(), 0, 1)
      );

      const dateFilteredSlackCollectionData = slackCollectionData.value.filter(
        data => {
          const currentPeriodStart = moment(data.currentPeriodStart.toDate());
          const currentPeriodEnd = moment(data.currentPeriodEnd.toDate());
          return (
            (currentPeriodStart.isSameOrBefore(yearDate) &&
              currentPeriodEnd.isSameOrAfter(yearDate)) ||
            currentPeriodStart.year() === yearDate.year() ||
            currentPeriodEnd.year() === yearDate.year()
          );
        }
      );

      dateFilteredSlackCollectionData.map(data => {
        const monthDate = moment(data.currentPeriodStart.toDate());
        const monthDateString =
          moment(data.currentPeriodStart.toDate()).format('MMM DD') +
          ' - ' +
          moment(data.currentPeriodEnd.toDate()).format('MMM DD');
        gameData.value[monthDateString] = {
          games: {},
          players: {},
          channels: {},
          members: {}
        };
        data.participants.map(player => {
          if (player.usageType === 'slack_member_added') {
            gameData.value[monthDateString].channels[player.channelId] = true;
            gameData.value[monthDateString].members[player.email] = true;
          } else {
            gameData.value[monthDateString].games[player.dateCreated] = true;
            gameData.value[monthDateString].players[player.email] = true;
          }
          if (!gameEnabledYears.value.includes(monthDate.year())) {
            gameEnabledYears.value.push(monthDate.year());
          }
        });
      });
    }

    getGameHistory();

    const disabledDates = {
      customPredictor: (date: Date) => {
        return !gameEnabledYears.value.includes(date.getFullYear());
      }
    };

    function onYear(date: Date) {
      selectedYear.value = date;
      getHistoryFiltered();
    }

    return {
      gameData,
      gameEnabledYears,
      disabledDates,
      selectedYear,
      onYear,
      isLoading,
      noData
    };
  }
});
