import { commaAndSeparatedString } from './helper';
import countries from 'countries-and-timezones';
export function timeFields() {
  const options: any[] = [];
  for (let i = 0; i < 24; i++) {
    let digit: number = i;
    let suffix: string;

    if (i < 12) {
      suffix = 'am';
    } else {
      suffix = 'pm';
    }
    if (i == 0) digit = 12;
    if (i > 12) digit = i - 12;

    options.push({ text: `${digit}:00${suffix}`, value: i * 60 });
  }
  return options;
}
export function timezoneFields() {
  const options: any[] = [];

  const tz = countries.getAllTimezones();
  const timezones = Object.entries(tz).map(z => z[0]);
  timezones.forEach(zone => {
    const processedText = zone.replaceAll('_', ' ');
    options.push({ text: zone, value: zone, processedText: processedText });
  });

  return options;
}
export function durationFields() {
  const options: any[] = [];

  for (let i = 1; i <= 23; i++) {
    const text = i == 1 ? `1 hour` : `${i} hours`;
    options.push({ text: text, value: i });
  }

  return options;
}
export function localTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}
export function localTimezoneIndex() {
  const localZone = localTimezone();
  const zones: any[] = timezoneFields();

  const index = zones.findIndex(val => {
    return val.text == localZone;
  });

  if (index == -1) {
    const depLocalZone = countries.getTimezone(localZone);
    if (depLocalZone?.aliasOf) {
      const newIndex = zones.findIndex(val => {
        return val.text == depLocalZone?.aliasOf;
      });

      if (newIndex == -1) return 0;
      return newIndex;
    }
    return 0;
  }
  return index;
}
const containsAllWeekdays = (i: number, currentDayOption) =>
  [7 * i + 0, 7 * i + 1, 7 * i + 2, 7 * i + 3, 7 * i + 4].every(element => {
    return currentDayOption.includes(element);
  }) &&
  ![7 * i + 5, 7 * i + 6].some(element => {
    return currentDayOption.includes(element);
  });
const containsAllWeek = (i: number, currentDayOption) =>
  [
    7 * i + 0,
    7 * i + 1,
    7 * i + 2,
    7 * i + 3,
    7 * i + 4,
    7 * i + 5,
    7 * i + 6
  ].every(element => {
    return currentDayOption.includes(element);
  });
const containsWeek = (i: number, currentDayOption) =>
  [7 * i + 0, 7 * i + 1, 7 * i + 2, 7 * i + 3, 7 * i + 4].some(element => {
    return currentDayOption.includes(element);
  });
// const noDaysSelected = (i: number) =>
//   ![7 * i + 0, 7 * i + 1, 7 * i + 2, 7 * i + 3, 7 * i + 4].some(
//     element => {
//       return currentDayOption.value.includes(element);
//     }
//   );

const isWeekdaysText = (weekOption, currentDayOption) => {
  if (weekOption === 0) return true;
  if (weekOption == 1 && containsAllWeekdays(0, currentDayOption)) return true;
  return false;
};
const isEverydayText = (weekOption, currentDayOption) => {
  if (weekOption == 1 && containsAllWeek(0, currentDayOption)) return true;
  return false;
};
const withinOneWeek = (weekOption, currentDayOption) => {
  if (weekOption == 0) return true;
  let containsInAtleastOneWeek = false;
  const days: Array<any> = [];
  for (let i = 0; i < weekOption; i++) {
    if (containsWeek(i, currentDayOption)) {
      if (containsInAtleastOneWeek) return null;
      containsInAtleastOneWeek = true;

      for (let j = 0; j < currentDayOption.length; j++) {
        const day = currentDayOption[j];
        if (day < 7 * i) continue;
        else if (day >= 7 * i + 7) break;

        if (day % 7 == 0) days.push('Mon');
        else if (day % 7 == 1) days.push('Tue');
        else if (day % 7 == 2) days.push('Wed');
        else if (day % 7 == 3) days.push('Thu');
        else if (day % 7 == 4) days.push('Fri');
        else if (day % 7 == 5) days.push('Sat');
        else if (day % 7 == 6) days.push('Sun');
      }
    }
  }
  if (containsInAtleastOneWeek) return days;
  return null;
};
export function evaluateFrequencyText(weekOption, currentDayOption) {
  if (isEverydayText(weekOption, currentDayOption)) {
    return 'Everyday';
  } else if (isWeekdaysText(weekOption, currentDayOption)) {
    return 'On weekdays';
  } else if (weekOption == 1) {
    const str = `On`;
    const days: Array<any> = [];
    for (let i = 0; i < currentDayOption.length; i++) {
      const day = currentDayOption[i];
      if (day == 0) days.push('Mon');
      else if (day == 1) days.push('Tue');
      else if (day == 2) days.push('Wed');
      else if (day == 3) days.push('Thu');
      else if (day == 4) days.push('Fri');
      else if (day == 5) days.push('Sat');
      else if (day == 6) days.push('Sun');
    }
    return `${str} ${commaAndSeparatedString(days)}`;
  } else {
    const str = `Every ${weekOption} weeks on`;
    const withinOneWeekRes = withinOneWeek(weekOption, currentDayOption);

    if (!withinOneWeekRes) return `${str} on various days`;
    return `${str} ${commaAndSeparatedString(withinOneWeekRes)}`;
  }
}
// const isIcebreakerActive = ref(false);
export const slackWeekOptions = [
  //   { text: 'On weekdays', value: 0 },
  { text: 'Every week', value: 1 },
  { text: 'Every 2 weeks', value: 2 },
  { text: 'Every 3 weeks', value: 3 },
  { text: 'Every 4 weeks', value: 4 }
];
export const slackDayOptions = [
  { text: 'Monday', label: 'M', value: 1 },
  { text: 'Tuesday', label: 'T', value: 2 },
  { text: 'Wednesday', label: 'W', value: 3 },
  { text: 'Thursday', label: 'T', value: 4 },
  { text: 'Friday', label: 'F', value: 5 },
  { text: 'Saturday', label: 'S', value: 6 },
  { text: 'Sunday', label: 'S', value: 7 }
];
export const icebreakerDefaultFrequency = 'weekly';
export const icebreakerDefaultWeekFrequency = slackWeekOptions[0].value;
export const icebreakerDefaultSelectedDays = [0, 2, 4];
export const icebreakerDefaultTime = timeFields()[11].value;

export const scheduleGamesDefaultFrequency = 'weekly';
export const scheduleGamesDefaultWeekFrequency = slackWeekOptions[0].value;
export const scheduleGamesDefaultSelectedDays = [4];
export const scheduleGamesDefaultTime = timeFields()[15].value;
export const scheduleGamesDefaultDuration = 2;

export const knowYouDefaultFrequency = 'weekly';
export const knowYouDefaultWeekFrequency = slackWeekOptions[1].value;
export const knowYouDefaultSelectedDays = [1];
export const knowYouDefaultTime = timeFields()[11].value;

export const slackBotScopes = [
  // 'channels:manage',
  // 'channels:join',
  'channels:history',
  'channels:read',
  'chat:write',
  'im:history',
  'users:read',
  'users:read.email',
  'chat:write.customize'
];

export const slackUserScopes = ['chat:write', 'channels:write'];

function isEligibleForRun(
  createDateMilliseconds: number,
  timeZone: string,
  createDay: number,
  weekFrequency: number,
  selectedDays: Array<number>,
  today: Date,
  time: number
) {
  const createDate = new Date(
    new Date(createDateMilliseconds).toLocaleString('en-US', {
      timeZone: timeZone
    })
  );
  today = new Date(today.toLocaleString('en-US', { timeZone: timeZone }));
  const createDateTodayTime = new Date(
    createDate.getFullYear(),
    createDate.getMonth(),
    createDate.getDate(),
    time / 60,
    time % 60,
    0
  );
  const todayWithGivenTime = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    time / 60,
    time % 60,
    0
  );

  const timeDifference =
    todayWithGivenTime.getTime() - createDateTodayTime.getTime();
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
  const daysDifferenceFloor = Math.floor(daysDifference);

  const diff = daysDifferenceFloor + createDay;
  const weekIndex = Math.floor(diff / 7) % weekFrequency;
  const dayIndex = diff % 7;
  const currentDayIndex = weekIndex * 7 + dayIndex;
  let eligible;
  if (selectedDays.includes(currentDayIndex)) {
    // console.log(`ELIGIBLE for ${today.toString()}`);
    eligible = true;
  } else {
    // console.log(`NOT ELIGIBLE for ${today.toString()}`);
    eligible = false;
  }

  //   console.log({
  //     timeZone: timeZone,
  //     createDay: createDay,
  //     weekFrequency: weekFrequency,
  //     selectedDays: selectedDays,
  //     today: today.toString(),
  //     todayWithGivenTime: todayWithGivenTime.toString(),
  //     createDate: createDate.toString(),
  //     createDateTodayTime: createDateTodayTime.toString(),
  //     daysDifference: daysDifference,
  //     daysDifferenceFloor: daysDifferenceFloor,
  //     eligible: eligible
  //   });
  return eligible;
}

export function slackNextJobRun(
  createDateMilliseconds: number,
  timeZone: string,
  createDay: number,
  weekFrequency: number,
  selectedDays: Array<number>,
  today: Date,
  time: number
) {
  for (let i = 0; i < 28; i++) {
    let eligible = isEligibleForRun(
      createDateMilliseconds,
      timeZone,
      createDay,
      weekFrequency,
      selectedDays,
      today,
      time
    );
    if (i == 0 && time < today.getHours() * 60 + today.getMinutes())
      eligible = false;
    if (eligible) return today;
    today.setDate(today.getDate() + 1);
  }
}
