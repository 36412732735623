import { RecommendedGameInfo } from '@/models/RecommendedGameInfo';
import { SubscriptionInterval } from '@/models/SubscriptionInterval';
import { SubscriptionLevel } from '@/models/SubscriptionLevel';
import { amplitudeEvent, identifyAmplitude } from '@/config/amplitudeConfig';
import firebaseApp from '@/firebaseApp';
import usePostAPI from '@/hooks/usePostAPI';
import moment from 'moment';
import {
  cardGameTypes,
  getGameTitleByType,
  scoreGameTypes
} from './gameConstants';
import { State, store } from '@/store';
import { Store } from 'vuex';
import zoomSdk from '@zoom/appssdk';
const crypto = require('crypto');
const base64url = require('base64url');
import {
  UPDATE_ACTIVEGAMENAME,
  UPDATE_ACTIVEGAMEROOMID,
  UPDATE_ISUSERUSINGCUSTOMGAMEROOMID,
  UPDATE_LOBBY_DOC,
  UPDATE_SLACK_CHANNELS,
  UPDATE_SLACK_STATUS
} from '@/store/mutation-types';
import firebase from 'firebase';
import { LivePollType } from './live_poll_enums';
import {
  CustomGameStartOptions,
  GameStartOptions
} from '@/models/GameStartOptions';
import { GameName } from './gamenames_enums';
import axios from 'axios';
import { LivePollParticipant } from '@/models/event_tools/live_poll/live_poll_model';
import { QnaParticipant } from '@/models/event_tools/qna/qna';
import { WheelParticipant } from '@/models/event_tools/spin_the_wheel/spin_the_wheel_model';
/// determine the environment
export const isPlayground =
  window.location.hostname.includes('localhost') ||
  window.location.hostname.includes('ngrok.io') ||
  window.location.hostname == '' ||
  window.location.hostname == '127.0.0.1';
export const isDev = window.location.hostname.includes('orric.app');
export const isProd = window.location.hostname.includes('brightful.me');
const playground = 'https://upcentral-prototype.appspot.com';
const dev = 'https://api.orric.app';
const prod = 'https://api.brightful.me';

export function getEndpoint(
  endpoint: string,
  prefix: string = '/api/minigame/'
) {
  let apiUrl = '';
  if (isPlayground) {
    apiUrl = playground + prefix + endpoint;
  } else if (isDev) {
    apiUrl = dev + prefix + endpoint;
  } else if (isProd) {
    apiUrl = prod + prefix + endpoint;
  }
  return apiUrl;
}

export function redirectToPlay(source: string) {
  if (isPlayground) console.log(`redirectToPlay + ${source}`);
  else if (isDev)
    window.location.href = `https://www.orric.app/play/?source=${source}`;
  else if (isProd)
    window.location.href = `https://www.brightful.me/play/?source=${source}`;
}

export function redirectToLive(source: string) {
  if (isPlayground) console.log(`redirectToLive + ${source}`);
  else if (isDev)
    window.location.href = `https://www.orric.app/live/?source=${source}`;
  else if (isProd)
    window.location.href = `https://www.brightful.me/live/?source=${source}`;
}

export function redirectToLogin(queryKey?: string, queryValue?: string) {
  console.log('redirect to login');
  if (isPlayground) console.log('redirectToLogin');
  else if (isDev) {
    queryKey === undefined && queryValue === undefined
      ? window.location.replace('https://dev.orric.app/room/auth/login')
      : window.location.replace(
          `https://dev.orric.app/room/auth/login?${queryKey}=${queryValue}`
        );
  } else if (isProd) {
    queryKey === undefined && queryValue === undefined
      ? window.location.replace('https://app.brightful.me/room/auth/login')
      : window.location.replace(
          `https://app.brightful.me/room/auth/login?${queryKey}=${queryValue}`
        );
  }
}

export function hypenDigitGameRoomId(gameRoomId: string) {
  return gameRoomId.length >= 4
    ? gameRoomId.substring(gameRoomId.indexOf('_') + 1).substring(0, 4) +
        '-' +
        gameRoomId.substring(gameRoomId.indexOf('_') + 1).substring(4, 8)
    : gameRoomId;
}

export function getGameRoomLink(roomId: string, isCustomGameRoomId: boolean) {
  const digitOnlyGameRoomId = isCustomGameRoomId
    ? roomId
    : hypenDigitGameRoomId(roomId.substring(roomId.indexOf('_') + 1));
  if (isPlayground) return `http://localhost:54576/room/${digitOnlyGameRoomId}`;
  else if (isDev) return `https://dev.orric.app/room/${digitOnlyGameRoomId}`;
  else return `https://app.brightful.me/room/${digitOnlyGameRoomId}`;
}

export function getBitesLink(id: string) {
  if (isDev) return `https://www.orric.app/bites/${id}`;
  else if (isProd) return `https://www.brightful.me/bites/${id}`;
  else return `redirectToBites + ${id}`;
}

export function redirectToGameRoom(
  roomId: string,
  isCustomGameRoomId: boolean,
  openNewTab: boolean = false
) {
  const link = getGameRoomLink(roomId, isCustomGameRoomId);
  if (!openNewTab) {
    window.location.href = link;
  } else {
    window.open(link, '_blank');
  }
}

export async function closeTab(lobbyId: string, fromEndGame: boolean = false) {
  if (fromEndGame) {
    const lobbyref = firebaseApp
      .firestore()
      .collection('/minigame_lobbies/')
      .doc(lobbyId);
    await lobbyref.update({
      showFreeTrialPopupStatus: 'go_back_to_dashboard'
    });
  }
  window.close();
}

export function removeDuplicates(arr: any[], equals: Function) {
  const newArr: any[] = [];
  const originalArr = arr.slice(0);
  let i, len, val: any;

  for (i = 0, len = originalArr.length; i < len; ++i) {
    val = originalArr[i];
    if (
      !newArr.some(function(item) {
        return equals(item, val);
      })
    ) {
      newArr.push(val);
    }
  }
  return newArr;
}

export function copyToClipboard(textToCopy: string) {
  let textArea: any;

  function isOS() {
    //can use a better detection logic here
    return navigator.userAgent.match(/ipad|iphone/i);
  }

  function createTextArea(text: string) {
    textArea = document.createElement('textArea');
    textArea.readOnly = true;
    textArea.contentEditable = true;
    textArea.value = text;
    document.body.appendChild(textArea);
  }

  function selectText() {
    let range: any, selection: any;

    if (isOS()) {
      range = document.createRange();
      range.selectNodeContents(textArea);
      selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      textArea.setSelectionRange(0, 999999);
    } else {
      textArea.select();
    }
  }

  function copyTo() {
    document.execCommand('copy');
    document.body.removeChild(textArea);
  }

  createTextArea(textToCopy);
  selectText();
  copyTo();
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getCookie(cname: string) {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function setCookie(cname: string, cvalue: string, daysToExpire: number) {
  let domain;
  const d = new Date();
  d.setTime(d.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  if (isPlayground) {
    domain = 'localhost';
  } else if (isDev) {
    domain = 'orric.app';
  } else if (isProd) {
    domain = 'brightful.me';
  }
  document.cookie =
    cname +
    '=' +
    cvalue +
    ';' +
    `domain=${domain};` +
    expires +
    ';path=/' +
    ';secure';
}

export function filterBadWords(str: string) {
  const Filter = require('bad-words'),
    filter = new Filter();

  return filter.clean(str);
}

export function containsProfaneWord(str: string) {
  const Filter = require('bad-words'),
    filter = new Filter();
  return filter.isProfane(str);
}

export async function completeLobbyFromLobbyId(lobbyId: string) {
  const lobbyref = firebaseApp
    .firestore()
    .collection('/minigame_lobbies/')
    .doc(lobbyId);
  const lobbySnapshot = await lobbyref.get();
  if (!lobbySnapshot.exists) {
    return null;
  }
  const lobby = lobbySnapshot.data()!;
  const playersSnaps = await lobbyref.collection('players').get();
  const accessListSnaps = await lobbyref.collection('access_list').get();
  const players: any = {};
  const accessList: any = {};
  playersSnaps.docs.forEach(doc => {
    players[doc.id] = doc.data();
  });
  accessListSnaps.docs.forEach(doc => {
    accessList[doc.id] = doc.data();
  });
  lobby['players'] = players;
  lobby['access_list'] = accessList;
  return lobby;
}
export async function getLobbyDoc(lobbyId: string) {
  const lobbyref = firebaseApp
    .firestore()
    .collection('/minigame_lobbies/')
    .doc(lobbyId);
  const lobbySnapshot = await lobbyref.get();
  if (!lobbySnapshot.exists) {
    return null;
  }
  const lobby = lobbySnapshot.data()!;
  return lobby;
}

export async function isInvalidEducationEmail(email: string) {
  const domain = email.substring(email.indexOf('@') + 1);
  const whitelistDoc = (
    await firebaseApp
      .firestore()
      .collection('minigame_edu_email_whitelist')
      .doc('whitelist')
      .get()
  ).data()!;
  const whiteListRegex = whitelistDoc['regex'];
  const whiteListDomains = whitelistDoc['domain'];
  const matchingPattern = new RegExp(whiteListRegex);
  const notFoundInRegex = !(domain.match(matchingPattern) !== null);
  const notFoundInWhitelist = !whiteListDomains.includes(domain);
  return notFoundInRegex && notFoundInWhitelist;
}

export function isGameRoomOpen(lobby: any) {
  const lobbyIsClosed = lobby['isClosed'];

  const playerIds = lobby['playerIds'] ?? [];
  return !lobbyIsClosed && playerIds.length != 0;
}

export function getTriggeredRole(isHost: boolean) {
  if (isHost) return 'host';
  return 'participant';
}

export function arrayRemove(arr: Array<string>, value: string) {
  return arr.filter(function(ele) {
    return ele != value;
  });
}
export interface ZoomClientConfigInterface {
  inZoomClient: Boolean;
  isHost: Boolean;
  uid: string | undefined | null;
  mid: string | undefined | null;
  meetingJoinUrl: string | undefined | null;
  hostName: string | undefined | null;
}
export default class ZoomClientConfig implements ZoomClientConfigInterface {
  inZoomClient: Boolean;
  isHost: Boolean;
  uid: string | undefined | null;
  mid: string | undefined | null;
  meetingJoinUrl: string | undefined | null;
  hostName: string | undefined | null;

  constructor(
    inZoomClient: Boolean,
    isHost: Boolean,
    uid: string | undefined | null,
    mid: string | undefined | null,
    meetingJoinUrl: string | undefined | null,
    hostName: string | undefined | null
  ) {
    this.inZoomClient = inZoomClient;
    this.isHost = isHost;
    this.uid = uid;
    this.mid = mid;
    this.meetingJoinUrl = meetingJoinUrl;
    this.hostName = hostName;
  }
}
export async function onGameStart(options: GameStartOptions) {
  try {
    const lobby = store.getters.getLobbyDoc;
    const customGameRoomId = lobby['customGameRoomId'] ?? null;

    const response = await usePostAPI(getEndpoint('create'), options);
    const result = response.data;
    const gameRoomId = result['gameRoomId'];
    identifyAmplitude('setOnce', 'firstExperience', 'Host');
    amplitudeEvent('createGame', {
      game: options.chosenGame,
      gameRoomId: gameRoomId,
      version: 'regular',
      subscriptionLevel: options.subscriptionLevel,
      customizedGameName: 'None',
      customizedGameId: 'None',
      eventTime: new Date(Date.now()).toISOString()
    });
    if (
      options.zoomClientConfig?.inZoomClient &&
      options.zoomClientConfig?.mid
    ) {
      const midDoc = await firebaseApp
        .firestore()
        .collection('zoom_meetings')
        .where('mid', '==', options.zoomClientConfig.mid)
        .limit(1)
        .get();
      let ref: firebaseApp.firestore.DocumentReference;
      if (midDoc.docs.length != 0) {
        ref = midDoc.docs[0].ref;
      } else {
        ref = firebaseApp
          .firestore()
          .collection(`zoom_meetings`)
          .doc();
      }
      await ref.set({
        mid: options.zoomClientConfig.mid,
        host: options.zoomClientConfig.uid,
        gameRoomId: gameRoomId,
        dateCreated: firebaseApp.firestore.FieldValue.serverTimestamp()
      });

      await zoomSdk.sendAppInvitationToAllParticipants();
    }

    redirectToGameRoom(
      customGameRoomId ?? gameRoomId,
      customGameRoomId !== null
    );
  } catch (err) {
    console.log(err);
  }
}

export async function onCustomGameStart(options: CustomGameStartOptions) {
  try {
    const lobby = store.getters.getLobbyDoc;
    const customGameRoomId = lobby['customGameRoomId'] ?? null;
    const response = await usePostAPI(getEndpoint('create'), options);
    const result = response.data;
    const gameRoomId = result['gameRoomId'];
    identifyAmplitude('setOnce', 'firstExperience', 'Host');
    amplitudeEvent('createGame', {
      game: options.chosenGame,
      gameRoomId: gameRoomId,
      version: 'customized',
      subscriptionLevel: options.subscriptionLevel,
      customizedGameName: options.customGameName,
      customizedGameId: options.customGameId,
      source: options.createGameSource ?? 'None',
      eventTime: new Date(Date.now()).toISOString()
    });
    if (
      options.zoomClientConfig &&
      options.zoomClientConfig.inZoomClient &&
      options.zoomClientConfig.mid
    ) {
      const midDoc = await firebaseApp
        .firestore()
        .collection('zoom_meetings')
        .where('mid', '==', options.zoomClientConfig.mid)
        .limit(1)
        .get();
      let ref: firebaseApp.firestore.DocumentReference;
      if (midDoc.docs.length != 0) {
        ref = midDoc.docs[0].ref;
      } else {
        ref = firebaseApp
          .firestore()
          .collection(`zoom_meetings`)
          .doc();
      }
      await ref.set({
        mid: options.zoomClientConfig.mid,
        host: options.zoomClientConfig.uid,
        gameRoomId: gameRoomId,
        dateCreated: firebaseApp.firestore.FieldValue.serverTimestamp()
      });

      await zoomSdk.sendAppInvitationToAllParticipants();
    }

    redirectToGameRoom(
      customGameRoomId ?? gameRoomId,
      customGameRoomId !== null
    );
  } catch (err) {
    console.log(err);
  }
}

export function base64toBlob(data) {
  const imgFormat = data.replaceAll('data:image/', '').split(';')[0];
  const prefix = `data:image/${imgFormat};base64,`;
  const contentType = imgFormat === 'gif' ? 'image/gif' : 'image/jpeg';
  const base64Data = data.replaceAll(prefix, '');
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}

export async function blobToBase64(blob) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export function getTimeZones() {
  return [
    'Europe/Andorra',
    'Asia/Dubai',
    'Asia/Kabul',
    'Europe/Tirane',
    'Asia/Yerevan',
    'Antarctica/Casey',
    'Antarctica/Davis',
    'Antarctica/DumontDUrville', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
    'Antarctica/Mawson',
    'Antarctica/Palmer',
    'Antarctica/Rothera',
    'Antarctica/Syowa',
    'Antarctica/Troll',
    'Antarctica/Vostok',
    'America/Argentina/Buenos_Aires',
    'America/Argentina/Cordoba',
    'America/Argentina/Salta',
    'America/Argentina/Jujuy',
    'America/Argentina/Tucuman',
    'America/Argentina/Catamarca',
    'America/Argentina/La_Rioja',
    'America/Argentina/San_Juan',
    'America/Argentina/Mendoza',
    'America/Argentina/San_Luis',
    'America/Argentina/Rio_Gallegos',
    'America/Argentina/Ushuaia',
    'Pacific/Pago_Pago',
    'Europe/Vienna',
    'Australia/Lord_Howe',
    'Antarctica/Macquarie',
    'Australia/Hobart',
    'Australia/Currie',
    'Australia/Melbourne',
    'Australia/Sydney',
    'Australia/Broken_Hill',
    'Australia/Brisbane',
    'Australia/Lindeman',
    'Australia/Adelaide',
    'Australia/Darwin',
    'Australia/Perth',
    'Australia/Eucla',
    'Asia/Baku',
    'America/Barbados',
    'Asia/Dhaka',
    'Europe/Brussels',
    'Europe/Sofia',
    'Atlantic/Bermuda',
    'Asia/Brunei',
    'America/La_Paz',
    'America/Noronha',
    'America/Belem',
    'America/Fortaleza',
    'America/Recife',
    'America/Araguaina',
    'America/Maceio',
    'America/Bahia',
    'America/Sao_Paulo',
    'America/Campo_Grande',
    'America/Cuiaba',
    'America/Santarem',
    'America/Porto_Velho',
    'America/Boa_Vista',
    'America/Manaus',
    'America/Eirunepe',
    'America/Rio_Branco',
    'America/Nassau',
    'Asia/Thimphu',
    'Europe/Minsk',
    'America/Belize',
    'America/St_Johns',
    'America/Halifax',
    'America/Glace_Bay',
    'America/Moncton',
    'America/Goose_Bay',
    'America/Blanc-Sablon',
    'America/Toronto',
    'America/Nipigon',
    'America/Thunder_Bay',
    'America/Iqaluit',
    'America/Pangnirtung',
    'America/Atikokan',
    'America/Winnipeg',
    'America/Rainy_River',
    'America/Resolute',
    'America/Rankin_Inlet',
    'America/Regina',
    'America/Swift_Current',
    'America/Edmonton',
    'America/Cambridge_Bay',
    'America/Yellowknife',
    'America/Inuvik',
    'America/Creston',
    'America/Dawson_Creek',
    'America/Fort_Nelson',
    'America/Vancouver',
    'America/Whitehorse',
    'America/Dawson',
    'Indian/Cocos',
    'Europe/Zurich',
    'Africa/Abidjan',
    'Pacific/Rarotonga',
    'America/Santiago',
    'America/Punta_Arenas',
    'Pacific/Easter',
    'Asia/Shanghai',
    'Asia/Urumqi',
    'America/Bogota',
    'America/Costa_Rica',
    'America/Havana',
    'Atlantic/Cape_Verde',
    'America/Curacao',
    'Indian/Christmas',
    'Asia/Nicosia',
    'Asia/Famagusta',
    'Europe/Prague',
    'Europe/Berlin',
    'Europe/Copenhagen',
    'America/Santo_Domingo',
    'Africa/Algiers',
    'America/Guayaquil',
    'Pacific/Galapagos',
    'Europe/Tallinn',
    'Africa/Cairo',
    'Africa/El_Aaiun',
    'Europe/Madrid',
    'Africa/Ceuta',
    'Atlantic/Canary',
    'Europe/Helsinki',
    'Pacific/Fiji',
    'Atlantic/Stanley',
    'Pacific/Chuuk',
    'Pacific/Pohnpei',
    'Pacific/Kosrae',
    'Atlantic/Faroe',
    'Europe/Paris',
    'Europe/London',
    'Asia/Tbilisi',
    'America/Cayenne',
    'Africa/Accra',
    'Europe/Gibraltar',
    'America/Godthab',
    'America/Danmarkshavn',
    'America/Scoresbysund',
    'America/Thule',
    'Europe/Athens',
    'Atlantic/South_Georgia',
    'America/Guatemala',
    'Pacific/Guam',
    'Africa/Bissau',
    'America/Guyana',
    'Asia/Hong_Kong',
    'America/Tegucigalpa',
    'America/Port-au-Prince',
    'Europe/Budapest',
    'Asia/Jakarta',
    'Asia/Pontianak',
    'Asia/Makassar',
    'Asia/Jayapura',
    'Europe/Dublin',
    'Asia/Jerusalem',
    'Asia/Kolkata',
    'Indian/Chagos',
    'Asia/Baghdad',
    'Asia/Tehran',
    'Atlantic/Reykjavik',
    'Europe/Rome',
    'America/Jamaica',
    'Asia/Amman',
    'Asia/Tokyo',
    'Africa/Nairobi',
    'Asia/Bishkek',
    'Pacific/Tarawa',
    'Pacific/Enderbury',
    'Pacific/Kiritimati',
    'Asia/Pyongyang',
    'Asia/Seoul',
    'Asia/Almaty',
    'Asia/Qyzylorda',
    'Asia/Qostanay', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
    'Asia/Aqtobe',
    'Asia/Aqtau',
    'Asia/Atyrau',
    'Asia/Oral',
    'Asia/Beirut',
    'Asia/Colombo',
    'Africa/Monrovia',
    'Europe/Vilnius',
    'Europe/Luxembourg',
    'Europe/Riga',
    'Africa/Tripoli',
    'Africa/Casablanca',
    'Europe/Monaco',
    'Europe/Chisinau',
    'Pacific/Majuro',
    'Pacific/Kwajalein',
    'Asia/Yangon',
    'Asia/Ulaanbaatar',
    'Asia/Hovd',
    'Asia/Choibalsan',
    'Asia/Macau',
    'America/Martinique',
    'Europe/Malta',
    'Indian/Mauritius',
    'Indian/Maldives',
    'America/Mexico_City',
    'America/Cancun',
    'America/Merida',
    'America/Monterrey',
    'America/Matamoros',
    'America/Mazatlan',
    'America/Chihuahua',
    'America/Ojinaga',
    'America/Hermosillo',
    'America/Tijuana',
    'America/Bahia_Banderas',
    'Asia/Kuala_Lumpur',
    'Asia/Kuching',
    'Africa/Maputo',
    'Africa/Windhoek',
    'Pacific/Noumea',
    'Pacific/Norfolk',
    'Africa/Lagos',
    'America/Managua',
    'Europe/Amsterdam',
    'Europe/Oslo',
    'Asia/Kathmandu',
    'Pacific/Nauru',
    'Pacific/Niue',
    'Pacific/Auckland',
    'Pacific/Chatham',
    'America/Panama',
    'America/Lima',
    'Pacific/Tahiti',
    'Pacific/Marquesas',
    'Pacific/Gambier',
    'Pacific/Port_Moresby',
    'Pacific/Bougainville',
    'Asia/Manila',
    'Asia/Karachi',
    'Europe/Warsaw',
    'America/Miquelon',
    'Pacific/Pitcairn',
    'America/Puerto_Rico',
    'Asia/Gaza',
    'Asia/Hebron',
    'Europe/Lisbon',
    'Atlantic/Madeira',
    'Atlantic/Azores',
    'Pacific/Palau',
    'America/Asuncion',
    'Asia/Qatar',
    'Indian/Reunion',
    'Europe/Bucharest',
    'Europe/Belgrade',
    'Europe/Kaliningrad',
    'Europe/Moscow',
    'Europe/Simferopol',
    'Europe/Kirov',
    'Europe/Astrakhan',
    'Europe/Volgograd',
    'Europe/Saratov',
    'Europe/Ulyanovsk',
    'Europe/Samara',
    'Asia/Yekaterinburg',
    'Asia/Omsk',
    'Asia/Novosibirsk',
    'Asia/Barnaul',
    'Asia/Tomsk',
    'Asia/Novokuznetsk',
    'Asia/Krasnoyarsk',
    'Asia/Irkutsk',
    'Asia/Chita',
    'Asia/Yakutsk',
    'Asia/Khandyga',
    'Asia/Vladivostok',
    'Asia/Ust-Nera',
    'Asia/Magadan',
    'Asia/Sakhalin',
    'Asia/Srednekolymsk',
    'Asia/Kamchatka',
    'Asia/Anadyr',
    'Asia/Riyadh',
    'Pacific/Guadalcanal',
    'Indian/Mahe',
    'Africa/Khartoum',
    'Europe/Stockholm',
    'Asia/Singapore',
    'America/Paramaribo',
    'Africa/Juba',
    'Africa/Sao_Tome',
    'America/El_Salvador',
    'Asia/Damascus',
    'America/Grand_Turk',
    'Africa/Ndjamena',
    'Indian/Kerguelen',
    'Asia/Bangkok',
    'Asia/Dushanbe',
    'Pacific/Fakaofo',
    'Asia/Dili',
    'Asia/Ashgabat',
    'Africa/Tunis',
    'Pacific/Tongatapu',
    'Europe/Istanbul',
    'America/Port_of_Spain',
    'Pacific/Funafuti',
    'Asia/Taipei',
    'Europe/Kiev',
    'Europe/Uzhgorod',
    'Europe/Zaporozhye',
    'Pacific/Wake',
    'America/New_York',
    'America/Detroit',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Indiana/Indianapolis',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Vevay',
    'America/Chicago',
    'America/Indiana/Tell_City',
    'America/Indiana/Knox',
    'America/Menominee',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/North_Dakota/Beulah',
    'America/Denver',
    'America/Boise',
    'America/Phoenix',
    'America/Los_Angeles',
    'America/Anchorage',
    'America/Juneau',
    'America/Sitka',
    'America/Metlakatla',
    'America/Yakutat',
    'America/Nome',
    'America/Adak',
    'Pacific/Honolulu',
    'America/Montevideo',
    'Asia/Samarkand',
    'Asia/Tashkent',
    'America/Caracas',
    'Asia/Ho_Chi_Minh',
    'Pacific/Efate',
    'Pacific/Wallis',
    'Pacific/Apia',
    'Africa/Johannesburg'
  ];
}

export async function shuffleArray(arr: any[]) {
  arr.sort(() => Math.random() - 0.5);
}

export function questionString(ids: number[]) {
  if (ids.length === 0) {
    return '';
  } else if (ids.length === 1) {
    return `Question ${ids[0]} `;
  } else {
    return (
      'Questions ' +
      ids.slice(0, ids.length - 1).join(', ') +
      ` and ${ids[ids.length - 1]} `
    );
  }
}

export function commaAndSeparatedString(values) {
  let result = '';
  for (let i = 0; i < values.length; i++) {
    if (i == values.length - 1) result += `${values[i]}`;
    else if (i == values.length - 2) {
      result += `${values[i]} & `;
    } else {
      result += `${values[i]}, `;
    }
  }
  return result;
}

export function roundToOneDecimal(score: number) {
  return Math.round(score * 10) / 10;
}

export function subscriptionText(subscriptionDetails) {
  const splitted = subscriptionDetails.subscriptionLevel.split('_');
  if (splitted == undefined) return 'Error';
  else if (
    subscriptionDetails.subscriptionLevel == SubscriptionLevel.ONE_OFF_EVENT
  )
    return 'EVENT';
  else if (subscriptionDetails.subscriptionLevel.includes('_')) {
    return (
      splitted[0][0].toUpperCase() +
      splitted[0].substring(1) +
      ` (${splitted[1][0].toUpperCase() + splitted[1].substring(1)})`
    );
  } else if (splitted.length == 1) return splitted[0].toUpperCase();
  return splitted[0][0].toUpperCase() + splitted[0].substring(1);
}
export function subscriptionIntervalText(subscriptionDetails) {
  if (!subscriptionDetails?.subscriptionInterval) return '';
  const interval = subscriptionDetails.subscriptionInterval as SubscriptionInterval;
  switch (interval) {
    case SubscriptionInterval.MONTH:
      return 'Monthly';
    case SubscriptionInterval.YEAR:
      return 'Annual';
    default:
      return '';
  }
}

export function calculateGameResult(game) {
  const result = {};
  if (scoreGameTypes.includes(game.gameName)) {
    result['scores'] = [] as any[];
    const actualPlayers = Object.assign(
      [],
      game.isHostPlaying === false
        ? game.players.filter(player => player.playerId !== game.host)
        : game.players
    );
    actualPlayers
      .filter(player => {
        return (
          game.isHostPlaying !== false ||
          (game.isHostPlaying === false && game.host !== player.playerId)
        );
      })
      .map(player => {
        let index = result['scores'].findIndex(ranking =>
          player.email
            ? ranking.email === player.email
            : ranking.name.trim() === player.name.trim()
        );
        if (index === -1) {
          result['scores'].push({
            name: player.name.trim(),
            email: player.email,
            score: 0
          });
          index = result['scores'].length - 1;
        }
        if (player.roundDetails) {
          player.roundDetails.map(detail => {
            let score = detail.score;
            if (detail.bonus && detail.bonus.includes('DOUBLEDOWN')) {
              score *= 2;
            }
            if (detail.bonus && detail.bonus.includes('STREAK')) {
              score *= 1.5;
            }
            result['scores'][index].score += score;
          });
        }
        if (player.votePlayer) {
          const votePlayer = actualPlayers.find(
            p => p.playerId === player.votePlayer
          );
          if (votePlayer) {
            let votePlayerIndex = result['scores'].findIndex(
              ranking => ranking.email === votePlayer.email
            );
            if (votePlayerIndex === -1) {
              result['scores'].push({
                name: votePlayer.name.trim(),
                email: votePlayer.email,
                score: 0
              });
              votePlayerIndex = result['scores'].length - 1;
            }
            result['scores'][votePlayerIndex].score += 1;
          }
        }
      });
    result['scores'].sort((a, b) => b.score - a.score);
    result['scores'].map(a => {
      a.score = roundToOneDecimal(a.score);
    });
  } else if (game.gameName === 'werewolf_game') {
    const actualPlayers = Object.assign(
      [],
      game.isHostPlaying === false
        ? game.players.filter(player => player.playerId !== game.host)
        : game.players
    );
    const aliveVillagers = actualPlayers.filter(player => {
      return !player.role.includes('werewolf') && !player.isDead.isDead;
    }).length;
    const aliveWerewolves = actualPlayers.filter(player => {
      return player.role.includes('werewolf') && !player.isDead.isDead;
    }).length;
    let status = 'draw';
    if (aliveWerewolves === 0 && aliveVillagers === 0) {
      status = 'draw';
    } else if (aliveWerewolves === 0) {
      status = 'villager';
    } else {
      status = 'werewolf';
    }

    result['won'] = status;
    result['villager'] = actualPlayers
      .filter(player => {
        return !player.role.includes('werewolf');
      })
      .map(player => {
        return {
          name: player.name.trim(),
          email: player.email
        };
      });
    result['werewolf'] = actualPlayers
      .filter(player => {
        return player.role.includes('werewolf');
      })
      .map(player => {
        return {
          name: player.name.trim(),
          email: player.email
        };
      });
  } else if (game.gameName === 'two_words_game') {
    result['won'] = game.won;
    result['civilian'] = [] as any[];
    result['mimic'] = [] as any[];
    const actualPlayers = Object.assign({}, game.players);
    if (game.isHostPlaying === false) {
      delete actualPlayers[game.host];
    }
    const playerIds = Object.keys(actualPlayers ?? {});
    playerIds.map(playerId => {
      if (actualPlayers[playerId]) {
        const player = actualPlayers[playerId];
        const name = player.name.trim();
        if (player.role.includes('mimic')) {
          result['mimic'].push({
            name: name,
            email: player.email
          });
        } else {
          result['civilian'].push({
            name: name,
            email: player.email
          });
        }
      }
    });
  } else if (game.gameName === 'spot_my_lie') {
    result['scores'] = [] as any[];
    const actualPlayers = Object.assign({}, game.players);
    if (game.isHostPlaying === false) {
      delete actualPlayers[game.host];
    }
    Object.keys(actualPlayers).map(playerId => {
      const player = actualPlayers[playerId];
      const name = player.name.trim();
      result['scores'].push({
        name: name,
        email: player.email,
        score: game.gameDetails.filter(gameDetail => {
          return (
            gameDetail.fakeAnswerPlayers &&
            Object.keys(gameDetail.fakeAnswerPlayers).includes(playerId)
          );
        }).length
      });
    });
    result['scores'].sort((a, b) => b.score - a.score);
  } else if (cardGameTypes.includes(game.gameName)) {
    result['scores'] = [] as any[];
    const actualPlayers = Object.assign({}, game.players);
    if (game.isHostPlaying === false) {
      delete actualPlayers[game.host];
    }
    Object.keys(actualPlayers).map(playerId => {
      const player = actualPlayers[playerId];
      const name = player.name.trim();
      result['scores'].push({
        name: name,
        email: player.email,
        score: 0
      });
      game.roundDetails.map(roundDetail => {
        if (roundDetail.players && roundDetail.players[playerId]) {
          result['scores'][result['scores'].length - 1].score +=
            roundDetail.players[playerId].score ?? 0;
        }
      });
    });
    result['scores'].sort((a, b) => b.score - a.score);
  } else if (game.gameName === 'top5') {
    result['scores'] = [] as any[];
    game.gameDetails.map(detail => {
      Object.values(detail.teamPlayers).map(player => {
        result['scores'].push({
          name: (player as any).name,
          email: (player as any).email,
          score: detail.teamPoint
        });
      });
    });
    result['scores'].sort((a, b) => b.score - a.score);
    let status = 'draw';
    if (game.gameDetails[0].teamPoint === game.gameDetails[1].teamPoint) {
      status = 'draw';
    } else if (game.gameDetails[0].teamPoint > game.gameDetails[1].teamPoint) {
      status = 'blue';
    } else {
      status = 'pink';
    }
    result['won'] = status;
  }
  return result;
}

export function getGameWinners(game) {
  let winners = [] as any[];
  const result = calculateGameResult(game);
  if (
    scoreGameTypes.includes(game.gameName) ||
    cardGameTypes.includes(game.gameName) ||
    game.gameName === 'spot_my_lie' ||
    game.gameName === 'top5'
  ) {
    if (result['scores'].length > 0) {
      const max = result['scores'][0].score;
      winners = result['scores'].map(score => {
        return {
          name: score.name,
          email: score.email,
          score: score.score,
          win: score.score === max ? 1 : 0
        };
      });
    }
  } else if (game.gameName === 'werewolf_game') {
    winners = result['werewolf']
      .map(winner => {
        return {
          name: winner.name,
          email: winner.email,
          win: result['won'] === 'werewolf' ? 1 : 0
        };
      })
      .concat(
        result['villager'].map(winner => {
          return {
            name: winner.name,
            email: winner.email,
            win: result['won'] === 'villager' ? 1 : 0
          };
        })
      );
  } else if (game.gameName === 'two_words_game') {
    winners = result['mimic']
      .map(winner => {
        return {
          name: winner.name,
          email: winner.email,
          win: result['won'] === 'mimic' ? 1 : 0
        };
      })
      .concat(
        result['civilian'].map(winner => {
          return {
            name: winner.name,
            email: winner.email,
            win: result['won'] === 'civilian' ? 1 : 0
          };
        })
      );
  }
  return winners;
}

export async function handleSlackStatus(store: Store<State>, uid, slackUid) {
  let isAuthenticated = false;
  let channelCreated = false;
  try {
    console.log('...');
    console.log(store.state.slackStatus);
    if (store.state.slackStatus === undefined) {
      console.log(slackUid);
      if (!slackUid) {
        store.commit(UPDATE_SLACK_STATUS, {
          newSlackStatus: {
            isAuthenticated: false
          }
        });

        isAuthenticated = false;
      } else {
        const slackAppDoc = firebaseApp
          .firestore()
          .collection(`slack_app`)
          .doc(slackUid);
        const slackApp = await slackAppDoc.get();
        const authResponse = await usePostAPI(
          getEndpoint('slackAuthStatus', '/api/slackapp/'),
          {}
        );
        if (authResponse.status == 200 && slackApp.exists) {
          const res = await getAndStoreSlackStatus(store, slackApp, uid);
          isAuthenticated = res?.isAuthenticated!;
          channelCreated = res?.channelCreated!;
        } else {
          store.commit(UPDATE_SLACK_STATUS, {
            newSlackStatus: {
              isAuthenticated: false
            }
          });

          isAuthenticated = false;
        }
      }
    } else {
      isAuthenticated = store.state.slackStatus['isAuthenticated'];
      channelCreated = store.state.slackStatus['hasActiveChannel'];
    }
    if (isAuthenticated) {
      if (store.state.slackChannels == undefined) {
        // get slack channels
        await getAndStoreChannels(store);
      }
    }
  } catch (err) {
    console.log(`Error in SlackApp handleSignedInUser: ${err}`);
  }
  return { isAuthenticated: isAuthenticated, channelCreated: channelCreated };
}

export async function getAndStoreSlackStatus(
  store: Store<State>,
  slackApp: any | undefined,
  uid
) {
  let isAuthenticated = false;
  let channelCreated = false;
  const snapshots = await firebaseApp
    .firestore()
    .collection(`slack_app`)
    .where('firebaseUid', '==', uid)
    .limit(1)
    .get();
  if (snapshots.empty || snapshots.docs.length == 0) {
    console.info(`slackUid not found`);
    return;
  }
  const slackUid = snapshots.docs[0].id;
  const slackAppDoc = firebaseApp
    .firestore()
    .collection(`slack_app`)
    .doc(slackUid);
  if (!slackApp) slackApp = await slackAppDoc.get();
  const slackData = slackApp.data()!;
  const selectedChannel = slackData.selectedChannel;
  const isInactive = slackData.isInactive ?? false;
  const accessToken = slackData.accessToken;
  const hasActiveChannel =
    selectedChannel != null && selectedChannel != undefined;
  const hasAuthenticated = accessToken != null && accessToken != undefined;
  store.commit(UPDATE_SLACK_STATUS, {
    newSlackStatus: {
      isAuthenticated: hasAuthenticated,
      isInactive: isInactive,
      hasActiveChannel: hasActiveChannel,
      selectedChannel: selectedChannel,
      currentIcebreakerOptions: slackData.currentIcebreakerOptions,
      currentKnowYouOptions: slackData.currentKnowYouOptions,
      currentScheduleGamesOptions: slackData.currentScheduleGamesOptions
    }
  });
  isAuthenticated = hasAuthenticated;
  channelCreated = hasActiveChannel;
  return { isAuthenticated: isAuthenticated, channelCreated: channelCreated };
}

export async function getAndStoreChannels(store: Store<State>) {
  const channelsResponse = await usePostAPI(
    getEndpoint('slackChannels', '/api/slackapp/'),
    {}
  );
  if (channelsResponse.status == 200 && channelsResponse.data.channels) {
    store.commit(UPDATE_SLACK_CHANNELS, {
      newSlackChannels: channelsResponse.data.channels,
      reservedChannels: channelsResponse.data.reservedChannels
    });
  }
}

export const getPeriodEndDate = function(stripeCurrentPeriodEnd) {
  try {
    if (stripeCurrentPeriodEnd) {
      const subscriptionPeriodEndDate = new Date(stripeCurrentPeriodEnd);
      const month = subscriptionPeriodEndDate.toLocaleString('default', {
        month: 'short'
      });
      const date = subscriptionPeriodEndDate.getDate();
      const year = subscriptionPeriodEndDate.getFullYear();
      return `${month} ${date}, ${year}`;
    }

    return '';
  } catch (err) {
    console.log(err);

    return '';
  }
};

export function checkRecommendedGameValid(
  gameInfo: RecommendedGameInfo,
  gameCount: number
) {
  if (gameInfo.condition === '>') {
    return gameCount > gameInfo.count;
  } else if (gameInfo.condition === '>=') {
    return gameCount >= gameInfo.count;
  } else if (gameInfo.condition === '==') {
    return gameCount == gameInfo.count;
  } else if (gameInfo.condition === '<=') {
    return gameCount <= gameInfo.count;
  } else if (gameInfo.condition === '<') {
    return gameCount < gameInfo.count;
  } else {
    return false;
  }
}

export function undefinedToNull(object: Object): any {
  const result = Object.assign({}, object);
  Object.keys(object).map(key => {
    if (!object[key]) {
      result[key] = null;
    }
  });
  return result;
}

export const generateCodeChallenge = codeVerifier => {
  const base64Digest = crypto
    .createHash('sha256')
    .update(codeVerifier)
    .digest('base64');
  return base64url.fromBase64(base64Digest);
};

export const generateCodeVerifier = () => {
  return crypto.randomBytes(64).toString('hex');
};

export const handleZoomOnSignedIn = async (
  zoomClientConfig: ZoomClientConfig | undefined
) => {
  if (!zoomClientConfig) return false;
  if (zoomClientConfig.inZoomClient) {
    const launchUrl = zoomInstallationUrl();
    const zoomData = await firebase
      .firestore()
      .collection('zoom_app')
      .doc(zoomClientConfig.uid!)
      .get();
    // get custom token from uid
    if (!zoomData.exists) {
      console.log('does not exist');
      console.log(launchUrl);
      await openUrlOnExternalBrowserFromZoom(launchUrl);
      return true;
    }
  }
  return false;
};

export const zoomInstallationUrl = () => {
  const redirectBase = isProd
    ? 'https://www.brightful.me'
    : isDev
    ? 'https://www.orric.app'
    : 'https://www.orric.app';
  return `${redirectBase}/zoom-app`;
};

export const openUrlOnExternalBrowserFromZoom = async (url: string) => {
  await zoomSdk.openUrl({ url: url });
};

export const handleZoomOnSignedOut = async (
  zoomClientConfig: ZoomClientConfig | undefined
) => {
  if (!zoomClientConfig) return false;
  if (zoomClientConfig.inZoomClient) {
    await firebase.auth().signInAnonymously();
    if (zoomClientConfig.isHost) {
      const zoomData = await firebase
        .firestore()
        .collection('zoom_app')
        .doc(zoomClientConfig.uid!)
        .get();
      // get custom token from uid
      if (!zoomData.exists) {
        console.log('does not exist');
        const launchUrl = zoomInstallationUrl();
        await openUrlOnExternalBrowserFromZoom(launchUrl);
        return true;
      }
      const customToken = zoomData.data()!.customToken;
      await firebase.auth().signInWithCustomToken(customToken);
    } else {
      // get host from mid
      // const midDoc = await firebase
      //   .firestore()
      //   .collection('zoom_meetings')
      //   .where('mid', '==', zoomClientConfig.mid)
      //   .limit(1)
      //   .get();
      // if (midDoc.docs.length != 0) {
      //   // redirect to host's game room
      //   const gameRoomId = midDoc.docs[0].data()!.gameRoomId;
      //   console.log(gameRoomId);
      //   redirectToGameRoom(gameRoomId, false);
      // }
      // else
    }
    return false;
  }
  return false;
};

export const firebaseModifiedSource = (src: string) => {
  try {
    // url formats:
    // https://storage.googleapis.com/image-hosting-aa7d4.brightful.me/minigame_content/funny_or_not/fon22.png?GoogleAccessId=firebase-adminsdk-mdhhn%40brightful-prod.iam.gserviceaccount.com&Expires=16446997800&Signature=NpN0isRZZQnnrR9BQ0zt%2BUnViSbeO5DC%2BrUUMrRYl5z6jEd16%2BPdcvNn2GFDqV4mSQyyqCe5d3FNl%2BJ7sXu6QLqK09Y7X8T96GUfyRA4Bztec11SY6f5sbCuXQ0OhjGfajd%2F%2BwwQEDaT1dVAw9Du3hskOeIR81S4Ugyr7SjhOrKSRholdqNYz9ht331hiJsRFjOgW%2Bk7kuMjfJ1URgx0OdR4dfUsTTgrKlBNI%2FxjDeeLESm9PyO6P1071%2FidpYfrcyIKAnnHEpxLJzqc%2BB0zzIB8OfESeZOFJUmqAR%2F4I3HRWUZKjhXR79pIMvUnDFvR6npsW0MSdawaI2yus1H%2FfQ%3D%3D
    // https://firebasestorage.googleapis.com/v0/b/image-hosting-aa7d4.brightful.me/o/slack_content%2Ffive_letters.png?alt=media&token=a9f6b2db-6a9d-4a73-92ce-c10cc86168af
    const buckets = ['image-hosting.brightful.me', 'assets.brightful.me'];

    // const inZoomClient = store.state.zoomClientStatus?.inZoomClient;
    // if (inZoomClient) {
    for (const key in buckets) {
      const bucket = buckets[key];

      if (src.includes(`${bucket}/o/`)) {
        const path = src.split(`${bucket}/o/`)[1];
        return `https://${bucket}/${path}`;
      } else if (src.includes(`storage.googleapis.com/${bucket}/`)) {
        const path = src.split(`${bucket}/`)[1];
        return `https://${bucket}/${path}`;
      }
    }
    // }
    return src;
  } catch (err) {
    return src;
  }
};

export const livePollConfigs = {
  [LivePollType.MULTIPLE_CHOICE]: {
    icon: require('@/assets/img/category-icons/survey.svg'),
    name: 'Multiple Choice'
  },
  [LivePollType.RATING]: {
    icon: require('@/assets/img/category-icons/star-rate.svg'),
    name: 'Rating'
  },
  [LivePollType.WORD_CLOUD]: {
    icon: require('@/assets/img/category-icons/cloud-word-2.svg'),
    name: 'Word Cloud'
  },
  [LivePollType.SHORT_ANSWER]: {
    icon: require('@/assets/img/category-icons/short-answer.svg'),
    name: 'Short Answer'
  }
};

export function dateDifference(date: firebaseApp.firestore.Timestamp) {
  try {
    if (!date) return 0;
    const today = new Date();
    const isToday =
      date.toDate().setHours(0, 0, 0, 0) == today.setHours(0, 0, 0, 0);
    const diff = moment(moment()).diff(date.toDate(), 'd');
    if (diff == 0 && !isToday) return 1;
    return diff;
  } catch (err) {
    console.log(err);
    return 0;
  }
}

export function pollDateCreatedText(date: firebase.firestore.Timestamp) {
  if (!date) return '';
  const today = new Date();
  const isToday =
    date.toDate().setHours(0, 0, 0, 0) == today.setHours(0, 0, 0, 0);
  const difference: number = dateDifference(date);
  if (isToday) return 'today';
  else if (difference == 1) return `yesterday`;
  else return `${difference} days ago`;
}

export function eventToolsHistoryDateFormat(
  date?: firebaseApp.firestore.Timestamp | null,
  delimiter = ' - '
) {
  try {
    if (date) {
      const timeFormat: string = date.toDate().toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      });
      const year = date.toDate().getFullYear();
      const today = new Date();
      const isToday =
        date.toDate().setHours(0, 0, 0, 0) == today.setHours(0, 0, 0, 0);
      if (isToday) return `Today${delimiter}${timeFormat}`;
      let dateOptions: Intl.DateTimeFormatOptions;
      if (year != today.getFullYear())
        dateOptions = {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        };
      else
        dateOptions = {
          month: 'short',
          day: 'numeric'
        };
      const dateFormat: string = date
        .toDate()
        .toLocaleString('en-US', dateOptions);
      return `${dateFormat}${delimiter}${timeFormat}`;
    }
    return '';
  } catch (err) {
    return '';
  }
}
export function qnaDateFormat(
  startDate: firebase.firestore.Timestamp,
  endDate?: firebase.firestore.Timestamp | undefined | null
) {
  console.log('....');
  if (!startDate) return '';
  if (endDate) {
    const isSameDate =
      endDate.toDate().setHours(0, 0, 0, 0) ==
      startDate.toDate().setHours(0, 0, 0, 0);
    console.log(isSameDate);
    if (isSameDate) {
      let dateFormat = '';
      const today = new Date();
      const isToday =
        startDate.toDate().setHours(0, 0, 0, 0) == today.setHours(0, 0, 0, 0);
      if (isToday) {
        dateFormat = `Today`;
      } else {
        let dateOptions: Intl.DateTimeFormatOptions;
        const year = startDate.toDate().getFullYear();
        if (year != today.getFullYear())
          dateOptions = {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          };
        else
          dateOptions = {
            month: 'short',
            day: 'numeric'
          };
        dateFormat = startDate.toDate().toLocaleString('en-US', dateOptions);
      }
      const startTimeFormat: string = startDate
        .toDate()
        .toLocaleString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        });
      const endTimeFormat: string = endDate.toDate().toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      });
      return `${dateFormat} - ${startTimeFormat} to ${endTimeFormat}`;
    } else {
      return `${eventToolsHistoryDateFormat(
        startDate,
        ' '
      )} - ${eventToolsHistoryDateFormat(endDate, ' ')}`;
    }
  } else {
    return eventToolsHistoryDateFormat(startDate, ' ');
  }
}
export async function configureGameRoomButton(defaultLobbyId: string) {
  const lobby: any = await getLobbyDoc(defaultLobbyId);
  if (!lobby) {
    // old account which has survey answer but not lobby
    return null;
  }
  store.commit(UPDATE_LOBBY_DOC, {
    newLobbyDoc: lobby
  });
  store.commit(UPDATE_ACTIVEGAMENAME, {
    activeGameName: getGameTitleByType(lobby['gameName'])!
  });
  if (lobby['customGameRoomId']) {
    store.commit(UPDATE_ISUSERUSINGCUSTOMGAMEROOMID, {
      isUserUsingCustomGameRoomId: true
    });
    store.commit(UPDATE_ACTIVEGAMEROOMID, {
      activeGameRoomId: lobby['customGameRoomId']
    });
  }
  return true;
}
export function hasActiveGame() {
  const lobby = store.getters.getLobbyDoc;

  if (!lobby || !lobby['gameName'] || lobby['isClosed']) {
    return false;
  } else {
    return true;
  }
}
export function gameNameFromPollType(type: LivePollType) {
  switch (type) {
    case LivePollType.MULTIPLE_CHOICE:
      return GameName.LIVE_POLL_MULTIPLE_CHOICE;
    case LivePollType.RATING:
      return GameName.LIVE_POLL_RATING;
    case LivePollType.SHORT_ANSWER:
      return GameName.LIVE_POLL_SHORT_ANSWER;
    case LivePollType.WORD_CLOUD:
      return GameName.LIVE_POLL_WORD_CLOUD;
  }
}
export const playgroundDevs = {
  dag: { email: 'localtestingdag@brightful.me', password: 'password123' },
  osho: { email: 'localtestingosho@brightful.me', password: '12345Gggg' },
  yugene: { email: 'localtestingyugene@brightful.me', password: '12345Gggg' }
};
export async function handlePlaygroundSignIn(dev: 'osho' | 'dag' | 'yugene') {
  await firebase
    .auth()
    .signInWithEmailAndPassword(
      playgroundDevs[dev].email,
      playgroundDevs[dev].password
    );
}

export async function fetchWordCloudImage(words: Array<string>) {
  const json = {
    text: words.join(' '),
    width: 352,
    height: 176,
    background_color: 'white'
  };
  const response = await usePostAPI(getEndpoint('wordCloudImage'), {
    data: json
  });

  // return (response.data as string).replace('�PNG', '').trimStart();
  return response.data;
  // const jsonData = JSON.parse(JSON.stringify(json));
  // const apiUrl =
  //   'http://wordcloud.endpoints.brightful-dev.cloud.goog/wordcloud?key=AIzaSyBG76XEG65x3sJywDNTLOHdWlywYi_LFWE';
  // const response = await axios.post(apiUrl, jsonData, {
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Accept: 'application/json'
  //   },
  //   responseType: 'blob'
  // });
  // console.log(response);
  // return response.data;
}
export function isLivePoll(gameName: string) {
  return (
    gameName == GameName.LIVE_POLL_MULTIPLE_CHOICE ||
    gameName == GameName.LIVE_POLL_RATING ||
    gameName == GameName.LIVE_POLL_SHORT_ANSWER ||
    gameName == GameName.LIVE_POLL_WORD_CLOUD
  );
}
export function eventToolsParticipantsWithoutHost(
  participants: Array<LivePollParticipant | QnaParticipant | WheelParticipant>
) {
  const lobby = store.getters.getLobbyDoc;

  const index = participants
    .map(participant => {
      return participant.id;
    })
    .findIndex(participant => participant == lobby.host);

  if (index > -1) {
    // only splice array when item is found
    return participants.filter(function(el, i) {
      return index !== i;
    });
  }
  return participants;
}
