import { SubscriptionDetails } from '@/models/SubscriptionDetails';
import { SubscriptionLevel } from '@/models/SubscriptionLevel';
import { isProd } from './helper';

export const stripePublishableKey = isProd
  ? 'pk_live_51HPLBFD2CpvaE0WLm1f3zWAjvyT1NPFz1Wg9cqfOCZpuJyvr9pUcV9VBSVCh7xZkNq896wEPmJAh77amcl7GT49S006KfO6mvO'
  : 'pk_test_51HPLBFD2CpvaE0WL7us2feQmueb1EIIW9GtLfPou7jOKYV3rxTPIslZ7yLEnSHRut09repJJL8FXZx6tGI8MkhtL0061axslhv';

export const liteParticipantsUnitRate = 1.2;
export const proParticipantsUnitRate = 1.5;
export const proMonthlyPrice = 80;
export const proAnnuallyPrice = 199;
export const liteMonthlyPrice = 20;
export const liteAnnuallyPrice = 149;
export const goldMonthlyPrice = 100;
export const goldAnnuallyPrice = 480;
export const bronzeMonthlyPrice = 70;
export const bronzeAnnuallyPrice = 336;
export const eventPriceV6 = 80;
export const premiumMonthlyPrice = 29;
export const premiumAnnuallyPrice = 150;
export const professionalMonthlyPrice = 150;
export const professionalAnnuallyPrice = 480;
export const enterpriseMonthlyPrice = 1100;
export const enterpriseAnnuallyPrice = 1100;

// v5 pricing
export const nLiteParticipantsIncluded = 0;
export const nProParticipantsIncluded = 0;
export const liteMetered = isProd
  ? 'price_1LS4sRD2CpvaE0WLWem6I1H8'
  : 'price_1LS4p6D2CpvaE0WLNcCAyVeI';
export const proMetered = isProd
  ? 'price_1LS4scD2CpvaE0WLyPMpLoh3'
  : 'price_1LS4pND2CpvaE0WLul3Xbfpy';
export const liteMonthly = isProd
  ? 'price_1LS4ywD2CpvaE0WLUqrL59zd'
  : 'price_1LS4wqD2CpvaE0WL8Nb7tSVy';
export const liteAnnually = isProd
  ? 'price_1LS4zFD2CpvaE0WLlkJ5RDk6'
  : 'price_1LS4wMD2CpvaE0WLXLfzvruB';
export const proMonthly = isProd
  ? 'price_1LS4zlD2CpvaE0WLkEPPr4MC'
  : 'price_1LS4vVD2CpvaE0WLo90CVoVQ';
export const proAnnually = isProd
  ? 'price_1LS4zXD2CpvaE0WLSuK98cNx'
  : 'price_1LS4unD2CpvaE0WLrhYW3Q3d';

export const bronzeMonthly = isProd
  ? 'price_1MCqGGD2CpvaE0WLyihUEMo1'
  : 'price_1MBVSwD2CpvaE0WL7fKHvyNt';
export const bronzeAnnually = isProd
  ? 'price_1MCqGBD2CpvaE0WLmhRw9XTZ'
  : 'price_1MBVTiD2CpvaE0WLFyEWNSeP';
export const goldMonthly = isProd
  ? 'price_1MCqG4D2CpvaE0WL7asyeZGY'
  : 'price_1MBVVCD2CpvaE0WLu1MuAEz6';
export const goldAnnually = isProd
  ? 'price_1MCqFxD2CpvaE0WLifuRRHgH'
  : 'price_1MBVX2D2CpvaE0WLbzRYYDD8';

export const oneOffEventV5 = isProd
  ? 'price_1LAGfwD2CpvaE0WLshAQNSpS'
  : 'price_1LAGfrD2CpvaE0WLXCJk2k4S';
export const oneOffEventV6 = isProd
  ? 'price_1MCqKiD2CpvaE0WLCMaRT2vS'
  : 'price_1MBVhKD2CpvaE0WLfPMagtTY';

export const basicMonthly = isProd
  ? 'price_1JfLgvD2CpvaE0WLhBMciswt'
  : 'price_1JfLSGD2CpvaE0WLPxdpevTk';
export const basicAnnually = isProd
  ? 'price_1JfLjQD2CpvaE0WLnSouPak5'
  : 'price_1JfLSsD2CpvaE0WLPkY1qocL';
export const premiumMonthly = isProd
  ? 'price_1IE7agD2CpvaE0WLtljSuQ6s'
  : 'price_1IE7R9D2CpvaE0WLpoj8iQFV';
export const premiumAnnually = isProd
  ? 'price_1IE7agD2CpvaE0WLw0AaCBUp'
  : 'price_1IE7RAD2CpvaE0WLVOCEzuQW';
export const professionalMonthly = isProd
  ? 'price_1JfLl5D2CpvaE0WLRalq6uDS'
  : 'price_1JfLX6D2CpvaE0WLSBQG8l9c';
export const professionalAnnually = isProd
  ? 'price_1JfLndD2CpvaE0WLdVQPJoNk'
  : 'price_1JfLXoD2CpvaE0WLtzNap6Gs';

export const basicEducatorMonthly = isProd
  ? 'price_1JfLpGD2CpvaE0WLmlgoFsMm'
  : 'price_1JfLTfD2CpvaE0WLY7XCR7nF';
export const basicEducatorAnnually = isProd
  ? 'price_1JfLqQD2CpvaE0WLKNnOQxOC'
  : 'price_1JfLUAD2CpvaE0WLXE16Oktj';
export const professionalEducatorMonthly = isProd
  ? 'price_1JfLs0D2CpvaE0WL5i7S63XM'
  : 'price_1JfLYrD2CpvaE0WLsGbfCvfv';
export const professionalEducatorAnnually = isProd
  ? 'price_1JfLstD2CpvaE0WLR1V46fzT'
  : 'price_1JfLZUD2CpvaE0WL1ZcUPNZs';

export function isNotValidEducationEmail(
  email: string,
  whiteListRegex: string,
  whiteListDomains: Array<string>
) {
  const domain = email.substring(email.indexOf('@') + 1);
  const matchingPattern = new RegExp(whiteListRegex);
  const notFoundInRegex = !(domain.match(matchingPattern) !== null);
  const notFoundInWhitelist = !whiteListDomains.includes(domain);
  return notFoundInRegex && notFoundInWhitelist;
}

export function getAccountFeatures(subscriptionDetails: SubscriptionDetails) {
  switch (subscriptionDetails.subscriptionLevel) {
    case SubscriptionLevel.FREE_TRIAL:
    case SubscriptionLevel.ONE_OFF_EVENT:
      return ['Single account, single host', 'Max players in one game: 200'];
    case SubscriptionLevel.LITE:
      return ['Single account, single host', 'Max players in one game: 50'];
    case SubscriptionLevel.PRO:
      return ['Multiple admins and hosts', 'Max players in one game: 200'];
    case SubscriptionLevel.BRONZE:
      return ['Single account, single host', 'Max players in one game: 20'];
    case SubscriptionLevel.GOLD:
      return ['Single account, single host', 'Max players in one game: 200'];
    case SubscriptionLevel.BASIC:
    case SubscriptionLevel.BASIC_EDUCATOR:
      return ['Single account, single host', 'Host up to 20 participants'];
    case SubscriptionLevel.PREMIUM:
    case SubscriptionLevel.PREMIUM_EDUCATOR:
      return ['Single account, single host', 'Host up to 50 participants'];
    case SubscriptionLevel.PROFESSIONAL:
    case SubscriptionLevel.PROFESSIONAL_EDUCATOR:
      return ['Single account, single host', 'Host up to 200 participants'];
    case SubscriptionLevel.ENTERPRISE:
      return [
        'Site wide license - All users can host',
        'Host up to 200 participants'
      ];
    case SubscriptionLevel.FREE:
      return ['Single account, single host', 'Host up to 4 participants'];
  }
}
export function isPricingBeforeV6(subscriptionDetails: SubscriptionDetails) {
  const subscriptionLevel = subscriptionDetails?.subscriptionLevel;
  return (
    subscriptionLevel &&
    [
      SubscriptionLevel.BASIC,
      SubscriptionLevel.BASIC_EDUCATOR,
      SubscriptionLevel.ENTERPRISE,
      SubscriptionLevel.LITE,
      SubscriptionLevel.PREMIUM,
      SubscriptionLevel.PREMIUM_EDUCATOR,
      SubscriptionLevel.PRO
    ].includes(subscriptionLevel)
  );
}
