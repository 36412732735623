
import { defineComponent, onMounted } from 'vue';
import { amplitudeEvent } from '@/config/amplitudeConfig';
// import NumberOfPlayersCharades from '@/components/Modal/AboutComponent/NumberOfPlayersComponents/NumberOfPlayersCharades.vue';
import HowToPlayCharades from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayCharades.vue';

export default defineComponent({
  name: 'AboutCharades',
  components: {
    // NumberOfPlayersCharades,
    HowToPlayCharades
  },
  setup() {
    onMounted(() => {
      amplitudeEvent('previewGame', {
        game: 'charades',
        version: 'regular',
        eventTime: new Date(Date.now()).toISOString()
      });
    });
  }
});
