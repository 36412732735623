
import { useStore } from '@/store';
import { computed, defineComponent, ref } from 'vue';
import useFirebaseCollection from '@/hooks/firebase/useFirebaseCollection';
import { RecommendedGameInfo } from '@/models/RecommendedGameInfo';
import {
  checkRecommendedGameValid,
  firebaseModifiedSource
} from '@/utils/helper';

export default defineComponent({
  name: 'GamePromotionBanner',
  emits: ['select', 'start', 'custom'],
  setup(_, context) {
    const store = useStore();
    const user = computed(() => store.getters.getUser);
    const userTotalGameplays = computed(() => store.getters.getTotalGameplays);
    const displayName = user.value.displayName ?? '';
    const recommendedGames = ref([] as RecommendedGameInfo[]);
    const gameIndex = ref(0);
    const timeCount = ref(6);
    var timer = undefined as number | undefined;
    const isLoading = ref(false);
    const recommendedGameQuery = useFirebaseCollection(
      'minigame_second_recommended_game',
      {
        onMounted: false
      }
    );
    getRecommendedGames();
    async function getRecommendedGames() {
      await recommendedGameQuery.getCollection({
        orderBy: 'index',
        isDesc: false
      });
      recommendedGames.value = [];
      const games = recommendedGameQuery.collectionData.value!.map(
        doc => new RecommendedGameInfo(doc)
      );
      games.map(game => {
        if (checkRecommendedGameValid(game, userTotalGameplays.value)) {
          recommendedGames.value.push(
            new RecommendedGameInfo({
              index: game.index,
              condition: game.condition,
              count: game.count,
              gameName: game.gameName,
              isCustomGame: game.isCustomGame,
              customGameName: game.customGameName,
              customGameId: game.customGameId,
              customGameIcon: game.customGameIcon,
              title: game.title
                .replaceAll('[displayname]', displayName)
                .replaceAll('[', '<strong class="color-blue">')
                .replaceAll(']', '</strong>'),
              description: game.description
                .replaceAll('[', '<strong class="color-blue">')
                .replaceAll(']', '</strong>')
            })
          );
        }
      });
      startTimer();
    }
    function startTimer() {
      if (!timer) {
        timer = setInterval(() => {
          if (timeCount.value === 1) {
            nextGame();
          } else {
            timeCount.value--;
          }
        }, 1000);
      }
    }
    function stopTimer() {
      if (timer) {
        clearInterval(timer);
        timer = undefined;
      }
    }
    function prevGame() {
      let prevIndex = gameIndex.value - 1;
      if (prevIndex < 0) {
        prevIndex = recommendedGames.value.length - 1;
      }
      goToIndex(prevIndex);
    }
    function nextGame() {
      goToIndex((gameIndex.value + 1) % recommendedGames.value.length);
    }
    function goToIndex(index: number) {
      gameIndex.value = index;
      timeCount.value = 6;
    }
    function startGame() {
      if (!recommendedGames.value[gameIndex.value].isCustomGame) {
        isLoading.value = true;
        console.log(isLoading.value);
        context.emit('start', {
          gameName: recommendedGames.value[gameIndex.value].gameName,
          source: 'TopBanner',
          onSuccess: () => {
            isLoading.value = false;
          },
          onError: () => {
            isLoading.value = false;
          }
        });
      } else {
        isLoading.value = true;
        context.emit('custom', {
          gameName: recommendedGames.value[gameIndex.value].gameName,
          customGameName:
            recommendedGames.value[gameIndex.value].customGameName,
          customGameId: recommendedGames.value[gameIndex.value].customGameId,
          source: 'TopBanner',
          onSuccess: () => {
            isLoading.value = false;
          },
          onError: () => {
            isLoading.value = false;
          }
        });
      }
    }
    function goToSelectedType() {
      context.emit('select', recommendedGames.value[gameIndex.value].gameName);
    }
    return {
      ...recommendedGameQuery,
      recommendedGames,
      gameIndex,
      startTimer,
      stopTimer,
      prevGame,
      nextGame,
      goToIndex,
      startGame,
      goToSelectedType,
      isLoading,
      firebaseModifiedSource
    };
  }
});
