
import {
  computed,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  Ref,
  ref,
  watch
} from 'vue';
import Papa from 'papaparse';
import VueScrollTo from 'vue-scrollto';
import { onBeforeRouteLeave } from 'vue-router';
import vClickOutside from 'click-outside-vue3';
import {
  getEndpoint,
  capitalizeFirstLetter,
  base64toBlob,
  onCustomGameStart,
  questionString,
  undefinedToNull,
  hasActiveGame
} from '@/utils/helper';
import { isExpiredFreeTrialPlan, isFreePlan } from '@/utils/pricing_rules';
import {
  getGameTitleByType,
  textFromChoices,
  customGameWithOrderTypes,
  getGameLeastCountByType,
  questionBasedTypes
} from '@/utils/gameConstants';
import CustomizedGameUpdateTitleDescriptionModal from '@/components/Modal/CustomizedGameUpdateTitleDescriptionModal.vue';
import CustomGameSuggestions from '@/components/CustomGameSuggestions.vue';
import CustomGameCreationInstruction from '@/components/CustomGame/CustomGameCreationInstruction.vue';
import GeneralWouldYouRatherInputItem from '@/components/GeneralInput/GeneralWouldYouRatherInputItem.vue';
import GeneralSingleInputItem from '@/components/GeneralInput/GeneralSingleInputItem.vue';
import GeneralKeywordInputItem from '@/components/GeneralInput/GeneralKeywordInputItem.vue';
import GeneralTriviaInputItem from '@/components/GeneralInput/GeneralTriviaInputItem.vue';
import GeneralMimicInputItem from '@/components/GeneralInput/GeneralMimicInputItem.vue';
import GeneralOneToHundredInputItem from '@/components/GeneralInput/GeneralOneToHundredInputItem.vue';
import GeneralYesOrNoInputItem from '@/components/GeneralInput/GeneralYesOrNoInputItem.vue';
import GeneralFunnyOrNotInputItem from '@/components/GeneralInput/GeneralFunnyOrNotInputItem.vue';
import GeneralPollInputItem from '@/components/GeneralInput/GeneralPollInputItem.vue';
import GeneralTop5InputItem from '@/components/GeneralInput/GeneralTop5InputItem.vue';
import BeginnerIntroductionModal from '@/components/Modal/BeginnerIntroductionModal.vue';
import ImageUploadingModal from '@/components/Modal/ImageUploadingModal.vue';
import { WouldYouRatherQuestion } from '@/models/WouldYouRatherQuestion';
import { SingleQuestion } from '@/models/SingleQuestion';
import { Keyword } from '@/models/Keyword';
import { MimicTwoWords } from '@/models/MimicTwoWords';
import { TriviaQuestion } from '@/models/TriviaQuestion';
import { OneToHundredTopic } from '@/models/OneToHundredTopic';
import { YesOrNoTopic } from '@/models/YesOrNoTopic';
import { FunnyOrNotQuestion } from '@/models/FunnyOrNotQuestion';
import { PollQuestion } from '@/models/PollQuestion';
import router from '@/router';
import firebaseApp from '@/firebaseApp';
import { amplitudeEvent } from '@/config/amplitudeConfig';
import { OpenAi } from '@/utils/openai_helper';
import usePostAPI from '@/hooks/usePostAPI';
import { useStore } from '@/store';
import { Top5Question } from '@/models/Top5Question';
import { LaunchOptions } from '@/models/GameStartOptions';
import { ActivityType } from '@/utils/gamenames_enums';
import LaunchModal from '@/components/Modal/LaunchModal.vue';
export default defineComponent({
  name: 'CreateCustomGame',
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    CustomizedGameUpdateTitleDescriptionModal,
    CustomGameCreationInstruction,
    GeneralWouldYouRatherInputItem,
    GeneralSingleInputItem,
    GeneralKeywordInputItem,
    GeneralTriviaInputItem,
    GeneralMimicInputItem,
    GeneralOneToHundredInputItem,
    GeneralYesOrNoInputItem,
    GeneralFunnyOrNotInputItem,
    GeneralPollInputItem,
    GeneralTop5InputItem,
    BeginnerIntroductionModal,
    ImageUploadingModal,
    CustomGameSuggestions,
    LaunchModal
  },
  setup() {
    const store = useStore();
    const modalTypeShown: Ref<['launch', LaunchOptions] | null> = ref(null);
    const user = computed(() => store.getters.getUser);
    const userDoc = computed(() => store.getters.getUserDoc);
    const subscriptionDetails = computed(
      () => store.getters.getSubscriptionDetails
    );

    const playOrderSelected = ref(0);
    const playOrder = computed(() => {
      return playOrders[playOrderSelected.value];
    });

    const type = router.currentRoute.value.query.type as string;
    const title = router.currentRoute.value.query.title as string;
    const description =
      (router.currentRoute.value.query.description as string) ?? '';
    const customGameId = firebaseApp
      .firestore()
      .collection('minigame_custom_game_public')
      .doc().id;

    const pageSize =
      type == 'draw_it' || type == 'describe_it' || type == 'charades'
        ? 18
        : 10;
    const iconColorNumber = Math.floor(Math.random() * 10) + 1;
    const pageSizeGeneralQuestions = 10;
    const pageSizeDescribeItDrawItWords = 18;
    const questionsToAddPageSize =
      type === 'describe_it' || type === 'draw_it' || type === 'charades'
        ? pageSizeDescribeItDrawItWords
        : pageSizeGeneralQuestions;
    const minimumQuestions = 1;
    let backConfirmed = false;
    let onNext: any;
    let saved = true;

    const isLoading = ref(false);
    const currentCustomGameDocId = ref('');
    const updatedTitle = ref(title);
    const updatedDescription = ref(description);
    const showUpdateTitleDescriptionModal = ref(false);
    const showPreviewModal = ref(false);
    const showPublishedModal = ref(false);
    const showFailedModal = ref(false);
    const showRemoveModal = ref(false);
    const showCustomizedGameSettingsModal = ref(false);
    const showConfirmBackModal = ref(false);
    const showBeginnerIntroductionModal = ref(false);
    const showImageUploadingModal = ref(false);
    const warningMessage1 = ref(undefined as string | undefined);
    const warningMessage2 = ref(undefined as string | undefined);
    const questionCount = ref(pageSize);
    const questions = ref([] as any[]);
    const checked = ref([] as boolean[]);

    const pageN = ref(0);
    const saveAndPublishInProgress = ref(false);

    const uploadingImageCount = ref(0);
    const uploadedImageCount = ref(0);

    const suggestionLimitReached = ref(false);
    const currentPrompt = ref('');
    const currentGptResponse = ref('');
    const isSuggestionUnsafe = ref(false);
    const currentGptResponseTime = ref();
    const currentPool = ref([] as any[]);
    const fetchingSuggestions = ref(false);
    const newRefresh = ref(false);
    const minimumEntriesForSuggestions = 4;
    const insufficientEntries = ref(true);
    const useAi = type !== 'trivia';
    const visibleLength = 6;
    const isOrderTabOpened = ref(false);
    const playOrders = { 0: 'shuffle', 1: 'same' };

    const isBrightful = computed(() =>
      user.value.email.endsWith('@brightful.me')
    );
    const fileinput = ref(null as any);

    function openFile() {
      if (fileinput.value) {
        fileinput.value.click();
      }
    }
    async function onStart(event) {
      const launchOptions: LaunchOptions = {
        activityType: ActivityType.GAME,
        chosenGame: event.gameName,
        createGameSource: 'dashboard',
        customGameId: event.customGameId,
        customGameName: event.customGameName
      };
      if (!hasActiveGame()) {
        await onCustomGameStart({
          ...launchOptions,
          subscriptionLevel: subscriptionDetails.value.subscriptionLevel,
          zoomClientConfig: store.state.zoomClientStatus,
          startFresh: false,
          customGameId: event.customGameId,
          customGameName: event.customGameName
        });
      } else {
        showPublishedModal.value = false;
        modalTypeShown.value = ['launch', launchOptions];
      }
    }

    function handleResult(results: any[]) {
      const lastIndex =
        questions.value.length > 0
          ? questions.value[questions.value.length - 1].key + 1
          : 0;
      const filteredQuestions = getFilteredQuestions(
        questions.value.filter(q => q.index !== -1),
        type
      );
      questions.value = questions.value.filter(
        question =>
          filteredQuestions.findIndex(fq => fq.index === question.index) !== -1
      );
      checked.value = Array.from(
        { length: questions.value.length },
        () => false
      );

      for (let i = 1; i < results.length; i++) {
        const entry = Object.values(results[i]) as any[];
        try {
          switch (type) {
            case 'would_you_rather': {
              const wyrQuestion = new WouldYouRatherQuestion({
                question: entry[0],
                choice1: entry[1],
                choice2: entry[2],
                index: lastIndex + i + 1,
                key: lastIndex + i
              });
              questions.value.push(wyrQuestion);
              break;
            }
            case 'question_of_the_day':
            case 'spot_my_lie': {
              let qotdSpotMyLieQuestion = new SingleQuestion({
                question: entry[0],
                index: lastIndex + i + 1,
                key: lastIndex + i
              });
              questions.value.push(qotdSpotMyLieQuestion);
              break;
            }
            case 'draw_tell': {
              let drawTellTopic = new SingleQuestion({
                question: entry[0],
                topic: entry[0],
                index: lastIndex + i + 1,
                key: lastIndex + i
              });
              questions.value.push(drawTellTopic);
              break;
            }
            case 'draw_it':
            case 'describe_it':
            case 'charades': {
              let drawItDescribeItQuestion = new Keyword({
                word: entry[0],
                index: lastIndex + i + 1,
                key: lastIndex + i
              });
              questions.value.push(drawItDescribeItQuestion);
              break;
            }
            case 'trivia': {
              let triviaQuestion = new TriviaQuestion({
                question: entry[0],
                correct: entry[1],
                incorrect1: entry[2],
                incorrect2: entry[3],
                incorrect3: entry[4],
                image: entry[5],
                index: lastIndex + i + 1,
                key: lastIndex + i
              });
              questions.value.push(triviaQuestion);
              break;
            }
            case 'two_words_game': {
              let mimicQuestion = new MimicTwoWords({
                word: entry[0],
                clue: entry[1],
                index: lastIndex + i + 1,
                key: lastIndex + i
              });
              questions.value.push(mimicQuestion);
              break;
            }
            case 'one_to_hundred': {
              let oneToHundredTopic = new OneToHundredTopic({
                topic: entry[0],
                left: entry[1],
                right: entry[2],
                index: lastIndex + i + 1,
                key: lastIndex + i
              });
              questions.value.push(oneToHundredTopic);
              break;
            }
            case 'yes_or_no': {
              let yesOrNoTopic = new YesOrNoTopic({
                hint: entry[0],
                word: entry[1],
                index: lastIndex + i + 1,
                key: lastIndex + i
              });
              questions.value.push(yesOrNoTopic);
              break;
            }
            case 'funny_or_not': {
              let fonQuestion = new FunnyOrNotQuestion({
                question: entry[0],
                imagePath: entry[1],
                index: lastIndex + i + 1,
                key: lastIndex + i
              });
              questions.value.push(fonQuestion);
              break;
            }
            case 'poll': {
              let pollQuestion = new PollQuestion({
                question: entry[0],
                choices: entry.slice(1).filter(c => c.trim() !== ''),
                index: lastIndex + i + 1,
                key: lastIndex + i
              });
              questions.value.push(pollQuestion);
              break;
            }
            case 'top5': {
              let top5Question = new Top5Question({
                question: entry[0],
                type: entry[1],
                answers: [entry[2], entry[3], entry[4], entry[5], entry[6]],
                index: lastIndex + i + 1,
                key: lastIndex + i
              });
              questions.value.push(top5Question);
              break;
            }
            default:
              break;
          }
          checked.value.push(false);
        } catch (e) {
          console.log((e as any).toString());
        }
      }
      questionCount.value = questions.value.length;
    }

    function handleFileUpload(e: any) {
      const file = e.target.files[0];
      Papa.parse(file, {
        complete: function(results) {
          handleResult(results.data);
        }
      });
    }

    const fetchSuggestions = async function(
      title: string,
      description: string,
      isInitial: boolean,
      words: Array<any>
    ) {
      if (suggestionLimitReached.value || insufficientEntries.value)
        return null;

      words = OpenAi.cleanWords(type, words);
      fetchingSuggestions.value = true;
      isSuggestionUnsafe.value = false;
      let limitedWords: Array<any>;
      let firstIndex = OpenAi.inputLimitFistIndex(
        type,
        words,
        title,
        description
      );
      limitedWords = words.slice(firstIndex, words.length);
      const props = OpenAi.getPrompt(
        type,
        title,
        description,
        isInitial,
        limitedWords
      );
      currentPrompt.value = props?.prompt!;
      try {
        const response = await usePostAPI(
          getEndpoint('aiSuggestions'),
          props?.toJson
        );
        if (response.status == 200) {
          currentGptResponse.value = response.data.data[0].text;
          let filterLabel = response.data.filterLabel;
          currentGptResponseTime.value = Date();
          amplitudeEvent('aiGeneration', {
            game: type,
            input: currentPrompt.value,
            output: currentGptResponse.value,
            isMonthlyLimitReached: false,
            filterLabel: filterLabel
          });
          let isIncomplete: boolean =
            response.data.data[0].finish_reason == 'length';
          let responseWords: Array<any> = OpenAi.processResponse(
            type,
            response.data.data[0].text,
            limitedWords
          )!;
          if (isIncomplete) responseWords.pop();
          let cleanResponseWords: Array<any> = OpenAi.cleanWords(
            type,
            responseWords
          );

          currentPool.value = cleanResponseWords.map(val => {
            return { ...val, picked: false };
          });
          usePostAPI(getEndpoint('gpt3ResultsUpdate'), {
            resultIncrement: responseWords.length
          });
        }
        fetchingSuggestions.value = false;
      } catch (err) {
        if ((err as any).message.includes('403')) {
          // monthly limit reached
          amplitudeEvent('aiGeneration', {
            game: type,
            input: currentPrompt.value,
            isMonthlyLimitReached: true
          });
          suggestionLimitReached.value = true;
        }
        fetchingSuggestions.value = false;
      }
    };

    const onPicked = function(item: any) {
      let index = emptyQuestionsIndex(questions.value, type);
      if (index == -1) {
        onAddQuestion();
        index = emptyQuestionsIndex(questions.value, type);
      }
      setQuestionValue(index + 1, item);
      item.picked = true;
      amplitudeEvent('aiSuggestionTaken', {
        game: type,
        suggestionn: textFromChoices(type, item)
      });
      let delayInMilliseconds: number = 2000;
      setTimeout(function() {
        let ind: number = currentPool.value.findIndex(val => val === item);
        if (ind != -1) currentPool.value.splice(ind, 1);
        if (currentPool.value.length >= visibleLength) {
          currentPool.value = currentPool.value.slice(0, visibleLength - 1);
        }
      }, delayInMilliseconds);
      newRefresh.value = true;
    };

    const onReport = async function(item: any, suggestion: string) {
      let ind: number = currentPool.value.findIndex(val => val === item);
      if (ind != -1) currentPool.value.splice(ind, 1);
      await usePostAPI(getEndpoint('flagGptSuggestion'), {
        prompt: currentPrompt.value,
        gptResult: currentGptResponse.value,
        flaggedSuggestion: suggestion,
        time: new Date(Date.now()).toISOString()
      });
      amplitudeEvent('flagAiSuggestion', {
        game: type,
        input: currentPrompt.value,
        output: currentGptResponse.value,
        flaggedSuggestion: suggestion
      });
    };

    const emptyQuestionsIndex = function(
      allQuestions: any[],
      type: string
    ): number {
      switch (type) {
        case 'would_you_rather':
          return allQuestions.findIndex(
            value =>
              value.question.trim() == '' &&
              value.choice1.trim() == '' &&
              value.choice2.trim() == ''
          );
        case 'question_of_the_day':
        case 'spot_my_lie':
          return allQuestions.findIndex(value => value.question.trim() == '');

        case 'draw_tell':
          return allQuestions.findIndex(value => value.topic.trim() == '');

        case 'draw_it':
        case 'describe_it':
        case 'charades':
          return allQuestions.findIndex(value => value.word.trim() == '');

        case 'trivia':
          return allQuestions.findIndex(
            value =>
              value.question.trim() == '' &&
              value.correct.trim() == '' &&
              value.incorrect1.trim() == '' &&
              value.incorrect2.trim() == '' &&
              value.incorrect3.trim() == ''
          );
        case 'two_words_game':
          return allQuestions.findIndex(
            value => value.clue.trim() == '' && value.word.trim() == ''
          );
        case 'one_to_hundred':
          return allQuestions.findIndex(
            value =>
              value.topic.trim() == '' &&
              value.left.trim() == '' &&
              value.right.trim() == ''
          );
        case 'yes_or_no':
          return allQuestions.findIndex(
            value => value.word.trim() == '' && value.hint.trim() == ''
          );
        case 'funny_or_not':
          return allQuestions.findIndex(value => value.question.trim() == '');
        case 'poll':
          return allQuestions.findIndex(
            value =>
              value.question.trim() == '' &&
              value.choices.length >= 2 &&
              value.choices.findIndex(
                (choice: string) => choice.trim() !== ''
              ) === -1
          );
        case 'top5':
          return allQuestions.findIndex(
            value =>
              value.question.trim() == '' &&
              value.answers.length == 5 &&
              value.answers.findIndex(
                (answer: string) => answer.trim() !== ''
              ) === -1
          );

        default:
          return -1;
      }
    };

    const onRefresh = function() {
      fetchFromCurrentWords();
    };

    const fetchFromCurrentWords = function() {
      fetchSuggestions(
        updatedTitle.value,
        updatedDescription.value,
        false,
        getFilteredQuestionsContent(questions.value, type)
      ).then(() => {
        newRefresh.value = false;
      });
    };

    const getFilteredQuestionsContent = function(
      allQuestions: any[],
      type: string
    ): any[] {
      switch (type) {
        case 'would_you_rather':
          return allQuestions
            .filter(
              value =>
                value.question.trim() !== '' &&
                value.choice1.trim() !== '' &&
                value.choice2.trim() !== ''
            )
            .map(value => {
              return {
                question: value.question,
                choice1: value.choice1,
                choice2: value.choice2
              };
            });
        case 'question_of_the_day':
        case 'spot_my_lie':
          return allQuestions
            .filter(value => value.question.trim() !== '')
            .map(value => {
              return { question: value.question };
            });
        case 'draw_it':
        case 'describe_it':
        case 'charades':
          return allQuestions
            .filter(value => value.word.trim() !== '')
            .map(value => {
              return value.word;
            });
        case 'trivia':
          return allQuestions
            .filter(
              value =>
                value.question.trim() !== '' &&
                value.correct.trim() !== '' &&
                value.incorrect1.trim() !== '' &&
                value.incorrect2.trim() !== '' &&
                value.incorrect3.trim() !== ''
            )
            .map(value => {
              return {
                question: value.question,
                correct: value.correct,
                incorrect1: value.incorrect1,
                incorrect2: value.incorrect2,
                incorrect3: value.incorrect3
              };
            });
        case 'two_words_game':
          return allQuestions
            .filter(
              value => value.clue.trim() !== '' && value.word.trim() !== ''
            )
            .map(value => {
              return {
                clue: value.clue,
                word: value.word
              };
            });
        case 'draw_tell':
          return allQuestions
            .filter(value => value.topic.trim() !== '')
            .map(value => {
              return { topic: value.topic };
            });
        case 'one_to_hundred':
          return allQuestions
            .filter(
              value =>
                value.topic.trim() !== '' &&
                value.left.trim() !== '' &&
                value.right.trim() !== ''
            )
            .map(value => {
              return {
                topic: value.topic,
                left: value.left,
                right: value.right
              };
            });
        case 'yes_or_no':
          return allQuestions
            .filter(
              value => value.word.trim() !== '' && value.hint.trim() !== ''
            )
            .map(value => {
              return {
                word: value.word,
                hint: value.hint
              };
            });
        case 'funny_or_not':
          return allQuestions
            .filter(value => value.question.trim() !== '')
            .map(value => {
              return {
                question: value.question
              };
            });
        case 'poll':
          return allQuestions
            .filter(
              value =>
                value.question.trim() !== '' &&
                value.choices.length >= 2 &&
                value.choices.findIndex(
                  (choice: string) => choice.trim() === ''
                ) === -1
            )
            .map(value => {
              return {
                question: value.question,
                choices: value.choices
              };
            });
        case 'top5':
          return allQuestions
            .filter(
              value =>
                value.question.trim() !== '' &&
                value.answers.length == 5 &&
                value.answers.findIndex(
                  (answer: string) => answer.trim() === ''
                ) === -1
            )
            .map(value => {
              return {
                question: value.question,
                answers: value.answers,
                type: value.type
              };
            });
        default:
          return [];
      }
    };

    const onValue = function(index: number, value: any) {
      saved = false;
      checked.value[index - 1] = value['checked'];
      setQuestionValue(index, value);
      insufficientEntries.value =
        validQuestionCount.value < minimumEntriesForSuggestions;
    };

    const shownDialog = computed(() => {
      return (
        showUpdateTitleDescriptionModal.value ||
        showPreviewModal.value ||
        showPublishedModal.value ||
        showFailedModal.value ||
        showRemoveModal.value ||
        showCustomizedGameSettingsModal.value ||
        showConfirmBackModal.value
      );
    });

    watch(shownDialog, newValue => updateBodyCssForDialog(newValue));

    for (let i = 0; i < questionCount.value; i++) {
      switch (type) {
        case 'would_you_rather':
          questions.value.push(new WouldYouRatherQuestion({ key: i }));
          break;
        case 'question_of_the_day':
        case 'spot_my_lie':
        case 'draw_tell':
          questions.value.push(new SingleQuestion({ key: i }));
          break;
        case 'draw_it':
        case 'describe_it':
        case 'charades':
          questions.value.push(new Keyword({ key: i }));
          break;
        case 'trivia':
          questions.value.push(new TriviaQuestion({ key: i }));
          break;
        case 'two_words_game':
          questions.value.push(new MimicTwoWords({ key: i }));
          break;
        case 'one_to_hundred':
          questions.value.push(new OneToHundredTopic({ key: i }));
          break;
        case 'yes_or_no':
          questions.value.push(new YesOrNoTopic({ key: i }));
          break;
        case 'funny_or_not':
          questions.value.push(new FunnyOrNotQuestion({ key: i }));
          break;
        case 'poll':
          questions.value.push(new PollQuestion({ key: i, choices: ['', ''] }));
          break;
        case 'top5':
          questions.value.push(
            new Top5Question({ key: i, answers: ['', '', '', '', ''] })
          );
          break;
        default:
          break;
      }
      checked.value.push(false);
    }

    updateBodyCssForDialog(false);

    const passedDescription = computed(function() {
      if (updatedDescription.value.trim() == '') {
        return 'No description';
      }
      return updatedDescription.value;
    });

    function updateBodyCssForDialog(shown: boolean) {
      if (shown) {
        document.body.className += ' modal-open';
      } else {
        document.body.className = document.body.className.replaceAll(
          ' modal-open',
          ''
        );
      }
    }

    const getBadQuestions = function(allQuestions: any[], type: string): any[] {
      switch (type) {
        case 'would_you_rather':
          return allQuestions
            .filter(value => {
              const answers = [
                value.choice1.trim(),
                value.choice2.trim()
              ].filter(v => v !== '');
              return value.question.trim() !== '' && answers.length <= 1;
            })
            .map(value => value.index);
        case 'poll':
          return allQuestions
            .filter(
              value =>
                value.question.trim() !== '' &&
                value.choices.filter((choice: string) => choice.trim() !== '')
                  .length <= 1
            )
            .map(value => value.index);
        case 'top5':
          return allQuestions
            .filter(
              value =>
                value.question.trim() !== '' &&
                value.answers.filter((answer: string) => answer.trim() !== '')
                  .length < 5
            )
            .map(value => value.index);
        case 'trivia':
          return allQuestions
            .filter(value => {
              const answers = [
                value.correct.trim(),
                value.incorrect1.trim(),
                value.incorrect2.trim(),
                value.incorrect3.trim()
              ].filter(v => v !== '');
              return value.question.trim() !== '' && answers.length <= 3;
            })
            .map(value => value.index);
        case 'yes_or_no':
          return allQuestions
            .filter(value => {
              const words = [value.word.trim(), value.hint.trim()].filter(
                v => v !== ''
              );
              return words.length === 1;
            })
            .map(value => value.index);
        case 'two_words_game':
          return allQuestions
            .filter(value => {
              const words = [value.clue.trim(), value.word.trim()].filter(
                v => v !== ''
              );
              return words.length === 1;
            })
            .map(value => value.index);
        default:
          return [];
      }
    };

    const getFilteredQuestions = function(
      allQuestions: any[],
      type: string
    ): any[] {
      switch (type) {
        case 'would_you_rather':
          return allQuestions
            .filter(
              value =>
                value.question.trim() !== '' &&
                value.choice1.trim() !== '' &&
                value.choice2.trim() !== ''
            )
            .map(value => {
              return {
                question: value.question,
                choice1: value.choice1,
                choice2: value.choice2,
                index: value.index
              };
            });
        case 'question_of_the_day':
        case 'spot_my_lie':
          return allQuestions
            .filter(value => value.question.trim() !== '')
            .map(value => {
              return {
                question: value.question,
                index: value.index
              };
            });
        case 'draw_tell':
          return allQuestions
            .filter(value => value.topic.trim() !== '')
            .map(value => {
              return {
                topic: value.topic,
                index: value.index
              };
            });
        case 'draw_it':
        case 'describe_it':
        case 'charades':
          return allQuestions
            .filter(value => value.word.trim() !== '')
            .map(value => {
              return {
                word: value.word,
                index: value.index
              };
            });
        case 'trivia':
          return allQuestions
            .filter(
              value =>
                value.question.trim() !== '' &&
                value.correct.trim() !== '' &&
                value.incorrect1.trim() !== '' &&
                value.incorrect2.trim() !== '' &&
                value.incorrect3.trim() !== ''
            )
            .map(value => {
              return {
                question: value.question,
                answer: value.correct,
                choices: [
                  value.correct,
                  value.incorrect1,
                  value.incorrect2,
                  value.incorrect3
                ],
                index: value.index,
                imageData: value.imageData,
                image: value.image
              };
            });
        case 'two_words_game':
          return allQuestions
            .filter(
              value => value.clue.trim() !== '' && value.word.trim() !== ''
            )
            .map(value => {
              return {
                clue: value.clue,
                word: value.word,
                index: value.index
              };
            });
        case 'one_to_hundred':
          return allQuestions
            .filter(
              value =>
                value.topic.trim() !== '' &&
                value.left.trim() !== '' &&
                value.right.trim() !== ''
            )
            .map(value => {
              return {
                topic: value.topic,
                left: value.left,
                right: value.right,
                index: value.index
              };
            });
        case 'yes_or_no':
          return allQuestions
            .filter(
              value => value.word.trim() !== '' && value.hint.trim() !== ''
            )
            .map(value => {
              return {
                word: value.word,
                hint: value.hint,
                index: value.index
              };
            });
        case 'funny_or_not':
          return allQuestions
            .filter(value => value.question.trim() !== '')
            .map(value => {
              return {
                question: value.question,
                index: value.index,
                imageData: value.imageData,
                imagePath: value.imagePath
              };
            });
        case 'poll':
          return allQuestions
            .filter(
              value =>
                value.question.trim() !== '' &&
                value.choices.length >= 2 &&
                value.choices.findIndex(
                  (choice: string) => choice.trim() === ''
                ) === -1
            )
            .map(value => {
              return {
                question: value.question,
                choices: value.choices,
                index: value.index
              };
            });
        case 'top5':
          return allQuestions
            .filter(
              value =>
                value.question.trim() !== '' &&
                value.answers.length == 5 &&
                value.answers.findIndex(
                  (answer: string) => answer.trim() === ''
                ) === -1
            )
            .map(value => {
              return {
                question: value.question,
                answers: value.answers,
                index: value.index,
                type: value.type
              };
            });
        default:
          return [];
      }
    };

    const validQuestionCount = computed(function() {
      return getFilteredQuestions(questions.value, type).length;
    });

    const addPageDisabled = computed(function() {
      return pageN.value >= Math.floor((questionCount.value - 1) / pageSize);
    });

    const subPageDisabled = computed(function() {
      return pageN.value <= 0;
    });

    const showUpdateTitleDescription = function() {
      showUpdateTitleDescriptionModal.value = true;
    };

    const onUpdateTitleDescriptionClosed = function(
      title: string,
      description: string
    ) {
      if (title !== undefined && description !== undefined) {
        updatedTitle.value = title;
        updatedDescription.value = description;
      }
      showUpdateTitleDescriptionModal.value = false;
    };

    const showPreview = function() {
      showPreviewModal.value = true;
    };

    const onPreviewClosed = function() {
      showPreviewModal.value = false;
    };

    const showPublished = function(
      type: string,
      words: number,
      invalidQuestions: number[]
    ) {
      const leastCount = getGameLeastCountByType(type);
      if (leastCount && words < leastCount) {
        const suffix = questionBasedTypes.includes(type)
          ? 'questions'
          : 'words';
        warningMessage1.value = `We recommend having at least ${leastCount} ${suffix} to avoid mundane repetitions during gameplay.\n`;
      } else {
        warningMessage1.value = undefined;
      }
      if (invalidQuestions.length > 0) {
        warningMessage2.value =
          questionString(invalidQuestions) +
          (invalidQuestions.length === 1 ? 'is' : 'are') +
          ' incomplete and ' +
          (invalidQuestions.length === 1 ? 'it' : 'they') +
          ' will not appear in the game.';
      } else {
        warningMessage2.value = undefined;
      }
      saved = true;
      showPublishedModal.value = true;
    };

    const onPublishedClosed = function() {
      showPublishedModal.value = false;
      router.replace({
        name: 'EditCustomGame',
        query: {
          customGameId: customGameId
        }
      });
    };

    const showFailed = function() {
      showFailedModal.value = true;
    };

    const onFailedClosed = function() {
      showFailedModal.value = false;
    };

    const showRemove = function() {
      showRemoveModal.value = true;
    };

    const onRemoveClosed = function() {
      showRemoveModal.value = false;
    };

    const onRemove = function() {
      saved = false;
      for (let i = questionCount.value - 1; i >= 0; i--) {
        if (checked.value[i]) {
          questions.value.splice(i, 1);
          checked.value.splice(i, 1);
          questionCount.value--;
        }
      }
      showRemoveModal.value = false;
    };

    const showConfirmBack = function() {
      showConfirmBackModal.value = true;
    };

    const onConfirmBackClosed = function() {
      showConfirmBackModal.value = false;
    };

    function onBeginnerIntroductionStart() {
      showBeginnerIntroductionModal.value = false;
      startCustomGame();
    }

    function onBeginnerIntroductionClosed() {
      showBeginnerIntroductionModal.value = false;
    }

    function onImageUploadingModalShow() {
      showImageUploadingModal.value = true;
    }

    function onImageUploadingModalClosed() {
      showImageUploadingModal.value = false;
    }

    const onBack = function() {
      showConfirmBackModal.value = false;
      backConfirmed = true;
      onNext();
    };
    const onOrderTab = function() {
      isOrderTabOpened.value = !isOrderTabOpened.value;
    };
    const onCloseOrderTab = function() {
      isOrderTabOpened.value = false;
    };
    const onShuffleOption = function() {
      playOrderSelected.value = 0;
      isOrderTabOpened.value = false;
    };
    const onSameOrderOption = function() {
      playOrderSelected.value = 1;
      isOrderTabOpened.value = false;
    };

    const onAddQuestion = function() {
      saved = false;
      const newCheckedToAdd = new Array(questionsToAddPageSize);
      for (let i = 0; i < questionsToAddPageSize; i++) {
        switch (type) {
          case 'would_you_rather':
            questions.value.push(
              new WouldYouRatherQuestion({
                key:
                  questions.value.length > 0
                    ? questions.value[questions.value.length - 1].key + 1
                    : 0
              })
            );
            break;
          case 'question_of_the_day':
          case 'spot_my_lie':
          case 'draw_tell':
            questions.value.push(
              new SingleQuestion({
                key:
                  questions.value.length > 0
                    ? questions.value[questions.value.length - 1].key + 1
                    : 0
              })
            );
            break;
          case 'draw_it':
          case 'describe_it':
          case 'charades':
            questions.value.push(
              new Keyword({
                key:
                  questions.value.length > 0
                    ? questions.value[questions.value.length - 1].key + 1
                    : 0
              })
            );
            break;
          case 'trivia':
            questions.value.push(
              new TriviaQuestion({
                key:
                  questions.value.length > 0
                    ? questions.value[questions.value.length - 1].key + 1
                    : 0
              })
            );
            break;
          case 'two_words_game':
            questions.value.push(
              new MimicTwoWords({
                key:
                  questions.value.length > 0
                    ? questions.value[questions.value.length - 1].key + 1
                    : 0
              })
            );
            break;
          case 'one_to_hundred':
            questions.value.push(
              new OneToHundredTopic({
                key:
                  questions.value.length > 0
                    ? questions.value[questions.value.length - 1].key + 1
                    : 0
              })
            );
            break;
          case 'yes_or_no':
            questions.value.push(
              new YesOrNoTopic({
                key:
                  questions.value.length > 0
                    ? questions.value[questions.value.length - 1].key + 1
                    : 0
              })
            );
            break;
          case 'funny_or_not':
            questions.value.push(
              new FunnyOrNotQuestion({
                key:
                  questions.value.length > 0
                    ? questions.value[questions.value.length - 1].key + 1
                    : 0
              })
            );
            break;
          case 'poll':
            questions.value.push(
              new PollQuestion({
                key:
                  questions.value.length > 0
                    ? questions.value[questions.value.length - 1].key + 1
                    : 0,
                choices: ['', '']
              })
            );
            break;
          case 'top5':
            questions.value.push(
              new Top5Question({
                key:
                  questions.value.length > 0
                    ? questions.value[questions.value.length - 1].key + 1
                    : 0,
                answers: ['', '', '', '', '']
              })
            );
            break;
          default:
            break;
        }
      }

      questionCount.value = questionCount.value + questionsToAddPageSize;
      checked.value.push(...newCheckedToAdd.fill(false));

      setTimeout(() => {
        VueScrollTo.scrollTo(
          `#item-${Math.floor((questionCount.value - 1) / pageSize) * pageSize +
            1}`,
          200,
          {
            offset: -140
          }
        );
      }, 100);
    };

    const onAddPage = function() {
      if (pageN.value < Math.floor((questionCount.value - 1) / pageSize)) {
        const prev = pageN.value + 1;
        VueScrollTo.scrollTo(`#item-${(pageN.value + 1) * pageSize + 1}`, 200, {
          offset: -140
        });
        setTimeout(() => {
          pageN.value = prev;
        }, 200);
      }
    };

    const onSubPage = function() {
      if (pageN.value > 0) {
        const prev = pageN.value - 1;
        VueScrollTo.scrollTo(`#item-${(pageN.value - 1) * pageSize + 1}`, 200, {
          offset: -140
        });
        setTimeout(() => {
          pageN.value = prev;
        }, 200);
      }
    };

    const onScroll = function() {
      const result = {} as any;
      for (let i = 0; i < questionCount.value; i++) {
        const elem = document.getElementById(`item-${i + 1}`);
        if (elem && isElemVisible(elem)) {
          const n = Math.floor(i / pageSize);
          result[n] = (result[n] ?? 0) + 1;
        }
      }
      let value = 0,
        max = 0;
      for (const key of Object.keys(result)) {
        const n = Number.parseInt(key);
        if (
          result[key] / Math.min(pageSize, questionCount.value - n * pageSize) >
          max
        ) {
          max =
            result[key] /
            Math.min(pageSize, questionCount.value - n * pageSize);
          value = n;
        }
      }
      pageN.value = value;
    };

    const onUncheckAll = function() {
      checked.value = Array.from({ length: questionCount.value }, () => false);
    };

    const isElemVisible = function(el: any) {
      var rect = el.getBoundingClientRect();
      var elemTop = rect.top;
      var elemBottom = rect.bottom;
      return elemTop >= 105 && elemBottom < window.innerHeight;
    };

    const pageString = computed(() => {
      const lastN = Math.min((pageN.value + 1) * pageSize, questionCount.value);
      return `${pageN.value * pageSize + 1}-${lastN} of ${questionCount.value}`;
    });

    const pageRange = computed(() => {
      const array = [] as any[];
      const lastN = Math.ceil(questionCount.value / 3);
      for (let i = 0; i < lastN; i++) {
        array.push(i * 3 + 1);
      }
      return array;
    });

    const subPageRange = function(index: number) {
      const array = [] as any[];
      const lastN = Math.min(index + 3 - 1, questionCount.value);
      for (let i = index; i <= lastN; i++) {
        array.push(i);
      }
      return array;
    };

    const checkCount = computed(() => {
      return checked.value.filter(v => v).length;
    });

    const saveAndPublishButtonClass = computed(() => {
      if (saveAndPublishInProgress.value)
        return ['btn', 'btn-md', 'btn-orange', 'loading'];
      const questionsToBeSubmitted = getFilteredQuestions(
        questions.value,
        type
      );
      if (
        questionCount.value < minimumQuestions ||
        questionsToBeSubmitted.length < minimumQuestions
      )
        return ['btn', 'btn-md', 'btn-orange', 'disabled'];
      return ['btn', 'btn-md', 'btn-orange'];
    });

    const isPendingImagesMoreThan30 = computed(() => {
      const questionsToBeSubmitted = getFilteredQuestions(
        questions.value,
        type
      );
      return (
        questionsToBeSubmitted.filter(question => question.imageData).length >=
        30
      );
    });

    const saveAndPublish = async function() {
      if (saveAndPublishButtonClass.value.includes('disabled')) {
        showFailed();
        return;
      }
      try {
        // add loading class to the button
        saveAndPublishInProgress.value = true;
        const questionsToBeSubmitted = getFilteredQuestions(
          questions.value,
          type
        );
        const badQuestionIndicies = getBadQuestions(questions.value, type);
        const batch = firebaseApp.firestore().batch();
        const documentIdsToWrite = [] as any[];
        const latestTitle = updatedTitle.value ?? '';
        const latestDescription = updatedDescription.value ?? '';
        const created_by = user.value!.uid;
        const creator = user.value!.displayName ?? 'Anonymous';

        // new game creation
        const userDocQuery = await firebaseApp
          .firestore()
          .collection('minigame_user')
          .doc(created_by)
          .get();
        const userDoc = userDocQuery.data();

        uploadingImageCount.value = questionsToBeSubmitted.filter(
          question => question.imageData
        ).length;
        uploadedImageCount.value = 0;
        if (uploadingImageCount.value > 0) {
          onImageUploadingModalShow();
        }
        for (const question of questionsToBeSubmitted) {
          const docRef = firebaseApp
            .firestore()
            .collection('minigame_custom_game_public')
            .doc(customGameId)
            .collection('question_bank')
            .doc();
          const questionTemp = undefinedToNull(question);
          if (question.imageData) {
            const blob = base64toBlob(question.imageData);
            const uploadTask = await firebaseApp
              .storage()
              .ref(`minigame_custom_game_public/${customGameId}/${docRef.id}`)
              .put(blob);
            const imageUrl = await uploadTask.ref.getDownloadURL();
            if (type === 'trivia') {
              questionTemp.image = imageUrl;
            } else if (type === 'funny_or_not') {
              questionTemp.imagePath = imageUrl;
            }
            uploadedImageCount.value++;
          }
          if (type === 'top5') {
            let newQuestion = {
              question: question['question'],
              type: question['type'] ?? 'fact',
              answer1: question['answers'][0],
              answer2: question['answers'][1],
              answer3: question['answers'][2],
              answer4: question['answers'][3],
              answer5: question['answers'][4],
              index: question['index'],
              image: null,
              imageData: null,
              imagePath: null
            };
            batch.set(docRef, newQuestion);
          } else {
            questionTemp.imageData = null;
            batch.set(docRef, questionTemp);
          }
          documentIdsToWrite.push(docRef.id);
        }
        batch.set(
          firebaseApp
            .firestore()
            .collection('minigame_custom_game_public')
            .doc(customGameId),
          {
            created_by: created_by,
            creator: creator,
            creatorSurvey1Answer:
              userDoc!.survey1Answer !== undefined
                ? userDoc!.survey1Answer
                : null,
            customGameIcon: null,
            customGameId: customGameId,
            customGameName: latestTitle,
            dateCreated: firebaseApp.firestore.FieldValue.serverTimestamp(),
            dateUpdated: firebaseApp.firestore.FieldValue.serverTimestamp(),
            defaultIconColorNumber: iconColorNumber,
            description: latestDescription,
            gameName: type,
            gameplays: 0,
            isEditorsPick: false,
            likes: 0,
            question_bank_play_ids: documentIdsToWrite,
            privacyType: 'public',
            playOrder: playOrder.value
          }
        );
        await batch.commit();

        if (
          uploadingImageCount.value > 0 &&
          uploadingImageCount.value == uploadedImageCount.value
        ) {
          onImageUploadingModalClosed();
        }

        const isWordBased =
          type == 'draw_it' ||
          type == 'describe_it' ||
          type == 'two_words_game' ||
          type == 'charades';
        const entriesList = [] as any[];
        for (const question of questionsToBeSubmitted) {
          if (isWordBased) {
            if (type === 'two_words_game') {
              entriesList.push(`${question.word} / ${question.clue}`);
            } else entriesList.push(question.word);
          } else {
            if (type == 'would_you_rather') {
              let ques: string = question['question'];
              let choice1: string = question['choice1'];
              let choice2: string = question['choice2'];
              entriesList.push(`${ques} ${choice1} or ${choice2}`);
            } else if (type == 'draw_tell') {
              entriesList.push(question['topic']);
            } else entriesList.push(question['question']);
          }
        }
        amplitudeEvent('saveAndPublish', {
          gameType: type,
          name: latestTitle,
          purpose: 'create',
          numEntries: documentIdsToWrite.length,
          eventTime: new Date(Date.now()).toISOString()
        });
        saveAndPublishInProgress.value = false;
        currentCustomGameDocId.value = customGameId;
        showPublished(type, questionsToBeSubmitted.length, badQuestionIndicies);
        return;
      } catch (err) {
        // show error message box
        console.error(err);
        saveAndPublishInProgress.value = false;
      }
    };

    async function startCustomGame() {
      if (
        isFreePlan(subscriptionDetails.value) ||
        isExpiredFreeTrialPlan(subscriptionDetails.value, userDoc.value)
      ) {
        const subscriptionEnded =
          subscriptionDetails.value.subscriptionEnded ?? false;
        goToPricing(
          subscriptionEnded ? 'subscription-ended' : 'free-trial-ended'
        );
        return;
      } else if (user.value!.displayName === null) {
        showBeginnerIntroductionModal.value = true;
        return;
      }
      isLoading.value = true;

      const launchOptions: LaunchOptions = {
        activityType: ActivityType.GAME,
        chosenGame: type,
        createGameSource: 'dashboard',
        customGameId: customGameId,
        customGameName: updatedTitle.value
      };
      if (!hasActiveGame()) {
        await onCustomGameStart({
          ...launchOptions,
          subscriptionLevel: subscriptionDetails.value.subscriptionLevel,
          zoomClientConfig: store.state.zoomClientStatus,
          startFresh: false,
          customGameId: customGameId,
          customGameName: updatedTitle.value
        });
      } else {
        showPublishedModal.value = false;
        modalTypeShown.value = ['launch', launchOptions];
      }

      isLoading.value = false;
    }

    function goToPricing(source: string) {
      router.push({
        name: 'Pricing',
        query: { source: source }
      });
    }
    const onPreventNav = function(event: any) {
      if (!saved) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    function setQuestionValue(index: number, value: any) {
      if (!value['index']) value['index'] = index;
      switch (type) {
        case 'would_you_rather':
          questions.value[index - 1].question = capitalizeFirstLetter(
            value['question']
          );
          questions.value[index - 1].choice1 = value['choice1'];
          questions.value[index - 1].choice2 = value['choice2'];
          questions.value[index - 1].index = value['index'];
          break;
        case 'question_of_the_day':
        case 'spot_my_lie':
          questions.value[index - 1].question = capitalizeFirstLetter(
            value['question']
          );
          questions.value[index - 1].index = value['index'];
          break;
        case 'draw_tell':
          questions.value[index - 1].topic = capitalizeFirstLetter(
            value['question']
          );
          questions.value[index - 1].index = value['index'];
          break;
        case 'draw_it':
        case 'describe_it':
        case 'charades':
          questions.value[index - 1].word = value['word'];
          questions.value[index - 1].index = value['index'];
          break;
        case 'trivia':
          questions.value[index - 1].question = capitalizeFirstLetter(
            value['question']
          );
          questions.value[index - 1].correct = value['correct'];
          questions.value[index - 1].incorrect1 = value['incorrect1'];
          questions.value[index - 1].incorrect2 = value['incorrect2'];
          questions.value[index - 1].incorrect3 = value['incorrect3'];
          questions.value[index - 1].image = value['image'];
          questions.value[index - 1].imageData = value['imageData'];
          questions.value[index - 1].index = value['index'];
          break;
        case 'two_words_game':
          questions.value[index - 1].clue = value['clue'];
          questions.value[index - 1].word = value['word'];
          questions.value[index - 1].index = value['index'];
          break;
        case 'one_to_hundred':
          questions.value[index - 1].topic = capitalizeFirstLetter(
            value['topic']
          );
          questions.value[index - 1].left = capitalizeFirstLetter(
            value['left']
          );
          questions.value[index - 1].right = capitalizeFirstLetter(
            value['right']
          );
          questions.value[index - 1].index = value['index'];
          break;
        case 'yes_or_no':
          questions.value[index - 1].word = capitalizeFirstLetter(
            value['word']
          );
          questions.value[index - 1].hint = capitalizeFirstLetter(
            value['hint']
          );
          questions.value[index - 1].index = value['index'];
          break;
        case 'funny_or_not':
          questions.value[index - 1].question = capitalizeFirstLetter(
            value['question']
          );
          questions.value[index - 1].imagePath = value['imagePath'];
          questions.value[index - 1].imageData = value['imageData'];
          questions.value[index - 1].index = value['index'];
          break;
        case 'poll':
          questions.value[index - 1].question = capitalizeFirstLetter(
            value['question']
          );
          questions.value[index - 1].choices = value['choices'];
          questions.value[index - 1].index = value['index'];
          break;
        case 'top5':
          questions.value[index - 1].question = capitalizeFirstLetter(
            value['question']
          );
          questions.value[index - 1].answers = value['answers'];
          questions.value[index - 1].index = value['index'];
          questions.value[index - 1].type = value['type'];
          break;
        default:
          break;
      }
    }

    onBeforeMount(() => {
      window.addEventListener('scroll', onScroll);
      window.addEventListener('beforeunload', onPreventNav);
      window.scrollTo(0, 0);
      if (useAi) document.body.className += ' show-with-ai-panel';
    });

    onBeforeUnmount(() => {
      document.body.className = document.body.className.replaceAll(
        ' show-with-ai-panel',
        ''
      );
    });

    onBeforeUnmount(() => {
      window.removeEventListener('beforeunload', onPreventNav);
    });

    onBeforeRouteLeave((_, __, next) => {
      onNext = next;
      if (type && !backConfirmed && !saved) {
        showConfirmBack();
        return;
      }
      next();
    });

    return {
      modalTypeShown,
      isBrightful,
      fileinput,
      openFile,
      handleFileUpload,
      //
      type,
      showUpdateTitleDescriptionModal,
      showPreviewModal,
      showPublishedModal,
      showFailedModal,
      showRemoveModal,
      showCustomizedGameSettingsModal,
      showConfirmBackModal,
      questionCount,
      validQuestionCount,
      questions,
      checked,
      pageN,
      iconColorNumber,
      minimumQuestions,
      addPageDisabled,
      subPageDisabled,
      //
      showBeginnerIntroductionModal,
      onBeginnerIntroductionStart,
      onBeginnerIntroductionClosed,
      //
      showImageUploadingModal,
      onImageUploadingModalClosed,
      onImageUploadingModalShow,
      //

      isLoading,
      currentCustomGameDocId,
      getGameTitleByType,
      customGameWithOrderTypes,
      passedDescription,
      updatedTitle,
      updatedDescription,
      showUpdateTitleDescription,
      onUpdateTitleDescriptionClosed,
      showPreview,
      onPreviewClosed,
      showPublished,
      onPublishedClosed,
      warningMessage1,
      warningMessage2,
      showFailed,
      onFailedClosed,
      showRemove,
      onRemoveClosed,
      showConfirmBack,
      onConfirmBackClosed,
      onBack,
      onValue,
      onAddQuestion,
      onAddPage,
      onSubPage,
      onRemove,
      onUncheckAll,
      isElemVisible,
      pageString,
      pageRange,
      subPageRange,
      checkCount,
      saveAndPublishInProgress,
      saveAndPublishButtonClass,
      saveAndPublish,
      onStart,
      startCustomGame,
      currentPool,
      fetchingSuggestions,
      onPicked,
      onReport,
      onRefresh,
      useAi,
      fetchFromCurrentWords,
      currentPrompt,
      suggestionLimitReached,
      insufficientEntries,
      isSuggestionUnsafe,
      isOrderTabOpened,
      playOrderSelected,
      onOrderTab,
      onCloseOrderTab,
      onShuffleOption,
      onSameOrderOption,
      subscriptionDetails,
      uploadingImageCount,
      uploadedImageCount,
      isPendingImagesMoreThan30,
      goToPricing
    };
  }
});
