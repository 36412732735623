
import { defineComponent, ref } from 'vue';
import HistoryModalDrawItComponent from './HistoryModalComponent/HistoryModalDrawItComponent.vue';
import HistoryModalWouldYouRatherComponent from './HistoryModalComponent/HistoryModalWouldYouRatherComponent.vue';
import HistoryModalPollComponent from './HistoryModalComponent/HistoryModalPollComponent.vue';
import HistoryModalScoreboardComponent from './HistoryModalComponent/HistoryModalScoreboardComponent.vue';
import HistoryModalFunnyOrNotComponent from './HistoryModalComponent/HistoryModalFunnyOrNotComponent.vue';
import HistoryModalWerewolfComponent from './HistoryModalComponent/HistoryModalWerewolfComponent.vue';
import HistoryModalQOfTheDayComponent from './HistoryModalComponent/HistoryModalQOfTheDayComponent.vue';
import HistoryModalDrawTellComponent from './HistoryModalComponent/HistoryModalDrawTellComponent.vue';
import HistoryModalOneToHundredComponent from './HistoryModalComponent/HistoryModalOneToHundredComponent.vue';
import HistoryModalTwoWordsComponent from './HistoryModalComponent/HistoryModalTwoWordsComponent.vue';
import HistoryModalGuidedMeditationComponent from './HistoryModalComponent/HistoryModalGuidedMeditationComponent.vue';
import HistoryModalCardGameComponent from './HistoryModalComponent/HistoryModalCardGameComponent.vue';
import HistoryModalSpotMyLieComponent from './HistoryModalComponent/HistoryModalSpotMyLieComponent.vue';
import HistoryModalTop5Component from './HistoryModalComponent/HistoryModalTop5Component.vue';
import { cardGameTypes, scoreboardGameTypes } from '@/utils/gameConstants';
import { amplitudeEvent } from '@/config/amplitudeConfig';

export default defineComponent({
  name: 'HistoryModal',
  components: {
    HistoryModalDrawItComponent,
    HistoryModalWouldYouRatherComponent,
    HistoryModalPollComponent,
    HistoryModalScoreboardComponent,
    HistoryModalFunnyOrNotComponent,
    HistoryModalWerewolfComponent,
    HistoryModalQOfTheDayComponent,
    HistoryModalDrawTellComponent,
    HistoryModalOneToHundredComponent,
    HistoryModalTwoWordsComponent,
    HistoryModalGuidedMeditationComponent,
    HistoryModalCardGameComponent,
    HistoryModalSpotMyLieComponent,
    HistoryModalTop5Component
  },
  props: {
    game: {
      type: Object,
      required: true
    }
  },
  emits: ['close'],
  setup(props, context) {
    const show = ref(true);

    const close = function() {
      context.emit('close');
    };

    amplitudeEvent('visitGameHistoryDetailPage', {
      eventTime: new Date(Date.now()).toISOString(),
      game: props.game.gameName
    });

    return {
      show,
      close,
      scoreboardGameTypes,
      cardGameTypes
    };
  }
});
