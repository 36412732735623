import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: _ctx.fromHost ? 'message-item from-host' : 'message-item'
  }, [
    _createVNode("h5", null, _toDisplayString(_ctx.fromHost ? `${_ctx.message.fromMember.name} (Host)` : _ctx.message.fromMember.name), 1),
    _createVNode("p", {
      id: _ctx.message.id
    }, [
      _createVNode("pre", null, _toDisplayString(_ctx.message.body), 1)
    ], 8, ["id"])
  ], 2))
}