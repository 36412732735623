
import { amplitudeEvent } from '@/config/amplitudeConfig';

import { SpinTheWheel } from '@/models/event_tools/spin_the_wheel/spin_the_wheel_model';
import { store } from '@/store';
import firebase from 'firebase';
import { onMounted } from 'vue';
import { computed, defineComponent, Ref, ref } from 'vue';
export default defineComponent({
  name: 'CreateWheelModal',

  props: {},
  emits: ['close'],

  setup(_, context) {
    const userDoc = computed(() => store.getters.getUserDoc);
    const isButtonLoading: Ref<boolean> = ref(false);
    const wheelData: Ref<SpinTheWheel> = ref({
      dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
      launchedCount: 0,
      entries: ['', ''],
      question: ''
    });
    const wheelEntries = ref([{ text: '' }, { text: '' }]);

    const maxEntries = ref(100);
    function onClose() {
      context.emit('close');
    }
    onMounted(() => {
      document.getElementById('title')?.focus();
    });
    function onAdd() {
      if (wheelEntries.value.length >= maxEntries.value) return;
      wheelEntries.value.push({ text: '' });
      setTimeout(function() {
        document
          .getElementById(`entry_${wheelEntries.value.length - 1}`)
          ?.focus();
      }, 200);
    }
    function onDelete(index: number) {
      if (wheelEntries.value.length <= 2) return;
      wheelEntries.value.splice(index, 1);
    }
    const validForm = computed(() => {
      if (!wheelData.value.question || wheelData.value.question?.trim() === '')
        return false;
      const nonEmptyEntries = wheelEntries.value.filter(
        entry => entry?.text && entry.text.trim() !== ''
      );
      if (!wheelEntries.value || nonEmptyEntries.length < 2) return false;

      return true;
    });

    const hasUniqueOptions = computed(() => {
      let entryText = wheelEntries.value
        .filter(option => option?.text && option.text.trim() != '')
        .map(option => option.text);
      return new Set(entryText).size == entryText.length;
    });
    async function onSave() {
      if (!validForm.value) {
        console.log('Form incomplete');
        return;
      }
      isButtonLoading.value = true;
      const nonEmptyEntries = wheelEntries.value.filter(
        entry => entry?.text && entry.text.trim() !== ''
      );
      console.log(nonEmptyEntries);
      wheelEntries.value = nonEmptyEntries;
      wheelData.value.entries = wheelEntries.value.map(entry => entry.text);
      console.log('adding new wheel');
      await firebase
        .firestore()
        .collection(
          `minigame_lobbies/${userDoc.value['defaultLobbyId']}/spin_the_wheel`
        )
        .add(wheelData.value);
      console.log('new wheel added');
      amplitudeEvent('saveWheel', {
        eventTime: new Date(Date.now()).toISOString()
      });
      isButtonLoading.value = false;
      onClose();
    }
    return {
      maxEntries,
      validForm,
      wheelData,
      wheelEntries,
      isButtonLoading,
      hasUniqueOptions,
      onClose,
      onSave,
      onAdd,
      onDelete
    };
  }
});
