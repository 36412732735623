import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("a", {
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChannel && _ctx.onChannel(...args)))
  }, [
    _createVNode("div", {
      class: _ctx.hasNewMessage ? 'name-box-item has-new-message' : 'name-box-item'
    }, [
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock("div", _hoisted_1))
        : (_openBlock(), _createBlock("div", _hoisted_2, [
            _createVNode("h5", null, _toDisplayString(_ctx.otherName), 1),
            _createVNode("p", null, _toDisplayString(_ctx.firstMessage?.body), 1)
          ]))
    ], 2)
  ]))
}