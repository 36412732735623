/* eslint-disable no-console */
// https://stackoverflow.com/questions/57782617/how-to-remove-pwa-service-worker-and-its-cache-from-client-browser-after-remove

if (process.env.NODE_ENV === 'production') {
  if ('caches' in window) {
    caches.keys().then(function(keyList) {
      return Promise.all(
        keyList.map(function(key) {
          return caches.delete(key);
        })
      );
    });
  }

  if (window.navigator && navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
      for (const registration of registrations) {
        registration.unregister();
      }
    });
  }

  // register(`${process.env.BASE_URL}service-worker.js`, {
  //   ready() {
  //     console.log(
  //       'App is being served from cache by a service worker.\n' +
  //         'For more details, visit https://goo.gl/AFskqB'
  //     );
  //   },
  //   registered() {
  //     console.log('Service worker has been registered.');
  //   },
  //   cached() {
  //     console.log('Content has been cached for offline use.');
  //   },
  //   updatefound() {
  //     console.log('New content is downloading.');
  //   },
  //   updated() {
  //     console.log('New content is available; please refresh.');
  //   },
  //   offline() {
  //     console.log(
  //       'No internet connection found. App is running in offline mode.'
  //     );
  //   },
  //   error(error) {
  //     console.error('Error during service worker registration:', error);
  //   }
  // });
}
