export enum ActivityType {
  LIVE_POLL = 'live_poll',
  QNA = 'qna',
  SPIN_THE_WHEEL = 'spin_the_wheel',
  GAME = 'game'
}
export enum GameName {
  DRAW_IT = 'draw_it',
  TWO_WORDS_GAME = 'two_words_game',
  WEREWOLF_GAME = 'werewolf_game',
  HEARTS_GAME = 'hearts_game',
  WOULD_YOU_RATHER = 'would_you_rather',
  DESCRIBE_IT = 'describe_it',
  SPOT_MY_LIE = 'spot_my_lie',
  TRIVIA = 'trivia',
  RUMMY_GAME = 'rummy_game',
  GIN_RUMMY_GAME = 'gin_rummy_game',
  QUESTION_OF_THE_DAY = 'question_of_the_day',
  DRAW_TELL = 'draw_tell',
  WORDS_OF_POWER = 'words_of_power',
  ONE_TO_HUNDRED = 'one_to_hundred',
  YES_OR_NO = 'yes_or_no',
  CHARADES = 'charades',
  FUNNY_OR_NOT = 'funny_or_not',
  POLL = 'poll',
  TOP5 = 'top5',
  LIVE_POLL_MULTIPLE_CHOICE = 'live_poll_multiple_choice',
  LIVE_POLL_RATING = 'live_poll_rating',
  LIVE_POLL_WORD_CLOUD = 'live_poll_word_cloud',
  LIVE_POLL_SHORT_ANSWER = 'live_poll_short_answer',
  QNA = 'qna',
  SPIN_THE_WHEEL = 'spin_the_wheel'
}
