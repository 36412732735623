import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ActivityDetailsView = _resolveComponent("ActivityDetailsView")
  const _component_EventToolsDetailsView = _resolveComponent("EventToolsDetailsView")
  const _component_BrightfulActivities = _resolveComponent("BrightfulActivities")
  const _component_BrightfulActivitiesTools = _resolveComponent("BrightfulActivitiesTools")

  return (_ctx.selectedType && _ctx.detailGameTypes.includes(_ctx.selectedType))
    ? (_openBlock(), _createBlock(_component_ActivityDetailsView, {
        key: 0,
        type: _ctx.selectedType,
        games: _ctx.filtered,
        onStart: _cache[1] || (_cache[1] = ($event: any) => (_ctx.startGame($event))),
        onCustomizedGameMore: _cache[2] || (_cache[2] = ($event: any) => (
      _ctx.showCustomizedGameExample($event[0], $event[1], $event[2])
    )),
        onCustomizedGameStart: _cache[3] || (_cache[3] = ($event: any) => (_ctx.startCustomizedGame($event))),
        onVideo: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showVideo($event))),
        onAbout: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showAbout($event)))
      }, null, 8, ["type", "games"]))
    : (_ctx.selectedType && _ctx.detailEventToolsTypes.includes(_ctx.selectedType))
      ? (_openBlock(), _createBlock(_component_EventToolsDetailsView, {
          key: 1,
          type: _ctx.selectedType,
          onLaunchModal: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onEventToolsLaunchModal($event)))
        }, null, 8, ["type"]))
      : (_ctx.showAllActivities)
        ? (_openBlock(), _createBlock(_component_BrightfulActivities, {
            key: 2,
            onAbout: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showAbout($event))),
            onViewDetails: _cache[8] || (_cache[8] = ($event: any) => (_ctx.viewDetails($event)))
          }))
        : (_openBlock(), _createBlock(_component_BrightfulActivitiesTools, {
            key: 3,
            onAbout: _cache[9] || (_cache[9] = ($event: any) => (_ctx.showAbout($event))),
            onViewDetails: _cache[10] || (_cache[10] = ($event: any) => (_ctx.viewDetails($event))),
            onStart: _cache[11] || (_cache[11] = ($event: any) => (_ctx.startGame($event))),
            onSelect: _cache[12] || (_cache[12] = ($event: any) => (_ctx.goToSelectedType($event))),
            onCustom: _cache[13] || (_cache[13] = ($event: any) => (_ctx.startCustomizedGame($event))),
            onLaunchModal: _cache[14] || (_cache[14] = ($event: any) => (_ctx.onEventToolsLaunchModal($event)))
          }))
}