
import { computed, defineComponent, Ref, ref } from 'vue';

import router from '@/router';
import { NavigationTabs } from '@/models/navigationTabs';
import { LaunchOptions } from '@/models/GameStartOptions';
import { store } from '@/store';
import { canAccessEventTools } from '@/utils/pricing_rules';
import { amplitudeEvent } from '@/config/amplitudeConfig';
import { EventToolsType } from '@/utils/event_tools_enums';
import QnaModal from '../EventTools/Qna/QnaModal.vue';
export default defineComponent({
  name: 'BrightfulEventTools',
  components: { QnaModal },
  props: {
    selectedType: {
      type: String
    }
  },
  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'launch-modal': (options: LaunchOptions) => true
  },
  setup(_, context) {
    const modalTypeShown: Ref<'qnaModal' | null> = ref(null);
    const subscriptionDetails = computed(
      () => store.getters.getSubscriptionDetails
    );

    amplitudeEvent('visitEventTools', {
      eventTime: new Date(Date.now()).toISOString()
    });

    function goToPricing() {
      const subscriptionEnded =
        subscriptionDetails.value.subscriptionEnded ?? false;
      router.push({
        name: 'Pricing',
        query: {
          source: subscriptionEnded ? 'subscription-ended' : 'free-trial-ended'
        }
      });
    }

    function onPoll() {
      if (canAccessEventTools(subscriptionDetails.value)) {
        router.push({
          query: { tab: NavigationTabs.HOME, type: EventToolsType.LIVE_POLL }
        });
      } else {
        goToPricing();
      }
    }
    function onSpinTheWheel() {
      if (canAccessEventTools(subscriptionDetails.value)) {
        router.push({
          query: {
            tab: NavigationTabs.HOME,
            type: EventToolsType.SPIN_THE_WHEEL
          }
        });
      } else {
        goToPricing();
      }
    }
    function onQnaClose() {
      modalTypeShown.value = null;
    }
    function onQna() {
      modalTypeShown.value = 'qnaModal';
    }
    function onLaunchModal(options: LaunchOptions) {
      modalTypeShown.value = null;
      context.emit('launch-modal', options);
    }

    return {
      modalTypeShown,
      onLaunchModal,
      onPoll,
      onQna,
      onQnaClose,
      onSpinTheWheel
    };
  }
});
