
import { computed, defineComponent, onBeforeMount, ref, watch } from 'vue';
import BounceLoading from '@/components/BounceLoading.vue';
import ZoomNoActivity from '@/components/Zoom/ZoomNoActivity.vue';
import WelcomeUserSurvey from '@/components/Onboarding/WelcomeUserSurvey.vue';
import RefreshModal from '@/components/Modal/RefreshModal.vue';
import Dashboard from '@/pages/Dashboard.vue';
import {
  getEndpoint,
  handleZoomOnSignedIn,
  handleZoomOnSignedOut,
  isDev,
  isPlayground,
  isProd,
  openUrlOnExternalBrowserFromZoom,
  redirectToLogin,
  redirectToPlay,
  zoomInstallationUrl,
  redirectToGameRoom,
  handlePlaygroundSignIn,
  playgroundDevs,
  configureGameRoomButton
} from '@/utils/helper';
import { eligibleForTeam, isFreePlan } from '@/utils/pricing_rules';
import firebase from '@/firebaseApp';
import useFirebaseDocument from '@/hooks/firebase/useFirebaseDocument';
import {
  amplitudeEvent,
  identifyAmplitude,
  initAmplitude
} from '@/config/amplitudeConfig';
import { useStore } from '@/store';
import {
  UPDATE_SURVEY_1_ANSWER,
  UPDATE_TEAM_INFO,
  UPDATE_USER,
  UPDATE_USER_DOC,
  UPDATE_USER_SUBSCRIPTION_DETAILS
} from '@/store/mutation-types';
import router from '@/router';
import useFirebaseLoginOut from '@/hooks/firebase/useFirebaseLoginOut';
import { hideFreshdesk } from '@/config/freshdeskConfig';
import useFirebaseCollection from '@/hooks/firebase/useFirebaseCollection';
import { TeamInfo } from '@/models/TeamInfo';
import { TeamRole } from '@/models/TeamRole';
import { uuid } from 'vue-uuid';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import usePostAPI from '@/hooks/usePostAPI';
import { WelcomeUserModelEmits } from '@/models/welcomeUserSurvey';
// import { NavigationTabs } from '@/models/navigationTabs';
const pRetry = require('p-retry');

export default defineComponent({
  name: 'DashboardEntry',
  emits: ['show-cancel-subscription', 'show-renew-subscription'],
  components: {
    BounceLoading,
    ZoomNoActivity,
    WelcomeUserSurvey,
    Dashboard,
    RefreshModal
  },
  setup() {
    const store = useStore();
    const playgroundDev: 'osho' | 'dag' | 'yugene' = 'osho';
    const midDoc = (mid: string) =>
      useFirebaseCollection(
        `zoom_meetings`,
        {
          onMounted: true,
          fieldPath: 'mid',
          opStr: '==',
          value: mid
        },
        true
      );
    const zoomClientStatus = store.state.zoomClientStatus!;
    const zoomHostName = zoomClientStatus?.hostName ?? undefined;
    if (
      zoomClientStatus.inZoomClient &&
      !zoomClientStatus.isHost &&
      zoomClientStatus.mid
    ) {
      watch(midDoc(zoomClientStatus.mid).collectionData, midsData => {
        if (midsData && midsData.length != 0) {
          const midData = midsData[0];
          const gameRoomId = midData.gameRoomId;
          if (gameRoomId) redirectToGameRoom(gameRoomId, false);
          hasZoomHostedGame.value = true;
        } else {
          hasZoomHostedGame.value = false;
        }
      });
    }

    const user = computed(() => store.getters.getUser);
    const userDoc = computed(() => store.getters.getUserDoc);
    const subscriptionDetails = computed(
      () => store.getters.getSubscriptionDetails
    );
    const userDocumentProps = useFirebaseDocument('minigame_user', {
      onMounted: false,
      documentId: undefined
    });
    const showInitialLoading = ref(true);
    const hasZoomHostedGame = ref(false);
    const showZoomWaiting = computed(
      () =>
        zoomClientStatus?.inZoomClient &&
        !zoomClientStatus?.isHost &&
        !hasZoomHostedGame.value
    );
    const showRefreshModal = ref(false);
    const showSignupSuccessModal = ref(false);
    const teamName = ref(undefined as string | undefined);

    // free trial related
    const isFreeTrialEnded = ref(true);
    const freeTrialEndDate = ref();
    const freeTrialEnded = ref();
    // survey
    const showWelcomeUserSurvey = ref(false);

    const isWaitingForZoomAuth = ref(false);

    onBeforeMount(async () => {});
    // async function checkFreeTrialPeriod(user: firebase.User) {
    function checkFreeTrialPeriod() {
      if (userDoc.value['freeTrialEndDate']) {
        freeTrialEndDate.value = (userDoc.value[
          'freeTrialEndDate'
        ] as firebase.firestore.Timestamp).toDate();
        freeTrialEnded.value = userDoc.value['freeTrialEnded'];
        if (freeTrialEndDate.value) {
          if (freeTrialEndDate.value <= Date.now() && freeTrialEnded.value) {
            // await user.getIdToken(true);
            // await userDocumentProps.getDocument(10, user.uid);
            // store.commit(UPDATE_USER, {
            //   newUser: user
            // });
            // store.commit(UPDATE_USER_DOC, {
            //   newUserDoc: userDocumentProps.documentData.value
            // });
            if (userDoc.value['survey1Answer']) {
              store.commit(UPDATE_SURVEY_1_ANSWER, {
                newSurvey1Answer: userDoc.value['survey1Answer']
              });
            }
            freeTrialEnded.value = true;
          }
        }
      }
    }

    async function configureTeamInfo() {
      const teamQuery = useFirebaseCollection('minigame_teams', {});
      if (eligibleForTeam(subscriptionDetails.value)) {
        identifyAmplitude('setOnce', 'teamRole', 'Owner');
        await teamQuery.getCollection({
          fieldPath: 'owner',
          opStr: '==',
          value: user.value.uid,
          limit: 1
        });
        const teams = teamQuery.collectionData.value ?? [];
        if (teams.length == 0) {
          identifyAmplitude('set', 'teamSize', 1);
          const newTeamInfo = new TeamInfo({
            id: firebase
              .firestore()
              .collection('minigame_teams')
              .doc().id,
            name: (user.value.displayName ?? '') + "'s Team",
            owner: user.value.uid,
            members: [
              {
                uid: user.value.uid,
                name: user.value.displayName ?? '',
                email: user.value.email,
                role: TeamRole.Owner
              }
            ],
            link: {
              url: uuid.v4(),
              enabled: true
            }
          });
          await firebase
            .firestore()
            .collection('minigame_teams')
            .doc(newTeamInfo.id)
            .set(Object.assign({}, newTeamInfo));
          store.commit(UPDATE_TEAM_INFO, {
            newTeamInfo: newTeamInfo
          });
        } else {
          const teamInfo = new TeamInfo(teams[0]);
          identifyAmplitude('set', 'teamSize', teamInfo.members.length);
          store.commit(UPDATE_TEAM_INFO, {
            newTeamInfo: teamInfo
          });
        }
      } else if (subscriptionDetails.value.teamId) {
        await teamQuery.getCollection({
          fieldPath: 'id',
          opStr: '==',
          value: subscriptionDetails.value.teamId,
          limit: 1
        });
        const teams = teamQuery.collectionData.value ?? [];
        if (teams.length != 0) {
          const teamInfo = new TeamInfo(teams[0]);
          store.commit(UPDATE_TEAM_INFO, {
            newTeamInfo: teamInfo
          });
        }
      }
    }

    async function handleSignedInUser(user: firebase.User) {
      var startTime = performance.now();
      console.log('DashboardEntry handleSignedInUser: running');
      hideFreshdesk();
      if (store.state.user === undefined) {
        store.commit(UPDATE_USER, {
          newUser: user
        });
      }
      console.log(user?.uid);
      if (userDoc.value === undefined || !userDoc.value['uid']) {
        await userDocumentProps.getDocument(10, user.uid);
        if (userDocumentProps.documentData.value === null) {
          console.log('Waiting 3s for initialization');
          await new Promise(resolve => setTimeout(resolve, 3000));
          await pRetry(
            async () => {
              await userDocumentProps.getDocument(10, user.uid);
              if (userDocumentProps.documentData.value === null) {
                throw new Error('user document not found');
              }
            },
            {
              onFailedAttempt: async (error: any) => {
                console.log(
                  `Attempt ${error.attemptNumber} failed. ${error.retriesLeft} retries left.`
                );
                console.log('Waiting 3s for another retry');
                await new Promise(resolve => setTimeout(resolve, 3000));
              },
              retries: 5
            }
          );
        }
        store.commit(UPDATE_USER_DOC, {
          newUserDoc: userDocumentProps.documentData.value
        });

        if (userDoc.value['survey1Answer']) {
          store.commit(UPDATE_SURVEY_1_ANSWER, {
            newSurvey1Answer: userDoc.value['survey1Answer']
          });
        }
      }
      if (!userDoc.value['id']) {
        showRefreshModal.value = true;
        initAmplitude();
        amplitudeEvent('firebaseOfflineError', {
          eventTime: new Date(Date.now()).toISOString()
        });
        // show dialog

        return;
      }

      if (subscriptionDetails.value.subscriptionLevel === undefined) {
        store.commit(UPDATE_USER_SUBSCRIPTION_DETAILS, {
          newSubscriptionDetails: await useFirebaseLoginOut().getSubscriptionDetails()
        });
      }

      await user.getIdToken(true);
      store.commit(UPDATE_USER, {
        newUser: user
      });
      // store.commit(UPDATE_USER_SUBSCRIPTION_DETAILS, {
      //   newSubscriptionDetails: await useFirebaseLoginOut().getSubscriptionDetails()
      // });

      if (userDoc.value['survey1Answer'] === undefined) {
        if (store.state.zoomClientStatus?.inZoomClient) {
          const fingerprintJsAgent = await FingerprintJS.load();
          const fingerprintResult = await fingerprintJsAgent.get();
          const urlParams = new URLSearchParams(window.location.search);
          const source = urlParams.get('source');
          const zoomSurvey1Answer = ['Business - Meeting'];
          const response = await usePostAPI(
            getEndpoint('userSurveyAndChecking'),
            {
              email: user.email,
              source: source,
              fingerprint: fingerprintResult.visitorId,
              survey1Answer: zoomSurvey1Answer,
              team: urlParams.get('team')
            }
          );
          store.commit(UPDATE_SURVEY_1_ANSWER, {
            newSurvey1Answer: zoomSurvey1Answer
          });
          console.log(response);
          store.commit(UPDATE_USER_SUBSCRIPTION_DETAILS, {
            newSubscriptionDetails: await useFirebaseLoginOut().getSubscriptionDetails()
          });
          await user.getIdToken(true);
          store.commit(UPDATE_USER, {
            newUser: user
          });
          showWelcomeUserSurvey.value = false;
          window.location.reload();
        } else {
          showWelcomeUserSurvey.value = true;
          document.body.className += ' bg-light-blue';
        }
      }
      const defaultLobbyId = userDoc.value['defaultLobbyId'];
      configureGameRoomButton(defaultLobbyId).then(async res => {
        if (!res) {
          // lobby is not there (Old accounts)
          const fingerprintJsAgent = await FingerprintJS.load();
          const fingerprintResult = await fingerprintJsAgent.get();
          const urlParams = new URLSearchParams(window.location.search);
          const source = urlParams.get('source');
          console.log('userSurveyAndChecking');
          await usePostAPI(getEndpoint('userSurveyAndChecking'), {
            email: user.email,
            source: source,
            fingerprint: fingerprintResult.visitorId,
            survey1Answer: userDoc.value['survey1Answer'],
            team: urlParams.get('team')
          });
          configureGameRoomButton(defaultLobbyId);
        }
      });

      // do free trial checking before getting custom claims from user
      // await checkFreeTrialPeriod(user);
      checkFreeTrialPeriod();
      if (
        isFreePlan(subscriptionDetails.value) &&
        userDoc.value['isFingerprintJSFlagged']
      ) {
        router.push({
          name: 'Pricing',
          query: { source: 'login' }
        });
        return;
      }
      // const urlParams = new URLSearchParams(window.location.search);
      // const source = urlParams.get('source');
      // if (source == 'slack') {
      //   router.push({ query: { tab: NavigationTabs.SLACK } });
      // }
      const zoomClientStatus = store.state.zoomClientStatus;
      if (zoomClientStatus?.inZoomClient) {
        if (zoomClientStatus.isHost) {
          isWaitingForZoomAuth.value = await handleZoomOnSignedIn(
            zoomClientStatus
          );
        }
      }

      console.log(isWaitingForZoomAuth.value);

      // log after confirming user is logged in
      initAmplitude();
      identifyAmplitude(
        'setOnce',
        'signUpDate',
        userDoc.value['dateCreated'].toDate().toISOString()
      );
      if (user.email) {
        identifyAmplitude(
          'setOnce',
          'emailDomain',
          user.email.substring(user.email.indexOf('@') + 1)
        );
      }
      await configureTeamInfo();
      var endTime = performance.now();
      console.log(
        `DashboardEntry handleSignedInUser: finished in ${endTime -
          startTime} milliseconds`
      );

      showInitialLoading.value = false;
    }

    async function handleSignedOutUser() {
      console.log('handling signed out user');
      const zoomClientStatus = store.state.zoomClientStatus;
      if (zoomClientStatus?.inZoomClient && !isWaitingForZoomAuth.value) {
        if (zoomClientStatus.isHost) {
          isWaitingForZoomAuth.value = await handleZoomOnSignedOut(
            zoomClientStatus
          );
        } else {
          showInitialLoading.value = false;
        }
      } else {
        if (isPlayground) {
          handlePlaygroundSignIn(playgroundDev);
          // showInitialLoading.value = false;
        } else if (isDev || isProd) {
          if (!showInitialLoading.value) {
            redirectToPlay('logout');
          } else {
            const urlParams = new URLSearchParams(window.location.search);
            const source = urlParams.get('source');
            const selectedAction = urlParams.get('selected');
            const customGameId = urlParams.get('customGame');
            const teamId = urlParams.get('team');
            if (source) {
              redirectToLogin('source', source);
            } else if (teamId !== null) {
              amplitudeEvent('startJoinTeam', {
                eventTime: new Date(Date.now()).toISOString()
              });
              redirectToLogin('team', teamId);
            } else if (customGameId !== null) {
              redirectToLogin('customGame', customGameId);
            } else if (selectedAction) {
              redirectToLogin('source', selectedAction);
            } else redirectToLogin();
          }
        }
      }
    }

    async function onZoomAuth() {
      const launchUrl = zoomInstallationUrl();
      await openUrlOnExternalBrowserFromZoom(launchUrl);
    }
    async function logRecommendedGame() {
      const recommendedGameQuery = useFirebaseCollection(
        'minigame_recommended_game',
        {
          onMounted: false
        }
      );
      await recommendedGameQuery.getCollection({
        orderBy: 'index',
        isDesc: false,
        limit: 1
      });
      const sourceSideBar = ref(
        new URLSearchParams(window.location.search).get('source') ??
          'recommended'
      );
      const sideBarSource = new Map([
        ['qotd-landing', 'question_of_the_day'],
        ['wyr-landing', 'would_you_rather'],
        ['trivia-landing', 'trivia'],
        ['drawit-landing', 'draw_it'],
        ['charades-landing', 'charades'],
        ['fon-landing', 'funny_or_not'],
        ['recommended', recommendedGameQuery.collectionData.value![0].gameName]
      ]);
      amplitudeEvent('arriveDashboardAfterSignUp', {
        gameRecommended:
          sideBarSource.get(sourceSideBar.value) ??
          recommendedGameQuery.collectionData.value![0].gameName,
        version: 'regular',
        eventTime: new Date(Date.now()).toISOString()
      });
    }

    async function onHideWelcomeUserSurvey(params: WelcomeUserModelEmits) {
      await userDocumentProps.getDocument(10, user.value.uid);
      store.commit(UPDATE_USER_DOC, {
        newUserDoc: userDocumentProps.documentData.value
      });
      if (userDoc.value['survey1Answer']) {
        store.commit(UPDATE_SURVEY_1_ANSWER, {
          newSurvey1Answer: userDoc.value['survey1Answer']
        });
      }
      store.commit(UPDATE_USER_SUBSCRIPTION_DETAILS, {
        newSubscriptionDetails: await useFirebaseLoginOut().getSubscriptionDetails()
      });
      showWelcomeUserSurvey.value = false;
      document.body.className = document.body.className.replaceAll(
        ' bg-light-blue',
        ''
      );
      if (params) {
        if (params.tab) {
          router.push({ query: { tab: params.tab } });
        }
        if (params.teamName) {
          teamName.value = params.teamName;
        }
        showSignupSuccessModal.value = true;
      } else {
        showSignupSuccessModal.value = true;
      }
      logRecommendedGame();
    }

    async function onHideWelcomeUserSurveyAndShowStarterPlan() {
      await userDocumentProps.getDocument(10, user.value.uid);
      store.commit(UPDATE_USER_DOC, {
        newUserDoc: userDocumentProps.documentData.value
      });
      if (userDoc.value['survey1Answer']) {
        store.commit(UPDATE_SURVEY_1_ANSWER, {
          newSurvey1Answer: userDoc.value['survey1Answer']
        });
      }
      store.commit(UPDATE_USER_SUBSCRIPTION_DETAILS, {
        newSubscriptionDetails: await useFirebaseLoginOut().getSubscriptionDetails()
      });
      isFreeTrialEnded.value = false;
      showWelcomeUserSurvey.value = false;
      router.push({
        name: 'Pricing',
        query: { source: 'login' }
      });
    }

    firebase.auth().onAuthStateChanged(async _user => {
      if (_user === null || _user.isAnonymous) {
        handleSignedOutUser();
      } else {
        if (
          isPlayground &&
          playgroundDevs[playgroundDev].email != _user.email
        ) {
          handlePlaygroundSignIn(playgroundDev);
        }
        handleSignedInUser(_user);
      }
    });

    return {
      user,
      showInitialLoading,
      showZoomWaiting,
      showRefreshModal,
      isWaitingForZoomAuth,
      //
      isFreeTrialEnded,
      //
      showSignupSuccessModal,
      teamName,
      zoomHostName,
      //
      showWelcomeUserSurvey,
      onZoomAuth,
      onHideWelcomeUserSurvey,
      onHideWelcomeUserSurveyAndShowStarterPlan
    };
  }
});
