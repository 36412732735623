
import { computed, defineComponent } from 'vue';
import GameInfo from '@/components/GameInfo.vue';
import GamePromotionBanner from '@/components/Home/GamePromotionBanner.vue';
import BrightfulEventTools from '@/components/Home/BrightfulEventTools.vue';
import router from '@/router';
import { previewGameTypes } from '@/utils/gameConstants';
import { useStore } from '@/store';
import { isFreePlan } from '@/utils/pricing_rules';
import { NavigationTabs } from '@/models/navigationTabs';

export default defineComponent({
  name: 'BrightfulActivitiesTools',
  emits: ['about', 'view-details', 'select', 'start', 'custom', 'launch-modal'],
  components: {
    GamePromotionBanner,
    GameInfo,
    BrightfulEventTools
  },
  props: {},
  setup(_, context) {
    const store = useStore();
    const subscriptionDetails = computed(
      () => store.getters.getSubscriptionDetails
    );

    function showAllActivities() {
      router.push({ query: { tab: NavigationTabs.HOME, show: 'all' } });
    }

    function viewDetails(event) {
      context.emit('view-details', event);
    }

    function showAbout(type: string) {
      context.emit('about', type);
    }

    function startGame(event) {
      context.emit('start', event);
    }

    function goToSelectedType(type: string) {
      context.emit('select', type);
    }

    function startCustomizedGame(event) {
      context.emit('custom', event);
    }

    function onLaunchModal(event) {
      context.emit('launch-modal', event);
    }

    return {
      isFreePlan,
      subscriptionDetails,
      previewGameTypes,
      showAllActivities,
      viewDetails,
      showAbout,
      startGame,
      goToSelectedType,
      startCustomizedGame,
      onLaunchModal
    };
  }
});
