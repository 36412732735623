
import LeaderboardTile from '@/components/HistoryLeaderboard/LeaderboardTile.vue';
import LeaderboardListSkeletonLoading from '@/components/HistoryLeaderboard/LeaderboardListSkeletonLoading.vue';
import { defineComponent } from 'vue';
import { amplitudeEvent } from '@/config/amplitudeConfig';
export default defineComponent({
  name: 'LeaderboardList',
  components: {
    LeaderboardTile,
    LeaderboardListSkeletonLoading
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    noPadding: {
      type: Boolean,
      required: true
    }
  },
  emits: ['open', 'upgrade', 'scroll'],
  setup(_, context) {
    function onOpenModal(type: string) {
      context.emit('open', type);
    }

    function onUpgrade() {
      context.emit('upgrade');
    }

    function saveScrollPosition(pos: number) {
      context.emit('scroll', pos);
    }

    amplitudeEvent('visitLeaderboard', {
      eventTime: new Date(Date.now()).toISOString()
    });

    return {
      onOpenModal,
      onUpgrade,
      saveScrollPosition
    };
  }
});
