
import { getGameTitleByType } from '@/utils/gameConstants';
import { defineComponent, ref } from 'vue';

import LivePollHistoryModal from '@/components/EventTools/LivePoll/Modal/History/LivePollHistoryModal.vue';
import { LivePollActivityHistory } from '@/models/event_tools/live_poll/live_poll_model';
import { eventToolsParticipantsWithoutHost } from '@/utils/helper';
export default defineComponent({
  name: 'HistoryLivePollTile',
  props: {
    game: Object as () => LivePollActivityHistory
  },
  emits: ['open'],
  setup(props) {
    const title = ref(getGameTitleByType(props.game!.gameName!));
    const dateString = ref(props.game!.dateCreated.format('MMM DD'));
    const openModal = ref(false);
    function onOpenModal() {
      openModal.value = true;
    }

    return {
      title,
      dateString,
      openModal,
      eventToolsParticipantsWithoutHost,
      onOpenModal
    };
  },
  components: { LivePollHistoryModal }
});
