
import { getGameTitleByType } from '@/utils/gameConstants';
import { capitalizeFirstLetter } from '@/utils/helper';
import { computed, defineComponent, ref } from 'vue';
import { useStore } from '@/store';

export default defineComponent({
  name: 'HistoryModalWerewolfComponent',
  components: {},
  props: {
    game: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const user = computed(() => store.getters.getUser);
    const title = getGameTitleByType(props.game.gameName);
    const customGameName = ref(props.game.customGameName);
    const customGameCss = ref(props.game.customGameCss);
    const dateString = ref(
      props.game.dateCreated.format('DD MMMM, YYYY - hh:mm A')
    );
    const hostName = ref(undefined as string | undefined);
    const isHostNotPlaying = ref(props.game.isHostPlaying === false);
    const status = ref('draw');
    const players = ref([] as any[]);

    function calculateResult() {
      if (props.game.host !== user.value.uid) {
        const host = props.game.players.find(
          player => player.playerId === props.game.host
        );
        if (host) {
          hostName.value = host.name;
        }
      }

      const actualPlayers = Object.assign(
        [],
        isHostNotPlaying.value
          ? props.game.players.filter(
              player => player.playerId !== props.game.host
            )
          : props.game.players
      );
      let aliveVillagers = 0,
        aliveWerewolves = 0;
      actualPlayers.map(player => {
        if (!player.role.includes('werewolf') && !player.isDead.isDead) {
          aliveVillagers++;
        }
        if (player.role.includes('werewolf') && !player.isDead.isDead) {
          aliveWerewolves++;
        }
        players.value.push({
          name: player.name,
          email: player.email,
          isDead: player.isDead.isDead,
          isVillager: !player.role.includes('werewolf'),
          role: capitalizeFirstLetter(player.role)
        });
      });
      if (aliveWerewolves === 0 && aliveVillagers === 0) {
        status.value = 'draw';
      } else if (aliveWerewolves === 0) {
        status.value = 'villager';
      } else {
        status.value = 'werewolf';
      }
      players.value.sort((a, b) => {
        if (a.isVillager && !b.isVillager) {
          return 1;
        }
        if (!a.isVillager && b.isVillager) {
          return -1;
        }
        return a.name.localeCompare(b.name);
      });
    }

    calculateResult();

    return {
      title,
      customGameName,
      customGameCss,
      dateString,
      hostName,
      isHostNotPlaying,
      status,
      players
    };
  }
});
