import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "popover-header"
}
const _hoisted_2 = { class: "popover-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: ["popover fade show", {
      'bs-popover-left': _ctx.position == 'left',
      'bs-popover-right': _ctx.position == 'right',
      'bs-popover-top': _ctx.position == 'top',
      'bs-popover-bottom': _ctx.position == 'bottom'
    }],
    role: "tooltip",
    style: 
      `position: absolute; 
        will-change: transform; 
        top: 0px; left: 0px; 
        transform: translate3d(${_ctx.x}px, ${_ctx.y}px, 0px); 
        max-width: ${_ctx.maxWidth}px;`
    
  }, [
    (_ctx.showArrow)
      ? (_openBlock(), _createBlock("div", {
          key: 0,
          class: "arrow",
          style: _ctx.arrowStyle
        }, null, 4))
      : _createCommentVNode("", true),
    (_ctx.title)
      ? (_openBlock(), _createBlock("h3", _hoisted_1, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    _createVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 6))
}