
import HistoryMonthList from '@/components/HistoryLeaderboard/HistoryMonthList.vue';
import HistoryLockTile from '@/components/HistoryLeaderboard/HistoryLockTile.vue';
import { computed, defineComponent } from 'vue';
import { useStore } from '@/store';

import { canAccessFullHistory } from '@/utils/pricing_rules';
import { amplitudeEvent } from '@/config/amplitudeConfig';

export default defineComponent({
  name: 'HistoryList',
  components: {
    HistoryMonthList,
    HistoryLockTile
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    hasHistoryMoreThan6: {
      type: Boolean,
      required: true
    }
  },
  emits: ['open', 'upgrade'],
  setup(_, context) {
    const store = useStore();
    const userDoc = computed(() => store.getters.getUserDoc);
    const subscriptionDetails = computed(
      () => store.getters.getSubscriptionDetails
    );
    const canAccessFullHistoryValue = computed(() => {
      return canAccessFullHistory(subscriptionDetails.value, userDoc.value);
    });

    function onOpenModal(game: any) {
      context.emit('open', game);
    }

    function onUpgrade() {
      context.emit('upgrade');
    }

    amplitudeEvent('visitGameHistory', {
      eventTime: new Date(Date.now()).toISOString()
    });

    return {
      canAccessFullHistoryValue,
      onOpenModal,
      onUpgrade
    };
  }
});
