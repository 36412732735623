import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, Teleport as _Teleport, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BounceLoading = _resolveComponent("BounceLoading")
  const _component_DashboardEntry = _resolveComponent("DashboardEntry")
  const _component_PricingEntry = _resolveComponent("PricingEntry")
  const _component_FeedbackEntry = _resolveComponent("FeedbackEntry")
  const _component_Whiteboard = _resolveComponent("Whiteboard")
  const _component_CreateCustomGame = _resolveComponent("CreateCustomGame")
  const _component_EditCustomGame = _resolveComponent("EditCustomGame")
  const _component_CancelSubscriptionModal = _resolveComponent("CancelSubscriptionModal")
  const _component_RenewSubscriptionModal = _resolveComponent("RenewSubscriptionModal")

  return (_openBlock(), _createBlock(_Fragment, null, [
    (_ctx.showInitialLoading)
      ? (_openBlock(), _createBlock(_component_BounceLoading, { key: 0 }))
      : (_ctx.routePath == _ctx.RoutePath.Dashboard)
        ? (_openBlock(), _createBlock(_component_DashboardEntry, {
            key: 1,
            onShowCancelSubscription: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showCancelSubscriptionModal = true)),
            onShowRenewSubscription: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showRenewSubscriptionModal = true))
          }))
        : (_ctx.routePath == _ctx.RoutePath.Pricing)
          ? (_openBlock(), _createBlock(_component_PricingEntry, {
              key: 2,
              onShowCancelSubscription: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showCancelSubscriptionModal = true)),
              onShowRenewSubscription: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showRenewSubscriptionModal = true))
            }))
          : (_ctx.routePath == _ctx.RoutePath.Feedback)
            ? (_openBlock(), _createBlock(_component_FeedbackEntry, {
                key: 3,
                onShowCancelSubscription: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showCancelSubscriptionModal = true)),
                onShowRenewSubscription: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showRenewSubscriptionModal = true))
              }))
            : (_ctx.routePath == _ctx.RoutePath.WhiteboardRoom)
              ? (_openBlock(), _createBlock(_component_Whiteboard, { key: 4 }))
              : (_ctx.routePath == _ctx.RoutePath.CreateCustomGame)
                ? (_openBlock(), _createBlock(_component_CreateCustomGame, { key: 5 }))
                : (_ctx.routePath == _ctx.RoutePath.EditCustomGame)
                  ? (_openBlock(), _createBlock(_component_EditCustomGame, { key: 6 }))
                  : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (_ctx.showCancelSubscriptionModal)
            ? (_openBlock(), _createBlock(_component_CancelSubscriptionModal, {
                key: 0,
                onClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showCancelSubscriptionModal = false))
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (_ctx.showRenewSubscriptionModal)
            ? (_openBlock(), _createBlock(_component_RenewSubscriptionModal, {
                key: 0,
                onClose: _cache[8] || (_cache[8] = ($event: any) => (_ctx.showRenewSubscriptionModal = false))
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]))
  ], 64))
}