import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "inside-1-line " }
const _hoisted_2 = {
  key: 1,
  class: "text-3xl color-grey position-text"
}
const _hoisted_3 = { class: "text-center" }
const _hoisted_4 = { class: "color-yellow text-center" }
const _hoisted_5 = {
  key: 0,
  class: "color-green text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("tr", null, [
    _createVNode("td", null, [
      _createVNode("div", _hoisted_1, [
        (_ctx.ranking <= 3)
          ? (_openBlock(), _createBlock("img", {
              key: 0,
              class: "trohy",
              src: require(`@/assets/img/colors-icons/trophy/${_ctx.trophy}.svg`)
            }, null, 8, ["src"]))
          : (_openBlock(), _createBlock("strong", _hoisted_2, _toDisplayString(_ctx.ranking), 1)),
        (_ctx.showChange && _ctx.changeText)
          ? (_openBlock(), _createBlock("span", {
              key: 2,
              class: ["material-icons color-green mr-2 has-tooltip", {
            'color-green': _ctx.data.change > 0,
            'color-grey': _ctx.data.change == 0,
            'color-red': _ctx.data.change < 0
          }],
              "data-text": _ctx.changeText
            }, _toDisplayString(_ctx.data.change == 0
              ? 'arrow_circle_right'
              : _ctx.data.change > 0
              ? 'arrow_circle_up'
              : 'arrow_circle_down'), 11, ["data-text"]))
          : _createCommentVNode("", true),
        _createVNode("strong", {
          class: ["one-flex", { 'has-tooltip': _ctx.data.email }],
          "data-text": _ctx.data.email
        }, _toDisplayString(_ctx.data.name), 11, ["data-text"])
      ])
    ]),
    _createVNode("td", _hoisted_3, _toDisplayString(_ctx.data.games), 1),
    _createVNode("td", _hoisted_4, _toDisplayString(_ctx.data.wins), 1),
    (!_ctx.groupWinnerGameTypes.includes(_ctx.gameName))
      ? (_openBlock(), _createBlock("td", _hoisted_5, [
          _createVNode("strong", null, _toDisplayString(_ctx.data.score), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}