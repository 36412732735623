
import { defineComponent, onBeforeMount, ref, watch } from 'vue';
import { PollQuestion } from '@/models/PollQuestion';

export default defineComponent({
  name: 'GeneralPollInputItem',
  props: {
    id: {
      type: String,
      required: true
    },
    number: {
      type: Number,
      required: true
    },
    value: PollQuestion || Object,
    checked: Boolean
  },
  emits: ['valuechanged', 'allfilled'],
  setup(props, context) {
    const checkedValue = ref(false);
    const question = ref('');
    const choices = ref([] as string[]);

    const maxPollInputLength = 140;

    const onValue = function(isCheckbox: Boolean) {
      context.emit('valuechanged', {
        checked: checkedValue.value,
        question: question.value,
        choices: choices.value,
        index: props.number
      });
      if (!isCheckbox) {
        if (
          question.value != '' &&
          choices.value.length >= 2 &&
          choices.value.findIndex((choice: string) => choice.trim() === '') ===
            -1
        ) {
          context.emit('allfilled');
        }
      }
    };

    const addChoice = function() {
      choices.value.push('');
    };

    const removeChoice = function(index: number) {
      choices.value.splice(index, 1);
    };

    onBeforeMount(() => {
      checkedValue.value = props.checked ?? false;
      question.value = props.value?.question ?? '';
      choices.value = props.value?.choices ?? [];
    });

    watch(props, newValue => {
      checkedValue.value = newValue.checked ?? false;
      question.value = newValue.value?.question ?? '';
      choices.value = newValue.value?.choices ?? [];
    });

    return {
      checkedValue,
      question,
      choices,
      maxPollInputLength,
      onValue,
      addChoice,
      removeChoice
    };
  }
});
