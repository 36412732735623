export class MimicTwoWords {
  public clue: string = '';
  public word: string = '';
  public index: number = -1;
  public key: number = -1;

  public constructor(fields?: {
    clue?: string;
    word?: string;
    index?: number;
    key?: number;
  }) {
    if (fields) Object.assign(this, fields);
  }
}
