
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PopoverComponent',
  props: {
    title: {
      type: String
    },
    x: {
      type: Number,
      required: true
    },
    y: {
      type: Number,
      required: true
    },
    showArrow: {
      type: Boolean,
      default: true
    },
    position: {
      type: String,
      default: 'left'
    },
    arrowStyle: {
      type: String,
      default: 'top: 34px;'
    },
    maxWidth: {
      type: Number,
      default: 276
    }
  }
});
