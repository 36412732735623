
import { defineComponent, onMounted } from 'vue';
import { amplitudeEvent } from '@/config/amplitudeConfig';
// import NumberOfPlayersYesOrNo from '@/components/Modal/AboutComponent/NumberOfPlayersComponents/NumberOfPlayersYesOrNo.vue';
import HowToPlayYesOrNo from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayYesOrNo.vue';

export default defineComponent({
  name: 'AboutYesOrNo',
  components: {
    // NumberOfPlayersYesOrNo,
    HowToPlayYesOrNo
  },
  setup() {
    onMounted(() => {
      amplitudeEvent('previewGame', {
        game: 'yes_or_no',
        version: 'regular',
        eventTime: new Date(Date.now()).toISOString()
      });
    });
  }
});
