
import { computed, defineComponent, ref } from 'vue';
import { getGameTitleByType, getGameCssByType } from '@/utils/gameConstants';
import useFirebaseCollection from '@/hooks/firebase/useFirebaseCollection';
import CopyGameLinkBox from '@/components/CopyGameLinkBox.vue';
import ConfirmationModal from '@/components/Modal/ConfirmationModal.vue';

export default defineComponent({
  name: 'EditCustomizedGameTile',
  components: {
    CopyGameLinkBox,
    ConfirmationModal
  },
  emits: ['start', 'edit', 'delete'],
  props: {
    game: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const isWordBased =
      props.game.gameName == 'draw_it' ||
      props.game.gameName == 'describe_it' ||
      props.game.gameName == 'yes_or_no' ||
      props.game.gameName == 'charades';
    const isTagBased = props.game.gameName == 'two_words_game' || isWordBased;
    const showShareModal = ref(false);
    const showRemoveModal = ref(false);

    const isLoading = computed(() => {
      return (
        !customGameQuestionBank.collectionData.value ||
        customGameQuestionBank.collectionData.value!.length === 0
      );
    });

    const customGameQuestionBank = useFirebaseCollection(
      `minigame_custom_game_public/${props.game.id}/question_bank`,
      {
        onMounted: true,
        orderBy: 'index',
        limit: isTagBased ? 10 : 3
      }
    );

    function startGame() {
      context.emit('start', {
        gameName: props.game.gameName,
        customGameName: props.game.customGameName,
        customGameId: props.game.customGameId
      });
    }

    async function closeRemoveModal(value: boolean) {
      showRemoveModal.value = false;
      if (value) {
        context.emit('delete');
      }
    }

    return {
      ...customGameQuestionBank,
      getGameTitleByType,
      getGameCssByType,
      isWordBased,
      isTagBased,
      showShareModal,
      showRemoveModal,
      isLoading,
      startGame,
      closeRemoveModal
    };
  }
});
