import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HistoryTile = _resolveComponent("HistoryTile")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("h4", null, [
      _createVNode("strong", null, _toDisplayString(_ctx.date), 1)
    ]),
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.games, (game) => {
      return (_openBlock(), _createBlock(_component_HistoryTile, {
        key: game.id,
        game: game,
        onOpen: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onOpenModal($event)))
      }, null, 8, ["game"]))
    }), 128))
  ], 64))
}