
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'QAMessageItem',
  props: {
    message: {
      type: Object,
      required: true
    },
    whiteboardData: {
      type: Object,
      required: true
    },
    isHost: {
      type: Boolean,
      required: true
    }
  },

  setup(props) {
    const fromHost = computed(() => {
      return props.message.from == props.whiteboardData.currentHost;
    });
    const newLineToBr = function(text: string) {
      return text.replaceAll('\n', '<br>');
    };
    return { fromHost, newLineToBr };
  }
});
