
import { Ref, computed, defineComponent, ref } from 'vue';
import { eventToolsHistoryDateFormat } from '@/utils/helper';

// import { shareLivePollResult } from '@/utils/shareResult';
import { store } from '@/store';
import { Qna, QnaQuestion } from '@/models/event_tools/qna/qna';
import firebase from 'firebase';
import QnaQuestionsList from './QnaQuestionsList.vue';
export default defineComponent({
  name: 'QnaActiveDetailModal',
  props: {
    qna: Object as () => Qna
  },
  emits: ['back', 'startQna'],
  setup(props, context) {
    const userDoc = computed(() => store.getters.getUserDoc);
    const questions: Ref<Array<QnaQuestion>> = ref([]);
    const popularQuestions: Ref<Array<QnaQuestion>> = ref([]);
    const recentQuestions: Ref<Array<QnaQuestion>> = ref([]);
    const archivedQuestions: Ref<Array<QnaQuestion>> = ref([]);
    const activeTab: Ref<'popular' | 'recent' | 'archived'> = ref('popular');
    async function fetcQuestions() {
      try {
        const snapshots = await firebase
          .firestore()
          .collection(
            `minigame_lobbies/${userDoc.value['defaultLobbyId']}/qna/${props.qna?.sessionId}/questions`
          )
          .orderBy('dateCreated', 'desc')
          .get();

        if (!snapshots.empty && snapshots.docs.length != 0) {
          questions.value = snapshots.docs.map(
            doc => doc.data() as QnaQuestion
          );
          popularQuestions.value = [
            ...questions.value.filter(q => !q.archived)
          ].sort((a, b) => {
            if (b.likes.length == a.likes.length)
              return (
                a.dateUpdated.toDate().getTime() -
                b.dateUpdated.toDate().getTime()
              );
            else return b.likes.length - a.likes.length;
          });
          recentQuestions.value = [
            ...questions.value.filter(q => !q.archived)
          ].sort((a, b) => {
            console.log(b.dateUpdated.toDate().getTime());
            return (
              b.dateUpdated.toDate().getTime() -
              a.dateUpdated.toDate().getTime()
            );
          });
          archivedQuestions.value = questions.value.filter(q => q.archived);
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetcQuestions();
    function onBack() {
      context.emit('back');
    }

    return {
      questions,
      popularQuestions,
      recentQuestions,
      archivedQuestions,
      activeTab,
      onBack,
      eventToolsHistoryDateFormat
    };
  },
  components: {
    QnaQuestionsList
  }
});
