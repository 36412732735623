
import { defineComponent, onBeforeMount, ref, watch } from 'vue';
import { WouldYouRatherQuestion } from '@/models/WouldYouRatherQuestion';

export default defineComponent({
  name: 'GeneralWouldYouRatherInputItem',
  props: {
    id: {
      type: String,
      required: true
    },
    number: {
      type: Number,
      required: true
    },
    value: WouldYouRatherQuestion || Object,
    checked: Boolean
  },
  emits: ['valuechanged', 'allfilled'],
  setup(props, context) {
    const checkedValue = ref(false);
    const question = ref('');
    // option A / choice1
    const choice1 = ref('');
    // option B / choice2
    const choice2 = ref('');

    const maxWouldYouRatherInputLength = 140;

    const onValue = function(isCheckbox: Boolean) {
      context.emit('valuechanged', {
        checked: checkedValue.value,
        question: question.value,
        choice1: choice1.value,
        choice2: choice2.value,
        index: props.number
      });
      if (!isCheckbox) {
        if (
          question.value != '' &&
          choice1.value != '' &&
          choice2.value != ''
        ) {
          context.emit('allfilled');
        }
      }
    };

    const checkQuestionEmpty = function() {
      if (question.value === '') {
        question.value = 'Would you rather ';
        onValue(false);
      }
    };

    onBeforeMount(() => {
      checkedValue.value = props.checked ?? false;
      question.value = props.value?.question ?? '';
      choice1.value = props.value?.choice1 ?? '';
      choice2.value = props.value?.choice2 ?? '';
    });

    watch(props, newValue => {
      checkedValue.value = newValue.checked ?? false;
      question.value = newValue.value?.question ?? '';
      choice1.value = newValue.value?.choice1 ?? '';
      choice2.value = newValue.value?.choice2 ?? '';
    });

    return {
      checkedValue,
      question,
      choice1,
      choice2,
      maxWouldYouRatherInputLength,
      onValue,
      checkQuestionEmpty
    };
  }
});
