
import { computed, defineComponent, onMounted, ref } from 'vue';
// import firebaseApp from '@/firebaseApp';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { getEndpoint } from '@/utils/helper';
import usePostAPI from '@/hooks/usePostAPI';
import { amplitudeEvent, identifyAmplitude } from '@/config/amplitudeConfig';
import { useStore } from '@/store';
import { UPDATE_USER } from '@/store/mutation-types';
import BounceLoading from '@/components/BounceLoading.vue';
import { WelcomeUserModelEmits } from '@/models/welcomeUserSurvey';
import { NavigationTabs } from '@/models/navigationTabs';
export default defineComponent({
  name: 'WelcomeUserSurvey',
  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isNewUser: (type: WelcomeUserModelEmits) => true,
    isOldUser: () => true
  },
  components: {
    BounceLoading
  },
  setup(_, context) {
    const store = useStore();
    const user = computed(() => store.getters.getUser);
    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams.get('source');
    const signupMethod = getSignupMethod(
      user.value.providerData[0]?.providerId
    );
    const isSigningUpWithEmail =
      user.value.displayName === null || signupMethod === 'email';
    // 1. extract content before @
    // 2. Replace special characters to space
    // 3. Remove numbers
    // 4. Remove extra space (space more than one)
    // 5. Trim
    // 6. Capitalize the first letter on each word
    const userInputDisplayName = ref(
      user.value.email
        .substring(user.value.email.indexOf('@'), 0)
        .replace(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/g, ' ')
        .replace(/[0-9]/g, '')
        .replace(/ +(?= )/g, '')
        .trim()
        .toLowerCase()
        .split(' ')
        .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    );
    // step 0 = Business / Education / Personal
    // step 1 = Size (with car images) -> Business / Personal
    // step 2  = Size (with tree images) -> Education
    // step 3  = Setup name
    // step 4  = Finishing up
    const currentStep = ref(0);
    const stepsClass = ref([
      {
        'center-box-content': true,
        center: true,
        animate: true,
        hidden: false
      },
      {
        'center-box-content': true,
        center: true,
        animate: false,
        hidden: true
      },
      {
        'center-box-content': true,
        center: true,
        animate: false,
        hidden: true
      },
      {
        'center-box-content': true,
        center: true,
        animate: false,
        hidden: true
      }
    ]);
    const selectedSurvey1Answer = ref('');
    const selectedSurvey1RealAnswer = ref('');
    const teamName = ref(undefined as string | undefined);
    var hasFreeTrial = false;
    const showLastStep = ref(false);

    function previousStep() {
      selectedSurvey1Answer.value = '';
      selectedSurvey1RealAnswer.value = '';
      stepsClass.value[currentStep.value]['animate'] = false;
      stepsClass.value[currentStep.value]['hidden'] = true;
      currentStep.value = currentStep.value - 1;
      stepsClass.value[currentStep.value]['hidden'] = false;
      stepsClass.value[currentStep.value]['animate'] = true;
    }

    async function nextStep() {
      stepsClass.value[currentStep.value]['animate'] = false;
      stepsClass.value[currentStep.value]['hidden'] = true;
      if (currentStep.value === 0 && isSigningUpWithEmail) {
        currentStep.value = 1;
        stepsClass.value[currentStep.value]['hidden'] = false;
        stepsClass.value[currentStep.value]['animate'] = true;
      } else {
        currentStep.value = 2;
        stepsClass.value[currentStep.value]['hidden'] = false;
        stepsClass.value[currentStep.value]['animate'] = true;
        // loading stage
        if (isSigningUpWithEmail) await updateDisplayName();
        await checkNewUser();
        return;
      }
    }

    async function selectSurvey1Answer(answer: string, realAnswer: string) {
      selectedSurvey1Answer.value = answer;
      selectedSurvey1RealAnswer.value = realAnswer;
      await nextStep();
    }

    async function updateDisplayName() {
      if (!userInputDisplayName.value) return;
      const correctedName = userInputDisplayName.value
        .replace(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/g, ' ')
        .trim();
      await user.value.updateProfile({
        displayName: correctedName
      });
    }

    // async function navigateDashboard(tab: string) {
    //   firebaseApp
    //     .firestore()
    //     .collection('minigame_user')
    //     .doc(user.value.uid)
    //     .update({
    //       survey2Answer: tab
    //     });
    //   amplitudeEvent('completedSignupSurvey', {
    //     answer: selectedSurvey1RealAnswer.value,
    //     answer2:
    //       tab === NavigationTabs.SLACK
    //         ? 'Team building in Slack'
    //         : 'Play a game over video chat',
    //     source: source,
    //     signupMethod: signupMethod,
    //     eventTime: new Date(Date.now()).toISOString()
    //   });
    //   if (hasFreeTrial) {
    //     context.emit('isNewUser', { tab: tab, teamName: teamName.value });
    //   } else {
    //     context.emit('isOldUser');
    //   }
    // }

    async function checkNewUser() {
      // granting free trial for the user
      const fingerprintJsAgent = await FingerprintJS.load();
      const fingerprintResult = await fingerprintJsAgent.get();
      const response = await usePostAPI(getEndpoint('userSurveyAndChecking'), {
        email: user.value.email,
        source: source,
        fingerprint: fingerprintResult.visitorId,
        survey1Answer: [selectedSurvey1Answer.value],
        team: urlParams.get('team')
      });
      if (response.status == 200) {
        const result = response.data;
        hasFreeTrial = result['hasFreeTrial'];
        const flaggedFingerprint = result['flaggedFingerprint'];
        teamName.value = result['teamName'];
        identifyAmplitude(
          'setOnce',
          'survey1Answer',
          selectedSurvey1Answer.value
        );
        identifyAmplitude('setOnce', 'flaggedFingerprint', flaggedFingerprint);
        if (hasFreeTrial) {
          await new Promise(resolve => setTimeout(resolve, 5000));
          await user.value.getIdToken(true);
          store.commit(UPDATE_USER, {
            newUser: user.value
          });
        }
        await new Promise(resolve => setTimeout(resolve, 3000));
        if (showLastStep.value && !teamName.value) {
          stepsClass.value[currentStep.value]['animate'] = false;
          stepsClass.value[currentStep.value]['hidden'] = true;
          currentStep.value = 3;
          stepsClass.value[currentStep.value]['hidden'] = false;
          stepsClass.value[currentStep.value]['animate'] = true;
        } else {
          amplitudeEvent('completedSignupSurvey', {
            answer: selectedSurvey1RealAnswer.value,
            answer2: null,
            source: source,
            signupMethod: signupMethod,
            eventTime: new Date(Date.now()).toISOString()
          });
          if (hasFreeTrial) {
            context.emit('isNewUser', {
              tab: NavigationTabs.HOME,
              teamName: teamName.value
            });
          } else {
            context.emit('isOldUser');
          }
        }
        return;
      }
    }

    function getSignupMethod(providerId: string) {
      switch (providerId) {
        case 'password': {
          return 'email';
        }
        case 'google.com': {
          return 'google';
        }
        case 'facebook.com': {
          return 'facebook';
        }
        case 'twitter.com': {
          return 'twitter';
        }
        default: {
          return 'email';
        }
      }
    }

    onMounted(() => {
      amplitudeEvent('openSignupSurvey', {
        source: source,
        signupMethod: signupMethod,
        eventTime: new Date(Date.now()).toISOString()
      });
    });

    return {
      isSigningUpWithEmail,
      userInputDisplayName,
      currentStep,
      stepsClass,
      previousStep,
      nextStep,
      selectedSurvey1Answer,
      selectSurvey1Answer,
      showLastStep,
      // navigateDashboard,
      teamName
    };
  }
});
