import firebase from 'firebase/app';
import firebaseConfig from '@/config/firebaseConfig';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/database';

firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({ experimentalForceLongPolling: true });

export default firebase;
