interface OpenAiArgs {
  prompt?: string;
  stream?: string;
  maxTokens?: number;
  temperature?: number;
  topP?: number;
  presencePenalty?: number;
  frequencyPenalty?: number;
  engine?: string;
}
export class OpenAiProps {
  prompt?: string;
  stream?: string;
  maxTokens?: number;
  temperature?: number;
  topP?: number;
  presencePenalty?: number;
  frequencyPenalty?: number;
  engine?: string;

  constructor(args: OpenAiArgs) {
    this.prompt = args.prompt;
    this.stream = args.stream;
    this.maxTokens = args.maxTokens;
    this.temperature = args.temperature;
    this.topP = args.topP;
    this.frequencyPenalty = args.frequencyPenalty;
    this.presencePenalty = args.presencePenalty;
    this.engine = args.engine;
  }
  get toJson() {
    const json: any = {
      prompt: this.prompt,
      stream: this.stream,
      maxTokens: this.maxTokens,
      temperature: this.temperature,
      topP: this.topP,
      frequencyPenalty: this.frequencyPenalty,
      presencePenalty: this.presencePenalty,
      engine: this.engine
    };
    const valuedJson: any = {};
    for (const key in json) {
      if (json[key]) {
        valuedJson[key] = json[key];
      }
    }
    return valuedJson;
  }
}
