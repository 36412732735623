
import { amplitudeEvent } from '@/config/amplitudeConfig';
import { defineComponent, ref } from 'vue';
import Datepicker from 'vuejs3-datepicker';
import moment from 'moment';

export default defineComponent({
  name: 'CategoryFilterMobile',
  components: {
    Datepicker
  },
  props: {
    icons: {
      type: Array,
      required: true
    },
    titles: {
      type: Array,
      required: true
    },
    textForNoFilter: {
      type: Array,
      required: true
    },
    multiFilters: {
      type: Array,
      required: true
    },
    selectedIndices: {
      type: Array,
      required: true
    },
    includeAlls: {
      type: Array,
      required: true
    },
    hasDates: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    dateTypes: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    enabledMonths: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    enabledYears: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    switchText: {
      type: String,
      required: false
    },
    switchValue: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  emits: ['select', 'switch'],
  setup(props, context) {
    const collapsed = ref(true);
    const shownMenu = ref(props.multiFilters.map(() => false));
    const switchV = ref(props.switchValue);
    const dates = ref(
      props.icons.map((_, rowI) => {
        if (props.hasDates[rowI]) {
          return (props.multiFilters[rowI] as any).map(() => undefined);
        } else {
          return [];
        }
      })
    );

    const disabledDates = function(rowI: number, index: number) {
      return {
        customPredictor: (date: Date) => {
          if ((props.dateTypes[rowI] as any)[index] === 'month') {
            return !props.enabledMonths.includes(
              moment(date).format('MMMM YYYY')
            );
          } else {
            return !props.enabledYears.includes(date.getFullYear());
          }
        }
      };
    };

    function onMenu(rowI: number) {
      props.multiFilters.map((_, index) => {
        if (index !== rowI) {
          shownMenu.value[index] = false;
        } else {
          shownMenu.value[index] = !shownMenu.value[index];
        }
      });
    }

    function onSelectIndex(index: number, rowI: number) {
      amplitudeEvent('clickedGameFilter', {
        eventTime: new Date(Date.now()).toISOString()
      });
      shownMenu.value[rowI] = false;
      context.emit('select', [index, rowI]);
    }

    function onSwitchValue() {
      context.emit('switch', switchV.value);
    }

    function onDate(rowI: number, index: number, date: any) {
      if (props.hasDates[rowI]) {
        const multiFilters = props.multiFilters[rowI] as any;
        for (let i = 0; i < multiFilters.length; i++) {
          if (index !== i) {
            dates.value[rowI][i] = undefined;
          } else {
            if (date) {
              dates.value[rowI][i] = new Date(date);
              if ((props.dateTypes[rowI] as any)[i] === 'month') {
                context.emit('select', [
                  moment(date).format('MMMM YYYY'),
                  rowI
                ]);
              } else {
                context.emit('select', [moment(date).format('YYYY'), rowI]);
              }
            } else {
              dates.value[rowI][i] = undefined;
              context.emit('select', [undefined, rowI]);
            }
          }
        }
        if (index === -1) {
          context.emit('select', [undefined, rowI]);
        }
        shownMenu.value[rowI] = false;
      }
    }

    return {
      collapsed,
      onMenu,
      onSelectIndex,
      shownMenu,
      onSwitchValue,
      switchV,
      dates,
      onDate,
      disabledDates
    };
  }
});
