
import { computed, defineComponent, ref } from 'vue';
import firebaseApp from '@/firebaseApp';
import { useStore } from '@/store';
import { getEndpoint } from '@/utils/helper';
import {
  hasTeamManagementAccess,
  isExpiredFreeTrialPlan,
  isLevel1Plan
} from '@/utils/pricing_rules';
import usePostAPI from '@/hooks/usePostAPI';
import {
  UPDATE_ACTIVEGAMEROOMID,
  UPDATE_ISUSERUSINGCUSTOMGAMEROOMID,
  UPDATE_SURVEY_1_ANSWER,
  UPDATE_USER_DOC
} from '@/store/mutation-types';
import { TeamInfo } from '@/models/TeamInfo';
import useFirebaseCollection from '@/hooks/firebase/useFirebaseCollection';
import vClickOutside from 'click-outside-vue3';
import useFirebaseDocument from '@/hooks/firebase/useFirebaseDocument';
import BounceLoading from '@/components/BounceLoading.vue';

export default defineComponent({
  name: 'AccountSettingsModal',
  directives: {
    clickOutside: vClickOutside.directive
  },
  emits: ['close', 'show-custom-id-modal', 'leave-team'],
  components: {
    BounceLoading
  },
  props: {
    digitOnlyGameRoomId: {
      type: String,
      required: true
    }
  },
  setup(_, context) {
    const store = useStore();
    const user = computed(() => store.getters.getUser);
    const userDoc = computed(() => store.getters.getUserDoc);
    const survey1Answer = computed(() => store.getters.getSurvey1Answer);
    const lobbyDoc = computed(() => store.getters.getLobbyDoc);
    const isUserUsingCustomGameRoomId = computed(
      () => store.getters.isUserUsingCustomGameRoomId
    );
    const activeGameRoomId = computed(() => store.getters.activeGameRoomId);
    const subscriptionDetails = computed(
      () => store.getters.getSubscriptionDetails
    );

    const show = ref(true);
    const isUpdateDisplayNameLoading = ref(false);
    const userInputName = ref(user.value.displayName ?? '');
    const originalDisplayName = ref(user.value.displayName ?? '');
    const isResetCustomGameRoomIdLoading = ref(false);
    const isSetupCustomGameRoomIdLoading = ref(false);
    const showSetupCustomGameRoomIdBox = ref(
      lobbyDoc.value !== undefined && lobbyDoc.value !== null
    );
    const userInputCustomGameRoomId = ref(
      isUserUsingCustomGameRoomId.value ? activeGameRoomId.value : ''
    );
    const originalCustomGameRoomId = ref(activeGameRoomId.value);
    const isPGEnabled = ref(userDoc.value['isPGEnabled'] ?? false);
    const isUpdateAccountTypeLoading = ref(false);
    const originalSurvey1Answer = ref(survey1Answer.value);
    const selectedSurvey1Answer = ref(originalSurvey1Answer.value[0]);
    const showEditNameField = ref(false);
    const showCustomGameRoomIdField = ref(false);
    const showAccountTypeField = ref(false);
    const showChangePasswordFields = ref(false);
    const showChangePasswordSuccessfulMessage = ref(false);
    const nameFieldErrorMessage = ref('');
    const customGameRommIdFieldErrorMessage = ref('');
    const pgFilterErrorMessage = ref('');
    const accountTypeFieldErrorMessage = ref('');
    const passwordFieldErrorMessage = ref('');
    const currentPassword = ref('');
    const newPassword = ref('');
    const confirmPassword = ref('');
    const currentPasswordEye = ref(false);
    const newPasswordEye = ref(false);
    const confirmPasswordEye = ref(false);
    const teamInfo = ref(undefined as TeamInfo | undefined);
    const loading = ref(true);

    function close(type: string) {
      if (type != 'prev' && type != 'next') {
        show.value = false;
        setTimeout(() => {
          context.emit('close', type);
        }, 50);
      } else {
        context.emit('close', type);
      }
    }

    const isValidName = computed(() => {
      return (
        userInputName.value &&
        userInputName.value.trim().length > 0 &&
        userInputName.value
          .replace(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/g, ' ')
          .trim() !== '' &&
        userInputName.value != originalDisplayName.value
      );
    });

    async function onUpdateName() {
      try {
        if (!isValidName.value) return;
        nameFieldErrorMessage.value = '';
        isUpdateDisplayNameLoading.value = true;
        const correctedName = userInputName.value
          .replace(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/g, ' ')
          .trim();
        await user.value.updateProfile({
          displayName: correctedName
        });
        await usePostAPI(getEndpoint('updateMemberName'), {
          displayName: correctedName
        });
        if (lobbyDoc.value) {
          const lobbyId = userDoc.value['defaultLobbyId'];
          await firebaseApp
            .firestore()
            .collection('minigame_lobbies')
            .doc(lobbyId)
            .update({
              hostName: correctedName
            });
          await firebaseApp
            .firestore()
            .collection('minigame_lobbies')
            .doc(lobbyId)
            .collection('players')
            .doc(user.value.uid)
            .update({
              name: correctedName
            });
        }
        originalDisplayName.value = userInputName.value;
        showEditNameField.value = false;
      } catch (err) {
        nameFieldErrorMessage.value = (err as any).message;
      } finally {
        isUpdateDisplayNameLoading.value = false;
      }
    }

    const isValidCustomGameRoomId = computed(() => {
      return (
        userInputCustomGameRoomId.value &&
        userInputCustomGameRoomId.value.trim().length >= 3 &&
        userInputCustomGameRoomId.value.trim().length <= 20 &&
        userInputCustomGameRoomId.value
          .replace(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/g, ' ')
          .trim() !== '' &&
        !new RegExp('^[0-9].*').test(userInputCustomGameRoomId.value) &&
        userInputCustomGameRoomId.value != originalCustomGameRoomId.value
      );
    });

    async function onSetupCustomGameRoomId(target: string | null) {
      try {
        let updatedCustomGameRoomId: string | null;
        if (target === null) {
          isResetCustomGameRoomIdLoading.value = true;
          updatedCustomGameRoomId = target;
        } else {
          if (!isValidCustomGameRoomId.value) return;
          customGameRommIdFieldErrorMessage.value = '';
          isSetupCustomGameRoomIdLoading.value = true;
          updatedCustomGameRoomId = target
            .replace(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/g, '')
            .trim()
            .toLowerCase();
        }
        await usePostAPI(getEndpoint('setupCustomGameRoomId'), {
          customGameRoomId: updatedCustomGameRoomId
        });
        originalCustomGameRoomId.value = target;
        showCustomGameRoomIdField.value = false;
        if (target === null) {
          userInputCustomGameRoomId.value = '';
          store.commit(UPDATE_ACTIVEGAMEROOMID, {
            activeGameRoomId: userDoc.value['defaultGameRoomId']
          });
          store.commit(UPDATE_ISUSERUSINGCUSTOMGAMEROOMID, {
            isUserUsingCustomGameRoomId: false
          });
        } else {
          store.commit(UPDATE_ACTIVEGAMEROOMID, {
            activeGameRoomId: target
          });
          store.commit(UPDATE_ISUSERUSINGCUSTOMGAMEROOMID, {
            isUserUsingCustomGameRoomId: true
          });
        }
      } catch (err) {
        isSetupCustomGameRoomIdLoading.value = false;
        if ((err as any).response !== undefined) {
          const errorMsg = (err as any).response.data.error;
          if (errorMsg === 'bad_words') {
            customGameRommIdFieldErrorMessage.value =
              'Please use an ID that is age-appropriate';
          } else if (errorMsg === 'id_in_use') {
            customGameRommIdFieldErrorMessage.value =
              'This ID is already being used by another user';
          } else {
            customGameRommIdFieldErrorMessage.value =
              'An unexpected error has occured. Please try again later or contact support@brightful.me for more info.';
          }
        }
      } finally {
        isResetCustomGameRoomIdLoading.value = false;
        isSetupCustomGameRoomIdLoading.value = false;
      }
    }

    async function togglePGFilter(flag: boolean) {
      try {
        pgFilterErrorMessage.value = '';
        await firebaseApp
          .firestore()
          .collection('minigame_user')
          .doc(user.value.uid)
          .update({
            isPGEnabled: flag
          });
        isPGEnabled.value = flag;
        const userDocumentProps = useFirebaseDocument('minigame_user', {
          onMounted: false,
          documentId: undefined
        });
        await userDocumentProps.getDocument(10, userDoc.value.id);
        store.commit(UPDATE_USER_DOC, {
          newUserDoc: userDocumentProps.documentData.value
        });
      } catch (err) {
        pgFilterErrorMessage.value =
          'An unexpected error has occured. Please try again later or contact support@brightful.me for more info.';
      }
    }

    const showCurrentPasswordEye = computed(() => {
      return currentPassword.value.trim().length > 0;
    });

    const showNewPasswordEye = computed(() => {
      return newPassword.value.trim().length > 0;
    });

    const showConfirmPasswordEye = computed(() => {
      return confirmPassword.value.trim().length > 0;
    });
    const inZoomClient = computed(() => {
      return store.state.zoomClientStatus?.inZoomClient ?? false;
    });

    function validPasswordUpdate() {
      return (
        showCurrentPasswordEye.value &&
        showNewPasswordEye.value &&
        showConfirmPasswordEye.value
      );
    }

    function checkValidPassword(password: string) {
      const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d~!@#$%&*=()-={}|;:<>?\\-`.+,/]{8,}$/g;
      return regex.test(password);
    }

    async function onUpdateAccountType() {
      try {
        accountTypeFieldErrorMessage.value = '';
        isUpdateAccountTypeLoading.value = true;
        await firebaseApp
          .firestore()
          .collection('minigame_user')
          .doc(user.value.uid)
          .update({
            survey1Answer: [selectedSurvey1Answer.value]
          });
        store.commit(UPDATE_SURVEY_1_ANSWER, {
          newSurvey1Answer: [selectedSurvey1Answer.value]
        });
        originalSurvey1Answer.value = [selectedSurvey1Answer.value];
      } catch (err) {
        console.log(selectedSurvey1Answer.value);
        console.log(err);
        accountTypeFieldErrorMessage.value =
          'An unexpected error has occured. Please try again later or contact support@brightful.me for more info.';
      } finally {
        isUpdateAccountTypeLoading.value = false;
        showAccountTypeField.value = false;
      }
    }

    async function onUpdatePassword() {
      passwordFieldErrorMessage.value = '';
      try {
        const credential = firebaseApp.auth.EmailAuthProvider.credential(
          user.value.email,
          currentPassword.value
        );
        await user.value.reauthenticateWithCredential(credential);
      } catch (err) {
        if ((err as any).code === 'auth/wrong-password') {
          passwordFieldErrorMessage.value =
            'Current password is incorrect. Please try again.';
        } else {
          passwordFieldErrorMessage.value = (err as any).message;
        }
        return;
      }
      if (
        newPassword.value.trim().length < 8 ||
        !checkValidPassword(newPassword.value)
      ) {
        passwordFieldErrorMessage.value =
          'The password must be at least 8 characters with a mixture of letters and numbers.';
      } else if (newPassword.value != confirmPassword.value) {
        passwordFieldErrorMessage.value =
          'The password confirmation does not match.';
      } else {
        try {
          await user.value.updatePassword(newPassword.value);
          currentPassword.value = '';
          newPassword.value = '';
          confirmPassword.value = '';
          showChangePasswordFields.value = false;
          showChangePasswordSuccessfulMessage.value = true;
          setTimeout(() => {
            showChangePasswordSuccessfulMessage.value = false;
          }, 5000);
        } catch (err) {
          passwordFieldErrorMessage.value =
            'An unexpected error has occured. Please try again later or contact support@brightful.me for more info.';
          return;
        }
      }
    }

    function isSocialAccount() {
      if (
        user.value &&
        user.value.providerData &&
        (user.value.providerData[0]?.providerId.includes('google.com') ||
          user.value.providerData[0]?.providerId.includes('facebook.com') ||
          user.value.providerData[0]?.providerId.includes('twitter.com'))
      ) {
        return true;
      }
      return false;
    }

    async function configureTeamInfo() {
      const teamQuery = useFirebaseCollection('minigame_teams', {});
      if (subscriptionDetails.value.teamId) {
        await teamQuery.getCollection({
          fieldPath: 'id',
          opStr: '==',
          value: subscriptionDetails.value.teamId,
          limit: 1
        });
      }
      const teams = teamQuery.collectionData.value ?? [];
      if (teams.length > 0) {
        teamInfo.value = new TeamInfo(teams[0]);
      }
      loading.value = false;
    }
    configureTeamInfo();

    function leaveTeam() {
      context.emit('leave-team');
    }

    async function userLogout() {
      await firebaseApp.auth().signOut();
    }

    function showBlockCustomIdModal() {
      context.emit('show-custom-id-modal');
    }
    function onCustomize() {
      if (
        isExpiredFreeTrialPlan(subscriptionDetails.value, userDoc.value) ||
        isLevel1Plan(subscriptionDetails.value)
      ) {
        showBlockCustomIdModal();
      } else {
        showCustomGameRoomIdField.value = true;
      }
    }

    return {
      subscriptionDetails,
      show,
      isUpdateDisplayNameLoading,
      userInputName,
      originalDisplayName,
      isResetCustomGameRoomIdLoading,
      isSetupCustomGameRoomIdLoading,
      showSetupCustomGameRoomIdBox,
      isUserUsingCustomGameRoomId,
      userInputCustomGameRoomId,
      originalCustomGameRoomId,
      isPGEnabled,
      isUpdateAccountTypeLoading,
      originalSurvey1Answer,
      selectedSurvey1Answer,
      showEditNameField,
      showCustomGameRoomIdField,
      showAccountTypeField,
      showChangePasswordFields,
      showChangePasswordSuccessfulMessage,
      nameFieldErrorMessage,
      customGameRommIdFieldErrorMessage,
      pgFilterErrorMessage,
      accountTypeFieldErrorMessage,
      passwordFieldErrorMessage,
      currentPassword,
      newPassword,
      confirmPassword,
      currentPasswordEye,
      newPasswordEye,
      confirmPasswordEye,
      inZoomClient,
      //
      close,
      isValidName,
      onUpdateName,
      isValidCustomGameRoomId,
      onSetupCustomGameRoomId,
      togglePGFilter,
      showCurrentPasswordEye,
      showNewPasswordEye,
      showConfirmPasswordEye,
      validPasswordUpdate,
      onUpdateAccountType,
      onUpdatePassword,
      isSocialAccount,
      userLogout,
      showBlockCustomIdModal,
      onCustomize,
      //
      hasTeamManagementAccess,
      teamInfo,
      leaveTeam,
      loading
    };
  }
});
