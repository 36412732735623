
import { computed, defineComponent, ref } from 'vue';
import { getGameTitleByType, getGameCssByType } from '@/utils/gameConstants';
import useFirebaseCollection from '@/hooks/firebase/useFirebaseCollection';

export default defineComponent({
  name: 'StandardCustomizedGameTile',
  emits: ['start', 'more'],
  props: {
    game: {
      type: Object,
      required: true
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    showType: {
      type: Boolean,
      default: true
    }
  },
  setup(props, context) {
    const isClicked = ref(false);
    const isWordBased =
      props.game.gameName == 'draw_it' ||
      props.game.gameName == 'describe_it' ||
      props.game.gameName == 'charades';
    const isTagBased = props.game.gameName == 'two_words_game' || isWordBased;

    const isLoading = computed(() => {
      return (
        !customGameQuestionBank.collectionData.value ||
        customGameQuestionBank.collectionData.value!.length === 0
      );
    });

    const customGameQuestionBank = useFirebaseCollection(
      `minigame_custom_game_public/${props.game.id}/question_bank`,
      {
        onMounted: true,
        orderBy: 'index',
        limit: isTagBased ? 10 : 3
      }
    );

    function showMore() {
      context.emit('more', [props.game.customGameName, props.game.id]);
    }

    function startGame() {
      context.emit('start', [
        props.game.customGameName,
        props.game.customGameId,
        props.game.created_by
      ]);
      isClicked.value = true;
      setTimeout(function() {
        isClicked.value = false;
      }, 2000);
    }

    return {
      ...customGameQuestionBank,
      getGameTitleByType,
      getGameCssByType,
      isWordBased,
      isTagBased,
      isLoading,
      isClicked,
      showMore,
      startGame
    };
  }
});
