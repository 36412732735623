
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ConfirmationModal',
  emits: ['close'],
  props: {
    title: {
      type: String,
      requried: false
    },
    description: {
      type: String,
      requried: false
    },
    okText: {
      type: String,
      requried: false
    },
    cancelText: {
      type: String,
      requried: false
    }
  },
  setup(_, context) {
    const close = function(value: Boolean | undefined) {
      context.emit('close', value);
    };

    return {
      close
    };
  }
});
