import { GameName } from '@/utils/gamenames_enums';
import { capitalizeFirstLetter } from '@/utils/helper';
import { EventToolsType } from './event_tools_enums';
import { LivePollType } from './live_poll_enums';

export const gameTitles = new Map([
  ['would_you_rather', 'Would You Rather'],
  ['draw_it', 'Draw It'],
  ['trivia', 'Trivia'],
  ['guided_meditation', 'Guided Meditation'],
  ['describe_it', 'Describe It'],
  ['question_of_the_day', 'Q of the Day'],
  ['werewolf_game', 'Werewolf'],
  ['two_words_game', 'Mimic'],
  ['charades', 'Charades'],
  ['draw_tell', 'Draw & Tell'],
  ['one_to_hundred', '1 to 100'],
  ['yes_or_no', 'Yes or No'],
  ['funny_or_not', 'Funny or Not'],
  ['spot_my_lie', 'Spot My Lie'],
  ['hearts_game', 'Hearts'],
  ['rummy_game', 'Rummy'],
  ['gin_rummy_game', 'Gin Rummy'],
  ['poll', 'Super Poll'],
  ['top5', 'Top 5 Answers'],
  ['qna', 'Live Q&A'],
  [GameName.LIVE_POLL_MULTIPLE_CHOICE, 'Live Polling'],
  [GameName.LIVE_POLL_RATING, 'Live Polling'],
  [GameName.LIVE_POLL_SHORT_ANSWER, 'Live Polling'],
  [GameName.LIVE_POLL_WORD_CLOUD, 'Live Polling'],
  [GameName.SPIN_THE_WHEEL, 'Spin The Wheel']

  //   ['words_of_power', 'Words of Power'],
]);
export const gameTitlesWithPolling = new Map([
  ['would_you_rather', 'Would You Rather'],
  ['draw_it', 'Draw It'],
  ['trivia', 'Trivia'],
  ['guided_meditation', 'Guided Meditation'],
  ['describe_it', 'Describe It'],
  ['question_of_the_day', 'Q of the Day'],
  ['werewolf_game', 'Werewolf'],
  ['two_words_game', 'Mimic'],
  ['charades', 'Charades'],
  ['draw_tell', 'Draw & Tell'],
  ['one_to_hundred', '1 to 100'],
  ['yes_or_no', 'Yes or No'],
  ['funny_or_not', 'Funny or Not'],
  ['spot_my_lie', 'Spot My Lie'],
  ['hearts_game', 'Hearts'],
  ['rummy_game', 'Rummy'],
  ['gin_rummy_game', 'Gin Rummy'],
  ['poll', 'Super Poll'],
  ['top5', 'Top 5 Answers'],
  ['dino_game', 'Dino Game'],
  ['pad_racing_game', 'Pad Racing Game'],
  ['qna', 'Live Q&A'],
  [GameName.LIVE_POLL_MULTIPLE_CHOICE, 'Live Polling - Multiple Choice'],
  [GameName.LIVE_POLL_RATING, 'Live Polling - Rating'],
  [GameName.LIVE_POLL_SHORT_ANSWER, 'Live Polling - Short Answer'],
  [GameName.LIVE_POLL_WORD_CLOUD, 'Live Polling - Word Cloud'],
  [GameName.SPIN_THE_WHEEL, 'Spin The Wheel']

  //   ['words_of_power', 'Words of Power'],
]);

const gameDescription = new Map([
  [
    'would_you_rather',
    'The classic question game that stretches your imagination with outlandish hypothetical scenarios. There are no right or wrong answers.'
  ],
  [
    'draw_it',
    'A fast paced guessing game that puts your artistic talents to the test. Doodles, stick figures and sense of humor are also welcome.'
  ],
  [
    'trivia',
    'Put your general knowledge to the test and battle it out to see who will be crowned King or Queen of Trivia. No Googling!'
  ],
  [
    'guided_meditation',
    'Find your inner calm with guided breathing and visualization exercises. Remove distractions and focus on your most important tasks.'
  ],
  [
    'describe_it',
    "Ever have a word that's been stuck on the tip of your tongue? Describe It is a game where you're allowed to say anything except the word itself."
  ],
  [
    'question_of_the_day',
    'Get to know people better by asking them a thought provoking ice breaker question. Share your life experience, funny anecdotes or completely wild imagination!'
  ],
  [
    'werewolf_game',
    "A social deduction game that will keep you guessing. Survive through the night as a villager or become a silent killer. Will you find the werewolf before it's too late?"
  ],
  [
    'two_words_game',
    'In a game of wit and deception, it’s a race to either decipher the secret word or figure out who is the Mimic!'
  ],
  [
    'charades',
    "Act out the word you are given, without saying what it is. It's up to the rest to deduce what the word is! A classic team game favorite."
  ],
  [
    'draw_tell',
    'Unleash your inner artist and express yourself using drawings. Perfect for getting to know each other in a fun and creative way.'
  ],
  [
    'one_to_hundred',
    'Score points by working as a team to correctly rank clues from smallest to largest.'
  ],
  [
    'yes_or_no',
    'Be the first to guess the secret word correctly by asking the right yes or no questions.'
  ],
  [
    'funny_or_not',
    'The game that seeks to answer: who’s the audience favourite? Pit your answer to a prompt against another’s, and see which one is voted the best one.'
  ],
  [
    'spot_my_lie',
    'Give three answers to a question about yourself. The twist is that one of those questions will be completely false. Pick the lie and trick others.'
  ],
  [
    'hearts_game',
    'A game of skill and surprising subtleties. Avoid taking tricks that contain hearts.'
  ],
  [
    'rummy_game',
    'One of the best-known card games. Be the first to get rid of all your cards by creating melds.'
  ],
  [
    'gin_rummy_game',
    'Collect cards into melds and have as little deadwood as possible at the end of a game.'
  ],
  [
    'poll',
    'Vote on various opinions on interesting and fun topics - then see what everyone else has to say!'
  ],
  [
    'top5',
    'The game that pits two teams against each other - game show style! See if you can correctly guess the most answers versus your rivals.'
  ]
  //   [
  //     'words_of_power',
  //     'Words of Power description bla bla bla bla bla bla bla bla bla bla'
  //   ],
]);

const gameRecommendedPlayers = new Map([
  ['would_you_rather', '2 - 25 recommended'],
  ['draw_it', '2 - 50 recommended'],
  ['trivia', '2 - 50 recommended'],
  ['guided_meditation', '2 - 10 recommended'],
  ['describe_it', '3 - 25 recommended'],
  ['question_of_the_day', '2 - 10 recommended'],
  ['werewolf_game', '8 - 16 recommended (Min. 6)'],
  ['two_words_game', '6 - 12 recommended (Min. 4)'],
  ['charades', '3 - 25 recommended'],
  ['draw_tell', '2 - 10 recommended'],
  ['one_to_hundred', '3 to 8 recommended'],
  ['yes_or_no', '3 - 10 recommended'],
  ['funny_or_not', '3 - 8 recommended (Min. 3)'],
  ['spot_my_lie', '3 - 10 recommended'],
  ['hearts_game', '3 - 5'],
  ['rummy_game', '2 - 5'],
  ['gin_rummy_game', '2'],
  ['poll', '5 to 25 recommended'],
  ['top5', '7 to 11 recommended (Min. 5)']
  //   ['words_of_power', '6 - 14 recommended (Min. 4)'],
]);

const gameCss = new Map([
  ['would_you_rather', 'would-you-rather'],
  ['draw_it', 'draw-it'],
  ['trivia', 'trivia'],
  ['guided_meditation', 'guided-meditation'],
  ['describe_it', 'describe-it'],
  ['question_of_the_day', 'q-of-the-day'],
  ['werewolf_game', 'werewolf'],
  ['two_words_game', 'mimic'],
  ['charades', 'charades'],
  ['draw_tell', 'draw-and-tell'],
  ['one_to_hundred', 'one-to-hundred'],
  ['yes_or_no', 'draw-it'],
  ['funny_or_not', 'draw-it'],
  ['spot_my_lie', 'spot-my-lie'],
  ['poll', 'poll'],
  ['top5', 'draw-it']
]);

const gameHero = new Map([
  ['would_you_rather', 'would_you_rather_hero'],
  ['draw_it', 'draw_it_hero'],
  ['trivia', 'trivia_hero'],
  ['guided_meditation', 'guided_meditation_hero'],
  ['describe_it', 'describe_it_hero'],
  ['question_of_the_day', 'question_of_the_day_hero'],
  ['werewolf_game', 'werewolf_game_hero'],
  ['two_words_game', 'two_words_game_hero'],
  ['charades', 'charades_hero'],
  ['draw_tell', 'draw_tell_hero'],
  ['one_to_hundred', 'werewolf_game_hero'],
  ['yes_or_no', 'draw_it_hero'],
  ['funny_or_not', 'draw_it_hero'],
  ['spot_my_lie', 'spot_my_lie_hero'],
  ['hearts_game', 'card_game_hero'],
  ['rummy_game', 'card_game_hero'],
  ['gin_rummy_game', 'card_game_hero'],
  ['poll', 'poll_hero'],
  ['top5', 'draw_it_hero']
  //   ['words_of_power', 'words_of_power_hero'],
]);

const gameVideo = new Map([
  ['would_you_rather', '4OsuPUMiz8M'],
  ['draw_it', 'v_ZdybuRDFk'],
  ['trivia', '9NmhO1_IAHU'],
  ['describe_it', 'HXrjW01gLlM'],
  ['charades', 'h6tFFD73X4I'],
  ['question_of_the_day', 'Hs5CfP06HTw'],
  ['werewolf_game', 'gatcIISCPbk'],
  //   ['two_words_game', ''],
  ['draw_tell', 'cM6qiLNE234'],
  //   ['one_to_hundred', ''],
  //   ['yes_or_no', ''],
  ['spot_my_lie', 'wfs2LfGjoBA']
  // ['poll', '4OsuPUMiz8M']
]);

const gameScreenshots = new Map([
  ['would_you_rather', ['would-you-rather', 5]],
  ['draw_it', ['draw-it', 4]],
  ['trivia', ['trivia', 7]],
  ['describe_it', ['describle-it', 4]],
  ['question_of_the_day', ['q-of-the-day', 4]],
  ['werewolf_game', ['werewolf', 6]],
  ['two_words_game', ['mimic', 6]],
  ['charades', ['charades', 4]],
  ['draw_tell', ['draw-and-tell', 5]],
  ['one_to_hundred', ['one-to-hundred', 6]],
  ['yes_or_no', ['yes-or-no', 6]],
  ['funny_or_not', ['funny-or-not', 5]],
  ['spot_my_lie', ['spot-my-lie', 4]],
  ['hearts_game', ['heart', 4]],
  ['rummy_game', ['rummy', 4]],
  ['gin_rummy_game', ['gin', 4]],
  ['poll', ['poll', 5]],
  ['top5', ['top5-answer', 5]]
]);

const gameIcons = new Map([
  [
    'would_you_rather',
    'https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Fgame-icons%2Fwould_you_rather.svg?alt=media&token=f988b400-44d2-4268-a049-010946c6bcf3'
  ],
  [
    'draw_it',
    'https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Fgame-icons%2Fdraw_it.svg?alt=media&token=6fafc2a2-62e4-484f-9b52-8a5245be5df6'
  ],
  [
    'trivia',
    'https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Fgame-icons%2Ftrivia.svg?alt=media&token=8ae6cba9-ea08-432a-851e-f783a4834a4c'
  ],
  [
    'guided_meditation',
    'https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Fgame-icons%2Fguided_meditation.svg?alt=media&token=0c28c3f3-ecb4-477c-95e9-a1bed505a69e'
  ],
  [
    'describe_it',
    'https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Fgame-icons%2Fdescribe_it.svg?alt=media&token=7293dc10-4326-4193-ab09-b3337675529d'
  ],
  [
    'question_of_the_day',
    'https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Fgame-icons%2Fquestion_of_the_day.svg?alt=media&token=a81972d5-3e6c-49e9-a6cb-e2497b587646'
  ],
  [
    'werewolf_game',
    'https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Fgame-icons%2Fwerewolf_game.svg?alt=media&token=086ad3ee-492a-42ab-b6f8-d57dadb1a14e'
  ],
  [
    'two_words_game',
    'https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Fgame-icons%2Ftwo_words_game.svg?alt=media&token=de6d6b8d-ce25-4bbd-87d2-07279058822c'
  ],
  [
    'charades',
    'https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Fgame-icons%2Fcharades.svg?alt=media&token=836c07fa-f721-4d05-af78-dd98733f4a85'
  ],
  [
    'draw_tell',
    'https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Fgame-icons%2Fdraw_tell.svg?alt=media&token=ac699ea3-d1bd-4913-9b6f-637835bc8d97'
  ],
  [
    'one_to_hundred',
    'https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Fgame-icons%2Fone_to_hundred.svg?alt=media&token=2156ec62-2638-4d88-8acc-6b98a9e6a7b1'
  ],
  [
    'yes_or_no',
    'https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Fgame-icons%2Fyes_or_no.svg?alt=media&token=49ca999f-5f2c-4484-b247-9acb95de8d52'
  ],
  [
    'funny_or_not',
    'https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Fgame-icons%2Ffunny_or_not.svg?alt=media&token=e31bdaa1-8e23-4f4b-801f-70f845b2edd1'
  ],
  [
    'spot_my_lie',
    'https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Fgame-icons%2Fspot_my_lie.svg?alt=media&token=707a0be7-1ef9-4eea-a822-b77dc7c292fb'
  ],
  [
    'hearts_game',
    'https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Fgame-icons%2Fhearts_game.svg?alt=media&token=0725e10f-2beb-4bca-bcb2-9d36759eef09'
  ],
  [
    'rummy_game',
    'https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Fgame-icons%2Frummy_game.svg?alt=media&token=5d8bad8b-62ee-449c-978b-31519a7934c4'
  ],
  [
    'gin_rummy_game',
    'https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Fgame-icons%2Fgin_rummy_game.svg?alt=media&token=436cf3d2-4b3b-4317-9e42-b91a3c80057e'
  ],
  [
    'poll',
    'https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Fgame-icons%2Fpoll.svg?alt=media&token=05516a96-603d-4427-bdfb-5292c88fdcef'
  ],
  [
    'top5',
    'https://firebasestorage.googleapis.com/v0/b/brightful-prod.appspot.com/o/minigame_share_img_files%2Fgame-icons%2Ftop5.svg?alt=media&token=ebaf22d3-3b9a-4948-a280-ede2d345bea8'
  ]
  //   ['words_of_power', 'Words of Power'],
]);

const gameTypes = [
  'trivia',
  'draw_it',
  'would_you_rather',
  'describe_it',
  'poll',
  'top5',
  'funny_or_not',
  'charades',
  'werewolf_game',
  'question_of_the_day',
  'draw_tell',
  'yes_or_no',
  'one_to_hundred',
  'two_words_game',
  'spot_my_lie',
  'guided_meditation',
  'hearts_game',
  'rummy_game',
  'gin_rummy_game',
  GameName.LIVE_POLL_MULTIPLE_CHOICE,
  GameName.LIVE_POLL_RATING,
  GameName.LIVE_POLL_SHORT_ANSWER,
  GameName.LIVE_POLL_WORD_CLOUD,
  GameName.QNA,
  GameName.SPIN_THE_WHEEL
  //   'words_of_power',
];

const freeGameTypes = [''];
const newGameTypes = [''];

const comingSoonGameTypes = [''];

const detailGameTypes = ['guided_meditation'];

const versionGameTypes = [
  'draw_it',
  'trivia',
  'would_you_rather',
  'describe_it'
];

const questionBasedTypes = [
  'would_you_rather',
  'trivia',
  'question_of_the_day',
  'funny_or_not',
  'spot_my_lie',
  'poll',
  'top5'
];

const customGameTypes = [
  'would_you_rather',
  'draw_it',
  'trivia',
  'describe_it',
  'question_of_the_day',
  'two_words_game',
  'charades',
  'draw_tell',
  'one_to_hundred',
  'yes_or_no',
  'funny_or_not',
  'spot_my_lie',
  'poll',
  'top5'
];

const customGameWithOrderTypes = [
  'would_you_rather',
  'trivia',
  'question_of_the_day',
  'poll'
];

const scoreGameTypes = [
  'draw_it',
  // 'would_you_rather',
  'trivia',
  'describe_it',
  // 'poll',
  'funny_or_not',
  'charades',
  // 'werewolf_game',
  // 'question_of_the_day',
  // 'draw_tell',
  'yes_or_no'
  // 'one_to_hundred',
  // 'two_words_game',
  // 'spot_my_lie',
  // 'guided_meditation',
  // 'hearts_game',
  // 'rummy_game',
  // 'gin_rummy_game'
];

const groupWinnerGameTypes = [
  // 'draw_it',
  // 'would_you_rather',
  // 'trivia',
  // 'describe_it',
  // 'poll',
  // 'funny_or_not',
  // 'charades',
  'werewolf_game',
  // 'question_of_the_day',
  // 'draw_tell',
  // 'yes_or_no'
  // 'one_to_hundred',
  'two_words_game'
  // 'spot_my_lie',
  // 'guided_meditation',
  // 'hearts_game',
  // 'rummy_game',
  // 'gin_rummy_game'
];

const noResultGameTypes = [
  // 'draw_it',
  'would_you_rather',
  // 'trivia',
  // 'describe_it',
  'poll',
  // 'funny_or_not',
  // 'charades',
  // 'werewolf_game',
  'question_of_the_day',
  'draw_tell',
  // 'yes_or_no',
  // 'one_to_hundred',
  // 'two_words_game',
  // 'spot_my_lie',
  'guided_meditation'

  // 'hearts_game',
  // 'rummy_game',
  // 'gin_rummy_game'
];

const scoreboardGameTypes = [
  // 'draw_it',
  // 'would_you_rather',
  'trivia',
  'describe_it',
  // 'poll',
  // 'funny_or_not',
  'charades',
  // 'werewolf_game',
  // 'question_of_the_day',
  // 'draw_tell',
  'yes_or_no'
  // 'one_to_hundred',
  // 'two_words_game',
  // 'spot_my_lie'
  // 'guided_meditation',
  // 'hearts_game',
  // 'rummy_game',
  // 'gin_rummy_game'
];

const leaderboardGameTypes = [
  'draw_it',
  // 'would_you_rather',
  'trivia',
  'describe_it',
  // 'poll',
  'funny_or_not',
  'charades',
  'werewolf_game',
  // 'question_of_the_day',
  // 'draw_tell',
  'yes_or_no',
  // 'one_to_hundred',
  'two_words_game',
  'spot_my_lie',
  // 'guided_meditation',
  'hearts_game',
  'rummy_game',
  'gin_rummy_game',
  'top5'
];

const firestoreGameTypes = [
  'draw_it',
  'would_you_rather',
  'trivia',
  'describe_it',
  'poll',
  'top5',
  'funny_or_not',
  'charades',
  'werewolf_game',
  'question_of_the_day',
  'draw_tell',
  'yes_or_no',
  'one_to_hundred',
  // 'two_words_game',
  // 'spot_my_lie'
  'guided_meditation',
  GameName.QNA,
  GameName.LIVE_POLL_MULTIPLE_CHOICE,
  GameName.LIVE_POLL_RATING,
  GameName.LIVE_POLL_SHORT_ANSWER,
  GameName.LIVE_POLL_WORD_CLOUD,
  GameName.SPIN_THE_WHEEL
  // 'hearts_game',
  // 'rummy_game',
  // 'gin_rummy_game'
];

const previewGameTypes = [
  'draw_it',
  'would_you_rather',
  'trivia',
  'describe_it',
  'charades',
  'funny_or_not',
  'werewolf_game'
];

const friendlyCompetitionGameTypes = [
  'trivia',
  'draw_it',
  'describe_it',
  'charades',
  'funny_or_not',
  'one_to_hundred',
  'yes_or_no',
  'top5'
];

const boostConversationGameTypes = [
  'would_you_rather',
  'poll',
  'draw_tell',
  'question_of_the_day',
  'spot_my_lie'
];

const socialDeductionGameTypes = ['werewolf_game', 'two_words_game'];

const mindfulGameTypes = ['guided_meditation'];

const cardGameTypes = ['hearts_game', 'rummy_game', 'gin_rummy_game'];

const gameLeastCount = new Map([
  ['describe_it', 40],
  ['charades', 40],
  ['draw_it', 15],
  ['question_of_the_day', 10],
  ['yes_or_no', 10],
  ['funny_or_not', 8],
  ['spot_my_lie', 8],
  ['poll', 7]
]);

const onboardingVideos = [
  'https://www.youtube.com/embed/dOC3BzypyZQ',
  'https://www.youtube.com/embed/7_x8UkOi7Z4',
  'https://www.youtube.com/embed/zDckenuvCqY',
  'https://www.youtube.com/embed/lPJvcVOZFqU'
];

const onboardingVideoTitles = [
  'How to: Start a game',
  'How to: Invite players to your game room',
  'How to: Create a customized game',
  'How to: Add Brightful to Slack'
];

const pollColors = [
  'color-green',
  'color-yellow',
  'color-light-blue',
  'color-light-green',
  'color-pink',
  'color-grass-green',
  'color-additional-purple',
  'color-orange',
  'color-light-red',
  'color-dirty-blue'
];

const livePollTypes: string[] = [
  LivePollType.MULTIPLE_CHOICE,
  LivePollType.RATING,
  LivePollType.SHORT_ANSWER,
  LivePollType.WORD_CLOUD
];

const detailEventToolsTypes: string[] = [
  EventToolsType.LIVE_POLL,
  EventToolsType.SPIN_THE_WHEEL
];

function getGameTitleByType(type: string, expandPolling?: boolean) {
  return expandPolling
    ? gameTitlesWithPolling.get(type)
    : gameTitles.get(type) ?? '';
}

function getGameDescriptionByType(type: string) {
  return gameDescription.get(type);
}

function getGameRecommendedPlayersByType(type: string) {
  return gameRecommendedPlayers.get(type);
}

function getGameCssByType(type: string) {
  return gameCss.get(type);
}

function getGameHeroByType(type: string) {
  return gameHero.get(type);
}

function getGameVideoByType(type: string) {
  return gameVideo.get(type);
}

function getGameScreenshotsByType(type: string) {
  return gameScreenshots.get(type);
}

function getGameIconsByType(type: string) {
  return gameIcons.get(type);
}

function getGameLeastCountByType(type: string) {
  return gameLeastCount.get(type);
}

function areTwoQuestionBankSame(
  questionBankA: any[],
  questionBankB: any[],
  gameName: string
) {
  if (questionBankA.length !== questionBankB.length) {
    return false;
  }
  switch (gameName) {
    case 'would_you_rather': {
      for (let i = 0; i < questionBankA.length; i++) {
        if (
          questionBankA[i].question !== questionBankB[i].question ||
          questionBankA[i].choice1 !== questionBankB[i].choice1 ||
          questionBankA[i].choice2 !== questionBankB[i].choice2
        ) {
          return false;
        }
      }
      break;
    }
    case 'question_of_the_day':
    case 'spot_my_lie': {
      for (let i = 0; i < questionBankA.length; i++) {
        if (questionBankA[i].question !== questionBankB[i].question) {
          return false;
        }
      }
      break;
    }
    case 'draw_it':
    case 'describe_it':
    case 'charades': {
      for (let i = 0; i < questionBankA.length; i++) {
        if (questionBankA[i].word !== questionBankB[i].word) {
          return false;
        }
      }
      break;
    }
    case 'trivia': {
      for (let i = 0; i < questionBankA.length; i++) {
        if (
          questionBankA[i].question !== questionBankB[i].question ||
          questionBankA[i].choices[0] !== questionBankB[i].correct ||
          questionBankA[i].choices[1] !== questionBankB[i].incorrect1 ||
          questionBankA[i].choices[2] !== questionBankB[i].incorrect2 ||
          questionBankA[i].choices[3] !== questionBankB[i].incorrect3
        ) {
          return false;
        }
      }
      break;
    }
    case 'two_words_game': {
      for (let i = 0; i < questionBankA.length; i++) {
        if (
          questionBankA[i].clue !== questionBankB[i].clue ||
          questionBankA[i].word !== questionBankB[i].word
        ) {
          return false;
        }
      }
      break;
    }
    case 'draw_tell': {
      for (let i = 0; i < questionBankA.length; i++) {
        if (questionBankA[i].topic !== questionBankB[i].topic) {
          return false;
        }
      }
      break;
    }
    case 'poll': {
      for (let i = 0; i < questionBankA.length; i++) {
        if (
          questionBankA[i].question !== questionBankB[i].question ||
          questionBankA[i].choices.length !== questionBankB[i].choices.length
        ) {
          return false;
        }
      }
      break;
    }
    default:
      break;
  }
  return true;
}

function textFromChoices(game: string, data: any) {
  switch (game as GameName) {
    case GameName.CHARADES:
    case GameName.DESCRIBE_IT:
    case GameName.DRAW_IT:
      return data.word;
    case GameName.TWO_WORDS_GAME:
      return `${data.clue} / ${data.word}`;
    case GameName.WEREWOLF_GAME:
    case GameName.SPOT_MY_LIE:
    case GameName.QUESTION_OF_THE_DAY:
    case GameName.TRIVIA:
    case GameName.DRAW_TELL:
    case GameName.FUNNY_OR_NOT:
      return capitalizeFirstLetter(data.question);
    case GameName.WOULD_YOU_RATHER:
      return `${capitalizeFirstLetter(data.question)} ${data.choice1} or ${
        data.choice2
      }`;
    case GameName.ONE_TO_HUNDRED:
      return {
        topic: capitalizeFirstLetter(data.topic),
        left: capitalizeFirstLetter(data.left),
        right: capitalizeFirstLetter(data.right)
      };
    case GameName.YES_OR_NO:
      return {
        word: capitalizeFirstLetter(data.word),
        hint: capitalizeFirstLetter(data.hint)
      };
    case GameName.POLL:
      return {
        question: capitalizeFirstLetter(data.question),
        choices: data.choices,
        choice: data.choices
          .map(c => `(${data.choices.indexOf(c) + 1}) ${c}`)
          .join(' ')
      };
    case GameName.TOP5:
      return {
        question: capitalizeFirstLetter(data.question),
        answers: data.answers,
        answer: data.answers
          .map(c => `(${data.answers.indexOf(c) + 1}) ${c}`)
          .join(' ')
      };
  }
}

export {
  gameVideo,
  gameTypes,
  freeGameTypes,
  newGameTypes,
  comingSoonGameTypes,
  versionGameTypes,
  detailGameTypes,
  questionBasedTypes,
  customGameTypes,
  customGameWithOrderTypes,
  scoreGameTypes,
  groupWinnerGameTypes,
  noResultGameTypes,
  scoreboardGameTypes,
  leaderboardGameTypes,
  firestoreGameTypes,
  previewGameTypes,
  friendlyCompetitionGameTypes,
  boostConversationGameTypes,
  socialDeductionGameTypes,
  mindfulGameTypes,
  cardGameTypes,
  onboardingVideos,
  onboardingVideoTitles,
  pollColors,
  livePollTypes,
  detailEventToolsTypes,
  getGameTitleByType,
  getGameDescriptionByType,
  getGameRecommendedPlayersByType,
  getGameCssByType,
  getGameHeroByType,
  getGameVideoByType,
  getGameScreenshotsByType,
  getGameIconsByType,
  getGameLeastCountByType,
  areTwoQuestionBankSame,
  textFromChoices
};
