
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'CreateWheelWithoutHistory',
  emits: {
    'show-modal': () => true
  },
  props: {},
  components: {},
  setup(_, context) {
    const hasPollHistory = ref(false);

    function onCreate() {
      context.emit('show-modal');
    }
    return {
      hasPollHistory,
      onCreate
    };
  }
});
