
import { getGameTitleByType } from '@/utils/gameConstants';
import { defineComponent, ref } from 'vue';

import QnaDetailModal from '@/components/EventTools/Qna/QnaDetailModal.vue';
import { eventToolsParticipantsWithoutHost } from '@/utils/helper';
import { QnaActivityHistory } from '@/models/event_tools/qna/qna';
export default defineComponent({
  name: 'HistoryLiveQnaTile',
  props: {
    game: Object as () => QnaActivityHistory
  },
  emits: ['open'],
  setup(props) {
    const title = ref(getGameTitleByType(props.game!.gameName!));
    const dateString = ref(props.game!.dateCreated.format('MMM DD'));
    const openModal = ref(false);
    function onOpenModal() {
      openModal.value = true;
    }

    return {
      title,
      dateString,
      openModal,
      onOpenModal,
      eventToolsParticipantsWithoutHost
    };
  },
  components: { QnaDetailModal }
});
