
import { defineComponent, onMounted } from 'vue';
import { amplitudeEvent } from '@/config/amplitudeConfig';
// import NumberOfPlayersWordsOfPower from '@/components/Modal/AboutComponent/NumberOfPlayersComponents/NumberOfPlayersWordsOfPower.vue';
import HowToPlayWordsOfPower from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayWordsOfPower.vue';

export default defineComponent({
  name: 'AboutWordsOfPower',
  components: {
    // NumberOfPlayersWordsOfPower,
    HowToPlayWordsOfPower
  },
  setup() {
    onMounted(() => {
      amplitudeEvent('previewGame', {
        game: 'words_of_power',
        version: 'regular',
        eventTime: new Date(Date.now()).toISOString()
      });
    });
  }
});
