import firebaseApp from '@/firebaseApp';
import { uuid } from 'vue-uuid';

export default class ChatModel {
  static participantChannelId = function(whiteboardId: string, uid: string) {
    // let numberedWhiteboardId = whiteboardId.replace('whiteboard_', '');
    // return `CH_${numberedWhiteboardId}_${uid}`;
    return `CH${whiteboardId}${uid}`;
  };
  static channelRef = function(channelSid: string) {
    return firebaseApp
      .firestore()
      .collection('whiteboard_channel')
      .doc(channelSid);
  };
  static messagesRef = function(channelSid: string) {
    return firebaseApp
      .firestore()
      .collection('whiteboard_channel')
      .doc(channelSid)
      .collection('messages');
  };
  static membersRef = function(channelSid: string) {
    return firebaseApp
      .firestore()
      .collection('whiteboard_channel')
      .doc(channelSid)
      .collection('members');
  };

  static sendMessage = async function(
    uid: string,
    channelSid: string,
    body: string,
    type: string,
    attributes: any
  ) {
    const messageSid: string = 'IM' + uuid.v4().replace(/-/g, '');

    const newMessage: any = {
      attributes: attributes,
      body: body,
      channelSid: channelSid,
      dateCreated: firebaseApp.firestore.FieldValue.serverTimestamp(),
      dateUpdated: firebaseApp.firestore.FieldValue.serverTimestamp(),
      from: uid,
      lastUpdatedBy: uid,
      to: null,
      readBy: [],
      sid: messageSid,
      wasEdited: false
    };
    const batch = firebaseApp.firestore().batch();

    batch.set(ChatModel.messagesRef(channelSid).doc(messageSid), newMessage);
    await batch.commit();
    ChatModel.channelRef(channelSid).update({
      lastMessageDate: firebaseApp.firestore.FieldValue.serverTimestamp()
    });
    return newMessage;
  };

  setReadBy = async function(
    uid: string,
    channelSid: string,
    readMessageSid: Array<string>
  ) {
    if (readMessageSid.length == 0) return null;

    const batch = firebaseApp.firestore().batch();
    for (let i = 0; i < readMessageSid.length; i++) {
      const messageSid = readMessageSid[i];
      batch.update(ChatModel.messagesRef(channelSid).doc(messageSid), {
        readBy: firebaseApp.firestore.FieldValue.arrayUnion([uid])
      });
    }
    await batch.commit();
    return;
  };

  static setLastConsumedMessageIndex = async function(
    uid: string,
    channelSid: string,
    index: number
  ) {
    await ChatModel.membersRef(channelSid)
      .doc(uid)
      .update({ lastConsumedMessageIndex: index });

    return;
  };
  static setParticipantMessageCount = async function(
    uid: string,
    whiteboardId: string,
    participantMessagesCount: number
  ) {
    await firebaseApp
      .firestore()
      .collection('whiteboard_data')
      .doc(whiteboardId)
      .collection('participants')
      .doc(uid)
      .update({
        participantMessagesCount: participantMessagesCount
      });

    return;
  };
  static setUnreadCounts = async function(
    uid: string,
    whiteboardId: string,
    unreadCounts: Object
  ) {
    await firebaseApp
      .firestore()
      .collection('whiteboard_data')
      .doc(whiteboardId)
      .collection('participants')
      .doc(uid)
      .update({
        unreadCounts: unreadCounts
      });

    return;
  };
}
