
import { defineComponent, onBeforeMount, ref, watch } from 'vue';
import { OneToHundredTopic } from '@/models/OneToHundredTopic';

export default defineComponent({
  name: 'GeneralOneToHundredInputItem',
  props: {
    id: {
      type: String,
      required: true
    },
    number: {
      type: Number,
      required: true
    },
    value: OneToHundredTopic || Object,
    checked: Boolean
  },
  emits: ['valuechanged', 'allfilled'],
  setup(props, context) {
    const checkedValue = ref(false);
    const topic = ref('');
    const left = ref('');
    const right = ref('');

    const maxOneToHundredInputLength = 50;

    const onValue = function(isCheckbox: Boolean) {
      context.emit('valuechanged', {
        checked: checkedValue.value,
        topic: topic.value,
        left: left.value,
        right: right.value,
        index: props.number
      });
      if (!isCheckbox) {
        if (topic.value != '' && left.value != '' && right.value != '') {
          context.emit('allfilled');
        }
      }
    };

    onBeforeMount(() => {
      checkedValue.value = props.checked ?? false;
      topic.value = props.value?.topic ?? '';
      left.value = props.value?.left ?? '';
      right.value = props.value?.right ?? '';
    });

    watch(props, newValue => {
      checkedValue.value = newValue.checked ?? false;
      topic.value = props.value?.topic ?? '';
      left.value = props.value?.left ?? '';
      right.value = props.value?.right ?? '';
    });

    return {
      checkedValue,
      topic,
      left,
      right,
      maxOneToHundredInputLength,
      onValue
    };
  }
});
