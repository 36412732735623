
import { defineComponent, onMounted } from 'vue';
import { amplitudeEvent } from '@/config/amplitudeConfig';
// import NumberOfPlayersDrawIt from '@/components/Modal/AboutComponent/NumberOfPlayersComponents/NumberOfPlayersDrawIt.vue';
import HowToPlayDrawIt from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayDrawIt.vue';

export default defineComponent({
  name: 'AboutDrawIt',
  components: {
    // NumberOfPlayersDrawIt,
    HowToPlayDrawIt
  },
  setup() {
    onMounted(() => {
      amplitudeEvent('previewGame', {
        game: 'draw_it',
        version: 'regular',
        eventTime: new Date(Date.now()).toISOString()
      });
    });
  }
});
