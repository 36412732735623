
import { onboardingVideos, onboardingVideoTitles } from '@/utils/gameConstants';
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'OnboardingVideoModal',
  components: {},
  props: {
    index: {
      type: Number,
      required: true
    }
  },
  emits: ['close'],
  setup(props, context) {
    const currentIndex = ref(props.index);
    const videoLink = computed(() => onboardingVideos[currentIndex.value]);
    const videoTitle = computed(
      () => onboardingVideoTitles[currentIndex.value]
    );

    const prevVideo = function() {
      currentIndex.value = currentIndex.value - 1;
      if (currentIndex.value < 0) {
        currentIndex.value = onboardingVideos.length - 1;
      }
    };
    const nextVideo = function() {
      currentIndex.value = currentIndex.value + 1;
      if (currentIndex.value >= onboardingVideos.length) {
        currentIndex.value = 0;
      }
    };

    const close = function() {
      context.emit('close');
    };

    return {
      currentIndex,
      videoLink,
      videoTitle,
      prevVideo,
      nextVideo,
      close
    };
  }
});
