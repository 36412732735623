
import { computed, defineComponent, onMounted, ref } from 'vue';
import firebaseApp from '@/firebaseApp';
import useFirebaseAuth from '@/hooks/firebase/useFirebaseAuth';
import { amplitudeEvent } from '@/config/amplitudeConfig';
export default defineComponent({
  name: 'PollHostLaunchPoll',
  props: {
    whiteboardId: {
      type: String,
      required: true
    },
    numParticipants: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const { currentUser } = useFirebaseAuth();
    const maxOptionLength = 280;
    const maxMultipleChoicesOptions = 10;
    const buttonLoading = ref(false);
    const errorMessage = ref('');
    const pollQuestion = ref('');
    // 0 is multiple-choice-one, 1 is multiple-choice-multiple
    const selectedPollType = ref(0);
    const optionsCount = ref(2);
    const options = ref(new Array(2).fill(''));

    const selectePollType = function(tab: number) {
      selectedPollType.value = tab;
    };

    const onValue = function(index: number, option: string) {
      options.value[index - 1] = option;
    };

    const onAddOption = function() {
      if (optionsCount.value >= maxMultipleChoicesOptions) return;
      options.value.push('');
      optionsCount.value++;
    };

    const onRemoveOption = function(index: number) {
      options.value.splice(index - 1, 1);
      optionsCount.value--;
    };

    const validPoll = computed(() => {
      return (
        pollQuestion.value.trim() &&
        options.value.filter(option => option.trim() !== '').length >= 2
      );
    });

    const launchPoll = async function() {
      buttonLoading.value = true;
      if (currentUser.value === null) return;
      try {
        const trimmedQuestion = pollQuestion.value.trim();
        const filteredOptions = options.value
          .filter(option => option.trim() !== '')
          .map(option => option.trim());
        const pollRef = firebaseApp
          .firestore()
          .collection(`whiteboard_data/${props.whiteboardId}/polls`)
          .doc();
        const batch = firebaseApp.firestore().batch();
        batch.set(pollRef, {
          creator: currentUser.value.uid,
          isActive: true,
          question: trimmedQuestion,
          type:
            selectedPollType.value === 0
              ? 'multiple-choice-one'
              : 'multiple-choice-multiple',
          voteCounts: 0
        });
        for (let i = 0; i < filteredOptions.length; i++) {
          batch.set(pollRef.collection('answers').doc(`option-${i + 1}`), {
            answer: filteredOptions[i],
            lastUpdated: firebaseApp.firestore.FieldValue.serverTimestamp(),
            voters: []
          });
        }
        batch.update(
          firebaseApp
            .firestore()
            .collection('whiteboard_data')
            .doc(props.whiteboardId),
          {
            hasActivePoll: true
          }
        );
        await batch.commit();
        amplitudeEvent('launchPoll', {
          numParticipants: props.numParticipants,
          choiceType:
            selectedPollType.value === 0
              ? 'multiple-choice-one'
              : 'multiple-choice-multiple',
          numberOfChoices: filteredOptions.length,
          whiteboardId: props.whiteboardId
        });
      } catch (err) {
        console.log(err);
        errorMessage.value =
          'An unexpected error has occured. Please try again later or contact support@brightful.me for more info.';
      } finally {
        buttonLoading.value = false;
      }
    };

    onMounted(() => {
      amplitudeEvent('openPollEditor', {
        whiteboardId: props.whiteboardId
      });
    });

    return {
      maxOptionLength,
      maxMultipleChoicesOptions,
      buttonLoading,
      errorMessage,
      pollQuestion,
      selectedPollType,
      optionsCount,
      options,
      selectePollType,
      onValue,
      onAddOption,
      onRemoveOption,
      validPoll,
      launchPoll
    };
  }
});
