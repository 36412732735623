
import { defineComponent } from 'vue';
import useFirebaseAuth from '@/hooks/firebase/useFirebaseAuth';
import WhiteboardEntryHost from '@/components/Whiteboard/WhiteboardEntryHost.vue';
import WhiteboardEntryParticipant from '@/components/Whiteboard/WhiteboardEntryParticipant.vue';

export default defineComponent({
  name: 'WhiteboardEntry',
  props: {
    whiteboardData: {
      type: Object,
      required: true
    }
  },
  components: {
    WhiteboardEntryHost,
    WhiteboardEntryParticipant
  },
  setup() {
    const { currentUser } = useFirebaseAuth();

    return { currentUser };
  }
});
