
import useFirebaseCollection from '@/hooks/firebase/useFirebaseCollection';
import { getGameTitleByType } from '@/utils/gameConstants';
import { computed, defineComponent, ref } from 'vue';
import { useStore } from '@/store';

import GameVersionTile from '@/components/Home/GameVersionTile.vue';
import { isFreePlan, isFreeTrialPlan } from '@/utils/pricing_rules';

export default defineComponent({
  name: 'GameVersionModal',
  components: {
    GameVersionTile
  },
  props: {
    gameName: {
      type: String,
      required: true
    }
  },
  emits: ['close', 'about'],
  setup(props, context) {
    const store = useStore();
    const user = computed(() => store.getters.getUser);
    const subscriptionDetails = computed(
      () => store.getters.getSubscriptionDetails
    );

    const userCustomizedGames = computed(
      () => store.getters.getUserCustomizedGames
    );

    const show = ref(true);
    const isLoading = ref(false);
    const customGameVersions = ref([] as any[]);
    const myGameVersions = ref([] as any[]);
    const othersGameVersions = ref([] as any[]);

    const gameVersionIndex = ref(-1);

    const notCustomGameAccess = computed(
      () =>
        gameVersionIndex.value >= myGameVersions.value.length &&
        (isFreeTrialPlan(subscriptionDetails.value) ||
          isFreePlan(subscriptionDetails.value))
    );

    async function loadGames() {
      const editorsPickGamesQuery = useFirebaseCollection(
        'minigame_custom_game_public',
        {
          onMounted: false
        }
      );
      await editorsPickGamesQuery.getCollection({
        fieldPath: 'isEditorsPick',
        opStr: '==',
        value: true,
        fieldPath3: 'gameName',
        opStr3: '==',
        value3: props.gameName,
        orderBy: 'dateCreated',
        isDesc: true,
        limit: 50
      });
      customGameVersions.value = Object.assign(
        [],
        editorsPickGamesQuery.collectionData.value
      );
      customGameVersions.value.sort((a, b) => {
        if (a.sortOrder && b.sortOrder) {
          return a.sortOrder - b.sortOrder;
        } else if (a.sortOrder && !b.sortOrder) {
          return -1;
        } else if (!a.sortOrder && b.sortOrder) {
          return 1;
        } else {
          return b.dateUpdated.toDate() - a.dateUpdated.toDate();
        }
      });
      myGameVersions.value = (userCustomizedGames.value ?? []).filter(
        game => game.gameName === props.gameName
      );
      othersGameVersions.value = customGameVersions.value.filter(
        a => a.created_by !== user.value.uid
      );
    }

    function selectGameVersion(index) {
      gameVersionIndex.value = index;
    }

    loadGames();

    function createRoom() {
      isLoading.value = true;
      const onSuccess = () => {
        isLoading.value = false;
      };
      const onError = () => {
        isLoading.value = false;
      };
      if (!notCustomGameAccess.value) {
        if (gameVersionIndex.value === -1) {
          context.emit('close', {
            gameName: props.gameName,
            customGameName: undefined,
            customGameId: undefined,
            isCommunityCreated: false,
            onSuccess: onSuccess,
            onError: onError
          });
        } else if (gameVersionIndex.value < myGameVersions.value.length) {
          context.emit('close', {
            gameName: props.gameName,
            customGameName:
              myGameVersions.value[gameVersionIndex.value]['customGameName'],
            customGameId:
              myGameVersions.value[gameVersionIndex.value]['customGameId'],
            isCommunityCreated: false,
            onSuccess: onSuccess,
            onError: onError
          });
        } else {
          context.emit('close', {
            gameName: props.gameName,
            customGameName:
              othersGameVersions.value[
                gameVersionIndex.value - myGameVersions.value.length
              ]['customGameName'],
            customGameId:
              othersGameVersions.value[
                gameVersionIndex.value - myGameVersions.value.length
              ]['customGameId'],
            isCommunityCreated: true,
            onSuccess: onSuccess,
            onError: onError
          });
        }
      } else {
        onSuccess();
      }
    }

    function showAbout() {
      context.emit('about');
    }

    function close() {
      context.emit('close', {
        gameName: undefined,
        customGameName: undefined,
        customGameId: undefined,
        isCommunityCreated: false
      });
    }

    return {
      show,
      isLoading,
      customGameVersions,
      myGameVersions,
      othersGameVersions,
      gameVersionIndex,
      showAbout,
      close,
      getGameTitleByType,
      selectGameVersion,
      createRoom
    };
  }
});
