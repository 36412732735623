
import { defineComponent, onMounted } from 'vue';
import { amplitudeEvent } from '@/config/amplitudeConfig';
// import NumberOfPlayersQOfTheDay from '@/components/Modal/AboutComponent/NumberOfPlayersComponents/NumberOfPlayersQOfTheDay.vue';
import HowToPlayQOfTheDay from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayQOfTheDay.vue';
import SampleQuestionQOfTheDay from '@/components/Modal/AboutComponent/SampleQuestionComponents/SampleQuestionQOfTheDay.vue';

export default defineComponent({
  name: 'AboutQOfTheDay',
  components: {
    // NumberOfPlayersQOfTheDay,
    HowToPlayQOfTheDay,
    SampleQuestionQOfTheDay
  },
  setup() {
    onMounted(() => {
      amplitudeEvent('previewGame', {
        game: 'question_of_the_day',
        version: 'regular',
        eventTime: new Date(Date.now()).toISOString()
      });
    });
  }
});
