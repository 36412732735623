
import { copyToClipboard, isProd, isPlayground } from '@/utils/helper';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'CopyGameLinkBox',
  props: {
    customGameId: {
      type: String
    },
    whiteboardId: {
      type: String
    }
  },
  setup(props) {
    const isGameRoomLink = props.customGameId !== undefined;
    const linkToCopy = isProd
      ? isGameRoomLink
        ? `https://app.brightful.me/dashboard/?customGame=${props.customGameId}`
        : `https://www.brightful.me/live/${props.whiteboardId!.replace(
            'whiteboard_',
            ''
          )}`
      : isPlayground
      ? isGameRoomLink
        ? `http://localhost:8080/?customGame=${props.customGameId}`
        : `https://localhost:8080/live/${props.whiteboardId!.replace(
            'whiteboard_',
            ''
          )}`
      : isGameRoomLink
      ? `https://dev.orric.app/dashboard/?customGame=${props.customGameId}`
      : `https://www.orric.app/live/${props.whiteboardId!.replace(
          'whiteboard_',
          ''
        )}`;
    const copyGameLinkButtonText = ref('COPY');

    const onCopyGameLink = function() {
      copyToClipboard(linkToCopy);
      copyGameLinkButtonText.value = 'COPIED';
      setTimeout(function() {
        copyGameLinkButtonText.value = 'COPY';
      }, 3000);
    };

    return {
      linkToCopy,
      copyGameLinkButtonText,
      onCopyGameLink
    };
  }
});
