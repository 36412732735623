
import { RatingLivePoll } from '@/models/event_tools/live_poll/live_poll_model';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'RatingPreviewModal',

  props: {
    livePoll: {
      type: Object as () => RatingLivePoll,
      default:null,
      required:true
    }
  },
  emits: ['close'],
  setup(_, context) {
    function onClose() {
      context.emit('close');
    }

    return {
      onClose
    };
  },
  mounted() {
    const chart = this.$el.querySelector('.preview-rating > .chart');
    const childCount = chart.children.length;
    chart.setAttribute('data-value', childCount);
  }
});
