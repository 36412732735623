
import { defineComponent, onBeforeMount, ref, watch } from 'vue';
import { SingleQuestion } from '@/models/SingleQuestion';

export default defineComponent({
  name: 'GeneralSingleInputItem',
  props: {
    id: {
      type: String,
      required: true
    },
    number: {
      type: Number,
      required: true
    },
    gameName: {
      type: String
    },
    value: SingleQuestion || Object,
    checked: Boolean
  },
  emits: ['valuechanged', 'allfilled'],
  setup(props, context) {
    const checkedValue = ref(false);
    const question = ref('');

    const maxQOTDSpotMyLieInputLength = 140;

    const onValue = function(isCheckbox: Boolean) {
      context.emit('valuechanged', {
        checked: checkedValue.value,
        question: question.value,
        index: props.number
      });
      if (!isCheckbox) {
        if (question.value != '') {
          context.emit('allfilled');
        }
      }
    };

    onBeforeMount(() => {
      checkedValue.value = props.checked ?? false;
      if (props.gameName == 'draw_tell')
        question.value = props.value?.topic ?? '';
      else question.value = props.value?.question ?? '';
    });

    watch(props, newValue => {
      checkedValue.value = newValue.checked ?? false;
      if (props.gameName == 'draw_tell')
        question.value = newValue.value?.topic ?? '';
      else question.value = newValue.value?.question ?? '';
    });

    return {
      checkedValue,
      question,
      maxQOTDSpotMyLieInputLength,
      onValue
    };
  }
});
