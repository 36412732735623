import { isPlayground, isDev, getCookie, setCookie } from '../utils/helper';
import amplitude from 'amplitude-js';
import firebase from '@/firebaseApp';
import { store } from '@/store';

export function initAmplitude() {
  try {
    let key: string;
    if (isPlayground) key = '9279bd321ccc4cf647dd62531ea3d4ad';
    else if (isDev) key = 'e3b11f33776ddf256ef5a0755cd80a67';
    else key = 'e0bddb97e8d2c849bc94e4a9af95958c';
    const visitorCache = getCookie('visitorCache');
    if (visitorCache === '') {
      const user = firebase.auth().currentUser;
      if (user === null || user === undefined || user.isAnonymous) {
        amplitude.getInstance().init(key, undefined, {
          includeReferrer: true,
          includeUtm: true,
          saveParamsReferrerOncePerSession: true
        });
      } else {
        amplitude.getInstance().init(key, user.uid, {
          includeReferrer: true,
          includeUtm: true,
          saveParamsReferrerOncePerSession: true
        });
      }
    } else {
      const visitorId = JSON.parse(visitorCache)['visitorId'];
      setCookie('visitorCache', JSON.stringify({ visitorId: visitorId }), 365);
      amplitude.getInstance().init(key, visitorId, {
        includeReferrer: true,
        includeUtm: true,
        saveParamsReferrerOncePerSession: true
      });
    }
  } catch (err) {
    console.error(err);
  }
}

export function amplitudeEvent(eventName: string, properties: any) {
  try {
    const inZoomClient = store.state.zoomClientStatus?.inZoomClient ?? false;
    const urlParams = new URLSearchParams(window.location.search);
    if (!properties) properties = {};
    properties['inZoomClient'] = inZoomClient;
    if (urlParams.has('source')) {
      properties['source'] = urlParams.get('source');
    }
    amplitude.getInstance().logEvent(eventName, properties);
    console.log(`${eventName} logged`);
    return;
  } catch (err) {
    console.error(err);
  }
}

export function identifyAmplitude(
  mode: string,
  propertyName: string,
  propertyValue: string | number
) {
  try {
    switch (mode) {
      case 'add': {
        const identify = new amplitude.Identify().add(
          propertyName,
          propertyValue
        );
        amplitude.getInstance().identify(identify);
        break;
      }
      case 'append': {
        const identify = new amplitude.Identify().append(
          propertyName,
          propertyValue
        );
        amplitude.getInstance().identify(identify);
        break;
      }
      case 'prepend': {
        const identify = new amplitude.Identify().prepend(
          propertyName,
          propertyValue
        );
        amplitude.getInstance().identify(identify);
        break;
      }
      case 'set': {
        const identify = new amplitude.Identify().set(
          propertyName,
          propertyValue
        );
        amplitude.getInstance().identify(identify);
        break;
      }
      case 'setOnce': {
        const identify = new amplitude.Identify().setOnce(
          propertyName,
          propertyValue
        );
        amplitude.getInstance().identify(identify);
        break;
      }
      case 'unset': {
        const identify = new amplitude.Identify().unset(propertyName);
        amplitude.getInstance().identify(identify);
        break;
      }
    }
  } catch (err) {
    console.error(err);
  }
}
