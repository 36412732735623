
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HowToPlayDrawIt',
  props: {
    outClass: {
      type: String,
      default: 'settings'
    },
    showNumber: {
      type: Boolean,
      default: true
    }
  }
});
