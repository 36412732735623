import { SubscriptionDetails } from '@/models/SubscriptionDetails';
import { SubscriptionInterval } from '@/models/SubscriptionInterval';
import { SubscriptionLevel } from '@/models/SubscriptionLevel';
import { TeamRole } from '@/models/TeamRole';

export function stripeMeteredActive(subscriptionDetails: SubscriptionDetails) {
  if (subscriptionDetails.subscriptionLevel == SubscriptionLevel.ONE_OFF_EVENT)
    return true;
  if (
    [SubscriptionLevel.LITE, SubscriptionLevel.PRO].includes(
      subscriptionDetails.subscriptionLevel!
    )
  ) {
    return subscriptionDetails.stripeMeteredActive ?? false;
  }
  // if (subscriptionDetails.teamId) {
  //   // if it is a team's account make sure owner's metered is active too
  //   return subscriptionDetails.teamClaims?.stripeMeteredActive ?? false;
  // }
  return true;
}

export function isFreePlan(subscriptionDetails: SubscriptionDetails) {
  if (subscriptionDetails.teamId) return false;

  const level = subscriptionDetails.subscriptionLevel;
  if (!level) return true;
  else if ([SubscriptionLevel.LITE, SubscriptionLevel.PRO].includes(level)) {
    return !stripeMeteredActive(subscriptionDetails);
  }
  return level == SubscriptionLevel.FREE;
}

export function isFreeTrialPlan(subscriptionDetails: SubscriptionDetails) {
  return (
    subscriptionDetails.subscriptionLevel === SubscriptionLevel.FREE_TRIAL &&
    !subscriptionDetails.teamId
  );
}

export function isExpiredFreeTrialPlan(
  subscriptionDetails: SubscriptionDetails,
  userDoc: any
) {
  if (!userDoc) return false;
  const freeTrialEnded = userDoc['freeTrialEnded'] ?? false;
  const isFingerprintJSFlagged = userDoc['isFingerprintJSFlagged'] ?? false;
  return (
    (freeTrialEnded || isFingerprintJSFlagged) &&
    (isFreeTrialPlan(subscriptionDetails) ||
      !subscriptionDetails.subscriptionInterval) &&
    !subscriptionDetails.teamId
  );
}

export function isActiveSubscription(subscriptionDetails: SubscriptionDetails) {
  return (
    !isFreePlan(subscriptionDetails) && !isFreeTrialPlan(subscriptionDetails)
  );
}

export function isOneOffPlan(subscriptionDetails: SubscriptionDetails) {
  return (
    subscriptionDetails.subscriptionLevel == SubscriptionLevel.ONE_OFF_EVENT
  );
}

export function availableForUpgrade(subscriptionDetails: SubscriptionDetails) {
  const level = subscriptionDetails.subscriptionLevel;
  const interval = subscriptionDetails.subscriptionInterval;
  if (
    (level == SubscriptionLevel.PRO && interval == SubscriptionInterval.YEAR) ||
    (level == SubscriptionLevel.GOLD && interval == SubscriptionInterval.YEAR)
  )
    return false;
  return true;
}

export function isLitePlan(subscriptionDetails: SubscriptionDetails) {
  return (
    subscriptionDetails.subscriptionLevel === SubscriptionLevel.LITE &&
    stripeMeteredActive(subscriptionDetails)
  );
}

export function isGoldPlan(subscriptionDetails: SubscriptionDetails) {
  return subscriptionDetails.subscriptionLevel === SubscriptionLevel.GOLD;
}

export function isProPlan(subscriptionDetails: SubscriptionDetails) {
  return (
    (eligibleForTeam(subscriptionDetails) || subscriptionDetails.teamId) &&
    stripeMeteredActive(subscriptionDetails)
  );
}

export function isLevel1Plan(subscriptionDetails: SubscriptionDetails) {
  return (
    subscriptionDetails.subscriptionLevel &&
    [SubscriptionLevel.LITE, SubscriptionLevel.BRONZE].includes(
      subscriptionDetails.subscriptionLevel
    ) &&
    stripeMeteredActive(subscriptionDetails)
  );
}

export function isLevel2Plan(subscriptionDetails: SubscriptionDetails) {
  return isProPlan(subscriptionDetails) || isGoldPlan(subscriptionDetails);
}

export function isFreeOrLitePlan(
  subscriptionDetails: SubscriptionDetails,
  userDoc: any
) {
  return (
    isFreePlan(subscriptionDetails) ||
    isLitePlan(subscriptionDetails) ||
    isExpiredFreeTrialPlan(subscriptionDetails, userDoc)
  );
}

export function eligibleForTeam(subscriptionDetails: SubscriptionDetails) {
  return (
    subscriptionDetails.subscriptionLevel &&
    [SubscriptionLevel.PRO, SubscriptionLevel.ENTERPRISE].includes(
      subscriptionDetails.subscriptionLevel
    ) &&
    stripeMeteredActive(subscriptionDetails)
  );
}

export function hasTeamManagementAccess(
  subscriptionDetails: SubscriptionDetails
) {
  return (
    subscriptionDetails.subscriptionLevel &&
    ([SubscriptionLevel.PRO, SubscriptionLevel.ENTERPRISE].includes(
      subscriptionDetails.subscriptionLevel
    ) ||
      (subscriptionDetails.teamId &&
        subscriptionDetails.teamRole !== TeamRole.Host)) &&
    stripeMeteredActive(subscriptionDetails)
  );
}

export function canAccessSlack(
  subscriptionDetails: SubscriptionDetails,
  userDoc: any
) {
  return (
    (isFreeTrialPlan(subscriptionDetails) &&
      !isExpiredFreeTrialPlan(subscriptionDetails, userDoc)) ||
    isLitePlan(subscriptionDetails) ||
    isProPlan(subscriptionDetails) ||
    isGoldPlan(subscriptionDetails)
  );
}

export function allPremiumFeatures(
  subscriptionDetails: SubscriptionDetails,
  userDoc: any
) {
  return (
    isLevel2Plan(subscriptionDetails) ||
    isOneOffPlan(subscriptionDetails) ||
    (isFreeTrialPlan(subscriptionDetails) &&
      !isExpiredFreeTrialPlan(subscriptionDetails, userDoc))
  );
}

export const canAccessFullHistory = (
  subscriptionDetails: SubscriptionDetails,
  userDoc: any
) => allPremiumFeatures(subscriptionDetails, userDoc);

export const canAccessFullLeaderboard = (
  subscriptionDetails: SubscriptionDetails,
  userDoc: any
) => allPremiumFeatures(subscriptionDetails, userDoc);

export const canAccessUsageReport = (
  subscriptionDetails: SubscriptionDetails
) => hasTeamManagementAccess(subscriptionDetails);

export const canAccessEventTools = (subscriptionDetails: SubscriptionDetails) =>
  !isFreePlan(subscriptionDetails);
