import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "games-filters mt-4"
}
const _hoisted_2 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CategoryFilterMobile = _resolveComponent("CategoryFilterMobile")
  const _component_HistoryList = _resolveComponent("HistoryList")
  const _component_HistoryListSkeletonLoading = _resolveComponent("HistoryListSkeletonLoading")

  return (_openBlock(), _createBlock(_Fragment, null, [
    (_ctx.canAccessFullHistoryValue && _ctx.collectionData.length > 0)
      ? (_openBlock(), _createBlock("section", _hoisted_1, [
          _createVNode("div", _hoisted_2, [
            _createVNode(_component_CategoryFilterMobile, {
              icons: ['icon-category', 'icon-calendar'],
              titles: ['Game', 'Period'],
              textForNoFilter: ['All', 'All time'],
              multiFilters: [_ctx.historyGameNames, _ctx.gamePeriods],
              selectedIndices: [_ctx.historyGameNameIndex, _ctx.historyGamePeriod],
              includeAlls: [true, true],
              hasDates: [false, true],
              dateTypes: [[], ['month', 'year']],
              enabledMonths: _ctx.historyEnabledMonths,
              enabledYears: _ctx.historyEnabledYears,
              switchText: _ctx.hasOtherHost ? 'Include other hosts’ games' : undefined,
              switchValue: _ctx.includeOtherHostHistory,
              onSelect: _cache[1] || (_cache[1] = ($event: any) => (
          $event[1] == 0
            ? _ctx.onSelectHistoryGameName($event[0])
            : _ctx.onSelectHistoryGamePeriod($event[0])
        )),
              onSwitch: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onIncludeOtherHostHistory($event)))
            }, null, 8, ["multiFilters", "selectedIndices", "enabledMonths", "enabledYears", "switchText", "switchValue"])
          ])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_HistoryList, {
          key: 1,
          hasHistoryMoreThan6: _ctx.hasHistoryMoreThan6,
          data: _ctx.historyData,
          onOpen: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onOpenHistoryModal($event))),
          onUpgrade: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onUpgrade()))
        }, null, 8, ["hasHistoryMoreThan6", "data"]))
      : _createCommentVNode("", true),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_HistoryListSkeletonLoading, { key: 2 }))
      : _createCommentVNode("", true)
  ], 64))
}