
import { computed, defineComponent, onMounted, ref } from 'vue';
import Flicking from '@egjs/vue3-flicking';
import { getGameTitleByType } from '@/utils/gameConstants';
import { useStore } from '@/store';
import { shareDrawTellImages } from '@/utils/shareResult';
import { firebaseModifiedSource } from '@/utils/helper';

export default defineComponent({
  name: 'HistoryModalDrawTellComponent',
  components: {
    Flicking
  },
  props: {
    game: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const user = computed(() => store.getters.getUser);
    const title = getGameTitleByType(props.game.gameName);
    const customGameName = ref(props.game.customGameName);
    const customGameCss = ref(props.game.customGameCss);
    const dateString = ref(
      props.game.dateCreated.format('DD MMMM, YYYY - hh:mm A')
    );
    const hostName = ref(undefined as string | undefined);
    const isHostNotPlaying = ref(props.game.isHostPlaying === false);
    const topic = ref('');
    const drawings = ref([] as any[]);
    const flicking = ref(null);

    const showPrev = ref(false);
    const showNext = ref(true);

    function calculateResult() {
      if (props.game.host !== user.value.uid) {
        const host = props.game.players.find(
          player => player.playerId === props.game.host
        );
        if (host) {
          hostName.value = host.name;
        }
      }
      props.game.gameDetails.map(detail => {
        drawings.value.push({
          name: detail.playerName,
          url: detail.url
        });
        topic.value = detail.topic.topic;
      });

      showNext.value = drawings.value.length > 2;
    }

    calculateResult();

    async function shareImages() {
      const data = {
        topic: topic.value,
        drawings: drawings.value,
        date:
          dateString.value +
          (hostName.value ? ` - Hosted by ${hostName.value}` : '')
      };
      const url = await shareDrawTellImages(data);
      window.open(url, '_blank');
    }

    async function onPrevCarousel() {
      try {
        await (flicking.value as any).prev();
      } catch (e) {
        e;
      }
    }

    async function onNextCarousel() {
      try {
        await (flicking.value as any).next();
      } catch (e) {
        e;
      }
    }

    function onChangedCarousel(event: any) {
      showPrev.value = event.axesEvent.pos.flick > 0;
      showNext.value =
        event.axesEvent.pos.flick <
        event.axesEvent.currentTarget.axis.flick.range[1];
    }

    onMounted(() => {
      setTimeout(() => {
        (flicking.value as any).init();
      }, 500);
    });

    return {
      title,
      customGameName,
      customGameCss,
      dateString,
      hostName,
      isHostNotPlaying,
      topic,
      drawings,
      firebaseModifiedSource,
      shareImages,
      onPrevCarousel,
      onNextCarousel,
      onChangedCarousel,
      flicking,
      showPrev,
      showNext
    };
  }
});
