
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HowToPlayTrivia',
  props: {
    outClass: {
      type: String,
      default: 'settings'
    }
  }
});
