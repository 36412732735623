
import { defineComponent, onMounted } from 'vue';
import { amplitudeEvent } from '@/config/amplitudeConfig';
// import NumberOfPlayersWouldYouRather from '@/components/Modal/AboutComponent/NumberOfPlayersComponents/NumberOfPlayersWouldYouRather.vue';
import HowToPlayWouldYouRather from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayWouldYouRather.vue';
import SampleQuestionWouldYouRather from '@/components/Modal/AboutComponent/SampleQuestionComponents/SampleQuestionWouldYouRather.vue';

export default defineComponent({
  name: 'AboutWouldYouRather',
  components: {
    // NumberOfPlayersWouldYouRather,
    HowToPlayWouldYouRather,
    SampleQuestionWouldYouRather
  },
  setup() {
    onMounted(() => {
      amplitudeEvent('previewGame', {
        game: 'would_you_rather',
        version: 'regular',
        eventTime: new Date(Date.now()).toISOString()
      });
    });
  }
});
