
import { amplitudeEvent } from '@/config/amplitudeConfig';
import { defineComponent, ref, onMounted } from 'vue';

export default defineComponent({
  name: 'VerifyEmailModal',
  props: {
    email: {
      type: String,
      required: true
    }
  },
  emits: ['close', 'change-email', 'proceed'],
  setup(_, context) {
    const buttonLoading = ref(false);

    const close = function() {
      context.emit('close');
    };

    const changeEmail = function() {
      context.emit('change-email');
    };

    const proceed = function() {
      buttonLoading.value = true;
      context.emit('proceed');
    };

    onMounted(() => {
      amplitudeEvent('visitBillingInfoEmailCheck', {
        eventTime: new Date(Date.now()).toISOString()
      });
    });

    return {
      buttonLoading,
      close,
      changeEmail,
      proceed
    };
  }
});
