
import { defineComponent, onMounted, ref } from 'vue';
import { amplitudeEvent } from '@/config/amplitudeConfig';
// import NumberOfPlayersGinRummy from '@/components/Modal/AboutComponent/NumberOfPlayersComponents/NumberOfPlayersGinRummy.vue';
import HowToPlayGinRummy from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayGinRummy.vue';
import CollapseTransition from '@/components/CollapseTransition.vue';

export default defineComponent({
  name: 'AboutGinRummy',
  components: {
    // NumberOfPlayersGinRummy,
    HowToPlayGinRummy,
    CollapseTransition
  },
  setup() {
    const showTabs = ref([true, false, false, false]);

    onMounted(() => {
      amplitudeEvent('previewGame', {
        game: 'gin_rummy_game',
        version: 'regular',
        eventTime: new Date(Date.now()).toISOString()
      });
    });

    return {
      showTabs
    };
  }
});
