
import { computed, defineComponent, onMounted, ref } from 'vue';
import Flicking from '@egjs/vue3-flicking';
import { getGameTitleByType } from '@/utils/gameConstants';
import { shareDrawItImages, shareGameResult } from '@/utils/shareResult';
import { useStore } from '@/store';
import { firebaseModifiedSource, roundToOneDecimal } from '@/utils/helper';

export default defineComponent({
  name: 'HistoryModalDrawItComponent',
  components: {
    Flicking
  },
  props: {
    game: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const user = computed(() => store.getters.getUser);
    const title = getGameTitleByType(props.game.gameName);
    const customGameName = ref(props.game.customGameName);
    const customGameCss = ref(props.game.customGameCss);
    const dateString = ref(
      props.game.dateCreated.format('DD MMMM, YYYY - hh:mm A')
    );
    const hostName = ref(undefined as string | undefined);
    const isHostNotPlaying = ref(props.game.isHostPlaying === false);
    const scores = ref([] as any[]);
    const drawings = ref([] as any[]);
    const flicking = ref(null);

    const showPrev = ref(false);
    const showNext = ref(true);

    function calculateResult() {
      if (props.game.host !== user.value.uid) {
        const host = props.game.players.find(
          player => player.playerId === props.game.host
        );
        if (host) {
          hostName.value = host.name;
        }
      }

      const actualPlayers = Object.assign(
        [],
        isHostNotPlaying.value
          ? props.game.players.filter(
              player => player.playerId !== props.game.host
            )
          : props.game.players
      );
      props.game.gameDetails.map(detail => {
        if (detail.word) {
          drawings.value.push({
            name: detail.actorPlayerName,
            playerId: detail.actorPlayerId,
            word: detail.word.word,
            url: detail.url,
            votes: 0
          });
        }
      });
      actualPlayers.map(player => {
        let score = 0;
        if (player.roundDetails) {
          player.roundDetails.map(detail => {
            score += detail.score;
          });
        }
        scores.value.push({
          name: player.name,
          email: player.email,
          playerId: player.playerId,
          score: score
        });
      });
      actualPlayers.map(player => {
        if (player.votePlayer) {
          const votePlayer = scores.value.find(
            p => p.playerId === player.votePlayer
          );
          if (votePlayer) {
            votePlayer.score += 1;
          }
          const drawer = drawings.value.find(
            p => p.playerId === player.votePlayer
          );
          if (drawer) {
            drawer.votes += 1;
          }
        }
      });
      scores.value.map(player => {
        player.score = roundToOneDecimal(player.score);
      });
      scores.value.sort((a, b) => b.score - a.score);

      drawings.value = drawings.value.filter(drawing => drawing.url);

      showNext.value = drawings.value.length > 2;
    }

    calculateResult();

    async function shareImages() {
      const data = {
        drawings: drawings.value,
        date:
          dateString.value +
          (hostName.value ? ` - Hosted by ${hostName.value}` : '')
      };
      const url = await shareDrawItImages(data);
      window.open(url, '_blank');
    }

    async function shareResult() {
      const data = {
        gameName: props.game.gameName,
        date:
          dateString.value +
          (hostName.value ? ` - Hosted by ${hostName.value}` : ''),
        scores: scores.value
      };
      const url = await shareGameResult(data);
      window.open(url, '_blank');
    }

    async function onPrevCarousel() {
      try {
        await (flicking.value as any).prev();
      } catch (e) {
        e;
      }
    }

    async function onNextCarousel() {
      try {
        await (flicking.value as any).next();
      } catch (e) {
        e;
      }
    }

    function onChangedCarousel(event: any) {
      showPrev.value = event.axesEvent.pos.flick > 0;
      showNext.value =
        event.axesEvent.pos.flick <
        event.axesEvent.currentTarget.axis.flick.range[1];
    }

    onMounted(() => {
      setTimeout(() => {
        (flicking.value as any).init();
      }, 500);
    });

    return {
      title,
      customGameName,
      customGameCss,
      dateString,
      hostName,
      isHostNotPlaying,
      drawings,
      scores,
      firebaseModifiedSource,
      shareImages,
      shareResult,
      onPrevCarousel,
      onNextCarousel,
      onChangedCarousel,
      flicking,
      showPrev,
      showNext
    };
  }
});
