
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'EditTeamNameModal',
  emits: ['close'],
  props: {
    name: {
      type: String,
      required: true
    }
  },
  setup(props, context) {
    const newName = ref(props.name);

    const close = function(value: String | undefined) {
      context.emit('close', value);
    };

    return {
      newName,
      close
    };
  }
});
