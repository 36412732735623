
import { getGameCssByType, getGameTitleByType } from '@/utils/gameConstants';
import { computed, defineComponent, ref } from 'vue';
import { useStore } from '@/store';
import { getGameWinners } from '@/utils/helper';
import useFirebaseDocument from '@/hooks/firebase/useFirebaseDocument';
export default defineComponent({
  name: 'HistoryCardGameTile',
  props: {
    game: {
      type: Object,
      required: true
    }
  },
  emits: ['open'],
  setup(props, context) {
    const store = useStore();
    const user = computed(() => store.getters.getUser);
    const gameName = ref(props.game.gameName);
    const title = ref(getGameTitleByType(gameName.value));
    const customGameName = ref(undefined as string | undefined);
    const customGameCss = ref(undefined as string | undefined);
    const dateString = ref(props.game.dateCreated.format('MMM DD'));
    const winners = ref([] as string[]);
    const hostName = ref(undefined as string | undefined);
    const game = ref(Object.assign({}, props.game));
    const isFinished = computed(() => props.game.isFinished);

    const actualPlayers = Object.assign({}, props.game.players);
    if (props.game.isHostPlaying === false) {
      delete actualPlayers[props.game.host];
    }
    const playersCount = ref(Object.keys(actualPlayers ?? {}).length);

    function calculateGameWinners() {
      if (user.value && props.game.host !== user.value.uid) {
        hostName.value =
          props.game.players[props.game.host]?.name ?? props.game.hostName;
      }
      if (isFinished.value) {
        const result = getGameWinners(props.game);
        winners.value = result
          .filter(player => player.win)
          .map(player => player.name);
      }
    }

    async function getCustomGameInfo() {
      if (props.game.isCustomGame && props.game.customGameId) {
        const customGameDocumentProps = useFirebaseDocument(
          'minigame_custom_game_public',
          {
            onMounted: false
          }
        );
        await customGameDocumentProps.getDocument(10, props.game.customGameId);
        if (customGameDocumentProps.documentData.value) {
          customGameName.value =
            customGameDocumentProps.documentData.value.customGameName;
          customGameCss.value = getGameCssByType(
            customGameDocumentProps.documentData.value.gameName
          );
          game.value['customGameName'] = customGameName.value;
          game.value['customGameCss'] = customGameCss.value;
        }
      }
    }

    calculateGameWinners();
    getCustomGameInfo();

    function onOpenModal() {
      if (isFinished.value) {
        context.emit('open', game.value);
      }
    }

    return {
      isFinished,
      gameName,
      title,
      customGameName,
      customGameCss,
      dateString,
      playersCount,
      winners,
      hostName,
      onOpenModal
    };
  }
});
