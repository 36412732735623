
import { defineComponent } from 'vue';
import CopyGameLinkBox from '@/components/CopyGameLinkBox.vue';

export default defineComponent({
  name: 'ShareButtonModal',
  components: {
    CopyGameLinkBox
  },
  emits: ['close'],
  props: {
    whiteboardId: {
      type: String,
      required: true
    }
  },
  setup(_, context) {
    const close = function() {
      context.emit('close');
    };

    return {
      close
    };
  }
});
