
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'OkModal',
  emits: ['close'],
  props: {
    description: {
      type: String,
      required: true
    },
    useHtml: {
      type: Boolean,
      required: false
    }
  },
  setup(_, context) {
    const close = function() {
      context.emit('close');
    };

    return {
      close
    };
  }
});
