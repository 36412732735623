
import { defineComponent, onBeforeMount, ref } from 'vue';
import router from '@/router';
import usePostAPI from '@/hooks/usePostAPI';
import {
  getCookie,
  getEndpoint,
  handlePlaygroundSignIn,
  handleZoomOnSignedIn,
  handleZoomOnSignedOut,
  isDev,
  isPlayground,
  isProd,
  redirectToLogin,
  redirectToPlay
} from '@/utils/helper';
import firebase from 'firebase';
import { store } from '@/store';
import { UPDATE_USER } from '@/store/mutation-types';
import { initAmplitude } from '@/config/amplitudeConfig';
import BounceLoading from '@/components/BounceLoading.vue';
import { slackBotScopes } from '@/utils/slack_helper';
export default defineComponent({
  name: 'SlackAuth',
  components: { BounceLoading },
  setup() {
    const playgroundDev: 'osho' | 'dag' | 'yugene' = 'yugene';
    const isLoading = ref(true);
    const showInitialLoading = ref(true);

    onBeforeMount(async () => {
      if (isPlayground) {
        handlePlaygroundSignIn(playgroundDev);
      }
    });

    async function handleSignedInUser(
      user: firebase.User | null,
      needRefreshToken: boolean
    ) {
      if (!user) {
        await firebase.auth().signInAnonymously();
        getAccessToken();
      } else {
        if (store.state.user === undefined) {
          store.commit(UPDATE_USER, {
            newUser: user
          });
        }
        needRefreshToken;
        await handleZoomOnSignedIn(store.state.zoomClientStatus);
        showInitialLoading.value = false;
        getAccessToken();
        // log after confirming user is logged in
        initAmplitude();
      }
    }

    async function handleSignedOutUser() {
      if (store.state.zoomClientStatus?.inZoomClient) {
        await handleZoomOnSignedOut(store.state.zoomClientStatus);
      } else {
        if (isPlayground) {
          showInitialLoading.value = false;
          getAccessToken();
        } else if (isDev || isProd) {
          if (!showInitialLoading.value) {
            redirectToPlay('logout');
          } else {
            redirectToLogin('source', 'slackAuth');
          }
        }
      }
    }

    firebase.auth().onAuthStateChanged(async _user => {
      if (_user === null || _user.isAnonymous) {
        const state = router.currentRoute.value.query.state as string;
        if (state) {
          handleSignedInUser(null, false);
        } else {
          handleSignedOutUser();
        }
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const selectedAction = urlParams.get('selected');
        handleSignedInUser(_user, selectedAction == 'subscribed');
      }
    });

    const parseCode = function() {
      const code = router.currentRoute.value.query.code as string;
      if (code == null || code == undefined || code == '') {
        // cannot authenticate
        return null;
      }
      return code;
    };

    const getAccessToken = async function() {
      isLoading.value = true;
      try {
        let code = parseCode();
        if (code) {
          const state = router.currentRoute.value.query.state as string;
          if (state.startsWith('install')) {
            const cookieState = getCookie('slack-oauth-state');
            if (cookieState !== state) {
              redirectToSlackApp();
              return;
            }
          }

          const response = await usePostAPI(
            getEndpoint('slackLoginAuth', '/api/slackapp/'),
            {
              code: code,
              state: !state || state === '' ? '' : state,
              isLogin: false,
              scopes: slackBotScopes
            }
          );
          if (response.status == 200) {
            // access token stored in firebase
            console.log('Authenticated!');
          }
        }
      } catch (err) {
        console.log(err);
      }

      isLoading.value = false;
      redirectToSlackApp();
    };

    const redirectToSlackApp = function() {
      console.log('Redirecting to slack app');
      // return;
      window.location.href = window.location.origin + '?tab=slack';
    };

    return { isLoading };
  }
});
