
import { copyToClipboard, isProd } from '@/utils/helper';
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'TeamMemberInviteBox',
  emits: ['enable-link', 'reset-link'],
  props: {
    teamInfo: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const copied = ref(false);
    const link = ref(
      isProd
        ? `https://app.brightful.me/dashboard/?team=${props.teamInfo.link.url}`
        : `https://dev.orric.app/dashboard/?team=${props.teamInfo.link.url}`
    );

    watch(props, newValue => {
      link.value = isProd
        ? `https://app.brightful.me/dashboard/?team=${newValue.teamInfo.link.url}`
        : `https://dev.orric.app/dashboard/?team=${newValue.teamInfo.link.url}`;
    });

    const enableLink = function() {
      context.emit('enable-link', true);
    };
    const disableLink = function() {
      context.emit('enable-link', false);
    };

    const copyLink = function() {
      copyToClipboard(
        `Join "${props.teamInfo.name}" in Brightful to host games and use different team-building features:\n${link.value}`
      );
      copied.value = true;
      setTimeout(() => {
        copied.value = false;
      }, 3000);
    };

    const resetLink = function() {
      context.emit('reset-link');
    };
    return {
      enableLink,
      disableLink,
      copied,
      copyLink,
      resetLink,
      link
    };
  }
});
