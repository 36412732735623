
import { defineComponent } from 'vue';
import BrightfulHeader from '@/components/General/BrightfulHeader.vue';
export default defineComponent({
  name: 'ZoomNoActivity',
  props: {
    host: {
      type: String,
      required: true,
      default: 'host'
    }
  },
  components: {
    BrightfulHeader
  }
});
