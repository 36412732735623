
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'HistoryLockTile',
  props: {},
  emits: ['upgrade'],
  setup(_, context) {
    function onUpgrade() {
      context.emit('upgrade');
    }

    return {
      onUpgrade
    };
  }
});
