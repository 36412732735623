
import { store } from '@/store';
import firebase from 'firebase';
import { computed, defineComponent, Ref, ref } from 'vue';
import {
  eventToolsHistoryDateFormat,
  qnaDateFormat,
  hasActiveGame,
  onGameStart
} from '@/utils/helper';
import { Qna } from '@/models/event_tools/qna/qna';
import { canAccessEventTools } from '@/utils/pricing_rules';
import { LaunchOptions } from '@/models/GameStartOptions';
import { ActivityType, GameName } from '@/utils/gamenames_enums';
import router from '@/router';
import QnaActiveDetailModal from './QnaActiveDetailModal.vue';
import QnaDetailModal from './QnaDetailModal.vue';
import BounceLoading from '@/components/BounceLoading.vue';
export default defineComponent({
  name: 'QnaModal',
  props: {},
  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'launch-modal': (options: LaunchOptions) => true,
    close: () => true
  },
  setup(props, context) {
    const userDoc = computed(() => store.getters.getUserDoc);
    const lobbyDoc = computed(() => store.getters.getLobbyDoc);
    const isQnaLoading: Ref<boolean> = ref(false);
    const subscriptionDetails = computed(
      () => store.getters.getSubscriptionDetails
    );
    const state: Ref<['list', boolean] | ['detail', Qna] | null> = ref(null);
    const qnaList: Ref<Array<Qna>> = ref([]);
    const currentLobbyQna: Ref<Qna | null> = ref(null);
    async function fetcQna() {
      try {
        const snapshots = await firebase
          .firestore()
          .collection(`minigame_lobbies/${userDoc.value['defaultLobbyId']}/qna`)
          .orderBy('dateCreated', 'desc')
          .get();
        if (!snapshots.empty && snapshots.docs.length != 0) {
          qnaList.value = snapshots.docs.map(doc => doc.data() as Qna);
          qnaList.value = qnaList.value.filter(
            qna => qna.activeQuestions || qna.archivedQuestions
          );
        }
        const currentSessionIndex = qnaList.value.findIndex(
          qna => qna.sessionId == lobbyDoc.value?.sessionId
        );
        if (currentSessionIndex != -1) {
          currentLobbyQna.value = qnaList.value[currentSessionIndex];
          state.value = ['list', true];
        } else {
          state.value = ['list', false];
        }
      } catch (err) {
        console.log(err);
        state.value = ['list', false];
      }
    }
    fetcQna();
    function onClose() {
      context.emit('close');
    }
    async function onStartQna() {
      if (canAccessEventTools(subscriptionDetails.value)) {
        const launchOptions: LaunchOptions = {
          activityType: ActivityType.QNA,
          chosenGame: GameName.QNA,
          createGameSource: 'dashboard'
        };
        if (!hasActiveGame()) {
          isQnaLoading.value = true;
          await onGameStart({
            ...launchOptions,
            subscriptionLevel: subscriptionDetails.value.subscriptionLevel,
            zoomClientConfig: store.state.zoomClientStatus,
            startFresh: false
          });
          isQnaLoading.value = false;
        } else {
          context.emit('launch-modal', launchOptions);
        }
      } else {
        goToPricing();
      }
    }
    function onView(qna: Qna) {
      state.value = ['detail', qna];
    }
    function onBack() {
      state.value = [
        'list',
        currentLobbyQna.value?.sessionId != null &&
          currentLobbyQna.value?.sessionId != undefined
      ];
    }
    function goToPricing() {
      const subscriptionEnded =
        subscriptionDetails.value.subscriptionEnded ?? false;
      router.push({
        name: 'Pricing',
        query: {
          source: subscriptionEnded ? 'subscription-ended' : 'free-trial-ended'
        }
      });
    }
    return {
      state,
      qnaList,
      currentLobbyQna,
      isQnaLoading,
      qnaDateFormat,
      onBack,
      onStartQna,
      onClose,
      onView,
      eventToolsHistoryDateFormat
    };
  },
  components: { QnaActiveDetailModal, QnaDetailModal, BounceLoading }
});
