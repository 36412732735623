
import useFirebaseAuth from '@/hooks/firebase/useFirebaseAuth';
import useFirebaseCollection from '@/hooks/firebase/useFirebaseCollection';
import ChatModel from '@/utils/whiteboardChatModel';
import { computed, defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'QAChannelItem',
  props: {
    channel: {
      type: Object,
      required: true
    },

    participantsData: {
      type: Array,
      required: true
    }
  },
  emits: ['channelClicked'],
  setup(props, context) {
    const { currentUser } = useFirebaseAuth();
    const messagesCollection = useFirebaseCollection(
      `whiteboard_channel/${props.channel.id}/messages`,
      {
        onMounted: true,
        orderBy: 'dateCreated',
        isDesc: true
      },
      true
    );
    const membersCollection = useFirebaseCollection(
      `whiteboard_channel/${props.channel.id}/members`,
      {
        onMounted: true
      },
      true
    );
    watch(messagesCollection.collectionData, messages => {
      if (messages) {
        currentMessages.value = messages;
      }
    });
    watch(membersCollection.collectionData, members => {
      if (members) {
        currentMembers.value = members;
      }
    });
    const myUnreadCount = computed(() => {
      if (isLoading.value) return 0;
      let members: Array<any> = currentMembers.value;
      let me = members.find(member => {
        return member.uid == currentUser?.value?.uid;
      });

      let unreadCount: number = 0;
      let lastIndex = me?.lastConsumedMessageIndex;
      if (lastIndex == null || lastIndex == undefined)
        lastIndex = currentMessages.value.length;
      for (let i = 0; i < currentMessages.value.length - lastIndex; i++) {
        if (currentMessages.value[i].from != currentUser?.value?.uid) {
          unreadCount++;
        }
      }
      let participant: any = props.participantsData.find((participant: any) => {
        return participant.id == currentUser?.value?.uid;
      });
      if (
        !participant.unreadCounts ||
        participant.unreadCounts[props.channel.id] != unreadCount
      ) {
        if (!participant.unreadCounts) {
          participant.unreadCounts = {};
        }
        participant.unreadCounts[props.channel.id] = unreadCount;
        ChatModel.setUnreadCounts(
          currentUser?.value?.uid!,
          props.channel.whiteboardId,
          participant.unreadCounts
        );
      }
      return unreadCount;
    });
    const isLoading = computed(() => {
      return (
        !currentMessages.value ||
        currentMessages.value.length == 0 ||
        !currentMembers.value ||
        currentMembers.value.length == 0
      );
    });
    const hasNewMessage = computed(() => {
      return myUnreadCount.value != 0;
    });
    const currentMessages = ref([] as any[]);
    const currentMembers = ref([] as any[]);
    const firstMessage = computed(() => {
      if (currentMessages.value.length == 0) return {};
      let first: any = currentMessages.value[0];
      let firstMessageId: string = first?.from;
      let firstMember = props.participantsData.find((participant: any) => {
        return participant.id == firstMessageId;
      });
      first.member = firstMember;
      return first;
    });
    const otherName = computed(() => {
      let channelId: string = props.channel.id;
      // channel id = 'CH${whiteboardId}${uid}'
      let otherUid = channelId.replace(`CH${props.channel.whiteboardId}`, '');
      let otherMember: any = props.participantsData.find((participant: any) => {
        return participant.id == otherUid;
      });
      return otherMember?.name;
    });
    const onChannel = function() {
      context.emit('channelClicked');
    };

    return {
      hasNewMessage,
      currentMessages,
      currentMembers,
      isLoading,
      firstMessage,
      onChannel,
      otherName
    };
  }
});
