
import { defineComponent } from 'vue';
import firebaseApp from '@/firebaseApp';

export default defineComponent({
  name: 'ConfirmDeleteWhiteboardModal',
  emits: ['close'],
  props: {
    whiteboardId: {
      type: String,
      required: true
    }
  },
  setup(props, context) {
    const close = function() {
      context.emit('close');
    };

    const deleteWhiteboard = function() {
      firebaseApp
        .firestore()
        .collection('whiteboard_data')
        .doc(props.whiteboardId)
        .delete();
      context.emit('close');
    };

    return {
      close,
      deleteWhiteboard
    };
  }
});
