
import {
  cardGameTypes,
  scoreGameTypes,
  noResultGameTypes
} from '@/utils/gameConstants';
import { defineComponent, ref } from 'vue';
import HistoryScoreGameTile from '@/components/HistoryLeaderboard/HistoryTiles/HistoryScoreGameTile.vue';
import HistoryNoResultGameTile from '@/components/HistoryLeaderboard/HistoryTiles/HistoryNoResultGameTile.vue';
import HistoryWerewolfTile from '@/components/HistoryLeaderboard/HistoryTiles/HistoryWerewolfTile.vue';
import HistoryOneToHundredTile from '@/components/HistoryLeaderboard/HistoryTiles/HistoryOneToHundredTile.vue';
import HistoryTwoWordsTile from '@/components/HistoryLeaderboard/HistoryTiles/HistoryTwoWordsTile.vue';
import HistorySpotMyLieTile from '@/components/HistoryLeaderboard/HistoryTiles/HistorySpotMyLieTile.vue';
import HistoryCardGameTile from '@/components/HistoryLeaderboard/HistoryTiles/HistoryCardGameTile.vue';
import HistoryTop5Tile from '@/components/HistoryLeaderboard/HistoryTiles/HistoryTop5Tile.vue';
import HistoryLivePollTile from './HistoryTiles/HistoryLivePollTile.vue';
import { isLivePoll } from '@/utils/helper';
import { GameName } from '@/utils/gamenames_enums';
import HistoryLiveQnaTile from './HistoryTiles/HistoryLiveQnaTile.vue';
import HistorySpinTheWheelTile from './HistoryTiles/HistorySpinTheWheelTile.vue';
export default defineComponent({
  name: 'HistoryTile',
  components: {
    HistoryScoreGameTile,
    HistoryNoResultGameTile,
    HistoryWerewolfTile,
    HistoryOneToHundredTile,
    HistoryTwoWordsTile,
    HistorySpotMyLieTile,
    HistoryCardGameTile,
    HistoryTop5Tile,
    HistoryLivePollTile,
    HistoryLiveQnaTile,
    HistorySpinTheWheelTile
  },
  props: {
    game: {
      type: Object,
      required: true
    }
  },
  emits: ['open'],
  setup(props, context) {
    const gameName = ref(props.game.gameName);

    function onOpenModal(game: any) {
      context.emit('open', game);
    }

    return {
      gameName,
      cardGameTypes,
      scoreGameTypes,
      noResultGameTypes,
      isLivePoll,
      onOpenModal
    };
  }
});
