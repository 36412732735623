
import { defineComponent, ref } from 'vue';
import { getGameTitleByType } from '@/utils/gameConstants';
import Popover from '@/components/Popover.vue';

export default defineComponent({
  name: 'CreateCustomizedGameModal',
  components: {
    Popover
  },
  props: {
    type: {
      type: String,
      required: true
    },
    fromListModal: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const show = ref(true);
    const customGameName = ref('');
    const description = ref('');
    const showPopover = ref(false);

    const maxCustomGameNameLength = 30;
    const maxDescriptionLength = 280;

    const close = function() {
      show.value = false;
      setTimeout(() => {
        context.emit('close');
      }, 50);
    };

    const onCreateGame = function() {
      if (customGameName.value) {
        show.value = false;
        setTimeout(() => {
          context.emit('create', {
            name: customGameName.value.trim(),
            description: description.value.trim()
          });
        }, 50);
      }
    };

    return {
      getGameTitleByType,
      show,
      customGameName,
      description,
      showPopover,
      maxCustomGameNameLength,
      maxDescriptionLength,
      close,
      onCreateGame
    };
  }
});
