import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import RootPage from '../pages/RootPage.vue';
import RedirectAuthandGameRoom from '../pages/RedirectAuthandGameRoom.vue';
import SlackAuth from '../pages/SlackAuth.vue';
// import DashboardEntry from '../pages/DashboardEntry.vue';
// import PricingEntry from '../pages/PricingEntry.vue';
// import FeedbackEntry from '../pages/FeedbackEntry.vue';
// import TeamEntry from '../pages/TeamEntry.vue';
// import Whiteboard from '../pages/Whiteboard.vue';
// import CreateCustomGame from '../pages/CreateCustomGame.vue';
// import EditCustomGame from '../pages/EditCustomGame.vue';
// import Feedback from '../pages/Feedback.vue';
import { gameTypes, getGameScreenshotsByType } from '@/utils/gameConstants';
// import firebase from 'firebase/app';
import { RoutePath } from '@/models/RoutePath';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: RootPage,
    beforeEnter(to, from, next) {
      loadImages();
      next();
    },
    props: { routePath: RoutePath.Dashboard }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: RootPage,
    props: { routePath: RoutePath.Dashboard }
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: RootPage,
    props: { routePath: RoutePath.Pricing }
  },
  {
    path: '/live/:room',
    name: 'WhiteboardRoom',
    component: RootPage,
    props: { routePath: RoutePath.WhiteboardRoom }
  },
  {
    path: '/create',
    name: 'CreateCustomGame',
    component: RootPage,
    props: { routePath: RoutePath.CreateCustomGame }
  },
  {
    path: '/edit',
    name: 'EditCustomGame',
    component: RootPage,
    props: { routePath: RoutePath.EditCustomGame }
  },
  {
    path: '/game',
    component: RedirectAuthandGameRoom
  },
  {
    path: '/slackAuth',
    component: SlackAuth
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: RootPage,
    props: { routePath: RoutePath.Feedback }
  }
];

function loadImages() {
  gameTypes.map(type => {
    const info = getGameScreenshotsByType(type);
    if (info) {
      for (let i = 0; i < info[1]; i++) {
        const img = new Image();
        img.src = require(`@/assets/img/games-screen/v2/${info[0]}-${i +
          1}.png`);
      }
    }
  });
}

const router = createRouter({
  history: createWebHistory(),
  routes: routes
});

export default router;
