import { createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-group has-button-inside" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("input", {
      class: "form-control form-control-lg",
      type: "text",
      value: _ctx.linkToCopy,
      readonly: ""
    }, null, 8, ["value"]),
    _createVNode("button", {
      type: "button",
      class: ["btn btn-md btn-transparent fixed-right orange", {
        green: _ctx.copyGameLinkButtonText == 'COPIED',
        orange: _ctx.copyGameLinkButtonText == 'COPY'
      }],
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onCopyGameLink()))
    }, _toDisplayString(_ctx.copyGameLinkButtonText), 3)
  ]))
}