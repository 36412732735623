export class OneToHundredTopic {
  public topic: string = '';
  public left: string = '';
  public right: string = '';
  public index: number = -1;
  public key: number = -1;

  public constructor(fields?: {
    topic?: string;
    left?: string;
    right?: string;
    index?: number;
    key?: number;
  }) {
    if (fields) Object.assign(this, fields);
  }
}
