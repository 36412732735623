
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'MobileScreenUnsupportedModal',
  setup(_, context) {
    const show = ref(true);

    const close = function() {
      show.value = false;
      setTimeout(() => {
        context.emit('close');
      }, 50);
    };

    return {
      show,
      close
    };
  }
});
