
import { computed, defineComponent, onMounted, ref } from 'vue';
import firebase from '@/firebaseApp';
import * as EmailValidator from 'email-validator';
import { amplitudeEvent } from '@/config/amplitudeConfig';

export default defineComponent({
  name: 'ChangeEmailModal',
  emits: ['close', 'proceed'],
  setup(_, context) {
    const buttonLoading = ref(false);
    const errorMessage = ref('');
    const newEmail = ref('');
    const confirmEmail = ref('');
    const currentPassword = ref('');
    const currentPasswordEye = ref(false);

    const close = function() {
      context.emit('close');
    };

    const showCurrentPasswordEye = computed(() => {
      return currentPassword.value.trim().length > 0;
    });

    function validEmailUpdate() {
      return (
        newEmail.value.trim() &&
        confirmEmail.value.trim() &&
        showCurrentPasswordEye.value
      );
    }

    async function onUpdateEmail() {
      buttonLoading.value = true;
      const newEmailTrimmed = newEmail.value.trim();
      const confirmEmailTrimmed = confirmEmail.value.trim();
      const password = currentPassword.value.trim();
      if (newEmailTrimmed != confirmEmailTrimmed) {
        errorMessage.value = 'The emails you have entered do not match.';
        buttonLoading.value = false;
        return;
      } else if (!EmailValidator.validate(confirmEmailTrimmed)) {
        errorMessage.value = 'Not a valid email. Please check and try again.';
        buttonLoading.value = false;
        return;
      } else {
        errorMessage.value = '';
        const user = firebase.auth().currentUser;
        if (user === null || user.email === null) {
          buttonLoading.value = false;
          throw new Error('Authenticated Failed');
        }
        try {
          const credential = firebase.auth.EmailAuthProvider.credential(
            user.email,
            password
          );
          await user.reauthenticateWithCredential(credential);
        } catch (err) {
          if ((err as any).code === 'auth/wrong-password') {
            amplitudeEvent('visitBillingInfoEmailError', {
              error: 'wrong_password',
              eventTime: new Date(Date.now()).toISOString()
            });
            errorMessage.value =
              'Incorrect password. Please check and try again.';
          } else {
            amplitudeEvent('visitBillingInfoEmailError', {
              error: err,
              eventTime: new Date(Date.now()).toISOString()
            });
            errorMessage.value = (err as any).message;
          }
          buttonLoading.value = false;
          return;
        }
        try {
          await user.updateEmail(confirmEmailTrimmed);
          context.emit('proceed');
        } catch (err) {
          if ((err as any).code === 'auth/email-already-in-use') {
            amplitudeEvent('visitBillingInfoEmailError', {
              error: 'email_already_in_use',
              eventTime: new Date(Date.now()).toISOString()
            });
            errorMessage.value = 'This email is already registered.';
          } else {
            amplitudeEvent('visitBillingInfoEmailError', {
              error: err,
              eventTime: new Date(Date.now()).toISOString()
            });
            errorMessage.value =
              'An unexpected error has occured. Please try again later or contact support@brightful.me for more info.';
          }
          buttonLoading.value = false;
          return;
        }
      }
    }

    onMounted(() => {
      amplitudeEvent('visitBillingInfoEmailChange', {
        eventTime: new Date(Date.now()).toISOString()
      });
    });

    return {
      buttonLoading,
      errorMessage,
      newEmail,
      confirmEmail,
      currentPassword,
      currentPasswordEye,
      showCurrentPasswordEye,
      validEmailUpdate,
      close,
      onUpdateEmail
    };
  }
});
