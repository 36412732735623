export class PollQuestion {
  public question: string = '';
  public choices: string[] = [];
  public index: number = -1;
  public key: number = -1;

  public constructor(fields?: {
    question?: string;
    choices?: string[];
    index?: number;
    key?: number;
  }) {
    if (fields) Object.assign(this, fields);
  }
}
