
import { defineComponent, onBeforeMount, ref, watch } from 'vue';
import { TriviaQuestion } from '@/models/TriviaQuestion';
import VueImageCropUpload from '@/components/VueImageCropUpload/upload-3.vue';
import { blobToBase64, firebaseModifiedSource } from '@/utils/helper';

export default defineComponent({
  name: 'GeneralTriviaInputItem',
  components: {
    VueImageCropUpload
  },
  props: {
    id: {
      type: String,
      required: true
    },
    number: {
      type: Number,
      required: true
    },
    value: TriviaQuestion || Object,
    checked: Boolean,
    isPendingImagesMoreThan30: Boolean
  },
  emits: ['valuechanged'],
  setup(props, context) {
    const checkedValue = ref(false);
    const question = ref('');
    const correct = ref('');
    const incorrect1 = ref('');
    const incorrect2 = ref('');
    const incorrect3 = ref('');

    const maxTriviaInputLength = 140;

    const isDropZoneActive = ref(false);

    const showImageCrop = ref(false);
    const image = ref(null);
    const imageData = ref(undefined);

    const droppedFile = ref(undefined);

    var imageChanged = false;

    const cropLangExt = {
      btn: {
        off: 'CANCEL',
        close: 'CANCEL',
        back: 'CANCEL',
        save: 'UPLOAD'
      },
      error: {
        onlyImg: 'File format not supported. Please use .jpg or .png files',
        outOfSize: 'Image exceeds size limit: ',
        lowestPx: "Image's size is too low. Expected at least: "
      }
    };

    const cropSuccess = imageDataUrl => {
      imageData.value = imageDataUrl;
      imageChanged = true;
      onValue();
    };

    const deleteImage = () => {
      imageData.value = undefined;
      image.value = null;
      imageChanged = true;
      onValue();
    };

    const preventDefault = e => {
      isDropZoneActive.value = true;
      e.preventDefault();
      return false;
    };

    const onDragLeave = e => {
      isDropZoneActive.value = false;
      e.preventDefault();
      return false;
    };

    const handleDrop = e => {
      isDropZoneActive.value = false;
      e.preventDefault();
      if (props.isPendingImagesMoreThan30) {
        return;
      }
      let files = e.target.files || e.dataTransfer.files;
      droppedFile.value = files[0];
      showImageCrop.value = true;
    };

    const onValue = function() {
      context.emit('valuechanged', {
        checked: checkedValue.value,
        question: question.value,
        correct: correct.value,
        incorrect1: incorrect1.value,
        incorrect2: incorrect2.value,
        incorrect3: incorrect3.value,
        index: props.number,
        image: image.value,
        imageData: imageChanged ? imageData.value : undefined
      });
    };

    watch(showImageCrop, newV => {
      if (!newV) {
        droppedFile.value = undefined;
      }
    });

    onBeforeMount(async () => {
      checkedValue.value = props.checked ?? false;
      question.value = props.value?.question ?? '';
      correct.value = props.value?.correct ?? '';
      incorrect1.value = props.value?.incorrect1 ?? '';
      incorrect2.value = props.value?.incorrect2 ?? '';
      incorrect3.value = props.value?.incorrect3 ?? '';
      image.value = props.value?.image as any;
      if (props.value?.image) {
        try {
          const imageBlog = await (await fetch(props.value?.image)).blob();
          const image = await blobToBase64(imageBlog);
          imageData.value = image as any;
        } catch (e) {
          console.log(e);
        }
      }
    });

    watch(props, newValue => {
      checkedValue.value = newValue.checked ?? false;
      correct.value = newValue.value?.correct ?? '';
      incorrect1.value = newValue.value?.incorrect1 ?? '';
      incorrect2.value = newValue.value?.incorrect2 ?? '';
      incorrect3.value = newValue.value?.incorrect3 ?? '';
    });

    return {
      checkedValue,
      question,
      correct,
      incorrect1,
      incorrect2,
      incorrect3,
      maxTriviaInputLength,
      onValue,
      showImageCrop,
      imageData,
      droppedFile,
      cropLangExt,
      firebaseModifiedSource,
      cropSuccess,
      deleteImage,
      isDropZoneActive,
      preventDefault,
      onDragLeave,
      handleDrop
    };
  }
});
