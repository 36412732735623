
import { defineComponent, ref } from 'vue';
import AboutWouldYouRather from './AboutComponent/AboutWouldYouRather.vue';
import AboutDrawIt from './AboutComponent/AboutDrawIt.vue';
import AboutYesOrNo from './AboutComponent/AboutYesOrNo.vue';
import AboutFunnyOrNot from './AboutComponent/AboutFunnyOrNot.vue';
import AboutSpotMyLie from './AboutComponent/AboutSpotMyLie.vue';
import AboutDescribeIt from './AboutComponent/AboutDescribeIt.vue';
import AboutQOfTheDay from './AboutComponent/AboutQOfTheDay.vue';
import AboutTrivia from './AboutComponent/AboutTrivia.vue';
import AboutHearts from './AboutComponent/AboutHearts.vue';
import AboutGinRummy from './AboutComponent/AboutGinRummy.vue';
import AboutRummy from './AboutComponent/AboutRummy.vue';
import AboutWerewolf from './AboutComponent/AboutWerewolf.vue';
import AboutTwoWords from './AboutComponent/AboutTwoWords.vue';
import AboutDrawTell from './AboutComponent/AboutDrawTell.vue';
import AboutCharades from './AboutComponent/AboutCharades.vue';
import AboutWordsOfPower from './AboutComponent/AboutWordsOfPower.vue';
import AboutPoll from './AboutComponent/AboutPoll.vue';
import AboutTop5 from './AboutComponent/AboutTop5.vue';
import AboutOneToHundred from './AboutComponent/AboutOneToHundred.vue';
import { amplitudeEvent } from '@/config/amplitudeConfig';

export default defineComponent({
  name: 'AboutModal',
  components: {
    AboutWouldYouRather,
    AboutDrawIt,
    AboutYesOrNo,
    AboutFunnyOrNot,
    AboutSpotMyLie,
    AboutDescribeIt,
    AboutQOfTheDay,
    AboutTrivia,
    AboutHearts,
    AboutGinRummy,
    AboutRummy,
    AboutWerewolf,
    AboutTwoWords,
    AboutDrawTell,
    AboutCharades,
    AboutWordsOfPower,
    AboutPoll,
    AboutTop5,
    AboutOneToHundred
  },
  props: {
    gameName: {
      type: String,
      required: true
    }
  },
  emits: ['close'],
  setup(props, context) {
    const show = ref(true);

    amplitudeEvent('readHowToPlay', {
      eventTime: new Date(Date.now()).toISOString(),
      game: props.gameName,
      platform: 'Dashboard'
    });

    const close = function() {
      context.emit('close');
    };

    return {
      show,
      close
    };
  }
});
