
import { amplitudeEvent } from '@/config/amplitudeConfig';
import { MultipleChoiceLivePoll } from '@/models/event_tools/live_poll/live_poll_model';
import { store } from '@/store';
import { LivePollType } from '@/utils/live_poll_enums';
import firebase from 'firebase';
import { computed, defineComponent, Ref, ref } from 'vue';
export default defineComponent({
  name: 'CreateMultipleChoiceModal',

  props: {},
  emits: ['close'],
  setup(_, context) {
    const userDoc = computed(() => store.getters.getUserDoc);
    const isButtonLoading: Ref<boolean> = ref(false);
    const pollData: Ref<MultipleChoiceLivePoll> = ref({
      allowMultipleSelect: false,
      dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
      launchedCount: 0,
      options: [{ text: '' }, { text: '' }],
      question: '',
      type: LivePollType.MULTIPLE_CHOICE
    });

    function onClose() {
      context.emit('close');
    }
    function onAdd() {
      pollData.value.options.push({
        text: ''
      });
    }
    function onDelete(index: number) {
      if (pollData.value.options.length <= 2) return;
      pollData.value.options.splice(index, 1);
    }
    function onToggle(value) {
      pollData.value.allowMultipleSelect = value;
    }
    const validForm = computed(() => {
      if (!pollData.value.question || pollData.value.question?.trim() === '')
        return false;
      const nonEmptyOptions = pollData.value.options.filter(
        option => option.text && option.text.trim() !== ''
      );
      if (!pollData.value.options || nonEmptyOptions.length < 2) return false;
      return true;
    });

    const hasUniqueOptions = computed(() => {
      let multipleChoiceOptionsText = pollData.value.options
        .filter(option => option.text.trim() != '')
        .map(option => option.text);
      return (
        new Set(multipleChoiceOptionsText).size ==
        multipleChoiceOptionsText.length
      );
    });
    async function onSave() {
      if (!validForm.value) {
        console.log('Form incomplete');
        return;
      }
      isButtonLoading.value = true;
      const nonEmptyOptions = pollData.value.options.filter(
        option => option.text && option.text.trim() !== ''
      );
      pollData.value.options = nonEmptyOptions;
      console.log('adding new poll');
      await firebase
        .firestore()
        .collection(
          `minigame_lobbies/${userDoc.value['defaultLobbyId']}/live_poll`
        )
        .add(pollData.value);
      console.log('new poll added');
      amplitudeEvent('savePoll', {
        eventTime: new Date(Date.now()).toISOString(),
        pollType: pollData.value.type
      });
      isButtonLoading.value = false;
      onClose();
    }
    return {
      onClose,
      onSave,
      onAdd,
      onDelete,
      onToggle,
      validForm,
      pollData,
      isButtonLoading,
      hasUniqueOptions
    };
  }
});
