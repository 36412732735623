import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QAMessageList = _resolveComponent("QAMessageList")

  return (_ctx.isLoading)
    ? (_openBlock(), _createBlock("div", _hoisted_1))
    : (_openBlock(), _createBlock(_component_QAMessageList, {
        key: 1,
        isHost: false,
        channel: _ctx.currentChannel,
        participantsData: _ctx.participantsData,
        whiteboardData: _ctx.whiteboardData,
        isModalOpen: _ctx.isModalOpen
      }, null, 8, ["channel", "participantsData", "whiteboardData", "isModalOpen"]))
}