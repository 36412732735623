
import { computed, defineComponent, ref } from 'vue';
import CategoryFilterMobile from '@/components/CategoryFilterMobile.vue';
import HistoryList from '@/components/HistoryLeaderboard/HistoryList.vue';
import HistoryListSkeletonLoading from '@/components/HistoryLeaderboard/HistoryListSkeletonLoading.vue';
import {
  gameTypes,
  getGameTitleByType,
  firestoreGameTypes
} from '@/utils/gameConstants';
import { useStore } from '@/store';
import moment from 'moment';
import firebase from '@/firebaseApp';
import useFirebaseCollection from '@/hooks/firebase/useFirebaseCollection';

import { canAccessFullHistory, isProPlan } from '@/utils/pricing_rules';
import useFirebaseDocument from '@/hooks/firebase/useFirebaseDocument';

export default defineComponent({
  name: 'HistoryMain',
  components: {
    CategoryFilterMobile,
    HistoryList,
    HistoryListSkeletonLoading
  },
  props: {},
  emits: ['open_history_modal', 'open_upgrade_modal', 'upgrade'],
  setup(_, context) {
    const store = useStore();
    const subscriptionDetails = computed(
      () => store.getters.getSubscriptionDetails
    );
    const userDoc = computed(() => store.getters.getUserDoc);
    const canAccessFullHistoryValue = computed(() => {
      return canAccessFullHistory(subscriptionDetails.value, userDoc.value);
    });

    const historyGameNames = gameTypes.map((type: string) =>
      getGameTitleByType(type, true)
    );
    const historyGameNameIndex = ref(-1);

    const gamePeriods = ['Select a month', 'Select a year'];
    const historyGamePeriod = ref(undefined as any);

    const isLoading = ref(false);
    const collectionData = ref([] as any[]);
    const hasHistoryMoreThan6 = ref(false);
    const historyData = ref({});
    const historyEnabledMonths = ref([] as string[]);
    const historyEnabledYears = ref([] as number[]);

    const includeOtherHostHistory = ref(false);

    const teamInfo = computed(() => store.getters.getTeamInfo);
    const hasOtherHost =
      isProPlan(subscriptionDetails.value) &&
      teamInfo.value &&
      teamInfo.value.members.length > 1;

    const currentGameData = computed(() => store.getters.getCurrentGameData);

    async function getRoomGameData(gameRoomId: string) {
      const userGameplayHistoryQuery = useFirebaseCollection(
        `minigame_gameplay_history/${gameRoomId}/game_plays`,
        {
          onMounted: false
        }
      );
      await userGameplayHistoryQuery.getCollection({
        orderBy: 'dateCreated',
        isDesc: true
      });
      let gamePlayHistory = currentGameData.value
        ? [currentGameData.value]
        : [];
      gamePlayHistory = gamePlayHistory.concat(
        userGameplayHistoryQuery.collectionData.value!
      );

      gamePlayHistory.map(data => {
        if (
          data['dateCreated'] &&
          ((firestoreGameTypes.includes(data.gameName) &&
            data['dateCreated'] instanceof firebase.firestore.Timestamp) ||
            (!firestoreGameTypes.includes(data.gameName) &&
              !(data['dateCreated'] instanceof firebase.firestore.Timestamp)))
        ) {
          const date =
            data['dateCreated'] instanceof firebase.firestore.Timestamp
              ? moment(data['dateCreated'].toDate())
              : moment(data['dateCreated']);
          const newData = Object.assign({}, data);
          newData['dateCreated'] = date;
          collectionData.value.push(newData);
        }
      });
      // console.log(collectionData.value);
    }

    async function getGameHistory() {
      isLoading.value = true;

      collectionData.value = [];

      if (includeOtherHostHistory.value) {
        for (let i = 0; i < teamInfo.value.members.length; i++) {
          const userDocument = useFirebaseDocument('minigame_user', {
            onMounted: false
          });
          await userDocument.getDocument(10, teamInfo.value.members[i].uid);
          await getRoomGameData(
            userDocument.documentData.value['defaultGameRoomId']
          );
        }
      } else {
        await getRoomGameData(userDoc.value['defaultGameRoomId']);
      }

      collectionData.value.sort(
        (a, b) => b.dateCreated.unix() - a.dateCreated.unix()
      );

      getHistoryFiltered();

      isLoading.value = false;
    }

    function getHistoryFiltered() {
      historyEnabledMonths.value = [];
      historyEnabledYears.value = [];
      historyData.value = {};

      hasHistoryMoreThan6.value = collectionData.value.length >= 6;
      const planCollectionData = canAccessFullHistoryValue.value
        ? collectionData.value
        : collectionData.value.slice(0, 7);

      if (planCollectionData.length === 2 && !canAccessFullHistoryValue.value) {
        planCollectionData.push(planCollectionData[1]);
      }

      const filteredGameNames =
        historyGameNameIndex.value === -1
          ? gameTypes
          : [gameTypes[historyGameNameIndex.value]];
      const gameFilteredPlanCollectionData = planCollectionData.filter(data =>
        filteredGameNames.includes(data.gameName)
      );
      gameFilteredPlanCollectionData.map(data => {
        const date = data.dateCreated;
        const month = moment(date).format('MMMM YYYY');
        if (!historyEnabledMonths.value.includes(month)) {
          historyEnabledMonths.value.push(month);
        }
        if (!historyEnabledYears.value.includes(date.year())) {
          historyEnabledYears.value.push(date.year());
        }
      });

      const dateFilteredPlanCollectionData = historyGamePeriod.value
        ? gameFilteredPlanCollectionData.filter(data => {
            const monthDate = data.dateCreated.format('MMMM YYYY');
            return monthDate.includes(historyGamePeriod.value);
          })
        : gameFilteredPlanCollectionData;

      dateFilteredPlanCollectionData.map(data => {
        const monthDate = data.dateCreated.format('MMMM YYYY');
        if (historyData.value[monthDate]) {
          historyData.value[monthDate].push(data);
        } else {
          historyData.value[monthDate] = [data];
        }
      });
    }

    getGameHistory();

    function onSelectHistoryGameName(index: number) {
      historyGameNameIndex.value = index;
      historyGamePeriod.value = undefined;
      getHistoryFiltered();
    }

    function onSelectHistoryGamePeriod(value: any) {
      historyGamePeriod.value = value;
      getHistoryFiltered();
    }

    function onIncludeOtherHostHistory(value) {
      includeOtherHostHistory.value = value;
      getGameHistory();
    }

    function onOpenHistoryModal(game: any) {
      if (canAccessFullHistoryValue.value) {
        context.emit('open_history_modal', game);
      } else {
        context.emit('open_upgrade_modal');
      }
    }

    function onUpgrade() {
      context.emit('upgrade');
    }

    return {
      canAccessFullHistoryValue,
      historyGameNames,
      historyGameNameIndex,
      gamePeriods,
      historyGamePeriod,
      onSelectHistoryGameName,
      onSelectHistoryGamePeriod,
      hasOtherHost,
      includeOtherHostHistory,
      onIncludeOtherHostHistory,
      onOpenHistoryModal,
      onUpgrade,
      isLoading,
      collectionData,
      hasHistoryMoreThan6,
      historyData,
      historyEnabledMonths,
      historyEnabledYears
    };
  }
});
