export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_DOC = 'UPDATE_USER_DOC';
export const UPDATE_LOBBY_DOC = 'UPDATE_LOBBY_DOC';
export const UPDATE_SURVEY_1_ANSWER = 'UPDATE_SURVEY_1_ANSWER';
export const UPDATE_ISUSERUSINGCUSTOMGAMEROOMID =
  'UPDATE_ISUSERUSINGCUSTOMGAMEROOMID';
export const UPDATE_ACTIVEGAMEROOMID = 'UPDATE_ACTIVEGAMEROOMID';
export const UPDATE_ACTIVEGAMENAME = 'UPDATE_ACTIVEGAMENAME';
export const UPDATE_USER_SUBSCRIPTION_DETAILS =
  'UPDATE_USER_SUBSCRIPTION_DETAILS';

export const UPDATE_USER_GM_GAMEPLAY_HISTORY =
  'UPDATE_USER_GM_GAMEPLAY_HISTORY';
export const UPDATE_USER_TESTIMONIAL = 'UPDATE_USER_TESTIMONIAL';
export const UPDATE_EDITORS_PICK_GAMES = 'UPDATE_EDITORS_PICK_GAMES';
export const UPDATE_MOST_POPULAR_GAMES = 'UPDATE_MOST_POPULAR_GAMES';
export const UPDATE_USER_CUSTOMIZED_GAMES = 'UPDATE_USER_CUSTOMIZED_GAMES';
export const UPDATE_MEDITATION_TRACKS = 'UPDATE_MEDITATION_TRACKS';
export const UPDATE_SLACK_STATUS = 'UPDATE_SLACK_STATUS';
export const UPDATE_SLACK_CHANNELS = 'UPDATE_SLACK_CHANNELS';
export const UPDATE_TEAM_INFO = 'UPDATE_TEAM_INFO';
export const UPDATE_PROMOTION_FLAG = 'UPDATE_PROMOTION_FLAG';
export const UPDATE_SLACK_MODAL_STATUS = 'UPDATE_SLACK_MODAL_STATUS';
export const UPDATE_ZOOM_STATUS = 'UPDATE_ZOOM_STATUS';
export const UPDATE_CURRENT_GAME_DATA = 'UPDATE_CURRENT_GAME_DATA';
