
// import { amplitudeEvent } from '@/config/amplitudeConfig';
import { store } from '@/store';
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'CouponCodeModal',

  emits: ['close'],
  setup(_, context) {
    const buttonLoading = ref(false);
    const user = computed(() => store.getters.getUser);
    const errorMsg = ref('');
    const emailSent = ref(true);
    const emailValue = user.value['email'];
    const close = function() {
      context.emit('close');
    };

    return {
      buttonLoading,
      emailSent,
      errorMsg,
      close,
      emailValue
    };
  }
});
