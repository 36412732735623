
import { computed, defineComponent, ref } from 'vue';
import CryptoJS from 'crypto-js';
import { amplitudeEvent } from '@/config/amplitudeConfig';
import usePostAPI from '@/hooks/usePostAPI';
import { getEndpoint } from '@/utils/helper';
import WhiteboardPainting from '@/components/Whiteboard/WhiteboardPainting.vue';
import Popover from '@/components/Popover.vue';

export default defineComponent({
  name: 'WhiteboardEntryHost',
  props: {
    whiteboardId: {
      type: String,
      required: true
    },
    clubhouseTitle: {
      type: String,
      default: null
    },
    clubhouseEventDate: {
      type: String,
      default: null
    },
    isAlreadyHost: {
      type: Boolean,
      default: false
    }
  },
  components: {
    WhiteboardPainting,
    Popover
  },
  setup(props) {
    const boardNumber = props.whiteboardId.replace('whiteboard_', '');
    const processedBoardNUmber =
      boardNumber.substring(0, 4) + '-' + boardNumber.substring(4);
    const completedWhiteboardSetup = ref(props.isAlreadyHost);
    const hostName = ref('');
    const password = ref('');
    const passwordEye = ref(false);
    const showPasswordPopover = ref(false);
    const isLoading = ref(false);
    const errorMessage = ref('');

    const showPasswordEye = computed(() => {
      return password.value.trim().length > 0;
    });

    const confirmButtonUpdate = computed(() => {
      return hostName.value.trim() && password.value.trim().length >= 4;
    });

    async function setupWhiteboard() {
      isLoading.value = true;
      try {
        const encryptedPassword = CryptoJS.AES.encrypt(
          password.value,
          props.whiteboardId
        ).toString();
        const response = await usePostAPI(
          getEndpoint('setup', '/api/whiteboard/'),
          {
            whiteboardId: props.whiteboardId,
            hostName: hostName.value,
            password: encryptedPassword
          }
        );
        const result = response.data;
        const whiteboardId = result['whiteboardId'];
        if (response.status === 200 && whiteboardId === props.whiteboardId) {
          amplitudeEvent('spaceCreated', {
            whiteboardId: whiteboardId,
            url: location.href,
            title: props.clubhouseTitle,
            hostName: hostName.value
          });
          showPasswordPopover.value = false;
          completedWhiteboardSetup.value = true;
        }
      } catch (err) {
        console.log(err);
        errorMessage.value =
          'An unexpected error has occured. Please try again later or contact support@brightful.me for more info.';
      }
    }

    return {
      processedBoardNUmber,
      completedWhiteboardSetup,
      hostName,
      password,
      passwordEye,
      showPasswordEye,
      showPasswordPopover,
      isLoading,
      errorMessage,
      confirmButtonUpdate,
      setupWhiteboard
    };
  }
});
