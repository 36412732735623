
import { defineComponent } from 'vue';
import { MultipleChoiceLivePoll } from '@/models/event_tools/live_poll/live_poll_model';

export default defineComponent({
  name: 'MultipleChoicePreviewModal',

  props: {
    livePoll: {
      type: Object as () => MultipleChoiceLivePoll,
      default: null,
      required: true
    }
  },
  emits: ['close'],
  setup(_, context) {
    function onClose() {
      context.emit('close');
    }

    return {
      onClose
    };
  }
});
