
import { defineComponent, ref, watchEffect, onMounted } from 'vue';
import BounceLoading from '@/components/BounceLoading.vue';
import WhiteboardEntry from '@/components/Whiteboard/WhiteboardEntry.vue';
import WhiteboardDeletedModal from '@/components/Modal/WhiteboardComponent/WhiteboardDeletedModal.vue';
import useFirebaseDocument from '@/hooks/firebase/useFirebaseDocument';
import useFirebaseAuth from '@/hooks/firebase/useFirebaseAuth';
import router from '@/router';
import { initAmplitude } from '@/config/amplitudeConfig';

export default defineComponent({
  name: 'WhiteboardMain',
  components: {
    BounceLoading,
    WhiteboardEntry,
    WhiteboardDeletedModal
  },
  setup() {
    const whiteboardId = `whiteboard_${router.currentRoute.value.params.room}`;
    const { user, signInAnonymously } = useFirebaseAuth();
    const whiteboardDoc = useFirebaseDocument('whiteboard_data', {
      onMounted: false
    });
    const signInLoading = ref(true);

    watchEffect(async () => {
      if (user.value === null) {
        signInAnonymously();
      } else if (user.value !== undefined) {
        await whiteboardDoc.getDocument(10, whiteboardId);
        signInLoading.value = false;
      }
    });

    onMounted(() => {
      initAmplitude();
    });

    return { ...whiteboardDoc, signInLoading };
  }
});
