
import {
  getEndpoint,
  hasActiveGame,
  onGameStart,
  pollDateCreatedText
} from '@/utils/helper';
import { GameName, ActivityType } from '@/utils/gamenames_enums';
import { computed, defineComponent, PropType, Ref, ref } from 'vue';
import firebase from 'firebase';
import { store } from '@/store';
import GeneralWarningModal from '@/components//Modal/GeneralWarningModal.vue';
import usePostAPI from '@/hooks/usePostAPI';
import { LaunchOptions } from '@/models/GameStartOptions';
import { SpinTheWheel } from '@/models/event_tools/spin_the_wheel/spin_the_wheel_model';
import WheelHistoryListModal from './Modal/History/WheelHistoryListModal.vue';
export default defineComponent({
  name: 'CreateWheelHavingHistory',
  emits: {
    'show-modal': () => true,

    preview: (wheel: SpinTheWheel) => true,
    'launch-modal': (options: LaunchOptions) => true
  },
  props: {
    wheels: { type: Array as PropType<Array<SpinTheWheel>>, required: true }
  },
  components: { GeneralWarningModal, WheelHistoryListModal },
  setup(props, context) {
    const modalTypeShown: Ref<
      ['deleteWarning', SpinTheWheel] | ['historyModal', SpinTheWheel] | null
    > = ref(null);
    const launchButtonLoading: Ref<string | null> = ref(null);

    const subscriptionDetails = computed(
      () => store.getters.getSubscriptionDetails
    );
    const userDoc = computed(() => store.getters.getUserDoc);
    function onCreate() {
      context.emit('show-modal');
    }
    function onPreview(wheels: SpinTheWheel) {
      context.emit('preview', wheels);
    }

    async function onLaunch(wheel: SpinTheWheel) {
      launchButtonLoading.value = wheel.id!;
      const gameName = GameName.SPIN_THE_WHEEL;
      // check if lobby is open
      const launchOptions: LaunchOptions = {
        activityType: ActivityType.SPIN_THE_WHEEL,
        chosenGame: gameName,
        createGameSource: 'dashboard',
        wheel: wheel
      };
      if (!hasActiveGame()) {
        // no active game in session
        await onGameStart({
          ...launchOptions,
          subscriptionLevel: subscriptionDetails.value.subscriptionLevel,
          zoomClientConfig: store.state.zoomClientStatus,
          startFresh: false
        });
      } else {
        context.emit('launch-modal', launchOptions);
      }
      launchButtonLoading.value = null;
    }
    function onDelete(wheel: SpinTheWheel) {
      modalTypeShown.value = ['deleteWarning', wheel];
    }
    function onDeleteCancel() {
      modalTypeShown.value = null;
    }
    async function onDeleteConfirm(wheel: SpinTheWheel, event: any) {
      try {
        if (event?.onRequest) {
          event.onRequest();
        }
        const lobbyId = userDoc.value['defaultLobbyId'];
        await usePostAPI(getEndpoint('deleteWheel'), {
          lobbyId: lobbyId,
          wheelId: wheel.id
        });
        if (event?.onSuccess) {
          event?.onSuccess();
        }
      } catch (err) {
        console.log(err);
        if (event?.onError) {
          event?.onError();
        }
      }
      modalTypeShown.value = null;
    }

    async function onLaunchedCount(wheel: SpinTheWheel) {
      modalTypeShown.value = ['historyModal', wheel];
    }
    return {
      modalTypeShown,
      launchButtonLoading,
      pollDateCreatedText,
      onLaunchedCount,
      onCreate,
      onPreview,
      onLaunch,
      onDelete,
      onDeleteCancel,
      onDeleteConfirm
    };
  }
});
