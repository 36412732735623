
import { defineComponent } from 'vue';
import { SpinTheWheel } from '@/models/event_tools/spin_the_wheel/spin_the_wheel_model';

export default defineComponent({
  name: 'WheelPreviewModal',

  props: {
    wheel: {
      type: Object as () => SpinTheWheel,
      default: null,
      required: true
    }
  },
  emits: ['close'],
  setup(_, context) {
    function onClose() {
      context.emit('close');
    }
    return {
      onClose
    };
  }
});
