
import { computed, defineComponent, ref } from 'vue';
import moment from 'moment';
import { getGameTitleByType } from '@/utils/gameConstants';
import BounceLoading from '@/components/BounceLoading.vue';
import CustomizedGameExampleModal from '@/components/Modal/CustomizedGameExampleModal.vue';
import CustomizedGameHowToPlayModal from '@/components/Modal/CustomizedGameHowToPlayModal.vue';
import useFirebaseCollection from '@/hooks/firebase/useFirebaseCollection';
import { useStore } from '@/store';
import { firebaseModifiedSource } from '@/utils/helper';

export default defineComponent({
  name: 'CustomizedGameSettingsModal',
  emits: ['start', 'close', 'duplicate'],
  components: {
    BounceLoading,
    CustomizedGameExampleModal,
    CustomizedGameHowToPlayModal
  },
  props: {
    customGameId: {
      type: String,
      required: true
    }
  },
  setup(props, context) {
    const store = useStore();
    const user = computed(() => store.getters.getUser);

    const show = ref(true);
    const isLoading = ref(false);
    const showCustomizedGameExampleModal = ref(false);
    const showHowToPlayModal = ref(false);

    const gameTitle = computed(() => {
      return getGameTitleByType(
        customGameDocQuery.collectionData.value![0].gameName ?? ''
      );
    });

    const ownedByMe = computed(() => {
      return (
        customGameDocQuery.collectionData.value![0].created_by ===
        user.value.uid
      );
    });

    const customGameDocQuery = useFirebaseCollection(
      'minigame_custom_game_public',
      {
        onMounted: true,
        fieldPath: 'customGameId',
        opStr: '==',
        value: props.customGameId,
        limit: 1
      }
    );

    const gameNotFound = computed(() => {
      return customGameDocQuery.collectionData.value?.length == 0;
    });

    function close() {
      show.value = false;
      setTimeout(() => {
        context.emit('close');
      }, 50);
    }

    function onStartGame() {
      if (user.value === null) return;
      isLoading.value = true;
      context.emit('start', {
        gameName: customGameDocQuery.collectionData.value![0].gameName,
        customGameName: customGameDocQuery.collectionData.value![0]
          .customGameName,
        customGameId: props.customGameId,
        onSuccess: () => {
          isLoading.value = false;
        },
        onError: () => {
          isLoading.value = false;
        }
      });
    }

    function showCustomizedGameExample() {
      showCustomizedGameExampleModal.value = true;
    }

    function onCustomizedGameExampleClosed() {
      showCustomizedGameExampleModal.value = false;
    }

    function showHowToPlay() {
      showHowToPlayModal.value = true;
    }

    function onHowToPlayClosed() {
      showHowToPlayModal.value = false;
    }

    function onDuplicate() {
      context.emit('duplicate', props.customGameId);
    }

    return {
      moment,
      show,
      isLoading,
      showCustomizedGameExampleModal,
      showHowToPlayModal,
      gameTitle,
      ownedByMe,
      ...customGameDocQuery,
      gameNotFound,
      firebaseModifiedSource,
      close,
      onStartGame,
      //
      showCustomizedGameExample,
      onCustomizedGameExampleClosed,
      //
      showHowToPlay,
      onHowToPlayClosed,
      //
      onDuplicate
    };
  }
});
