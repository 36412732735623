
import {
  LivePollResult,
  MultipleChoiceLivePoll
} from '@/models/event_tools/live_poll/live_poll_model';
import { blobToBase64, fetchWordCloudImage } from '@/utils/helper';

import { Ref, defineComponent, ref } from 'vue';
export default defineComponent({
  name: 'WordCloudHistoryDetail',

  props: {
    result: Object as () => LivePollResult
  },
  emits: ['close', 'loadImage'],
  setup(props, context) {
    const image: Ref<any> = ref(null);
    const words: Ref<Array<string>> = ref([]);
    async function getImage() {
      const response = props.result?.response;
      if (response && Object.keys(response).length != 0) {
        words.value = Object.keys(response);
        // const res = await fetchWordCloudImage(Object.keys(response));

        // const utf8_binary = [...res].map(char => char.charCodeAt(0));
        // let blob = new Blob(utf8_binary, { type: 'image/png' });
        // console.log(blob);
        // image.value = await blobToBase64(blob);
        // console.log(image.value);
        // // image.value = await blobToBase64(res);

        // context.emit('loadImage', image.value);
      }
    }
    getImage();
    return { image, words };
  }
});
