
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HowToPlayGinRummy',
  emits: ['more'],
  setup(_, context) {
    function onMore() {
      context.emit('more');
    }
    return {
      onMore
    };
  }
});
