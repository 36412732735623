
import {
  firestoreGameTypes,
  getGameTitleByType,
  groupWinnerGameTypes
} from '@/utils/gameConstants';
import { computed, defineComponent, ref } from 'vue';
import useFirebaseCollection from '@/hooks/firebase/useFirebaseCollection';
import { useStore } from '@/store';
import firebase from '@/firebaseApp';
import moment from 'moment';
import CategoryFilterMobile from '@/components/CategoryFilterMobile.vue';
import HistoryLockTile from '@/components/HistoryLeaderboard/HistoryLockTile.vue';
import LeaderboardDetailRankingTile from '@/components/HistoryLeaderboard/LeaderboardDetailRankingTile.vue';
import { getGameWinners, roundToOneDecimal } from '@/utils/helper';
import { canAccessFullLeaderboard, isProPlan } from '@/utils/pricing_rules';
import { shareLeaderboardDetailList } from '@/utils/shareResult';
import { amplitudeEvent } from '@/config/amplitudeConfig';
import useFirebaseDocument from '@/hooks/firebase/useFirebaseDocument';

export default defineComponent({
  name: 'LeaderboardDetail',
  components: {
    CategoryFilterMobile,
    HistoryLockTile,
    LeaderboardDetailRankingTile
  },
  props: {
    gameName: {
      type: String,
      required: true
    },
    includeOtherHost: {
      type: Boolean,
      required: true
    }
  },
  emits: ['upgrade'],
  setup(props, context) {
    const store = useStore();
    const subscriptionDetails = computed(
      () => store.getters.getSubscriptionDetails
    );
    const user = computed(() => store.getters.getUser);
    const userDoc = computed(() => store.getters.getUserDoc);

    const canAccessFullLeaderboardValue = computed(() => {
      return canAccessFullLeaderboard(subscriptionDetails.value, userDoc.value);
    });
    const isLoading = ref(false);

    const title = getGameTitleByType(props.gameName);

    const gamePeriods = ['Select a month', 'Select a year'];
    const gamePeriod = ref(undefined as any);

    const collectionData = ref([] as any[]);
    const leaderboardData = ref([] as any[]);
    const leaderboardEnabledMonths = ref([] as string[]);
    const leaderboardEnabledYears = ref([] as number[]);
    let rankings = [] as any[];

    const sortType = ref(groupWinnerGameTypes.includes(props.gameName));

    const includeOtherHostLeaderboard = ref(props.includeOtherHost);

    const teamInfo = computed(() => store.getters.getTeamInfo);
    const hasOtherHost =
      isProPlan(subscriptionDetails.value) &&
      teamInfo.value &&
      teamInfo.value.members.length > 1;

    function handleGameResult(game: any) {
      const result = getGameWinners(game);
      result.map(winner => {
        let index = rankings.findIndex(result =>
          winner.email
            ? result.email === winner.email
            : result.name === winner.name
        );
        if (index === -1) {
          rankings.push({
            name: winner.name,
            email: winner.email,
            games: 0,
            wins: 0,
            score: 0
          });
          index = rankings.length - 1;
        }
        rankings[index].games += 1;
        rankings[index].wins += winner.win;
        if (winner.score) {
          rankings[index].score += winner.score;
        }
      });
      rankings.map(player => {
        player.score = roundToOneDecimal(player.score);
      });
    }

    function getFiltered() {
      rankings = [];

      const dateFilteredCollectionData = gamePeriod.value
        ? collectionData.value.filter(data => {
            const monthDate = data.dateCreated.format('MMMM YYYY');
            return monthDate.includes(gamePeriod.value);
          })
        : collectionData.value;

      let prevRankings = [] as any[];
      for (let i = 0; i < dateFilteredCollectionData.length; i++) {
        handleGameResult(
          dateFilteredCollectionData[dateFilteredCollectionData.length - i - 1]
        );
        if (
          i === dateFilteredCollectionData.length - 2 &&
          !groupWinnerGameTypes.includes(props.gameName)
        ) {
          prevRankings = Object.assign([], rankings);
          prevRankings.sort((a, b) => b.score - a.score);
        }
      }

      rankings.sort((a, b) => b.score - a.score);
      rankings.map((ranking, index) => {
        ranking.score = Math.round(ranking.score * 10) / 10;
        if (!groupWinnerGameTypes.includes(props.gameName)) {
          const prevIndex = prevRankings.findIndex(pRanking =>
            ranking.email || pRanking.email
              ? ranking.email === pRanking.email
              : ranking.name === pRanking.name
          );
          ranking.change =
            prevIndex !== -1 ? prevIndex - index : rankings.length - 1 - index;
        }
      });

      rankings.sort((a, b) => {
        if (sortType.value) {
          return b.wins - a.wins;
        } else {
          return b.score - a.score;
        }
      });
      leaderboardData.value = rankings;

      if (!canAccessFullLeaderboardValue.value) {
        leaderboardData.value = leaderboardData.value.slice(0, 6);
      }
    }

    async function getRoomGameData(gameRoomId: string) {
      const userGameplayHistoryQuery = useFirebaseCollection(
        `minigame_gameplay_history/${gameRoomId}/game_plays`,
        {
          onMounted: false
        }
      );
      await userGameplayHistoryQuery.getCollection({
        fieldPath: 'isFinished',
        opStr: '==',
        value: true,
        fieldPath2: 'gameName',
        opStr2: '==',
        value2: props.gameName,
        orderBy: 'dateCreated',
        isDesc: true
      });
      for (
        let i = 0;
        i < userGameplayHistoryQuery.collectionData.value!.length;
        i++
      ) {
        const data = userGameplayHistoryQuery.collectionData.value![i];
        if (
          data['dateCreated'] &&
          ((firestoreGameTypes.includes(data.gameName) &&
            data['dateCreated'] instanceof firebase.firestore.Timestamp) ||
            (!firestoreGameTypes.includes(data.gameName) &&
              !(data['dateCreated'] instanceof firebase.firestore.Timestamp)))
        ) {
          const date =
            data['dateCreated'] instanceof firebase.firestore.Timestamp
              ? moment(data['dateCreated'].toDate())
              : moment(data['dateCreated']);
          const newData = Object.assign({}, data);
          newData['dateCreated'] = date;
          collectionData.value.push(newData);
          if (firestoreGameTypes.includes(props.gameName)) {
            const playersQuery = useFirebaseCollection(
              `minigame_gameplay_history/${gameRoomId}/game_plays/${data.gamePlayId}/players`,
              {
                onMounted: false
              }
            );
            await playersQuery.getCollection({});
            newData['players'] = playersQuery.collectionData.value;
          }
        }
      }
    }

    async function getGameHistory() {
      isLoading.value = true;

      collectionData.value = [];
      leaderboardEnabledMonths.value = [];
      leaderboardEnabledYears.value = [];

      if (includeOtherHostLeaderboard.value) {
        for (let i = 0; i < teamInfo.value.members.length; i++) {
          const userDocument = useFirebaseDocument('minigame_user', {
            onMounted: false
          });
          await userDocument.getDocument(10, teamInfo.value.members[i].uid);
          await getRoomGameData(
            userDocument.documentData.value['defaultGameRoomId']
          );
        }
      } else {
        await getRoomGameData(userDoc.value['defaultGameRoomId']);
      }

      collectionData.value.sort(
        (a, b) => b.dateCreated.unix() - a.dateCreated.unix()
      );

      collectionData.value.map(data => {
        const date = data.dateCreated;
        const month = moment(date).format('MMMM YYYY');
        if (!leaderboardEnabledMonths.value.includes(month)) {
          leaderboardEnabledMonths.value.push(month);
        }
        if (!leaderboardEnabledYears.value.includes(date.year())) {
          leaderboardEnabledYears.value.push(date.year());
        }
      });

      getFiltered();

      isLoading.value = false;
    }

    getGameHistory();

    function onSelectGamePeriod(value: any) {
      gamePeriod.value = value;
      getFiltered();
    }

    function onSortType(type: boolean) {
      sortType.value = type;
      leaderboardData.value.sort((a, b) => {
        if (sortType.value) {
          return b.wins - a.wins;
        } else {
          return b.score - a.score;
        }
      });
    }

    function onIncludeOtherHostLeaderboard(value) {
      includeOtherHostLeaderboard.value = value;
      getGameHistory();
    }

    async function onShareList() {
      const sortedRankings = Object.assign([] as any[], leaderboardData.value);
      sortedRankings.sort((a, b) => {
        if (sortType.value) {
          return b.wins - a.wins;
        } else {
          return b.score - a.score;
        }
      });
      const dateString =
        (!gamePeriod.value ? 'All Time' : gamePeriod.value) +
        ' Leaderboard - ' +
        (includeOtherHostLeaderboard.value
          ? 'All hosts'
          : 'Host: ' + user.value.displayName);
      const data = {
        gameName: props.gameName,
        date: dateString,
        sortType: sortType.value,
        rankings: sortedRankings
      };
      const url = await shareLeaderboardDetailList(data);
      window.open(url, '_blank');
    }

    function onUpgrade() {
      context.emit('upgrade');
    }

    amplitudeEvent('visitLeaderboardDetailPage', {
      eventTime: new Date(Date.now()).toISOString(),
      game: props.gameName
    });

    return {
      groupWinnerGameTypes,
      isLoading,
      title,
      leaderboardData,
      leaderboardEnabledMonths,
      leaderboardEnabledYears,
      gamePeriods,
      gamePeriod,
      onSelectGamePeriod,
      hasOtherHost,
      includeOtherHostLeaderboard,
      onIncludeOtherHostLeaderboard,
      sortType,
      onSortType,
      onShareList,
      onUpgrade,
      canAccessFullLeaderboardValue
    };
  }
});
