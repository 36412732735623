const firebaseConfig = {
  apiKey: 'AIzaSyCMkC7rOaaxUzb_M9AeM6LjY3nhn87sNlQ',
  authDomain: 'app.brightful.me',
  databaseURL: 'https://brightful-prod.firebaseio.com',
  projectId: 'brightful-prod',
  storageBucket: 'assets.brightful.me',
  messagingSenderId: '515118877670',
  appId: '1:515118877670:web:bf75f4d160fee9a1b46427'
};

// Initialize Firebase
export default firebaseConfig;
