
import { computed, defineComponent, Ref, ref, watch } from 'vue';

import { store } from '@/store';
import useFirebaseCollection from '@/hooks/firebase/useFirebaseCollection';
import BounceLoading from '../../BounceLoading.vue';
import { LaunchOptions } from '@/models/GameStartOptions';
import { canAccessEventTools } from '@/utils/pricing_rules';
import { SpinTheWheel } from '@/models/event_tools/spin_the_wheel/spin_the_wheel_model';
import CreateWheelModal from './Modal/CreateWheelModal.vue';
import WheelPreviewModal from './Modal/WheelPreviewModal.vue';
import CreateWheelWithoutHistory from './CreateWheelWithoutHistory.vue';
import CreateWheelHavingHistory from './CreateWheelHavingHistory.vue';

export default defineComponent({
  name: 'SpinTheWheel',
  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'launch-modal': (options: LaunchOptions) => true
  },
  props: {},
  components: {
    BounceLoading,
    CreateWheelModal,
    WheelPreviewModal,
    CreateWheelWithoutHistory,
    CreateWheelHavingHistory
  },
  setup() {
    const userDoc = computed(() => store.getters.getUserDoc);
    const subscriptionDetails = computed(
      () => store.getters.getSubscriptionDetails
    );
    const canAccessWheel = computed(() =>
      canAccessEventTools(subscriptionDetails.value)
    );
    const wheelsQuery = useFirebaseCollection(
      `minigame_lobbies/${userDoc.value['defaultLobbyId']}/spin_the_wheel`,
      {
        onMounted: true,
        orderBy: 'dateCreated',
        isDesc: true
      },
      true
    );

    const isLoading: Ref<boolean> = ref(true);
    const wheels: Ref<Array<SpinTheWheel> | null> = ref(null);
    const modalTypeShown: Ref<
      ['create'] | ['preview', SpinTheWheel] | null
    > = ref(null);

    watch(wheelsQuery.collectionData, newValue => {
      if (newValue) {
        wheels.value = newValue;
      } else {
        wheels.value = [];
      }
      isLoading.value = false;
    });

    const hasWheelHistory = computed(
      () => wheels.value && wheels.value.length != 0
    );

    function onShowModal() {
      modalTypeShown.value = ['create'];
    }
    function onPreview(wheel: SpinTheWheel) {
      modalTypeShown.value = ['preview', wheel];
    }
    function onModalClose() {
      modalTypeShown.value = null;
    }
    return {
      isLoading,
      hasWheelHistory,
      modalTypeShown,
      wheels,
      canAccessWheel,
      onShowModal,
      onModalClose,
      onPreview
    };
  }
});
