import { createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "modal-content" }
const _hoisted_2 = { class: "modal-header" }
const _hoisted_3 = { class: "modal-body" }
const _hoisted_4 = { class: "game-settings" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AboutDrawIt = _resolveComponent("AboutDrawIt")
  const _component_AboutYesOrNo = _resolveComponent("AboutYesOrNo")
  const _component_AboutFunnyOrNot = _resolveComponent("AboutFunnyOrNot")
  const _component_AboutDrawTell = _resolveComponent("AboutDrawTell")
  const _component_AboutTwoWords = _resolveComponent("AboutTwoWords")
  const _component_AboutWordsOfPower = _resolveComponent("AboutWordsOfPower")
  const _component_AboutWerewolf = _resolveComponent("AboutWerewolf")
  const _component_AboutHearts = _resolveComponent("AboutHearts")
  const _component_AboutWouldYouRather = _resolveComponent("AboutWouldYouRather")
  const _component_AboutDescribeIt = _resolveComponent("AboutDescribeIt")
  const _component_AboutCharades = _resolveComponent("AboutCharades")
  const _component_AboutRummy = _resolveComponent("AboutRummy")
  const _component_AboutSpotMyLie = _resolveComponent("AboutSpotMyLie")
  const _component_AboutTrivia = _resolveComponent("AboutTrivia")
  const _component_AboutGinRummy = _resolveComponent("AboutGinRummy")
  const _component_AboutQOfTheDay = _resolveComponent("AboutQOfTheDay")
  const _component_AboutPoll = _resolveComponent("AboutPoll")
  const _component_AboutTop5 = _resolveComponent("AboutTop5")
  const _component_AboutOneToHundred = _resolveComponent("AboutOneToHundred")

  return (_openBlock(), _createBlock("div", {
    class: "modal-right",
    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.close()))
  }, [
    _createVNode(_Transition, {
      appear: "",
      name: "slide"
    }, {
      default: _withCtx(() => [
        (_ctx.show)
          ? (_openBlock(), _createBlock("div", {
              key: 0,
              class: "modal-dialog",
              onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
            }, [
              _createVNode("div", _hoisted_1, [
                _createVNode("div", _hoisted_2, [
                  _createVNode("button", {
                    type: "button",
                    class: "btn-close-left",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.close()))
                  }, [
                    _createVNode("img", {
                      src: require('@/assets/img/close.png'),
                      alt: "close modal"
                    }, null, 8, ["src"])
                  ])
                ]),
                _createVNode("div", _hoisted_3, [
                  _createVNode("div", _hoisted_4, [
                    (_ctx.gameName == 'draw_it')
                      ? (_openBlock(), _createBlock(_component_AboutDrawIt, { key: 0 }))
                      : _createCommentVNode("", true),
                    (_ctx.gameName == 'yes_or_no')
                      ? (_openBlock(), _createBlock(_component_AboutYesOrNo, { key: 1 }))
                      : _createCommentVNode("", true),
                    (_ctx.gameName == 'funny_or_not')
                      ? (_openBlock(), _createBlock(_component_AboutFunnyOrNot, { key: 2 }))
                      : _createCommentVNode("", true),
                    (_ctx.gameName == 'draw_tell')
                      ? (_openBlock(), _createBlock(_component_AboutDrawTell, { key: 3 }))
                      : _createCommentVNode("", true),
                    (_ctx.gameName == 'two_words_game')
                      ? (_openBlock(), _createBlock(_component_AboutTwoWords, { key: 4 }))
                      : _createCommentVNode("", true),
                    (_ctx.gameName == 'words_of_power')
                      ? (_openBlock(), _createBlock(_component_AboutWordsOfPower, { key: 5 }))
                      : _createCommentVNode("", true),
                    (_ctx.gameName == 'werewolf_game')
                      ? (_openBlock(), _createBlock(_component_AboutWerewolf, { key: 6 }))
                      : _createCommentVNode("", true),
                    (_ctx.gameName == 'hearts_game')
                      ? (_openBlock(), _createBlock(_component_AboutHearts, { key: 7 }))
                      : _createCommentVNode("", true),
                    (_ctx.gameName == 'would_you_rather')
                      ? (_openBlock(), _createBlock(_component_AboutWouldYouRather, { key: 8 }))
                      : _createCommentVNode("", true),
                    (_ctx.gameName == 'describe_it')
                      ? (_openBlock(), _createBlock(_component_AboutDescribeIt, { key: 9 }))
                      : _createCommentVNode("", true),
                    (_ctx.gameName == 'charades')
                      ? (_openBlock(), _createBlock(_component_AboutCharades, { key: 10 }))
                      : _createCommentVNode("", true),
                    (_ctx.gameName == 'rummy_game')
                      ? (_openBlock(), _createBlock(_component_AboutRummy, { key: 11 }))
                      : _createCommentVNode("", true),
                    (_ctx.gameName == 'spot_my_lie')
                      ? (_openBlock(), _createBlock(_component_AboutSpotMyLie, { key: 12 }))
                      : _createCommentVNode("", true),
                    (_ctx.gameName == 'trivia')
                      ? (_openBlock(), _createBlock(_component_AboutTrivia, { key: 13 }))
                      : _createCommentVNode("", true),
                    (_ctx.gameName == 'gin_rummy_game')
                      ? (_openBlock(), _createBlock(_component_AboutGinRummy, { key: 14 }))
                      : _createCommentVNode("", true),
                    (_ctx.gameName == 'question_of_the_day')
                      ? (_openBlock(), _createBlock(_component_AboutQOfTheDay, { key: 15 }))
                      : _createCommentVNode("", true),
                    (_ctx.gameName == 'poll')
                      ? (_openBlock(), _createBlock(_component_AboutPoll, { key: 16 }))
                      : _createCommentVNode("", true),
                    (_ctx.gameName == 'top5')
                      ? (_openBlock(), _createBlock(_component_AboutTop5, { key: 17 }))
                      : _createCommentVNode("", true),
                    (_ctx.gameName == 'one_to_hundred')
                      ? (_openBlock(), _createBlock(_component_AboutOneToHundred, { key: 18 }))
                      : _createCommentVNode("", true)
                  ])
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}