import { GameName } from './gamenames_enums';
import { containsProfaneWord } from './helper';
import { OpenAiProps } from './openai_props';

export class OpenAi {
  constructor() {}
  static preProcessTitle(title: string, game: string) {
    function remove(from: string, words: Array<string>) {
      let newString: string = from;
      for (let i = 0; i < words.length; i++) {
        const regex = new RegExp(`\\b\\w*?${words[i]}\\w*?\\b`, 'ig');
        newString = newString.replace(regex, '');
      }
      newString = newString.replace(/  +/g, ' ').trim();
      newString = newString.replace(/!+$/gi, '').trim();
      return newString;
    }
    switch (game) {
      case GameName.DRAW_IT:
        return remove(title, ['draw it', 'draw']);
      case GameName.DESCRIBE_IT:
        return remove(title, ['describe it', 'describe']);
      case GameName.CHARADES:
        return remove(title, ['charades', 'charades']);
      case GameName.TWO_WORDS_GAME:
        return remove(title, ['mimic']);
      case GameName.WOULD_YOU_RATHER:
        return remove(title, [
          'would you rather questions',
          'would you rather'
        ]);
      case GameName.SPOT_MY_LIE:
      case GameName.QUESTION_OF_THE_DAY:
        // return remove(title, [
        //   'Question of the day', 'qotd'
        // ]);
        return title;
      case GameName.DRAW_TELL:
        return title;
      // return remove(title, [
      //   'Draw & tell', 'Draw and tell', 'draw&tell', 'draw tell'
      // ]);
      case GameName.ONE_TO_HUNDRED:
        return title;
      case GameName.YES_OR_NO:
        return title;
      case GameName.FUNNY_OR_NOT:
        return title;
      case GameName.POLL:
        return title;
      case GameName.TOP5:
        return title;
      default:
        break;
    }
    return title;
  }
  static getPrompt(
    game: string,
    title: string,
    description: string,
    isInitial: boolean,
    words: Array<any>
  ) {
    let prompt: string = '';
    let temperature: number;
    let engine: string = 'davinci';
    let maxTokens: number = 200;
    const processedTitle: string = OpenAi.preProcessTitle(title, game);
    let frequencyPenalty: number = 0.8;

    switch (game) {
      case GameName.DRAW_IT:
      case GameName.DESCRIBE_IT:
      case GameName.CHARADES:
        prompt += `I am generating a list of popular ${processedTitle}.\n`;
        if (description && description != '') {
          prompt += `${description}\n`;
        }
        prompt += `Here is a list of nouns related to ${processedTitle}:\n`;
        if (isInitial || !words || words.length === 0) {
          prompt += `1.`;
          engine = 'davinci-instruct-beta';
          temperature = 0.2;
          maxTokens = 150;
          frequencyPenalty = 0.8;
        } else {
          engine = 'davinci';
          temperature = 0.7;
          let i = 0;
          for (i = 0; i < words.length; i++) {
            prompt += `${i + 1}. ${words[i]}\n`;
          }
          prompt += `${i + 1}.`;
          maxTokens = 150;
          frequencyPenalty = 0.7;
        }
        return new OpenAiProps({
          engine: engine,
          frequencyPenalty: frequencyPenalty,
          prompt: prompt,
          maxTokens: maxTokens,
          temperature: temperature
        });
      case GameName.WOULD_YOU_RATHER: {
        prompt += `I am generating a ${processedTitle} Would you rather questions list.\n`;
        if (description && description != '') {
          prompt += `${description}\n`;
        }
        engine = 'davinci';
        temperature = 0.7;
        maxTokens = 200;
        frequencyPenalty = 0.2;
        prompt += `Here is a list of ${processedTitle} Would you rather questions with choices:\n`;
        let i = 0;
        for (i = 0; i < words.length; i++) {
          prompt += `${i + 1}. question: ${words[i].question}\nchoice 1: ${
            words[i].choice1
          }\nchoice 2: ${words[i].choice2}\n`;
        }
        prompt += `${i + 1}. question:`;

        return new OpenAiProps({
          engine: engine,
          frequencyPenalty: frequencyPenalty,
          prompt: prompt,
          maxTokens: maxTokens,
          temperature: temperature
        });
      }
      case GameName.SPOT_MY_LIE:
      case GameName.QUESTION_OF_THE_DAY:
      case GameName.FUNNY_OR_NOT: {
        prompt += `I am generating a list of open ended ${processedTitle} questions which are fun and interesting to answer.\n`;
        if (description && description != '') {
          prompt += `${description}\n`;
        }
        engine = 'davinci';
        temperature = 0.7;
        maxTokens = isInitial ? 50 : 200;
        frequencyPenalty = 0.2;
        prompt += `Here is a list of fun and interesting open ended ${processedTitle} questions:\n`;
        let i = 0;
        for (i = 0; i < words.length; i++) {
          prompt += `${i + 1}. question: ${words[i].question}\n`;
        }
        prompt += `${i + 1}. question:`;
        return new OpenAiProps({
          engine: engine,
          frequencyPenalty: frequencyPenalty,
          prompt: prompt,
          maxTokens: maxTokens,
          temperature: temperature
        });
      }
      case GameName.TWO_WORDS_GAME: {
        prompt += `I am generating a list of ${processedTitle} word pair which are having slightly similar meanings.\n`;
        if (description && description != '') {
          prompt += `${description}\n`;
        }
        engine = 'davinci';
        temperature = 0.7;
        maxTokens = 150;
        frequencyPenalty = 0.2;
        prompt += `Here is a list of ${processedTitle} pair of words that have similar meanings:\n`;
        let i = 0;
        for (i = 0; i < words.length; i++) {
          prompt += `${i + 1}. word 1: ${words[i].word}\nword 2: ${
            words[i].clue
          }\n`;
        }
        prompt += `${i + 1}. word 1:`;

        return new OpenAiProps({
          engine: engine,
          frequencyPenalty: frequencyPenalty,
          prompt: prompt,
          maxTokens: maxTokens,
          temperature: temperature
        });
      }
      case GameName.DRAW_TELL: {
        prompt += `Here is a list of ${processedTitle} prompts that would be interesting ton draw It could be an object, a memory or something completely hypothetical.\n`;
        if (description && description != '') {
          prompt += `${description}\n`;
        }
        engine = 'davinci';
        temperature = 0.7;
        maxTokens = isInitial ? 50 : 150;
        frequencyPenalty = 0.2;
        prompt += `Here is a list of ${processedTitle} prompts:\n`;
        let i = 0;
        for (i = 0; i < words.length; i++) {
          prompt += `${i + 1}. topic: ${words[i].topic}\n`;
        }
        prompt += `${i + 1}. topic:`;

        return new OpenAiProps({
          engine: engine,
          frequencyPenalty: frequencyPenalty,
          prompt: prompt,
          maxTokens: maxTokens,
          temperature: temperature
        });
      }
      case GameName.ONE_TO_HUNDRED: {
        prompt +=
          'I am generating a list of antonyms which ranges from low to high:\n';
        if (description && description != '') {
          prompt += `${description}\n`;
        }
        engine = 'davinci';
        temperature = 0.7;
        maxTokens = isInitial ? 50 : 150;
        frequencyPenalty = 0.2;
        let i = 0;
        for (i = 0; i < words.length; i++) {
          prompt += `${i + 1}. topic: ${words[i].topic}\nlow: ${
            words[i].left
          }\nhigh: ${words[i].right}`;
        }
        prompt += `${i + 1}. topic:`;

        return new OpenAiProps({
          engine: engine,
          frequencyPenalty: frequencyPenalty,
          prompt: prompt,
          maxTokens: maxTokens,
          temperature: temperature
        });
      }
      case GameName.YES_OR_NO: {
        prompt += `I am generating a list of ${processedTitle} words with hints that describes the word:\n`;
        if (description && description != '') {
          prompt += `${description}\n`;
        }
        engine = 'davinci';
        temperature = 0.7;
        maxTokens = isInitial ? 50 : 150;
        frequencyPenalty = 0.2;
        let i = 0;
        for (i = 0; i < words.length; i++) {
          prompt += `${i + 1}. word: ${words[i].word}\nhint: ${words[i].hint}`;
        }
        prompt += `${i + 1}. word:`;

        return new OpenAiProps({
          engine: engine,
          frequencyPenalty: frequencyPenalty,
          prompt: prompt,
          maxTokens: maxTokens,
          temperature: temperature
        });
      }
      case GameName.TOP5: {
        prompt += `I am generating a list of questions related to ${processedTitle} with top 5 possible answers.\n`;
        engine = 'davinci';
        temperature = 0.6;
        maxTokens = 1200;
        frequencyPenalty = 0.3;
        prompt += `Here is a list of questions with answers:\n`;
        let i = 0;
        for (i = 0; i < words.length; i++) {
          prompt += `${i + 1}. question: ${words[i].question}\n`;
        }
        prompt += `${i + 1}. question:`;

        return new OpenAiProps({
          engine: engine,
          frequencyPenalty: frequencyPenalty,
          prompt: prompt,
          maxTokens: maxTokens,
          temperature: temperature
        });
      }
      case GameName.POLL: {
        prompt += `I am generating a ${processedTitle} Super Poll questions list.\n`;
        if (description && description != '') {
          prompt += `${description}\n`;
        }
        engine = 'davinci';
        temperature = 0.7;
        maxTokens = 200;
        frequencyPenalty = 0.2;
        prompt += `Here is a list of ${processedTitle} Super Poll questions with choices:\n`;
        let i = 0;
        for (i = 0; i < words.length; i++) {
          prompt += `${i + 1}. question: ${words[i].question}\nchoices: ${words[
            i
          ].choices.join(',')}\n`;
        }
        prompt += `${i + 1}. question:`;

        return new OpenAiProps({
          engine: engine,
          frequencyPenalty: frequencyPenalty,
          prompt: prompt,
          maxTokens: maxTokens,
          temperature: temperature
        });
      }
      default:
        break;
    }
  }

  static processResponse(game: string, response: string, arr: Array<any>) {
    let arrSize = arr?.length;
    if (!arrSize) arrSize = 0;
    let start = arrSize + 1;
    let end = start + 1;
    let result = [] as any[];
    let startIndex;
    let lowerCaseArr = [] as any[];
    let uniqueResults = [] as any[];
    switch (game) {
      case GameName.DRAW_IT:
      case GameName.DESCRIBE_IT:
      case GameName.CHARADES:
        response = `${arrSize + 1} ` + response.substr(1, response.length);
        result = [];
        startIndex = response.indexOf(`${start}`);
        while (startIndex !== -1) {
          startIndex = response.indexOf(`${start}`);
          if (startIndex == -1) break;
          let endIndex = response.indexOf(`${end}`);
          if (endIndex == -1) {
            endIndex = response.length;
          }
          let word = response.substring(startIndex + 2, endIndex).trim();
          if (word[0] === '.') word = word.substr(1, word.length).trim();
          if (word[0] === '.') word = word.substr(1, word.length).trim();
          if (word.trim() != '' && word.split(' ').length < 4) {
            result.push(word);
          }
          word = word.substring(endIndex, word.length);
          start += 1;
          end += 1;
        }
        // remove duplicates (if any)
        result = result.map(word => word.toLowerCase());
        result = [...new Set(result)];
        lowerCaseArr = arr.map(word => word.toLowerCase());
        uniqueResults = [];

        // remove duplicates from given array of words
        for (const index in result) {
          const word = result[index];
          if (!lowerCaseArr.includes(word.toLowerCase())) {
            uniqueResults.push(word);
          }
        }
        // console.log(result);
        // console.log(uniqueResults);
        return uniqueResults.map(word => {
          return { word: word };
        });
      // console.log(uniqueResults);
      // return result.map(word => {
      //   return { word: word };
      // });
      case GameName.WOULD_YOU_RATHER:
        response =
          `${arrSize + 1}. question: ` + response.substr(1, response.length);
        while (startIndex != -1) {
          const startIndex = response.indexOf(`${start}`);
          if (startIndex == -1) break;
          let endIndex = response.indexOf(`${end}`);
          if (endIndex == -1) {
            endIndex = response.length;
          }
          let word = response.substring(startIndex + 2, endIndex).trim();
          if (word[0] === '.') word = word.substr(1, word.length).trim();
          const contentList = word.split('\n');
          if (contentList.length === 3) {
            const data = {
              question: '',
              choice1: '',
              choice2: ''
            };
            let dataCorrupt = false;
            for (let i = 0; i < contentList.length; i++) {
              if (i == 0) {
                const split = contentList[i].toLowerCase().split('question:');
                if (
                  split.length == 2 &&
                  split[0] == '' &&
                  split[1].trim() != ''
                ) {
                  data.question = split[1].trim();
                } else {
                  dataCorrupt = true;
                  break;
                }
              } else if (i == 1) {
                const split = contentList[i].toLowerCase().split('choice 1:');

                if (
                  split.length == 2 &&
                  split[0] == '' &&
                  split[1].trim() != ''
                ) {
                  data.choice1 = split[1].trim();
                } else {
                  dataCorrupt = true;
                  break;
                }
              } else {
                const split = contentList[i].toLowerCase().split('choice 2:');
                if (
                  split.length == 2 &&
                  split[0] == '' &&
                  split[1].trim() != ''
                ) {
                  data.choice2 = split[1].trim();
                } else {
                  dataCorrupt = true;
                  break;
                }
              }
            }
            if (!dataCorrupt && data.choice1 != data.choice2) result.push(data);
          }
          word = word.substring(endIndex, word.length);
          start += 1;
          end += 1;
        }
        result = result.filter(
          (v, i, a) =>
            a.findIndex(t => JSON.stringify(t) === JSON.stringify(v)) === i
        );
        return result;
      case GameName.TWO_WORDS_GAME: {
        response =
          `${arrSize + 1}. word 1: ` + response.substr(1, response.length);
        while (startIndex != -1) {
          const startIndex = response.indexOf(`${start}.`);
          if (startIndex == -1) break;
          let endIndex = response.indexOf(`${end}.`);
          if (endIndex == -1) {
            endIndex = response.length;
          }
          let word = response.substring(startIndex + 2, endIndex).trim();
          if (word[0] === '.') word = word.substr(1, word.length).trim();
          const contentList = word.split('\n');
          if (contentList.length === 2) {
            const data = {
              word: '',
              clue: ''
            };
            let dataCorrupt = false;
            for (let i = 0; i < contentList.length; i++) {
              if (i == 0) {
                const split = contentList[i].toLowerCase().split('word 1:');

                if (
                  split.length == 2 &&
                  split[0] == '' &&
                  split[1].trim() != ''
                ) {
                  data.word = split[1].trim();
                } else dataCorrupt = true;
              } else {
                const split = contentList[i].toLowerCase().split('word 2:');
                if (
                  split.length == 2 &&
                  split[0] == '' &&
                  split[1].trim() != ''
                ) {
                  data.clue = split[1].trim();
                } else dataCorrupt = true;
              }
            }
            if (!dataCorrupt && data.word != data.clue) result.push(data);
          }
          word = word.substring(endIndex, word.length);
          start += 1;
          end += 1;
        }
        result = result.filter(
          (v, i, a) =>
            a.findIndex(t => JSON.stringify(t) === JSON.stringify(v)) === i
        );
        const filteredResult: {
          word: string;
          clue: string;
        }[] = [];
        for (let i = 0; i < result.length; i++) {
          const entry = result[i];
          const reversedEntry = { word: entry.clue, clue: entry.word };
          if (
            !filteredResult.some(
              e => e.clue == entry.clue && e.word === entry.word
            ) &&
            !filteredResult.some(
              e => e.clue == reversedEntry.clue && e.word === reversedEntry.word
            )
          ) {
            filteredResult.push(entry);
          }
        }
        for (let i = 0; i < arr.length; i++) {
          const entry = arr[i];
          const reversedEntry = { word: entry.clue, clue: entry.word };
          if (
            filteredResult.some(
              e => e.clue == entry.clue && e.word === entry.word
            )
          ) {
            const ind = filteredResult.findIndex(
              val => val.clue === entry.clue && val.word === entry.word
            );
            if (ind != -1) filteredResult.splice(ind, 1);
          }

          if (
            filteredResult.some(
              e => e.clue == reversedEntry.clue && e.word === reversedEntry.word
            )
          ) {
            const ind = filteredResult.findIndex(
              val =>
                val.clue === reversedEntry.clue &&
                val.word === reversedEntry.word
            );
            if (ind != -1) filteredResult.splice(ind, 1);
          }
        }

        return filteredResult;
      }
      case GameName.SPOT_MY_LIE:
      case GameName.QUESTION_OF_THE_DAY:
      case GameName.DRAW_TELL:
      case GameName.FUNNY_OR_NOT: {
        let key = '';
        if (game == GameName.DRAW_TELL) key = 'topic';
        else key = 'question';
        response =
          `${arrSize + 1}. ${key}: ` + response.substr(1, response.length);
        while (startIndex != -1) {
          const startIndex = response.indexOf(`${start}`);
          if (startIndex == -1) break;
          let endIndex = response.indexOf(`${end}`);
          if (endIndex == -1) {
            endIndex = response.length;
          }
          let word = response.substring(startIndex + 2, endIndex).trim();
          if (word[0] === '.') word = word.substr(1, word.length).trim();
          const contentList = word.split('\n');
          if (contentList.length === 1) {
            const data = { question: '' };
            const split = contentList[0].toLowerCase().split(`${key}:`);

            if (split.length == 2 && split[0] == '' && split[1].trim() != '') {
              data.question = split[1].trim();
              result.push(data);
            }
          }
          word = word.substring(endIndex, word.length);
          start += 1;
          end += 1;
        }
        result = result.filter(
          (v, i, a) =>
            a.findIndex(t => JSON.stringify(t) === JSON.stringify(v)) === i
        );
        return result;
      }
      case GameName.TRIVIA:
        break;
      case GameName.ONE_TO_HUNDRED:
        response =
          `${arrSize + 1}. topic: ` + response.substr(1, response.length);
        while (startIndex != -1) {
          const startIndex = response.indexOf(`${start}`);
          if (startIndex == -1) break;
          let endIndex = response.indexOf(`${end}`);
          if (endIndex == -1) {
            endIndex = response.length;
          }
          let word = response.substring(startIndex + 2, endIndex).trim();
          if (word[0] === '.') word = word.substr(1, word.length).trim();
          const contentList = word.split('\n');
          if (contentList.length === 3) {
            const data = {
              topic: '',
              left: '',
              right: ''
            };
            let dataCorrupt = false;
            for (let i = 0; i < contentList.length; i++) {
              if (i == 0) {
                const split = contentList[i].toLowerCase().split('topic:');

                if (
                  split.length == 2 &&
                  split[0] == '' &&
                  split[1].trim() != ''
                ) {
                  data.topic = split[1].trim();
                } else {
                  dataCorrupt = true;
                  break;
                }
              } else if (i == 1) {
                const split = contentList[i].toLowerCase().split('low:');

                if (
                  split.length == 2 &&
                  split[0] == '' &&
                  split[1].trim() != ''
                ) {
                  data.left = split[1].trim();
                } else {
                  dataCorrupt = true;
                  break;
                }
              } else {
                const split = contentList[i].toLowerCase().split('high:');
                if (
                  split.length == 2 &&
                  split[0] == '' &&
                  split[1].trim() != ''
                ) {
                  data.right = split[1].trim();
                } else {
                  dataCorrupt = true;
                  break;
                }
              }
            }
            if (!dataCorrupt && data.left != data.right) result.push(data);
          }
          word = word.substring(endIndex, word.length);
          start += 1;
          end += 1;
        }
        result = result.filter(
          (v, i, a) =>
            a.findIndex(t => JSON.stringify(t) === JSON.stringify(v)) === i
        );
        return result;
      case GameName.YES_OR_NO:
        response =
          `${arrSize + 1}. word: ` + response.substr(1, response.length);
        while (startIndex != -1) {
          const startIndex = response.indexOf(`${start}`);
          if (startIndex == -1) break;
          let endIndex = response.indexOf(`${end}`);
          if (endIndex == -1) {
            endIndex = response.length;
          }
          let word = response.substring(startIndex + 2, endIndex).trim();
          if (word[0] === '.') word = word.substr(1, word.length).trim();
          const contentList = word.split('\n');
          if (contentList.length === 2) {
            const data = {
              word: '',
              hint: ''
            };
            let dataCorrupt = false;
            for (let i = 0; i < contentList.length; i++) {
              if (i == 0) {
                const split = contentList[i].toLowerCase().split('word:');

                if (
                  split.length == 2 &&
                  split[0] == '' &&
                  split[1].trim() != ''
                ) {
                  data.word = split[1].trim();
                } else {
                  dataCorrupt = true;
                  break;
                }
              } else {
                const split = contentList[i].toLowerCase().split('hint:');
                if (
                  split.length == 2 &&
                  split[0] == '' &&
                  split[1].trim() != ''
                ) {
                  data.hint = split[1].trim();
                } else {
                  dataCorrupt = true;
                  break;
                }
              }
            }
            if (!dataCorrupt && data.word != data.hint) result.push(data);
          }
          word = word.substring(endIndex, word.length);
          start += 1;
          end += 1;
        }
        result = result.filter(
          (v, i, a) =>
            a.findIndex(t => JSON.stringify(t) === JSON.stringify(v)) === i
        );
        return result;
      case GameName.POLL:
        response =
          `${arrSize + 1}. question: ` + response.substr(1, response.length);
        while (startIndex != -1) {
          const startIndex = response.indexOf(`${start}`);
          if (startIndex == -1) break;
          let endIndex = response.indexOf(`${end}`);
          if (endIndex == -1) {
            endIndex = response.length;
          }
          let word = response.substring(startIndex + 2, endIndex).trim();
          if (word[0] === '.') word = word.substr(1, word.length).trim();
          const contentList = word.split('\n');
          if (contentList.length === 2) {
            const data = {
              question: '',
              choices: [] as string[]
            };
            let dataCorrupt = false;
            for (let i = 0; i < contentList.length; i++) {
              if (i == 0) {
                const split = contentList[i].toLowerCase().split('question:');
                if (
                  split.length == 2 &&
                  split[0] == '' &&
                  split[1].trim() != ''
                ) {
                  data.question = split[1].trim();
                } else {
                  dataCorrupt = true;
                  break;
                }
              } else {
                const split = contentList[i].toLowerCase().split('choices: ');

                if (
                  split.length == 2 &&
                  split[0] == '' &&
                  split[1].trim() != ''
                ) {
                  data.choices = split[1].trim().split(',');
                } else {
                  dataCorrupt = true;
                  break;
                }
              }
            }
            if (!dataCorrupt && data.choices.length >= 2) result.push(data);
          }
          word = word.substring(endIndex, word.length);
          start += 1;
          end += 1;
        }
        result = result.filter(
          (v, i, a) =>
            a.findIndex(t => JSON.stringify(t) === JSON.stringify(v)) === i
        );
        return result;

      case GameName.TOP5:
        response =
          `${arrSize + 1}. question: ` + response.substr(1, response.length);

        while (startIndex != -1) {
          const startIndex = response.indexOf(`${start}.`);
          if (startIndex == -1) break;
          let endIndex = response.indexOf(`${end}.`);
          if (endIndex == -1) {
            endIndex = response.length;
          }
          let entry = response.substring(startIndex + 3, endIndex).trim();
          let ques;

          // if (entry[0] === '.') entry = entry.substr(1, entry.length).trim();
          const contentList = entry.split('\n');

          if (contentList.length == 1) {
            const questionSplit = contentList[0].split('question: ');

            if (questionSplit.length == 2) {
              ques = questionSplit[1];
              // if (contentList[1] == 'answers:') {
              //   answers = ['','','','','']
              // }
            }
          }
          if (ques) {
            result.push({
              question: ques,
              answers: ['', '', '', '', '']
            });
          }
          entry = entry.substring(endIndex, entry.length);
          start += 1;
          end += 1;
        }

        result = result.filter(
          (v, i, a) =>
            a.findIndex(t => JSON.stringify(t) === JSON.stringify(v)) === i
        );
        return result;

      default:
        break;
    }
  }
  static cleanWords(game: string, words: Array<any>) {
    const clean: Array<any> = [];
    switch (game) {
      case GameName.DRAW_IT:
      case GameName.DESCRIBE_IT:
      case GameName.CHARADES:
        for (const index in words) {
          if (!containsProfaneWord(words[index])) clean.push(words[index]);
        }
        return clean;
      case GameName.WOULD_YOU_RATHER:
      case GameName.SPOT_MY_LIE:
      case GameName.QUESTION_OF_THE_DAY:
      case GameName.DRAW_TELL:
      case GameName.TRIVIA:
      case GameName.TWO_WORDS_GAME:
      case GameName.ONE_TO_HUNDRED:
      case GameName.YES_OR_NO:
      case GameName.FUNNY_OR_NOT:
      case GameName.POLL:
        for (const index in words) {
          const word = words[index];
          let isClean: boolean = true;
          for (const key in word) {
            if (containsProfaneWord(word[key])) {
              isClean = false;
              break;
            }
          }
          if (isClean) clean.push(word);
        }
        return clean;
      case GameName.TOP5:
        for (const index in words) {
          const word = words[index];
          let isClean: boolean = true;
          for (const key in word) {
            if (containsProfaneWord(word[key])) {
              isClean = false;
              break;
            }
          }
          if (isClean) clean.push(word);
        }
        return clean;
      default:
        return [];
    }
  }
  static inputLimitFistIndex(
    game: string,
    words: Array<any>,
    title: string,
    description: string
  ) {
    let totalChar = 0;
    const limit = 1000;
    let firstIndex = words.length - 1;
    totalChar += title.length;

    totalChar += description.length;
    for (let i = words.length - 1; i >= 0; i--) {
      let size = 0;
      let word;
      switch (game) {
        case GameName.DRAW_IT:
        case GameName.DESCRIBE_IT:
        case GameName.CHARADES:
          size += words[i].length;
          break;
        case GameName.TWO_WORDS_GAME:
        case GameName.WEREWOLF_GAME:
        case GameName.HEARTS_GAME:
        case GameName.WOULD_YOU_RATHER:
        case GameName.SPOT_MY_LIE:
        case GameName.TRIVIA:
        case GameName.RUMMY_GAME:
        case GameName.GIN_RUMMY_GAME:
        case GameName.QUESTION_OF_THE_DAY:
        case GameName.DRAW_TELL:
        case GameName.WORDS_OF_POWER:
        case GameName.ONE_TO_HUNDRED:
        case GameName.YES_OR_NO:
        case GameName.FUNNY_OR_NOT:
        case GameName.POLL:
        case GameName.TOP5:
          word = words[i];
          for (const key in word) {
            if (word[key]) size += word[key].length;
          }
          break;

        default:
          break;
      }

      if (totalChar + size <= limit) {
        firstIndex = i;
        totalChar += size;
      } else break;
    }
    // console.log(`Choosing words from ${firstIndex} to ${words.length - 1}`);
    return firstIndex;
  }
}
