
import usePostAPI from '@/hooks/usePostAPI';

import { store } from '@/store';
import {
  getEndpoint,
  subscriptionText,
  getPeriodEndDate,
  subscriptionIntervalText
} from '@/utils/helper';
import { computed, defineComponent, ref } from 'vue';
import BounceLoading from '@/components/BounceLoading.vue';
import useFirebaseLoginOut from '@/hooks/firebase/useFirebaseLoginOut';

export default defineComponent({
  name: 'RenewSubscriptionModal',
  emits: ['close'],
  components: {
    BounceLoading
  },
  setup(_, context) {
    const isLoading = ref(true);
    const isSuccess = ref(false);
    const error = ref('');
    const subscriptionDetails = computed(
      () => store.getters.getSubscriptionDetails
    );
    const subscriptionLevelText = computed(() => {
      return subscriptionText(subscriptionDetails.value);
    });
    const intervalText = computed(() => {
      return subscriptionIntervalText(subscriptionDetails.value);
    });
    const subscriptionPeriodEnd = ref('');
    const close = function() {
      context.emit('close');
    };
    const refresh = function() {
      window.location.reload();
      context.emit('close');
    };
    const initialize = async function() {
      let endDate = getPeriodEndDate(
        subscriptionDetails.value.stripeCurrentPeriodEnd
      );
      subscriptionPeriodEnd.value = endDate ? endDate : '';
      isLoading.value = false;
    };
    initialize();

    const renewSubscription = async function() {
      try {
        isLoading.value = true;
        const response = await usePostAPI(
          getEndpoint('renewSubscription', '/api/stripe/'),
          {}
        );
        useFirebaseLoginOut().getSubscriptionDetails(true);
        if (response.status == 200) {
          isSuccess.value = true;
          error.value = '';
        } else {
          console.log(response.data);
          error.value = 'There is problem executing the request right now';
          isSuccess.value = false;
        }

        isLoading.value = false;
      } catch (err) {
        isLoading.value = false;
        error.value = 'There is problem executing the request right now';
        console.log(err);
      }
    };

    return {
      subscriptionPeriodEnd,
      subscriptionLevelText,
      isLoading,
      error,
      isSuccess,
      intervalText,
      renewSubscription,
      close,
      refresh
    };
  }
});
