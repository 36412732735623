
import { getGameTitleByType } from '@/utils/gameConstants';
import { computed, defineComponent, ref } from 'vue';
import { shareGameResult } from '@/utils/shareResult';
import { useStore } from '@/store';

export default defineComponent({
  name: 'HistoryModalSpotMyLieComponent',
  components: {},
  props: {
    game: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const user = computed(() => store.getters.getUser);
    const title = getGameTitleByType(props.game.gameName);
    const customGameName = ref(props.game.customGameName);
    const customGameCss = ref(props.game.customGameCss);
    const dateString = ref(
      props.game.dateCreated.format('DD MMMM, YYYY - hh:mm A')
    );
    const hostName = ref(undefined as string | undefined);
    const isHostNotPlaying = ref(props.game.isHostPlaying === false);
    const totalScores = ref([] as any[]);

    function calculateResult() {
      if (props.game.host !== user.value.uid) {
        hostName.value =
          props.game.players[props.game.host]?.name ?? props.game.hostName;
      }

      const actualPlayers = Object.assign({}, props.game.players);
      if (isHostNotPlaying.value) {
        delete actualPlayers[props.game.host];
      }
      Object.keys(actualPlayers ?? {}).map(playerId => {
        const player = actualPlayers[playerId];
        const score = props.game.gameDetails.filter(gameDetail => {
          return (
            gameDetail.fakeAnswerPlayers &&
            Object.keys(gameDetail.fakeAnswerPlayers).includes(playerId)
          );
        }).length;
        totalScores.value.push({
          name: player.name,
          email: player.email,
          score: score
        });
      });
      totalScores.value.sort((a, b) => b.score - a.score);
    }

    calculateResult();

    async function shareResult() {
      const data = {
        gameName: props.game.gameName,
        date:
          dateString.value +
          (hostName.value ? ` - Hosted by ${hostName.value}` : ''),
        scores: totalScores.value
      };
      const url = await shareGameResult(data);
      window.open(url, '_blank');
    }

    return {
      title,
      customGameName,
      customGameCss,
      dateString,
      hostName,
      isHostNotPlaying,
      totalScores,
      shareResult
    };
  }
});
