export class FunnyOrNotQuestion {
  public question: string = '';
  public index: number = -1;
  public key: number = -1;
  public imageData: any;
  public imagePath: string = '';

  public constructor(fields?: {
    question?: string;
    index?: number;
    key?: number;
    imageData?: any;
    imagePath?: any;
  }) {
    if (fields) Object.assign(this, fields);
  }
}
