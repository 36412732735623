declare global {
  interface Window {
    heap: any;
    FreshworksWidget: any;
  }
}

export function identifyHeap(uid: string) {
  try {
    if (window.heap) window.heap.identify(uid);
  } catch (err) {
    console.error(err);
  }
}

export function heapAddUserProperties(
  propertyName: string,
  propertyValue: string | number
) {
  try {
    if (window.heap)
      window.heap.addUserProperties({ [propertyName]: propertyValue });
  } catch (err) {
    console.error(err);
  }
}
