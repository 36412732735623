
import BounceLoading from '@/components/BounceLoading.vue';
import { store } from '@/store';
import firebase from 'firebase';
import { computed, defineComponent, Ref, ref } from 'vue';
import { eventToolsHistoryDateFormat } from '@/utils/helper';
import WheelHistoryModal from './WheelHistoryModal.vue';
import {
  SpinTheWheel,
  WheelResult
} from '@/models/event_tools/spin_the_wheel/spin_the_wheel_model';
export default defineComponent({
  name: 'WheelHistoryListModal',
  components: { BounceLoading, WheelHistoryModal },
  props: {
    wheel: Object as () => SpinTheWheel
  },
  emits: ['close'],
  setup(props, context) {
    const state: Ref<'loading' | 'list' | ['result', WheelResult]> = ref(
      'loading'
    );
    const userDoc = computed(() => store.getters.getUserDoc);
    const wheelResults: Ref<Array<WheelResult> | null> = ref(null);
    async function fetchResults() {
      try {
        const snapshots = await firebase
          .firestore()
          .collection(
            `minigame_lobbies/${userDoc.value['defaultLobbyId']}/spin_the_wheel/${props.wheel?.id}/results`
          )
          .orderBy('dateCreated', 'desc')
          .get();

        if (!snapshots.empty && snapshots.docs.length != 0) {
          let results: Array<WheelResult> = [];
          snapshots.docs.forEach(doc => {
            let result = doc.data() as WheelResult;
            result.id = doc.id;
            results.push(result);
          });
          wheelResults.value = results;
        }
      } catch (err) {
        console.log(err);
      }
      state.value = 'list';
    }
    fetchResults();
    function onClose() {
      context.emit('close');
    }
    function onView(result: WheelResult) {
      // result.participants = [
      //   { id: '1', email: '111', name: '111' },
      //   { id: '2', email: '111', name: '111' },
      //   { id: '3', email: '111', name: '111' },
      //   { id: '4', email: '111', name: '111' },
      //   { id: '5', email: '111', name: '111' },
      //   { id: '6', email: '111', name: '111' },
      //   { id: '7', email: '111', name: '111' },
      //   { id: '8', email: '111', name: '111' },
      //   { id: '9', email: '111', name: '111' },
      //   { id: '10', email: '111', name: '111' }
      // ];
      // result.entries = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
      state.value = ['result', result];
    }
    function onBack() {
      state.value = 'list';
    }

    return {
      wheelResults,
      state,
      onClose,
      onView,
      onBack,
      eventToolsHistoryDateFormat
    };
  }
});
