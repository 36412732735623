
import {
  LivePollResult,
  MultipleChoiceLivePoll
} from '@/models/event_tools/live_poll/live_poll_model';

import { defineComponent } from 'vue';
export default defineComponent({
  name: 'MultipleChoiceHistoryDetail',

  props: {
    result: Object as () => LivePollResult
  },
  emits: ['close'],
  setup() {
    return {};
  }
});
