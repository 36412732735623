
import { getGameTitleByType, pollColors } from '@/utils/gameConstants';
import { computed, defineComponent, ref } from 'vue';
import { useStore } from '@/store';

export default defineComponent({
  name: 'HistoryModalPollComponent',
  components: {},
  props: {
    game: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const user = computed(() => store.getters.getUser);
    const title = getGameTitleByType(props.game.gameName);
    const customGameName = ref(props.game.customGameName);
    const customGameCss = ref(props.game.customGameCss);
    const dateString = ref(
      props.game.dateCreated.format('DD MMMM, YYYY - hh:mm A')
    );
    const hostName = ref(undefined as string | undefined);
    const isHostNotPlaying = ref(props.game.isHostPlaying === false);
    const players = ref([] as any[]);
    const showPlayers = ref([] as any[]);
    const maxWolf = ref(0);
    const loneWolves = ref([] as string[]);
    const questions = ref([] as any[]);
    const showMores = ref([] as boolean[]);

    function calculateResult() {
      if (props.game.host !== user.value.uid) {
        const host = props.game.players.find(
          player => player.playerId === props.game.host
        );
        if (host) {
          hostName.value = host.name;
        }
      }

      const actualPlayers = Object.assign(
        [],
        isHostNotPlaying.value
          ? props.game.players.filter(
              player => player.playerId !== props.game.host
            )
          : props.game.players
      );
      const wolves = {};
      props.game.gameDetails.map(detail => {
        questions.value.push({
          question: detail.question,
          choices: detail.choices.map(choice => {
            return {
              choice: choice,
              players: []
            };
          })
        });
        showMores.value.push(false);
      });
      actualPlayers.map(player => {
        players.value.push({
          name: player.name,
          email: player.email
        });
        if (player.roundDetails) {
          player.roundDetails.map((detail, index) => {
            if (detail.selectedChoice !== -1) {
              questions.value[index].choices[
                detail.selectedChoice
              ].players.push(player.name);
            }
          });
        }
      });
      questions.value.map(question => {
        question.choices.sort((a, b) => b.players.length - a.players.length);
        const wolfIndex = question.choices.findIndex(
          choice => choice.players.length === 1
        );
        if (
          wolfIndex !== -1 &&
          question.choices.filter(
            (choice, index) => choice.players.length > 1 && index !== wolfIndex
          ).length === 1
        ) {
          const wolf = question.choices[wolfIndex].players[0];
          if (wolves[wolf]) {
            wolves[wolf]++;
          } else {
            wolves[wolf] = 1;
          }
        }
      });
      if (props.game.gameDetails.length >= 4) {
        Object.keys(wolves).map(name => {
          if (maxWolf.value < wolves[name]) {
            maxWolf.value = wolves[name];
          }
        });
        if (maxWolf.value > 0) {
          loneWolves.value = Object.keys(wolves).filter(
            name => wolves[name] === maxWolf.value
          );
        }
      }
      if (players.value.length > 7) {
        showPlayers.value = players.value.slice(0, 7);
      } else {
        showPlayers.value = players.value;
      }
    }

    calculateResult();

    function choices(index: number) {
      if (questions.value.length <= index) {
        return [];
      } else {
        if (questions.value[index].choices.length > 5) {
          if (showMores.value[index]) {
            return questions.value[index].choices;
          } else {
            return questions.value[index].choices.slice(0, 5);
          }
        } else {
          return questions.value[index].choices;
        }
      }
    }

    function showMore() {
      showPlayers.value = players.value;
    }

    return {
      pollColors,
      title,
      customGameName,
      customGameCss,
      dateString,
      hostName,
      isHostNotPlaying,
      players,
      showPlayers,
      maxWolf,
      loneWolves,
      questions,
      showMores,
      choices,
      showMore
    };
  }
});
