import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "personalized-game-instruction"
}
const _hoisted_2 = {
  key: 1,
  class: "personalized-game-instruction"
}
const _hoisted_3 = {
  key: 2,
  class: "personalized-game-instruction"
}
const _hoisted_4 = {
  key: 3,
  class: "personalized-game-instruction"
}
const _hoisted_5 = {
  key: 4,
  class: "personalized-game-instruction"
}
const _hoisted_6 = {
  key: 5,
  class: "personalized-game-instruction"
}
const _hoisted_7 = {
  key: 6,
  class: "personalized-game-instruction"
}
const _hoisted_8 = {
  key: 7,
  class: "personalized-game-instruction"
}
const _hoisted_9 = {
  key: 8,
  class: "personalized-game-instruction"
}
const _hoisted_10 = {
  key: 9,
  class: "personalized-game-instruction"
}
const _hoisted_11 = {
  key: 10,
  class: "personalized-game-instruction"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Fragment, null, [
    (_ctx.type == 'would_you_rather')
      ? (_openBlock(), _createBlock("div", _hoisted_1, " Think of two hypothetical scenarios that fit the \"Would You Rather\" format. Tip: Try to make both choices equally appealing. "))
      : _createCommentVNode("", true),
    (_ctx.type == 'draw_it' || _ctx.type == 'describe_it' || _ctx.type == 'charades')
      ? (_openBlock(), _createBlock("div", _hoisted_2, " Enter whatever word or phrase you like. Make sure to double-check spelling. "))
      : _createCommentVNode("", true),
    (_ctx.type == 'question_of_the_day' || _ctx.type == 'spot_my_lie')
      ? (_openBlock(), _createBlock("div", _hoisted_3, " Think of an open ended question is fun or interesting to answer. "))
      : _createCommentVNode("", true),
    (_ctx.type == 'draw_tell')
      ? (_openBlock(), _createBlock("div", _hoisted_4, " Enter a prompt that would be interesting to draw. It could be an object, a memory or something completely hypothetical. "))
      : _createCommentVNode("", true),
    (_ctx.type == 'trivia')
      ? (_openBlock(), _createBlock("div", _hoisted_5, " Create your own multiple choice trivia question. Make sure to include 3 incorrect answers. "))
      : _createCommentVNode("", true),
    (_ctx.type == 'two_words_game')
      ? (_openBlock(), _createBlock("div", _hoisted_6, " Use the fields below to create word pairs for Mimic. For the best results, use words that are slightly similar to each other, e.g. Tiger and Cat. "))
      : _createCommentVNode("", true),
    (_ctx.type == 'one_to_hundred')
      ? (_openBlock(), _createBlock("div", _hoisted_7, " Think of a Topic that contains a wide range of possible answers. In the From (0) and To (100) sections, have two opposite adjectives that can describe the objects in the topic. "))
      : _createCommentVNode("", true),
    (_ctx.type == 'yes_or_no')
      ? (_openBlock(), _createBlock("div", _hoisted_8, " Think of a secret word or phrase. Give a clue for that word without making it too obvious. TIP: A great secret word is hard to guess but not impossible. "))
      : _createCommentVNode("", true),
    (_ctx.type == 'funny_or_not')
      ? (_openBlock(), _createBlock("div", _hoisted_9, " Create your own funny prompts that anyone can write reactions to. Attach images or gifs to any of the questions. "))
      : _createCommentVNode("", true),
    (_ctx.type == 'poll')
      ? (_openBlock(), _createBlock("div", _hoisted_10, " Create your own poll questions. Make sure to include at least two possible answers. "))
      : _createCommentVNode("", true),
    (_ctx.type == 'top5')
      ? (_openBlock(), _createBlock("div", _hoisted_11, " Create your own questions. Make sure to include five answers for every question. "))
      : _createCommentVNode("", true)
  ], 64))
}