
import { store } from '@/store';
import { gameTitles } from '@/utils/gameConstants';
import { computed, defineComponent, ref, Ref, PropType } from 'vue';
import { onGameStart } from '@/utils/helper';
import { GameStartOptions, LaunchOptions } from '@/models/GameStartOptions';
export default defineComponent({
  name: 'LaunchModal',

  props: {
    settings: {
      type: Object as PropType<LaunchOptions>,
      required: true
    }
  },
  emits: ['close'],
  setup(props, context) {
    function onClose() {
      context.emit('close');
    }

    const confirmButtonLoading: Ref<boolean> = ref(false);
    const selectedOption: Ref<
      'startFresh' | 'currentParticipants' | null
    > = ref(null);
    const lobby = computed(() => store.getters.getLobbyDoc);
    const gameName = computed(() => gameTitles.get(lobby.value.gameName));
    const participantsText = computed(() => {
      const participants = lobby.value.playerIds?.length ?? 0;
      if (participants == 1) return '1 participant';
      else return `${participants} participants`;
    });
    const subscriptionDetails = computed(
      () => store.getters.getSubscriptionDetails
    );
    async function onConfirm() {
      if (selectedOption.value == null) return;
      confirmButtonLoading.value = true;
      const gameSettings: GameStartOptions = {
        ...props.settings,
        subscriptionLevel: subscriptionDetails.value.subscriptionLevel,
        startFresh: selectedOption.value == 'startFresh',
        zoomClientConfig: store.state.zoomClientStatus
      };
      await onGameStart(gameSettings);
      confirmButtonLoading.value = false;
      onClose();
    }

    return {
      onClose,
      onConfirm,
      confirmButtonLoading,
      selectedOption,
      gameName,
      participantsText
    };
  }
});
