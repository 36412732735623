
import { defineComponent, onMounted, ref } from 'vue';
import { amplitudeEvent } from '@/config/amplitudeConfig';
// import NumberOfPlayersWerewolf from '@/components/Modal/AboutComponent/NumberOfPlayersComponents/NumberOfPlayersWerewolf.vue';
import HowToPlayWerewolf from '@/components/Modal/AboutComponent/HowToPlayComponents/HowToPlayWerewolf.vue';
import CollapseTransition from '@/components/CollapseTransition.vue';

export default defineComponent({
  name: 'AboutWerewolf',
  components: {
    // NumberOfPlayersWerewolf,
    HowToPlayWerewolf,
    CollapseTransition
  },
  setup() {
    const showTabs = ref([true, false]);

    onMounted(() => {
      amplitudeEvent('previewGame', {
        game: 'werewolf_game',
        version: 'regular',
        eventTime: new Date(Date.now()).toISOString()
      });
    });

    return {
      showTabs
    };
  }
});
