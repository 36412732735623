import { toRefs, reactive, onMounted } from 'vue';
import firebase from '@/firebaseApp';

export default function(dbPath: string) {
  const state: {
    error: firebase.auth.Error | null;
    documentData: any | null;
    loading: boolean;
  } = reactive({
    // error if one happens
    error: null,
    // the results of the query
    documentData: {},
    // if the query is loading or ot
    loading: false
  });

  // get the database
  const db = firebase.database();

  /**
   * there is the option to load the query when the component
   * is mounted, you need to set the option in the `queryOptions`
   * params that you pass in
   *
   */
  onMounted(() => {
    listenDocument(dbPath);
  });

  const listenDocument = (dbPath: string) => {
    state.loading = true;
    state.error = null;
    try {
      db.ref(dbPath).on('value', doc => {
        if (doc.exists()) {
          state.documentData = doc.val();
          state.error = null;
        } else {
          // doc.data() will be undefined in this case
          state.documentData = null;
          state.error = null;
        }
      });
    } catch (err) {
      console.error('Error listenDocument: ', err);
      state.error = err as any;
    } finally {
      state.loading = false;
    }
  };

  return {
    ...toRefs(state)
  };
}
