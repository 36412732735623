import { createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "modal-content pin-header" }
const _hoisted_2 = { class: "modal-header" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 2,
  class: "modal-body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BounceLoading = _resolveComponent("BounceLoading")
  const _component_PollHost = _resolveComponent("PollHost")
  const _component_PollParticipant = _resolveComponent("PollParticipant")

  return (_openBlock(), _createBlock("div", {
    class: "modal-right no-overlay-layer for-long-modal",
    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.close()))
  }, [
    _createVNode(_Transition, {
      appear: "",
      name: "slide"
    }, {
      default: _withCtx(() => [
        _createVNode("div", {
          class: "modal-dialog",
          onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
        }, [
          _createVNode("div", _hoisted_1, [
            _createVNode("div", _hoisted_2, [
              _createVNode("button", {
                type: "button",
                class: "btn-close-left",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.close()))
              }, [
                _createVNode("img", {
                  src: require('@/assets/img/close.png'),
                  alt: "close modal"
                }, null, 8, ["src"])
              ])
            ]),
            (_ctx.loading)
              ? (_openBlock(), _createBlock(_component_BounceLoading, { key: 0 }))
              : (_ctx.error)
                ? (_openBlock(), _createBlock("div", _hoisted_3, " An unexpected error has occured. Please try again later or contact support@brightful.me for more info. "))
                : (!_ctx.loading)
                  ? (_openBlock(), _createBlock("div", _hoisted_4, [
                      (_ctx.isHost)
                        ? (_openBlock(), _createBlock(_component_PollHost, {
                            key: 0,
                            whiteboardId: _ctx.whiteboardId,
                            hasActivePoll: _ctx.collectionData.length !== 0,
                            pollDoc: _ctx.collectionData[0],
                            numParticipants: _ctx.numParticipants
                          }, null, 8, ["whiteboardId", "hasActivePoll", "pollDoc", "numParticipants"]))
                        : (!_ctx.isHost && _ctx.collectionData.length !== 0)
                          ? (_openBlock(), _createBlock(_component_PollParticipant, {
                              key: 1,
                              whiteboardId: _ctx.whiteboardId,
                              pollDoc: _ctx.collectionData[0],
                              onHasVoted: _ctx.hasVoted,
                              onHasNotVoted: _ctx.hasNotVoted
                            }, null, 8, ["whiteboardId", "pollDoc", "onHasVoted", "onHasNotVoted"]))
                          : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 1
    })
  ]))
}