
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'RefreshModal',
  emits: ['submit-form'],
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup() {
    function refreshPage() {
      window.location.reload();
    }

    return {
      refreshPage
    };
  }
});
