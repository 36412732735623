
import { livePollConfigs } from '@/utils/helper';
import { LivePollType } from '@/utils/live_poll_enums';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'CreatePollWithoutHistory',
  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'show-modal': (type: LivePollType) => true
  },
  props: {},
  components: {},
  setup(_, context) {
    const hasPollHistory = ref(false);
    const pollConfigs = livePollConfigs;
    function onPollType(type: LivePollType) {
      context.emit('show-modal', type);
    }
    return {
      hasPollHistory,
      pollConfigs,
      onPollType
    };
  }
});
