import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "preview-bar-chart h-100 bg-white border py-4 px-4" }
const _hoisted_2 = { class: "heading text-center color-primary" }
const _hoisted_3 = { class: "color-grey text-center" }
const _hoisted_4 = { class: "mt-2" }
const _hoisted_5 = { class: "table table-sm table-bordered table-striped font-size-14 " }
const _hoisted_6 = { class: "py-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("h4", _hoisted_2, _toDisplayString(_ctx.result?.poll.question), 1),
      _createVNode("p", _hoisted_3, " Responded: " + _toDisplayString(_ctx.result?.respondedParticipantIDs.length), 1),
      _createVNode("div", _hoisted_4, [
        _createVNode("table", _hoisted_5, [
          _createVNode("tbody", null, [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.words, (word) => {
              return (_openBlock(), _createBlock("tr", { key: word }, [
                _createVNode("td", _hoisted_6, _toDisplayString(word), 1)
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}